import { AfterViewChecked, Component, Input, OnChanges, OnInit } from '@angular/core';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { ModalController, Platform } from '@ionic/angular';
import { UtilService } from '../../services/util.services';
import { TalentDashboardUtilService } from 'src/modules/talent-dashboard/services/talent-dashboard-util.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-full-detail',
  templateUrl: './full-detail.component.html',
  styleUrls: ['./full-detail.component.scss'],
})
export class FullDetailComponent implements OnInit {

  @Input() data;
  distanceFromShift;
  jobItem;
  profilePicture;
  description: string;
  invoice;
  userData;
  shiftAddressList: string[] = [];
  
  constructor(
    private talentJobFormatService: TalentJobFormatService,
    private modalController : ModalController,
    private utilService: UtilService,
    private talentDashboardUtilService: TalentDashboardUtilService
  ) { 
  }

  ngOnInit(): void {

    if(this.data.user) 
      this.userData = this.data.user;


    if(this.data.currentInvoice)  {
      this.invoice = this.data.currentInvoice;
      this.generateDescription();
    }
    
    if(this.data.job) {
      let url;
      this.jobItem = this.data.job;
 
      this.jobItem.shiftStartTimeFormatted = moment.unix(this.jobItem.shiftStartTime).tz(this.jobItem.timeZone).format('dddd MMMM DD');
      this.jobItem.startTime = this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftStartTime, true);
      this.jobItem.endTime = this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftEndTime, true);
      this.shiftAddressList = this.utilService.fetchAddressesFromPatientList(this.jobItem);      
      url = this.utilService.evaluateProfilePictureUrl(this.jobItem, this.invoice)

      if(this.data.job && this.data.job.entity)
        this.profilePicture = this.talentJobFormatService.getProfilePic(url);   
      else
        this.profilePicture = 'assets/images/staffy-gray.png'

      this.distanceFromShift = this.utilService.calculateDistanceFromShift(this.jobItem.companyAddress, this.userData.address);
    }
    
  }

  downloadInvoice() {
    this.modalController.dismiss({download: true});
  }

  generateDescription() {
    this.description = this.talentJobFormatService.getJobDescription(this.invoice);
  }

  updateInvoiceMarkAsPaid() {
    this.modalController.dismiss({markAsPaid: true})
  }

  goBack() {
    this.modalController.dismiss();
  }

  calculateStaffyPayFee(shiftAmount): number {

    let staffyPayFee = 0;
    // Rules
    // If shiftAmount <= 200 then staffyPay Fee would be $5
    if (shiftAmount <= 200) {
        staffyPayFee = 5;
    } else // Otherwise 3% of the shiftAmount
    if (shiftAmount > 200) {
        staffyPayFee = Number(this.utilService.bigDeci(shiftAmount).multipliedBy(this.utilService.bigDeci(0.03)));
    }

    return this.utilService.bigDeci(staffyPayFee, true);
}

}
