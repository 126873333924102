/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-multi-address-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./shift-multi-address-list.component";
var styles_ShiftMultiAddressListComponent = [i0.styles];
var RenderType_ShiftMultiAddressListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftMultiAddressListComponent, data: {} });
export { RenderType_ShiftMultiAddressListComponent as RenderType_ShiftMultiAddressListComponent };
function View_ShiftMultiAddressListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "multi-address"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "general-book-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "."])), (_l()(), i1.ɵted(4, null, ["\u00A0", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 4, 0, currVal_1); }); }
function View_ShiftMultiAddressListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "shift-address"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "general-regular"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-map-marker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "general-book-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Multiple addresses: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftMultiAddressListComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shiftAddressList; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_ShiftMultiAddressListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftMultiAddressListComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.shiftAddressList == null) ? null : _co.shiftAddressList.length); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ShiftMultiAddressListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shift-multi-address-list", [], null, null, null, View_ShiftMultiAddressListComponent_0, RenderType_ShiftMultiAddressListComponent)), i1.ɵdid(1, 114688, null, 0, i3.ShiftMultiAddressListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShiftMultiAddressListComponentNgFactory = i1.ɵccf("app-shift-multi-address-list", i3.ShiftMultiAddressListComponent, View_ShiftMultiAddressListComponent_Host_0, { shiftAddressList: "shiftAddressList" }, {}, []);
export { ShiftMultiAddressListComponentNgFactory as ShiftMultiAddressListComponentNgFactory };
