import { HttpPromiseService } from './http-promise.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-promise.service";
export class AttestationAndDeclarationService {
    constructor(httpPromiseService) {
        this.httpPromiseService = httpPromiseService;
    }
    updateDocumentValidityDate(certificateId, requestBody) {
        return this.httpPromiseService.httpPutRequest(`/api/certificates/updateDocumentValidityDate/${certificateId}`, requestBody);
    }
    doesContractorHasVSCWithinXYears(userId, certificateType, period, duration) {
        return this.httpPromiseService.httpGetRequest(`/api/certificates/checkCertificateByExpiryDateRange/${userId}?type=${certificateType.toString()}&period=${period}&duration=${duration}`);
    }
    updateDeclarationSignedDate(stafferId, data) {
        return this.httpPromiseService.httpPostRequest(`/api/attestationsAndDeclarations/${stafferId}`, data);
    }
    generateRequestLetter(stafferId, timeZone, skills, type, city) {
        return this.httpPromiseService.httpPostRequest(`/api/attestationsAndDeclarations/vsc-request/${stafferId}`, {
            timeZone,
            skills,
            type,
            city
        });
    }
    fetchContractorVSCProperties(stafferId) {
        return this.httpPromiseService.httpGetRequest(`/api/attestationsAndDeclarations/${stafferId}`);
    }
}
AttestationAndDeclarationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AttestationAndDeclarationService_Factory() { return new AttestationAndDeclarationService(i0.ɵɵinject(i1.HttpPromiseService)); }, token: AttestationAndDeclarationService, providedIn: "root" });
