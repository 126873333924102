import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SplashScreenResolverService } from 'src/modules/shared/services/splash-screen-resolver.service';
import { LoggedInGuard } from './guards/logged-in.guard';
import { ContractorAppLayoutComponent } from 'src/layouts/ContractorAppLayout/contractor-app-layout.component';
const ɵ0 = SplashScreenResolverService, ɵ1 = () => import("./../modules/authentication/authentication.module.ngfactory").then(m => m.AuthenticationModuleNgFactory), ɵ2 = SplashScreenResolverService, ɵ3 = () => import("./../modules/onboarding-company/onboarding-company.module.ngfactory").then(m => m.OnboardingCompanyModuleNgFactory), ɵ4 = SplashScreenResolverService, ɵ5 = () => import("./../modules/onboarding-staffer/onboarding-staffer.module.ngfactory").then(m => m.OnboardingStafferModuleNgFactory), ɵ6 = SplashScreenResolverService, ɵ7 = () => import("./../modules/company-dashboard/company-dashboard.module.ngfactory").then(m => m.CompanyDashboardModuleNgFactory), ɵ8 = SplashScreenResolverService, ɵ9 = () => import("./../modules/talent-dashboard/talent-dashboard.module.ngfactory").then(m => m.TalentDashboardModuleNgFactory), ɵ10 = SplashScreenResolverService, ɵ11 = () => import("./../modules/shared/shared.module.ngfactory").then(s => s.SharedModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        canActivateChild: [AuthGuard],
        resolve: { data: ɵ0 },
        loadChildren: ɵ1
    },
    {
        path: 'onboarding-company',
        resolve: { data: ɵ2 },
        loadChildren: ɵ3
    },
    {
        path: 'onboarding-staffer',
        resolve: { data: ɵ4 },
        loadChildren: ɵ5
    },
    {
        path: 'company-dashboard',
        resolve: { data: ɵ6 },
        loadChildren: ɵ7
    },
    {
        path: '',
        component: ContractorAppLayoutComponent,
        children: [
            {
                path: 'talent-dashboard',
                resolve: { data: ɵ8 },
                canActivateChild: [LoggedInGuard],
                loadChildren: ɵ9
            },
        ]
    },
    {
        path: 'shared',
        resolve: { data: ɵ10 },
        loadChildren: ɵ11
    },
    { path: '**', redirectTo: 'auth', pathMatch: 'full' }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
