/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./choose-picture.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ngx-webcam/ngx-webcam.ngfactory";
import * as i3 from "ngx-webcam";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./choose-picture.component";
import * as i9 from "../../../talent-dashboard/services/talent-job-format.service";
import * as i10 from "@ngrx/store";
var styles_ChoosePictureComponent = [i0.styles];
var RenderType_ChoosePictureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChoosePictureComponent, data: {} });
export { RenderType_ChoosePictureComponent as RenderType_ChoosePictureComponent };
function View_ChoosePictureComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "wrapper"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (_co.onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "webcam", [], null, [[null, "imageCapture"], [null, "initError"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("imageCapture" === en)) {
        var pd_0 = (_co.handleImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("initError" === en)) {
        var pd_1 = (_co.handleInitError($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_WebcamComponent_0, i2.RenderType_WebcamComponent)), i1.ɵdid(2, 4374528, null, 0, i3.WebcamComponent, [], { width: [0, "width"], height: [1, "height"], imageQuality: [2, "imageQuality"], trigger: [3, "trigger"] }, { imageCapture: "imageCapture", initError: "initError" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = 400; var currVal_2 = 1; var currVal_3 = _co.triggerObservable; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ChoosePictureComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-button", [["class", "snap-btn"], ["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerSnapshot() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null)], function (_ck, _v) { var currVal_0 = "basic"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChoosePictureComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "outer-circle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureComponent_4)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "label", [["class", "custom-file-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fas fa-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, [["imageInput", 1]], null, 0, "input", [["accept", "image/*"], ["id", "chooseFile"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.processFile(i1.ɵnov(_v, 8)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCamAllowed; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isCamAllowed; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ChoosePictureComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "cordova-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-button", [["class", "snap-btn"], ["color", "basic"], ["expand", "full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.takePhoto("CAMERA") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, [" Take Photo "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ion-button", [["class", "snap-btn"], ["color", "dark"], ["expand", "full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.takePhoto("GALLERY") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(5, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, [" Choose Photo "]))], function (_ck, _v) { var currVal_0 = "basic"; var currVal_1 = "full"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "dark"; var currVal_3 = "full"; _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
function View_ChoosePictureComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "staffer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureComponent_5)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isCordova || _co.isBrowser); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.isCordova && !_co.isBrowser); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ChoosePictureComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "wrapper"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (_co.onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "webcam", [], null, [[null, "imageCapture"], [null, "initError"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("imageCapture" === en)) {
        var pd_0 = (_co.handleImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("initError" === en)) {
        var pd_1 = (_co.handleInitError($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_WebcamComponent_0, i2.RenderType_WebcamComponent)), i1.ɵdid(2, 4374528, null, 0, i3.WebcamComponent, [], { width: [0, "width"], height: [1, "height"], imageQuality: [2, "imageQuality"], trigger: [3, "trigger"] }, { imageCapture: "imageCapture", initError: "initError" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = 400; var currVal_2 = 1; var currVal_3 = _co.triggerObservable; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ChoosePictureComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-button", [["class", "snap-btn"], ["color", "basic"], ["expand", "full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerSnapshot() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "basic"; var currVal_1 = "full"; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("SHARED_IMAGE_COMPONENT.snapshot")); _ck(_v, 2, 0, currVal_2); }); }
function View_ChoosePictureComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureComponent_8)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureComponent_9)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "label", [["class", "custom-file-upload"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, [["imageInput", 1]], null, 0, "input", [["accept", "image/*"], ["id", "chooseFile"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.processFile(i1.ɵnov(_v, 8)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCamAllowed; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isCamAllowed; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("SHARED_IMAGE_COMPONENT.chooseFromSystem")); _ck(_v, 6, 0, currVal_2); }); }
function View_ChoosePictureComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "cordova-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-button", [["class", "snap-btn"], ["color", "basic"], ["expand", "full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.takePhoto("CAMERA") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, [" Take Photo "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ion-button", [["class", "snap-btn"], ["color", "dark"], ["expand", "full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.takePhoto("GALLERY") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(5, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, [" Choose Photo "]))], function (_ck, _v) { var currVal_0 = "basic"; var currVal_1 = "full"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "dark"; var currVal_3 = "full"; _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
function View_ChoosePictureComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "employer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureComponent_7)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureComponent_10)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isCordova || _co.isBrowser); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.isCordova && !_co.isBrowser); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ChoosePictureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureComponent_6)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.user.type == "staffer"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.user.type == "employer"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ChoosePictureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-choose-picture", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChoosePictureComponent_0, RenderType_ChoosePictureComponent)), i1.ɵdid(1, 114688, null, 0, i8.ChoosePictureComponent, [i5.Platform, i9.TalentJobFormatService, i10.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChoosePictureComponentNgFactory = i1.ɵccf("shared-choose-picture", i8.ChoosePictureComponent, View_ChoosePictureComponent_Host_0, { user: "user" }, { formSubmitted: "formSubmitted" }, []);
export { ChoosePictureComponentNgFactory as ChoosePictureComponentNgFactory };
