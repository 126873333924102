/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-context-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./no-context-found.component";
import * as i6 from "@angular/router";
var styles_NoContextFoundComponent = [i0.styles];
var RenderType_NoContextFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoContextFoundComponent, data: {} });
export { RenderType_NoContextFoundComponent as RenderType_NoContextFoundComponent };
function View_NoContextFoundComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "ion-button", [["class", "button"], ["color", "basic"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], type: [1, "type"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "div", [["class", "button-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "div", [["class", "arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-arrow-right"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "basic"; var currVal_1 = "submit"; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.noShifts == null) ? null : _co.noShifts.buttonText); _ck(_v, 4, 0, currVal_2); }); }
export function View_NoContextFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "no-shifts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "subText"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoContextFoundComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.noShifts == null) ? null : _co.noShifts.buttonText); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.noShifts == null) ? null : _co.noShifts.text); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.noShifts == null) ? null : _co.noShifts.imageUrl); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.noShifts == null) ? null : _co.noShifts.subText); _ck(_v, 5, 0, currVal_2); }); }
export function View_NoContextFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-context-found", [], null, null, null, View_NoContextFoundComponent_0, RenderType_NoContextFoundComponent)), i1.ɵdid(1, 638976, null, 0, i5.NoContextFoundComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoContextFoundComponentNgFactory = i1.ɵccf("app-no-context-found", i5.NoContextFoundComponent, View_NoContextFoundComponent_Host_0, { noShiftsData: "noShiftsData" }, {}, []);
export { NoContextFoundComponentNgFactory as NoContextFoundComponentNgFactory };
