import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Injectable } from '@angular/core';
import { AddressModificationService } from "src/modules/shared/services/address-modification.service";

@Injectable({ providedIn: 'root' })

export class PersonalInformationValidatorService {

  constructor(
    private addressModificationService: AddressModificationService,
  ) { }

  postalCodeValidator(): ValidatorFn  {
      return (control: AbstractControl) : ValidationErrors | null => {
          const country = control.value.country || 'us';
          const value = control.value.code;
  
          if (!value || !country) {
              return { code: true};
          }
          
          if (!this.addressModificationService.isPostalCodeValid(value, country) == true) {
              return { code: true };
          }
          
          return null;
      };
  }
}