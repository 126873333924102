import { createReducer, Action, on } from '@ngrx/store';

import * as authActions from '../actions/auth.actions';
import * as onBoardingActions from '../../../onboarding-staffer/+store/actions/onboarding-staffer.actions'
import { IAuthState, authState } from '../auth.state';
const authReducers = createReducer(
  authState,
  on(authActions.loginAction, state => ({
    ...state,
    isLoading: true
  })),
  on(authActions.loginSuccessAction, (state, { token }) => ({
    ...state,
    isLoading: false,
    token
  })),
  on(authActions.loginFailureAction, state => ({
    ...state,
    isLoading: false
  })),

  on(authActions.getUserInfoAction, state => ({
    ...state,
    isLoading: true
  })),
  on(authActions.getuserInfoSuccessAction, (state, { user }) => ({
    ...state,
    isLoading: false,
    user
  })),
  on(authActions.getUserInfoAfterLoginSuccessAction, (state, { user }) => ({
    ...state,
    isLoading: false,
    user
  })),
  on(authActions.getUserInfoAfterCreateUserSuccessAction, (state, { user }) => ({
    ...state,
    isLoading: false,
    user
  })),
  on(authActions.getUserInfoFailureAction, state => ({
    ...state,
    isLoading: false
  })),

  on(authActions.addPatientAction, (state) => ({ ...state, isLoading: false })),

  on(authActions.updatePatientAction, (state) => ({ ...state, isLoading: false })),

  on(authActions.removePatientAction, (state) => ({ ...state, isLoading: false })),
  

  on(authActions.setStoreAction, (state, { token, user }) => ({
    ...state,
    token,
    user
  })),

  on(authActions.setNotificationCountAction, (state, { notificationCount }) => ({
    ...state,
    notificationCount
  })),

  on(authActions.setShiftInProgressAction, (state, { shiftInProgress }) => ({
    ...state,
    shiftInProgress
  })),

  on(authActions.setAvailableShiftAction, (state, { availableShift }) => ({
    ...state,
    availableShift
  })),

  on(authActions.resetStoreAction, state => ({
    ...state,
    token: null,
    user: null,
    signupCode: null
  })),

  on(authActions.setTokenToStoreAction, (state, { token }) => {
    return {
      ...state,
      token
    };
  }),

  on(authActions.setUserToStoreAction, (state, { user }) => {
    return {
      ...state,
      user
    };
  }),

  on(authActions.updateTalentContract, (state, { user }) => {
    return {
      ...state,
      user
    };
  }),

  on(authActions.updateRoute, (state, { user }) => {
    return {
      ...state,
      user
    };
  }),

  on(authActions.userQuizSubmissionSuccessAction, (state: IAuthState, {quiz})=> {
    return {
      ...state,
      user: {
        ...state.user,
        quizzes: [...state.user.quizzes, quiz],
        isQuizSuccessful: quiz.isSuccessful
      }
    }
  }),
  on(authActions.completedIntroductoryStepsAction, (state: IAuthState) => {
      return {
        ...state,
        hasUserCompletedIntroductorySteps: true
      }
  }),
  on(onBoardingActions.submitForApprovalSuccessAction, state => ({
    ...state,
    user: {
      ...state.user,
      isSignupProcessComplete: true,
    }
  })),
  on(authActions.updateUserPartialStateSuccess, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      ...action.partialUserState,
    }
  })),
);

export function authReducer(state: IAuthState | undefined, action: Action) {
  return authReducers(state, action);
}
