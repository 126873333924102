
import { Injectable } from '@angular/core';
import { PopupService } from './popup.service';

@Injectable({ providedIn: 'root' })
export class ApplyErrorHandler {
  constructor(
      private popupService: PopupService,
  ) {}

  handleError(error){
    this.popupService.showModal({
      heading: 'Sorry',
      message:
        error.error && typeof error.error === 'string'
          ? error.error
          : error.data && error.data.errMsg
          ? error.data.errMsg
          : typeof error.data === 'string'
          ? error.data
          : 'We encountered an error when processing your request. Please try again later.',
      btn: 'Dismiss',
      navigateRoute: null,
      imgURL: 'assets/images/sorry1.png'
    });
  }
   

}
