/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-recurrent-shift-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared-toggle-bar/shared-toggle-bar.component.ngfactory";
import * as i3 from "../shared-toggle-bar/shared-toggle-bar.component";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "@angular/common";
import * as i7 from "../edit-recurrent-specific-shifts/edit-recurrent-specific-shifts.component.ngfactory";
import * as i8 from "../edit-recurrent-specific-shifts/edit-recurrent-specific-shifts.component";
import * as i9 from "@angular/forms";
import * as i10 from "../../services/loading.service";
import * as i11 from "../../services/job.service";
import * as i12 from "../../services/popup.service";
import * as i13 from "../../../company-dashboard/services/shift-validator.service";
import * as i14 from "../edit-recurrent-full-recurrence/edit-recurrent-full-recurrence.component.ngfactory";
import * as i15 from "../edit-recurrent-full-recurrence/edit-recurrent-full-recurrence.component";
import * as i16 from "../../../company-dashboard/services/broadcast-job-utils.service";
import * as i17 from "../../../talent-dashboard/services/recurrentShift.service";
import * as i18 from "./edit-recurrent-shift-modal.component";
import * as i19 from "../../services/local-storage.service";
import * as i20 from "../../services/util.services";
var styles_EditRecurrentShiftModalComponent = [i0.styles];
var RenderType_EditRecurrentShiftModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditRecurrentShiftModalComponent, data: {} });
export { RenderType_EditRecurrentShiftModalComponent as RenderType_EditRecurrentShiftModalComponent };
function View_EditRecurrentShiftModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shared-toggle-bar", [["class", "edit-shift"]], null, [[null, "toggleBar"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggleBar" === en)) {
        var pd_0 = (_co.onRecurrentToggleChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SharedToggleBarComponent_0, i2.RenderType_SharedToggleBarComponent)), i1.ɵdid(1, 638976, null, 0, i3.SharedToggleBarComponent, [], { data: [0, "data"] }, { toggleBar: "toggleBar" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EditRecurrentShiftModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-label", [["class", "heading"]], null, null, null, i4.View_IonLabel_0, i4.RenderType_IonLabel)), i1.ɵdid(2, 49152, null, 0, i5.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(3, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurrentShiftModalComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.submitView; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerText; _ck(_v, 3, 0, currVal_0); }); }
function View_EditRecurrentShiftModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-label", [["class", "heading confirmation-heading"]], null, null, null, i4.View_IonLabel_0, i4.RenderType_IonLabel)), i1.ɵdid(2, 49152, null, 0, i5.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Are you sure you want to save the changes?"]))], null, null); }
function View_EditRecurrentShiftModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-edit-recurrent-specific-shifts", [], null, null, null, i7.View_EditRecurrentSpecificShiftsComponent_0, i7.RenderType_EditRecurrentSpecificShiftsComponent)), i1.ɵdid(2, 8503296, [[2, 4], ["editSpecificShift", 4]], 0, i8.EditRecurrentSpecificShiftsComponent, [i9.FormBuilder, i10.LoadingService, i11.JobService, i12.PopupService, i13.ShiftValidatorService, i1.ChangeDetectorRef], { jobItem: [0, "jobItem"], timeZone: [1, "timeZone"], submitView: [2, "submitView"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jobItem; var currVal_1 = _co.timeZone; var currVal_2 = _co.submitView; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_EditRecurrentShiftModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-edit-recurrent-full-recurrence", [], null, null, null, i14.View_EditRecurrentFullRecurrenceComponent_0, i14.RenderType_EditRecurrentFullRecurrenceComponent)), i1.ɵdid(2, 114688, [[1, 4], ["editFullRecurrence", 4]], 0, i15.EditRecurrentFullRecurrenceComponent, [i9.FormBuilder, i13.ShiftValidatorService, i16.BroadcastJobsUtilService, i17.RecurrentShiftService, i11.JobService, i10.LoadingService, i12.PopupService], { jobItem: [0, "jobItem"], timeZone: [1, "timeZone"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jobItem; var currVal_1 = _co.timeZone; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_EditRecurrentShiftModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "unchange-view"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No changes has been made yet"]))], null, null); }
export function View_EditRecurrentShiftModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { editFullRecurrence: 0 }), i1.ɵqud(671088640, 2, { editSpecificShift: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "edit-shift-modal"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurrentShiftModalComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurrentShiftModalComponent_3)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurrentShiftModalComponent_4)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurrentShiftModalComponent_5)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 10, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "ion-button", [["class", "discard"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(16, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(17, 0, ["", ""])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "ion-button", [["class", "confirm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(19, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(20, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurrentShiftModalComponent_6)), i1.ɵdid(22, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.submitView; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.submitView; _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.toggleView && _co.jobItem); _ck(_v, 9, 0, currVal_2); var currVal_3 = (!_co.toggleView && _co.jobItem); _ck(_v, 11, 0, currVal_3); var currVal_7 = _co.unChangeView; _ck(_v, 22, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.note; _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.discardText; _ck(_v, 17, 0, currVal_5); var currVal_6 = _co.confirmText; _ck(_v, 20, 0, currVal_6); }); }
export function View_EditRecurrentShiftModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-recurrent-shift-modal", [], null, null, null, View_EditRecurrentShiftModalComponent_0, RenderType_EditRecurrentShiftModalComponent)), i1.ɵdid(1, 114688, null, 0, i18.EditRecurrentShiftModalComponent, [i5.ModalController, i19.LocalStorageService, i20.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditRecurrentShiftModalComponentNgFactory = i1.ɵccf("app-edit-recurrent-shift-modal", i18.EditRecurrentShiftModalComponent, View_EditRecurrentShiftModalComponent_Host_0, { jobItem: "jobItem", userData: "userData", onSuccess: "onSuccess" }, {}, []);
export { EditRecurrentShiftModalComponentNgFactory as EditRecurrentShiftModalComponentNgFactory };
