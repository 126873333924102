import { AddressModificationService } from "src/modules/shared/services/address-modification.service";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/address-modification.service";
export class PersonalInformationValidatorService {
    constructor(addressModificationService) {
        this.addressModificationService = addressModificationService;
    }
    postalCodeValidator() {
        return (control) => {
            const country = control.value.country || 'us';
            const value = control.value.code;
            if (!value || !country) {
                return { code: true };
            }
            if (!this.addressModificationService.isPostalCodeValid(value, country) == true) {
                return { code: true };
            }
            return null;
        };
    }
}
PersonalInformationValidatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PersonalInformationValidatorService_Factory() { return new PersonalInformationValidatorService(i0.ɵɵinject(i1.AddressModificationService)); }, token: PersonalInformationValidatorService, providedIn: "root" });
