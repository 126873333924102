export const getAvailableTransitAllowance = (): Array<{ label: string, value: string }> => {
    const travelAllowance = [
      { label: 'None', value: '0' },
      { label: '1 Hour', value: '1' },
      { label: '2 Hour', value: '2' },
      { label: '3 Hour', value: '3' },
      { label: '4 Hour', value: '4' },
      { label: '5 Hour', value: '5' },
      { label: '6 Hour', value: '6' },
      { label: '7 Hour', value: '7' },
      { label: '8 Hour', value: '8' },
      { label: '9 Hour', value: '9' },
      { label: '10 Hour', value: '10' }
    ];
    return travelAllowance;
  };

  
  export const getAvailableUnpaidBreak = (): Array<{ label: string, value: string }> => {
    const unpaidBreak = [
      { label: 'No Break', value: 'No break' },
      { label: '15 Minutes', value: '15 minutes' },
      { label: '30 Minutes', value: '30 minutes' },
      { label: '45 Minutes', value: '45 minutes' },
      { label: '60 Minutes', value: '1 hour' },
      { label: '120 Minutes', value: '2 hour' },
    ];
    return unpaidBreak;
  };