<!-- <span>{{control.errors | json}}</span> -->
<div class="error" *ngIf="control && control.errors && (control.dirty || control.touched)">
  <div *ngIf="control.errors.required">
    <small>This field is required</small>
  </div>
  <div *ngIf="control.errors.unique">
    <small>{{ control.errors.unique }}</small>
  </div>
  <div *ngIf="control.errors.lessThan">
    <small>{{ control.errors.lessThan }}</small>
  </div>
  <div *ngIf="control.errors.greaterThan">
    <small>{{ control.errors.greaterThan }}</small>
  </div>
  <div *ngIf="control.errors.email">
    <small>{{ control.errors.email }}</small>
  </div>
  <div *ngIf="control.errors.mobile">
    <small>{{ control.errors.mobile }}</small>
  </div>
  <div *ngIf="control.errors.confirmPassword">
    <small>{{ control.errors.confirmPassword }}</small>
  </div>
  <div *ngIf="control.errors.minlength">
    <small>atleaset {{ control.errors.minlength.requiredLength }} characters
      required</small>
  </div>
  <div *ngIf="control.errors.min">
    <small>minimum allowed value {{control.errors.min.min}}</small>
  </div>
  <!-- for custom errors -->
  <div *ngIf="control.errors.message">
    <small>{{control.errors.message}}</small>
  </div>

</div>