import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as talentDashboardActions from '../actions/talent-dashboard.actions';
import { tap, count, exhaustMap, map, catchError,  } from 'rxjs/operators';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { TalentDashboardService } from 'src/modules/talent-dashboard/services/talent-dashboard.service'
import { PopupService } from 'src/modules/shared/services/popup.service'
import { of } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class TalentDashboardEffects {

    constructor(
        private actions$: Actions,
        private localStorageService: LocalStorageService,
        private loadingService: LoadingService,
        private talentDashboardService: TalentDashboardService,
        private popupService: PopupService,


    ) { }
    /////////////////////////////////////////////////
    setTalentDashboardStoreAction$ = createEffect(() => this.actions$.pipe(
        ofType(talentDashboardActions.setTalentDashboardStoreAction),
        tap((action) => this.localStorageService.setTalentUpcomingShifts(action.upcomingShifts))),
        { dispatch: false }
    );

    setFutureShiftsCountStoreAction$ = createEffect(() => this.actions$.pipe(
        ofType(talentDashboardActions.setFutureShiftsCountStoreAction),
        tap((action) => this.localStorageService.setSingleKey('futureUpcomingShiftsCount', action.futureUpcomingShiftsCount))),
        { dispatch: false }
    );

    completeTask$ = createEffect(()=> this.actions$.pipe(
        ofType(talentDashboardActions.completeTaskAction),
        exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action }) ))),
        exhaustMap(({ loader, action }) => 
            this.talentDashboardService.completeTaskRequest(action.jobId, action.taskId, action.patientId)
                .pipe(
                    tap(() => this.loadingService.hideLoading(loader)),
                    map(data => talentDashboardActions.completeTaskSuccessAction(action) ),
                    catchError(error => 
                        {
                        this.loadingService.hideLoading(loader)
                        return of(talentDashboardActions.completeTaskFailureAction({ error }))
                    })
                )
        )
    ));

    completeTaskSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(talentDashboardActions.completeTaskSuccessAction),
        tap(_ => {
            const data = {
                heading: 'Success',
                message: 'Successfully marked task as completed',
                rejectBtnText: '',
                btn: 'Okay',
                navigateRoute: '',
                imgURL: 'assets/images/thumbs-up.png'
              }
              this.popupService.showModal(data);
        } )), { dispatch: false }
    );

    completeTaskFailure$ = createEffect(() => this.actions$.pipe(
        ofType(talentDashboardActions.completeTaskFailureAction),
        tap((action,) => this.popupService.showPopup('Error', 'Something went wrong, try again'))
    ), { dispatch: false });

    saveCareNotes$ = createEffect(()=> this.actions$.pipe(
        ofType(talentDashboardActions.saveCareNotesAction),
        exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action }) ))),
        exhaustMap(({ loader, action }) => 
            this.talentDashboardService.saveCareNotesRequest(action.jobId, action.careNotes,action.patientId)
                .pipe(
                    tap(() => this.loadingService.hideLoading(loader)),
                    map(data => talentDashboardActions.saveCareNotesSuccessAction(action) ),
                    catchError(error => 
                        {
                        this.loadingService.hideLoading(loader)
                        return of(talentDashboardActions.saveCareNotesFailureAction({ error }))
                    })
                )
        )
    ));

    saveCareNotesSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(talentDashboardActions.saveCareNotesSuccessAction),
        tap(_ => {
            const data = {
                heading: 'Success',
                message: 'Care notes saved successfully',
                rejectBtnText: '',
                btn: 'Okay',
                navigateRoute: '',
                imgURL: 'assets/images/thumbs-up.png'
              }
              this.popupService.showModal(data);
        } )), { dispatch: false }
    );

    saveCareNotesFailure$ = createEffect(() => this.actions$.pipe(
        ofType(talentDashboardActions.completeTaskFailureAction),
        tap((action) => this.popupService.showPopup('Error', 'Something went wrong, try again'))
    ), { dispatch: false });

}