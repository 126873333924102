/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recurrent-shift-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../recurrent-shift-list-item/recurrent-shift-list-item.component.ngfactory";
import * as i5 from "../recurrent-shift-list-item/recurrent-shift-list-item.component";
import * as i6 from "../../../company-dashboard/services/broadcast-job-utils.service";
import * as i7 from "../../../talent-dashboard/services/recurrentShift.service";
import * as i8 from "@angular/common";
import * as i9 from "./recurrent-shift-list.component";
var styles_RecurrentShiftListComponent = [i0.styles];
var RenderType_RecurrentShiftListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecurrentShiftListComponent, data: {} });
export { RenderType_RecurrentShiftListComponent as RenderType_RecurrentShiftListComponent };
function View_RecurrentShiftListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-col", [["class", "header"], ["size", "2"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(1, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = "2"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
function View_RecurrentShiftListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recurrent-shift-list-item", [["class", "row-container"]], null, null, null, i4.View_RecurrentShiftListItemComponent_0, i4.RenderType_RecurrentShiftListItemComponent)), i1.ɵdid(1, 114688, null, 0, i5.RecurrentShiftListItemComponent, [i1.ChangeDetectorRef, i6.BroadcastJobsUtilService, i7.RecurrentShiftService], { config: [0, "config"], data: [1, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config; var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_RecurrentShiftListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-row", [["class", "main-container"]], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "header-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecurrentShiftListComponent_1)), i1.ɵdid(4, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(6, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RecurrentShiftListComponent_2)), i1.ɵdid(8, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.data; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_RecurrentShiftListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recurrent-shift-list", [], null, null, null, View_RecurrentShiftListComponent_0, RenderType_RecurrentShiftListComponent)), i1.ɵdid(1, 114688, null, 0, i9.RecurrentShiftListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecurrentShiftListComponentNgFactory = i1.ɵccf("app-recurrent-shift-list", i9.RecurrentShiftListComponent, View_RecurrentShiftListComponent_Host_0, { data: "data", config: "config" }, {}, []);
export { RecurrentShiftListComponentNgFactory as RecurrentShiftListComponentNgFactory };
