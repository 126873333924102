import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
// import { map, filter, pluck } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { getUserInfoAction, logOutAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { Store, select } from '@ngrx/store';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { take } from 'rxjs/operators';
import { NetworkService } from 'src/modules/shared/services/network.service';
import { LanguageService } from 'src/modules/shared/services/language.service';
import { PushNotificationService } from 'src/modules/shared/services/push-notification.service';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { AppInfoService } from 'src/modules/shared/services/app-info.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { AppUpdateService } from './services/app-updates.service';
import { SkillsService } from 'src/modules/shared/services/skills.service';
import { ConfigService } from './services/config.service';
export class AppComponent {
    // splash: boolean = true;
    ////////////////////////////////////
    constructor(platform, languageService, store, statusBar, networkService, pushNotificationService, localStorageService, appInfoService, utilService, appUpdates, skillsService, configService) {
        this.platform = platform;
        this.languageService = languageService;
        this.store = store;
        this.statusBar = statusBar;
        this.networkService = networkService;
        this.pushNotificationService = pushNotificationService;
        this.localStorageService = localStorageService;
        this.appInfoService = appInfoService;
        this.utilService = utilService;
        this.appUpdates = appUpdates;
        this.skillsService = skillsService;
        this.configService = configService;
        this.showDemoBanner = true;
        ////////////////////////////// will be use for side menu ///////////
        this.appPages = [];
        this.isOnline = true;
        this.isDemo = false;
        this.user = this.store.pipe(select(getUserInfo));
        if (~window.location.href.indexOf('app.staffy.com')) {
            // override console on prod
            const console = (oldCons => {
                return {
                    log: text => { },
                    info: text => { },
                    warn: text => { },
                    error: text => { }
                };
            })(window.console);
            window.console = console;
        }
        this.initializeApp();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    initializeApp() {
        this.platform.ready().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.subscription = this.user.subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.userData = result;
            }));
            // this.mobileAccessibility.usePreferredTextZoom(false),
            this.statusBar.styleDefault();
            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#FF4D00');
            this.statusBar.styleLightContent();
            this.networkService.watchForNetwork();
            this.reloadPushWush();
            this.getAppVersionInfo();
            // this.splash = false;
            // this.splashScreen.hide();
            this.languageService.setInitialAppLanguage();
            this.isDemo = this.configService.isDemoMode();
            this.apiUrlSubscription = this.configService.apiUrl$.subscribe(newApiUrl => {
                console.log('API Base URL changed:', newApiUrl);
                // Handle the new API Base URL as needed
                this.isDemo = this.configService.isDemoMode();
                if (this.isDemo)
                    this.showDemoBanner = true;
            });
            this.stripeKeySubscription = this.configService.stripeKey$.subscribe(newStripeKey => {
                console.log('Stripe Key changed:', newStripeKey);
            });
            this.networkService.appNetworkStatus.subscribe(status => {
                if (status === 'online') {
                    this.isOnline = true;
                }
                else {
                    this.isOnline = false;
                }
            });
            this.setDefaultTimeZone();
            // check for app updates
            this.appUpdates.check();
            // check for app store updates
            this.appInfoService.ensureUserHasMinimumRequiredVersion();
        }));
        this.platform.resume.subscribe(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // check for app updates
            this.appUpdates.check();
            //load user data
            // check for app store updates
            this.appInfoService.ensureUserHasMinimumRequiredVersion();
            // fetch update user from backend
            const user = yield this.user.pipe(take(1)).toPromise();
            if (user && user._id) {
                this.store.dispatch(getUserInfoAction());
            }
        }));
    }
    reloadPushWush() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.localStorageService.getLoggedInUserInfo();
            this.pushNotificationService.initPushNotification(user);
        });
    }
    getAppVersionInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.localStorageService.getLoggedInUserInfo();
            if (user) {
                this.appInfoService.checkForAppInfo(user);
            }
        });
    }
    setDefaultTimeZone() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.localStorageService.getLoggedInUserInfo();
            if (user) {
                this.utilService.setTimeZone(user.address.province);
            }
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.configService.revertToDefault();
            yield this.pushNotificationService.removePushNotificationToken();
            this.utilService.clearCashedJobs.next('true');
            if (this.userType === 'employer') {
                this.skillsService.invalidateSkillsCache();
            }
            this.store.dispatch(logOutAction());
        });
    }
    ionViewWillLeave() {
        this.subscription.unsubscribe();
        this.apiUrlSubscription.unsubscribe();
        this.stripeKeySubscription.unsubscribe();
    }
    hideDemoBanner() {
        this.showDemoBanner = false;
    }
    exitDemoMode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.logout();
            this.configService.revertToDefault();
            alert('Exiting Demo Mode');
        });
    }
}
