<div class = "no-shifts">
  <p [innerHTML] = "" class = "text"> {{ noShifts?.text}}</p>
  
  <img [src] = "noShifts?.imageUrl">
  
  <p class = "subText"> {{ noShifts?.subText }} </p>
  
  <div class = "button-container" *ngIf = "noShifts?.buttonText">
    <ion-button class = "button" type="submit" color="basic" (click) = "navigate()">
      <div class = "button-txt">{{ noShifts?.buttonText }}</div>
      <div class = "arrow"><i class = "fas fa-arrow-right"></i></div>
    </ion-button>
  </div>
</div>