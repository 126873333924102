/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-history-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shift-basic-info/shift-basic-info.component.ngfactory";
import * as i3 from "../shift-basic-info/shift-basic-info.component";
import * as i4 from "../../../talent-dashboard/services/talent-job-format.service";
import * as i5 from "../../../company-dashboard/services/broadcast-job-utils.service";
import * as i6 from "../../services/recurrentShift.service";
import * as i7 from "../shift-detail-container/shift-detail-container.component.ngfactory";
import * as i8 from "../shift-detail-container/shift-detail-container.component";
import * as i9 from "../../services/user.service";
import * as i10 from "@ionic/angular";
import * as i11 from "../../services/job.service";
import * as i12 from "../../services/images.service";
import * as i13 from "@angular/common";
import * as i14 from "./shift-history-detail.component";
import * as i15 from "../../services/popup.service";
var styles_ShiftHistoryDetailComponent = [i0.styles];
var RenderType_ShiftHistoryDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftHistoryDetailComponent, data: {} });
export { RenderType_ShiftHistoryDetailComponent as RenderType_ShiftHistoryDetailComponent };
function View_ShiftHistoryDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Past Shifts with "]))], null, null); }
function View_ShiftHistoryDetailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Future Shifts with "]))], null, null); }
function View_ShiftHistoryDetailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userData.entityId.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ShiftHistoryDetailComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contractorPastShifts.length; var currVal_1 = ((_co.contractorPastShifts.length === 1) ? "Shift" : "Shifts"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ShiftHistoryDetailComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "hire"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hireAgain() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "hire-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hire again"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "ellipse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-arrow-right"]], null, null, null, null, null))], null, null); }
function View_ShiftHistoryDetailComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-shift-basic-info", [["class", "equalize-col"]], null, null, null, i2.View_ShiftBasicInfoComponent_0, i2.RenderType_ShiftBasicInfoComponent)), i1.ɵdid(2, 114688, null, 0, i3.ShiftBasicInfoComponent, [i4.TalentJobFormatService, i5.BroadcastJobsUtilService, i6.RecurrentShiftService], { jobItem: [0, "jobItem"], unitNumber: [1, "unitNumber"], readOnly: [2, "readOnly"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "under-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "mont-bold small-font bottom-10 applicant-card remove-margin"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ":"])), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "applicant-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "contractor-detail-card bottom-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-shift-detail-container", [], null, null, null, i7.View_ShiftDetailContainerComponent_0, i7.RenderType_ShiftDetailContainerComponent)), i1.ɵdid(10, 114688, null, 0, i8.ShiftDetailContainerComponent, [i9.UserService, i10.ModalController, i11.JobService, i4.TalentJobFormatService, i12.ImagesService], { staffer: [0, "staffer"], userData: [1, "userData"], viewingHistory: [2, "viewingHistory"], viewingHired: [3, "viewingHired"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "button-stack"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "reject mont-regular"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addToTeam(_co.staffer.staffer._id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add to a team "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftHistoryDetailComponent_7)), i1.ɵdid(15, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = _co.unitNumber; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.staffer; var currVal_5 = _co.userData; var currVal_6 = true; var currVal_7 = true; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = (_co.staffer.status === "interested"); _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.shiftStatus; _ck(_v, 6, 0, currVal_3); }); }
function View_ShiftHistoryDetailComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftHistoryDetailComponent_6)), i1.ɵdid(2, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contractorPastShifts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ShiftHistoryDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "history"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftHistoryDetailComponent_1)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftHistoryDetailComponent_2)), i1.ɵdid(7, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftHistoryDetailComponent_3)), i1.ɵdid(9, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftHistoryDetailComponent_4)), i1.ɵdid(11, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "timings"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftHistoryDetailComponent_5)), i1.ɵdid(14, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.history; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.history; _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.userData == null) ? null : ((_co.userData.entityId == null) ? null : _co.userData.entityId.name)); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.contractorPastShifts; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.contractorPastShifts; _ck(_v, 14, 0, currVal_4); }, null); }
export function View_ShiftHistoryDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shift-history-detail", [], null, null, null, View_ShiftHistoryDetailComponent_0, RenderType_ShiftHistoryDetailComponent)), i1.ɵdid(1, 114688, null, 0, i14.ShiftHistoryDetailComponent, [i15.PopupService, i10.ModalController, i11.JobService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShiftHistoryDetailComponentNgFactory = i1.ɵccf("app-shift-history-detail", i14.ShiftHistoryDetailComponent, View_ShiftHistoryDetailComponent_Host_0, { userData: "userData", contractorPastShifts: "contractorPastShifts", history: "history", staffer: "staffer", shiftStatus: "shiftStatus" }, {}, []);
export { ShiftHistoryDetailComponentNgFactory as ShiftHistoryDetailComponentNgFactory };
