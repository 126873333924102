import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { JobService } from '../../services/job.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-contractor-recurrent-shift',
  templateUrl: './contractor-recurrent-shift.component.html',
  styleUrls: ['./contractor-recurrent-shift.component.scss'],
})
export class ContractorRecurrentShiftComponent implements OnInit {

 @Input() groupJobId: string;
 @Input() entityId: string;

  shifts = []
  confirmationView :Boolean = true

  config = ["Date", "Start Time", "End Time"]

  description = "You are applying to all the upcoming shifts.";
  title= "This is a recurring shift"


  constructor(private modalController: ModalController, private jobService: JobService, 
    private router : Router,
    private loadingService: LoadingService) { }

   ngOnInit() {
    this.fetchRecurringShifts()
  }

  closeModal(): void {
    this.modalController.dismiss();
  }

  confirmShift(){
    this.title = "Your recurring shift has been confirmed!"
    this.confirmationView = false;
  }

  navigateToUpcomingShifts(){
    this.closeModal()
    this.router.navigate(['/talent-dashboard/upcoming-shifts']);
  }

  async fetchRecurringShifts() {
    const loading = await this.loadingService.showLoading().toPromise();

    try {

      let {jobs} = await this.jobService.getRecurringShiftsV2(this.groupJobId);

      if (jobs && jobs.length) {
        this.shifts = jobs.map(item => {
          return {
            Date: moment(item.shiftDate).tz(item.timeZone).format('DD MMM'),
            'Start Time': moment(item.shiftStartTime, 'X').tz(item.timeZone).format('hh:mm A'),
            'End Time': moment(item.shiftEndTime, 'X').tz(item.timeZone).format('hh:mm A'),
            'Unpaid Break': item.break ? item.break.unpaid : "No break",
            'Transit Allowance': item.travelTime.toString(),
          }
        })
      }

    } catch (error) {
      console.log(error);
    } finally {
      await this.loadingService.hideLoading(loading);
    }

  }

}
