<div class = "pagination">
  <div class = "showing-numbers right-20">
    <label for = "pageSize">Showing: </label>
    <select id = "pageSize" [(ngModel)] = "pagination.pageSize" (change) = "changePageSize()">
      <option *ngFor="let option of pagination.pageSizeOptions" [ngValue]="option">{{ option }}</option>
    </select>
  </div>
  

  <div class = "total-items mont-bold right-20" *ngIf = "totalRecords && totalRecords > 0">
    {{ minCurrentPageItems() }}
    of {{ totalRecords }}
  </div>

  <div class = "pageList right-20">
    <button [disabled]="pagination.currentPage == 1" (click)="goToPrevious()"><i class = "fa fa-chevron-left"></i></button>
      <div *ngFor="let page of getPages()" class = "currentPage" [class.active]="page === pagination.currentPage">
        <a (click)="goToPage(page)">{{page}}</a>
      </div>
      <button [disabled]="pagination.currentPage == getTotalPages()" (click)="goToNext()"><i class = "fa fa-chevron-right"></i></button>
  </div>
</div>