import { EventEmitter, Component, Input, Output } from '@angular/core';
import { ITalentJob } from 'src/modules/talent-dashboard/interfaces/talent-job.interface';
import { ExtendedEvent } from '../../interfaces/extended-event.interface';
import { CalendarFormattingService } from '../../services/calendar-formatting.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar-events',
  templateUrl: './calendar-events.component.html',
  styleUrls: ['./calendar-events.component.scss'],
})
export class CalendarEventsComponent {

  @Input() extendedProps: ITalentJob & ExtendedEvent;
  @Input() direction: string;
  @Output() hidePopup = new EventEmitter();
  maxSliceCount: number = 7;
  
  constructor(private calendarFormattingService: CalendarFormattingService,
    private router: Router
  ) { }

  
  formatJobStatus(jobStatus: string) {
    //Update job status text from partial to partially filled
    if(!jobStatus.includes('partial')) {
      return jobStatus;
    }
    return 'partially filled';
  }
  
  navigateToRequestView(jobStatus: string) {
    const isFilled = this.extendedProps.isFilled || false;

    //If shift is upcoming, compute their navigation path according to their job status 
    if(!this.extendedProps['isPreviousShift']) {
      const navigateObj = this.calendarFormattingService.navigationPathUrlByState(jobStatus, isFilled);
      this.router.navigateByUrl(navigateObj.url);
      return;
    } 

    //Redirect client to previous contractor screen if the shift has passed
    if(jobStatus !== 'open') {
      this.router.navigateByUrl('company-dashboard/previous-staffies');
      return;
    }

    return;
  }

  displayEventIcon(jobStatus: string): string {
    return this.calendarFormattingService.eventIconsByJobStatus(jobStatus) || 'fas fa-door-open icon';
  }

  viewDetailsButton(): string {
    return this.calendarFormattingService.viewDetailsButton(this.extendedProps['jobStatus'], this.extendedProps.isFilled);
  }
  
  closePopup() {
    this.hidePopup.emit()
  }

  renderStafferList(): string {
    const formattedStaffersList = this.calendarFormattingService.formatStaffersList(this.extendedProps['hiredStaffers']);
    return `<div class = "event-staffer-list">${this.calendarFormattingService.renderStafferList(formattedStaffersList)}</div>`
  }
}
