import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { UserTypeEnum } from 'src/modules/authentication/enums/user-type.enum';
import { onboardingEnumsToRouteMap } from 'src/modules/shared/utils/helpers/onboarding-steps-route-map';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(store, router) {
        this.store = store;
        this.router = router;
    }
    canActivateChild(childRoute, state) {
        return combineLatest([
            this.store.pipe(select(getUserInfo)),
        ])
            .pipe(take(1))
            .pipe(map((data) => ({ user: data[0] })), mergeMap(({ user }) => {
            if (!this._isUserLoggedIn(user, state, childRoute)) {
                return of(true);
            }
            // User is Logged In
            // determine the next route and return 
            return this._determindNextRoute(user);
        }));
    }
    _isUserLoggedIn(user, routerState, route) {
        return (user && !(routerState.url.includes('auth/login') && route.queryParams.token));
    }
    _determindNextRoute(user) {
        // User is Logged In
        // if the user is a staffer and has completed onboarding
        if (user.type === UserTypeEnum.STAFFER) {
            // Redirect to dashboard
            if (user.profile_approved) {
                return of(this.router.parseUrl('/talent-dashboard'));
            }
            console.log(user.currentNavStep);
            // profile is not approved, redirect user back the last navigated step
            const lastRoute = onboardingEnumsToRouteMap[user.currentNavStep] || '/onboarding-staffer/expertise';
            return of(this.router.parseUrl(lastRoute));
        }
        // if the user is a staffer and hasn't completed onboarding
        if (user.type === UserTypeEnum.EMPLOYER) {
            // Redirect to dashboard
            return of(this.router.parseUrl('/company-dashboard'));
        }
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
