import { Component, OnInit, Input } from '@angular/core';
import { sortBy, forEach } from 'lodash';
import { ModalController } from '@ionic/angular';
import { IViewCert } from 'src/modules/shared/interfaces/certificate.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { HelpService } from '../../pages/help/services/help.service';


@Component({
  selector: 'app-view-certificate-modal',
  templateUrl: './view-certificate-modal.component.html',
  styleUrls: ['./view-certificate-modal.component.scss'],
})
export class ViewCertificateModalComponent implements OnInit {
	
  currentIndex : number = 0;		
  @Input() approvedCertificates: IViewCert[] = [];	
  @Input() isCOVID: boolean;  
  viewer : string = 'google';
  doc = '';
  isCertificateDOC : boolean = false;
  isCertificatePDF : boolean = false;
  isCertificateImage : boolean = false;
  sanitizeURL : string = '';
  showLoading = true;
  
  constructor(
  	private modalController: ModalController,
    private popupService: PopupService,
    private sanitizer: DomSanitizer,
    private helpService: HelpService
  ) { }

  ngOnInit() {

    if(this.approvedCertificates.length > 0) {
        this.approvedCertificates = sortBy(this.approvedCertificates, (element) => {
          const rank = {
              'Food Handler Certified' : 1,
              'Smart Serve Certified' : 2,
              'CPR' : 3,
              'Immunization Record' : 4,
              'Current Flu Vaccination' : 5,
              'TB Testing' : 6,
              'Vulnerable Sector Check' : 7,
              'N95' : 8,
              'Nursing License/Certification' : 9,
              'Other' : 10,
              'Food Service/Safe Certification' : 11,
              'Healthcare Aid Certification/Diploma' : 12,
              'OPSWA Membership' : 13,
              'COVID-19 Vaccination' : 14,
              'CPI': 15
            }

            return rank[element.type];
        }) as any;

        forEach(this.approvedCertificates, (cert) => {
            if(cert.url.includes('http:')) {
              cert.url = cert.url.replace('http:', 'https:');
            }

            let extension = cert.url.substring(cert.url.lastIndexOf('.'));
            if(extension == '.pdf') {
              cert.isCertificatePDF = true;
              cert.sanitizeURL = this.sanitizer.bypassSecurityTrustResourceUrl(cert.url);
            }

            else if(extension === '.docx' || extension === '.doc' || extension === '.txt') {
              cert.isCertificateDOC = true;
              cert.sanitizeURL = this.sanitizer.bypassSecurityTrustResourceUrl(cert.url);
            } else if(extension === '.heic') {
              cert.url = cert.url.replace('.heic', '.jpg');
              cert.isCertificateImage = true;
            } else {
              cert.isCertificateImage = true;
            }
        })
    }
  }

  textLayerRendered(e: CustomEvent) {
      this.showLoading = false;
    }

    onError(error: any) {
      this.showLoading = false;
      this.popupService.showModal({ 
        heading: 'Error', 
        message: 'Failed to load document. Please click on link to view credential file.', 
        btn: 'Dismiss', 
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png' 
      });

    }

    onProgress(certificateProgress: any) {
      this.showLoading = true;
    }

	
    showNextCertificate() {
      this.currentIndex = this.currentIndex + 1;
    }

    showPreviousCertificate() {
      this.currentIndex = this.currentIndex - 1;
    }

    
    terminateCertificatePopup() {
      this.modalController.dismiss();
    }

    openLink(url: string) {
      this.helpService.openAppBrowserLink(url);
    }
}
