import * as moment from "moment";
import * as i0 from "@angular/core";
export class RecurrentShiftService {
    constructor() { }
    unitNumberByShift(jobItem) {
        let unitNumber = '';
        const isRecurring = jobItem[0] && jobItem[0].recurringShift && jobItem[0].recurringShift.isTrue ? jobItem[0].recurringShift.isTrue : false;
        //If Shift is recurring fetch unit numbers from jobs array
        if (isRecurring) {
            //If any unit number exists in recurring job, return the unit number else return empty string
            const jobUnitNumber = jobItem.findIndex(o => o.unitNumber !== undefined);
            if (jobUnitNumber > -1) {
                unitNumber = jobItem[jobUnitNumber].unitNumber;
            }
        }
        else {
            unitNumber = jobItem[0].unitNumber || '';
        }
        return unitNumber;
    }
    groupJobsByDate(broadcastedJobs) {
        // Initialize an empty object to store the grouped jobs
        let dates = {};
        // Initialize an array to keep track of dates to combine
        let datesToCombine = [];
        // Iterate over each job in the broadcastedJobs array for grouping
        broadcastedJobs.forEach((jobs) => {
            if (!jobs.recurringShift.isTrue) {
                // For non-recurring shifts (single shifts)
                // Get the date key for the shift's start time in the specified time zone
                const key = moment.unix(jobs.shiftStartTime).tz(jobs.timeZone).format('D MMMM YYYY');
                // Find the index of the key in datesToCombine array
                const index = datesToCombine.findIndex((index) => { return index === key; });
                // If the key is not found, add it to datesToCombine array and initialize the corresponding entry in dates object
                if (index < 0) {
                    datesToCombine.push(key);
                    dates[key] = { shift: [[Object.assign({}, jobs, jobs)]] };
                }
                else {
                    // If the key is found, append the job to the corresponding shift array in dates object
                    dates[key].shift.push([Object.assign({}, jobs, jobs)]);
                }
            }
            else {
                // For recurring shifts
                // Iterate over each shift within the recurring job
                jobs.jobs.forEach((shift) => {
                    // Get the date key for the shift's start time in the specified time zone
                    const key = moment.unix(shift.shiftStartTime).tz(shift.timeZone).format('D MMMM YYYY');
                    // Find the index of the key in datesToCombine array
                    const index = datesToCombine.findIndex((index) => { return index === key; });
                    if (index < 0) {
                        // If the key is not found, add it to datesToCombine array and initialize the corresponding entry in dates object
                        datesToCombine.push(key);
                        dates[key] = { shift: [jobs.jobs.map(item => {
                                    let jobObject = jobs;
                                    return Object.assign({}, jobObject, item, { unitNumber: item.unitNumber || "" });
                                })] };
                        if (jobs.recurringShift && jobs.recurringShift.groupJobId && jobs.recurringShift.groupShift.recurrentShiftTemplateId) {
                            dates[key].shift[0]['recurrentShiftTemplateId'] = jobs.recurringShift.groupShift.recurrentShiftTemplateId;
                        }
                    }
                    else {
                        // If the key is found, append the job to the corresponding shift array in dates object
                        dates[key].shift.push(jobs.jobs.map(item => {
                            let jobObject = jobs;
                            return Object.assign({}, jobObject, item, { unitNumber: item.unitNumber || "" });
                        }));
                        if (jobs.recurringShift && jobs.recurringShift.groupJobId && jobs.recurringShift.groupShift.recurrentShiftTemplateId) {
                            dates[key].shift[dates[key].shift.length - 1]['recurrentShiftTemplateId'] = jobs.recurringShift.groupShift.recurrentShiftTemplateId;
                        }
                    }
                });
            }
        });
        // return grouped jobs and datesToCombine array
        return this.sortKeysByDates(dates);
    }
    sortKeysByDates(dates) {
        if (!dates) {
            return;
        }
        //Convert the keys to Date objects using Moment.js
        const dateKeys = Object.keys(dates).map(key => moment(key, 'D MMMM YYYY'));
        //Sort the keys by dates
        dateKeys.sort((dateA, dateB) => moment(dateA, 'D MMM').diff(moment(dateB, 'D MMM')));
        // Step 3: Create a new object with the sorted keys
        const sortedObj = {};
        for (const date of dateKeys) {
            const formattedDate = date.format('D MMMM YYYY');
            sortedObj[formattedDate] = dates[formattedDate];
        }
        return sortedObj;
    }
    formatShiftInterval(recurrentShiftTemplateId) {
        if (recurrentShiftTemplateId.intervalType !== 'Weekly') {
            return 'Daily';
        }
        const dateRange = 'Every ' + this.addNumberSuffix(recurrentShiftTemplateId.interval) + ' week ';
        return dateRange;
    }
    addNumberSuffix(interval) {
        //If interval is a string, convert it into number
        if (typeof interval !== 'number' || isNaN(interval)) {
            interval = parseInt(interval.toString());
        }
        const suffixes = ['st', 'nd', 'rd', 'th'];
        return interval + suffixes[(interval % 10) - 1];
    }
    convertDaysToShortFormat(days) {
        if (days.length === 1) {
            return days[0] + 's';
        }
        const shortDays = days.map(day => moment(day, 'dddd').format('ddd'));
        if (shortDays.length === 2) {
            return shortDays.join(' & ');
        }
        else if (shortDays.length > 2) {
            const lastDay = shortDays.pop();
            return shortDays.join(', ') + ' & ' + lastDay;
        }
        return '';
    }
    shiftInformation(jobItem) {
        if (jobItem.recurrentShiftTemplateId.intervalType !== 'Weekly') {
            return (jobItem.length - 1) + ' days before it ends it will automatically renew for another 6 months.';
        }
        if (jobItem.recurrentShiftTemplateId.intervalType === 'Weekly') {
            const frequency = jobItem.recurrentShiftTemplateId.interval + (jobItem.recurrentShiftTemplateId.interval > 1 ? ' Weeks' : ' Week');
            return frequency + ', ' + (jobItem.length) + ' days before it ends it will automatically renew for another 6 months.';
        }
    }
}
RecurrentShiftService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecurrentShiftService_Factory() { return new RecurrentShiftService(); }, token: RecurrentShiftService, providedIn: "root" });
