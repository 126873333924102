/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phone-number-template.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./phone-number-template.component";
var styles_PhoneNumberTemplateComponent = [i0.styles];
var RenderType_PhoneNumberTemplateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoneNumberTemplateComponent, data: {} });
export { RenderType_PhoneNumberTemplateComponent as RenderType_PhoneNumberTemplateComponent };
export function View_PhoneNumberTemplateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" eg: XXXXXXXXXX\n"]))], null, null); }
export function View_PhoneNumberTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-phone-number-template", [], null, null, null, View_PhoneNumberTemplateComponent_0, RenderType_PhoneNumberTemplateComponent)), i1.ɵdid(1, 114688, null, 0, i2.PhoneNumberTemplateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhoneNumberTemplateComponentNgFactory = i1.ɵccf("shared-phone-number-template", i2.PhoneNumberTemplateComponent, View_PhoneNumberTemplateComponent_Host_0, {}, {}, []);
export { PhoneNumberTemplateComponentNgFactory as PhoneNumberTemplateComponentNgFactory };
