import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-copy-popover',
    templateUrl: './copy-popover.component.html',
    styleUrls: ['./copy-popover.component.scss'],
})

export class CopyPopoverComponent implements OnChanges  {
    @Input() text: string;
    @Input() showPopover: boolean = false;
    @Input() copiedToClipBoard : boolean = false;
    showCopiedText : boolean = false;

    copiedText = "Copied!"

    ngOnChanges(changes){
        
        if(changes.copiedToClipBoard && changes.copiedToClipBoard.currentValue){
            this.copyToClipboard()
        }

        if(changes.copiedToClipBoard && !changes.copiedToClipBoard.currentValue){
            this.showCopiedText = false
            this.copiedToClipBoard = false 
        }

        if(changes.showPopover && !changes.showPopover.currentValue){
            this.showCopiedText = false
            this.copiedToClipBoard = false 
        }

        
    }
    
    copyToClipboard() {
            navigator.clipboard.writeText(this.text);
            this.showCopiedText = true; 
    
    }
}
