import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { talentDashboardstate } from './talent-dashboard.state';
import { EffectsModule } from '@ngrx/effects';
import { talentDashboardReducer } from './reducers/talent-dashboard.reducers';
import { TalentDashboardEffects } from './effects/talent-dashboard.effects';



@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('talent-dashboard',
      talentDashboardReducer,
      { initialState: talentDashboardstate }),
    EffectsModule.forFeature([TalentDashboardEffects])
  ],
  exports: [StoreModule, EffectsModule]
})
export class TalentDashboardStoreModule { }
