import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shared-phone-number-template',
  templateUrl: './phone-number-template.component.html',
  styleUrls: ['./phone-number-template.component.scss'],
})
export class PhoneNumberTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
