import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isString } from 'lodash';
import { IAuthState } from './auth.state';
import { UtilService } from 'src/modules/shared/services/util.services';
import { from, observable, Observable, of } from 'rxjs';

export const selectAuth = createFeatureSelector<IAuthState>('auth');

export const getAuthLoading = createSelector(selectAuth, state => state.isLoading);

export const getUserInfo = createSelector(selectAuth, state => state && state.user);

export const getLoggedInUserToken = createSelector(selectAuth, state => state && state.token);

export const hasUserCompletedIntroSteps = createSelector(selectAuth, state => state && state.hasUserCompletedIntroductorySteps);

export const getUserIdFromInfo = createSelector(getUserInfo, user => user && user._id);

export const getUserAddress = createSelector(getUserInfo, user => user && user.address);
export const getUserCity = createSelector(getUserAddress, address => address && address.city);

export const getNotificationCount = createSelector(selectAuth, state => state && state.notificationCount);

export const getShiftInProgress = createSelector(selectAuth, state => state && state.shiftInProgress);

export const getAvailableShift = createSelector(selectAuth, state => state && state.availableShift);

export const getAppSettings = createSelector(selectAuth, state => state && state.settings);

export const getUserQuizzes = createSelector(selectAuth, state => state && state.user.quizzes);

export const isQuizSuccessfull = createSelector(selectAuth, state => state && state.user.isQuizSuccessful);

// ONBOARDING DASHBOARD SELECTORS

//check if contractor has provided required personal information
export const hasContractorProvidedPersonalInformation = createSelector(getUserInfo, user => {
    return (
        user && 
        isStringDefined(user.firstName) && 
        isStringDefined(user.lastName) && 
        isStringDefined(user.phoneNumber) && 
        isStringDefined(user.address) && 
        isStringDefined(user.email) && 
        isStringDefined(user.address.street) && 
        isStringDefined(user.address.code) && 
        isStringDefined(user.address.type) && 
        isSuiteNumberRequired(user.address) && 
        isStringDefined(user.profileUrl)
    )
});

//check if contractor has signed contract agreement
export const hasContractorProvidedAgreement = createSelector(getUserInfo, user => {
    if(!user){
        return false;
    }
    return (user && 
        isStringDefined(user.contractSignedDates.length > 0))
});

//check if contractor has provided all the required professional informatio
export const hasContractorProvidedProfessionalInformation = createSelector(getUserInfo, user => {

    if(!user){
        return false;
    }

    return (user && 
        isStringDefined(user.resumeUrl) && 
        isStringDefined(user.bio))  
});

//check if contractor has selected atleast one skill
export const hasContractorProvidedSkills = createSelector(getUserInfo, user => {

    if(!user){
        return false;
    }

    return (user && 
        user.skills.length > 0)
});


//check if contractor has selected atleast one skill
export const hasContractorProvidedReferences = createSelector(getUserInfo, user => {
    console.log('user: ', user);
    if(!user){
        return false;
    }

    return (user && 
        user.references.length >= 3)
});

//check if contractor does not have any rejected certificate
export const hasContractorProvidedCertificates = createSelector(getUserInfo, user => {

    if(!user){
        return false;
    }

    return (user && 
        isString(user.certifications))
});


//check if contractor has completed vss attestation
export const hasContractorProvidedAttestation = createSelector(getUserInfo, user => {

    if(!user){
        return false;
    }

    return user && 
    user.isVSSAndImmunizationAttested
})  

//check if contractor has added background check
export const hasContractorProvidedBackgroundCheck = createSelector(getUserInfo, user => {

    if(!user){
        return false;
    }

    return (user && 
        isString(user.certnApplicantId)) ||
        user.policeVerified
});

//check if contractor has passed quiz
export const hasContractorProvidedQuiz = createSelector(getUserInfo, user => {

    if(!user){
        return false;
    }

    return user && user.isQuizSuccessful
})

export const hasContractorAvailedAllQuizAttempts = createSelector(getUserInfo, user => {

    if(!user){
        return false;
    }
    
    return user && !user.isQuizSuccessful && user.quizzes.length >= 3
})

export const hasContractorbyPassVSCFlow = createSelector(getUserInfo, user => {
    if(!user) {
        return false;
    }

    return user && user.byPassVSCFlow;
})


const isSuiteNumberRequired = (address): boolean => {
    //return true if address type is home
    if(address.type === 'home'){
        return true
    }

    //return true if address type is not home and suiteNumber is entered
    if(address.type !== 'home' && address.suiteNumber){
        return true
    }
    // address type is not home or suite number is not entered
    return false;
}




const isStringDefined = (str): boolean => {
    if(!str || str === '' || str === undefined || str === null || typeof str === "undefined"){
        // not defined
        return false
    }
    // defined
    return true;
}

