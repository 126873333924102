import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-app-update-progress',
  templateUrl: './app-update-progress.component.html',
  styleUrls: ['./app-update-progress.component.scss']
})
export class AppUpdateProgressComponent implements OnInit {
  @Input() progress: Observable<number>;
  progressValue = 0;

  constructor() {}

  ngOnInit() {
    this.progress.subscribe(progress => {
      this.progressValue = progress / 100;
    });
  }
}
