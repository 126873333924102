import { Injectable } from '@angular/core';
// import { AngularFireAnalytics } from '@angular/fire/analytics';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private firebaseAnalytics: FirebaseAnalytics,
    // private angularFireAnalytics: AngularFireAnalytics,
    private platform: Platform
  ) {}

  params = {
    category: '',
    action: '',
    label: '',
    user_industry: ''
  };


  setUserId(id: string) {
    this.firebaseAnalytics.setUserId(id);
  }

  setUserProperty(name: string, value: string) {
  	this.firebaseAnalytics.setUserProperty(name, value);
  }

  trackEvent(eventName, category, action, label, userIndustry) {
    this.params.category = category;
    this.params.action = action;
    this.params.label = label;
    this.params.user_industry = userIndustry;
    
    // assign ionic native firebase analaytics for android and ios
    // when running on the web browser use angular fire
    // const fireBaseAnalyticsService = this.pluginSupportedByDevice() ? this.firebaseAnalytics : this.angularFireAnalytics;
    
    if(this.pluginSupportedByDevice()){

      this.firebaseAnalytics
      .logEvent(eventName, this.params)
      .then((res: any) => {
        console.log(res);
      })
      .catch((error: any) => {
        console.log('not supported');
      });
    }
  }

  pluginSupportedByDevice() {
    const isIOS = this.platform.is('ios');
    const isAndroid = this.platform.is('android');
    const isIPad = this.platform.is('ipad');
    return isIOS || isAndroid || isIPad;
  }
}
