import { Component, OnInit, HostListener, Input, Output } from '@angular/core';
import { WebcamInitError, WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { Platform, ModalController } from '@ionic/angular';
import { dataURLtoFile } from 'src/modules/shared/utils/helpers/images.helper';
import { ImagesService } from 'src/modules/shared/services/images.service';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'shared-choose-picture-modal',
  templateUrl: './choose-picture-modal.component.html',
  styleUrls: ['./choose-picture-modal.component.scss'],
})
export class ChoosePictureModalComponent implements OnInit {

  isCordova: boolean;
  isBrowser: boolean;
  selectedFile: ImageSnippet;
  @Input() selectImage: boolean = false;
  @Input() type: string;
  
  ///////////////////////////////////////
  public width: number;
  public errors: WebcamInitError[] = [];
  private trigger: Subject<void> = new Subject<void>();

  constructor(
    private platform: Platform,
    private imagesService: ImagesService,
    private modalController: ModalController,
    private talentJobFormatService: TalentJobFormatService

  ) {

  }


  ngOnInit() {
    this.isBrowser = this.talentJobFormatService.isBrowserCheck();
    this.isCordova = this.platform.is('cordova');
    this.onResize();

    if(this.isCordova && !this.isBrowser && this.type == 'staffer')  {
      this.takePhoto('CAMERA');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    const win = !!event ? (event.target as Window) : window;
    this.width = win.innerWidth;
  }
  ///////////////////////////////////////////////////// for browser

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      const formData = new FormData();
      formData.append('images', this.selectedFile.file);
      this.modalController.dismiss({ formData });
    };

    reader.readAsDataURL(file);
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }
  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  handleImage(webcamImage: WebcamImage): void {
    const file = dataURLtoFile(webcamImage.imageAsDataUrl, 'image.png');
    
    var fd = new FormData();
    fd.append('images', file);
  
    // Create a test FormData object
    this.modalController.dismiss({ fd });

  }

  /////////////////////////////////////////////////// for mobile

  takePhoto(sourceType: 'CAMERA' | 'GALLERY') {
    this.platform.ready().then(async () => {
      const filePath = await this.imagesService.choosePicture(sourceType).toPromise();
      this.modalController.dismiss({ filePath, sourceType });

    });
  }

}
