import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
export class LoggedInGuard {
    constructor(store, router) {
        this.store = store;
        this.router = router;
    }
    canActivateChild(childRoute, state) {
        return this.store
            .pipe(select(getUserInfo))
            .pipe(take(1))
            .pipe(mergeMap(user => {
            if (user) {
                return of(true);
            }
            // User is not Logged In
            return of(this.router.parseUrl('/auth'));
        }));
    }
}
LoggedInGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggedInGuard_Factory() { return new LoggedInGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router)); }, token: LoggedInGuard, providedIn: "root" });
