import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocalStorageService } from '../../services/local-storage.service';
import { UtilService } from '../../services/util.services';
export class EditRecurrentShiftModalComponent {
    constructor(modalController, localStorageService, utilService) {
        this.modalController = modalController;
        this.localStorageService = localStorageService;
        this.utilService = utilService;
        this.data = {
            leftText: 'Change full recurrence',
            rightText: 'Change specific shifts'
        };
        this.headerText = 'Edit recurrence';
        this.submitView = false;
        this.unChangeView = false;
        this.toggleView = false;
        this.discardText = 'Go back';
        this.confirmText = 'Save';
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const province = this.userData.entityId.address.province || 'America/Toronto';
            this.note = `<span class = "mont-bold italic">Important note: </span><span class = "mont-regular italic">If you make any changes that affect shifts starting in the next 3 hours, cancellation fees may apply.</span>`;
            this.timeZone = (yield this.localStorageService.getUserTimeZone()) || this.utilService.getTimezone(province);
        });
    }
    onRecurrentToggleChange(value) {
        this.toggleView = value;
    }
    onSave() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.unChangeView = false;
            if (this.submitView && this.toggleView) {
                yield this.editSpecificShift.onSubmit(this.onSuccess);
                this.modalController.dismiss();
            }
            if (this.toggleView) {
                if ((yield this.editSpecificShift.trackShiftPropertyChanges()).length) {
                    this.submitView = true;
                    this.discardText = "Discard",
                        this.confirmText = "Yes, proceed";
                    yield this.editSpecificShift.trackShiftPropertyChanges(true);
                }
                else {
                    this.unChangeView = true;
                }
            }
            else {
                this.modalController.dismiss({ isFullRecurrence: this.editFullRecurrence.isFullRecurrence, payload: this.editFullRecurrence.recurrentForm, onSubmit: this.editFullRecurrence.onSubmit.bind(this.editFullRecurrence, this.onSuccess) });
            }
        });
    }
    dismiss() {
        this.modalController.dismiss();
    }
}
