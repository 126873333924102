<div class = "address-list">
  <i (click)="dismiss()" class="fa fa-times"></i>
  <div class = "address-header">
    Shift with multiple addresses
  </div>
  
  <div class = "address-body">
    <ion-list>
      <div *ngFor = "let shiftData of addressAndTasksList; let i = index">
        <div class = "address-container">
          <ion-item>
            <span class = "address-count">{{ i + 1}}.</span>{{shiftData.address}}
          </ion-item>
        </div>
        <div *ngIf = "shiftData?.tasks?.length" class= "task-container">
          <div class = "task-header">Tasks</div>
          <ion-item>
            <div class = "task-body">
              <div *ngFor = "let task of shiftData?.tasks" class = "task-capsulated">
                <div class = "task-name">{{ task }}</div>
              </div>
            </div>
          </ion-item>
        </div>
      </div>
    </ion-list>
  </div>

  <div class = "address-footer">
    <ion-button color = "basic" (click) = "dismiss()">Close</ion-button>
  </div>
</div>