import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-country-phone-numbers',
  templateUrl: './country-phone-numbers.component.html',
  styleUrls: ['./country-phone-numbers.component.scss'],
})
export class CountryPhoneNumbersComponent implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() { }

  selected(country: string) {
    this.modalController.dismiss({
      country
    });
  }

}
