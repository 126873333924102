import { Component, Input, OnInit } from '@angular/core';
import { RecurrentShiftService } from '../../services/recurrentShift.service';

@Component({
  selector: 'app-shift-tag',
  templateUrl: './shift-tag.component.html',
  styleUrls: ['./shift-tag.component.scss'],
})
export class ShiftTagComponent implements OnInit {

  @Input() jobItem: any;
  @Input() onlyRecurrentJobs: boolean;
  shiftInterval;
  shiftIntervalDays;
  shiftInformation;

  showTrainingStyle: boolean;
  
  constructor(
    private recurrentShiftService: RecurrentShiftService
  ) { }

  ngOnInit() {
    
    if(!this.jobItem || !this.jobItem[0]) {
      return;
    }

    this.showTrainingShiftStyling();

    //If the shift is not recurrent then return by marking it as training or not. Do not calculate interval messade
    if(!this.jobItem.recurrentShiftTemplateId) {
      return
    } 

    this.shiftInterval = this.recurrentShiftService.formatShiftInterval(this.jobItem.recurrentShiftTemplateId);
    this.shiftIntervalDays = this.recurrentShiftService.convertDaysToShortFormat(this.jobItem.recurrentShiftTemplateId.days);
    this.shiftInformation = this.recurrentShiftService.shiftInformation(this.jobItem);
  }


  showTrainingShiftStyling() {
    //Check if non recurring or recurring shift is a training shift
    //If true, apply training shift CSS (Show orange outline), otherwise apply regular CSS
    this.showTrainingStyle =  this.jobItem[0].isTrainingShift;
  }

}
