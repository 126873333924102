import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StarRatingComponent } from 'ng-starrating';
import { UtilService } from '../../services/util.services';

@Component({
  selector: 'shared-staffer-rating-popup',
  templateUrl: './staffer-rating-popup.component.html',
  styleUrls: ['./staffer-rating-popup.component.scss'],
})
export class StafferRatingPopupComponent implements OnInit {

  @Input() data: { name: string, message: string, };
  name: string;
  message: string;
  rating: number;
  isMobile: boolean;
  constructor(
    private modalController: ModalController,
    private utilService: UtilService
  ) {
    this.isMobile = this.utilService.isMobile();

  }

  ngOnInit() {
    this.name = this.data.name;
    this.message = this.data.message;
  }
  onRate(event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    if (event.newValue) {
      this.rating = event.newValue;
    }
  }

  submit() {
    this.modalController.dismiss({
      rating: this.rating
    });
  }

  cancel() {
    this.modalController.dismiss({
      rating: null
    });
  }
}
