<ion-row class="main-container">
  <div class="header-container">
    <ion-col class="header" size="2" *ngFor="let item of config">
      {{item}}
    </ion-col>
  </div>
</ion-row>

<ion-row >
  <app-recurrent-shift-list-item  class="row-container" *ngFor="let item of data; let i = index"
   [data]="item"  [config]="config"  >
  </app-recurrent-shift-list-item>
</ion-row>