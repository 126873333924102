import { Injectable } from '@angular/core';
import { UtilService } from 'src/modules/shared/services/util.services';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { Store } from '@ngrx/store';
import { findIndex, minBy } from 'lodash';
import * as moment from 'moment-timezone';
import { AlertController } from '@ionic/angular';
import { ITalentJob } from '../interfaces/talent-job.interface';
import { ITalentDashboardState } from '../+store/talent-dashboard.state';
import { setTalentDashboardStoreAction, setFutureShiftsCountStoreAction } from '../+store/actions/talent-dashboard.actions';
import { TalentDashboardUtilService } from './talent-dashboard-util.service';
import { TalentDashboardService } from './talent-dashboard.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { JobService } from 'src/modules/shared/services/job.service';
import { setShiftInProgressAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { ShiftCardComponent } from '../components/shift-card/shift-card.component';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { take } from 'rxjs/operators';
import { TalentLocationTrackingService } from './talent-location-tracking.service';
import { CalendarService } from './calendar.service';


@Injectable({ providedIn: 'root' })
export class TalentSyncService {


  constructor(
    private utilService: UtilService,
    private loadingService: LoadingService,
    private localStorageService: LocalStorageService,
    private jobService: JobService,
    private talentDashboardUtilService: TalentDashboardUtilService,
    private talentDashboardService: TalentDashboardService,
    private talentStore: Store<ITalentDashboardState>,
    private alertController: AlertController,
    private authStore: Store<IAuthState>,
    private talentLocationTrackingService: TalentLocationTrackingService,
    private calendarService: CalendarService,
  ) {
  }

  async sync(skipCalendarSync? : Boolean) {
    if (!this.utilService.isConnected()) {
      const localShifts = await this.localStorageService.getTalentUpcomingShifts();
      this.talentStore.dispatch(setTalentDashboardStoreAction({ upcomingShifts: localShifts }));
      return;
    }

    const remoteShifts = await this.getShiftsFromRemote();
    this.localStorageService.setShiftInProgress(false);
    if (remoteShifts) {
      await this.startSyncing(remoteShifts);
      const localShifts = await this.localStorageService.getTalentUpcomingShifts();
      await this.checkShiftsInProgress(localShifts)
      this.talentStore.dispatch(setTalentDashboardStoreAction({ upcomingShifts: localShifts }));

      const user = await this.authStore.select(getUserInfo).pipe(take(1)).toPromise();
      const upcomingShifts = await this.jobService.findUpcomingShifts(user._id, user.address.province, false);

      const deleteShifts = await this.jobService.findUpcomingShifts(user._id,user.address.province,false,true)

      // sync upcoming shifts in native calendar
      if(!skipCalendarSync){
        await this.calendarService.syncShifts(deleteShifts.jobs, [...localShifts, ...upcomingShifts.jobs]);
      }
    }
    // this.loadingService.hideLoading(loading);
  }

  async checkShiftsInProgress(shifts) {
    let shiftInProgress = false;
    const user = await this.utilService.getUserFromServerOrLocal();
    if (!user) {
      return;
    }

    shiftInProgress = this.talentDashboardUtilService.checkForShiftsInProgress(user._id, shifts)


    if(!shiftInProgress) {
      shiftInProgress = false;
    }

    this.authStore.dispatch(setShiftInProgressAction({ shiftInProgress: shiftInProgress }));
    this.localStorageService.setShiftInProgress(shiftInProgress);
  }

  async getShiftsFromRemote() {
    const user = await this.authStore.select(getUserInfo).pipe(take(1)).toPromise();
    const localShifts = await this.localStorageService.getTalentUpcomingShifts();


    const upShifts = await this.jobService.findUpcomingShifts(user._id, user.address.province, true);

    const startShiftJobs = [];

    for (const job of upShifts.jobs) {
      const basicConditions = moment.unix(job.shiftEndTime).isAfter(moment());

      const stafferObject = job.staffersAndShifts.filter(staffer => {
        return staffer.staffer === user._id && (staffer.state === 'accepted' || staffer.state === 'offer'); // && staffer.isConfirmedShift;;
      });

      const isStateAccepted = stafferObject.length > 0;
      const isShiftStarted = stafferObject.length && stafferObject[0].isShiftStarted;

      if ((basicConditions && isStateAccepted) || (!basicConditions && isStateAccepted && isShiftStarted)) {
        startShiftJobs.push(job);
      }
    }

    let shiftCount = startShiftJobs.length;

    if (upShifts.futureCount) {
      shiftCount += upShifts.futureCount;
    }

    this.setFutureShiftsCount(shiftCount);

    /**
     * Commenting this code becuase this condition is not needed
     */
    
    // if (startShiftJobs.length == 0) {
    //   return 0;
    // }
    
    

    return startShiftJobs;
  }

  async startSyncing(newShifts: ITalentJob[]) {
    const lShifts = await this.localStorageService.getTalentUpcomingShifts();
    if (!lShifts.length) {
      this.talentStore.dispatch(setTalentDashboardStoreAction({ upcomingShifts: newShifts }));
      return;
    }

    // Removing those shifts from localstorage
    // that are either ended or deleted by admin
    const localShifts = await this.deleteUnusedShiftsFromLocalStorage(newShifts);

    await this.checkForShiftsChanges(newShifts, localShifts);
  }

  async checkForShiftsChanges(newShifts: ITalentJob[], localShifts: ITalentJob[]) {
    for (const newShift of newShifts) {
      // searching if new shift already exists in localShifts
      // it will return the index number if it exist in localshifts array
      // else it will return -1;
      const localShiftIndex = findIndex(localShifts, ['_id', newShift._id]);

      // if new shift already exists in local storage, we will compare both shifts
      if (localShiftIndex !== -1) {
        await this.updateCovidAffectedStatus(localShifts[localShiftIndex], newShift); // added check for covid status
        await this.updateLocationBypassCheck(localShifts[localShiftIndex], newShift); // added check for covid status
        await this.checkIfShiftWasUpdatedByAdmin(localShifts[localShiftIndex], newShift);
        await this.findStafferIndexInShift(localShifts[localShiftIndex], newShift);
      } else {
        await this.talentDashboardUtilService.saveShiftInLocalStorage(newShift);
      }
    }
  }

  async updateCovidAffectedStatus(localShift: ITalentJob, newShift: ITalentJob) {
    localShift.entity.isCovidAffected = newShift.entity.isCovidAffected;
    await this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
  }
  async updateLocationBypassCheck(localShift: ITalentJob, newShift: ITalentJob) {
    localShift.entity.switchOffLocationTracking = newShift.entity.switchOffLocationTracking;
    await this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
  }

  // check if shift was updated on the server
  async checkIfShiftWasUpdatedByAdmin(localShift: ITalentJob, newShift: ITalentJob) {
    if (localShift.isABroadcastRequest.isTrue && localShift.isABroadcastRequest.vacancies !== newShift.isABroadcastRequest.vacancies) {
      localShift.isABroadcastRequest.vacancies = newShift.isABroadcastRequest.vacancies;
    }
    localShift.rate = newShift.rate;
    localShift.stafferRate = newShift.stafferRate;
    localShift.shiftNotes = newShift.shiftNotes;
    localShift.shiftStartTime = newShift.shiftStartTime;
    localShift.shiftEndTime = newShift.shiftEndTime;
    localShift.supervisor = newShift.supervisor;
    localShift.companyAddress = newShift.companyAddress;
    // homecare specific fields
    localShift.homecareShift = newShift.homecareShift;

    await this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
  }

  async findStafferIndexInShift(localShift: ITalentJob, newShift: ITalentJob) {
    let updatedServerShift = null;

    const currentUser = await this.localStorageService.getLoggedInUserInfo();
    const stafferIndexLocalShift = findIndex(localShift.staffersAndShifts, ['staffer', currentUser._id]);
    const stafferIndexNewShift = findIndex(newShift.staffersAndShifts, ['staffer', currentUser._id]);

    if (stafferIndexLocalShift !== -1 && stafferIndexNewShift !== -1) {
      // if shift was started
      // both ( locally and by admin )
      await this.checkIfShiftStartedLocallyAndByAdminAlso(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift);

      // if shift was started locally
      // but server was not updated
      const updatedServerShiftResult = await this.checkIfShiftStartedLocallyThenUpdateServer(
        localShift,
        stafferIndexLocalShift,
        newShift,
        stafferIndexNewShift
      );
      updatedServerShift = updatedServerShiftResult;

      // if shift was started by admin
      // but local shift was not updated
      await this.checkIfShiftStartedByAdminThenUpdateLocally(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift);
      let index = null;
      let shift = null;

      if (updatedServerShift) {
        index = findIndex(updatedServerShift.staffersAndShifts, ['staffer._id', currentUser._id]);
        shift = updatedServerShift;
      } else {
        index = stafferIndexNewShift;
        shift = newShift;
      }

      // If shift was ended locally
      // but server was not updated
      await this.checkIfShiftEndedLocallyThenUpdateServer(localShift, stafferIndexLocalShift, shift, index);

      // check for breaks and update
      await this.updateBreaks(localShift, stafferIndexLocalShift);

      // check for status in staffers and shifts
      await this.updateConfirmationStatusInStaffersAndShifts(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift);
      return;
    } else {
      console.log('Could not find staffer in job');
      return;
    }
  }

  // Shift was started both ( locally and by admin )
  async checkIfShiftStartedLocallyAndByAdminAlso(
    localShift: ITalentJob,
    stafferIndexLocalShift: number,
    newShift: ITalentJob,
    stafferIndexNewShift: number
  ) {
    if (
      localShift.staffersAndShifts[stafferIndexLocalShift].tracker &&
      newShift.staffersAndShifts[stafferIndexNewShift].tracker &&
      localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start !== newShift.staffersAndShifts[stafferIndexNewShift].tracker.start
    ) {

      const localShiftObject = localShift.staffersAndShifts[stafferIndexLocalShift];
      const newShiftObject = newShift.staffersAndShifts[stafferIndexNewShift];

      localShiftObject.started = 'locally';
      newShiftObject.started = 'by admin';

      // checking if shift was started locally or by admin
      const temp = [];
      temp.push(localShiftObject);
      temp.push(newShiftObject);

      const startedFirst = minBy(temp, o => {
        return o.tracker.start;
      });

      if (startedFirst.started === 'locally') {
        try {
          const tracker = await this.talentDashboardService.updateStartTime(
            localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start,
            newShift.staffersAndShifts[stafferIndexNewShift].tracker._id
          );

          // not replacing complete local tracker with new tracker
          // because if shift was started and ended offline, then end time
          // can be overwrite by zero
          localShift.staffersAndShifts[stafferIndexLocalShift].tracker._id = tracker._id;
          localShift.staffersAndShifts[stafferIndexLocalShift].tracker.user = tracker.user;
          localShift.staffersAndShifts[stafferIndexLocalShift].tracker.job = tracker.job;

          // saving/updating shift in localstorage
          await this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
          return;
        } catch (error) {
          console.log('error in updateStartTime', error);
          return;
        } finally {
          // deleting temporary properties
          delete localShiftObject.started;
          delete newShiftObject.started;
        }
      } else {
        // not replacing complete local tracker with new tracker
        // beacuse if shift was started and ended offline, then end time
        // can be overwrite by zero
        localShift.staffersAndShifts[stafferIndexLocalShift].tracker._id = newShift.staffersAndShifts[stafferIndexNewShift].tracker._id;
        localShift.staffersAndShifts[stafferIndexLocalShift].tracker.user = newShift.staffersAndShifts[stafferIndexNewShift].tracker.user;
        localShift.staffersAndShifts[stafferIndexLocalShift].tracker.job = newShift.staffersAndShifts[stafferIndexNewShift].tracker.job;
        localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start = newShift.staffersAndShifts[stafferIndexNewShift].tracker.start;

        // saving/updating shift in localstorage
        await this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);

        // deleting temporary properties
        delete localShiftObject.started;
        delete newShiftObject.started;
        return;
      }
    } else {
      return;
    }
  }

  // Shift was started locally but server was not updated
  async checkIfShiftStartedLocallyThenUpdateServer(
    localShift: ITalentJob,
    stafferIndexLocalShift: number,
    newShift: ITalentJob,
    stafferIndexNewShift: number
  ) {
    // return new Promise(function (resolve, reject) {
    const currentUser = await this.localStorageService.getLoggedInUserInfo();

    if (localShift.staffersAndShifts[stafferIndexLocalShift].tracker && !newShift.staffersAndShifts[stafferIndexNewShift].tracker) {
      // console.log('Shift was started locally, now updating server to start shift');
      try {
        const location = await this.talentLocationTrackingService.getUserLocation();
        const job = await this.talentDashboardService.startTimer(
          localShift._id,
          localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start,
          currentUser, 
          {
            latitude: `${location.coords.latitude}`,
            longitude: `${location.coords.longitude}`,
          }
        );
        const stafferIndex = findIndex(job.staffersAndShifts, ['staffer._id', currentUser._id]);
        if (stafferIndex !== -1) {
          // not replacing complete local tracker with new tracker
          // beacuse if shift was started and ended offline, then end time
          // can be overwritten by zero
          localShift.staffersAndShifts[stafferIndexLocalShift].tracker['_id'] = job.staffersAndShifts[stafferIndex].tracker._id;
          localShift.staffersAndShifts[stafferIndexLocalShift].tracker['user'] = job.staffersAndShifts[stafferIndex].tracker.user;
          localShift.staffersAndShifts[stafferIndexLocalShift].tracker['job'] = job.staffersAndShifts[stafferIndex].tracker.job;

          // saving/updating shift in localstorage
          await this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
          return;
        } else {
          return;
        }
      } catch (error) {
        console.log('error in startTime', error);
        return;
      }
    } else {
      return;
    }
  }

  // Shift was started by admin but local shift was not updated
  async checkIfShiftStartedByAdminThenUpdateLocally(
    localShift: ITalentJob,
    stafferIndexLocalShift: number,
    newShift: ITalentJob,
    stafferIndexNewShift: number
  ) {
    if (!localShift.staffersAndShifts[stafferIndexLocalShift].tracker && newShift.staffersAndShifts[stafferIndexNewShift].tracker) {
      localShift.staffersAndShifts[stafferIndexLocalShift] = newShift.staffersAndShifts[stafferIndexNewShift];

      // saving/updating shift in localstorage
      await this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);

      return;
    } else {
      return;
    }
  }

  // Shift was ended locally but server was not updated
  async checkIfShiftEndedLocallyThenUpdateServer(
    localShift: ITalentJob,
    stafferIndexLocalShift: number,
    newShift: ITalentJob,
    stafferIndexNewShift: number
  ) {
    const currentUser = await this.localStorageService.getLoggedInUserInfo();
    if (
      localShift.staffersAndShifts[stafferIndexLocalShift].tracker &&
      localShift.staffersAndShifts[stafferIndexLocalShift].tracker.end &&
      newShift.staffersAndShifts[stafferIndexNewShift].tracker &&
      !newShift.staffersAndShifts[stafferIndexNewShift].tracker.end
    ) {
      // console.log('shift was ended locally, now updating server to end shift');
      try {
        if (localShift.staffersAndShifts[stafferIndexLocalShift].tracker.hasAddedFromStaffer === true) {
          await this.jobService.sendRequestToAdminToLoggedHours(currentUser._id, {
            jobId: localShift._id,
            startAndEndTime: {
              start: localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start,
              end: localShift.staffersAndShifts[stafferIndexLocalShift].tracker.end
            },
            timeZone: localShift.timeZone
          });
        }

        const location = await this.talentLocationTrackingService.getUserLocation();
        await this.talentDashboardService.stopTimer(
          localShift._id,
          localShift.staffersAndShifts[stafferIndexLocalShift].tracker.end,
          currentUser._id,
          {
            latitude: `${location.coords.latitude}`,
            longitude: `${location.coords.longitude}`
          },
          localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start,
          localShift.staffersAndShifts[stafferIndexLocalShift].tracker.hasAddedFromStaffer
        );

        await this.removeShiftFromLocalStorage(localShift);
        return;
      } catch (error) {
        console.log('error in stopTimer', error);
      }
    } else {
      return;
    }
  }
  // check for breaks and update
  async updateBreaks(localShift: ITalentJob, stafferIndexLocalShift: number) {
    if (
      localShift.staffersAndShifts[stafferIndexLocalShift].tracker &&
      localShift.staffersAndShifts[stafferIndexLocalShift].tracker._id &&
      localShift.staffersAndShifts[stafferIndexLocalShift].tracker.breaks.length
    ) {
      const state = localShift.staffersAndShifts[stafferIndexLocalShift].tracker.state;
      const breaks = localShift.staffersAndShifts[stafferIndexLocalShift].tracker.breaks;
      const trackerId = localShift.staffersAndShifts[stafferIndexLocalShift].tracker._id;
      try {
        await this.talentDashboardService.updateBreaks(breaks, trackerId, state);
        return;
      } catch (error) {
        console.log('error in updateBreaks ', error);
        return;
      }
    } else {
      return;
    }
  }

  async updateConfirmationStatusInStaffersAndShifts(
    localShift: ITalentJob,
    stafferIndexLocalShift: number,
    newShift: ITalentJob,
    stafferIndexNewShift: number
  ) {
    if (
      localShift.staffersAndShifts[stafferIndexLocalShift].isConfirmedShift &&
      !newShift.staffersAndShifts[stafferIndexNewShift].isConfirmedShift
    ) {
      // console.log('Shift confirmation status updated locally, now updating server to confirmation status shift updated ');
      const currentUser = await this.localStorageService.getLoggedInUserInfo();

      try {
        const job = await this.jobService.nonRecurringShiftConfirm(localShift._id, currentUser._id);
        const stafferIndex = findIndex(job.staffersAndShifts, ['staffer._id', currentUser._id]);
        if (stafferIndex != -1) {
          localShift.staffersAndShifts[stafferIndexLocalShift].isConfirmedShift = job.staffersAndShifts[stafferIndex].isConfirmedShift;

          // saving/updating shift in localstorage
          await this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
          return;
        } else {
          return;
        }
      } catch (error) {
        console.log('error in nonRecurringShiftConfirm ', error);
        return;
      }
    } else if (
      !localShift.staffersAndShifts[stafferIndexLocalShift].isConfirmedShift &&
      newShift.staffersAndShifts[stafferIndexNewShift].isConfirmedShift
    ) {
      console.log('Shift confirmation status updated on server, now updating locally ');
      localShift.staffersAndShifts[stafferIndexLocalShift].isConfirmedShift =
        newShift.staffersAndShifts[stafferIndexNewShift].isConfirmedShift;

      // saving/updating shift in localstorage
      await this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
      return;
    } else {
      return;
    }
  }

  async deleteUnusedShiftsFromLocalStorage(newShifts: ITalentJob[]) {
    const localShifts = await this.localStorageService.getTalentUpcomingShifts();
    const shifts = [];
    const shiftsToDelete = [];
    for (const localShift of localShifts) {
      const index = findIndex(newShifts, ['_id', localShift._id]);
      if (index !== -1) {
        shifts.push(localShift);
      } else {
        shiftsToDelete.push(localShift);
      }
    }
    this.calendarService.removeDeletedShiftsFromCalendar(shiftsToDelete);
    await this.localStorageService.setTalentUpcomingShifts(shifts);
    return shifts;
  }
  

  async removeShiftFromLocalStorage(shift: ITalentJob) {
    const localShifts = await this.localStorageService.getTalentUpcomingShifts();
    delete localShifts[shift._id];
    return await this.localStorageService.setTalentUpcomingShifts(localShifts);
  }

  setFutureShiftsCount(futureUpcomingShiftsCount: number) {
    this.talentStore.dispatch(setFutureShiftsCountStoreAction({ futureUpcomingShiftsCount }));
  }
}
