import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { JobService } from '../../services/job.service';
import { LoadingService } from '../../services/loading.service';
export class ContractorShiftModifiedComponent {
    constructor(modalController, jobService, loadingService) {
        this.modalController = modalController;
        this.jobService = jobService;
        this.loadingService = loadingService;
        this.shiftExtended = false;
        this.shifts = [];
        this.config = ["Date", "Start Time", "End Time", "Unpaid Break", "Transit Allowance"];
        this.description = "Your client has made the following changes to your shifts:";
        this.title = "This shift has been modified";
    }
    ngOnInit() {
        this.fetchRecurringShifts();
        if (this.shiftExtended) {
            this.title = "This shift has been extended";
            this.description = "Your client has extended this shift on these dates:";
        }
    }
    closeModal() {
        this.modalController.dismiss();
    }
    fetchRecurringShifts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                const { data: jobs } = yield this.jobService.getRecurringShiftsV2({
                    groupJobId: this.groupJobId,
                    shiftStartTimeFrom: moment().format("X"),
                    pageSize: 93,
                    format: 'simple'
                });
                if (jobs && jobs.length) {
                    this.shifts = jobs.map(item => {
                        return {
                            Date: moment(item.shiftDate).tz(item.timeZone).format('DD MMM, YYYY'),
                            'Start Time': moment(item.shiftStartTime, 'X').tz(item.timeZone).format('hh:mm A'),
                            'End Time': moment(item.shiftEndTime, 'X').tz(item.timeZone).format('hh:mm A'),
                            'Unpaid Break': item.break ? item.break.unpaid : "No break",
                            'Transit Allowance': item.travelTime.toString(),
                        };
                    });
                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                yield this.loadingService.hideLoading(loading);
            }
        });
    }
}
