import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
export class SuccessPopupComponent {
    constructor(modalController, router, store) {
        this.modalController = modalController;
        this.router = router;
        this.store = store;
        this.user = this.store.pipe(select(getUserInfo));
    }
    ngOnInit() {
        this.subscription = this.user.subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.userData = result;
        }));
        if (this.data.heading && this.data.message && this.data.btn) {
            this.heading = this.data.heading;
            this.message = this.data.message;
            this.btnText = this.data.btn;
            this.imgURL = this.data.imgURL;
            this.specificClass = this.data.specificClass || '';
            this.disableSubmission = this.data.disableSubmission;
        }
        else {
            this.heading = 'Success';
            this.message = '';
            this.btnText = 'OK';
        }
        console.log('this.specificClass: ', this.specificClass);
        if (this.heading.includes('Message'))
            this.isMOTD = true;
        this.data.rejectBtnText && (this.rejectBtnText = this.data.rejectBtnText);
        this.data.list && this.data.list.length && (this.list = this.data.list);
    }
    submit(type) {
        if (this.data.navigateRoute) {
            this.router.navigateByUrl(this.data.navigateRoute);
        }
        else if (this.data.externalNavigationUrl && type === 'accept') {
            window.open(this.data.externalNavigationUrl, '_system');
        }
        if (!this.disableSubmission)
            this.modalController.dismiss(type);
        if (this.btnText == 'Contact support') {
            this.modalController.dismiss(type);
        }
    }
}
