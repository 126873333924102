import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic-native/splash-screen/ngx/index";
export class SplashScreenResolverService {
    constructor(platform, splashScreen) {
        this.platform = platform;
        this.splashScreen = splashScreen;
    }
    resolve() {
        this.hideSplashScreen();
        return true;
    }
    hideSplashScreen() {
        this.platform.ready().then(() => {
            this.splashScreen.hide();
        });
    }
}
SplashScreenResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SplashScreenResolverService_Factory() { return new SplashScreenResolverService(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.SplashScreen)); }, token: SplashScreenResolverService, providedIn: "root" });
