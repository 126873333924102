import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { RecurringShiftsTimingComponent } from '../components/recurring-shifts-timing/recurring-shifts-timing.component';
import { JobService } from '../services/job.service';
import { NotificationBadgesService } from '../services/notificationBadges.sevice';
import { UtilService } from '../services/util.services';
export class NotificationPopupComponent {
    constructor(notificationBadgeService, modalController, talentJobFormatService, jobService, utilService) {
        this.notificationBadgeService = notificationBadgeService;
        this.modalController = modalController;
        this.talentJobFormatService = talentJobFormatService;
        this.jobService = jobService;
        this.utilService = utilService;
        this.respondTobroadcastRequest = new EventEmitter();
        this.badge = {};
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notification = this.notify;
            this.userData = this.user;
            this.badge.type = this.notificationBadgeService.showNotificationTypeBadge(this.notification);
            this.badge.jobCompleted = this.notificationBadgeService.showJobCompletedBadge(this.notification);
            this.badge.fileDispute = this.notificationBadgeService.showFileDisputeBadge(this.notification);
            this.badge.shiftFilled = this.notificationBadgeService.showShiftFilledBadge(this.notification);
            this.badge.hired = this.notificationBadgeService.showHiredBadge(this.notification);
            this.badge.rejected = this.notificationBadgeService.showRejectedBadge(this.notification);
            this.badge.awaitingResponse = this.notificationBadgeService.showAwaitingResponseBadge(this.notification);
            this.badge.stafferHired = this.notificationBadgeService.showStafferHiredBadge(this.notification);
            this.badge.stafferIgnored = this.notificationBadgeService.showStafferIgnoredBadge(this.notification);
            this.badge.showRating = this.notificationBadgeService.showRatingBadge(this.notification);
            this.badge.isABroadcastRequest = this.notificationBadgeService.showIsABroadcastRequestBadge(this.notification);
            this.badge.showApplyButton = this.notificationBadgeService.showApplyButton(this.notification);
            this.badge.verifiedHoursBadge = this.notificationBadgeService.showVerifiedHoursBadge(this.notification);
            this.badge.trackerWorking = this.notificationBadgeService.showTrackerWorkingBadge(this.notification);
            this.badge.alreadyApplied = this.notificationBadgeService.showAlreadyAppliedBadge(this.notification);
            this.badge.alreadyHired = this.notificationBadgeService.showAlreadyHiredBadge(this.notification);
            this.badge.viewCertificates = this.notificationBadgeService.showViewCertificatesBadge(this.notification);
            this.badge.seeAvailableShifts = this.notificationBadgeService.showAvailableShiftsBadge(this.notification);
            this.badge.logFileDispute = this.notificationBadgeService.showlogFileDisputeBadge(this.notification);
            this.badge.logRating = this.notificationBadgeService.showLogRatingBadge(this.notification);
            this.notification.job.proximity = this.utilService.calculateDistanceFromShift(this.notification.job.companyAddress, this.userData.address);
            this.notification.job.canApply = yield this.jobService.isTrainingRequired(this.userData._id, this.notification.job);
            this.notification.job.isApply = yield this.jobService.isApplied(this.userData._id, this.notification.job);
            if (this.notification.groupJobId) {
                this.notification.dateRange = this.talentJobFormatService.getDateRange(this.notification.groupJobId);
                this.badge.recurringJobOffer = this.notificationBadgeService.recurringJobOffer(this.notification);
                this.badge.showApplyRecurringButton = this.notificationBadgeService.showApplyRecurringButton(this.notification);
                this.badge.alreadyAppliedOnRecurring = this.notificationBadgeService.showAlreadyAppliedOnRecurring(this.notification);
                this.badge.recurringJobCheck = this.notificationBadgeService.recurringJobCheck(this.notification, this.userData.type);
                this.badge.canViewCertificates = this.notificationBadgeService.canViewCertificates(this.notification);
                this.badge.isRecurringBroadcast = this.notificationBadgeService.isRecurringBroadcast(this.notification);
                this.badge.seeAvailableShifts = this.notificationBadgeService.seeAvailableShifts(this.notification);
                this.badge.jobCompletedForEmployer = this.notificationBadgeService.jobCompletedForEmployer(this.notification, this.userData.type);
                this.badge.canRateShift = this.notificationBadgeService.canRateShift(this.notification);
            }
            console.log(this.notification);
        });
    }
    dismissModal() {
        this.modalController.dismiss();
    }
    applyOnBroadcastRequest() {
        this.modalController.dismiss('respondTobroadcastRequest');
    }
    rejectStaffer() {
        this.modalController.dismiss('rejectStaffer');
    }
    acceptHireRequest() {
        this.modalController.dismiss('acceptHireRequest');
    }
    rejectJob() {
        this.modalController.dismiss('rejectJob');
    }
    confirmShift() {
        this.modalController.dismiss('confirmShift');
    }
    verifyHours() {
        this.modalController.dismiss('verifyHours');
    }
    stillWorkingStaffer() {
        this.modalController.dismiss('stillWorkingStaffer');
    }
    stopTrackerNow() {
        this.modalController.dismiss('stopTrackerNow');
    }
    unApplyFromShift() {
        this.modalController.dismiss('unApplyFromShift');
    }
    hireStafferForJob() {
        this.modalController.dismiss('hireStafferForJob');
    }
    seeAvailableShifts() {
        this.modalController.dismiss('seeAvailableShifts');
    }
    fileDispute() {
        this.modalController.dismiss('fileDispute');
    }
    rateStaffer() {
        this.modalController.dismiss('rateStaffer');
    }
    viewShiftTimings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: RecurringShiftsTimingComponent,
                backdropDismiss: false,
                cssClass: 'wideModal',
                componentProps: {
                    jobItem: this.notification.groupJobId
                }
            });
            return yield myModal.present();
        });
    }
    recurringRespondToBroadcastRequest() {
        this.modalController.dismiss('recurringRespondToBroadcastRequest');
    }
    recurringRejectBroadcastRequest() {
        this.modalController.dismiss('recurringRejectBroadcastRequest');
    }
    recurringUnApplyFromShift() {
        this.modalController.dismiss('recurringUnApplyFromShift');
    }
    recurringAcceptJob() {
        this.modalController.dismiss('recurringAcceptJob');
    }
    recurringRejectJob() {
        this.modalController.dismiss('recurringRejectJob');
    }
    recurringHireStaffer() {
        this.modalController.dismiss('recurringHireStaffer');
    }
}
