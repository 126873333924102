import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingService } from '../../services/loading.service';
import { UserService } from '../../services/user.service';
export class BulletinViewerComponent {
    constructor(popupService, modalController, userService, loadingService) {
        this.popupService = popupService;
        this.modalController = modalController;
        this.userService = userService;
        this.loadingService = loadingService;
        this.isVideo = false;
        this.isTemplate = false;
        this.isAcknowledgmentRequired = false;
        this.skill = "";
        this.acknowedgment = false;
    }
    ngOnInit() {
    }
    closeModal() {
        this.modalController.dismiss();
    }
    acknowledgeDocument() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.userService.acknowledgeBulletin(this.entityId, this.bulletinId, this.skill);
                this.popupService.showModal({
                    heading: 'Success!',
                    message: 'Bulletin has been acknowledge successfully',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/thumbs-up.png'
                });
                this.modalController.dismiss();
                if (this.onBulletinAcknowledgmentSuccess) {
                    this.onBulletinAcknowledgmentSuccess(this.bulletinId);
                }
            }
            catch (e) {
                this.popupService.showModal({
                    heading: 'Error',
                    message: 'Something went wrong, Please try again later',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/profile.png'
                });
            }
            finally {
                this.loadingService.hideLoading(loading);
            }
        });
    }
}
