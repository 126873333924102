<div class = "reusable-form-component checkbox-top">
  <ion-label class = "label">{{ labelText }}</ion-label>
  <div class = "flex-row padding-top-8">
  <div *ngFor="let option of options">
    <div class="custom-checkbox" [ngClass] = "{'disable-buttons': disableList}"> 
      <input
        [disabled] = "disableList"
        type="checkbox"
        id="{{ option.value }}"
        [(ngModel)]="option.checked"
        (change)="handleChange()"
      />
      <label for="{{ option.value }}" [attr.data-value]="option.label"></label>
    </div>
  </div>
</div>
</div>

<app-form-errors [control]="control"></app-form-errors>
