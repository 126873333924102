/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./copy-popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./copy-popover.component";
var styles_CopyPopoverComponent = [i0.styles];
var RenderType_CopyPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CopyPopoverComponent, data: {} });
export { RenderType_CopyPopoverComponent as RenderType_CopyPopoverComponent };
function View_CopyPopoverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
function View_CopyPopoverComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.copiedText; _ck(_v, 1, 0, currVal_0); }); }
function View_CopyPopoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyToClipboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyPopoverComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyPopoverComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "vertical-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-copy copy-icon"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showCopiedText; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showCopiedText; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_CopyPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyPopoverComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPopover; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CopyPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-copy-popover", [], null, null, null, View_CopyPopoverComponent_0, RenderType_CopyPopoverComponent)), i1.ɵdid(1, 573440, null, 0, i3.CopyPopoverComponent, [], null, null)], null, null); }
var CopyPopoverComponentNgFactory = i1.ɵccf("app-copy-popover", i3.CopyPopoverComponent, View_CopyPopoverComponent_Host_0, { text: "text", showPopover: "showPopover", copiedToClipBoard: "copiedToClipBoard" }, {}, []);
export { CopyPopoverComponentNgFactory as CopyPopoverComponentNgFactory };
