import { OnboardingStepEnum } from "../../enums/onboarding-step.enum"

export const onboardingEnumsToRouteMap = {
    [OnboardingStepEnum.STAFFER_EXPERTISE]: '/onboarding-staffer/expertise',
    [OnboardingStepEnum.STAFFER_HOME]: '/talent-dashboard/home',
    [OnboardingStepEnum.STAFFER_INSTRUCTION]: '/onboarding-staffer/instruction',
    [OnboardingStepEnum.STAFFER_GETREADY]: '/onboarding-staffer/get-ready',
    [OnboardingStepEnum.STAFFER_PRIVACY_POLICY]: '/shared/privacy-policy',
    [OnboardingStepEnum.STAFFER_ONBOARDING_DASHBOARD]: '/onboarding-staffer/onboarding-dashboard',
    [OnboardingStepEnum.STAFFER_PERSONAL_INFORMATION]: '/onboarding-staffer/personal-information',
    [OnboardingStepEnum.STAFFER_CONTRACT]: '/onboarding-staffer/staffer-contract',
    [OnboardingStepEnum.STAFFER_PROFESSIONAL_INFORMATION]: '/onboarding-staffer/professional-information',
    [OnboardingStepEnum.STAFFER_SKILLS]: '/onboarding-staffer/onboarding-dashboard',
    [OnboardingStepEnum.STAFFER_CERTIFICATES]: '/onboarding-staffer/onboarding-dashboard',
    [OnboardingStepEnum.STAFFER_VSS_ATTESTATION]: '/onboarding-staffer/vss-attestation',
    [OnboardingStepEnum.STAFFER_ADD_REFERENCE]: '/onboarding-staffer/add-reference',
    [OnboardingStepEnum.STAFFER_UPLOAD_EQUIPMENT]: '/onboarding-staffer/upload-equipment',
    [OnboardingStepEnum.STAFFER_ADD_BACKGROUND_CHECK]: '/onboarding-staffer/background-check',
    [OnboardingStepEnum.STAFFER_QUIZ]: '/onboarding-staffer/quiz',


}