import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-context-found',
  templateUrl: './no-context-found.component.html',
  styleUrls: ['./no-context-found.component.scss'],
})
export class NoContextFoundComponent implements OnInit {

  @Input() noShiftsData;
  
  noShifts : {
    text: string,
    imageUrl: string,
    subText: string,
    buttonText: string,
    navigateRoute: string
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.noShifts = this.noShiftsData;
  }

  ngOnChanges() {
    this.noShifts = this.noShiftsData;
  }

  navigate() {
    this.router.navigateByUrl(this.noShifts.navigateRoute)
  }

}
