import { Component, OnInit, Input } from '@angular/core';
import {  ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { Subscription } from 'rxjs';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';

@Component({
  selector: 'shared-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})
export class SuccessPopupComponent implements OnInit {
  @Input() msgType?: string;
  @Input() data: {
    heading: string;
    message: string;
    btn: string;
    navigateRoute: string;
    imgURL?: string;
    rejectBtnText?: string;
    list?: string[];
    externalNavigationUrl?: string;
    specificClass?:string;
    disableSubmission?: boolean
  };
  isMOTD: boolean;
  heading: string;
  message: string;
  btnText: string;
  rejectBtnText: string;
  imgURL: string;
  list: string[];
  specificClass: string;
  companyOnboardingInfoSuccess: boolean;
  companyOnboardingComplete: boolean;
  disableSubmission: boolean;
  userData: IUser;
  subscription: Subscription;
  

  readonly user = this.store.pipe(select(getUserInfo));
  constructor(private modalController: ModalController, 
    private router: Router,
    private store: Store<IAuthState>) {}

  ngOnInit() {

    this.subscription = this.user.subscribe(async (result) => {
      this.userData = result;  
    });


    if (this.data.heading && this.data.message && this.data.btn) {
      this.heading = this.data.heading;
      this.message = this.data.message;
      this.btnText = this.data.btn;
      this.imgURL = this.data.imgURL;
      this.specificClass = this.data.specificClass || '' ;
      this.disableSubmission = this.data.disableSubmission;
    } else {
      this.heading = 'Success';
      this.message = '';
      this.btnText = 'OK';
    }

    console.log('this.specificClass: ' ,this.specificClass);

    if(this.heading.includes('Message')) 
      this.isMOTD = true;

    this.data.rejectBtnText && (this.rejectBtnText = this.data.rejectBtnText);
    this.data.list && this.data.list.length && (this.list = this.data.list);
  }

  submit(type: 'accept' | 'reject') {
    if (this.data.navigateRoute) {
      this.router.navigateByUrl(this.data.navigateRoute);
    } else if (this.data.externalNavigationUrl && type === 'accept') {
      window.open(this.data.externalNavigationUrl, '_system');
    }

    if(!this.disableSubmission)
      this.modalController.dismiss(type);
    
    if(this.btnText == 'Contact support') {
      this.modalController.dismiss(type);
    }
  }
}
