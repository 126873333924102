import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { compact } from 'lodash';

@Component({
  selector: 'app-sub-skills-health-care-form',
  templateUrl: './sub-skills-health-care-form.component.html',
  styleUrls: ['./sub-skills-health-care-form.component.scss'],
})
export class SubSkillsHealthCareFormComponent implements OnInit {

  data = [];
  filteredData = [];
  @Output() formSubmitted = new EventEmitter();
  @Input() user: IUser;
  @Input() pendingSubSkills;

  constructor() {}


  ngOnChanges() {
    this.data = [{
      title: 'ACUTE CARE',
      isChecked: false,
      isDisabled: false,
      name: 'Acute care'
    },{
      title: 'Addictions',
      isChecked: false,
      isDisabled: false,
      name: 'Addictions'
    },{
      title: 'Anesthesiology',
      isChecked: false,
      isDisabled: false,
      name: 'Anesthesiology'
    },{
      title: 'Cardiology',
      isChecked: false,
      isDisabled: false,
      name: 'Cardiology'
    },{
      title: 'Charge',
      isChecked: false,
      isDisabled: false,
      name: 'Charge'
    },{
      title: 'Chronic Care',
      isChecked: false,
      isDisabled: false,
      name: 'Chronic Care'
    },{
      title: 'Community Health',
      isChecked: false,
      isDisabled: false,
      name: 'Community Health'
    },{
      title: 'Critical Care',
      isChecked: false,
      isDisabled: false,
      name: 'Critical Care'
    },{
      title: 'Dermatology',
      isChecked: false,
      isDisabled: false,
      name: 'Dermatology'
    },{
      title: 'Diabetes',
      isChecked: false,
      isDisabled: false,
      name: 'Diabetes'
    },{
      title: 'Emergency/ER',
      isChecked: false,
      isDisabled: false,
      name: 'Emergency/ER'
    },{
      title: 'Enterostomal Therapy (ET)',
      isChecked: false,
      isDisabled: false,
      name: 'Enterostomal Therapy (ET)'
    },{
      title: 'Gastroenterology',
      isChecked: false,
      isDisabled: false,
      name: 'Gastroenterology'
    },{
      title: 'General Medicine',
      isChecked: false,
      isDisabled: false,
      name: 'General Medicine'
    },{
      title: 'Geriatric',
      isChecked: false,
      isDisabled: false,
      name: 'Geriatric'
    },{
      title: 'Gerontology',
      isChecked: false,
      isDisabled: false,
      name: 'Gerontology'
    },
    {
      title: 'Hematology',
      isChecked: false,
      isDisabled: false,
      name: 'Hematology'
    },{
      title: 'ICU',
      isChecked: false,
      isDisabled: false,
      name: 'ICU'
    },{
      title: 'Medical/Surgery',
      isChecked: false,
      isDisabled: false,
      name: 'Medical/Surgery'
    },{
      title: 'Neonatal',
      isChecked: false,
      isDisabled: false,
      name: 'Neonatal'
    },{
      title: 'Nephrology',
      isChecked: false,
      isDisabled: false,
      name: 'Nephrology'
    },{
      title: 'Neurology',
      isChecked: false,
      isDisabled: false,
      name: 'Neurology'
    },{
      title: 'Respirology',
      isChecked: false,
      isDisabled: false,
      name: 'Respirology'
    },{
      title: 'NICU',
      isChecked: false,
      isDisabled: false,
      name: 'NICU'
    },{
      title: 'Obstetrics',
      isChecked: false,
      isDisabled: false,
      name: 'Obstetrics'
    },{
      title: 'Oncology',
      isChecked: false,
      isDisabled: false,
      name: 'Oncology'
    },{
      title: 'Orthopedic',
      isChecked: false,
      isDisabled: false,
      name: 'Orthopedic'
    },{
      title: 'Otorhinolaryngology',
      isChecked: false,
      isDisabled: false,
      name: 'Otorhinolaryngology'
    },{
      title: 'Palliative',
      isChecked: false,
      isDisabled: false,
      name: 'Palliative'
    },{
      title: 'Pediatric',
      isChecked: false,
      isDisabled: false,
      name: 'Pediatric'
    },{
      title: 'Perinatal',
      isChecked: false,
      isDisabled: false,
      name: 'Perinatal'
    },{
      title: 'Plastic surgery',
      isChecked: false,
      isDisabled: false,
      name: 'Plastic surgery'
    },{
      title: 'Postpartum',
      isChecked: false,
      isDisabled: false,
      name: 'Postpartum'
    },{
      title: 'Preoperative',
      isChecked: false,
      isDisabled: false,
      name: 'Preoperative'
    },{
      title: 'Psychiatric/Mental Health',
      isChecked: false,
      isDisabled: false,
      name: 'Psychiatric/Mental Health'
    },{
      title: 'Radiology',
      isChecked: false,
      isDisabled: false,
      name: 'Radiology'
    },{
      title: 'Rehabilitation',
      isChecked: false,
      isDisabled: false,
      name: 'Rehabilitation'
    },{
      title: 'Reconstituter',
      isChecked: false,
      isDisabled: false,
      name: 'Reconstituter'
    },{
      title: 'Rheumatology',
      isChecked: false,
      isDisabled: false,
      name: 'Rheumatology'
    },
    {
      title: 'Surgery',
      isChecked: false,
      isDisabled: false,
      name: 'Surgery'
    },
    {
      title: 'OR',
      isChecked: false,
      isDisabled: false,
      name: 'OR'
    },{
      title: 'Transplant',
      isChecked: false,
      isDisabled: false,
      name: 'Transplant'
    },{
      title: 'Trauma',
      isChecked: false,
      isDisabled: false,
      name: 'Trauma'
    },{
      title: 'Urology',
      isChecked: false,
      isDisabled: false,
      name: 'Urology'
    },{
      title: 'Vaccinator',
      isChecked: false,
      isDisabled: false,
      name: 'Vaccinator'
    },{
      title: 'L&D (Labour & Delivery)',
      isChecked: false,
      isDisabled: false,
      name: 'L&D (Labour & Delivery)'
    },{
      title: 'Hemodialysis',
      isChecked: false,
      isDisabled: false,
      name: 'Hemodialysis'
    },{
      title: 'Carestream DRX Revolution',
      isChecked: false,
      isDisabled: false,
      name: 'Carestream DRX Revolution'
    },{
      title: 'GE OEC 9800/9900',
      isChecked: false,
      isDisabled: false,
      name: 'GE OEC 9800/9900'
    },{
      title: 'Canon Aquillion 64',
      isChecked: false,
      isDisabled: false,
      name: 'Canon Aquillion 64'
    },{
      title: 'Canon Aquillion Prime',
      isChecked: false,
      isDisabled: false,
      name: 'Canon Aquillion Prime'
    },{
      title: 'Canon Aquillion One Vision',
      isChecked: false,
      isDisabled: false,
      name: 'Canon Aquillion One Vision'
    },{
      title: 'Canon Aquillion One Prismc',
      isChecked: false,
      isDisabled: false,
      name: 'Canon Aquillion One Prismc'
    },
    {
      title: 'POINT CLICK CARE (PCC)',
      isChecked: false,
      isDisabled: false,
      name: 'POINT CLICK CARE (PCC)'
    },
  ];

    if(this.user && this.pendingSubSkills) {
      this.markPendingSubSkills();
    }

    if (this.user && this.user.subSkills.length > 0) {
      this.markApprovedSubSkills();
    }
  }
  
  ngOnInit() {
  }

  markApprovedSubSkills() {
    this.user.subSkills.forEach(skill => {
      for (const dataItem of this.data) {
        if (skill === dataItem.name) {
          dataItem.isChecked = true;
          dataItem.isDisabled = true;
        }
      }
    });
  }

  markPendingSubSkills() {
    this.pendingSubSkills.forEach(skill => {
      for (const dataItem of this.data) {
        if (skill.subSkill === dataItem.name) {
          dataItem.isChecked = true;
        }
      }
    });
  }


  next() {
    const data = this.data.filter(item=> item.isChecked).map(({name}) => name);
    this.formSubmitted.emit(data);
  }

  cardClicked(index: number, e) {
    e.preventDefault();
    if(this.data[index].isDisabled == false) {
      this.data[index].isChecked = !this.data[index].isChecked;
    }
  }
}

