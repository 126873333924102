import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { getUserInfo, hasUserCompletedIntroSteps } from 'src/modules/authentication/+store/auth-selector';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { UserTypeEnum } from 'src/modules/authentication/enums/user-type.enum';
import { onboardingEnumsToRouteMap } from 'src/modules/shared/utils/helpers/onboarding-steps-route-map';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(
    private readonly store: Store<IAuthState>,
    private router: Router
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree>  {

    return combineLatest([
      this.store.pipe(select(getUserInfo)),
      // this.store.pipe(select(hasUserCompletedIntroSteps))
      ])
      .pipe(take(1))
      .pipe(
        map((data)=>({user: data[0]})),
        mergeMap(({ user})  => {
          if(!this._isUserLoggedIn(user, state, childRoute)){
            return of(true)
          }
          // User is Logged In
          // determine the next route and return 
          return this._determindNextRoute(user);
        })
      )
  }

  

  _isUserLoggedIn(user, routerState, route, ){
    return (user && !(routerState.url.includes('auth/login') && route.queryParams.token));
  }

  _determindNextRoute(user: IUser): Observable<UrlTree>{
    // User is Logged In
    // if the user is a staffer and has completed onboarding
    if (user.type === UserTypeEnum.STAFFER) {
      // Redirect to dashboard
      if(user.profile_approved){
        return of(this.router.parseUrl('/talent-dashboard'))
      }

      console.log(user.currentNavStep);

      // profile is not approved, redirect user back the last navigated step
      const lastRoute = onboardingEnumsToRouteMap[user.currentNavStep] || '/onboarding-staffer/expertise';
      return of(this.router.parseUrl(lastRoute));
    }

    // if the user is a staffer and hasn't completed onboarding
    if(user.type === UserTypeEnum.EMPLOYER){
      // Redirect to dashboard
      return of(this.router.parseUrl('/company-dashboard'))
    }
  }
}
