/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-experience.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./select-experience.component";
import * as i4 from "@ionic/angular";
var styles_SelectExperienceComponent = [i0.styles];
var RenderType_SelectExperienceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectExperienceComponent, data: {} });
export { RenderType_SelectExperienceComponent as RenderType_SelectExperienceComponent };
function View_SelectExperienceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "active": 0, "inactive": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.experienceSelected(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_v.context.$implicit.name == _co.activate), (_v.context.$implicit.name != _co.activate)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.value; _ck(_v, 5, 0, currVal_1); }); }
export function View_SelectExperienceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "outer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "list-section"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectExperienceComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "button-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveExperience() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Select"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.list; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; _ck(_v, 2, 0, currVal_0); var currVal_2 = !_co.activate; _ck(_v, 9, 0, currVal_2); }); }
export function View_SelectExperienceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select-experience", [], null, null, null, View_SelectExperienceComponent_0, RenderType_SelectExperienceComponent)), i1.ɵdid(1, 114688, null, 0, i3.SelectExperienceComponent, [i4.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectExperienceComponentNgFactory = i1.ɵccf("app-select-experience", i3.SelectExperienceComponent, View_SelectExperienceComponent_Host_0, { data: "data", activated: "activated" }, {}, []);
export { SelectExperienceComponentNgFactory as SelectExperienceComponentNgFactory };
