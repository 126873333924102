import { Component, OnInit, Input } from '@angular/core';
import { ITask } from 'src/modules/authentication/interfaces/task.interface';
import { UserService } from '../../../../shared/services/user.service';
import { Store } from '@ngrx/store';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { getUserInfoAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss'],
})
export class AddTaskComponent implements OnInit {

  @Input() task: ITask = {
    title: '',
    description: ''
  };
  @Input() entityId: string;

  constructor(
    private userService: UserService,
    private authStore: Store<IAuthState>,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    console.log(this.task)
  }

  closeModal() {
    this.modalController.dismiss(false);
  }

  async addTask() {
    if (!this.task.title || !this.task.description) {
      return;
    }

    try {
      let res;

      if (this.task._id) {
        res = await this.userService.editCompanyTask(
          this.entityId,
          this.task
        );
      } else {
        res = await this.userService.addNewTaskIntoCompany(
          this.entityId,
          this.task
        );
      }

      if (res === 'success') {
        this.authStore.dispatch(getUserInfoAction());
        this.modalController.dismiss(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

}
