import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IonDatetime, IonSlides, LoadingController, ModalController } from '@ionic/angular';
import { forEach, sortBy } from 'lodash';
import * as moment from 'moment';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user.service';
import { HelpService } from '../../pages/help/services/help.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { IViewCert } from '../../interfaces/certificate.interface';
import { IDateSpecification } from 'src/modules/talent-dashboard/interfaces/dateSpecification.interface';

@Component({
  selector: 'app-view-certificates',
  templateUrl: './view-certificates.component.html',
  styleUrls: ['./view-certificates.component.scss'],
})
export class ViewCertificatesComponent implements OnInit, OnChanges {

  @Input() userData;
  @Input() certificates;
  @Input() dateSpecifications: IDateSpecification[];
  stafferCertificates;
  @Output() addCertificate = new EventEmitter();
  @Output() editCertificateDate = new EventEmitter<{certificate: IViewCert, selectedDateType: "issueDate" | "expiryDate"}>();
  @ViewChild('slider', { read: IonSlides, static: false }) slider: IonSlides;
  @ViewChildren('slide') slides: QueryList<ElementRef>;
  @ViewChild('picker', {static: false}) picker: IonDatetime;
  slideConfig = {
    slidesPerView: 1,
    spaceBetween: 10
   }
   currentIndex :number = 0;
   images = [];
   currentSlide:number = 0;
   viewer : string = 'google';
   doc = '';
   isCertificateDOC : boolean = false;
   isCertificatePDF : boolean = false;
   isCertificateImage : boolean = false;
   sanitizeURL : string = '';
   showLoading = true;
   customPickerButtons = {};
   showDatePicker: boolean = false;
   expiryDate: Number;
   expiryMoment;
  
  constructor(
    private popupService: PopupService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private helpService: HelpService,
    private router: Router,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    if(!this.stafferCertificates || !this.stafferCertificates.length) {
      return;
    }
  }

  async slideTouchEnd() {
    let isEnd = await (this.slider.isEnd());
    let sliderLength = await this.slider.length() - 1 ;
  
    if(isEnd && this.currentSlide < sliderLength) {
      this.currentSlide = this.currentSlide + 1;
    }
    
    this.slider.update();  
  }

  enableDeleteButton(item) {
    //if certificate is approved or already deleted hide the delete button
   
    if(item.isApprove || item.markCertificateAsRejected.isDeleted)
      return false;

    return true;

  }

  async slideChanged() {
      this.currentSlide = await this.slider.getActiveIndex();
  }

  ngOnChanges() {
    this.stafferCertificates = this.certificates;

    this.stafferCertificates = sortBy(this.stafferCertificates, o => (!o.markCertificateAsRejected.isRejected))
    this.formatExtension();
  }

  //Emit action when add/replaced certificate is clicked
  addCertificates(isReplaced: boolean, certificateId?: string) {
    this.addCertificate.emit({isReplaced: isReplaced, certificateId});
  }

  async updateSliders(index: number) {
    if(index == 0)
      await this.slider.slideNext();
    else 
      await this.slider.slidePrev();
  }

  formatExtension() {
    forEach(this.stafferCertificates, (cert) => {
      if(cert.url.includes('http:')) {
        cert.url = cert.url.replace('http:', 'https:');
      }

      let extension = cert.url.substring(cert.url.lastIndexOf('.'));
      if(extension == '.pdf') {
        cert.isCertificatePDF = true;
        cert.sanitizeURL = this.sanitizer.bypassSecurityTrustResourceUrl(cert.url);
      }

      else if(extension === '.docx' || extension === '.doc' || extension === '.txt') {
        cert.isCertificateDOC = true;
        cert.sanitizeURL = this.sanitizer.bypassSecurityTrustResourceUrl(cert.url);
      } else if(extension === '.heic') {
        cert.url = cert.url.replace('.heic', '.jpg');
        cert.isCertificateImage = true;
      } else {
        cert.isCertificateImage = true;
      }
  })
  }

  textLayerRendered(e: CustomEvent) {
    this.showLoading = false;
  }


  showDeleteCertificatePopup(item) {
    //show confirmation popup when contractor wants to remove certificate, uploaded accidently
    this.popupService.showModal({
      heading: 'STOP',
      message: 'Are you sure you want to delete this credential',
      btn: 'Yes',
      rejectBtnText: 'No',
      navigateRoute: null,
      imgURL: 'assets/images/notice.png'
    }, (data) => {
      this.removeCertificate(item._id);
    });
  }

  async removeCertificate(certificateId: string) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
    //Remove certificate from backend by requesting API
    this.userService.removeCertificate(this.userData._id, certificateId, false);
    //Remove certificate from frontend by slicing the id from Array
    const index = this.stafferCertificates.findIndex(cert => cert._id == certificateId);
    this.stafferCertificates.splice(index, 1);  
    
    
    //if contractor deleted all the certificates then naviagte to back screen
    if(this.stafferCertificates.length == 0)
      this.navigateBackForEmptyCertificates()


    } catch(error) {
      this.onDeletionError();
    } finally {
      this.loadingService.hideLoading(loading);

    }
  }


  navigateBackForEmptyCertificates() {
    if(!this.userData.profile_approved) 
      this.router.navigateByUrl('onboarding-staffer/certificates');
    else
      this.router.navigateByUrl('talent-dashboard/profile');  
  }

  onError(error: any) {
    console.log('error: ', error);
    this.showLoading = false;
    this.popupService.showModal({ 
      heading: 'Error', 
      message: 'Failed to load document. Please click on link to view credential file.', 
      btn: 'Dismiss', 
      navigateRoute: null,
      imgURL: 'assets/images/sorry1.png' 
    });

  }

  onDeletionError() {
    this.showLoading = false;
    this.popupService.showModal({ 
      heading: 'Error', 
      message: 'Failed to delete document. Please try again later.', 
      btn: 'Dismiss', 
      navigateRoute: null,
      imgURL: 'assets/images/sorry1.png' 
    });

  }

  showReasonOfRejection(item) {

    this.popupService.showModal({
      heading: 'This document has not been approved',
      message: item.markCertificateAsRejected.reason ? 'Reason for rejection: \n\n' + item.markCertificateAsRejected.reason : '-',
      btn: 'Reupload',
      rejectBtnText: 'Ok',
      navigateRoute: null,
      imgURL: 'assets/images/notice.png'
    });
  }



  showPicker() {
    this.showDatePicker = !this.showDatePicker;
  }

  loadResults(item) {
    if(!item.expiryDate) {
      console.log('Expiry date missing');
      return;
    }
  }

  onProgress(certificateProgress: any) {
    this.showLoading = true;
    // const loadingTask = pdf.getDocument({ data: certificateProgress });
  }

  openLink(url: string) {
    this.helpService.openAppBrowserLink(url);
  }

  navigateToUpload() {
    if(!this.userData.isSignupProcessComplete)
      this.router.navigateByUrl('onboarding-staffer/certificates');    
    else 
      this.router.navigateByUrl('talent-dashboard/home');    
  }

  //Emit action when edit X date is clicked. Only 2 type of dates can be edited, issue date or expiry date
  editDateForCertificate(certificate: IViewCert, selectedDateType: 'issueDate' | 'expiryDate') {
    this.editCertificateDate.emit({certificate, selectedDateType});
  }
}
