import * as i0 from "@angular/core";
export class CSVService {
    constructor() { }
    parseCsvData(csvData) {
        // remove all the escape characters other than \n
        csvData = csvData.replace(/\r\n/g, '\n').replace(/\r/g, '');
        const lines = csvData.split('\n');
        const headers = lines[0].split(',');
        const result = [];
        for (let i = 1; i < lines.length; i++) {
            // if current line is empty, skip it
            if (lines[i].trim() === '') {
                continue;
            }
            const obj = {};
            const currentLine = lines[i].split(',');
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentLine[j] || '';
            }
            result.push(obj);
        }
        return result;
    }
    getCsvContent(file) {
        const reader = new FileReader();
        reader.readAsText(file);
        const results = [];
        return new Promise((resolve, reject) => {
            reader.onload = () => {
                const csvData = reader.result.toString();
                resolve(this.parseCsvData(csvData));
            };
            reader.onerror = () => {
                reject(reader.error);
            };
        });
    }
}
CSVService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CSVService_Factory() { return new CSVService(); }, token: CSVService, providedIn: "root" });
