import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private defaultApiUrl = environment.API_BASE_URL; // Store the default URL
  private defaultApiUrlCompanies = environment.API_BASE_URL_COMPANIES // store the default url companies
  private defaultStripe = environment.STRIPE_API_KEY;// Store the default STRIPE KEY
  private apiUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('API_BASE_URL') || this.defaultApiUrl);
  private apiUrlCompaniesSubject: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('API_BASE_URL_COMPANIES') || this.defaultApiUrlCompanies);
  private stripeKeySubject: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('STRIPE_API_KEY') || this.defaultStripe);

  apiUrl$ = this.apiUrlSubject.asObservable();
  apiUrlCompanies$ = this.apiUrlCompaniesSubject.asObservable();
  stripeKey$ = this.stripeKeySubject.asObservable();

  setApiUrlWithStripeKey(url: string, compUrl: string, stripeKey: string) {
    this.apiUrlSubject.next(url);
    this.stripeKeySubject.next(stripeKey);
    environment.API_BASE_URL = url;
    environment.API_BASE_URL_COMPANIES = compUrl;
    environment.STRIPE_API_KEY = stripeKey;
    localStorage.setItem('API_BASE_URL', url);
    localStorage.setItem('API_BASE_URL_COMPANIES', compUrl);
    localStorage.setItem('STRIPE_API_KEY', stripeKey);
    
  }

  revertToDefault() {
    this.setApiUrlWithStripeKey(this.defaultApiUrl, this.defaultApiUrlCompanies, this.defaultStripe);
  }

  isDemoMode(): boolean {
    return this.getApiUrl() !== this.defaultApiUrl;
  }

  getApiUrl(): string {
    return this.apiUrlSubject.value;
  }

  getApiUrlCompanies(): string {
    return this.apiUrlCompaniesSubject.value;
  }

  getStripeKey(): string {
    return this.stripeKeySubject.value;
  }
}
