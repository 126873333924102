<div class = "outer">
  <div   class = "header">
    {{ heading }}
  </div>

  <div class = "list-section">
    <div *ngFor = "let exp of list" [ngClass] = "{'active' : (exp.name == activate), 'inactive' : (exp.name != activate)}">
      <p (click) = "experienceSelected(exp)"> {{ exp.value }}</p>
    </div>
  </div>

  <div class = "button-section">
    <button class = "cancel" (click) = "cancel()">Cancel</button>
    <button class = "submit" [disabled] = "!activate" (click) = "saveExperience()">Select</button>
  </div>
</div>