import { HttpPromiseService } from './http-promise.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-promise.service";
export class BadgeService {
    constructor(httpPromiseService) {
        this.httpPromiseService = httpPromiseService;
    }
    applyForBadge(userId) {
        return this.httpPromiseService.httpPostRequest(`/api/badgeRequests`, { userId: `${userId}` });
    }
    getBadges(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/badgeRequests?id=${userId}`);
    }
}
BadgeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BadgeService_Factory() { return new BadgeService(i0.ɵɵinject(i1.HttpPromiseService)); }, token: BadgeService, providedIn: "root" });
