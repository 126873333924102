import * as tslib_1 from "tslib";
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { Platform } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { JobService } from 'src/modules/shared/services/job.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/local-storage.service";
import * as i2 from "@ionic/angular";
import * as i3 from "../../shared/services/popup.service";
import * as i4 from "../../shared/services/job.service";
import * as i5 from "../../shared/services/loading.service";
import * as i6 from "@ionic-native/geolocation/ngx/index";
import * as i7 from "@ionic-native/diagnostic/ngx/index";
export class TalentLocationTrackingService {
    constructor(localStorageService, platform, popupService, jobService, loadingService, geolocation, diagnostic) {
        this.localStorageService = localStorageService;
        this.platform = platform;
        this.popupService = popupService;
        this.jobService = jobService;
        this.loadingService = loadingService;
        this.geolocation = geolocation;
        this.diagnostic = diagnostic;
    }
    // DEPRECATED -- DO NOT CHANGE IT OR WORK ON IT
    checkIfUserIn100meters(jobId, operation, success, error) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isBrowser = window.location.href.indexOf('app.staffy.com') > -1;
            const isCordova = this.platform.is('cordova');
            const user = yield this.localStorageService.getLoggedInUserInfo();
            if (isCordova && !isBrowser && user) {
                const loading = yield this.loadingService.showLoading().toPromise();
                this.diagnostic.isLocationEnabled().then(isEnabled => {
                    if (!isEnabled) {
                        this.loadingService.hideLoading(loading);
                        this.showLocationPermissionDenialPopup();
                        return;
                    }
                    // location is enabled
                    // now try to get user's current position
                    this.geolocation
                        .getCurrentPosition({
                        enableHighAccuracy: true
                    })
                        .then(
                    // got the current position
                    (resp) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        try {
                            // ensuring that the user is within 150 meters of the location
                            yield this.jobService.ensureLocation(user._id, jobId, {
                                lat: resp.coords.latitude,
                                lng: resp.coords.longitude,
                                operation
                            });
                            // user is within 150 meters
                            success();
                        }
                        catch (err) {
                            // user is not at the location, show error
                            console.log('err', err);
                            error();
                        }
                        finally {
                            this.loadingService.hideLoading(loading);
                        }
                    }))
                        .catch(error => {
                        // unable to retrive the location
                        // maybe user denied the permission, so show the permission
                        // message
                        this.loadingService.hideLoading(loading);
                        this.showLocationPermissionDenialPopup(`Please enable location access for staffy`);
                        console.log('Error getting location', error);
                    });
                }, err => {
                    // location is not enabled or failed to get the location enabled status
                    this.loadingService.hideLoading(loading);
                    this.showLocationPermissionDenialPopup();
                });
            }
            else {
                success();
            }
        });
    }
    // This is refactored version,
    ensureUserIn100meters(jobId, operation) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                const isBrowser = window.location.href.indexOf('app.staffy.com') > -1;
                const isCordova = this.platform.is('cordova');
                const user = yield this.localStorageService.getLoggedInUserInfo();
                if (!isCordova && !user) {
                    // because we can't determine the location
                    throw new Error(`can't determine user's location`);
                }
                yield this.ensureLocationIsEnabled();
                // location is enabled
                // now try to get user's current position
                const userLocation = yield this.getUserLocation();
                // got the user location
                yield this.ensureUserIsOnSite(user, jobId, userLocation, operation);
                // user is on site
                return userLocation;
            }
            catch (e) {
                console.log('error in checking user location', e);
                // rethrow the error
                throw e;
            }
            finally {
                // hide the loader
                this.loadingService.hideLoading(loading);
            }
        });
    }
    showLocationPermissionDenialPopup(message) {
        this.popupService.showModal({
            heading: 'Location Access',
            message: message || `You must enable your location to proceed`,
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
        }, () => {
            this.diagnostic.switchToLocationSettings();
        });
    }
    ensureLocationIsEnabled() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const isEnabled = yield this.diagnostic.isLocationEnabled();
                if (!isEnabled) {
                    this.showLocationPermissionDenialPopup();
                    throw new Error('Location is not enabled');
                }
            }
            catch (error) {
                console.log('Error getting location settings', error);
                this.showLocationPermissionDenialPopup();
                throw new Error('Error in getting location settings');
            }
            return;
        });
    }
    getUserLocation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const userLocation = yield this.geolocation.getCurrentPosition({
                    enableHighAccuracy: true
                });
                return userLocation;
            }
            catch (e) {
                this.showLocationPermissionDenialPopup();
                throw new Error('Error in getting location');
            }
        });
    }
    ensureUserIsOnSite(user, jobId, userLocation, operation) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // ensuring that the user is within 150 meters of the location
            try {
                yield this.jobService.ensureLocation(user._id, jobId, {
                    lat: userLocation.coords.latitude,
                    lng: userLocation.coords.longitude,
                    operation
                });
                // user is within 150 meters
            }
            catch (err) {
                this.showInAccurateLocationPopup();
                throw new Error('User is not within 150meters');
            }
        });
    }
    showInAccurateLocationPopup() {
        this.popupService.showModal({
            heading: 'Sorry',
            message: `You can only start this shift within 150 metres of the location.`,
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
        });
    }
}
TalentLocationTrackingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TalentLocationTrackingService_Factory() { return new TalentLocationTrackingService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.Platform), i0.ɵɵinject(i3.PopupService), i0.ɵɵinject(i4.JobService), i0.ɵɵinject(i5.LoadingService), i0.ɵɵinject(i6.Geolocation), i0.ɵɵinject(i7.Diagnostic)); }, token: TalentLocationTrackingService, providedIn: "root" });
