import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ICertificateSpecification } from 'src/modules/talent-dashboard/interfaces/certificate-specification.interface';
import { IVerificationDate } from '../interfaces/verification-date.interface';

@Injectable({ providedIn: 'root' })

export class CertificateValidationRulesService {

parseValidityString(validity: string) : { period: number, duration: string } {
    // Split the validity string by space
    const dateArray = validity.split(' ');
    // Return an object containing period and duration
    return {period: parseInt(dateArray[0]) , duration: dateArray[1]};
}

validateRequiredDate(selectedDate: moment.Moment, timeZone: string, key: "issueDate" | "expiryDate", certificateSpecification: ICertificateSpecification) : boolean {
    
    const currentDate = moment().tz(timeZone);

    //If the selected date is expiry date then check if its after the current date
    //If the selected dates is issue date, apply the validation rules
    if(key === 'expiryDate') {
        return selectedDate.isAfter(currentDate.startOf('day'));
    }
   
    const validityPeriod = this.getValidityPeriod(certificateSpecification, key);
    //Break the duration which is stored as string into duration and period
    //For example: "6 months" will be parsed as 6, "months"
    const {period, duration} = this.parseValidityString(validityPeriod);
    
    const validUntil = moment.tz(selectedDate, timeZone).add(period, duration as moment.DurationInputArg2).endOf('day');

    //Compare today's date if it is within the expiration date, return true
    if(currentDate.isBefore(validUntil)) {
        return true;
    }

    //Otherwise the issue date of certificate is consider to be expired, return false
    return false;
}

checkIfDeclarationIsValid(vscDeclarationExpiry: number, timeZone: string): boolean {
    if(!vscDeclarationExpiry) {
        return false;
    }

    // Convert Unix timestamp to a Moment object
    const expiryMoment = moment.unix(vscDeclarationExpiry).tz(timeZone);
    const currentMoment = moment().tz(timeZone);


    // Check if the expiry date is valid and in the future
    if (!expiryMoment.isValid() || !expiryMoment.isAfter(currentMoment)) {
        return false;
    } 

    return true;
}

unitOfTimePassedSinceDeclarationExpired(declarationExpiryDateUnix: number, timeZone: string, unitOfTime?: any): number {
    const currentDate = moment().tz(timeZone);
    const expiryDate = moment.unix(declarationExpiryDateUnix).tz(timeZone);
    //Return difference in weeks
    return currentDate.diff(expiryDate, unitOfTime);

}

getMaxDateByCertificateDate(key?: 'issueDate' | 'expiryDate'): string {
    //Set issue date max date to be end of day and expiry date limit to be 5 years
    const maxDate = {
        'issueDate': moment().tz('America/Toronto').endOf('day').format('YYYY-MM-DD'), 
        'expiryDate':  moment().tz('America/Toronto').add(10, 'years').endOf('year').format('YYYY-MM-DD'), 
    }

    return maxDate[key];
}

// Find validity period of selected date
getValidityPeriod(certificateSpecification: ICertificateSpecification, key: "issueDate" | "expiryDate"): string | undefined {
    
    //Return if no certificate specification found or no date check is required
    if(!certificateSpecification || Object.keys(certificateSpecification.datesRequired).length === 0 || !certificateSpecification.datesRequired[key]) {
        return;
    }

    // Get the date specification for the specified key from the certificate specification
    const dateSpec = certificateSpecification.datesRequired[key];
    
    // Return the validity from the validation period of the date specification, or null if not found
    return dateSpec.validationPeriod[0].validity || null;
  }
}

