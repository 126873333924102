<div class="notice-wrapper">
  <div class="notice-head">
    <a  class="close" (click)="closeModal()"><i class = "fa fa-times"></i></a>
    <img src="assets/images/alert.png" alt="alret">
  </div>
  <ng-container>
    <div class="notice-text">
      <h1>{{ notices.title }}</h1>
      <div class="para">{{ notices.shortNotice }}</div>
    </div>
    <div class="btn-wrapper">
      <ion-button color="basic" class="ion-no-margin btn" (click)="acceptDoc()">
        I Accept
      </ion-button>
    </div>
  </ng-container>
</div>