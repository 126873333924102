<div class = "main-container">
<div class = "middle-section">
  <ion-grid>
      <ion-row class="ion-justify-content-center add-certificates">
          <ion-col size="12">
              <div class="shift-and-contract certificates" *ngIf = "stafferCertificates">
                      <!-- COVID -->
                      <div class="requirement-padding covid">
                          <p class="shift-requirement-font"><b>
                              {{'PROFILE.COVID credential' | translate}} <span class="required">(Required)</span></b>
                          </p>
                      </div>
                      
                      <div *ngFor = "let certificate of covidCertificates">
                          <div class="requirement-padding not-uploaded" 
                          (click) = "uploadCertificate(certificate.name)"
                          [ngClass]="{'uploaded' : [certState.passed, certState.rejected].includes(getCertificateStatus(certificate.name))}">
                              <p class="shift-requirement-font" [ngClass] = "{'max-width': getCertificateStatus(certificate.name) !== certState.rejected}">{{certificate.displayName || certificate.name}}
                                <span *ngIf = "certificate.isRequired"class = "required">(Required)</span>
                              </p>
                              <div class="button-container">
                                  <i class="fa fa-check" *ngIf="getCertificateStatus(certificate.name) === certState.passed"></i>
                                  <i *ngIf="[certState.missing, certState.deleted].includes(getCertificateStatus(certificate.name))" class="fa fa-arrow-right" aria-hidden="true"></i>
                                  
                                  <div class =  "rejection-container" *ngIf="getCertificateStatus(certificate.name) === certState.rejected">
                                      <button class = "reupload">Reupload</button>
                                      <i class="fa fa-times" aria-hidden="true"></i>
                                  </div>
                              </div>
                          </div>
                      </div>

                    <!-- Vulnerable Sector Check -->
                    <ng-container *ngIf = "isVSCRequired">
                        <div class="requirement-padding other-certificates">
                            <p class="shift-requirement-font"><b>
                                Vulnerable Sector Check<span class="required"> (Required)</span></b>
                            </p>
                        </div>
                        
                        <div *ngFor = "let certificate of vscCertificates; let i = index" >
                            <div class="requirement-padding not-uploaded" 
                            (click) = "uploadCertificate(certificate.name)"
                            [ngClass]="{'uploaded' : [certState.passed, certState.rejected].includes(getCertificateStatus(certificate.name))}">
                                <p class="shift-requirement-font" [ngClass] = "{'max-width': getCertificateStatus(certificate.name) !== certState.rejected}">{{certificate.displayName || certificate.name}}
                                </p>
                                <div class="button-container">
                                <i class="fa fa-check" *ngIf="getCertificateStatus(certificate.name) === certState.passed"></i>
                                <i *ngIf="[certState.missing, certState.deleted].includes(getCertificateStatus(certificate.name))" class="fa fa-arrow-right" aria-hidden="true"></i>
                                
                                <div class =  "rejection-container" *ngIf="getCertificateStatus(certificate.name) === certState.rejected">
                                    <button class = "reupload">Reupload</button>
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </div>
                                </div>
                            </div>

                            <p class = "or-text" *ngIf = "i === 0">Or</p>
                            <p class = "request-letter" *ngIf = "i === 1">Don't know how to apply to VSC? <span class = "hyperlink" (click) = "showVSCRequestPopup()">Click here</span></p>
                        </div>
                    </ng-container>

                      <!-- HealthCare -->
                      <div class="requirement-padding other-certificates">
                          <p class="shift-requirement-font"><b>
                                  {{'PROFILE.Healthcare Credentials' | translate}}
                              </b></p>
                      </div>
                      
                      <div *ngFor = "let certificate of healthcareCertificates">
                          <div class="requirement-padding not-uploaded" 
                          (click) = "uploadCertificate(certificate.name)"
                          [ngClass]="{'uploaded' : [certState.passed, certState.rejected].includes(getCertificateStatus(certificate.name))}">
                              <p class="shift-requirement-font" [ngClass] = "{'max-width': getCertificateStatus(certificate.name) !== certState.rejected}">{{certificate.displayName || certificate.name}}
                                <span *ngIf = "certificate.isRequired"class = "required">(Required)</span>
                              </p>
                              <div class="button-container">
                                <i class="fa fa-check" *ngIf="getCertificateStatus(certificate.name) === certState.passed"></i>
                                <i *ngIf="[certState.missing, certState.deleted].includes(getCertificateStatus(certificate.name))" class="fa fa-arrow-right" aria-hidden="true"></i>
                                
                                <div class =  "rejection-container" *ngIf="getCertificateStatus(certificate.name) === certState.rejected">
                                    <button class = "reupload">Reupload</button>
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </div>
                              </div>
                          </div>
                      </div>
                  
                      
                      <!-- Food handler and smart serve certificate -->
                      <div *ngIf = "!isHCW">
                          <div class="requirement-padding other-certificates">
                              <p class="shift-requirement-font"><b>
                                  Other
                              </b></p>
                          </div>

                          <div *ngFor = "let certificate of otherCertificates">
                              <div class="requirement-padding not-uploaded" 
                              (click) = "uploadCertificate(certificate.name)"
                              [ngClass]="{'uploaded' : [certState.passed, certState.rejected].includes(getCertificateStatus(certificate.name))}">
                                  <p class="shift-requirement-font" [ngClass] = "{'max-width': getCertificateStatus(certificate.name) !== certState.rejected}">{{certificate.displayName || certificate.name}}
                                    <span *ngIf = "certificate.isRequired"class = "required">(Required)</span>
                                  </p>
                                  <div class="button-container">
                                    <i class="fa fa-check" *ngIf="getCertificateStatus(certificate.name) === certState.passed"></i>
                                    <i *ngIf="[certState.missing, certState.deleted].includes(getCertificateStatus(certificate.name))" class="fa fa-arrow-right" aria-hidden="true"></i>
                                    
                                    <div class =  "rejection-container" *ngIf="getCertificateStatus(certificate.name) === certState.rejected">
                                        <button class = "reupload">Reupload</button>
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      
                      <!-- Dietary Aide -->
                      <div class="requirement-padding other-certificates">
                          <p class="shift-requirement-font"><b>
                                  {{'PROFILE.Dietary aide credentials' | translate}}
                              </b></p>
                      </div>
                  
                      <div *ngFor = "let certificate of dietaryaideCertificates">
                          <div class="requirement-padding not-uploaded" 
                          (click) = "uploadCertificate(certificate.name)"
                          [ngClass]="{'uploaded' : [certState.passed, certState.rejected].includes(getCertificateStatus(certificate.name))}">
                              <p class="shift-requirement-font" [ngClass] = "{'max-width': getCertificateStatus(certificate.name) !== certState.rejected}">{{certificate.displayName || certificate.name}}
                                <span *ngIf = "certificate.isRequired"class = "required">(Required)</span>
                              </p>
                              <div class="button-container" >
                                <i class="fa fa-check" *ngIf="getCertificateStatus(certificate.name) === certState.passed"></i>
                                <i *ngIf="[certState.missing, certState.deleted].includes(getCertificateStatus(certificate.name))" class="fa fa-arrow-right" aria-hidden="true"></i>
                                
                                <div class =  "rejection-container" *ngIf="getCertificateStatus(certificate.name) === certState.rejected">
                                    <button class = "reupload">Reupload</button>
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </div>
                              </div>
                          </div>
                      </div>

                      <!-- Personal Support Worker -->
                      <div class="requirement-padding other-certificates">
                          <p class="shift-requirement-font"><b>PSW/UCP credentials</b></p>
                      </div>

                      <div *ngFor = "let certificate of pswCertificates">
                          <div class="requirement-padding not-uploaded" 
                          (click) = "uploadCertificate(certificate.name)"
                          [ngClass]="{'uploaded' : [certState.passed, certState.rejected].includes(getCertificateStatus(certificate.name))}">
                              <p class="shift-requirement-font" [ngClass] = "{'max-width': getCertificateStatus(certificate.name) !== certState.rejected}">{{certificate.displayName || certificate.name}}
                                <span *ngIf = "certificate.isRequired"class = "required">(Required)</span>
                              </p>
                              <div class="button-container" >
                                <i class="fa fa-check" *ngIf="getCertificateStatus(certificate.name) === certState.passed"></i>
                                <i *ngIf="[certState.missing, certState.deleted].includes(getCertificateStatus(certificate.name))" class="fa fa-arrow-right" aria-hidden="true"></i>
                                
                                <div class =  "rejection-container" *ngIf="getCertificateStatus(certificate.name) === certState.rejected">
                                    <button class = "reupload">Reupload</button>
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </div>
                              </div>
                          </div>
                      </div>
              </div>
          </ion-col>
      </ion-row>
  </ion-grid>
</div>
</div>