import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { dataURLtoFile } from '../../utils/helpers/images.helper';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { chooseImageFromMobileAction } from 'src/modules/onboarding-company/+store/actions/onboarding-company.actions';
import { stafferChooseImageFromMobileAction } from 'src/modules/onboarding-staffer/+store/actions/onboarding-staffer.actions';
import { UserTypeEnum } from 'src/modules/authentication/enums/user-type.enum';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
class ImageSnippet {
    constructor(src, file) {
        this.src = src;
        this.file = file;
    }
}
export class ChoosePictureComponent {
    constructor(platform, talentJobFormatService, store) {
        this.platform = platform;
        this.talentJobFormatService = talentJobFormatService;
        this.store = store;
        this.formSubmitted = new EventEmitter();
        this.allowCameraSwitch = true;
        this.multipleWebcamsAvailable = false;
        this.isCamAllowed = true;
        this.errors = [];
        this.trigger = new Subject();
    }
    ngOnInit() {
        this.isBrowser = this.talentJobFormatService.isBrowserCheck();
        this.isCordova = this.platform.is('cordova');
        this.onResize();
    }
    onResize(event) {
        const win = !!event ? event.target : window;
        this.width = win.innerWidth;
    }
    processFile(imageInput) {
        const file = imageInput.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);
            const formData = new FormData();
            formData.append('images', this.selectedFile.file);
            this.formSubmitted.emit(formData);
        };
        reader.readAsDataURL(file);
    }
    triggerSnapshot() {
        this.trigger.next();
    }
    handleInitError(error) {
        this.errors.push(error);
    }
    get triggerObservable() {
        return this.trigger.asObservable();
    }
    handleImage(webcamImage) {
        const file = dataURLtoFile(webcamImage.imageAsDataUrl, 'image.png');
        const formData = new FormData();
        formData.append('images', file);
        this.formSubmitted.emit(formData);
    }
    takePhoto(sourceType) {
        this.platform.ready().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.user.type === UserTypeEnum.EMPLOYER) {
                // for company
                this.store.dispatch(chooseImageFromMobileAction({
                    sourceType
                }));
            }
            else {
                // for staffer
                this.store.dispatch(stafferChooseImageFromMobileAction({
                    sourceType
                }));
            }
        }));
    }
}
