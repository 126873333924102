<div class = "container" *ngIf="!isCordova || isBrowser">
  <div class="wrapper ion-text-center" (window:resize)="onResize($event)">
    <webcam [width]="width" [height]="250" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
      [imageQuality]="1" (initError)="handleInitError($event)"></webcam>
  </div>
  <div class="ion-text-center button-bg">
    <ion-button color = "basic" class="snap-btn" (click)=" triggerSnapshot();">
      {{ 'SHARED_IMAGE_COMPONENT.snapshot' | translate }}
    </ion-button>

    <label class="custom-file-upload" *ngIf = "selectImage">
      {{ 'SHARED_IMAGE_COMPONENT.chooseFromSystem' | translate }}
      <input #imageInput id="chooseFile" type="file" accept="image/*" (change)="processFile(imageInput)">
    </label>
  </div>
</div>

<div *ngIf="isCordova && !isBrowser" class="cordova-wrapper">
  <!-- <ion-button color = "basic" class="snap-btn" (click)="takePhoto('CAMERA')">
    Take Photo
  </ion-button> -->

  <ion-button *ngIf = "type != 'staffer'" color = "basic" class="snap-btn" (click)="takePhoto('GALLERY')">
    Choose Photo
  </ion-button>
</div>