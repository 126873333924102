import { Component, OnInit, Input} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-searchable-select-modal',
  templateUrl: './searchable-select-modal.component.html',
  styleUrls: ['./searchable-select-modal.component.scss'],
})
export class SearchableSelectModalComponent implements OnInit {

  @Input() options: Array<string>;

  @Input() selectedOptions: Array<string> = [];

  @Input() onCancel;
  
  @Input() onSelect;

  @Input() multiple : Boolean = false

  searchItem: string;

  originalOptions : Array<string>=[] 

  constructor( private modalController: ModalController){
  }

  ngOnInit() {
    this.originalOptions=[...this.options]
  }

  /**
   * 
   * @param option 
   * @description this function checks whether option is preselected or not 
   */

  isSelectedOption(option : string){
    return this.selectedOptions.includes(option)
  }

  /**
   * 
   * @param check 
   * @param option 
   * @description this function called on checkbox changes
   */
  handleCheckboxSelect(check: Boolean, option:string){
    if(check && !this.multiple){
      this.selectedOptions = []
    }

    if (check) {
      this.selectedOptions.push(option)
    } else {
      this.selectedOptions= this.selectedOptions.filter(item=>item!==option)
    }
  }

  /**
   * @description this function called on searchbar changes
   */
  handleSearchChange(){
    if(!this.searchItem){
      this.handleClearSearch()
    }
    this.options=this.originalOptions.filter(item=>{
        return item.toLowerCase().includes(this.searchItem.toLowerCase())
    })
  }


  /**
   * @description this function called on clear searchbar change
   */
  handleClearSearch(){
    this.options=[...this.originalOptions]
  }

}
