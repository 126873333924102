import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-select-v2',
  templateUrl: './form-select-v2.component.html',
  styleUrls: ['./form-select-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponentV2),
      multi: true
    }
  ]
})
export class FormSelectComponentV2 implements OnInit, ControlValueAccessor {

  @Input() labelText: string;
  @Input() options : Array<String | Object> = [];
  @Input() labelKey: string;
  @Input() valueKey: string;
  @Input() placeholder: string;
  @Input() disableList?: boolean = false;
  @Input() control: FormControl;
  @Input() returnObject: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();


  _value: any;
  propagateChange = (_: any) => {};
  propagateTouched = (_: any) => {};

  constructor(

  ) { }

  ngOnInit() {
  }


  get value(): any {
    return this._value;
  }

  set value(value:  any) {
    this._value = value;
    this.propagateChange(this._value);
    this.onChange.emit(value);
  }


  // fetchObject(): Object {
  //   return this.options.find( (obj) => {return obj[this.dataKey] === this.value})
  // }

  handlePopup() {
    // this.showPopup.emit();
  }

  //Form Accessor Functions for custom components
  writeValue(value: any): void {
    this._value = value;
  }
  
  touched($event){
    this.propagateTouched($event);
  }

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

}
