import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { BaseUrlInterceptor } from './base-url';
import { ConfigService } from 'src/app/services/config.service';
const authInterceptorFactory = (storage, store, configService) => new AuthInterceptor(storage, store, configService);
const ɵ0 = authInterceptorFactory;
export class InterceptorsModule {
    static forRoot() {
        return {
            ngModule: InterceptorsModule,
            providers: [
                { useFactory: authInterceptorFactory, provide: HTTP_INTERCEPTORS, deps: [LocalStorageService, Store, ConfigService], multi: true },
                { useClass: BaseUrlInterceptor, provide: HTTP_INTERCEPTORS, multi: true },
            ]
        };
    }
}
export { ɵ0 };
