/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contractor-image-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./contractor-image-card.component";
import * as i4 from "../../services/images.service";
var styles_ContractorImageCardComponent = [i0.styles];
var RenderType_ContractorImageCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContractorImageCardComponent, data: {} });
export { RenderType_ContractorImageCardComponent as RenderType_ContractorImageCardComponent };
function View_ContractorImageCardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.profileUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_ContractorImageCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "img-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "image-circle"]], null, [[null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.mouseOver(_v.parent.context.$implicit.firstName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorImageCardComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, [["attendant.firstName", 1]], null, 1, "div", [["class", "tooltip-name"]], [[1, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.profileUrl) && _co.showImage); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.firstName; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.firstName; var currVal_3 = _v.parent.context.$implicit.lastName; _ck(_v, 5, 0, currVal_2, currVal_3); }); }
function View_ContractorImageCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorImageCardComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index < _co.maxSliceCount); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContractorImageCardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "dotted-circle darkFont"]], null, null, null, null, null))], null, null); }
function View_ContractorImageCardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "remaining-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["+", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contractorInfo.length - 7); _ck(_v, 1, 0, currVal_0); }); }
function View_ContractorImageCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ContractorImageCardComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorImageCardComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorImageCardComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.contractorInfo, 0, _co.maxSliceCount)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.jobStatus === "open") || (_co.jobStatus === "partial")); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.contractorInfo.length >= _co.maxSliceCount); _ck(_v, 7, 0, currVal_2); }, null); }
function View_ContractorImageCardComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "dotted-circle darkFont"]], null, null, null, null, null))], null, null); }
export function View_ContractorImageCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorImageCardComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorImageCardComponent_7)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.contractorInfo == null) ? null : _co.contractorInfo.length) && (_co.jobStatus !== "open")); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.jobStatus === "open"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ContractorImageCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contractor-image-card", [], null, null, null, View_ContractorImageCardComponent_0, RenderType_ContractorImageCardComponent)), i1.ɵdid(1, 4308992, null, 0, i3.ContractorImageCardComponent, [i4.ImagesService, i1.ElementRef, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractorImageCardComponentNgFactory = i1.ɵccf("app-contractor-image-card", i3.ContractorImageCardComponent, View_ContractorImageCardComponent_Host_0, { maxSliceCount: "maxSliceCount", jobStatus: "jobStatus", contractorInfo: "contractorInfo" }, {}, []);
export { ContractorImageCardComponentNgFactory as ContractorImageCardComponentNgFactory };
