/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./choose-picture-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/ngx-webcam/ngx-webcam.ngfactory";
import * as i4 from "ngx-webcam";
import * as i5 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i6 from "@ionic/angular";
import * as i7 from "@angular/common";
import * as i8 from "./choose-picture-modal.component";
import * as i9 from "../../services/images.service";
import * as i10 from "../../../talent-dashboard/services/talent-job-format.service";
var styles_ChoosePictureModalComponent = [i0.styles];
var RenderType_ChoosePictureModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChoosePictureModalComponent, data: {} });
export { RenderType_ChoosePictureModalComponent as RenderType_ChoosePictureModalComponent };
function View_ChoosePictureModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "label", [["class", "custom-file-upload"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, [["imageInput", 1]], null, 0, "input", [["accept", "image/*"], ["id", "chooseFile"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.processFile(i1.ɵnov(_v, 3)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("SHARED_IMAGE_COMPONENT.chooseFromSystem")); _ck(_v, 1, 0, currVal_0); }); }
function View_ChoosePictureModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "wrapper ion-text-center"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (_co.onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "webcam", [], null, [[null, "imageCapture"], [null, "initError"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("imageCapture" === en)) {
        var pd_0 = (_co.handleImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("initError" === en)) {
        var pd_1 = (_co.handleInitError($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_WebcamComponent_0, i3.RenderType_WebcamComponent)), i1.ɵdid(3, 4374528, null, 0, i4.WebcamComponent, [], { width: [0, "width"], height: [1, "height"], imageQuality: [2, "imageQuality"], trigger: [3, "trigger"] }, { imageCapture: "imageCapture", initError: "initError" }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "ion-text-center button-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "ion-button", [["class", "snap-btn"], ["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerSnapshot() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IonButton_0, i5.RenderType_IonButton)), i1.ɵdid(6, 49152, null, 0, i6.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(7, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureModalComponent_2)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = 250; var currVal_2 = 1; var currVal_3 = _co.triggerObservable; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "basic"; _ck(_v, 6, 0, currVal_4); var currVal_6 = _co.selectImage; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_5 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("SHARED_IMAGE_COMPONENT.snapshot")); _ck(_v, 7, 0, currVal_5); }); }
function View_ChoosePictureModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-button", [["class", "snap-btn"], ["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.takePhoto("GALLERY") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IonButton_0, i5.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i6.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Choose Photo "]))], function (_ck, _v) { var currVal_0 = "basic"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChoosePictureModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "cordova-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureModalComponent_4)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type != "staffer"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChoosePictureModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChoosePictureModalComponent_3)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isCordova || _co.isBrowser); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isCordova && !_co.isBrowser); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ChoosePictureModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-choose-picture-modal", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChoosePictureModalComponent_0, RenderType_ChoosePictureModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.ChoosePictureModalComponent, [i6.Platform, i9.ImagesService, i6.ModalController, i10.TalentJobFormatService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChoosePictureModalComponentNgFactory = i1.ɵccf("shared-choose-picture-modal", i8.ChoosePictureModalComponent, View_ChoosePictureModalComponent_Host_0, { selectImage: "selectImage", type: "type" }, {}, []);
export { ChoosePictureModalComponentNgFactory as ChoosePictureModalComponentNgFactory };
