<div class = "staffer">
  <i class = "fa fa-times" (click) = "dismissPopup()"></i>
  <div class="ion-text-center child detail">
    <div class = "img-container">
    <img *ngIf="data.imgURL" src="{{data.imgURL}}">
    </div>
    <div class = "heading" >
      <p *ngIf = "data.message" class="ov-auto para" [innerHTML]="data.message | replaceLineBreaks"></p>
    </div>
  
    <div class="buttons-section">
      <div class = "ss-container">
        <i class="fab fa-facebook-square" aria-hidden="true"></i>
        <ion-button id="success-popup-btn" class=" ion-no-margin btn success-popup-ok" (click) = "socialSiteNaviate('facebook')">Join</ion-button>
      </div>

      <div class = "ss-container">
        <i class="fab fa-instagram" aria-hidden="true"></i>
        <ion-button id="success-popup-btn" class=" ion-no-margin btn success-popup-ok" (click) = "socialSiteNaviate('instagram')">Follow</ion-button>
      </div>
    </div>

    <div class = "navigate-btn" (click) = "navigateToHome()">
      <span class = "available-shifts" >Check out available shifts</span>
      <i class = "fa fa-chevron-right"></i>
    </div>
  </div>
  </div>
