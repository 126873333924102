import * as tslib_1 from "tslib";
import { HttpPromiseService } from './http-promise.service';
import { orderBy } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "./http-promise.service";
export class SkillsService {
    constructor(httpPromiseService) {
        this.httpPromiseService = httpPromiseService;
        this.skills = null;
        this.isLoadingSkills = false;
    }
    getAllSkills() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.skills && !this.isLoadingSkills) {
                this.isLoadingSkills = true;
                this.skills = this.httpPromiseService.httpGetRequest('/api/industries/getSkillsByJson');
                yield this.skills;
                this.isLoadingSkills = false;
            }
            return this.skills;
        });
    }
    getListOfAllSkills(options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { industriesToInclude, outputFormat, filters } = options;
            // fetch
            const skillsByIndustry = yield this.getAllSkills();
            const industriesToKeep = {};
            // filter
            // We need 2 filterations
            // filter by industry
            if (industriesToInclude) {
                industriesToInclude.forEach((industry) => {
                    industriesToKeep[industry] = skillsByIndustry.industries[industry];
                });
            }
            else {
                Object.keys(skillsByIndustry.industries).forEach((industry) => {
                    industriesToKeep[industry] = skillsByIndustry.industries[industry];
                });
            }
            // filter by flags
            // before filteration by flags combine all the industries in one array
            const combinedSkills = this.combineSkills(industriesToKeep);
            const filteredSkills = this.applyFilters(combinedSkills, filters);
            // transformations
            let transformedSkills = {} || [];
            switch (outputFormat) {
                // {skillName: skillRate}
                case "rates":
                    Object.values(filteredSkills).forEach((eachSkill) => {
                        transformedSkills[eachSkill['name']] = eachSkill['rateCA'];
                    });
                    break;
                case "manual_rates":
                    //Map rates into an object consisting of rate and allow change property so it can be re-assigned (deconstruct) 
                    Object.values(filteredSkills).forEach((eachSkill) => {
                        transformedSkills[eachSkill['name']] = { rate: eachSkill['rateCA'], allowChange: true, defaultRate: eachSkill['rateCA'] };
                    });
                    break;
                case 'experience':
                    // {skillName: 'reqExpereince'}
                    Object.values(filteredSkills).forEach((eachSkill) => {
                        transformedSkills[eachSkill['name']] = eachSkill['requiredExperience'];
                    });
                    break;
                case 'names':
                    // [skill1, skill2, skill3] -- names of skills
                    transformedSkills = orderBy(filteredSkills.map((eachSkill) => eachSkill['name']));
                    break;
                default:
                    transformedSkills = filteredSkills;
                    break;
            }
            return transformedSkills;
        });
    }
    combineSkills(skillsByIndustry) {
        const skills = [];
        Object.keys(skillsByIndustry).forEach((industry) => {
            skills.push(...Object.values(skillsByIndustry[industry]));
        });
        return skills;
    }
    applyFilters(combinedSkills, filters) {
        if (!filters) {
            return combinedSkills;
        }
        return combinedSkills.filter(skill => {
            skill.shouldStay = false;
            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i];
                if (skill[filter]) {
                    skill.shouldStay = true;
                    break;
                }
            }
            return skill.shouldStay;
        });
    }
    invalidateSkillsCache() {
        this.skills = null;
    }
}
SkillsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SkillsService_Factory() { return new SkillsService(i0.ɵɵinject(i1.HttpPromiseService)); }, token: SkillsService, providedIn: "root" });
