export enum StatusEnum {
    'notuploaded',
    'missing',
    'fail',
    'pending',
    'pass' ,
    'block', //Block submit button if mandatory certificate(s) are not uploaded
    'unblock'  //Unblock submit button after checking mandatory certificate(s) are uploaded/valid and check rest of the certificates that are required butnt mandatory
}


//Mandatory certificates will disable the submit button located at onboarding screen
//Required certificates will not disable the submit button located at onboarding screen but it will change the certificate button tag to missing/reupload based upon the required certificate state