import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { uniq } from 'lodash';
import { OnboardingStafferUtilService } from 'src/modules/onboarding-staffer/services/onboarding-staffer-util.service';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { SkillFilterationService } from './skill-filteration.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./local-storage.service";
import * as i3 from "../../onboarding-staffer/services/onboarding-staffer-util.service";
import * as i4 from "./skill-filteration.service";
export class OnBoardingStepService {
    constructor(router, localStorageService, onboardingStafferUtilService, skillFilterationService) {
        this.router = router;
        this.localStorageService = localStorageService;
        this.onboardingStafferUtilService = onboardingStafferUtilService;
        this.skillFilterationService = skillFilterationService;
        this.onboardingSteps = {
            personalInfo: { status: '', route: `onboarding-staffer/personal-information` },
            professionalInfo: { status: '', route: `onboarding-staffer/professional-information` },
            skills: { status: '', route: `onboarding-staffer/skills-and-experience` },
            reference: { status: '', route: `onboarding-staffer/add-reference` },
            certificates: { status: '', route: `talent-dashboard/certificates` },
            uploadApparel: { status: '', route: `onboarding-staffer/upload-equipment` }
        };
    }
    jumpToState(step) {
        this.router.navigateByUrl(this.onboardingSteps[step].route);
    }
    checkSkill(skill) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let expertise;
            const isHCSkill = yield this.skillFilterationService.doesUserHasHCSkills(skill);
            if (!isHCSkill) {
                expertise = 'hospitality';
            }
            else {
                expertise = 'healthcare';
            }
            return expertise;
        });
    }
    stepDetails(step) {
        const onboardingStepDetails = {
            personalInformation: { heading: 'Personal Information', message: 'Your Personal information includes your name, email, phone number and address. We use this information to create your account and show you the available shifts in your region.', imgUrl: '', btn: 'Dismiss', navigateRoute: '' },
            stafferContract: { heading: 'Independent Contractor Agreement', message: 'As an Independent Contractor, you can accept or decline shifts as you wish. The Staffy Terms and Professional Addendum protects that relationship. Please read carefully and accept the terms to continue signing up for the Staffy app.', imgUrl: '', btn: 'Dismiss', navigateRoute: '' },
            professionalInformation: { heading: 'Professional Information', message: ' Your Professional information consists of a headshot or photo of you, your most current CV or Resume, and some background on your professional experience in your own words.', imgUrl: '', btn: 'Dismiss', navigateRoute: '' },
            skills: { heading: 'Skills', message: 'Choose as many skills as you like and include duration of time working within that skill. Depending on the skills you choose, you will be required to upload the required credentials in a separate section within Onboarding.', imgUrl: '', btn: 'Dismiss', navigateRoute: '' },
            references: { heading: 'References', message: 'We require 3(three) references. References can be from the same company/site and may include Personal References if you don’t have professional ones. We will be contacting these people to confirm your experience.', imgUrl: '', btn: 'Dismiss', navigateRoute: '' },
            certificates: { heading: 'Credentials', message: 'Depending on the skills you chose, you may be required to upload credentials, diplomas or attestations so we can confirm your qualifications are current and valid.', imgUrl: '', btn: 'Dismiss', navigateRoute: '' },
            stafferUniform: { heading: 'Apparel/Equipment', message: 'Depending on the skills you chose, you will need to upload equipment or apparel for us to confirm you are ready to work.', imgUrl: '', btn: 'Dismiss', navigateRoute: '' },
            attestation: { heading: 'Healthcare Workers Attestation', message: 'If you signed up for Healthcare, you will need to complete attestations so we can be sure you are up to date with all requirements.', imgUrl: '', btn: 'Dismiss', navigateRoute: '' },
            backgroundCheck: { heading: 'Background Check', message: 'We will need to do a background check before you work your first shift. Enter the information below and we will do the rest.', imgUrl: '', btn: 'Dismiss', navigateRoute: '' }
        };
        return onboardingStepDetails[step];
    }
    allBoardingSteps(skill) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let expertise = yield this.localStorageService.getExpertise();
                if (skill) {
                    expertise = yield this.checkSkill(skill);
                }
                let onboardingSteps = [
                    'personalInfo', 'professionalInfo', 'skills', 'reference', 'certificates'
                ];
                if (expertise == 'hospitality') {
                    onboardingSteps.push('uploadApparel');
                }
                uniq(onboardingSteps);
                return onboardingSteps;
            }
            catch (error) {
                console.log('error while fetching on boarding steps list: ', error);
            }
        });
    }
    updateCompletionStatus(onboardingStep, status, nextStep) {
        this.onboardingSteps[onboardingStep].status = status;
        if (onboardingStep != 'uploadApparel' && nextStep) {
            this.router.navigateByUrl('onboarding-staffer/onboarding-dashboard');
        }
        this.localStorageService.setCompletionStatusOfOnboarding(this.onboardingSteps);
    }
    checkOnboardingStepsStatus(skills) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const expertise = yield this.checkSkill(skills);
            const allOnboardingStatus = yield this.localStorageService.getCompletionStatusOfOnboarding();
            let isCompleted = true;
            if (expertise == 'healthcare') {
                delete allOnboardingStatus['uploadApparel'];
            }
            for (let i in allOnboardingStatus) {
                if ((i != 'certificates' && allOnboardingStatus[i].status == '') || allOnboardingStatus[i].status == 'incomplete') {
                    isCompleted = false;
                }
            }
            return isCompleted;
        });
    }
    checkAllStepsCompleted(onBoardingProcessSteps, userSkills) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allStepsCompleted = yield this.checkOnboardingStepsStatus(userSkills);
            if (onBoardingProcessSteps.length == 5) {
                return allStepsCompleted;
            }
        });
    }
    stepConfirmation(userData) {
        console.log('professionalInformationCheck: ', this.personalInformationCheck(userData), this.professionalInformationCheck(userData), this.skillsInformationCheck(userData), this.referenceInformationCheck(userData), userData);
        if (this.personalInformationCheck(userData) &&
            this.professionalInformationCheck(userData) &&
            this.skillsInformationCheck(userData) &&
            this.referenceInformationCheck(userData))
            return true;
        return false;
    }
    personalInformationCheck(userData) {
        if (userData.phoneNumber && userData.address && userData.address.street && userData.address.code && userData.type)
            return true;
        return false;
    }
    professionalInformationCheck(userData) {
        if (userData.profileUrl && userData.resumeUrl && userData.bio)
            return true;
        return false;
    }
    skillsInformationCheck(userData) {
        if (userData.skills.length > 0)
            return true;
        return false;
    }
    referenceInformationCheck(userData) {
        if (userData.references.length >= 3)
            return true;
        return false;
    }
}
OnBoardingStepService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnBoardingStepService_Factory() { return new OnBoardingStepService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.OnboardingStafferUtilService), i0.ɵɵinject(i4.SkillFilterationService)); }, token: OnBoardingStepService, providedIn: "root" });
