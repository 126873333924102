import { Component, Input, OnInit } from '@angular/core';
import { IClientJob } from 'src/modules/company-dashboard/interfaces/client-job.interface';
import { BroadcastJobsUtilService } from 'src/modules/company-dashboard/services/broadcast-job-utils.service';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { RecurrentShiftService } from '../../services/recurrentShift.service';

@Component({
  selector: 'app-shift-basic-info',
  templateUrl: './shift-basic-info.component.html',
  styleUrls: ['./shift-basic-info.component.scss'],
})
export class ShiftBasicInfoComponent implements OnInit {

  @Input() jobItem: any;
  @Input() unitNumber: Array<string>;

  @Input() readOnly: boolean = false;
  patientId: string = '';
  constructor(
    private talentJobFormatService: TalentJobFormatService,
    private broadcastJobsUtilService: BroadcastJobsUtilService,
    private recurrentShiftService: RecurrentShiftService
  ) { }

  ngOnInit() {
    if(this.jobItem) {
      this.fetchPatientId();
    }
  }

  viewRecurringPropertiesOfShift(jobItem: IClientJob, heading: string, showUnitNumber: boolean) {
    //Format individual jobs into job.jobs Object  
    const job = {
      jobs: jobItem
    };
    
    this.talentJobFormatService.viewRecurringProperties(job, heading, showUnitNumber);
  }

  async addUnitNumber(jobItem: IClientJob) {
    const updatedJob = await this.broadcastJobsUtilService.editUnitNumber(jobItem, this.unitNumber, 'Add'); 
    this.jobItem = updatedJob;
  }

  doesRecurringShiftHasUnitNumber(jobItem: IClientJob) {
    const unitNumber =  this.recurrentShiftService.unitNumberByShift(jobItem);
    
    if(!unitNumber) {
      return false;
    }
    return true;
  }

  fetchPatientId() {
    const firstJob = this.jobItem[0];
    if(!firstJob || !firstJob.homecareShift || !firstJob.homecareShift.patients || firstJob.homecareShift.patients.length == 0) {
      return;
    }
    this.patientId = firstJob.homecareShift.patients.map(patient=>patient.id).join(", ");
  }
}
