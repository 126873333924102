import { Injectable } from '@angular/core';
import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { UserTypeEnum } from 'src/modules/authentication/enums/user-type.enum';
import { orderBy, filter } from 'lodash';
import { PopupService } from './popup.service';
import { Router } from '@angular/router';
import { OnboardingStepEnum } from 'src/modules/shared/enums/onboarding-step.enum';
import { onboardingEnumsToRouteMap } from '../utils/helpers/onboarding-steps-route-map';

@Injectable({ providedIn: 'root' })

export class OnBoardingUtilService {

  constructor(
    private httpPromiseService: HttpPromiseService,
    private localStorageService: LocalStorageService,
    private popupService: PopupService,
    private router: Router
  ) { }


  async checkForVouchersAndCode(user: IUser) {
    const voucher = await this.localStorageService.getVoucherCode();
    const teamCode = await this.localStorageService.getSingleKey('teamCode');
    if (voucher && user.type === UserTypeEnum.EMPLOYER) { // voucher is only for company
      this.redeemVoucherCode(user, voucher);
    }
    if (teamCode && user.type === UserTypeEnum.STAFFER) { // team code is only for staffer
      this.redeemTeamCode(user, teamCode);
    }
  }





  redeemVoucherCode(user: IUser, code: string) {
    try {
      this.redeemCode({
        code,
        timeZone: 'America/' + (user.address.province === 'Texas' ? 'Chicago' : user.address.city),
        userId: user._id,
        walletAmount: user.wallet
      });
    } catch (e) { console.log(e) }
  }

  redeemTeamCode(user: IUser, code: string) {
    try {
      this.addInATeam(user._id, code);
    } catch (e) {
      console.log(e);

    }
  }

  public redeemCode(data: {
    code: string,
    walletAmount: number,
    timeZone: string,
    userId: string
  }) {
    return this.httpPromiseService.httpPostRequest(
      `/api/walletTransactions/ `, data
    );
  }

  addInATeam(userId: string, code: string) {
    return this.httpPromiseService.httpPutRequest(
      `/api/teams/addUserInATeam/${userId} `, { code }
    );
  }

  //rename kitchen black/White to optional if contractor does not have any back of house skill other than dishwasher
  renameApparelForDishwasherSkillOnly(dataArrayBOH) {
    const index = dataArrayBOH.findIndex(skill => skill.name == 'Kitchen Blacks/Whites (Required)');
    if(index > -1 ) { 
      dataArrayBOH[index].name =  'Kitchen Blacks/Whites (Optional)'; 
      dataArrayBOH[index].required = false;
    }
    
  }    

  //sort apparels from required to optional
  sortByRequirementStatus(uniformPicture: { [key: string]: { [key: string]: string } }) {
    return orderBy(Object.keys(uniformPicture).map((key) => {
      return uniformPicture[key];
    }), 'required', ['desc']);
  }

  //show first image in an array and hide all others 
  showFirstImageInArray(uniformArray) {
    uniformArray.forEach((i, index) => {
        i.showDetails = false;
        i.showImage = index === 0 ? true : false;
      })
  }

  evaluateResults(resultantArray: { isBOH: boolean, isFOH: boolean, isGENERAL: boolean, bohCompleted: boolean; fohCompleted: boolean; generalCompleted: boolean; }) : boolean{
  
    if (resultantArray.isFOH && resultantArray.isBOH && resultantArray.isGENERAL) { return resultantArray.bohCompleted && resultantArray.fohCompleted && resultantArray.generalCompleted; }

    if (resultantArray.isBOH && !resultantArray.isFOH && !resultantArray.isGENERAL) { return resultantArray.bohCompleted; }

    if (!resultantArray.isBOH && resultantArray.isFOH && !resultantArray.isGENERAL) { return resultantArray.fohCompleted; }

    if (!resultantArray.isBOH && !resultantArray.isFOH && resultantArray.isGENERAL) { return resultantArray.generalCompleted; }

    if (resultantArray.isBOH && resultantArray.isFOH && !resultantArray.isGENERAL) { return resultantArray.bohCompleted && resultantArray.fohCompleted; }

    if (resultantArray.isBOH && !resultantArray.isFOH && resultantArray.isGENERAL) { return resultantArray.bohCompleted && resultantArray.generalCompleted; }

    if (!resultantArray.isBOH && resultantArray.isFOH && resultantArray.isGENERAL) { return resultantArray.fohCompleted && resultantArray.generalCompleted; }
  }

  checkUniformRequirement(uniformArray: { [key: string]: { status: string; }}, requirement: string[], onboardingCheck? : boolean): boolean {
    //return false if 
    //any required apparel is not uploaded or
    //any required apparel is marked as failed by admin
    return filter(requirement, (i) => {
      return uniformArray[i].status == 'not uploaded' || uniformArray[i].status == 'fail'
    }).length > 0 ? false : true;
  }
  

  checkUniformRequirementOnboarding(uniformArray: { [key: string]: { status: string; }}, requirement: string[]): string {
    //return false if 
    //any required apparel is not uploaded or
    //any required apparel is marked as failed by admin
    //check if all uniform apparels are uploaded
      
    if(this.allApparelsStatus(uniformArray, requirement, 'pending'))
        return 'pending';

    if(this.allApparelsStatus(uniformArray, requirement, 'pass'))
        return 'pending';


    if(this.allApparelsStatus(uniformArray, requirement, 'not uploaded'))
        return 'not uploaded';

    if(this.allApparelsStatus(uniformArray, requirement, 'fail'))
        return 'rejected';

      
  }

  allApparelsStatus(uniformArray, requirement, status) {
    return filter(requirement, (i) => {
      return uniformArray[i].status == status
    }).length > 0 ? false : true;
  }

  checkDocumentExtension(name) {
    return (name.includes('.pdf') == true || name.includes('.doc') == true || name.includes('.docx') == true) ? true : false  
  }

  async successfulQuizPopup() {
    await this.popupService.showModal({
      heading: 'Nicely done!',
      message: 'We have received your application and our team is reviewing it. You will receive an approval email soon. In the meantime, you can browse available opportunities or continue adding to your profile to improve your chances of being approved for better shifts.',
      btn: 'Take me there',
      imgURL: 'assets/images/hands-up.png',
      navigateRoute: 'onboarding-staffer/background-check',
    })
  }

  async unsuccessfulQuizPopup() {
    try {
      await this.popupService.showModal({
        heading: 'Sorry!',
        message: 'You have not passed the quiz, if you want to try again contact us to unlock a new opportunity.',
        btn: 'Contact support',
        imgURL: 'assets/images/hands-up.png',
        navigateRoute: '',
        disableSubmission: true 
      })
    } catch(error) {
      console.log('error: ', error);
    }
  }

  resolveUrl({currentNavStep}: IUser) {

    console.log('current nav step of user: ', currentNavStep);

    // if the route is known, redirect to it, otherwise redirect to expertise
    const route = onboardingEnumsToRouteMap[currentNavStep] || 'onboarding-staffer/expertise';
    return this.router.navigateByUrl(route);
    
  //   if (user.currentNavStep === OnboardingStepEnum.CONFIRM_ADDRESS) {
  //     this.router.navigateByUrl('onboarding-staffer/personal-information');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_EXPERTISE) {
  //       this.router.navigateByUrl('onboarding-staffer/expertise');
  //   }

  //   else if (user.currentNavStep === OnboardingStepEnum.CONTRACTOR_INFORMATION) {
  //     this.router.navigateByUrl('onboarding-staffer/contractor-information');
  //   }
    
  //   else if (user.currentNavStep === OnboardingStepEnum.NAVIGATION_NON_AUTHENTICATE_HOME || user.currentNavStep === OnboardingStepEnum.STAFFER_HOME || user.currentNavStep === '/talent-dashboard/home') {
  //       this.router.navigateByUrl('talent-dashboard/home');
  //   }
  //   else if ((user.currentNavStep === OnboardingStepEnum.STAFFER_LICENSE ||
  //           user.currentNavStep === 'license-agreement'
  //       )) {
  //       this.router.navigateByUrl('onboarding-staffer/license-agreement');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_SKILLS || user.currentNavStep == 'skills-and-experience' || user.currentNavStep == 'stafferSignup') {
  //       this.router.navigateByUrl('onboarding-staffer/skills-and-experience');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_QUIZ || user.currentNavStep === 'quizGuide') {
  //       this.router.navigateByUrl('onboarding-staffer/quiz');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_INSTRUCTION || user.currentNavStep === 'instruction') {
  //       this.router.navigateByUrl('onboarding-staffer/instruction');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_GETREADY || user.currentNavStep === 'get-ready') {
  //       this.router.navigateByUrl('onboarding-staffer/get-ready');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_PRIVACY_POLICY || user.currentNavStep === 'privacy-policy') {
  //       this.router.navigateByUrl('shared/privacy-policy');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_PERSONAL_INFORMATION || user.currentNavStep === 'personal-information' || user.currentNavStep === 'confirmAddress') {
  //       this.router.navigateByUrl('onboarding-staffer/personal-information');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_PROFESSIONAL_INFORMATION || user.currentNavStep === 'professional-information' || user.currentNavStep == 'resumeUpload' || user.currentNavStep == "displayPictureSignup") {
  //       this.router.navigateByUrl('onboarding-staffer/professional-information');
  //   }
    
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_CONTRACT || user.currentNavStep == 'stafferContract' || user.currentNavStep == 'staffer-contract') {
  //           this.router.navigateByUrl('onboarding-staffer/staffer-contract');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_SET_SKILLS_RATE) {
  //       this.router.navigateByUrl('onboarding-staffer/add-skills-rate');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_UPLOAD_EQUIPMENT || user.currentNavStep === 'uniformPicture') {
  //       this.router.navigateByUrl('onboarding-staffer/upload-equipment');
  //   }
  //   else if (user.currentNavStep === OnboardingStepEnum.STAFFER_ADD_REFERENCE) {
  //           this.router.navigateByUrl('onboarding-staffer/add-reference');
  //   }
  //   else if ((user.currentNavStep === OnboardingStepEnum.STAFFER_CERTIFICATES)) {
  //           this.router.navigateByUrl('talent-dashboard/certificates');
  //   }
  //   else if ((user.currentNavStep === OnboardingStepEnum.STAFFER_VSS_ATTESTATION)) {
  //           this.router.navigateByUrl('talent-dashboard/vss-attestation');
  //   }
  //   else if ((user.currentNavStep === OnboardingStepEnum.STAFFER_ADD_BACKGROUND_CHECK
  //           || user.currentNavStep === OnboardingStepEnum.STAFFER_ADD_BACKGROUND_CHECK_CPY || user.currentNavStep === 'certnVerificationForm')) {
  //       this.router.navigateByUrl('onboarding-staffer/background-check');
  //   }

  //   else if ((user.currentNavStep === OnboardingStepEnum.STAFFER_PENDING_STEPS
  //         || user.currentNavStep === 'navigation.onBoardingComplete')) {

  //           this.router.navigateByUrl('onboarding-staffer/get-ready');
  //   } else if (user.currentNavStep === 'workHistory') {
  //       this.router.navigateByUrl('onboarding-staffer/work-history');
  //   } else {
  //       console.log('calling from here ar no url found');
  //       this.router.navigateByUrl('onboarding-staffer/get-ready');
  //   }
  }
  
}
