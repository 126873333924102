import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImagesService } from 'src/modules/shared/services/images.service';
import { ITalentJob } from '../../interfaces/talent-job.interface';
import { TalentDashboardUtilService } from '../../services/talent-dashboard-util.service';
import { TalentJobFormatService } from '../../services/talent-job-format.service';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-shift-details-modal',
  templateUrl: './shift-details-modal.component.html',
  styleUrls: ['./shift-details-modal.component.scss'],
})
export class ShiftDetailsModalComponent implements OnInit {
  profilePicture: string;
  @Input() jobItem: ITalentJob;
  @Input() userData: IUser;
  formattedJobItem;
  potentialEarning: number;
  showDropdownDetails = {
    shiftDescription: false,
    contacts: false,
    allergies: false,
    risks: false,
    diagnoses: false,
    surgeries: false,
    tasks: false,
  };
  constructor(
    private modalController: ModalController,
    private imagesService: ImagesService,
    private talentDashboardUtilService: TalentDashboardUtilService,
    private talentJobFormatService: TalentJobFormatService,
  ) { }

  toggleDropdown = (key) => {
    this.showDropdownDetails[key] = !this.showDropdownDetails[key];
  }

  getProfilePic(profileUrl: { [key: string]: string }) {
    return this.imagesService.getProfilePic(profileUrl);
  }

  getAge(dateOfBirth) {
    if (dateOfBirth) {
      const year = moment().diff(dateOfBirth, 'years', false);
      return year;
    }
    return '';
  }

  closeModal = () => {
    this.modalController.dismiss();
  }

  ngOnInit() {
    console.log(this.jobItem)
    this.profilePicture = this.getProfilePic(this.jobItem.entity.creator.profileUrl);
    // this.potentialEarning = this.talentDashboardUtilService.getcalculatedPotentialEarning(this.jobItem);
    if (this.jobItem) {
      // we do not need to show recurring data, hence marking the shift as single shift
      const formattedData: any[] = this.talentJobFormatService.formatAvailableShifts([{...this.jobItem, recurringShift: {isTrue: false, groupJobId: null}}], this.userData);
      if (formattedData.length > 0) {
        this.formattedJobItem = formattedData[0];
      }

    }
  }

}
