<div class="add-task-wrapper">
  <div class="heading-wrapper">
    <h3 class="ion-text-center title" *ngIf="task._id">
      {{'TASKS.EDIT_TASK' | translate}}
    </h3>
    <h3 class="ion-text-center title" *ngIf="!task._id">
      {{'TASKS.ADD_TASK' | translate}}
    </h3>
    <button class="close-btn" (click)="closeModal()"><i class="fa fa-times"></i></button>
  </div>
  <div class="content-wrapper">
    <ion-list>
      <ion-item>
        <ion-input [(ngModel)]="task.title" placeholder="Task Title"></ion-input>
      </ion-item>
      <ion-item>
        <ion-textarea rows="5" [(ngModel)]="task.description" placeholder="Task Description (Required)"></ion-textarea>
      </ion-item>
    </ion-list>
    <div class="action-container">
      <button class="save-btn" (click)="addTask()">
        {{'TASKS.SAVE' | translate}}
      </button>

    </div>
  </div>

</div>