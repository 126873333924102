import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { addPatientAction, getUserInfoAction, updatePatientAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { UserService } from 'src/modules/shared/services/user.service';
import { BroadcastJobsUtilService } from '../../../company-dashboard/services/broadcast-job-utils.service';
import { IUserAddress } from 'src/modules/authentication/interfaces/user-address.interface';

@Component({
  selector: 'app-new-patient-form',
  templateUrl: './new-patient-form.component.html',
  styleUrls: ['./new-patient-form.component.scss'],
})
export class NewPatientFormComponent implements OnInit {

  @Input() entityId: string;
  @Input() addPatientAddress: Function;
  @Input() class: string;

  @Input() patientId: string;
  @Input() name = '';
  @Input() patientAddress: IUserAddress;
  errMsg = '';

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private broadcastUtilService: BroadcastJobsUtilService,
    private authStore: Store<IAuthState>,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {}

  closeModal(status: string = null): void {
    this.modalController.dismiss(status);
  }

  async addNewPatient(){
    if (!this.name || !this.patientAddress) {
      this.errMsg = 'Invalid: Patient ID or Postal Code'
      console.log('no name or address');
      return;
    }

    try {
      const newPatient = {
        id: this.name,
        address: this.patientAddress
      };
      const res = await this.userService.addNewPatientIntoCompany(this.entityId, newPatient);

      if (res.msg === 'success') {
        this.authStore.dispatch(addPatientAction({patient: res.patient}));
        this.addPatientAddress && this.addPatientAddress(res.patient);
        this.closeModal('success');
      }
    } catch (error) {
      this.closeModal('fail');
      console.log(error);
    }
  }

  async updatePatient(){
    if (!this.name || !this.patientAddress) {
      this.errMsg = 'Invalid: Patient ID or Postal Code'
      console.log('no name or address');
      return;
    }

    try {
      const patient = {
        _id: this.patientId,
        id: this.name,
        address: this.patientAddress
      }
      const res = await this.userService.editCompanyPatient(
        this.entityId, patient
      );

      if (res === 'success') {
        this.authStore.dispatch(updatePatientAction({patient}));
        this.closeModal('success');
      }
    } catch (error) {
      this.closeModal('fail');
      console.log(error);
    }
  }

  getAddress(address: any): void {
    this.errMsg = ""
    const formatedAddress = this.broadcastUtilService.getFormatedAddress(address);
    this.patientAddress = formatedAddress
    this.cdr.detectChanges()
  }

  handlePatientChange(){
    this.errMsg = ""
  }
}