import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { TalentSyncService } from 'src/modules/talent-dashboard/services/talent-sync.service';
import { LocalStorageService } from './local-storage.service';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { UserTypeEnum } from 'src/modules/authentication/enums/user-type.enum';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    constructor(
        private network: Network,
        private talentSyncService: TalentSyncService,
        private localStorageService: LocalStorageService,
        private platform: Platform,
    ) { }

    public appNetworkStatus = new BehaviorSubject<'online' | 'offline'>('online' || 'offline');

    /////////////////////////////////////////////////////////
    watchForNetwork() {
        console.log('watch for network');

        if (this.platform.is('cordova')) {
            // watch network for a disconnection
            this.network.onDisconnect().subscribe(() => {
                console.log('network was disconnected :-(');
                this.appNetworkStatus.next('offline');

            });

            // // stop disconnect watch
            // disconnectSubscription.unsubscribe();


            // watch network for a connection
            this.network.onConnect().subscribe(async () => {
                console.log('network connected!');
                this.appNetworkStatus.next('online');

                const userData: IUser = await this.localStorageService.getLoggedInUserInfo();

                // if user is loggedin and type is staffer and got wifi connection then sync it
                if (userData && userData._id && userData.type === UserTypeEnum.STAFFER) {
                    this.talentSyncService.sync(true);

                }
            });

            // // stop connect watch
            // connectSubscription.unsubscribe();
        } else {
            fromEvent(window, 'offline').subscribe(() => {
                console.log('network disconnected:(');
                this.appNetworkStatus.next('offline');

            }
            );
            fromEvent(window, 'online').subscribe(async () => {
                console.log('network connected!');
                this.appNetworkStatus.next('online');

                const userData: IUser = await this.localStorageService.getLoggedInUserInfo();

                // if user is loggedin and type is staffer and got wifi connection then sync it
                if (userData && userData._id && userData.type === UserTypeEnum.STAFFER) {
                    this.talentSyncService.sync(true);

                }
            });
        }

    }
    /////////////////////////////////////////////////////////





}
