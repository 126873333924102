import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { from, of } from 'rxjs';
export class LoadingService {
    constructor(loadingController) {
        this.loadingController = loadingController;
        this.isDisplayed = false;
    }
    showLoading() {
        // console.log('counter ', this.counter)
        if (this.isDisplayed) {
            return of(this.loading);
        }
        this.isDisplayed = true;
        return from(this.loadingController.create({
            cssClass: 'custom-loader',
            spinner: null,
        }).then((ldr) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = ldr;
            yield ldr.present();
            return ldr;
        })));
    }
    hideLoading(loader) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.loading) {
                yield this.loading.dismiss();
                this.isDisplayed = false;
            }
        });
    }
}
