/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-recurrent-specific-shifts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../edit-recurring-shift-list/edit-recurring-shift-list.component.ngfactory";
import * as i4 from "../edit-recurring-shift-list/edit-recurring-shift-list.component";
import * as i5 from "../pagination/pagination.component.ngfactory";
import * as i6 from "../pagination/pagination.component";
import * as i7 from "@angular/common";
import * as i8 from "./edit-recurrent-specific-shifts.component";
import * as i9 from "../../services/loading.service";
import * as i10 from "../../services/job.service";
import * as i11 from "../../services/popup.service";
import * as i12 from "../../../company-dashboard/services/shift-validator.service";
var styles_EditRecurrentSpecificShiftsComponent = [i0.styles];
var RenderType_EditRecurrentSpecificShiftsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditRecurrentSpecificShiftsComponent, data: {} });
export { RenderType_EditRecurrentSpecificShiftsComponent as RenderType_EditRecurrentSpecificShiftsComponent };
function View_EditRecurrentSpecificShiftsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Shifts List"]))], null, null); }
function View_EditRecurrentSpecificShiftsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "app-edit-recurring-shift-list", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_EditRecurringShiftListComponent_0, i3.RenderType_EditRecurringShiftListComponent)), i1.ɵdid(6, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), i1.ɵdid(9, 114688, null, 0, i4.EditRecurringShiftListComponent, [], { data: [0, "data"], config: [1, "config"], formGroup: [2, "formGroup"], confirmationView: [3, "confirmationView"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.editRecurrentSpecificShiftForm; _ck(_v, 2, 0, currVal_7); var currVal_15 = _co.editRecurrentSpecificShiftForm; _ck(_v, 6, 0, currVal_15); var currVal_16 = _co.data; var currVal_17 = _co.config; var currVal_18 = _co.editRecurrentSpecificShiftForm; var currVal_19 = _co.submitView; _ck(_v, 9, 0, currVal_16, currVal_17, currVal_18, currVal_19); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 8).ngClassValid; var currVal_13 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 5, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
function View_EditRecurrentSpecificShiftsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pagination", [["class", "flex-end"]], null, [[null, "onPageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onPageChange" === en)) {
        var pd_0 = (_co.loadMoreData($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_PaginationComponent_0, i5.RenderType_PaginationComponent)), i1.ɵdid(1, 114688, null, 0, i6.PaginationComponent, [], { totalRecords: [0, "totalRecords"] }, { onPageChange: "onPageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shifts.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EditRecurrentSpecificShiftsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurrentSpecificShiftsComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurrentSpecificShiftsComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurrentSpecificShiftsComponent_3)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.submitView; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.editRecurrentSpecificShiftForm; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.shifts && _co.shifts.length); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_EditRecurrentSpecificShiftsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-recurrent-specific-shifts", [], null, null, null, View_EditRecurrentSpecificShiftsComponent_0, RenderType_EditRecurrentSpecificShiftsComponent)), i1.ɵdid(1, 8503296, null, 0, i8.EditRecurrentSpecificShiftsComponent, [i2.FormBuilder, i9.LoadingService, i10.JobService, i11.PopupService, i12.ShiftValidatorService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditRecurrentSpecificShiftsComponentNgFactory = i1.ɵccf("app-edit-recurrent-specific-shifts", i8.EditRecurrentSpecificShiftsComponent, View_EditRecurrentSpecificShiftsComponent_Host_0, { jobItem: "jobItem", timeZone: "timeZone", submitView: "submitView" }, {}, []);
export { EditRecurrentSpecificShiftsComponentNgFactory as EditRecurrentSpecificShiftsComponentNgFactory };
