import * as tslib_1 from "tslib";
import { UtilService } from 'src/modules/shared/services/util.services';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { Store } from '@ngrx/store';
import { findIndex, minBy } from 'lodash';
import * as moment from 'moment-timezone';
import { AlertController } from '@ionic/angular';
import { setTalentDashboardStoreAction, setFutureShiftsCountStoreAction } from '../+store/actions/talent-dashboard.actions';
import { TalentDashboardUtilService } from './talent-dashboard-util.service';
import { TalentDashboardService } from './talent-dashboard.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { JobService } from 'src/modules/shared/services/job.service';
import { setShiftInProgressAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { take } from 'rxjs/operators';
import { TalentLocationTrackingService } from './talent-location-tracking.service';
import { CalendarService } from './calendar.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/util.services";
import * as i2 from "../../shared/services/loading.service";
import * as i3 from "../../shared/services/local-storage.service";
import * as i4 from "../../shared/services/job.service";
import * as i5 from "./talent-dashboard-util.service";
import * as i6 from "./talent-dashboard.service";
import * as i7 from "@ngrx/store";
import * as i8 from "@ionic/angular";
import * as i9 from "./talent-location-tracking.service";
import * as i10 from "./calendar.service";
export class TalentSyncService {
    constructor(utilService, loadingService, localStorageService, jobService, talentDashboardUtilService, talentDashboardService, talentStore, alertController, authStore, talentLocationTrackingService, calendarService) {
        this.utilService = utilService;
        this.loadingService = loadingService;
        this.localStorageService = localStorageService;
        this.jobService = jobService;
        this.talentDashboardUtilService = talentDashboardUtilService;
        this.talentDashboardService = talentDashboardService;
        this.talentStore = talentStore;
        this.alertController = alertController;
        this.authStore = authStore;
        this.talentLocationTrackingService = talentLocationTrackingService;
        this.calendarService = calendarService;
    }
    sync(skipCalendarSync) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.utilService.isConnected()) {
                const localShifts = yield this.localStorageService.getTalentUpcomingShifts();
                this.talentStore.dispatch(setTalentDashboardStoreAction({ upcomingShifts: localShifts }));
                return;
            }
            const remoteShifts = yield this.getShiftsFromRemote();
            this.localStorageService.setShiftInProgress(false);
            if (remoteShifts) {
                yield this.startSyncing(remoteShifts);
                const localShifts = yield this.localStorageService.getTalentUpcomingShifts();
                yield this.checkShiftsInProgress(localShifts);
                this.talentStore.dispatch(setTalentDashboardStoreAction({ upcomingShifts: localShifts }));
                const user = yield this.authStore.select(getUserInfo).pipe(take(1)).toPromise();
                const upcomingShifts = yield this.jobService.findUpcomingShifts(user._id, user.address.province, false);
                const deleteShifts = yield this.jobService.findUpcomingShifts(user._id, user.address.province, false, true);
                // sync upcoming shifts in native calendar
                if (!skipCalendarSync) {
                    yield this.calendarService.syncShifts(deleteShifts.jobs, [...localShifts, ...upcomingShifts.jobs]);
                }
            }
            // this.loadingService.hideLoading(loading);
        });
    }
    checkShiftsInProgress(shifts) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let shiftInProgress = false;
            const user = yield this.utilService.getUserFromServerOrLocal();
            if (!user) {
                return;
            }
            shiftInProgress = this.talentDashboardUtilService.checkForShiftsInProgress(user._id, shifts);
            if (!shiftInProgress) {
                shiftInProgress = false;
            }
            this.authStore.dispatch(setShiftInProgressAction({ shiftInProgress: shiftInProgress }));
            this.localStorageService.setShiftInProgress(shiftInProgress);
        });
    }
    getShiftsFromRemote() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.authStore.select(getUserInfo).pipe(take(1)).toPromise();
            const localShifts = yield this.localStorageService.getTalentUpcomingShifts();
            const upShifts = yield this.jobService.findUpcomingShifts(user._id, user.address.province, true);
            const startShiftJobs = [];
            for (const job of upShifts.jobs) {
                const basicConditions = moment.unix(job.shiftEndTime).isAfter(moment());
                const stafferObject = job.staffersAndShifts.filter(staffer => {
                    return staffer.staffer === user._id && (staffer.state === 'accepted' || staffer.state === 'offer'); // && staffer.isConfirmedShift;;
                });
                const isStateAccepted = stafferObject.length > 0;
                const isShiftStarted = stafferObject.length && stafferObject[0].isShiftStarted;
                if ((basicConditions && isStateAccepted) || (!basicConditions && isStateAccepted && isShiftStarted)) {
                    startShiftJobs.push(job);
                }
            }
            let shiftCount = startShiftJobs.length;
            if (upShifts.futureCount) {
                shiftCount += upShifts.futureCount;
            }
            this.setFutureShiftsCount(shiftCount);
            /**
             * Commenting this code becuase this condition is not needed
             */
            // if (startShiftJobs.length == 0) {
            //   return 0;
            // }
            return startShiftJobs;
        });
    }
    startSyncing(newShifts) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const lShifts = yield this.localStorageService.getTalentUpcomingShifts();
            if (!lShifts.length) {
                this.talentStore.dispatch(setTalentDashboardStoreAction({ upcomingShifts: newShifts }));
                return;
            }
            // Removing those shifts from localstorage
            // that are either ended or deleted by admin
            const localShifts = yield this.deleteUnusedShiftsFromLocalStorage(newShifts);
            yield this.checkForShiftsChanges(newShifts, localShifts);
        });
    }
    checkForShiftsChanges(newShifts, localShifts) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const newShift of newShifts) {
                // searching if new shift already exists in localShifts
                // it will return the index number if it exist in localshifts array
                // else it will return -1;
                const localShiftIndex = findIndex(localShifts, ['_id', newShift._id]);
                // if new shift already exists in local storage, we will compare both shifts
                if (localShiftIndex !== -1) {
                    yield this.updateCovidAffectedStatus(localShifts[localShiftIndex], newShift); // added check for covid status
                    yield this.updateLocationBypassCheck(localShifts[localShiftIndex], newShift); // added check for covid status
                    yield this.checkIfShiftWasUpdatedByAdmin(localShifts[localShiftIndex], newShift);
                    yield this.findStafferIndexInShift(localShifts[localShiftIndex], newShift);
                }
                else {
                    yield this.talentDashboardUtilService.saveShiftInLocalStorage(newShift);
                }
            }
        });
    }
    updateCovidAffectedStatus(localShift, newShift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            localShift.entity.isCovidAffected = newShift.entity.isCovidAffected;
            yield this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
        });
    }
    updateLocationBypassCheck(localShift, newShift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            localShift.entity.switchOffLocationTracking = newShift.entity.switchOffLocationTracking;
            yield this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
        });
    }
    // check if shift was updated on the server
    checkIfShiftWasUpdatedByAdmin(localShift, newShift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (localShift.isABroadcastRequest.isTrue && localShift.isABroadcastRequest.vacancies !== newShift.isABroadcastRequest.vacancies) {
                localShift.isABroadcastRequest.vacancies = newShift.isABroadcastRequest.vacancies;
            }
            localShift.rate = newShift.rate;
            localShift.stafferRate = newShift.stafferRate;
            localShift.shiftNotes = newShift.shiftNotes;
            localShift.shiftStartTime = newShift.shiftStartTime;
            localShift.shiftEndTime = newShift.shiftEndTime;
            localShift.supervisor = newShift.supervisor;
            localShift.companyAddress = newShift.companyAddress;
            // homecare specific fields
            localShift.homecareShift = newShift.homecareShift;
            yield this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
        });
    }
    findStafferIndexInShift(localShift, newShift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let updatedServerShift = null;
            const currentUser = yield this.localStorageService.getLoggedInUserInfo();
            const stafferIndexLocalShift = findIndex(localShift.staffersAndShifts, ['staffer', currentUser._id]);
            const stafferIndexNewShift = findIndex(newShift.staffersAndShifts, ['staffer', currentUser._id]);
            if (stafferIndexLocalShift !== -1 && stafferIndexNewShift !== -1) {
                // if shift was started
                // both ( locally and by admin )
                yield this.checkIfShiftStartedLocallyAndByAdminAlso(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift);
                // if shift was started locally
                // but server was not updated
                const updatedServerShiftResult = yield this.checkIfShiftStartedLocallyThenUpdateServer(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift);
                updatedServerShift = updatedServerShiftResult;
                // if shift was started by admin
                // but local shift was not updated
                yield this.checkIfShiftStartedByAdminThenUpdateLocally(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift);
                let index = null;
                let shift = null;
                if (updatedServerShift) {
                    index = findIndex(updatedServerShift.staffersAndShifts, ['staffer._id', currentUser._id]);
                    shift = updatedServerShift;
                }
                else {
                    index = stafferIndexNewShift;
                    shift = newShift;
                }
                // If shift was ended locally
                // but server was not updated
                yield this.checkIfShiftEndedLocallyThenUpdateServer(localShift, stafferIndexLocalShift, shift, index);
                // check for breaks and update
                yield this.updateBreaks(localShift, stafferIndexLocalShift);
                // check for status in staffers and shifts
                yield this.updateConfirmationStatusInStaffersAndShifts(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift);
                return;
            }
            else {
                console.log('Could not find staffer in job');
                return;
            }
        });
    }
    // Shift was started both ( locally and by admin )
    checkIfShiftStartedLocallyAndByAdminAlso(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (localShift.staffersAndShifts[stafferIndexLocalShift].tracker &&
                newShift.staffersAndShifts[stafferIndexNewShift].tracker &&
                localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start !== newShift.staffersAndShifts[stafferIndexNewShift].tracker.start) {
                const localShiftObject = localShift.staffersAndShifts[stafferIndexLocalShift];
                const newShiftObject = newShift.staffersAndShifts[stafferIndexNewShift];
                localShiftObject.started = 'locally';
                newShiftObject.started = 'by admin';
                // checking if shift was started locally or by admin
                const temp = [];
                temp.push(localShiftObject);
                temp.push(newShiftObject);
                const startedFirst = minBy(temp, o => {
                    return o.tracker.start;
                });
                if (startedFirst.started === 'locally') {
                    try {
                        const tracker = yield this.talentDashboardService.updateStartTime(localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start, newShift.staffersAndShifts[stafferIndexNewShift].tracker._id);
                        // not replacing complete local tracker with new tracker
                        // because if shift was started and ended offline, then end time
                        // can be overwrite by zero
                        localShift.staffersAndShifts[stafferIndexLocalShift].tracker._id = tracker._id;
                        localShift.staffersAndShifts[stafferIndexLocalShift].tracker.user = tracker.user;
                        localShift.staffersAndShifts[stafferIndexLocalShift].tracker.job = tracker.job;
                        // saving/updating shift in localstorage
                        yield this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
                        return;
                    }
                    catch (error) {
                        console.log('error in updateStartTime', error);
                        return;
                    }
                    finally {
                        // deleting temporary properties
                        delete localShiftObject.started;
                        delete newShiftObject.started;
                    }
                }
                else {
                    // not replacing complete local tracker with new tracker
                    // beacuse if shift was started and ended offline, then end time
                    // can be overwrite by zero
                    localShift.staffersAndShifts[stafferIndexLocalShift].tracker._id = newShift.staffersAndShifts[stafferIndexNewShift].tracker._id;
                    localShift.staffersAndShifts[stafferIndexLocalShift].tracker.user = newShift.staffersAndShifts[stafferIndexNewShift].tracker.user;
                    localShift.staffersAndShifts[stafferIndexLocalShift].tracker.job = newShift.staffersAndShifts[stafferIndexNewShift].tracker.job;
                    localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start = newShift.staffersAndShifts[stafferIndexNewShift].tracker.start;
                    // saving/updating shift in localstorage
                    yield this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
                    // deleting temporary properties
                    delete localShiftObject.started;
                    delete newShiftObject.started;
                    return;
                }
            }
            else {
                return;
            }
        });
    }
    // Shift was started locally but server was not updated
    checkIfShiftStartedLocallyThenUpdateServer(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // return new Promise(function (resolve, reject) {
            const currentUser = yield this.localStorageService.getLoggedInUserInfo();
            if (localShift.staffersAndShifts[stafferIndexLocalShift].tracker && !newShift.staffersAndShifts[stafferIndexNewShift].tracker) {
                // console.log('Shift was started locally, now updating server to start shift');
                try {
                    const location = yield this.talentLocationTrackingService.getUserLocation();
                    const job = yield this.talentDashboardService.startTimer(localShift._id, localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start, currentUser, {
                        latitude: `${location.coords.latitude}`,
                        longitude: `${location.coords.longitude}`,
                    });
                    const stafferIndex = findIndex(job.staffersAndShifts, ['staffer._id', currentUser._id]);
                    if (stafferIndex !== -1) {
                        // not replacing complete local tracker with new tracker
                        // beacuse if shift was started and ended offline, then end time
                        // can be overwritten by zero
                        localShift.staffersAndShifts[stafferIndexLocalShift].tracker['_id'] = job.staffersAndShifts[stafferIndex].tracker._id;
                        localShift.staffersAndShifts[stafferIndexLocalShift].tracker['user'] = job.staffersAndShifts[stafferIndex].tracker.user;
                        localShift.staffersAndShifts[stafferIndexLocalShift].tracker['job'] = job.staffersAndShifts[stafferIndex].tracker.job;
                        // saving/updating shift in localstorage
                        yield this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
                        return;
                    }
                    else {
                        return;
                    }
                }
                catch (error) {
                    console.log('error in startTime', error);
                    return;
                }
            }
            else {
                return;
            }
        });
    }
    // Shift was started by admin but local shift was not updated
    checkIfShiftStartedByAdminThenUpdateLocally(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!localShift.staffersAndShifts[stafferIndexLocalShift].tracker && newShift.staffersAndShifts[stafferIndexNewShift].tracker) {
                localShift.staffersAndShifts[stafferIndexLocalShift] = newShift.staffersAndShifts[stafferIndexNewShift];
                // saving/updating shift in localstorage
                yield this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
                return;
            }
            else {
                return;
            }
        });
    }
    // Shift was ended locally but server was not updated
    checkIfShiftEndedLocallyThenUpdateServer(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const currentUser = yield this.localStorageService.getLoggedInUserInfo();
            if (localShift.staffersAndShifts[stafferIndexLocalShift].tracker &&
                localShift.staffersAndShifts[stafferIndexLocalShift].tracker.end &&
                newShift.staffersAndShifts[stafferIndexNewShift].tracker &&
                !newShift.staffersAndShifts[stafferIndexNewShift].tracker.end) {
                // console.log('shift was ended locally, now updating server to end shift');
                try {
                    if (localShift.staffersAndShifts[stafferIndexLocalShift].tracker.hasAddedFromStaffer === true) {
                        yield this.jobService.sendRequestToAdminToLoggedHours(currentUser._id, {
                            jobId: localShift._id,
                            startAndEndTime: {
                                start: localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start,
                                end: localShift.staffersAndShifts[stafferIndexLocalShift].tracker.end
                            },
                            timeZone: localShift.timeZone
                        });
                    }
                    const location = yield this.talentLocationTrackingService.getUserLocation();
                    yield this.talentDashboardService.stopTimer(localShift._id, localShift.staffersAndShifts[stafferIndexLocalShift].tracker.end, currentUser._id, {
                        latitude: `${location.coords.latitude}`,
                        longitude: `${location.coords.longitude}`
                    }, localShift.staffersAndShifts[stafferIndexLocalShift].tracker.start, localShift.staffersAndShifts[stafferIndexLocalShift].tracker.hasAddedFromStaffer);
                    yield this.removeShiftFromLocalStorage(localShift);
                    return;
                }
                catch (error) {
                    console.log('error in stopTimer', error);
                }
            }
            else {
                return;
            }
        });
    }
    // check for breaks and update
    updateBreaks(localShift, stafferIndexLocalShift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (localShift.staffersAndShifts[stafferIndexLocalShift].tracker &&
                localShift.staffersAndShifts[stafferIndexLocalShift].tracker._id &&
                localShift.staffersAndShifts[stafferIndexLocalShift].tracker.breaks.length) {
                const state = localShift.staffersAndShifts[stafferIndexLocalShift].tracker.state;
                const breaks = localShift.staffersAndShifts[stafferIndexLocalShift].tracker.breaks;
                const trackerId = localShift.staffersAndShifts[stafferIndexLocalShift].tracker._id;
                try {
                    yield this.talentDashboardService.updateBreaks(breaks, trackerId, state);
                    return;
                }
                catch (error) {
                    console.log('error in updateBreaks ', error);
                    return;
                }
            }
            else {
                return;
            }
        });
    }
    updateConfirmationStatusInStaffersAndShifts(localShift, stafferIndexLocalShift, newShift, stafferIndexNewShift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (localShift.staffersAndShifts[stafferIndexLocalShift].isConfirmedShift &&
                !newShift.staffersAndShifts[stafferIndexNewShift].isConfirmedShift) {
                // console.log('Shift confirmation status updated locally, now updating server to confirmation status shift updated ');
                const currentUser = yield this.localStorageService.getLoggedInUserInfo();
                try {
                    const job = yield this.jobService.nonRecurringShiftConfirm(localShift._id, currentUser._id);
                    const stafferIndex = findIndex(job.staffersAndShifts, ['staffer._id', currentUser._id]);
                    if (stafferIndex != -1) {
                        localShift.staffersAndShifts[stafferIndexLocalShift].isConfirmedShift = job.staffersAndShifts[stafferIndex].isConfirmedShift;
                        // saving/updating shift in localstorage
                        yield this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
                        return;
                    }
                    else {
                        return;
                    }
                }
                catch (error) {
                    console.log('error in nonRecurringShiftConfirm ', error);
                    return;
                }
            }
            else if (!localShift.staffersAndShifts[stafferIndexLocalShift].isConfirmedShift &&
                newShift.staffersAndShifts[stafferIndexNewShift].isConfirmedShift) {
                console.log('Shift confirmation status updated on server, now updating locally ');
                localShift.staffersAndShifts[stafferIndexLocalShift].isConfirmedShift =
                    newShift.staffersAndShifts[stafferIndexNewShift].isConfirmedShift;
                // saving/updating shift in localstorage
                yield this.talentDashboardUtilService.saveShiftInLocalStorage(localShift);
                return;
            }
            else {
                return;
            }
        });
    }
    deleteUnusedShiftsFromLocalStorage(newShifts) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const localShifts = yield this.localStorageService.getTalentUpcomingShifts();
            const shifts = [];
            const shiftsToDelete = [];
            for (const localShift of localShifts) {
                const index = findIndex(newShifts, ['_id', localShift._id]);
                if (index !== -1) {
                    shifts.push(localShift);
                }
                else {
                    shiftsToDelete.push(localShift);
                }
            }
            this.calendarService.removeDeletedShiftsFromCalendar(shiftsToDelete);
            yield this.localStorageService.setTalentUpcomingShifts(shifts);
            return shifts;
        });
    }
    removeShiftFromLocalStorage(shift) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const localShifts = yield this.localStorageService.getTalentUpcomingShifts();
            delete localShifts[shift._id];
            return yield this.localStorageService.setTalentUpcomingShifts(localShifts);
        });
    }
    setFutureShiftsCount(futureUpcomingShiftsCount) {
        this.talentStore.dispatch(setFutureShiftsCountStoreAction({ futureUpcomingShiftsCount }));
    }
}
TalentSyncService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TalentSyncService_Factory() { return new TalentSyncService(i0.ɵɵinject(i1.UtilService), i0.ɵɵinject(i2.LoadingService), i0.ɵɵinject(i3.LocalStorageService), i0.ɵɵinject(i4.JobService), i0.ɵɵinject(i5.TalentDashboardUtilService), i0.ɵɵinject(i6.TalentDashboardService), i0.ɵɵinject(i7.Store), i0.ɵɵinject(i8.AlertController), i0.ɵɵinject(i7.Store), i0.ɵɵinject(i9.TalentLocationTrackingService), i0.ɵɵinject(i10.CalendarService)); }, token: TalentSyncService, providedIn: "root" });
