/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./previous-hire-add-to-team.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./previous-hire-add-to-team.component";
import * as i5 from "@ionic/angular";
var styles_PreviousHireAddToTeamComponent = [i0.styles];
var RenderType_PreviousHireAddToTeamComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviousHireAddToTeamComponent, data: {} });
export { RenderType_PreviousHireAddToTeamComponent as RenderType_PreviousHireAddToTeamComponent };
function View_PreviousHireAddToTeamComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
export function View_PreviousHireAddToTeamComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "ion-justify-content-center ion-text-center child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Select Team "])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "form-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "select", [["class", "list"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.selectedOpt = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(7, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "option", [["disabled", ""], ["selected", ""]], null, null, null, null, null)), i1.ɵdid(11, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(12, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, [" Select Team* "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviousHireAddToTeamComponent_1)), i1.ɵdid(15, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", " button-stack"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" SUBMIT "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn black"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" CANCEL "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.selectedOpt; _ck(_v, 7, 0, currVal_7); var currVal_8 = undefined; _ck(_v, 11, 0, currVal_8); var currVal_9 = undefined; _ck(_v, 12, 0, currVal_9); var currVal_10 = _co.teams; _ck(_v, 15, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_PreviousHireAddToTeamComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-previous-hire-add-to-team", [], null, null, null, View_PreviousHireAddToTeamComponent_0, RenderType_PreviousHireAddToTeamComponent)), i1.ɵdid(1, 114688, null, 0, i4.PreviousHireAddToTeamComponent, [i5.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreviousHireAddToTeamComponentNgFactory = i1.ɵccf("app-previous-hire-add-to-team", i4.PreviousHireAddToTeamComponent, View_PreviousHireAddToTeamComponent_Host_0, { teams: "teams" }, {}, []);
export { PreviousHireAddToTeamComponentNgFactory as PreviousHireAddToTeamComponentNgFactory };
