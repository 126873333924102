import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { IUser } from 'src/modules/authentication/interfaces/user.interface'
import { SuccessPopupComponent } from 'src/modules/shared/components/success-popup/success-popup.component';
import { InAppBrowser, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';
import { CertificateService } from 'src/modules/shared/services/certificate.service';
import { AttestationAndDeclarationService } from 'src/modules/shared/services/attestation-and-declaration.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import * as _ from 'lodash';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { TalentJobFormatService } from '../../services/talent-job-format.service';
import { File } from '@ionic-native/file/ngx';
import { PopupService } from 'src/modules/shared/services/popup.service';

@Component({
  selector: 'app-certificate-list',
  templateUrl: './certificate-list.component.html',
  styleUrls: ['./certificate-list.component.scss'],
})
export class CertificateListComponent implements OnChanges {

  @Input() userData: IUser;
  @Input() stafferCertificate;

  @Input() isHCW;

  @Input() requiredCertificatesBySkill;
  @Input() isVSCRequired: boolean;
  covidCertificates = [
    {name : 'COVID-19 Vaccination', isRequired: false}
  ];

  vscCertificates = [
    {name: 'Vulnerable Sector Check', isRequired: false},
    {name: 'Receipt of Vulnerable Sector Check', isRequired: false},
   ];

  healthcareCertificates = [
    {name: 'CMRTO Registration', isRequired: false},
    {name: 'CMRITO Registration', isRequired: false},
    {name: 'CPR', isRequired: false},
    {name: 'Current Flu Vaccination', isRequired: false},
    {name: 'Government Issued Photo ID & Eligibility', isRequired: false},
    {name: 'Immunization Record', isRequired: false},
    {name: 'Nursing License/Certification', displayName: 'Nursing License / Certification', isRequired: false},
    {name: 'Medical Lab Certificate', isRequired: false},
    {name: 'N95', isRequired: false},
    {name: 'Nursing Specialization', isRequired: false},
    {name: 'Other', displayName: 'Additional Credential', isRequired: false},
    {name: 'TB Testing', isRequired: false},
    {name: 'Professional Liability Protection', isRequired: false},
  ];

  dietaryaideCertificates = [
    {name: 'Food Service/Safe Certification', displayName: 'Food Service / Safe Certification', isRequired: false},
  ];

  pswCertificates = [
    {name: 'CPI', isRequired: false},
    {name: 'PSW Certificate/Documentation', displayName: 'PSW Certificate / Documentation', isRequired: false},
    {name: 'OPSWA Membership', displayName: 'OPSWA', isRequired: false}
  ];

  otherCertificates = [
    {name: 'Food Handler Certified', isRequired: false},
    {name: 'Smart Serve Certified', isRequired: false}
  ]

  
  stafferCertificates;
  certState = {
    passed: 'PASSED',
    rejected: 'REJECTED',
    deleted: 'DELETED',
    missing: 'MISSING'
  }
  isBrowser: any;

  constructor(private router: Router, 
  private modalController: ModalController,
  private theInAppBrowser: InAppBrowser,
  private certificateService: CertificateService,
  private utilService: UtilService,
  private attestationAndDeclarationService: AttestationAndDeclarationService,
  private loadingService: LoadingService,
  private platform: Platform,
  private transfer: FileTransfer,
  private fileopen: FileOpener,
  private file: File,
  private talentJobFormatService: TalentJobFormatService,
  private popupService: PopupService
  ) { }

  ngOnInit() {

  }

  async ngOnChanges() {

    this.isBrowser = this.talentJobFormatService.isBrowserCheck();

    this.stafferCertificates = this.stafferCertificate;
    this.covidCertificates = this.covidCertificates.map((certs) => {
      certs.isRequired = this.checkRequirement(certs.name);
      return certs;
    })

    this.healthcareCertificates = this.healthcareCertificates.map((certs) => {
      certs.isRequired = this.checkRequirement(certs.name);
      return certs;
    })
    this.dietaryaideCertificates = this.dietaryaideCertificates.map((certs) => {
      certs.isRequired = this.checkRequirement(certs.name);
      return certs;
    })

    this.pswCertificates = this.pswCertificates.map((certs) => {
      certs.isRequired = this.checkRequirement(certs.name);
      return certs;
    })

    this.otherCertificates = this.otherCertificates.map((certs) => {
      certs.isRequired = this.checkRequirement(certs.name);
      return certs;
    });

    this.vscCertificates = this.vscCertificates.map((certs) => {
      certs.isRequired = this.checkRequirement(certs.name);
      return certs;
    });
  }

  uploadCertificate(certificate: string) {
    if(certificate.includes('/')) {
      certificate = certificate.replace('/', '.');
    } 

    if(certificate.includes('&')) {
      certificate = certificate.toString();
    }
    this.router.navigateByUrl(`/talent-dashboard/upload-file/${certificate}`);
  }

  checkRequirement(certificate) {
    if(this.requiredCertificatesBySkill)    
    {return this.requiredCertificatesBySkill.includes(certificate);}
  }

  getCertificateStatus(name: string, type?: string): string { 
    //Check if either of the VSC Certificate/Receipt is uploaded, mark other as optional 
   
    if(name.includes('Vulnerable Sector Check')) {
      this.handleVSCRequirement();
    }

    if (!this.stafferCertificates[name] || this.stafferCertificates[name].length === 0) {
      return this.certState.missing;
    } else if(
      this.stafferCertificates[name].every(
        c => c.markCertificateAsRejected.isDeleted
      )
    ) {
      return this.certState.deleted;
    } else if (this.stafferCertificates[name].rejectedFound !== 0) {
      return this.certState.rejected;
    } 
    return this.certState.passed;
  }


  handleVSCRequirement() {

    //Check if VSC Certificate is uploaded, make Receipt optional and if receipt is uploaded make VSC Certificate optional
    this.vscCertificates.forEach((cert) => {
      if(!this.stafferCertificates[cert.name]) {
        cert.isRequired = false;
      }
    })
  }

  async showVSCRequestPopup() {
    
    //Check if the contractor resides in Toronto, Check the postal code of contractor, if the contractor code starts with M then contractor resides in Toronto
    
    const city  = this.userData.address.code.charAt(0) === 'M' ? 'Toronto' : this.userData.address.city;

    const data = {
      heading: 'How to apply to Vulnerable Sector Check',
      message: this.certificateService.getRequestLetterModalMessageByCity(city),
      rejectBtnText: 'Cancel',
      btn: city === 'Toronto' ? 'Apply' : 'Download'
    }
    const myModal = await this.modalController.create({
        component: SuccessPopupComponent,
        backdropDismiss: false,
        cssClass: 'wideModal',
        componentProps: {
          data
        }
      });

      await myModal.present();

      return await myModal.onDidDismiss().then(async dismiss => {
        if(dismiss.data == 'reject') {
          return false;
        }

        if(city !== 'Toronto') {
          await this.generateVSCRequestLetter(city);
          return;
        } 

        this.theInAppBrowser.create(`https://www.tps.ca/services/police-record-checks/`, '_blank');
      })
    }

  async generateVSCRequestLetter(city: string) {

    const loading = await this.loadingService.showLoading().toPromise();
    try {
      const timeZone = this.utilService.getTimezoneByCity(city);
      const skills = _.map(this.userData.skills, 'name') || [];  
      const response = await this.attestationAndDeclarationService.generateRequestLetter(this.userData._id, timeZone, skills, 'vsc-request', city);
    
      const pdfData = response.attachment;

      const linkSource = `data:application/pdf;base64,${pdfData}`;
      const downloadLink = document.createElement('a');
      const fileName = `VSCRequestLetter.pdf`;


      if ((this.platform.is('android') || this.platform.is('ios') || this.platform.is('ipad')) && !this.isBrowser) {
        const fileTransfer: FileTransferObject = this.transfer.create();

        const path = this.file.dataDirectory;

        fileTransfer.download(linkSource, path + fileName).then((entry) => {
          this.popupService.showModal({
            heading: 'Success',
            message: 'Download complete!',
            btn: 'Open',
            navigateRoute: null,
            imgURL: 'assets/images/thumbs-up.png'
          }, () => {
            this.fileopen.open(path + fileName, 'application/pdf');
          });

        })
          .catch((err) => {
            console.log(err);
            alert('Error saving file: ' + err.message);

          });
      } else {
        // If downloaded by Web Browser or ios
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }

    } catch (error) {
      console.log('err', error);
      this.popupService.showModal({
        heading: 'Error',
        message: typeof error.error === 'string' ? error.error : 'Sorry, Unable to download, try again',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
    } finally {
      await this.loadingService.hideLoading(loading);
    }

  }
  }

