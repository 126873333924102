<ion-row class="main-container">
  <div class="header-container">
    <ion-col class="header" size="2" *ngFor="let item of config">
      {{item}}
    </ion-col>
  </div>
</ion-row>

<ion-row *ngIf="formGroup" [formGroup]="formGroup">
  <app-edit-recurring-shift-list-item  class="row-container" *ngFor="let item of data; let i = index"
  formArrayName="recurringShifts" [confirmationView]="confirmationView"  [config]="config" [control]="formGroup.get('recurringShifts').controls[item.index]"  >
  </app-edit-recurring-shift-list-item>
</ion-row>

<ion-row *ngIf="!formGroup">
  <app-edit-recurring-shift-list-item  class="row-container" *ngFor="let item of data; let i = index"
   [confirmationView]="confirmationView" [data]="item"  [config]="config"  >
  </app-edit-recurring-shift-list-item>
</ion-row>