import * as i0 from "@angular/core";
export class RegexService {
    constructor() { }
    nameRegex() {
        return /^[\s]*[a-zA-ZÀ-ÿ]+(([’'. -][a-zA-ZÀ-ÿ ])?[a-zA-ZÀ-ÿ]*)*[\s]*$/;
    }
    companyRegex() {
        return /^[\s]*[a-zA-ZÀ-ÿ]+(([\s]?[’'.&'-`. \s][a-zA-ZÀ-ÿ ])?[a-zA-ZÀ-ÿ]*)*[\s]*$/;
    }
    emailRegex() {
        return "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z]{2,63}?$";
    }
    passwordRegex() {
        return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&^()-_+={}~|])[A-Za-z\d$@$!%*#?&^()-_+={}~|]{8,}$/;
    }
}
RegexService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegexService_Factory() { return new RegexService(); }, token: RegexService, providedIn: "root" });
