/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/ng2-date-picker/ng2-date-picker.ngfactory";
import * as i6 from "ng2-date-picker";
import * as i7 from "@angular/forms";
import * as i8 from "./date-selector.component";
var styles_DateSelectorComponent = [i0.styles];
var RenderType_DateSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DateSelectorComponent, data: {} });
export { RenderType_DateSelectorComponent as RenderType_DateSelectorComponent };
function View_DateSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(1, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(2, 0, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayDate; _ck(_v, 2, 0, currVal_0); }); }
function View_DateSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "current-date"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectDate(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["I currently work here"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-arrow-right"]], null, null, null, null, null))], null, null); }
export function View_DateSelectorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { datePicker: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 24, "div", [["class", "date-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [["class", "label-and-date"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateSelectorComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 10, "dp-date-picker", [["theme", "dp-material"]], [[8, "className", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "onChange"], [null, "ngModelChange"], [null, "click"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onScroll() !== false);
        ad = (pd_1 && ad);
    } if (("onChange" === en)) {
        var pd_2 = (_co.dateChanged() !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = ((_co.selectedDate = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_DatePickerComponent_0, i5.RenderType_DatePickerComponent)), i1.ɵprd(512, null, i6.ɵc, i6.ɵc, [i6.ɵb]), i1.ɵprd(512, null, i6.ɵe, i6.ɵe, [i6.ɵb]), i1.ɵprd(512, null, i6.ɵd, i6.ɵd, [i6.ɵb, i6.ɵe, i6.ɵc]), i1.ɵprd(512, null, i6.ɵa, i6.ɵa, [i6.ɵb, i6.ɵc, i6.ɵd]), i1.ɵdid(11, 4964352, [[1, 4], ["datePicker", 4]], 0, i6.DatePickerComponent, [i6.ɵa, i6.ɵf, i1.ElementRef, i1.Renderer2, i6.ɵb, i1.ChangeDetectorRef], { config: [0, "config"], theme: [1, "theme"] }, { onChange: "onChange" }), i1.ɵprd(1024, null, i7.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i6.DatePickerComponent]), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.DatePickerComponent]), i1.ɵdid(14, 671744, null, 0, i7.NgModel, [[8, null], [6, i7.NG_VALIDATORS], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(16, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 8, "div", [["class", "save-date"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateSelectorComponent_2)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 5, "ion-button", [["class", "select-date"], ["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectDate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(22, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(23, { "current-date-btn": 0 }), i1.ɵdid(24, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], disabled: [1, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["Select"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayDate; _ck(_v, 5, 0, currVal_0); var currVal_9 = _co.dateConfig; var currVal_10 = "dp-material"; _ck(_v, 11, 0, currVal_9, currVal_10); var currVal_11 = _co.selectedDate; _ck(_v, 14, 0, currVal_11); var currVal_12 = ((_co.referenceType === "professional") && (_co.type === "endDate")); _ck(_v, 19, 0, currVal_12); var currVal_13 = "select-date"; var currVal_14 = _ck(_v, 23, 0, (_co.referenceType === "personal")); _ck(_v, 22, 0, currVal_13, currVal_14); var currVal_15 = "basic"; var currVal_16 = !_co.selectedDate; _ck(_v, 24, 0, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 11).theme; var currVal_2 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 16).ngClassValid; var currVal_7 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_DateSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-date-selector", [], null, null, null, View_DateSelectorComponent_0, RenderType_DateSelectorComponent)), i1.ɵdid(1, 4308992, null, 0, i8.DateSelectorComponent, [i1.ChangeDetectorRef, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DateSelectorComponentNgFactory = i1.ɵccf("app-date-selector", i8.DateSelectorComponent, View_DateSelectorComponent_Host_0, { startDate: "startDate", referenceType: "referenceType", type: "type" }, {}, []);
export { DateSelectorComponentNgFactory as DateSelectorComponentNgFactory };
