import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
export class BaseUrlInterceptor {
    constructor() { }
    intercept(req, next) {
        if (req.url.startsWith('/')) {
            req = req.clone({ url: environment.API_BASE_URL + req.url });
        }
        return next.handle(req);
    }
}
BaseUrlInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseUrlInterceptor_Factory() { return new BaseUrlInterceptor(); }, token: BaseUrlInterceptor, providedIn: "root" });
