<div class="main-container">
  <div class="row-container" *ngIf="!confirmationView">
    <ion-col [formGroup]="control" class="recurrent-data" [ngClass]="{'date-item': item == 'Date'}" size="2"
      *ngFor="let item of config">

      <app-form-date-picker showEditIcon="true" class="without-border-bottom w-100" *ngIf="item=='Date'"
        [dateConfig]="dateConfig" [formControlName]="getFormControl(item)" [control]="control.get('date')">
      </app-form-date-picker>


      <app-form-select-v2 *ngIf="item!='Date'" [options]="getAvailableOptions(item)" [labelKey]="getLabelKey(item)"
        [control]="control.get(getFormControl(item))" [valueKey]="'value'" [returnObject]="true"
        [formControlName]="getFormControl(item)" class="without-bottom-border"></app-form-select-v2>
    </ion-col>
  </div>

  <div  class="row-container" *ngIf="confirmationView && !data">
    <ion-col class="recurrent-data" [ngClass]="{'date-item': item == 'Date'}" size="2" *ngFor="let item of config">
      {{getFormControlValue(item)}}
    </ion-col>
  </div>

  <div class="row-container" *ngIf="confirmationView && data">
    <ion-col class="recurrent-data" [ngClass]="{'date-item': item == 'Date'}" size="2" *ngFor="let item of config">
      {{data[item]}}
    </ion-col>
  </div>

</div>