import { HttpService } from 'src/modules/shared/services/http.service';
import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/http.service";
import * as i2 from "../../shared/services/http-promise.service";
export class OnBoardingStafferService {
    constructor(http, httpPromiseService) {
        this.http = http;
        this.httpPromiseService = httpPromiseService;
    }
    createUser(data) {
        return this.http.httpPostRequest('/api/users/createUser', data);
    }
    addressConfirmation(userId, data) {
        return this.http.httpPutRequest(`/api/users/${userId}`, data);
    }
    sendContractToEmail(data) {
        return this.httpPromiseService.httpPostRequest(`/api/users/contractSend/all`, data);
    }
    updateUser$(userId, data) {
        return this.http.httpPutRequest(`/api/users/${userId}`, data);
    }
    updateNavigationStep(userId, currentNavStep) {
        return this.httpPromiseService.httpPutRequest(`/api/users/updateRoute/${userId}`, { currentNavStep: currentNavStep });
    }
    updateUser(userId, data) {
        return this.httpPromiseService.httpPutRequest(`/api/users/${userId}`, data);
    }
    updateRoutes(userId, data) {
        return this.httpPromiseService.httpPutRequest(`/api/users/updateRoute/${userId}`, data);
    }
    updateSkillsRate(userId, data) {
        return this.httpPromiseService.httpPutRequest(`/api/users/${userId}/updateSkillsRate`, data);
    }
    deleteUser(userId) {
        return this.httpPromiseService.httpDeleteRequest(`/api/users/${userId}`);
    }
    addCompanyLogo$(data) {
        return this.http.httpPostRequest(`/api/users/avatar`, data);
    }
    addProfilePicture(data) {
        return this.httpPromiseService.httpPostRequest(`/api/users/avatar`, data);
    }
    addResume(data) {
        return this.httpPromiseService.httpPostRequest(`/api/users/resume`, data);
    }
    getQuizQuestions(version) {
        return this.httpPromiseService.httpGetRequest(`/api/quizzes?version=${version}`);
    }
    verfiyCode(code) {
        return this.httpPromiseService.httpGetRequest(`/api/users/verfiyCode/license/agreement/?code=${code}`);
    }
    addReferences(userId, data) {
        return this.httpPromiseService.httpPostRequest(`/api/appInfo/sendEmailToReferences/${userId}`, data);
    }
    addBackgroundCheck(userId, data) {
        return this.httpPromiseService.httpPostRequest(`/api/certn/${userId}`, data);
    }
    addNewReferral(data) {
        return this.httpPromiseService.httpPostRequest(`/api/refferals`, data);
    }
    resendInvite(inviteeId) {
        return this.httpPromiseService.httpPostRequest(`/api/refferals/resendInvite/${inviteeId}`, {});
    }
    getFriendReferrals() {
        return this.httpPromiseService.httpGetRequest(`/api/refferals`);
    }
    getActiveReferrals() {
        return this.httpPromiseService.httpGetRequest(`/api/refferals/active`);
    }
    addUnifromPicture(category, data) {
        return this.httpPromiseService.httpPostRequest(`/api/users/uploadUniformPhoto?category=${category}`, data);
    }
    submitForApproval() {
        return this.http.httpPostRequest('/api/users/submit-for-approval', {});
    }
}
OnBoardingStafferService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnBoardingStafferService_Factory() { return new OnBoardingStafferService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.HttpPromiseService)); }, token: OnBoardingStafferService, providedIn: "root" });
