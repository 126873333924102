import { Component, OnInit, Input } from '@angular/core';
import { ITalentJob } from '../../../talent-dashboard/interfaces/talent-job.interface';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment-timezone';

@Component({
  selector: 'shared-recurring-shifts-timing-modal',
  templateUrl: './recurring-shifts-timing.component.html',
  styleUrls: ['./recurring-shifts-timing.component.scss'],
})
export class RecurringShiftsTimingComponent implements OnInit {
  @Input() jobItem: ITalentJob;
  @Input() heading: string;
  @Input() showUnitNumber: boolean;
  @Input() unitNumber: string[];

  constructor(private modalController: ModalController) { }

  ngOnInit() {

    if (this.jobItem && this.jobItem.jobs && this.jobItem.jobs.length) {
      this.formatJobs();
    }
  }
  submit() {
    this.modalController.dismiss();
  }

  formatJobs() {
    this.jobItem.jobs.forEach(job => {

      job.momentedShiftStartTimeTz = moment.unix(job.shiftStartTime).tz(job.timeZone).format('MMM DD, YYYY')
      job.momentedShiftStartTime = this.optimizeTime(job.shiftStartTime, job.timeZone);
      job.momentedShiftEndTime = this.optimizeTime(job.shiftEndTime, job.timeZone);
      
      if(!job.break || !job.break.unpaid) {
        return job;
      }

      job.unpaidBreak = job.break.unpaid
      
    });
  }

  optimizeTime(time: number, tz) {
    if (moment.unix(time).format('mm') === '00') {
      return moment.unix(time).tz(tz).format('h:mma');
    } else {
      return moment.unix(time).tz(tz).format('hh:mma');
    }
  }
}
