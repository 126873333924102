<div class="root">
  <div class="scroll-container">
    <div class="flex-container">
      <h1 class="">Notes</h1>
      <div class = "notes-container">
        <p class = "notes-verbiage" *ngIf = "item.shiftNotes">
          <b> {{ 'NOTES.Shift_Notes' | translate}} : </b>
          <span [innerHTML]="item.shiftNotes | sanitizeHtml"></span>
        </p>

        <p class = "notes-verbiage" *ngIf = "item.entity.siteNotes">
          <b> {{ 'NOTES.Site_Notes' | translate}} : </b> {{item.entity.siteNotes}}
        </p>
      </div>
    </div>

  <ion-button expand="full" color="dark" id="success-popup-btn" class=" ion-no-margin btn success-popup-ok"
    (click)='submit()'>Dismiss
  </ion-button>
  </div>
</div>
