/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./staffer-rating-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/ng-starrating/ng-starrating.ngfactory";
import * as i4 from "ng-starrating";
import * as i5 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i6 from "@ionic/angular";
import * as i7 from "./staffer-rating-popup.component";
import * as i8 from "../../services/util.services";
var styles_StafferRatingPopupComponent = [i0.styles];
var RenderType_StafferRatingPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StafferRatingPopupComponent, data: {} });
export { RenderType_StafferRatingPopupComponent as RenderType_StafferRatingPopupComponent };
export function View_StafferRatingPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "ion-justify-content-center ion-text-center child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "ion-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "info-row"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "mobile-view": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "star-rating", [["checkedcolor", "#ff4d00"], ["class", "ratings"], ["size", "22px"], ["totalstars", "5"], ["uncheckedcolor", "grey"], ["value", "0"]], null, [[null, "rate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rate" === en)) {
        var pd_0 = (_co.onRate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_StarRatingComponent_0, i3.RenderType_StarRatingComponent)), i1.ɵdid(10, 4243456, null, 0, i4.StarRatingComponent, [], { checkedcolor: [0, "checkedcolor"], uncheckedcolor: [1, "uncheckedcolor"], value: [2, "value"], size: [3, "size"], totalstars: [4, "totalstars"] }, { rate: "rate" }), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ion-button", [["class", " ion-no-margin submit-btn success-popup-ok"], ["color", "basic"], ["expand", "full"], ["id", "success-popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IonButton_0, i5.RenderType_IonButton)), i1.ɵdid(12, 49152, null, 0, i6.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, ["Submit "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "ion-button", [["class", " ion-no-margin cancel-btn success-popup-ok"], ["color", "dark"], ["expand", "full"], ["id", "success-popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IonButton_0, i5.RenderType_IonButton)), i1.ɵdid(15, 49152, null, 0, i6.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "info-row"; var currVal_3 = _ck(_v, 8, 0, _co.isMobile); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = "#ff4d00"; var currVal_5 = "grey"; var currVal_6 = "0"; var currVal_7 = "22px"; var currVal_8 = "5"; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = "basic"; var currVal_10 = "full"; _ck(_v, 12, 0, currVal_9, currVal_10); var currVal_11 = "dark"; var currVal_12 = "full"; _ck(_v, 15, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 4, 0, currVal_1); }); }
export function View_StafferRatingPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-staffer-rating-popup", [], null, null, null, View_StafferRatingPopupComponent_0, RenderType_StafferRatingPopupComponent)), i1.ɵdid(1, 114688, null, 0, i7.StafferRatingPopupComponent, [i6.ModalController, i8.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StafferRatingPopupComponentNgFactory = i1.ɵccf("shared-staffer-rating-popup", i7.StafferRatingPopupComponent, View_StafferRatingPopupComponent_Host_0, { data: "data" }, {}, []);
export { StafferRatingPopupComponentNgFactory as StafferRatingPopupComponentNgFactory };
