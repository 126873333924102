import {  NgModule } from '@angular/core';
import {  StoreModule } from '@ngrx/store';
import { authReducer } from './reducers/auth.reducers';
import { authState } from './auth.state';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './effects/auth.effects';



@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('auth', authReducer, { initialState: authState }), EffectsModule.forFeature([AuthEffects])
  ],
  exports: [StoreModule, EffectsModule],
})
export class AuthStoreModule {
  constructor(){
    console.log('initialzing auth store module');
  }
 }
