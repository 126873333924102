import * as tslib_1 from "tslib";
import { AfterViewChecked, ChangeDetectorRef, OnInit } from '@angular/core';
import { BroadcastJobsUtilService } from 'src/modules/company-dashboard/services/broadcast-job-utils.service';
import { RecurrentShiftService } from '../services/recurrentShift.service';
export class ModifyUnitNumberComponent {
    constructor(broadcastJobsUtilService, recurrentShiftService, cdr) {
        this.broadcastJobsUtilService = broadcastJobsUtilService;
        this.recurrentShiftService = recurrentShiftService;
        this.cdr = cdr;
    }
    ngOnInit() {
        this.assignActionTextToButton(this.jobItem);
    }
    ngAfterViewChecked() {
        this.assignActionTextToButton(this.jobItem);
        this.cdr.detectChanges();
    }
    editUnitNumberPopup(jobItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.jobItem = yield this.broadcastJobsUtilService.editUnitNumber(jobItem, this.unitNumber, this.action);
            console.log('updated: ', this.jobItem);
            this.assignActionTextToButton(this.jobItem);
        });
    }
    assignActionTextToButton(jobItem) {
        //Check if job doesn't have a unit number, set action text to Add [unit number]
        //else set action text to Edit [unit number]
        const action = this.recurrentShiftService.unitNumberByShift(jobItem);
        if (!action) {
            this.action = 'Add';
        }
        else {
            this.action = 'Edit';
        }
    }
}
