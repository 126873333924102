import { Store, select } from '@ngrx/store';
import { from, of, throwError } from 'rxjs';
import { catchError, map, switchMap, first, exhaustMap } from 'rxjs/operators';
import { shouldAppendHeadersTo } from '../utils/interceptors.utils';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { getLoggedInUserToken } from 'src/modules/authentication/+store/auth-selector';
import { logOutAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { ConfigService } from 'src/app/services/config.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../modules/shared/services/local-storage.service";
import * as i2 from "@ngrx/store";
import * as i3 from "../../services/config.service";
export class AuthInterceptor {
    constructor(storage, store, configService) {
        this.storage = storage;
        this.store = store;
        this.configService = configService;
    }
    intercept(req, next) {
        return shouldAppendHeadersTo(req)
            .pipe(exhaustMap(shouldAppend => (shouldAppend ? this.addAuthenticationToken(req) : of(req))))
            .pipe(switchMap(request => next.handle(request)))
            .pipe(catchError(err => this.handleExpiredToken(req, next)(err)));
    }
    addAuthenticationToken(req) {
        return from(this.store.pipe(select(getLoggedInUserToken), first())).pipe(map(token => req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) })));
    }
    handleExpiredToken(req, next) {
        return (error) => {
            if (error) {
                if (error.status === 401) {
                    // TODO: Implement Expired token handler
                    // this.store.dispatch(signinRedirectAction());
                    this.configService.revertToDefault();
                    this.store.dispatch(logOutAction());
                }
                return throwError(error);
            }
        };
    }
}
AuthInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthInterceptor_Factory() { return new AuthInterceptor(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.ConfigService)); }, token: AuthInterceptor, providedIn: "root" });
