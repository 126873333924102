import { Injectable } from '@angular/core';
import { HttpPromiseService } from './http-promise.service';
import { SkillInterface } from './../interfaces/skill.interface';
import { orderBy } from 'lodash';

@Injectable({ providedIn: 'root' })
export class SkillsService {
    skills = null;
    isLoadingSkills = false;
    constructor(
        private httpPromiseService: HttpPromiseService
    ) {}

    public async getAllSkills(): Promise<Object> {
        if(!this.skills && !this.isLoadingSkills) {
            this.isLoadingSkills = true;
            this.skills = this.httpPromiseService.httpGetRequest('/api/industries/getSkillsByJson');
            await this.skills;
            this.isLoadingSkills = false;
        }

        return this.skills;
    }

    /**
     * @name getListOfAllSkills
     * @param options - industriesToInclude: ['Healthcare', ...], outputFormat: ['default'], filters: ['isBOH']
     * @returns formatted skills
     */
1
    async getListOfAllSkills(options: { 
        industriesToInclude?: string[], 
        outputFormat:  "rates" | "manual_rates" | "experience"  | "names" | "default",
        filters?: string[]}
    ): Promise<Array<string> | Object | SkillInterface[]> {

        const { industriesToInclude, outputFormat, filters } = options;

        // fetch
        const skillsByIndustry = await this.getAllSkills() as {industries: {Healthcare: {}, Hospitality: {}}, cities: string[]};
        const industriesToKeep = {};        
        // filter
        // We need 2 filterations
        // filter by industry
        if (industriesToInclude) {
            industriesToInclude.forEach((industry) => {
                industriesToKeep[industry] = skillsByIndustry.industries[industry];
            });
        } else {
            Object.keys(skillsByIndustry.industries).forEach((industry) => {
                industriesToKeep[industry] = skillsByIndustry.industries[industry];
            });
        }
        // filter by flags
        // before filteration by flags combine all the industries in one array
        const combinedSkills = this.combineSkills(industriesToKeep);
        const filteredSkills = this.applyFilters(combinedSkills, filters);
        // transformations
        let transformedSkills: Object | string[] = {} || [];
        switch(outputFormat){
            // {skillName: skillRate}
            case "rates":
                Object.values(filteredSkills).forEach((eachSkill) => {         
                    transformedSkills[eachSkill['name']] = eachSkill['rateCA'];
                });
                break;
            case "manual_rates":
                //Map rates into an object consisting of rate and allow change property so it can be re-assigned (deconstruct) 
                Object.values(filteredSkills).forEach((eachSkill) => {         
                    transformedSkills[eachSkill['name']] = {rate: eachSkill['rateCA'], allowChange: true, defaultRate: eachSkill['rateCA']};
                });
            break;
            case 'experience':
                // {skillName: 'reqExpereince'}
                Object.values(filteredSkills).forEach((eachSkill) => {
                    transformedSkills[eachSkill['name']] = eachSkill['requiredExperience'];
                });
                break;
            case 'names':
                // [skill1, skill2, skill3] -- names of skills
                transformedSkills = orderBy(filteredSkills.map((eachSkill) => eachSkill['name']));
                break;
            default:
                transformedSkills = filteredSkills;
                break;
        }

        return transformedSkills;

    }

    private combineSkills(skillsByIndustry: Object): SkillInterface[]{
        const skills = [];

        Object.keys(skillsByIndustry).forEach((industry) => {
            skills.push(...Object.values(skillsByIndustry[industry]));
        });
        return skills;
    }

    private applyFilters(combinedSkills: SkillInterface[], filters: string[]): SkillInterface[] {
        if (!filters) {
            return combinedSkills;
        }
        return combinedSkills.filter(skill => {
            skill.shouldStay = false;

            for (let i = 0; i < filters.length; i++){
                const filter = filters[i];
                if (skill[filter]){
                    skill.shouldStay  = true;
                    break;
                }
            }

            return skill.shouldStay;
        });
    }

    invalidateSkillsCache() {
        this.skills = null;
    }
}