import * as tslib_1 from "tslib";
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { ModalController } from '@ionic/angular';
import { AppUpdateProgressComponent } from '../componets/app-update-progress/app-update-progress.component';
import { environment } from 'src/environments/environment';
import { Observable, Observer } from 'rxjs';
import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import * as i0 from "@angular/core";
import * as i1 from "cordova-plugin-ionic/dist/ngx/ngx/index";
import * as i2 from "@ionic/angular";
import * as i3 from "../../modules/shared/services/http-promise.service";
export class AppUpdateService {
    constructor(deploy, modalController, httpPromiseService) {
        this.deploy = deploy;
        this.modalController = modalController;
        this.httpPromiseService = httpPromiseService;
        this.config = {
            appId: 'da4462b7',
            channel: environment.APPFLOW_CHANNEL,
            updateMethod: 'none'
        };
    }
    check() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // initialize observable
                this.downloadProgressObservable = new Observable(observer => {
                    observer.next(0);
                    this.observer = observer;
                });
                // configure live deploy plugin
                yield this.deploy.configure(this.config);
                // check if any update is available
                const update = yield this.deploy.checkForUpdate();
                if (update.available) {
                    // check importance
                    const { isCritical } = yield this.checkBuildImportance(update.build).catch(() => {
                        return {
                            isCritical: false
                        };
                    });
                    // if criticial
                    if (isCritical) {
                        // show popup
                        this.showProgressModal();
                    }
                    // download updates
                    yield this.downloadUpdates();
                    // if its a critical update, reload the app
                    if (isCritical) {
                        yield this.deploy.reloadApp();
                    }
                }
                return;
            }
            catch (e) {
                console.log('error in downlodaing updates');
            }
            finally {
                this.hideProgressModal();
            }
        });
    }
    downloadUpdates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                //   this.isUpdating = true;
                yield this.deploy.downloadUpdate(progress => {
                    if (this.observer) {
                        this.observer.next(progress);
                    }
                });
                yield this.deploy.extractUpdate();
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    showProgressModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.progressModal = yield this.modalController.create({
                component: AppUpdateProgressComponent,
                cssClass: 'wideModal',
                backdropDismiss: false,
                componentProps: {
                    progress: this.downloadProgressObservable
                }
            });
            this.progressModal.present();
        });
    }
    hideProgressModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.progressModal) {
                this.progressModal.dismiss();
            }
        });
    }
    checkBuildImportance(buildId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const buildImportance = yield this.httpPromiseService.httpGetRequest(`/api/live-updates/${buildId}`);
            // if its defined return it
            if (buildImportance) {
                return buildImportance;
            }
            // if not found, return false
            return {
                isCritical: false,
                buildId
            };
        });
    }
}
AppUpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppUpdateService_Factory() { return new AppUpdateService(i0.ɵɵinject(i1.Deploy), i0.ɵɵinject(i2.ModalController), i0.ɵɵinject(i3.HttpPromiseService)); }, token: AppUpdateService, providedIn: "root" });
