import * as tslib_1 from "tslib";
import { LocalStorageService } from './local-storage.service';
import { Platform } from '@ionic/angular';
import { HttpPromiseService } from './http-promise.service';
import { UserTypeEnum } from 'src/modules/authentication/enums/user-type.enum';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
import * as i2 from "@ionic/angular";
import * as i3 from "./http-promise.service";
import * as i4 from "@angular/router";
import * as i5 from "./user.service";
export class PushNotificationService {
    constructor(localStorageService, platform, httpPromiseService, router, userService) {
        this.localStorageService = localStorageService;
        this.platform = platform;
        this.httpPromiseService = httpPromiseService;
        this.router = router;
        this.userService = userService;
    }
    initPushNotification(currentUser) {
        if (!currentUser) {
            return;
        }
        if (this.isMobileDevice()) {
            this.initPushwoosh(currentUser);
        }
        else {
            if (!this.OneSignal && window.location.href.indexOf('localhost') < 1) { // if not initilized before and not localhost
                this.addScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js', (callback) => {
                    this.initOneSignal(currentUser);
                });
            }
        }
    }
    isMobileDevice() {
        return this.platform.is('cordova');
    }
    initPushwoosh(currentUser) {
        const pushNotification = window.plugins.pushNotification;
        // set push notifications handler
        document.addEventListener('push-notification', (event) => {
            const message = event.notification.message;
            const userData = event.notification.userdata;
            // dump custom data to the console if it exists
            if (typeof (userData) !== 'undefined') {
                const notificationId = userData.notificationId;
                if (notificationId) {
                    this.navigateToSingleNotificationView(notificationId);
                }
            }
        });
        document.addEventListener('push-receive', (event) => {
            const message = event.notification.message;
            const userData = event.notification.userdata;
        });
        pushNotification.onDeviceReady({
            appid: '72DD2-BE47E',
            projectid: '423542432527',
        });
        // register for push notifications
        const app = this;
        pushNotification.registerDevice((status) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const pushToken = status.pushToken;
            console.log('PUSH TOKEN', pushToken);
            this.localStorageService.setSingleKey('deviceToken', pushToken);
            pushNotification.setMultiNotificationMode();
            pushNotification.setTags({
                userInfo: [currentUser.type, currentUser.address.city]
            }, (successTag) => {
                console.log(currentUser.type, currentUser.address.city);
                console.log('setTags success');
            }, (errorTag) => {
                console.log('setTags failed');
            });
            try {
                yield this.httpPromiseService.httpPostRequest(`/api/users/token`, { token: pushToken });
            }
            catch (error) {
                console.log('err updating token to server', error);
            }
        }), (status) => {
            console.log('failed to register: ' + status);
        });
    }
    navigateToSingleNotificationView(notificationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const currentUser = yield this.localStorageService.getLoggedInUserInfo();
            if (currentUser) {
                if (currentUser.type === UserTypeEnum.STAFFER) {
                    this.router.navigateByUrl(`talent-dashboard/notifications?id=${notificationId}`);
                }
                else if (currentUser.type === UserTypeEnum.EMPLOYER) {
                    this.router.navigateByUrl(`company-dashboard/notifications?id=${notificationId}`);
                }
            }
        });
    }
    removePushNotificationToken() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = yield this.localStorageService.getSingleKey('deviceToken');
            if (!token) {
                return;
            }
            try {
                yield this.httpPromiseService.httpPostRequest(`/api/users/rtoken`, { token });
            }
            catch (error) {
                console.log('err updating token to server', error);
            }
        });
    }
    ///////////////////////////////////////////////////////// desktop
    addScript(fileSrc, callback) {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = callback;
        script.src = fileSrc;
        head.appendChild(script);
    }
    initOneSignal(currentUser) {
        this.OneSignal = window.OneSignal || [];
        console.log('Init OneSignal');
        this.OneSignal.push(() => {
            // If we're on an unsupported browser, do nothing
            if (!this.OneSignal.isPushNotificationsSupported()) {
                console.log('not push notification supported');
                return;
            }
            this.OneSignal.init({
                appId: 'ea51ea11-80eb-4037-9e96-da341a61844b',
                // "f5adee87-116c-46c7-a343-046f40230c13",//"995cc7c7-e69a-41a0-9ed0-88f7390514c8", // PRODUCTION
                autoResubscribe: true,
                subdomainName: 'app',
                // /* The label for your site that you added in Site Setup mylabel.os.tc */
                welcomeNotification: {
                    disable: true,
                    title: 'STAFFY',
                    message: 'Thanks for subscribing to STAFFY',
                },
                persistNotification: true,
                notifyButton: {
                    enable: true,
                    position: 'bottom-left',
                    size: 'medium'
                },
                allowLocalhostAsSecureOrigin: true,
            });
        });
        this.checkIfSubscribed(currentUser);
    }
    checkIfSubscribed(currentUser) {
        this.OneSignal.getSubscription((state) => {
            console.log('state', state);
            if (state && currentUser._id) {
                console.log('get user onesignal id');
                this.OneSignal.getUserId((id) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    console.log('got user onesignal id');
                    try {
                        yield this.userService.updateUser(currentUser._id, { desktopTokenId: id });
                    }
                    catch (error) {
                        console.log(error);
                    }
                }));
            }
        });
        // On change event for OneSignal for subscription
        this.OneSignal.on('subscriptionChange', (isSubscribed) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (currentUser._id) {
                if (isSubscribed) {
                    console.log('subs');
                    this.OneSignal.getUserId((id) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        try {
                            yield this.userService.updateUser(currentUser._id, { desktopTokenId: id });
                        }
                        catch (error) {
                            console.log(error);
                        }
                    }));
                }
                else {
                    console.log('not subs');
                    try {
                        yield this.userService.updateUser(currentUser._id, { desktopTokenId: '' });
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
            }
        }));
    }
}
PushNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushNotificationService_Factory() { return new PushNotificationService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.Platform), i0.ɵɵinject(i3.HttpPromiseService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.UserService)); }, token: PushNotificationService, providedIn: "root" });
