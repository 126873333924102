import { Component, Input, OnInit } from '@angular/core';
import { PopupService } from '../../services/popup.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {

  @Input() url : string = ""
  showLoading : Boolean = true
  sanitizeUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private popupService : PopupService) { }

  ngOnInit() {

    if(this.url){
      this.sanitizeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
  }

  textLayerRendered() {
    this.showLoading = false;
  }

  onError() {
    this.showLoading = false;
    this.popupService.showModal({ 
      heading: 'Error', 
      message: 'Failed to load document. Please try again.', 
      btn: 'Dismiss', 
      navigateRoute: null,
      imgURL: 'assets/images/sorry1.png' 
    });

  }

  onProgress() {
    this.showLoading = true;
  }


}
