import * as tslib_1 from "tslib";
import { HttpPromiseService } from './http-promise.service';
import { LocalStorageService } from './local-storage.service';
import { Store } from '@ngrx/store';
import { setNotificationCountAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { forEach, findIndex } from 'lodash';
import { UtilService } from './util.services';
import * as moment from 'moment-timezone';
import { ModalController } from '@ionic/angular';
import { NotificationPopupComponent } from '../notification-popup/notification-popup.component';
import { ImagesService } from './images.service';
import { SuccessPopupComponent } from '../components/success-popup/success-popup.component';
import { ContractorShiftModifiedComponent } from '../components/contractor-shift-modified/contractor-shift-modified.component';
import * as i0 from "@angular/core";
import * as i1 from "./http-promise.service";
import * as i2 from "./local-storage.service";
import * as i3 from "./util.services";
import * as i4 from "@ngrx/store";
import * as i5 from "@ionic/angular";
import * as i6 from "./images.service";
export class NotificationService {
    constructor(httpPromiseService, localStorageService, utilService, authStore, modalController, imagesService) {
        this.httpPromiseService = httpPromiseService;
        this.localStorageService = localStorageService;
        this.utilService = utilService;
        this.authStore = authStore;
        this.modalController = modalController;
        this.imagesService = imagesService;
    }
    showPopup(notification) {
        if (notification.key == 'signupawaiting' || notification.body.includes('approved'))
            return false;
        return true;
    }
    getNotificationCount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.localStorageService.getLoggedInUserInfo();
            if (user) {
                try {
                    const count = yield this.httpPromiseService.httpGetRequest(`/api/notifications/myNotificationCount/${user._id}`);
                    if (typeof count === 'object') {
                        this.authStore.dispatch(setNotificationCountAction({ notificationCount: count.count }));
                    }
                }
                catch (error) {
                    console.log('err', error);
                }
            }
        });
    }
    isStafferConfirmedShift(notification, userData) {
        let isConfirmed = false;
        if (notification.job && notification.user && notification.type === 'confirm shift') {
            if (notification.user.type === 'staffer') {
                forEach(notification.job.staffersAndShifts, (shift) => {
                    if (shift && shift.staffer === userData._id && shift.state === 'accepted' && shift.isConfirmedShift) {
                        isConfirmed = true;
                    }
                });
            }
            else if (notification.user.type === 'employer') {
                isConfirmed = true;
            }
        }
        else {
            isConfirmed = true;
        }
        return isConfirmed;
    }
    isQuotaFilled(notification) {
        if (notification.job) {
            return notification.job.isABroadcastRequest.vacancies ===
                this.utilService.isStaffersConfirmedShifts(notification.job.staffersAndShifts).length;
        }
    }
    isStafferHired(notification, userData) {
        let is = false;
        if (userData) {
            if (notification.job && typeof notification.job.staffersAndShifts) {
                for (const shift of notification.job.staffersAndShifts) {
                    if (shift.staffer === userData._id) {
                        is = true;
                        break;
                    }
                }
            }
        }
        return is;
    }
    isJobRejected(notification, userData) {
        let response = false;
        if (userData) {
            if (notification.job && typeof notification.job.isABroadcastRequest.broadcastedTo) {
                for (const shift of notification.job.isABroadcastRequest.broadcastedTo) {
                    if (shift.staffer === userData._id && shift.status === 'rejected') {
                        response = true;
                        break;
                    }
                }
            }
        }
        return response;
    }
    getJobState(notification) {
        let state = 'offer';
        if (notification.job && notification.user) {
            if (notification.job.isABroadcastRequest) {
                for (const entity of notification.job.isABroadcastRequest.broadcastedTo) {
                    if (entity.staffer === notification.user._id) {
                        state = entity.status;
                        break;
                    }
                }
            }
            else {
                for (const entity of notification.job.staffersAndShifts) {
                    if (entity.staffer === notification.user._id) {
                        state = entity.state;
                        break;
                    }
                }
            }
        }
        return state;
    }
    hasFiledDispute(notification) {
        let disputeFiled = false;
        if (notification.user && notification.job && notification.job.staffersAndShifts) {
            for (const shift of notification.job.staffersAndShifts) {
                if (shift.staffer === notification.user._id &&
                    shift.dispute) {
                    disputeFiled = true;
                    break;
                }
            }
        }
        return disputeFiled;
    }
    isJobActive(notification) {
        if (notification.user) {
            let is = false;
            if (notification.job) {
                for (const shift of notification.job.staffersAndShifts) {
                    if (shift.staffer === notification.user._id) {
                        is = shift.active;
                        break;
                    }
                }
            }
            return is;
        }
    }
    isJobAccpted(notification, userData) {
        let isAccepted = false;
        if (notification.job && notification.user) {
            if (notification.type === 'confirm shift') {
                forEach(notification.job.staffersAndShifts, (s) => {
                    if (s && s.staffer === userData._id && s.state === 'accepted' && !s.isConfirmedShift) {
                        isAccepted = true;
                    }
                });
            }
        }
        return isAccepted;
    }
    validateShiftEndTime(shiftEndTime) {
        return moment().isBefore(moment.unix(shiftEndTime));
    }
    findTrackerIsStillWorking(notification, userData) {
        const currentUserIndex = notification.job ? findIndex(notification.job.staffersAndShifts, ['staffer', userData._id]) : '';
        if (notification.job && typeof notification.job.staffersAndShifts[currentUserIndex] !== 'undefined' &&
            typeof notification.job.staffersAndShifts[currentUserIndex].tracker !== 'undefined' &&
            notification.job.staffersAndShifts[currentUserIndex].tracker &&
            notification.job.staffersAndShifts[currentUserIndex].tracker.state !== 'stopped' &&
            !notification.job.staffersAndShifts[currentUserIndex].tracker.stillWorking) {
            return true;
        }
        else {
            return false;
        }
    }
    isHoursUnConfimred(notification, userData) {
        if (notification.type === 'confirm hours') {
            let isHoursConfirmedForTheShift = false;
            if (notification.job && notification.job.staffersAndShifts) {
                forEach(notification.job.staffersAndShifts, (s) => {
                    if (s.staffer === userData._id && !s.tracker.hasLoggedHoursConfirm) {
                        isHoursConfirmedForTheShift = true;
                    }
                });
            }
            return isHoursConfirmedForTheShift;
        }
    }
    showModal(notify, user, settings, cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: NotificationPopupComponent,
                backdropDismiss: true,
                cssClass: 'notificationsModal',
                componentProps: {
                    notify,
                    user,
                    settings
                }
            });
            yield myModal.present();
            return yield myModal.onDidDismiss().then(data => {
                return cb(data.data);
            });
        });
    }
    showShiftCancellationModal(groupJobId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let data = {
                heading: 'Cancelled!!',
                message: "Sorry this recurring shift has been cancelled",
                btn: "Close",
                navigateRoute: '',
                imgURL: 'assets/images/sorry1.png'
            };
            const myModal = yield this.modalController.create({
                component: SuccessPopupComponent,
                backdropDismiss: true,
                cssClass: 'wideModal',
                componentProps: {
                    data,
                    groupJobId
                }
            });
            yield myModal.present();
        });
    }
    showShiftModifiedModal(groupJobId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: ContractorShiftModifiedComponent,
                backdropDismiss: true,
                cssClass: 'wideModal',
                componentProps: {
                    groupJobId
                }
            });
            yield myModal.present();
        });
    }
    showShiftExtendedModal(groupJobId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: ContractorShiftModifiedComponent,
                backdropDismiss: true,
                cssClass: 'wideModal',
                componentProps: {
                    groupJobId,
                    shiftExtended: true
                }
            });
            yield myModal.present();
        });
    }
    getName(notification, userData) {
        if (notification.type === 'admin') {
            return 'Team Staffy';
        }
        else {
            if (userData) {
                if (userData.type == 'staffer') {
                    if (notification.job && notification.job.entity && notification.job.entity.creator) {
                        return notification.job.entity.name;
                    }
                    else {
                        return this.utilService.getUserName(notification.user);
                    }
                }
                else {
                    return this.utilService.getUserName(notification.user);
                }
            }
        }
    }
    getProfilePic(notification, userData) {
        if (notification.type === 'admin') {
            return 'assets/images/staffy-gray.png';
        }
        if (userData.type === 'staffer') {
            if (notification.job && notification.job.entity && notification.job.entity.creator) {
                return this.imagesService.getProfilePic(notification.job.entity.creator.profileUrl);
            }
            else {
                return this.imagesService.getProfilePic(notification.user.profileUrl);
            }
        }
        else {
            return this.imagesService.getProfilePic(notification.user.profileUrl);
        }
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.HttpPromiseService), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.UtilService), i0.ɵɵinject(i4.Store), i0.ɵɵinject(i5.ModalController), i0.ɵɵinject(i6.ImagesService)); }, token: NotificationService, providedIn: "root" });
;
