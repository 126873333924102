import * as tslib_1 from "tslib";
import { HttpPromiseService } from './http-promise.service';
import { HttpService } from './http.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-promise.service";
import * as i2 from "./http.service";
export class UserService {
    constructor(httpPromiseService, http) {
        this.httpPromiseService = httpPromiseService;
        this.http = http;
    }
    me() {
        return this.http.httpGetRequest('/api/users/me');
    }
    markBlocked() {
        return this.http.httpPostRequest('/api/users/markBlocked', {});
    }
    getUser() {
        return this.httpPromiseService.httpGetRequest(`/api/users/me`);
    }
    getCardList(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/users/cardList/${userId}`);
    }
    updateUserAvailability(stafferId, fixedAvailability, fixedStatus, scheduleAvailability, scheduleStatus) {
        const data = {};
        if (fixedAvailability) {
            data['availability_schedule'] = fixedAvailability,
                data['availability_status'] = fixedStatus;
        }
        if (scheduleAvailability) {
            data['schedule'] = {
                availability: scheduleAvailability,
                status: scheduleStatus
            };
        }
        return this.httpPromiseService.httpPutRequest(`/api/users/${stafferId}`, data);
    }
    updateUserAggregatedRatings(userId) {
        return this.httpPromiseService.httpPutRequest(`/api/users/${userId}/aggregated_rating`, {});
    }
    createUser(data) {
        return this.http.httpPostRequest('/api/users/createUser', data);
    }
    addressConfirmation(userId, data) {
        return this.http.httpPutRequest(`/api/users/${userId}`, data);
    }
    updateUserSignupPendingState(userId, data) {
        return this.http.httpPostRequest(`/api/users/updateUserSignupPendingState/${userId}`, data);
    }
    sendContractToEmail(data) {
        return this.http.httpPostRequest(`/api/users/contractSend/all`, data);
    }
    sendUserInvitationEmail(userId, data) {
        return this.httpPromiseService.httpPatchRequest(`/api/users/sendEmail/${userId}`, data);
    }
    updateUser(userId, data) {
        return this.http.httpPutRequest(`/api/users/${userId}`, data);
    }
    updateStafferAvailability(userId, data) {
        return this.httpPromiseService.httpPutRequest(`/api/users/${userId}/updateStafferAvailability`, data);
    }
    updateStafferAddress(userId, data) {
        return this.httpPromiseService.httpPutRequest(`/api/users/${userId}/updateStafferAddress`, data);
    }
    addCompanyLogo(data) {
        return this.http.httpPostRequest(`/api/users/avatar`, data);
    }
    userCreditCardAdd(userId, data) {
        return this.httpPromiseService.httpPostRequest(`/api/users/checkout/all/${userId}`, { token: data });
    }
    addCard(userId, token) {
        return this.httpPromiseService.httpPutRequest(`/api/users/addCard/${userId}`, { token });
    }
    setDefaultCard(userId, cardId) {
        return this.httpPromiseService.httpPostRequest(`/api/users/setDefaultCard/${userId}`, { cardId });
    }
    listForBlockingClients(stafferId) {
        return this.httpPromiseService.httpGetRequest(`/api/users/list/unblocked/?findListOf=entities&stafferId=${stafferId} `);
    }
    listForBlockingStaffies(entityId) {
        return this.httpPromiseService.httpGetRequest(`/api/users/list/unblocked/?findListOf=staffers&entityId=${entityId} `);
    }
    blockbyId(userId, body) {
        return this.httpPromiseService.httpPostRequest(`/api/users/blockbyId/${userId} `, body);
    }
    unblockbyId(userId, body) {
        return this.httpPromiseService.httpPostRequest(`/api/users/unblockbyId/${userId} `, body);
    }
    updateUserPromise(userId, data) {
        if (data.__v)
            delete data.__v;
        return this.httpPromiseService.httpPutRequest(`/api/users/${userId}`, data);
    }
    updateUserName(userId, userName) {
        return this.httpPromiseService.httpPutRequest(`/api/users/username/${userName}/${userId}`, {});
    }
    applyForBadge(userId, val, requestForAddressUpdate) {
        return this.httpPromiseService.httpPutRequest(`/api/users/applyForBadge/${userId}`, { hasAppliedForBadge: val, requestForAddressUpdate: requestForAddressUpdate });
    }
    getContract(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/users/stafferContract/download/${userId}`);
    }
    fetchStafferCertificates(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/certificates/getAllCertificatesByStafferId/${userId}?showDeleted=${true}`);
    }
    fetchStafferCertificatesByType(userId, type) {
        if (type.includes('&'))
            type = encodeURIComponent(type);
        return this.httpPromiseService.httpGetRequest(`/api/certificates/getStafferCertificateByType/${userId}?type=${type}`);
    }
    uploadMultipleCertificates(formData) {
        return this.httpPromiseService.httpPostRequest(`/api/certificates/uploadMultipleCertificates`, formData);
    }
    replaceCertificate(formData) {
        return this.httpPromiseService.httpPutRequest(`/api/certificates/update`, formData);
    }
    uploadCertificates(formData) {
        return this.httpPromiseService.httpPostRequest(`/api/users/uploadCertificates`, formData);
    }
    removeCertificate(userId, certificateId, isCOVID) {
        return this.httpPromiseService.httpDeleteRequest(`/api/certificates/deleteCertificate/${userId}/${certificateId}/?isCOVID=` + isCOVID);
    }
    removeCertificateForStaffer(userId, certificateId, type) {
        return this.httpPromiseService.httpPutRequest(`/api/certificates/removeRejectedCertificateForStaffer/${certificateId}`, {
            certificateId: certificateId, type: type
        });
    }
    updateAddressForCompany(employerId, data) {
        return this.httpPromiseService.httpPutRequest(`/api/users/${employerId}`, data);
    }
    addNewTaskIntoCompany(employerId, newTask) {
        return this.httpPromiseService.httpPostRequest(`/api/entitys/${employerId}/tasks`, { task: newTask });
    }
    editCompanyTask(employerId, task) {
        return this.httpPromiseService.httpPutRequest(`/api/entitys/${employerId}/tasks/${task._id}`, { task });
    }
    removeCompanyTask(employerId, taskId) {
        return this.httpPromiseService.httpDeleteRequest(`/api/entitys/${employerId}/tasks/?taskId=${taskId}`);
    }
    addNewPatientIntoCompany(employerId, newPatient) {
        return this.httpPromiseService.httpPostRequest(`/api/entitys/${employerId}/patient`, newPatient);
    }
    editCompanyPatient(employerId, patient) {
        return this.httpPromiseService.httpPutRequest(`/api/entitys/${employerId}/patient/${patient._id}`, { patient });
    }
    removeCompanyPatient(employerId, patientId) {
        return this.httpPromiseService.httpDeleteRequest(`/api/entitys/${employerId}/patient/${patientId}`);
    }
    removeTaskFromCompany(employerId, task) {
        return this.httpPromiseService.httpDeleteRequest(`/api/entitys/${employerId}/tasks?task=${task}`);
    }
    ensureEmailNotExists(email, type) {
        return this.httpPromiseService.httpPostRequest(`/api/users/ensureEmailNotExists?accountType=${type}`, { email });
    }
    ensureCompanyNameNotExists(companyName) {
        return this.httpPromiseService.httpPostRequest(`/api/users/ensureCompanyNameNotExists`, { companyName });
    }
    isUserExistInWhiteList(email, userId) {
        return this.httpPromiseService.httpPostRequest(`/api/createUserFromAdmin/checkEmails`, { email, userId });
    }
    getProfileInfo(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/users/${userId}/view-staffer-profile-data`);
    }
    getApprovedCertificatesOfStaffer(userId, isCOVID) {
        return this.httpPromiseService.httpGetRequest(`/api/certificates/getApprovedCertificatesOfStaffer/${userId}/?isCOVID=` + isCOVID);
    }
    createTrainingRequest(entityId, entityName, jobId) {
        return this.httpPromiseService.httpPostRequest(`/api/trainingRequest/`, { entityId, entityName, jobId });
    }
    extractSubSkillsFromErrorMessage(errorMessage) {
        return errorMessage
            .replace('You do not have the required', '')
            .replace('to work this shift.', '')
            .replace('(', '')
            .replace(')', '')
            .replace('subskills', '')
            .replace('subskill', '')
            .replace('"', '')
            .replace('"', '')
            .trim();
    }
    createSubSkillsRequest(userId, subSkills) {
        return this.httpPromiseService.httpPostRequest(`/api/users/subSkillsRequest/forContractor/`, { userId, subSkills });
    }
    getSubSkills() {
        return this.httpPromiseService.httpGetRequest(`/api/industries/subSkill/list`);
    }
    sendCodeToVerifyEmail(email) {
        return this.httpPromiseService.httpPostRequest(`/api/pendingEmailAddressVerification/`, {
            email
        });
    }
    sendCodeToVerifyEmailSignup(email) {
        return this.httpPromiseService.httpPostRequest(`/api/pendingEmailAddressVerification/signup`, {
            email
        });
    }
    setupETransferWithCode(otp) {
        return this.httpPromiseService.httpPostRequest(`/api/berkeleyPayment/e-transfer/setup-with-code`, {
            code: otp
        });
    }
    checkEmailWhiteListStatus(email) {
        return this.http.httpPostRequest(`/api/createUserFromAdmin/checkEmailStatus`, { email });
    }
    checkRequiredCertificateIsUploaded() {
        return this.httpPromiseService.httpGetRequest(`/api/certificates/checkRequiredCertificateIsUploaded`);
    }
    getCertificatesJSON(type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (type.includes('&')) {
                type = encodeURIComponent(type);
            }
            return this.httpPromiseService.httpGetRequest(`/api/certificates/certificates-json?type=${type}`);
        });
    }
    acknowledgeBulletin(entityId, bulletinId, skill) {
        return this.httpPromiseService.httpPostRequest(`/api/bulletin-acknowledgments`, {
            entityId,
            bulletinId,
            skill
        });
    }
    assembleBulletin(bulletinId, role) {
        return this.httpPromiseService.httpPostRequest(`/api/bulletin-templates/${bulletinId}/compile`, {
            role
        });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpPromiseService), i0.ɵɵinject(i2.HttpService)); }, token: UserService, providedIn: "root" });
