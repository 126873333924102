/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multi-address-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./multi-address-detail.component";
var styles_MultiAddressDetailComponent = [i0.styles];
var RenderType_MultiAddressDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultiAddressDetailComponent, data: {} });
export { RenderType_MultiAddressDetailComponent as RenderType_MultiAddressDetailComponent };
function View_MultiAddressDetailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "task-capsulated"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "task-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_MultiAddressDetailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "task-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "task-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tasks"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(4, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "div", [["class", "task-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultiAddressDetailComponent_3)), i1.ɵdid(7, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.tasks); _ck(_v, 7, 0, currVal_0); }, null); }
function View_MultiAddressDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "address-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(3, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "span", [["class", "address-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", "."])), (_l()(), i1.ɵted(6, 0, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultiAddressDetailComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.tasks == null) ? null : _v.context.$implicit.tasks.length)); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 5, 0, currVal_0); var currVal_1 = _v.context.$implicit.address; _ck(_v, 6, 0, currVal_1); }); }
export function View_MultiAddressDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "address-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "address-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Shift with multiple addresses "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "address-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "ion-list", [], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(6, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MultiAddressDetailComponent_1)), i1.ɵdid(8, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "address-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "ion-button", [["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.addressAndTasksList; _ck(_v, 8, 0, currVal_0); var currVal_1 = "basic"; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_MultiAddressDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-multi-address-detail", [], null, null, null, View_MultiAddressDetailComponent_0, RenderType_MultiAddressDetailComponent)), i1.ɵdid(1, 114688, null, 0, i5.MultiAddressDetailComponent, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultiAddressDetailComponentNgFactory = i1.ɵccf("app-multi-address-detail", i5.MultiAddressDetailComponent, View_MultiAddressDetailComponent_Host_0, { addressAndTasksList: "addressAndTasksList" }, {}, []);
export { MultiAddressDetailComponentNgFactory as MultiAddressDetailComponentNgFactory };
