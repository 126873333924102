import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @Input() url: string;

  get videoUrl() {
    if (this.url.length) {
      return this.url;
    }
    return '';
  }

  constructor(){}
  ngOnInit() {

  }

}
