<div class="notice-wrapper">
  <div class="notice-head">
    <a  class="close" (click)="closeModal()"><i class = "fa fa-times"></i></a>
  </div>
  <ng-container>
    <div class="notice-text">
      <h1>{{title}}</h1>
      <div  *ngIf="confirmationView"class="para">{{description}}</div>
    </div>

    <div *ngIf="!confirmationView" class="notice-success">
      <div class="para">{{description}}</div>
    </div>

    <app-edit-recurring-shift-list
    *ngIf="confirmationView"
    [confirmationView]="true"
    [config]="config" [data]="shifts"
    ></app-edit-recurring-shift-list>

    <div  *ngIf="confirmationView" class="btn-wrapper">
      <ion-button color="basic" class="ion-no-margin btn" (click)="confirmShift()">
       Confirm
      </ion-button>
      <a class="link">
        Go back to shift details
      </a>
    </div>

    <div *ngIf="!confirmationView" class="btn-wrapper">
      <ion-button color="basic" class="ion-no-margin btn" (click)="navigateToUpcomingShifts()">
        See upcoming shifts
      </ion-button>
    </div>
  </ng-container>
</div>