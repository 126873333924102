import { HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';

/**
 * Check if headers should be attached or not
 *
 * @export
 * @param {HttpRequest<any>} req
 * @param {RegExp} [exempted=/(auth\/sign)/]
 * @returns {Observable<boolean>}
 */
export function shouldAppendHeadersTo(
  req: HttpRequest<any>,
  exempted: RegExp = /(auth\/local)|(api\/users\/createUser)/
): Observable<boolean> {
  const { urlWithParams } = req;
  const isExempted = new RegExp(exempted).test(urlWithParams);
  return of(!isExempted);
}
