import { Component, Input, OnInit } from '@angular/core';
import { FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { BulletinViewerComponent } from '../components/bulletin-viewer/bulletin-viewer.component';
import { IBulletin } from '../interfaces/bulletin.interface';



@Component({
  selector: 'app-bulletins',
  templateUrl: './bulletins.component.html',
  styleUrls: ['./bulletins.component.scss'],
})
export class BulletinsComponent implements OnInit {

  @Input() bulletins: IBulletin;
  @Input() entityId: string;
  downloadInprogressPdfId: string;

  constructor(private platform: Platform,
    private fileOpener: FileOpener,
    private transfer: FileTransfer,
    private file: File, 
    private talentJobFormatService: TalentJobFormatService,
    private modalController: ModalController) { }

  ngOnInit() {}

  async openBulletin(data) {
      const myModal = await this.modalController.create({
        component: BulletinViewerComponent,
        backdropDismiss: false,
        cssClass: 'wideModal',
        componentProps: {
          url: data.type === 'video-url'? data.url : data.secure_url,
          title: data.name,
          isVideo : data.type === 'video-url' ? true : false,
          isAcknowledgmentRequired :  false,
          bulletinId : data._id,
          entityId : this.entityId 
        }
      });
      return await myModal.present();
    
  }

  openPdf(data) {
    if (!this.downloadInprogressPdfId) {
      console.log("opening pdf");
      const fileName = `ShiftBulletin-${data._id}.pdf`;
      const isBrowser = this.talentJobFormatService.isBrowserCheck();
      if ((this.platform.is('android') || this.platform.is('ios') || this.platform.is('ipad')
      ) && !isBrowser) {

        this.downloadInprogressPdfId = data._id;
        const path = this.file.dataDirectory;

        const fileTransfer: FileTransferObject = this.transfer.create();
        fileTransfer.download(data.secure_url, path + fileName).then(async (entry) => {
          this.fileOpener.open(path + fileName, 'application/pdf');
          this.downloadInprogressPdfId = null;
        }).catch((err) => {
          console.log(err);
          alert('Error saving file: ' + err.message);
          this.downloadInprogressPdfId = null;
        });
      } else {
        window.open(data.secure_url, '_system');
      }
    }
  }

  dimissModal() {
    this.modalController.dismiss();
  }

}
