<ion-grid class="ion-no-padding height-100">
  <ion-row class="ion-justify-content-center  main-wrapper">
    <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="10" size-xs="10">
      <div class="heading-wrapper">
        <h1 class="ion-text-center title"> {{ 'STAFFER_SKILLS_ROOM_ATTENDANT.title' | translate }} </h1>
        
        <h1 class="ion-text-center heading"> SUB SKILLS </h1>

      </div>
      
      <div class="form-wrapper">
        <div class="card-wrapper" *ngFor="let item of data; index as i">
          <div class="left-wrap"  (click)="cardClicked(i, $event)" >
            <label class="custom-checkbox-container" >
              <input type="checkbox" [(ngModel)]="item.isChecked" [disabled] = "item.isDisabled"> 
              <span class="checkmark"></span>
            </label>
            <ion-label [ngClass]="{'color-gray' : !item.isChecked}" class="skill-label">{{item.title}}<i *ngIf = "item.isDisabled" class = "fa fa-check green"></i></ion-label>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class = "tag-row">
    <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="10" size-xs="10">
      <h3 class="ion-text-center tag-line"> {{ 'SKILLS.sub_tag' | translate }}</h3>
    </ion-col>
  </ion-row>

  <ion-row class="ion-justify-content-center ion-align-items-end btn-wrapper">
    <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="10" size-xs="10">
      <div class="buttons-section" style="margin-bottom: 100px;">
        <ion-button color="basic" expand="full" (click)='next()'
          class="onboarding-company-btn submit-btns  ion-no-margin">
          {{ 'SHARED_BTN_TEXT.next' | translate}} <i class="fas fa-chevron-right icon-right"></i>
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

