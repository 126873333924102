<div class = "toggle-schedule">
  <ion-button>
    <div *ngIf = "allText && (userType === 'client')" class = "schedule" [ngClass] = "{'toggleActive' : value === 'all'}" (click)="onToggleChange('all')">
      {{ allText }}
    </div>
    <div class = "schedule" [ngClass] = "{'toggleActive' : value === false}" (click)="onToggleChange(false)">
      {{ leftText }}
    </div>
    <div class = "schedule" [ngClass] = "{'toggleActive' : value === true}"  (click)="onToggleChange(true)">
      {{ rightText }}
    </div>
  </ion-button>
</div>