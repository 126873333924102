import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { UserService } from '../../../../shared/services/user.service';
import { Store } from '@ngrx/store';
import { getUserInfoAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { ModalController } from '@ionic/angular';
export class AddTaskComponent {
    constructor(userService, authStore, modalController) {
        this.userService = userService;
        this.authStore = authStore;
        this.modalController = modalController;
        this.task = {
            title: '',
            description: ''
        };
    }
    ngOnInit() {
        console.log(this.task);
    }
    closeModal() {
        this.modalController.dismiss(false);
    }
    addTask() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.task.title || !this.task.description) {
                return;
            }
            try {
                let res;
                if (this.task._id) {
                    res = yield this.userService.editCompanyTask(this.entityId, this.task);
                }
                else {
                    res = yield this.userService.addNewTaskIntoCompany(this.entityId, this.task);
                }
                if (res === 'success') {
                    this.authStore.dispatch(getUserInfoAction());
                    this.modalController.dismiss(false);
                }
            }
            catch (error) {
                console.log(error);
            }
        });
    }
}
