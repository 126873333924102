import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { forEach, groupBy, isEqual } from 'lodash';
import * as moment from 'moment';
import { distinctUntilChanged } from 'rxjs/operators';
import { getUserInfoAction, setNotificationCountAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { TalentDashboardService } from 'src/modules/talent-dashboard/services/talent-dashboard.service';
import { TalentLocationTrackingService } from 'src/modules/talent-dashboard/services/talent-location-tracking.service';
import { AnalyticsService } from '../../services/analytics.service';
import { ApplyErrorHandler } from '../../services/apply-error-handler.service';
import { JobService } from '../../services/job.service';
import { LoadingService } from '../../services/loading.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { PopupService } from '../../services/popup.service';
import { SubSkillService } from '../../services/subskill.service';
import { UserService } from '../../services/user.service';
import { UtilService } from '../../services/util.services';
import { appendQueryParameter } from '../../utils/helpers/appendQueryParameter.helper';
import { rejectNonRecurringSuccessAction, rejectRecurringSuccessAction } from 'src/modules/talent-dashboard/+store/actions/talent-dashboard.actions';
import { TalentJobChecksService } from 'src/modules/talent-dashboard/services/talent-job-checks.service';
export class NotificationListComponent {
    constructor(el, authStore, talentDashboardStore, talentDashboardService, jobService, utilService, router, route, alertController, loadingService, popupService, userService, localStorageService, analyticsService, platform, subSkillService, applyErrorHandler, cdRef, modalController, talentJobChecksService, talentLocationTrackingService) {
        this.el = el;
        this.authStore = authStore;
        this.talentDashboardStore = talentDashboardStore;
        this.talentDashboardService = talentDashboardService;
        this.jobService = jobService;
        this.utilService = utilService;
        this.router = router;
        this.route = route;
        this.alertController = alertController;
        this.loadingService = loadingService;
        this.popupService = popupService;
        this.userService = userService;
        this.localStorageService = localStorageService;
        this.analyticsService = analyticsService;
        this.platform = platform;
        this.subSkillService = subSkillService;
        this.applyErrorHandler = applyErrorHandler;
        this.cdRef = cdRef;
        this.modalController = modalController;
        this.talentJobChecksService = talentJobChecksService;
        this.talentLocationTrackingService = talentLocationTrackingService;
        this.openHeight = 0;
        this.user = this.authStore.pipe(select(getUserInfo));
        this.isLoadingComplete = false;
        this.isOpen = true;
        this.hideList = new EventEmitter();
        this.isPageAlive$ = true;
    }
    ionViewWillEnter() {
        this.cdRef.detectChanges();
        this.toggleDrawer();
    }
    ionViewDidEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentDate = moment().tz('America/Toronto').format('MMM DD, YYYY');
            let user = this.authStore.pipe(select(getUserInfo), distinctUntilChanged(isEqual));
            this.subscription = user.subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.userData = result;
                if (this.userData) {
                    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
                        this.notificationId = params.id;
                        if (this.notificationId) {
                            this.loadSingleNotification();
                        }
                        else {
                            this.loadNormal();
                        }
                    });
                }
            }));
            this.settings = yield this.localStorageService.getSetiings();
            this.message = this.settings.messageOfDay || 'No Message Found';
            if (this.settings && this.settings.hour24PayAvailableBadge) {
                this.hour24PayAvailableBadge = true;
            }
            else {
                this.hour24PayAvailableBadge = false;
            }
        });
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
        console.log('destroyed');
    }
    ngAfterViewChecked() {
        this.toggleDrawer();
    }
    drawerVisibility(redirectPath) {
        this.isOpen = !this.isOpen;
        this.toggleDrawer();
        this.modalController.dismiss({
            isOpen: this.isOpen,
            redirectPath
        }, null, "notification-list-modal");
    }
    toggleDrawer() {
        const drawer = this.drawer.nativeElement;
        if (this.isOpen) {
            drawer.style.visibility = 'visible';
            drawer.style.transition = '.4s ease-out';
            drawer.style.transform = 'translateY(0px)';
            if (drawer) {
                let wrapper = this.el.nativeElement.querySelector('.wrapper');
                if (wrapper)
                    wrapper.style.height = `${this.openHeight - 100}px`;
            }
        }
        else {
            drawer.style.transition = '.4s ease-out';
            drawer.style.transform = `translateY(${this.openHeight}px)`;
            this.el.nativeElement.querySelector('.wrapper').style.height = `${this.openHeight - 100}px`;
            drawer.style.visibility = 'hidden';
        }
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.openHeight = (this.platform.height());
            this.noShifts = {
                text: 'No notification found',
                imageUrl: 'assets/images/sorry1.png',
                subText: '',
                buttonText: '',
                navigateRoute: ''
            };
        });
    }
    returnZero() {
        return 0;
    }
    showMOTD() {
        this.popupService.showModal({
            heading: 'Staffy: Message of the day',
            message: this.message,
            btn: 'Ok',
            navigateRoute: null,
            imgURL: ''
        });
    }
    loadSingleNotification() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                const notification = yield this.talentDashboardService.getSingleNotifications(this.notificationId);
                const notifications = yield this.formatAndFeedCurrentUserNotifications([notification]);
                this.notifications = groupBy(notifications, 'day');
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.loadingService.hideLoading(loading);
                this.isLoadingComplete = true;
            }
        });
    }
    getLatestNotifications() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let loading;
            if (this.isOpen)
                loading = yield this.loadingService.showLoading().toPromise();
            try {
                this.notificationData = yield this.talentDashboardService.getNotifications(this.userData._id);
                const notifications = yield this.formatAndFeedCurrentUserNotifications(this.notificationData.notifications);
                this.notifications = groupBy(notifications, 'day');
            }
            catch (error) {
                console.log(error);
            }
            finally {
                if (loading)
                    this.loadingService.hideLoading(loading);
                this.isLoadingComplete = true;
            }
        });
    }
    formatAndFeedCurrentUserNotifications(notifications) {
        forEach(notifications, (notification) => {
            let day;
            const created = moment(notification.created);
            const today = moment().clone().startOf('day');
            const yesterday = moment().clone().subtract(1, 'days').startOf('day');
            if (created.isSame(today, 'day')) {
                day = 'Today';
            }
            else if (created.isSame(yesterday, 'd')) {
                day = 'Yesterday';
            }
            else {
                day = created.format('MMM DD, YYYY');
            }
            notification.day = day;
            if (notification.job && notification.groupJobId) {
                notification.job.isStatPay = this.utilService.isStatPayAvailable(notification.groupJobId.jobs);
                if (notification.job.state === 'offer') {
                    notification.isAJobOffer = true;
                }
                else {
                    notification.isAJobOffer = false;
                }
                if (notification.job.state === 'complete' && notification.job.comments.staffer.rating) {
                    notification.isARateRequestAndHasBeenRated = true;
                }
                else {
                    notification.isARateRequestAndHasBeenRated = false;
                }
            }
        });
        return notifications;
    }
    confirmedClear() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.talentDashboardService.dismissOldNotifications(this.userData._id, +moment().format('X'));
                yield this.getLatestNotifications();
            }
            catch (e) {
                console.log(e);
            }
            finally {
                this.loadingService.hideLoading(loading);
                this.isLoadingComplete = true;
            }
        });
    }
    cardClicked(notification) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.talentDashboardService.updateNotificationReadStatus(notification._id);
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    clear() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: 'Dismiss Notifications',
                message: 'Are you sure you want to clear previous notifications?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            this.confirmedClear();
                        }
                    }
                ]
            });
            yield alert.present();
        });
    }
    doRefresh(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.notificationId) {
                yield this.loadSingleNotification();
            }
            else {
                yield this.loadNormal();
            }
            event.target.complete();
        });
    }
    loadNormal() {
        this.authStore.dispatch(setNotificationCountAction({ notificationCount: 0 }));
        if (this.userData) {
            this.getLatestNotifications();
        }
    }
    acceptNonRecurringBroadcastRequest(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.jobService.acceptBroadcastRequestNonRecurring(data.jobId, data.stafferId, data.acceptedRcmpFee);
                const homecare = data.job.entity.homecareClient;
                if (homecare && homecare.screening && homecare.screening.isRequired) {
                    let link = homecare.screening.link;
                    const entityName = data.job.entity.name;
                    if (data.job.homecareShift && data.job.homecareShift.patientId) {
                        link = appendQueryParameter(link, `a3=${data.job.homecareShift.patientId}`);
                    }
                    return this.popupService.showScreeningPopup(link, entityName);
                }
                this.popupService.showModal({
                    heading: 'Shift requested',
                    message: 'You have successfully requested this shift! We will send you a notification if you are approved.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/clap.png'
                });
                this.userIndustry = yield this.utilService.getIndustryBySkillName(data.skill);
                this.analyticsService.trackEvent('job_applied', 'User Flow', '3 Apply Job', 'app', this.userIndustry);
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
                // checking if we need to show subskill error
                const { isHandled } = this.subSkillService.checkSubSkillError(error, this.userData);
                if (isHandled) {
                    return;
                }
                this.applyErrorHandler.handleError(error);
            }
        });
    }
    unApplyFromNonRecurringShift(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.jobService.unApplyFromBroadcast(data.jobId, this.userData, true, false, data.dueToRCMPFee);
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
            }
        });
    }
    rejectNonRecurringJob(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.jobService.rejectNonRecurringJob(Object.assign({}, data.job));
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
                //Dispatch action to update the state of non recurring shift in staffer and shifts 
                //Update state to "reject"
                this.talentDashboardStore.dispatch(rejectNonRecurringSuccessAction({ jobId: data.job._id, stafferId: this.userData._id }));
                this.popupService.showModal({
                    heading: 'Job rejected',
                    message: 'Shift rejected. The creator has been notified.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/sorry1.png'
                });
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
            }
        });
    }
    acceptNonRecurringHireRequest(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.jobService.acceptNonRecurringHireRequest(data.jobId, {
                    stafferId: this.userData._id,
                    shiftResponseTime: data.shiftResponseTime
                });
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
                this.popupService.showModal({
                    heading: 'Job Accepted',
                    message: 'Shift accepted. The creator has been notified.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/thumbs-up.png'
                });
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
                this.popupService.showModal({
                    heading: 'Technical Issue',
                    message: 'Technical error, contact Staffy OPS Team to proceed.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/profile.png'
                });
            }
        });
    }
    confirmNonRecurringShift(data, notification) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.userData.hasAccessToApplyOnShifts) {
                this.popupService.showModal({
                    heading: 'Please sign the Waiver of Liability',
                    message: 'To be able to apply to this shift, you will need to sign the Waiver of liability.',
                    btn: 'Ok',
                    navigateRoute: 'talent-dashboard/hello-sign',
                    imgURL: 'assets/images/profile.png'
                });
            }
            else {
                const loading = yield this.loadingService.showLoading().toPromise();
                try {
                    //Check if the contractor has valid VSC before confirming for non recurring shift
                    yield this.talentJobChecksService.ensureContractorHasValidDocuments(notification.job, this.userData);
                    yield this.jobService.nonRecurringShiftConfirm(data.jobId, this.userData._id);
                    yield this.loadingService.hideLoading(loading);
                    this.getLatestNotifications();
                }
                catch (error) {
                    console.log('error', error);
                    yield this.loadingService.hideLoading(loading);
                    if (error === 'VACANCIES_FULL') {
                        this.popupService.showModal({
                            heading: 'Vacancies full',
                            message: `Sorry, all vacancies for this shift are full.`,
                            btn: 'Dismiss',
                            navigateRoute: null,
                            imgURL: 'assets/images/sorry1.png'
                        });
                    }
                    else if (error && error.error && typeof error.error === 'string') {
                        let heading = 'Sorry';
                        let cssClass = '';
                        //Overwrite heading and CSS class if the error is a background check error
                        if (error.error.includes('background check')) {
                            heading = 'This shift requires a valid background check';
                            cssClass = 'background-check';
                        }
                        this.popupService.showModal({
                            heading: heading,
                            message: error.error,
                            btn: 'Dismiss',
                            navigateRoute: null,
                            imgURL: 'assets/images/sorry1.png',
                            specificClass: cssClass
                        });
                    }
                }
            }
        });
    }
    stafferStillWorkingOnJob(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.talentDashboardService.stillWorkingStafferNonRecurringJob(data.trackerId);
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
            }
        });
    }
    rejectStafferBroadcastRequest(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.jobService.rejectStafferBroadcastRequest(data);
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
            }
        });
    }
    seeAvailableJobs() {
        this.router.navigateByUrl('talent-dashboard/home');
    }
    updateUser(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // for rcmp update
            try {
                yield this.userService.updateUserPromise(this.userData._id, user);
                this.authStore.dispatch(getUserInfoAction());
            }
            catch (error) {
                console.log('err', error);
            }
        });
    }
    submitRating(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                const payload = {
                    stafferId: data.stafferId,
                    rating: data.rating,
                    jobIds: [data.jobId],
                    skill: data.skill
                };
                yield this.jobService.rateStafferInJobs(payload);
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log(error);
            }
        });
    }
    stopTimerOnJob(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                const location = yield this.talentLocationTrackingService.getUserLocation();
                yield this.talentDashboardService.stopTimer(data.jobId, data.endTime, data.userId, {
                    latitude: `${location.coords.latitude}`,
                    longitude: `${location.coords.longitude}`
                });
                this.popupService.showModal({
                    heading: 'End Shift Request',
                    message: 'Shift ended. The creator will be sent a notification.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/request-sent.png'
                });
                this.userIndustry = yield this.utilService.getIndustryBySkillName(data.skill);
                this.analyticsService.trackEvent('job_completed', 'User Flow', '4 Complete Job', 'app', this.userIndustry);
                this.getLatestNotifications();
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
            }
        });
    }
    hireStafferForTheNonRecurringJob(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const invoiceResponse = yield this.ensureStafferAvailibility(data.job._id, data.staffer._id);
            if (!invoiceResponse) {
                return;
            }
            const bCStaffer = data.job.isABroadcastRequest.broadcastedTo;
            for (const shift of bCStaffer) {
                if (shift.staffer === data.staffer._id && shift.status === 'hired') {
                    return;
                }
                else if (this.utilService.isStaffersConfirmedShifts(data.job.staffersAndShifts).length === data.job.isABroadcastRequest.vacancies) {
                    this.popupService.showModal({
                        heading: 'OOPS!',
                        message: 'Vacancies are Full.',
                        btn: 'Dismiss',
                        navigateRoute: null,
                        imgURL: 'assets/images/sorry1.png'
                    });
                    return;
                }
            }
            const updatedJob = yield this.updateJobStatus(data.job._id, data.staffer._id, invoiceResponse);
            this.updateAvailibility(data.staffer, updatedJob);
        });
    }
    ensureStafferAvailibility(jobId, stafferId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.jobService.isStafferAvailableToAcceptJob(jobId, { staffer_id: stafferId });
            }
            catch (error) {
                if (error.errMsg) {
                    this.popupService.showModal({
                        heading: 'Sorry!',
                        message: 'Something went wrong, please contact Staffy Team to proceed.',
                        btn: 'Dismiss',
                        navigateRoute: null,
                        imgURL: 'assets/images/profile.png'
                    });
                }
                else {
                    this.popupService.showModal({
                        heading: 'OOPS!',
                        message: 'Staffer is no longer available in that time slot.',
                        btn: 'Dismiss',
                        navigateRoute: null,
                        imgURL: 'assets/images/profile.png'
                    });
                }
                return;
            }
        });
    }
    updateJobStatus(jobId, stafferId, invoiceResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const payload = {
                stafferAndShift: {
                    staffer: stafferId,
                    state: 'accepted',
                    active: true
                }
            };
            if (invoiceResponse.invoiceId) {
                payload['invoices'] = invoiceResponse.invoiceId;
            }
            try {
                return yield this.jobService.updateJob(jobId, payload);
            }
            catch (error) {
                console.log('err', error);
                this.popupService.showModal({
                    heading: 'Sorry!',
                    message: 'Something went wrong, please contact Staffy Team to proceed.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/profile.png'
                });
                return;
            }
        });
    }
    updateAvailibility(staffer, updatedJob) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (staffer.availability_schedule.length) {
                let acceptedJobDate = moment(updatedJob.shiftDate).format('MMMM Do YYYY');
                if (moment.unix(updatedJob.shiftStartTime).hours() < 6) {
                    acceptedJobDate = moment.unix(updatedJob.shiftStartTime).subtract(1, 'days').format('MMMM Do YYYY');
                }
                const interestedStafferUpdatedSchedule = [];
                forEach(staffer.availability_schedule, schedule => {
                    if (schedule.date !== acceptedJobDate) {
                        interestedStafferUpdatedSchedule.push(schedule); // update availability status for that date
                    }
                });
                yield this.userService.updateUserAvailability(staffer._id, interestedStafferUpdatedSchedule, true);
                this.popupService.showModal({
                    heading: 'Request Approved!',
                    message: 'The staffer will be sent a notification.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/request-sent.png'
                });
                this.getLatestNotifications();
            }
            else {
                this.popupService.showModal({
                    heading: 'Request Approved!',
                    message: 'The staffer will be sent a notification.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/request-sent.png'
                });
                this.getLatestNotifications();
            }
        });
    }
    acceptRecurringBroadcastRequest(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.talentDashboardService.acceptBroadcastRequestRecurring(data.groupJobId, data.stafferId, data.acceptedRcmpFee);
                const homecare = data.entity.homecareClient;
                if (homecare && homecare.isTrue && homecare.screening && homecare.screening.isRequired) {
                    let link = homecare.screening.link;
                    const entityName = data.entity.name;
                    if (data.job && data.job.homecareShift && data.job.homecareShift.patientId) {
                        link = appendQueryParameter(link, `a3=${data.job.homecareShift.patientId}`);
                    }
                    return this.popupService.showScreeningPopup(link, entityName);
                }
                this.popupService.showModal({
                    heading: 'Shift requested',
                    message: 'You have successfully requested this shift! We will send you a notification if you are approved.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/clap.png'
                });
                this.userIndustry = yield this.utilService.getIndustryBySkillName(data.skill);
                this.analyticsService.trackEvent('job_applied', 'User Flow', '3 Apply Job', 'app', this.userIndustry);
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
                // checking if we need to show subskill error
                const { isHandled } = this.subSkillService.checkSubSkillError(error, this.userData);
                if (isHandled) {
                    return;
                }
                this.applyErrorHandler.handleError(error);
            }
        });
    }
    rejectStafferRecurringBroadcastRequest(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.jobService.rejectStafferRecurringRequest(data.groupJobId, {
                    stafferId: data.stafferId,
                    notificationId: data.notificationId
                });
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
            }
        });
    }
    unApplyFromRecurringShift(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.jobService.unApplyFromBroadcast(data.groupJobId, this.userData, true, true, data.dueToRCMPFee);
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
            }
        });
    }
    confirmRecurringShift(data, notification) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.userData.hasAccessToApplyOnShifts) {
                this.popupService.showModal({
                    heading: 'Please sign the Waiver of Liability',
                    message: 'To be able to apply to this shift, you will need to sign the Waiver of liability.',
                    btn: 'Ok',
                    navigateRoute: 'talent-dashboard/hello-sign',
                    imgURL: 'assets/images/profile.png'
                });
            }
            else {
                const loading = yield this.loadingService.showLoading().toPromise();
                try {
                    //Check if the contractor has valid VSC before confirming for recurring shift
                    yield this.talentJobChecksService.ensureContractorHasValidDocuments(notification.job, this.userData);
                    yield this.jobService.recurringShiftConfirm(data.groupJobId, this.userData._id);
                    yield this.loadingService.hideLoading(loading);
                    this.getLatestNotifications();
                }
                catch (error) {
                    console.log('error', error);
                    yield this.loadingService.hideLoading(loading);
                    if (error === 'VACANCIES_FULL') {
                        this.popupService.showModal({
                            heading: 'Vacancies full',
                            message: `Sorry, all vacancies for this shift are full.`,
                            btn: 'Dismiss',
                            navigateRoute: null,
                            imgURL: 'assets/images/sorry1.png'
                        });
                    }
                    else if (error && error.error && typeof error.error === 'string') {
                        let heading = 'Sorry';
                        let cssClass = '';
                        //Overwrite heading and CSS class if the error is a background check error
                        if (error.error.includes('background check')) {
                            heading = 'This shift requires a valid background check';
                            cssClass = 'background-check';
                        }
                        this.popupService.showModal({
                            heading: heading,
                            message: error.error,
                            btn: 'Dismiss',
                            navigateRoute: null,
                            imgURL: 'assets/images/profile.png',
                            specificClass: cssClass
                        });
                    }
                }
            }
        });
    }
    acceptRecurringHireRequest(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.talentDashboardService.acceptRecurringHireRequest({
                    stafferId: this.userData._id,
                    groupJobId: data.groupJobId,
                    shiftResponseTime: data.shiftResponseTime
                });
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
                this.popupService.showModal({
                    heading: 'Job Accepted',
                    message: 'Shift accepted. The creator has been notified.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/thumbs-up.png'
                });
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
                this.popupService.showModal({
                    heading: 'Technical Issue',
                    message: 'Technical error, contact Staffy OPS Team to proceed.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/profile.png'
                });
            }
        });
    }
    rejectRecurringHireRequest(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                yield this.talentDashboardService.rejectRecurringHireRequest({
                    groupJobId: data.groupJobId,
                    stafferId: this.userData._id
                });
                this.loadingService.hideLoading(loading);
                this.getLatestNotifications();
                //Dispatch action to update the state of recurring shifts in staffer and shifts  
                //Update state to "reject"
                this.talentDashboardStore.dispatch(rejectRecurringSuccessAction({ groupJobId: data.groupJobId, stafferId: this.userData._id }));
                this.popupService.showModal({
                    heading: 'Job rejected',
                    message: 'Shift rejected. The creator has been notified.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/sorry1.png'
                });
            }
            catch (error) {
                this.loadingService.hideLoading(loading);
                console.log('error', error);
            }
        });
    }
    hireStafferForTheRecurringJob(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.jobService.hireStafferOnRecurringJob(data.groupJobId, {
                    stafferId: data.staffer._id
                });
                this.popupService.showModal({
                    heading: 'Request Approved!',
                    message: 'The staffer will be sent a notification.',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/request-sent.png'
                });
                this.getLatestNotifications();
            }
            catch (err) {
                console.log('error', err);
                let errMsg = 'Something went wrong, please contact Staffy Team to proceed.';
                if (err.error === 'Vacancies are Full') {
                    errMsg = 'Vacancies are Full';
                }
                else if (err.error === 'Availability is not set for job specific days') {
                    errMsg = 'Staffer is not available in this time frame';
                }
                else if (err.transaction === false) {
                    errMsg = 'Your credit card was declined';
                }
                this.popupService.showModal({
                    heading: 'SORRY!',
                    message: errMsg,
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/profile.png'
                });
            }
        });
    }
}
