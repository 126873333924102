import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HomecarePatient } from '../../interfaces/homecare-patient.interface';

@Component({
  selector: 'app-multi-address-detail',
  templateUrl: './multi-address-detail.component.html',
  styleUrls: ['./multi-address-detail.component.scss'],
})
export class MultiAddressDetailComponent implements OnInit {

  @Input() addressAndTasksList: [{
    address: string,
    tasks: string[]
  }]
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss()
  }

}
