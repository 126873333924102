import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-detail-popup',
  templateUrl: './detail-popup.component.html',
  styleUrls: ['./detail-popup.component.scss'],
})
export class DetailPopupComponent implements OnInit {

  @Input() data: {
    heading?: string;
    message?: string;
    listHead?:string;
    listPoints?: any;
    btnText?:string;
    certificate?:boolean
  };

  heading: string;
  message: string;
  btnText: string;
  listHead: string;
  listPoints: any;
  certificate: boolean;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    this.heading = this.data.heading || '';
    this.message = this.data.message || '' ;
    this.btnText = this.data.btnText || '';
    this.listHead  = this.data.listHead || '';
    this.listPoints = this.data.listPoints || null;
    this.certificate = this.data.certificate || false;
  }

  submit() {
    this.modalController.dismiss();
  }
}
