import * as tslib_1 from "tslib";
import * as moment from 'moment-timezone';
import BigNumber from 'bignumber.js';
import { forEach, findIndex } from 'lodash';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import 'moment-duration-format';
import { setTalentDashboardStoreAction } from '../+store/actions/talent-dashboard.actions';
import { Store } from '@ngrx/store';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/local-storage.service";
import * as i2 from "@ngrx/store";
export class TalentDashboardUtilService {
    constructor(localStorageService, store) {
        this.localStorageService = localStorageService;
        this.store = store;
    }
    optimizeTime(time, caps) {
        if (moment.unix(time).format('mm') === '00') {
            if (caps) {
                return moment.unix(time).format('h:mm a');
            }
            return moment.unix(time).format('h:mm a');
        }
        else {
            if (caps) {
                return moment.unix(time).format('hh:mm a');
            }
            return moment.unix(time).format('hh:mm a');
        }
    }
    unapprovedStafferFromPreviousApp(userData) {
        if (userData && userData.type == 'staffer' && (userData.isSignupProcessComplete == false || !userData.profile_approved))
            return true;
        return false;
    }
    getcalculatedPotentialEarning(job) {
        let shiftTime = moment.duration(job.shiftEndTime - job.shiftStartTime, 'seconds').asHours();
        //Deduct break from job, if break exists
        if (typeof job.break !== 'undefined' && job.break.unpaid) {
            shiftTime = this.deductBreakByMinutes(shiftTime, job.break.unpaid);
        }
        //Set initial rate of job depending upon conditions
        const rate = this.setInitialRateOfJob(job);
        //Add travel allowance in shift timings
        let shiftAmount = this.addTravelAllowanceInShiftTimings(shiftTime, rate, job);
        // exclude Service fee
        shiftAmount = this.excludeServiceFee(Number(shiftAmount), job.serviceFeePercentageRate || 0);
        return Number(shiftAmount.toFixed(2));
    }
    deductBreakByMinutes(shiftTime, unpaidBreak) {
        //deduct break from shift time depending upon the selected break
        let unpaidBreakArray = {
            'No break': shiftTime,
            '15 minutes': shiftTime - 0.25,
            '30 minutes': shiftTime - 0.5,
            '45 minutes': shiftTime - 0.75,
            '1 hour': shiftTime - 1,
            '2 hour': shiftTime - 2
        };
        return unpaidBreakArray[unpaidBreak] || shiftTime;
    }
    setInitialRateOfJob(job) {
        let rate = job.stafferRate || job.rate;
        //Set rate to initial rate for recurring shifts
        if (job.recurringShift && job.recurringShift.isTrue == true) {
            rate = job.initialStafferRate ? job.initialStafferRate : (job.initialRate ? job.initialRate : (job.stafferRate ? job.stafferRate : job.rate));
        }
        return rate;
    }
    addTravelAllowanceInShiftTimings(shiftTime, rate, job) {
        //If travel allowance exists then add the travel time in shift timings
        if (job.travelTime) {
            return this.bigDeci(rate).multipliedBy(this.bigDeci(shiftTime).plus(this.bigDeci(job.travelTime)));
        }
        return this.bigDeci(rate).multipliedBy(this.bigDeci(shiftTime));
    }
    excludeServiceFee(shiftAmount, serviceFeeRate) {
        // exlude service fee from shift amount
        const serviceFee = this.bigDeci(shiftAmount)
            .multipliedBy(this.bigDeci(serviceFeeRate, 2));
        return this.bigDeci(shiftAmount).minus(this.bigDeci(Number(serviceFee), 2));
    }
    bigDeci(value, decimalPlaces) {
        const deciPlace = decimalPlaces ? decimalPlaces : 2;
        const val = new BigNumber(value.toString());
        const fixedVal = val.toFixed(deciPlace, BigNumber.ROUND_DOWN);
        return new BigNumber(fixedVal);
    }
    filterUserUpcomingJobs(userJobs, userId, today) {
        const allJobs = [];
        forEach(userJobs, (job) => {
            let basicConditions = moment.unix(job.shiftEndTime).isAfter(moment());
            const stafferObject = job.staffersAndShifts.filter((staffer) => {
                let cond = staffer.staffer === userId && (staffer.state === 'accepted' || staffer.state === 'offer');
                if (today) {
                    basicConditions = moment.unix(job.shiftEndTime).tz('America/Toronto').isAfter(moment()) && moment.unix(job.shiftEndTime).tz('America/Toronto').isBefore(moment().add(1, 'day'));
                    cond = staffer.staffer === userId && (staffer.state === ('offer') || staffer.state === ('accepted')) && staffer.isConfirmedShift == true;
                }
                return cond;
            });
            const isStateAccepted = stafferObject.length > 0;
            const isShiftStarted = stafferObject.length && stafferObject[0].isShiftStarted;
            if ((basicConditions && isStateAccepted) ||
                (!basicConditions && isStateAccepted && isShiftStarted)) {
                allJobs.push(job);
            }
        });
        return allJobs;
    }
    filterUserConfirmedJobs(userJobs, userId, today) {
        const allJobs = [];
        forEach(userJobs, (job) => {
            let basicConditions = moment.unix(job.shiftEndTime).isAfter(moment());
            const stafferObject = job.staffersAndShifts.filter((staffer) => {
                let cond = staffer.staffer === userId && staffer.state === 'accepted' && staffer.isConfirmedShift == true && staffer.covidAssessment == false;
                return cond;
            });
            const isStateAccepted = stafferObject.length > 0;
            const isShiftStarted = stafferObject.length && stafferObject[0].isShiftStarted;
            if ((basicConditions && isStateAccepted) ||
                (!basicConditions && isStateAccepted && isShiftStarted)) {
                allJobs.push(job);
            }
        });
        return allJobs;
    }
    totalTimeWorked(staffy) {
        let time = 0;
        if (!staffy.tracker) {
            return time;
        } // if no tracker then return
        if (staffy.tracker.end) {
            if (!staffy.tracker.breaks.length) {
                time += moment(staffy.tracker.end * 1000)
                    .diff(staffy.tracker.start * 1000, 'milliseconds');
            }
            else {
                if (staffy.tracker.breaks[staffy.tracker.breaks.length - 1].end) {
                    time += moment(staffy.tracker.end * 1000)
                        .diff(staffy.tracker.breaks[staffy.tracker.breaks.length - 1].end * 1000, 'milliseconds');
                }
                for (let i = staffy.tracker.breaks.length - 1; i >= 0; i--) {
                    if (i === 0) {
                        time += moment(staffy.tracker.breaks[i].start * 1000)
                            .diff(staffy.tracker.start * 1000, 'milliseconds');
                        continue;
                    }
                    time += moment(staffy.tracker.breaks[i].start * 1000)
                        .diff(staffy.tracker.breaks[i - 1].end * 1000, 'milliseconds');
                } // for loop
            } // else breaks.length is true
        } // if end true 
        else {
            if (!staffy.tracker.breaks.length) {
                time += moment().diff(staffy.tracker.start * 1000, 'milliseconds');
            }
            else {
                if (staffy.tracker.state === 'played') {
                    time += moment()
                        .diff(staffy.tracker.breaks[staffy.tracker.breaks.length - 1].end * 1000, 'milliseconds');
                }
                for (let i = staffy.tracker.breaks.length - 1; i >= 0; i--) {
                    if (i === 0) {
                        time += moment(staffy.tracker.breaks[i].start * 1000)
                            .diff(staffy.tracker.start * 1000, 'milliseconds');
                        continue;
                    }
                    time += moment(staffy.tracker.breaks[i].start * 1000)
                        .diff(staffy.tracker.breaks[i - 1].end * 1000, 'milliseconds');
                } // for loop
            }
        }
        return time;
    }
    saveShiftInLocalStorage(jobItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const upcomingJobs = yield this.localStorageService.getTalentUpcomingShifts();
            const i = upcomingJobs.findIndex(item => item._id === jobItem._id);
            if (i > -1) {
                upcomingJobs[i] = jobItem;
            }
            else {
                upcomingJobs.push(jobItem);
            }
            yield this.localStorageService.setTalentUpcomingShifts(upcomingJobs);
        });
    }
    hasStafferStartedAJob(stafferId, staffersAndShifts) {
        let hasStarted = false;
        if (!staffersAndShifts)
            return;
        for (const staffersAndShift of staffersAndShifts) {
            if (staffersAndShift.staffer === stafferId && staffersAndShift.isShiftStarted) {
                hasStarted = true;
                break;
            }
        }
        return hasStarted;
    }
    hasStafferStartedAJobAndInProgress(stafferId, staffersAndShifts) {
        let hasStarted = false;
        const stafferIndex = findIndex(staffersAndShifts, ['staffer', stafferId]);
        for (const staffersAndShift of staffersAndShifts) {
            if (staffersAndShift.staffer === stafferId && staffersAndShift.isShiftStarted && staffersAndShifts[stafferIndex].tracker && (staffersAndShifts[stafferIndex].tracker.state == 'played' || staffersAndShifts[stafferIndex].tracker.state == 'paused')) {
                hasStarted = true;
                break;
            }
        }
        return hasStarted;
    }
    checkForShiftsInProgress(stafferId, upShifts) {
        let startedShifts = [];
        if (upShifts.jobs) {
            forEach(upShifts.jobs, (jobs) => {
                if (jobs.staffersAndShifts && jobs.staffersAndShifts.length > 0)
                    startedShifts.push(this.hasStafferStartedAJobAndInProgress(stafferId, jobs.staffersAndShifts));
            });
        }
        else {
            forEach(upShifts, (jobs) => {
                if (jobs.staffersAndShifts && jobs.staffersAndShifts.length > 0)
                    startedShifts.push(this.hasStafferStartedAJobAndInProgress(stafferId, jobs.staffersAndShifts));
            });
        }
        const hasStafferStartedAJob = startedShifts.filter((status) => { return status == true; }).length > 0 ? true : false;
        return hasStafferStartedAJob;
    }
    isStafferConfirmedShift(job, userData) {
        if (!job.staffersAndShifts)
            return;
        if (job.staffersAndShifts.length && userData._id) {
            const stafferIndex = findIndex(job.staffersAndShifts, ['staffer', userData._id]);
            return stafferIndex > -1 ? job.staffersAndShifts[stafferIndex].isConfirmedShift : false;
        }
    }
    getFormattedSkill(skill) {
        if (skill === 'event bartender' || skill === 'catering bartender') {
            skill = 'Event Bartender';
        }
        else if (skill === 'bartender') {
            skill = 'Bartender';
        }
        else if (skill === 'busser / bar back') {
            skill = 'Bar Backs';
        }
        else if (skill === 'dishwasher') {
            skill = 'Dishwasher';
        }
        else if (skill === 'prep cook') {
            skill = 'Prep cook';
        }
        else if (skill === 'line cook') {
            skill = 'Line cook';
        }
        else if (skill === 'senior line cook') {
            skill = 'Senior Line cook';
        }
        else if (skill === 'sous chef') {
            skill = 'Sous Chef';
        }
        else if (skill === 'restaurant server') {
            skill = 'Restaurant Server';
        }
        else if (skill === 'event server' || skill === 'catering server') {
            skill = 'Event Server';
        }
        else if (skill === 'mixologist') {
            skill = 'Mixologist';
        }
        else if (skill === 'sommelier') {
            skill = 'Sommelier';
        }
        else if (skill === 'lead server / supervisor') {
            skill = 'Lead server/ Supervisor';
        }
        else if (skill === 'host') {
            skill = 'Host';
        }
        else if (skill === 'bartender') {
            skill = 'Bartender';
        }
        else if (skill === 'server assistant') {
            skill = 'Server Assistant';
        }
        else if (skill === 'room attendant') {
            skill = 'Room attendant';
        }
        else if (skill === 'event porter') {
            skill = 'Event porter';
        }
        else if (skill === 'cashier') {
            skill = 'Cashier';
        }
        else {
            skill = 'Bartender';
        }
        return skill;
    }
    startInterval(callback, ms) {
        this.timeInterval = setInterval(callback, ms);
    }
    stopInterval() {
        clearInterval(this.timeInterval);
    }
    removeDeleted(stafferCertificates) {
        stafferCertificates = stafferCertificates.filter(certificate => !certificate.markCertificateAsRejected.isDeleted);
        return stafferCertificates;
    }
    checkRejectedCertificates(stafferCertificates) {
        for (let certificateType in stafferCertificates) {
            stafferCertificates[certificateType]['rejectedFound'] = stafferCertificates[certificateType].filter((certificate) => {
                return (certificate.markCertificateAsRejected.isRejected == true && certificate.markCertificateAsRejected.isDeleted == false);
            }).length;
        }
        return stafferCertificates;
    }
    verifyCreator(entity) {
        if (entity && entity.creator)
            return true;
        return false;
    }
    updateDeclinedShiftStateInStorage(jobItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const declinedJobId = jobItem.recurringShift && jobItem.recurringShift.isTrue ? jobItem.recurringShift.groupJobId : jobItem._id;
            const upcomingShifts = yield this.localStorageService.getTalentUpcomingShifts();
            // Declined shift excluded from the upcoming shift array
            const filteredUpcomingShifts = upcomingShifts.filter(shift => {
                const shiftId = shift.recurringShift && shift.recurringShift.isTrue ? shift.recurringShift.groupJobId : shift._id;
                return shiftId != declinedJobId;
            });
            // reset the upcoming shift array in store & local storage
            this.store.dispatch(setTalentDashboardStoreAction({ upcomingShifts: filteredUpcomingShifts }));
        });
    }
    hasRequiredSubSkills(staffer, job) {
        // Check if job has no subSkills or if the job's skill is not in the applicable skills array
        if (!job.subSkills || !job.subSkills.length) {
            return true;
        }
        // Find missing skills
        const missingSkills = job.subSkills.filter(subSkill => !staffer.subSkills.includes(subSkill));
        // Return true if no missing skills
        return missingSkills.length === 0;
    }
    getMissingSubSkills(staffer, job) {
        // Check if job has no subSkills or if the job's skill is not in the applicable skills array
        if (!job.subSkills || !job.subSkills.length) {
            return [];
        }
        // Find missing skills
        const missingSkills = job.subSkills.filter(subSkill => !staffer.subSkills.includes(subSkill));
        // Return true if no missing skills
        return missingSkills;
    }
}
TalentDashboardUtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TalentDashboardUtilService_Factory() { return new TalentDashboardUtilService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.Store)); }, token: TalentDashboardUtilService, providedIn: "root" });
