<div class="notice-wrapper">
  <div class="notice-head">
    <a  class="close" (click)="closeModal()"><i class = "fa fa-times"></i></a>
  </div>
  <ng-container>
    <div class="notice-text">
      <h1>{{title}}</h1>
      <div class="para">{{description}}</div>
    </div>

    <app-recurrent-shift-list
    [config]="config" [data]="shifts"
    ></app-recurrent-shift-list>
  </ng-container>
</div>