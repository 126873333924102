/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-fee-update-notice.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./service-fee-update-notice.component";
import * as i5 from "@angular/router";
import * as i6 from "../../services/user.service";
import * as i7 from "../../services/loading.service";
import * as i8 from "../../services/popup.service";
import * as i9 from "@ngrx/store";
var styles_ServiceFeeUpdateNoticeComponent = [i0.styles];
var RenderType_ServiceFeeUpdateNoticeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServiceFeeUpdateNoticeComponent, data: {} });
export { RenderType_ServiceFeeUpdateNoticeComponent as RenderType_ServiceFeeUpdateNoticeComponent };
export function View_ServiceFeeUpdateNoticeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "notice-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "notice-head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "alret"], ["src", "assets/images/alert.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "notice-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "para"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "btn-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "ion-button", [["class", "ion-no-margin btn"], ["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptDoc() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(13, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" I Accept "]))], function (_ck, _v) { var currVal_2 = "basic"; _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notices.title; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.notices.shortNotice; _ck(_v, 10, 0, currVal_1); }); }
export function View_ServiceFeeUpdateNoticeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-service-fee-update-notice", [], null, null, null, View_ServiceFeeUpdateNoticeComponent_0, RenderType_ServiceFeeUpdateNoticeComponent)), i1.ɵdid(1, 114688, null, 0, i4.ServiceFeeUpdateNoticeComponent, [i3.ModalController, i5.Router, i6.UserService, i7.LoadingService, i8.PopupService, i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServiceFeeUpdateNoticeComponentNgFactory = i1.ɵccf("app-service-fee-update-notice", i4.ServiceFeeUpdateNoticeComponent, View_ServiceFeeUpdateNoticeComponent_Host_0, { staffer: "staffer" }, {}, []);
export { ServiceFeeUpdateNoticeComponentNgFactory as ServiceFeeUpdateNoticeComponentNgFactory };
