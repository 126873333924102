<div class="notice-wrapper">
  <div class="notice-head">
    <a *ngIf="data.allowClose" class="close" (click)="closeModal()"><i class = "fa fa-times"></i></a>
    <img src="assets/images/alert.png" alt="alret">
  </div>
  <ng-container *ngIf="!showFullNotice; else fullNotice">
    <div class="notice-text">
      <h1>{{ notices.title }}</h1>
      <div class="para">{{ notices.shortNotice }}</div>
    </div>
    <div class="btn-wrapper">
      <ion-button color="basic" class="ion-no-margin btn" (click)="readMore()">
        More Information 
      </ion-button>
      <ion-button color="basic" class="ion-no-margin btn" (click)="acceptDoc()">
        Review and Accept Document
      </ion-button>
    </div>
  </ng-container>
  <ng-template #fullNotice>
    <div class="notice-text">
      <h1>{{ notices.title }}</h1>
      <div class="para" [innerHtml]="notices.fullNotice"></div>
    </div>
    <div class="btn-wrapper">
      <ion-button color="basic" class="ion-no-margin btn" (click)="acceptDoc()">
        Review and Accept Document
      </ion-button>
      <ion-button *ngIf="data.allowClose" class="ion-no-margin btn close" (click)="closeModal()">
        Close
      </ion-button>
    </div>
  </ng-template>
</div>