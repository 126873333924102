<div class="ion-text-center child">
  <h1>{{ heading }}</h1>
  <div class = "timings">
    <div *ngIf = "jobItem">
      <div *ngFor="let job of jobItem.jobs" class="inner">
        <span>{{ job.momentedShiftStartTimeTz }}:</span>&nbsp;{{job.momentedShiftStartTime}} - {{job.momentedShiftEndTime}}
        <br/>
        <div *ngIf = "showUnitNumber"><span>Unit Number:</span>&nbsp;{{job.unitNumber || 'Not Assigned'}} <br/><br/></div>
      </div>
    </div>

    <div *ngIf = "!jobItem && unitNumber && showUnitNumber">
      <div *ngFor="let unit of unitNumber" class="inner">
        <div><span>Unit Number:</span>&nbsp;{{unit || 'Not Assigned'}} <br/><br/></div>
      </div>
    </div>
  </div>

  <ion-button color = "basic" id="success-popup-btn" class=" ion-no-margin btn"
    (click)='submit()'>
    Dismiss
  </ion-button>
</div>
