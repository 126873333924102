import * as i0 from "@angular/core";
export class SideMenuUtilService {
    constructor() { }
    getMenuForCompany(isMobileDevice) {
        return [
            {
                title: 'Home',
                icon: 'fa fa-home',
                path: isMobileDevice ? 'old-company-home' : 'company-home',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/home'
            },
            {
                title: 'Hire a staffy',
                icon: 'fa fa-bullhorn',
                path: 'hire-staffy',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/broadcast-request',
                subMenu: [
                    {
                        title: 'New Broadcast',
                        icon: 'fa fa-bullhorn',
                        path: 'company-broadcast-request',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/broadcast-request'
                    },
                    {
                        title: 'Shift-Loader Pro',
                        icon: 'fa fa-calendar',
                        path: 'multi-shift-builder',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/multi-shift-builder'
                    },
                    {
                        title: 'Direct Hire',
                        icon: 'fa fa-search',
                        path: 'company-direct-hire',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/direct-hire'
                    },
                    {
                        title: 'Recurring Shifts',
                        icon: 'fa fa-tasks',
                        path: 'company-recurrent-shifts',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/recurrent-shifts'
                    }
                ]
            },
            {
                title: 'Patients',
                icon: 'fas fa-tasks',
                path: 'patients',
                forApprovedUserOnly: false,
                forHomeCareClientOnly: true,
                navigateUrl: 'company-dashboard/patients'
            },
            {
                title: 'Notifications',
                icon: 'fa fa-bell',
                path: 'company-notifications',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/notifications'
            },
            {
                title: 'Requests',
                icon: 'fab fa-shirtsinbulk',
                path: 'company-requests',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/shift-requests/open/all',
                subMenu: [
                    {
                        title: 'Open Requests',
                        icon: 'fa fa-bullhorn',
                        path: 'company-open-request',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/shift-requests/open/all'
                    },
                    {
                        title: 'Filled Requests',
                        icon: 'fa fa-bell',
                        path: 'company-filled-request',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/shift-requests/filled/all'
                    },
                    {
                        title: 'Shifts Calender',
                        icon: 'fa fa-calendar',
                        path: 'company-filled-request-calendar',
                        forApprovedUserOnly: false,
                        mobHidden: true,
                        navigateUrl: 'company-dashboard/full-calender'
                    }
                ]
            },
            {
                title: 'Previous Staffies',
                icon: 'fa fa-address-book',
                path: 'company-previous-staffies',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/previous-staffies'
            },
            {
                title: 'Billing',
                icon: 'fas fa-money-bill',
                path: 'company-billing',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/payment-history',
                subMenu: [
                    {
                        title: 'Payment History',
                        icon: 'fa fa-history',
                        path: 'company-payment-history',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/payment-history'
                    },
                    {
                        title: 'Invoices',
                        icon: 'fa fa-search',
                        path: 'company-invoices',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/invoices'
                    },
                    {
                        title: 'Credit Notes',
                        icon: 'fa fa-sticky-note',
                        path: 'company-credit-notes',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/credit-notes'
                    },
                    {
                        title: 'Credit Cards',
                        icon: 'fa fa-credit-card',
                        path: 'company-credit-cards',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/credit-cards'
                    },
                    {
                        title: 'Account Statement',
                        icon: 'fa fa-download',
                        path: 'company-account-statement',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/account-statement'
                    }
                ]
            },
            {
                title: 'Wallet',
                icon: 'fab fa-google-wallet',
                path: 'company-wallet',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/wallet'
            },
            {
                title: 'Teams',
                icon: 'fa fa-users',
                path: 'company-teams',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/teams'
            },
            {
                title: 'Settings',
                icon: 'fa fa-wrench',
                path: 'settings',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/business-profile',
                subMenu: [
                    {
                        title: 'Business Profile',
                        icon: 'fa fa-suitcase',
                        path: 'company-profile',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/business-profile',
                    },
                    {
                        title: 'Block Staffies',
                        icon: 'fa fa-ban',
                        path: 'company-block-staffies',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/block-staffies',
                    },
                    {
                        title: 'Addresses',
                        icon: 'fa fa-address-book',
                        path: 'company-other-addresses',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/other-addresses'
                    },
                    {
                        title: 'Notifications',
                        icon: 'fa fa-bell',
                        path: 'company-notification-preferences',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/notification-preferences'
                    },
                    {
                        title: 'Auto Hire',
                        icon: 'fa fa-street-view',
                        path: 'company-auto-hire',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/auto-hire'
                    },
                    {
                        title: 'Credential Requirement',
                        icon: 'fa fa-file',
                        path: 'company-certificate-requirement',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/credential-requirement'
                    },
                    {
                        title: 'Tasks',
                        icon: 'fas fa-tasks',
                        path: 'company-tasks',
                        forApprovedUserOnly: false,
                        navigateUrl: 'company-dashboard/tasks',
                        forHomeCareClientOnly: true
                    }
                ]
            },
            {
                title: 'Sync',
                icon: 'fas fa-sync-alt',
                path: 'company-sync',
                forApprovedUserOnly: false
            },
            {
                title: 'FAQ',
                icon: 'fa fa-question',
                path: 'company-faq',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/faqs'
            },
            {
                title: 'Contact Us',
                icon: 'fa fa-envelope',
                path: 'company-contact-us',
                forApprovedUserOnly: false,
                navigateUrl: 'company-dashboard/contact-us'
            },
            {
                title: 'Privacy Policy',
                icon: 'fa fa-print',
                path: 'privacy-agreement',
                forApprovedUserOnly: false,
                navigateUrl: 'shared/privacy-policy'
            },
            {
                title: 'Logout',
                icon: 'fa fa-sign-out-alt',
                path: 'logout',
                forApprovedUserOnly: false
            }
        ];
    }
    getMenuForStaffy() {
        return [
            {
                title: 'Home',
                icon: 'fa fa-home',
                path: 'talent-home',
                forApprovedUserOnly: false
            },
            {
                title: 'Edit profile',
                icon: 'fa fa-user',
                path: 'talent-set-profile',
                forApprovedUserOnly: false,
            },
            {
                title: 'Shifts',
                icon: 'fa fa-clone',
                path: 'shifts',
                expand: true,
                forApprovedUserOnly: true,
                subMenu: [
                    {
                        title: 'Available shifts',
                        icon: 'fa fa-briefcase',
                        path: 'talent-home',
                        forApprovedUserOnly: true
                    },
                    {
                        title: 'Upcoming shifts',
                        icon: 'fa fa-arrow-up',
                        path: 'upcoming-shifts',
                        forApprovedUserOnly: true
                    }, {
                        title: 'Confirm your shifts',
                        icon: 'fa fa-check-square',
                        path: 'shifts-confirmation',
                        forApprovedUserOnly: true
                    },
                    {
                        title: 'My Applications',
                        icon: 'fa fa-envelope-open',
                        path: 'my-applications',
                        forApprovedUserOnly: true
                    },
                ]
            },
            {
                title: 'Billing',
                icon: 'fas fa-wallet',
                path: 'talent-billing',
                expand: true,
                forApprovedUserOnly: true,
                subMenu: [
                    {
                        title: 'Add Manual Time',
                        path: 'talent-log-hours'
                    },
                    {
                        title: 'Invoices',
                        path: 'talent-invoices'
                    },
                    {
                        title: 'Reports',
                        path: 'talent-reports'
                    },
                    {
                        title: 'Setup Payments',
                        path: 'setup-payment-method',
                        forApprovedUserOnly: true
                    },
                    {
                        title: 'StaffyPay',
                        path: 'talent-staffy-pay'
                    },
                    {
                        title: 'Tax',
                        path: 'talent-contractor-tax'
                    },
                    {
                        title: 'Unverified Hours',
                        path: 'talent-unverified-hours'
                    },
                    {
                        title: 'Wallet',
                        path: 'talent-wallet',
                        forApprovedUserOnly: true
                    }
                ]
            },
            {
                title: 'Set Availability',
                icon: 'fa fa-clock',
                path: 'set-availability',
                forApprovedUserOnly: true
            },
            {
                title: 'Peer Ratings',
                icon: 'fas fa-star',
                path: 'peer-ratings',
                forApprovedUserOnly: true
            },
            {
                title: 'Blocked Clients',
                icon: 'fa fa-ban',
                path: 'talent-ignore-client',
                forApprovedUserOnly: true
            },
            {
                title: 'Referrals',
                icon: 'fa fa-users',
                path: 'talent-add-referrals',
                forApprovedUserOnly: true
            },
            {
                title: 'FAQ',
                icon: 'fa fa-question',
                path: 'talent-faq',
                forApprovedUserOnly: false
            },
            {
                title: 'Help',
                icon: 'fas fa-hands-helping',
                path: 'talent-help',
                expand: true,
                forApprovedUserOnly: false,
                subMenu: [
                    {
                        title: 'Sync',
                        icon: 'fas fa-sync-alt',
                        path: 'talent-sync',
                        forApprovedUserOnly: false
                    },
                    {
                        title: 'Help',
                        icon: 'fa fa-question',
                        path: 'talent-help-page',
                        forApprovedUserOnly: false
                    }
                ]
            },
            {
                title: 'Settings',
                icon: 'fa fa-cog',
                path: 'settings',
                expand: true,
                forApprovedUserOnly: false,
                subMenu: [
                    {
                        title: 'Apparel/Equipment',
                        icon: 'fa fa-tshirt',
                        path: 'talent-upload-equipment',
                        forApprovedUserOnly: false,
                    },
                    {
                        title: 'Join team',
                        icon: 'fa fa-check-square',
                        path: 'talent-add-as-team',
                        forApprovedUserOnly: true,
                    },
                    {
                        title: 'Notifications',
                        icon: 'fa fa-bell',
                        path: 'talent-notification-preferences',
                        forApprovedUserOnly: false
                    },
                    {
                        title: 'Public Profile',
                        icon: 'fas fa-id-card-alt',
                        path: 'talent-public-profile',
                        forApprovedUserOnly: true
                    },
                    {
                        title: 'Set Rates',
                        icon: 'fas fa-hand-holding-usd',
                        path: 'talent-set-skills-rate',
                        forApprovedUserOnly: true
                    },
                ]
            },
            {
                title: 'Privacy Policy',
                icon: 'fa fa-print',
                path: 'privacy-policy',
                forApprovedUserOnly: false
            },
            {
                title: 'Logout',
                icon: 'fa fa-sign-out-alt',
                path: 'logout',
                forApprovedUserOnly: false
            }
        ];
    }
}
SideMenuUtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SideMenuUtilService_Factory() { return new SideMenuUtilService(); }, token: SideMenuUtilService, providedIn: "root" });
