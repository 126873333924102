/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contractor-shift-modified.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../recurrent-shift-list/recurrent-shift-list.component.ngfactory";
import * as i3 from "../recurrent-shift-list/recurrent-shift-list.component";
import * as i4 from "./contractor-shift-modified.component";
import * as i5 from "@ionic/angular";
import * as i6 from "../../services/job.service";
import * as i7 from "../../services/loading.service";
var styles_ContractorShiftModifiedComponent = [i0.styles];
var RenderType_ContractorShiftModifiedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContractorShiftModifiedComponent, data: {} });
export { RenderType_ContractorShiftModifiedComponent as RenderType_ContractorShiftModifiedComponent };
export function View_ContractorShiftModifiedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "notice-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "notice-head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "notice-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "para"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-recurrent-shift-list", [], null, null, null, i2.View_RecurrentShiftListComponent_0, i2.RenderType_RecurrentShiftListComponent)), i1.ɵdid(11, 114688, null, 0, i3.RecurrentShiftListComponent, [], { data: [0, "data"], config: [1, "config"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.shifts; var currVal_3 = _co.config; _ck(_v, 11, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.description; _ck(_v, 9, 0, currVal_1); }); }
export function View_ContractorShiftModifiedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contractor-shift-modified", [], null, null, null, View_ContractorShiftModifiedComponent_0, RenderType_ContractorShiftModifiedComponent)), i1.ɵdid(1, 114688, null, 0, i4.ContractorShiftModifiedComponent, [i5.ModalController, i6.JobService, i7.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractorShiftModifiedComponentNgFactory = i1.ɵccf("app-contractor-shift-modified", i4.ContractorShiftModifiedComponent, View_ContractorShiftModifiedComponent_Host_0, { groupJobId: "groupJobId", shiftExtended: "shiftExtended" }, {}, []);
export { ContractorShiftModifiedComponentNgFactory as ContractorShiftModifiedComponentNgFactory };
