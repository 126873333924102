import { Camera } from '@ionic-native/camera/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/camera/ngx/index";
import * as i2 from "@ionic-native/file-transfer/ngx/index";
export class ImagesService {
    constructor(camera, transfer) {
        this.camera = camera;
        this.transfer = transfer;
    }
    choosePicture(srcType
    // 'CAMERA' | 'GALLERY'
    ) {
        const options = {
            quality: 75,
            destinationType: this.camera.DestinationType.FILE_URI,
            sourceType: srcType === 'CAMERA' ? this.camera.PictureSourceType.CAMERA : this.camera.PictureSourceType.PHOTOLIBRARY,
            encodingType: this.camera.EncodingType.JPEG,
            targetWidth: 720,
            saveToPhotoAlbum: false,
            correctOrientation: true
        };
        return from(this.camera.getPicture(options));
    }
    uploadPicture(imageData, token) {
        const fileTransfer = this.transfer.create();
        const uploadOptions = {
            fileKey: 'images',
            fileName: 'avatar.jpeg',
            chunkedMode: true,
            mimeType: 'image/jpeg',
            headers: {
                Authorization: 'Bearer ' + token
            }
        };
        const server = environment.API_BASE_URL + '/api/users/avatar';
        const filePath = imageData;
        return from(fileTransfer.upload(filePath, server, uploadOptions));
    }
    getProfilePic(profileUrl, dimensions) {
        if (profileUrl && !profileUrl.error) {
            // resize it and return the url
            let imageUrl = profileUrl.url.slice(0, 46);
            const imageName = profileUrl.url.slice(45);
            const imageSize = dimensions || 'w_300,h_300';
            imageUrl = imageUrl + imageSize + imageName;
            return imageUrl;
        }
        return ('assets/images/staffy-gray.png');
    }
}
ImagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImagesService_Factory() { return new ImagesService(i0.ɵɵinject(i1.Camera), i0.ɵɵinject(i2.FileTransfer)); }, token: ImagesService, providedIn: "root" });
