import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-date-picker',
  templateUrl: './form-date-picker.component.html',
  styleUrls: ['./form-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDatePickerComponent),
      multi: true
    }]
})
export class FormDatePickerComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder;
  @Input() dateConfig;
  @Input() control: FormControl;
  @Input() showEditIcon = false
  @Output() onChange = new EventEmitter();
  _shiftDate: string;
  propagateChange = (_: any) => {};
  propagateTouched = (_: any) => {};

  constructor() { }

  ngOnInit() {
  }
  
  get shiftDate(): string {
    return this._shiftDate;
  }

  set shiftDate(value: string) {
      this._shiftDate = value;
      this.onChange.emit(this._shiftDate);
      this.propagateChange(this._shiftDate);
  }

  //Form Accessor Functions
  writeValue(value: any): void {
    this._shiftDate = value;
  }

  registerOnChange(fn: (value: any) => void): void {
      this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
      this.propagateTouched = fn;
  }

  openDatePicker(datePickerElement: any){
    if(datePickerElement && datePickerElement.api && datePickerElement.api.open){
      datePickerElement.api.open()
     }
  }


}
