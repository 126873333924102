import { Injectable } from '@angular/core';
import { ILogin } from '../interfaces/login.interface';
import { HttpService } from 'src/modules/shared/services/http.service';
import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import { IForgotPassword } from '../interfaces/forget-password.interface';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(private http: HttpService, private httpPromiseService: HttpPromiseService) {}

  public loginViaEmail(data: ILogin) {
    return this.http.httpPostRequest<{ token: string }>('/auth/local', data);
  }

  public forgotPassword(data: IForgotPassword) {
    return this.httpPromiseService.httpPostRequest('/api/appInfo/forgotPassword', data);
  }

  public findUserByResetPasswordToken(token: string) {
    return this.httpPromiseService.httpGetRequest(`/api/appInfo/findUserByResetPasswordToken/${token}`);
  }

  public resetPassword(body: { confirmPassword: string; password: string; token: string }) {
    return this.httpPromiseService.httpPutRequest(`/api/appInfo/resetPassword`, body);
  }
}
