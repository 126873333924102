/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./country-phone-numbers.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./country-phone-numbers.component";
var styles_CountryPhoneNumbersComponent = [i0.styles];
var RenderType_CountryPhoneNumbersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountryPhoneNumbersComponent, data: {} });
export { RenderType_CountryPhoneNumbersComponent as RenderType_CountryPhoneNumbersComponent };
export function View_CountryPhoneNumbersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "ion-list", [], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(1, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 7, "ion-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selected("ca") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(3, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-thumbnail", [["slot", "start"]], null, null, null, i2.View_IonThumbnail_0, i2.RenderType_IonThumbnail)), i1.ɵdid(5, 49152, null, 0, i3.IonThumbnail, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "img", [["src", "assets/images/ca-flag.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(8, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Canda +1"])), (_l()(), i1.ɵeld(10, 0, null, 0, 7, "ion-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selected("usa") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(11, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 2, "ion-thumbnail", [["slot", "start"]], null, null, null, i2.View_IonThumbnail_0, i2.RenderType_IonThumbnail)), i1.ɵdid(13, 49152, null, 0, i3.IonThumbnail, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(14, 0, null, 0, 0, "img", [["src", "assets/images/usa-flag.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(16, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["USA +1"]))], null, null); }
export function View_CountryPhoneNumbersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-country-phone-numbers", [], null, null, null, View_CountryPhoneNumbersComponent_0, RenderType_CountryPhoneNumbersComponent)), i1.ɵdid(1, 114688, null, 0, i4.CountryPhoneNumbersComponent, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountryPhoneNumbersComponentNgFactory = i1.ɵccf("app-country-phone-numbers", i4.CountryPhoneNumbersComponent, View_CountryPhoneNumbersComponent_Host_0, {}, {}, []);
export { CountryPhoneNumbersComponentNgFactory as CountryPhoneNumbersComponentNgFactory };
