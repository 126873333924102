import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {  RouteReuseStrategy } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import {  Store } from '@ngrx/store';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharedModule } from '../modules/shared/shared.module';
import { AppStoreModule } from './app-store/app-store.module';
import { InterceptorsModule } from './intercepters/interceptors.module';
import { GoogleMaps } from '@ionic-native/google-maps';
import { Camera } from '@ionic-native/camera/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { DpDatePickerModule } from 'ng2-date-picker';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { Device } from '@ionic-native/device/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Market } from '@ionic-native/market/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { AppUpdateProgressComponent } from './componets/app-update-progress/app-update-progress.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import * as Sentry from '@sentry/browser';
import { SentryIonicErrorHandler } from './error-handler';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { completedIntroductoryStepsAction, setStoreAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { AuthStoreModule } from 'src/modules/authentication/+store/auth-store.module';
import { UserService } from 'src/modules/shared/services/user.service';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { LocalStorageKeys } from 'src/modules/shared/enums/local-storage-keys.enum';
import { setTalentDashboardStoreAction } from 'src/modules/talent-dashboard/+store/actions/talent-dashboard.actions';
import { TalentDashboardStoreModule } from 'src/modules/talent-dashboard/+store/talent-dashboard-store.module';
import { Calendar } from '@ionic-native/calendar/ngx';
import { LayoutModule } from 'src/layouts/layout.module';
import { ConfigService } from './services/config.service';


import { IntercomModule } from 'ng-intercom';

// Sentry.init({
//   dsn: 'https://e1949ba4e75449258df8284993f1173c@o877632.ingest.sentry.io/5828475',
//   release: environment.SENTRY_RELEASE
// });

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function initializeApp(
  localStorageService: LocalStorageService,
  store: Store<IAuthState>,
  userService: UserService,
  configService: ConfigService
  ) : () => Promise<void> {

  return async () => {

    return new Promise(async (resolve, reject) => {
      // const upcomingShifts = await localStorageService.getTalentUpcomingShifts();
      // 1. Check localstroage 
      const token = await localStorageService.getLoggedInUserToken();
      // If the user is not found
      if (!token) {
        // redirect to login, default path of the app is /auth, so we don't need to do anything here
        return resolve();
      }
      // If the user is found
      store.dispatch(setStoreAction({ token, user: null }));
      // check the demo mode
      const isDemo = configService.isDemoMode();
      if(isDemo){
        // restore it
        configService.setApiUrlWithStripeKey('https://demo.staffy.com', 'https://demo.staffy.com', "pk_test_DzFi3DiORqdtFixQpeLMdawi");
      }
      // refersh the user
      const user = await userService.me().toPromise().catch( e=> {
        console.log('unable to referesh user');
        return localStorageService.getLoggedInUserInfo();
      }) as IUser;
      // update the store 
      store.dispatch(setStoreAction({ token, user }));

      const upcomingShifts = await localStorageService.getTalentUpcomingShifts();
      store.dispatch(setTalentDashboardStoreAction({ upcomingShifts }));
      
      // check if the user has completed introductory steps
      const hasCompletedIntroSteps = await localStorageService.getSingleKey(LocalStorageKeys.HAS_COMPLETED_INTRODUCTORY_STEPS); 
      if(hasCompletedIntroSteps){
        store.dispatch(completedIntroductoryStepsAction());
      }
      // utilService.setTimeZone(user.address.province);
      return resolve();
    });


  }
}

@NgModule({
  declarations: [AppComponent, AppUpdateProgressComponent],
  entryComponents: [AppUpdateProgressComponent],
  imports: [
    InterceptorsModule.forRoot(),
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    DpDatePickerModule,
    SharedModule.forRoot(),
    AppStoreModule,
    NgxDocViewerModule,
    NgCircleProgressModule.forRoot({
      "backgroundPadding": -2,
      "radius": 78,
      "space": -10,
      "toFixed": 0,
      "outerStrokeGradient": false,
      "outerStrokeColor": "#ff4d00",
      "animationDuration": 1000,
      "clockwise": true,
      "startFromZero": false,
      "lazy": false
    }),
    // FullCalendarModule, // register FullCalendar with you app
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AuthStoreModule,
    TalentDashboardStoreModule,
    LayoutModule,
    IntercomModule.forRoot({
      appId: environment.INTERCOM_KEY, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [LocalStorageService, Store, UserService, ConfigService],
      multi: true
    },
    StatusBar,
    GoogleMaps,
    SplashScreen,
    Camera,
    AppRate,
    Market,
    Keyboard,
    FileOpener,
    InAppBrowser,
    File,
    Network,
    AppVersion,
    Device,
    Contacts,
    FileTransfer,
    Geolocation,
    Diagnostic,
    FirebaseAnalytics,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Deploy,
    { provide: ErrorHandler, useClass: SentryIonicErrorHandler },
    Calendar,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
