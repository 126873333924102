import { PopupService } from './popup.service';
import * as i0 from "@angular/core";
import * as i1 from "./popup.service";
export class ApplyErrorHandler {
    constructor(popupService) {
        this.popupService = popupService;
    }
    handleError(error) {
        this.popupService.showModal({
            heading: 'Sorry',
            message: error.error && typeof error.error === 'string'
                ? error.error
                : error.data && error.data.errMsg
                    ? error.data.errMsg
                    : typeof error.data === 'string'
                        ? error.data
                        : 'We encountered an error when processing your request. Please try again later.',
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
        });
    }
}
ApplyErrorHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplyErrorHandler_Factory() { return new ApplyErrorHandler(i0.ɵɵinject(i1.PopupService)); }, token: ApplyErrorHandler, providedIn: "root" });
