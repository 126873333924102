<div class="ion-justify-content-center ion-text-center child">
  <h1 class="covid-title">
    COVID-19 Assessment
  </h1>

  <ng-container *ngFor="let item of quiz; let i = index">
    <div *ngIf="item.show" class="item">

      <h3 *ngIf="item.title"> {{item.title}}</h3>


      <p>{{item.question}}</p>
      <div *ngIf="item.key" class="opt">
        <span>
          <input name="options" type="radio" [value]="true" [(ngModel)]="item.val">Yes

        </span>
        <span>
          <input name="options" type="radio" [value]="false" [(ngModel)]="item.val">No

        </span>


      </div>

    </div>
    <div *ngIf="item.show" class="button-stack">

      <button *ngIf="!item.isLast" [disabled] = "i!= 0 && (item.val == undefined)" (click)="next(i)" class="btn blue">
        Next
      </button>
      <button *ngIf="item.isLast" (click)="finish()" class="btn black">
        Finish
      </button>
    </div>
  </ng-container>
</div>