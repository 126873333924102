import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as talentDashboardActions from '../actions/talent-dashboard.actions';
import { tap, exhaustMap, map, catchError, } from 'rxjs/operators';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { TalentDashboardService } from 'src/modules/talent-dashboard/services/talent-dashboard.service';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../../../shared/services/local-storage.service";
import * as i3 from "../../../shared/services/loading.service";
import * as i4 from "../../services/talent-dashboard.service";
import * as i5 from "../../../shared/services/popup.service";
export class TalentDashboardEffects {
    constructor(actions$, localStorageService, loadingService, talentDashboardService, popupService) {
        this.actions$ = actions$;
        this.localStorageService = localStorageService;
        this.loadingService = loadingService;
        this.talentDashboardService = talentDashboardService;
        this.popupService = popupService;
        /////////////////////////////////////////////////
        this.setTalentDashboardStoreAction$ = createEffect(() => this.actions$.pipe(ofType(talentDashboardActions.setTalentDashboardStoreAction), tap((action) => this.localStorageService.setTalentUpcomingShifts(action.upcomingShifts))), { dispatch: false });
        this.setFutureShiftsCountStoreAction$ = createEffect(() => this.actions$.pipe(ofType(talentDashboardActions.setFutureShiftsCountStoreAction), tap((action) => this.localStorageService.setSingleKey('futureUpcomingShiftsCount', action.futureUpcomingShiftsCount))), { dispatch: false });
        this.completeTask$ = createEffect(() => this.actions$.pipe(ofType(talentDashboardActions.completeTaskAction), exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))), exhaustMap(({ loader, action }) => this.talentDashboardService.completeTaskRequest(action.jobId, action.taskId, action.patientId)
            .pipe(tap(() => this.loadingService.hideLoading(loader)), map(data => talentDashboardActions.completeTaskSuccessAction(action)), catchError(error => {
            this.loadingService.hideLoading(loader);
            return of(talentDashboardActions.completeTaskFailureAction({ error }));
        })))));
        this.completeTaskSuccess$ = createEffect(() => this.actions$.pipe(ofType(talentDashboardActions.completeTaskSuccessAction), tap(_ => {
            const data = {
                heading: 'Success',
                message: 'Successfully marked task as completed',
                rejectBtnText: '',
                btn: 'Okay',
                navigateRoute: '',
                imgURL: 'assets/images/thumbs-up.png'
            };
            this.popupService.showModal(data);
        })), { dispatch: false });
        this.completeTaskFailure$ = createEffect(() => this.actions$.pipe(ofType(talentDashboardActions.completeTaskFailureAction), tap((action) => this.popupService.showPopup('Error', 'Something went wrong, try again'))), { dispatch: false });
        this.saveCareNotes$ = createEffect(() => this.actions$.pipe(ofType(talentDashboardActions.saveCareNotesAction), exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))), exhaustMap(({ loader, action }) => this.talentDashboardService.saveCareNotesRequest(action.jobId, action.careNotes, action.patientId)
            .pipe(tap(() => this.loadingService.hideLoading(loader)), map(data => talentDashboardActions.saveCareNotesSuccessAction(action)), catchError(error => {
            this.loadingService.hideLoading(loader);
            return of(talentDashboardActions.saveCareNotesFailureAction({ error }));
        })))));
        this.saveCareNotesSuccess$ = createEffect(() => this.actions$.pipe(ofType(talentDashboardActions.saveCareNotesSuccessAction), tap(_ => {
            const data = {
                heading: 'Success',
                message: 'Care notes saved successfully',
                rejectBtnText: '',
                btn: 'Okay',
                navigateRoute: '',
                imgURL: 'assets/images/thumbs-up.png'
            };
            this.popupService.showModal(data);
        })), { dispatch: false });
        this.saveCareNotesFailure$ = createEffect(() => this.actions$.pipe(ofType(talentDashboardActions.completeTaskFailureAction), tap((action) => this.popupService.showPopup('Error', 'Something went wrong, try again'))), { dispatch: false });
    }
}
TalentDashboardEffects.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TalentDashboardEffects_Factory() { return new TalentDashboardEffects(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.LoadingService), i0.ɵɵinject(i4.TalentDashboardService), i0.ɵɵinject(i5.PopupService)); }, token: TalentDashboardEffects, providedIn: "root" });
