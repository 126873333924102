  <ng-container class = "inner-modal-wrapper" *ngFor = "let certificate of approvedCertificates; let i = index">
    <div class = "certificate-body" *ngIf = "approvedCertificates.length > 0 && currentIndex == i" style = "width: 100%; height: 100%">
      <div class = "certificate-inner">    
        <p class="certificate-heading">
          {{certificate.type}}
        </p>
       
        <div class = "image-and-arrow">
          <div class = "chev-div">
            <i class = "fa fa-caret-left" *ngIf = "approvedCertificates.length > 1 && currentIndex > 0" (click) = "showPreviousCertificate()"></i>  
          </div>
          
          <div class = "certificate-remove">
            
            <div class = "box" *ngIf = "  certificate.isCertificateImage">
              <img class="img-responsive" style="height:100%; object-fit: contain; width: 100%"  [src]="certificate.url">
            </div>

            <div *ngIf = "certificate.isCertificatePDF" class = "full-width">
              <div class = "box">
                <div *ngIf="showLoading" class = "loader-div">
                  <img class="loading-img" src="assets/images/loading.gif" alt="logo">
               </div>

              <pdf-viewer class = "pdf-viewer" [ngClass] = "{'blurCertificate' : showLoading}" [src]="certificate.sanitizeURL.changingThisBreaksApplicationSecurity" [show-all] = "true"
                [autoresize]="false" [original-size]="false" [stick-to-page]="true" [show-borders]="false" 
                (text-layer-rendered)="textLayerRendered($event)" (error)="onError($event)" (on-progress)="onProgress($event)"></pdf-viewer>
              </div>  
            </div>

            <div *ngIf = "certificate.isCertificateDOC" class = "full-width">
              <div class = "box">
                <p class = "document-txt">Document File encounterd. Please <a (click)= "openLink(certificate.url)">Click here</a> to view credential.</p>
              </div>
            </div>

            <!-- <ngx-doc-viewer *ngIf = "certificate.isCertificateDOC" class="certificate-image" [url]="certificate.sanitizeURL.changingThisBreaksApplicationSecurity" viewer="google"></ngx-doc-viewer>
             -->
            <div *ngIf = "certificate.isCertificatePDF" class = "text-center">
              <a class = "view-certificate-link" (click)= "openLink(certificate.url)">View Credential File</a>
            </div>
          </div>

          <div class = "chev-div">
          <i class = "fa fa-caret-right" *ngIf = "approvedCertificates.length > 1 && currentIndex < approvedCertificates.length - 1" (click) = "showNextCertificate()"></i></div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container class = "inner-modal-wrapper no-certificate-found-container" *ngIf = "approvedCertificates.length == 0">
    <div class = "outer">
      <div class = "icon-text">
        <i class="fa fa-ban" aria-hidden="true"></i>  
        <p class = "no-certificate-found">No Credential Found</p>
      </div>
    </div>
  </ng-container>
  <i class = "certificate-dismiss fa fa-times " (click) = "terminateCertificatePopup()"></i>