import { Component, OnInit, Input } from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'shared-signals-indicators',
  templateUrl: './signals-indicators.component.html',
  styleUrls: ['./signals-indicators.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignalsIndicatorsComponent implements OnInit {
  @Input() userData: IUser;
  @Input() isDirectHire?= false;
  @Input() showTotalShifts? : boolean = false;

  constructor() { }

  ngOnInit() { }

}
