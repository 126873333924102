import { createAction, props } from '@ngrx/store';
import { ITalentJob } from '../../interfaces/talent-job.interface';

//////////////////////////////////////////////

export const setTalentDashboardStoreAction = createAction(
    '[Talent Dashboard]  Set Store Action',
    props<{ upcomingShifts: ITalentJob[] }>()
);

export const setFutureShiftsCountStoreAction = createAction(
    '[Talent Dashboard]  Set FutureShiftsCount Action',
    props<{ futureUpcomingShiftsCount: number }>()
);


export const resetTalentDashboardStoreAction = createAction(
    '[Talent Dashboard]  Reset Store Action '
);

export const getTalentUpcomingShiftsAction = createAction(
    '[Talent Dashboard] Talent Upcoming Shifts Action '
);

export const setTalentDashboardAvailableShiftStoreAction = createAction(
    '[Talent Dashboard] Set Available Shift Store Action',
    props<{ availableShift: any }>()
);

export const getTalentDashboardAvailableShiftStoreAction = createAction(
    '[Talent Dashboard] Talent Available Shifts Action '
);


export const completeTaskAction = createAction(
    '[Talent Dashboard]  complete task',
    props<{ jobId: string, taskId: string, patientId : string }>()
);

export const completeTaskSuccessAction = createAction(
    '[Talent Dashboard]  complete task success',
    props<{ jobId: string, taskId: string,patientId : string  }>()
);

export const completeTaskFailureAction = createAction(
    '[Talent Dashboard  complete task failed',
    props<{ error: any }>()
);

export const saveCareNotesAction = createAction(
    '[Talent Dashboard]  save care notes',
    props<{ jobId: string, careNotes: string,patientId : string }>()
);

export const saveCareNotesSuccessAction = createAction(
    '[Talent Dashboard]  save care notes success',
    props<{ jobId: string, careNotes: string,patientId : string }>()
);

export const saveCareNotesFailureAction = createAction(
    '[Talent Dashboard  save care notes failed',
    props<{ error: any }>()
);

export const rejectNonRecurringSuccessAction = createAction(
    '[Talent Dashboard  reject direct hire action',
    props<{ jobId: string, stafferId: string }>()
);

export const rejectRecurringSuccessAction = createAction(
    '[Talent Dashboard  reject direct hire action',
    props<{ groupJobId: string, stafferId: string }>()
);