/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recurring-shifts-timing.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./recurring-shifts-timing.component";
var styles_RecurringShiftsTimingComponent = [i0.styles];
var RenderType_RecurringShiftsTimingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecurringShiftsTimingComponent, data: {} });
export { RenderType_RecurringShiftsTimingComponent as RenderType_RecurringShiftsTimingComponent };
function View_RecurringShiftsTimingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unit Number:"])), (_l()(), i1.ɵted(3, null, ["\u00A0", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.unitNumber || "Not Assigned"); _ck(_v, 3, 0, currVal_0); }); }
function View_RecurringShiftsTimingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), (_l()(), i1.ɵted(3, null, ["\u00A0", " - ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecurringShiftsTimingComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.showUnitNumber; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.momentedShiftStartTimeTz; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.momentedShiftStartTime; var currVal_2 = _v.context.$implicit.momentedShiftEndTime; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_RecurringShiftsTimingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecurringShiftsTimingComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jobItem.jobs; _ck(_v, 2, 0, currVal_0); }, null); }
function View_RecurringShiftsTimingComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unit Number:"])), (_l()(), i1.ɵted(4, null, ["\u00A0", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit || "Not Assigned"); _ck(_v, 4, 0, currVal_0); }); }
function View_RecurringShiftsTimingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecurringShiftsTimingComponent_5)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unitNumber; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RecurringShiftsTimingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "ion-text-center child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "timings"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecurringShiftsTimingComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecurringShiftsTimingComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "ion-button", [["class", " ion-no-margin btn"], ["color", "basic"], ["id", "success-popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(9, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Dismiss "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.jobItem; _ck(_v, 5, 0, currVal_1); var currVal_2 = ((!_co.jobItem && _co.unitNumber) && _co.showUnitNumber); _ck(_v, 7, 0, currVal_2); var currVal_3 = "basic"; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; _ck(_v, 2, 0, currVal_0); }); }
export function View_RecurringShiftsTimingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-recurring-shifts-timing-modal", [], null, null, null, View_RecurringShiftsTimingComponent_0, RenderType_RecurringShiftsTimingComponent)), i1.ɵdid(1, 114688, null, 0, i5.RecurringShiftsTimingComponent, [i4.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecurringShiftsTimingComponentNgFactory = i1.ɵccf("shared-recurring-shifts-timing-modal", i5.RecurringShiftsTimingComponent, View_RecurringShiftsTimingComponent_Host_0, { jobItem: "jobItem", heading: "heading", showUnitNumber: "showUnitNumber", unitNumber: "unitNumber" }, {}, []);
export { RecurringShiftsTimingComponentNgFactory as RecurringShiftsTimingComponentNgFactory };
