import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-covid-quiz-modal',
  templateUrl: './covid-quiz-modal.component.html',
  styleUrls: ['./covid-quiz-modal.component.scss'],
})
export class CovidQuizModalComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  quiz = [{
    question: `Staffy is concerned for the safety of our contractors and vendors.
    In the interest of ensuring a safe and healthy work environment we ask that all staff
    carefully complete this self-assessment prior to visiting any client location.`,
    show: true
  },
  {
    question: `Have you traveled within the last 21 days to/from mainland China, Hong Kong,
    Italy, Japan, Singapore, or South Korea including layovers?`,
    title: 'Part I(A)',
    key: 'p1a',
    val: undefined,
    show: false

  },
  {
    question: `Has a member of your household returned within the last 21 days from mainland China,
     Hong Kong, Italy, Japan, Singapore, or South Korea including layovers?`,
    title: 'Part I(B)',
    key: 'p1b',
    val: undefined,
    show: false

  }, {
    question: `Have you had close contact* with a person who has been diagnosed with COVID-19 (Coronavirus)?`,
    title: 'Part I(C)',
    key: 'p1c',
    val: undefined,
    show: false

  },
  {
    question: `Do you have a fever (greater than 38°C/100.4°F) with or without cough or congestion?`,
    title: 'Part II(A)',
    key: 'p2a',
    val: undefined,
    show: false

  }, {
    question: `Do you have any signs or symptoms of respiratory or flu-like illness or have had signs or symptoms in the past 21 days?`,
    title: 'Part II(B)',
    key: 'p2b',
    //val: false

  }, {
    question: `I certify this information is accurate and complete.`,
    show: false,
    isLast: true,
  },

  ];



  ngOnInit() { }

  next(i: number) {
    this.quiz.forEach(item => {
      item.show = false;
    });
    this.quiz[i + 1].show = true;
  }

  finish() {
    const data = { covidAssessment: false };
    this.quiz.forEach(item => {
      if (item.key) {
        data[item.key] = item.val;
      }

    });
    data.covidAssessment = true;

    this.modalController.dismiss(data);

  }

}
