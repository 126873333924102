<ion-list class="reusable-form-component">
  <ion-item>
    <ion-label class="label" position="stacked" *ngIf="labelText">{{labelText}}</ion-label>
    <ion-select class="form-select" [disabled]="disableList" [(ngModel)]="value" placeholder="{{placeholder}}"
      [ngClass]="{'inital-value' : !value}" [interfaceOptions]="{cssClass: 'references-select'}" (cancel)="touched($event)">
      <!-- if options are string -->
      <ng-container *ngIf="!labelKey" >
        <ion-select-option *ngFor="let option of options" value="{{option}}">{{option}}</ion-select-option>
      </ng-container>
      <!-- if options are objects -->
      <ng-container *ngIf="labelKey && valueKey && !returnObject">
        <ion-select-option  *ngFor="let option of options"
          value="{{option[valueKey]}}">{{option[labelKey]}}</ion-select-option>
      </ng-container>

      <ng-container *ngIf="labelKey && valueKey && returnObject">
        <ion-select-option  *ngFor="let option of options"
          [value]="option">{{option[labelKey]}}</ion-select-option>
      </ng-container>
      <!-- if options is an array of object and just hte label key is provided, we will use the complete object for value -->
      <ng-container *ngIf="labelKey && !valueKey">
        <ion-select-option *ngFor="let option of options" [value]="option">{{option[labelKey]}}</ion-select-option>
      </ng-container>
    </ion-select>
    
  </ion-item>
</ion-list>

<app-form-errors class = "errors" [control]="control"></app-form-errors>