import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { from, of } from 'rxjs';


@Injectable()
export class LoadingService {

  constructor(public loadingController: LoadingController) { }
  loading: HTMLIonLoadingElement;
  isDisplayed = false;
  showLoading() {
    // console.log('counter ', this.counter)
    if (this.isDisplayed) {
      return of(this.loading);
    }
    this.isDisplayed = true;
    return from(
      this.loadingController.create({
        cssClass: 'custom-loader',
        spinner: null,

      }).then(async ldr => { 
        this.loading = ldr; 
        await ldr.present(); 
        return ldr; 
      }));
  }

  async hideLoading(loader: HTMLIonLoadingElement) {
    if (this.loading) {
      await this.loading.dismiss();
      this.isDisplayed = false;
    }
  }
}