import { Component, Input, OnInit } from '@angular/core';
import { IUserAddress } from 'src/modules/authentication/interfaces/user-address.interface';

@Component({
  selector: 'app-shift-multi-address-list',
  templateUrl: './shift-multi-address-list.component.html',
  styleUrls: ['./shift-multi-address-list.component.scss'],
})
export class ShiftMultiAddressListComponent implements OnInit {

  @Input() shiftAddressList: string[];
  constructor() { }

  ngOnInit() {}

}
