import * as tslib_1 from "tslib";
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
import * as i2 from "@ngx-translate/core";
export class LanguageService {
    constructor(localStorageService, translate) {
        this.localStorageService = localStorageService;
        this.translate = translate;
        this.selectedLanguage = 'en';
        this.checkForLanguage();
    }
    setInitialAppLanguage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // const language = this.translate.getBrowserLang();
            let lng = yield this.localStorageService.getLanguage();
            lng = lng ? lng : 'en';
            this.translate.setDefaultLang(lng);
            yield this.localStorageService.setLanguage(lng);
        });
    }
    setLanguage(lng) {
        this.localStorageService.setLanguage(lng);
        this.translate.use(lng);
        this.selectedLanguage = lng;
    }
    checkForLanguage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const lng = yield this.localStorageService.getLanguage();
            if (lng) {
                this.translate.use(lng);
                this.selectedLanguage = lng;
            }
            return lng;
        });
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.TranslateService)); }, token: LanguageService, providedIn: "root" });
