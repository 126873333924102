import * as tslib_1 from "tslib";
import { Platform } from '@ionic/angular';
import * as moment from 'moment-timezone';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { Network } from '@ionic-native/network/ngx';
import { LocalStorageService } from './local-storage.service';
import { filter, flatMap, groupBy, uniqBy } from 'lodash';
import { UserService } from './user.service';
import { UserTypeEnum } from 'src/modules/authentication/enums/user-type.enum';
import BigNumber from 'bignumber.js';
import { BehaviorSubject } from 'rxjs';
import 'moment-duration-format';
import { ModalController } from '@ionic/angular';
import { StatPayComponent } from '../components/stat-pay/stat-pay.component';
import { SkillsService } from './skills.service';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic-native/app-rate/ngx/index";
import * as i3 from "@ionic-native/network/ngx/index";
import * as i4 from "./local-storage.service";
import * as i5 from "./user.service";
import * as i6 from "./skills.service";
export class UtilService {
    constructor(platform, appRate, network, localStorageService, userService, modalController, skillsService) {
        this.platform = platform;
        this.appRate = appRate;
        this.network = network;
        this.localStorageService = localStorageService;
        this.userService = userService;
        this.modalController = modalController;
        this.skillsService = skillsService;
        this.clearCashedJobs = new BehaviorSubject('true' || 'false');
    }
    otherCityUser(address) {
        const state = address.province;
        // Quebec removed  
        const regions = ['Ontario', 'British_Columbia', 'New_York', 'Texas', 'Alberta', 'Saskatchewan', 'Manitoba', 'Nova_Scotia'];
        let isRegionExist = false;
        regions.forEach((region) => {
            if (region === state) {
                isRegionExist = true;
            }
        });
        return isRegionExist;
    }
    launchAddressOnMap(lat, lng, name) {
        const isIOS = this.platform.is('ios');
        const isAndroid = this.platform.is('android');
        const isIPad = this.platform.is('ipad');
        const geocoords = lat + ',' + lng;
        if (isAndroid) {
            window.open('geo:0,0?q=' + geocoords + '(' + name + ')', '_system');
        }
        else if (isIOS || isIPad) {
            window.open('maps://?q=' + geocoords, '_system');
        }
        else {
            window.open('https://www.google.com/maps/?q=' + geocoords, '_system');
        }
    }
    stringifyRecurringShifts(timeZone, arrayOfUnixTime) {
        const result = {};
        let dateRange = '';
        const tz = timeZone ? timeZone : localStorage.getItem('timezone');
        arrayOfUnixTime.sort();
        arrayOfUnixTime.forEach(el => {
            const month = moment.unix(el).tz(tz).format('MMM');
            if (result[month]) {
                result[month].push(' ' + moment.unix(el).tz(tz).format('DD'));
            }
            else {
                result[month] = [(' ' + moment.unix(el).tz(tz).format('DD'))];
            }
        });
        for (const [key, value] of Object.entries(result)) {
            dateRange += (dateRange.length ? ' | ' + key : key) + ' ' + value;
        }
        return dateRange;
    }
    stringifyRecurringShiftsForClient(timeZone, arrayOfUnixTime) {
        let dateRanges = '';
        let connector = '';
        const tz = timeZone ? timeZone : localStorage.getItem('timezone');
        arrayOfUnixTime.sort();
        let startRange = moment.unix(arrayOfUnixTime[0]).tz(tz);
        let endRange = startRange;
        let currentMonth = startRange.format('MMM');
        //If the calendar ends and not all the dates are fetched and only one shift from the recurring sequence is visible
        if (arrayOfUnixTime.length === 1) {
            return startRange.format('MMM D, YYYY');
        }
        for (let i = 0; i < arrayOfUnixTime.length; i++) {
            //Assign current date from unix 
            const currentDate = moment.unix(arrayOfUnixTime[i]).tz(tz);
            //Check if the current date is contination of the end date, if yes, update the end date
            if (this.areDatesInContinuation(currentDate, endRange)) {
                //If dates are in continuation, update the end date by next date
                endRange = currentDate;
            }
            else {
                //Rules to decide whether to include month in string or not
                const includeMonth = this.includeMonthInRange(dateRanges, currentDate, endRange);
                //Identiy the connector use to link start date and end date
                if (dateRanges.length) {
                    connector = currentMonth !== endRange.format('MMM') ? ' , ' : ' & ';
                }
                dateRanges += connector + this.formatDateRanges(startRange, endRange, includeMonth, dateRanges);
                //Update the current month, start and end ranges by the previous date
                currentMonth = endRange.format('MMM');
                startRange = currentDate;
                endRange = currentDate;
            }
        }
        //Select connector to link last range
        if (dateRanges.length) {
            connector = currentMonth === endRange.format('MMM') ? ' & ' : ' , ';
        }
        let includeMonth = !dateRanges.includes(endRange.format('MMM')) && endRange.format('MMM') != currentMonth;
        dateRanges += (dateRanges.length ? connector : '') + this.formatDateRanges(startRange, endRange, includeMonth, dateRanges);
        dateRanges = this.editDateRange(startRange, endRange, dateRanges);
        return dateRanges;
    }
    // // Function to format date ranges
    formatDateRanges(start, end, includeMonth, dateRange) {
        let startFormat = start.format('D');
        let endFormat = '';
        const isMonthSame = end.format('MMM') === start.format('MMM') || false;
        if (includeMonth && !dateRange.includes(end.format('MMM'))) {
            startFormat = start.format('MMM D');
        }
        // Check if the start and end months are the same
        if (isMonthSame) {
            if (!dateRange.includes(start.format('MMM')) && (end.diff(start, 'days') === 1)) {
                startFormat = start.format('MMM D');
            }
            // If the end date is the same day as the start date, leave endFormat empty
            // If the date range spans more than one day, include the day in the end format
            endFormat = end.isSame(start, 'day') ? '' : (end.diff(start, 'days') >= 1 ? '-' + end.format('D') : '');
        }
        //Add a , connector as join when month changes otherwise add date2
        if (!isMonthSame) {
            endFormat += ', ' + end.format('MMM') + ' ' + end.format('D');
        }
        // Define regex to handle special characters in the date range
        const formattedRange = this.removeTrailingJoins(startFormat + endFormat);
        return formattedRange;
    }
    areDatesInContinuation(currentDate, endRange) {
        //Rules to create one interval:
        //1: Current date should have same month as end date
        //2: To check if its the very next date, the difference between 2 dates but be 0 or 1
        return currentDate.format('MMM') === endRange.format('MMM') &&
            (currentDate.diff(endRange, 'days') === 1 || currentDate.isSame(endRange, 'day'));
    }
    includeMonthInRange(dateRanges, currentDate, endRange) {
        //Rules to include month in a range
        //01: Its the first date, i.e date range is null
        //02: Date range already has the month, dont include the month 
        //03: Current month is different than the end date
        return !dateRanges.length ||
            !dateRanges.includes(currentDate.format('MMM')) ||
            currentDate.format('MMM') !== endRange.format('MMM') ? true : false;
    }
    editDateRange(startRange, endRange, dateRanges) {
        if (!dateRanges.includes('&') && endRange.diff(startRange, 'days') > 1) {
            // Check if there are multiple months involved
            if (startRange.format('MMM') === endRange.format('MMM')) {
                dateRanges = startRange.format('MMM') + ' ' + dateRanges;
            }
        }
        let trailingMonthRegex = /(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s/;
        dateRanges = dateRanges.replace(trailingMonthRegex, dateRanges.substr(3, 5));
        //Replace all & expect last
        const regex = /[&](?=.*&)/g;
        dateRanges = dateRanges.replace(regex, ",");
        return dateRanges;
    }
    removeTrailingJoins(dateFormatted) {
        const regex = /[&](?=.*&)/g;
        return dateFormatted.replace(regex, ",").trim();
    }
    formatDateRange(start, end) {
        if (start.isSame(end, 'day')) {
            return start.format('MMM D');
        }
        else {
            return start.format('MMM D') + '-' + end.format('D');
        }
    }
    showAppRating(userJobsLength) {
        if (!userJobsLength) {
            this.appRate.preferences.storeAppURL = {
                ios: '1133559351',
                android: 'market://details?id=ca.staffy.app'
            };
            this.appRate.promptForRating(true);
        }
    }
    getTimezone(province) {
        let timezone = 'America/Toronto';
        if (province == 'Ontario' || province == 'Quebec' || province == 'Québec') {
            timezone = 'America/Toronto';
        }
        else if (province == 'British_Columbia') {
            timezone = 'America/Vancouver';
        }
        else if (province == 'New_York') {
            timezone = 'America/New_York';
        }
        else if (province == 'Texas') {
            timezone = 'America/Chicago';
        }
        else if (province == 'Alberta') {
            timezone = 'America/Edmonton';
        }
        else if (province == 'Saskatchewan') {
            timezone = 'America/Regina';
        }
        else if (province == 'Manitoba') {
            timezone = 'America/Winnipeg';
        }
        else if (province == 'Novia_Scotia') {
            timezone = 'America/Halifax';
        }
        return timezone;
    }
    setTimeZone(province) {
        const tz = this.getTimezone(province);
        moment.tz.setDefault(tz);
        this.localStorageService.setUserTimeZoneAndCity(tz);
    }
    getUserName(user) {
        const lastName = user.lastName && user.isFullName ? user.lastName : user.lastName[0] + '.';
        return user.firstName + ' ' + lastName;
    }
    getUserFromServerOrLocal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isConnected()) {
                try {
                    const response = yield this.userService.getUser();
                    this.setTimeZone(response.address.province);
                    return response;
                }
                catch (error) {
                    console.log('error in user/me', error);
                    return;
                }
            }
            else {
                return this.localStorageService.getLoggedInUserInfo();
            }
        });
    }
    isMobile() {
        const isIOS = this.platform.is('ios');
        const isAndroid = this.platform.is('android');
        const isIPad = this.platform.is('ipad');
        return (window.cordova && (isIPad || isIOS || isAndroid));
    }
    isConnected() {
        const isOnline = this.isMobile() ? this.isMobileNetworkAvailable() : navigator.onLine;
        const token = this.localStorageService.getLoggedInUserToken();
        if (isOnline && token) {
            return true;
        }
        return false;
    }
    isMobileNetworkAvailable() {
        const contentType = this.network.type;
        return contentType && contentType !== 'unknown' && contentType !== 'none';
    }
    isStaffersConfirmedShifts(staffersAndShifts, isConfirmedShift) {
        if (staffersAndShifts && staffersAndShifts.length) {
            return filter(staffersAndShifts, (s) => {
                return s.isConfirmedShift == isConfirmedShift;
            });
        }
        else {
            return [];
        }
    }
    pendingStaffersInShifts(staffersAndShifts) {
        if (staffersAndShifts && staffersAndShifts.length) {
            return filter(staffersAndShifts, (s) => {
                return s.isConfirmedShift == false && s.state !== 'rejected';
            });
        }
        else {
            return [];
        }
    }
    filterInterestedStaffers(interestedStaffers) {
        if (interestedStaffers && interestedStaffers.length) {
            return filter(interestedStaffers, (staffers) => {
                return staffers.status == 'interested';
            });
        }
        else {
            return [];
        }
    }
    checkDisputeButtonCondition(job, user) {
        if (user && job && job.shiftEndTime) {
            let is = false;
            if ((user)) {
                if (user.customer.hasCreditCard && moment().diff(moment.unix(job.shiftEndTime), 'hours') < 48) {
                    is = true;
                }
                else if (!user.customer.hasCreditCard && moment().diff(moment.unix(job.shiftEndTime), 'hours') < 120) {
                    is = true;
                }
            }
            return is;
        }
    }
    parse29HourFormat(value) {
        let hours = moment.unix(value).hour();
        const minutes = moment.unix(value).minutes();
        if (hours < 6) {
            if (hours === 0) {
                hours = 24;
            }
            else if (hours === 1) {
                hours = 25;
            }
            else if (hours === 2) {
                hours = 26;
            }
            else if (hours === 3) {
                hours = 27;
            }
            else if (hours === 4) {
                hours = 28;
            }
            else if (hours === 5) {
                hours = 29;
            }
        }
        return parseFloat(hours.toString() + '.' + minutes.toString()).toFixed(2);
    }
    isAvailableToday(user) {
        if (user.type !== UserTypeEnum.STAFFER) {
            return;
        }
        let availablity = false;
        for (const schedule of user.availability_schedule) {
            if (schedule.date === moment().format('MMMM Do YYYY')) {
                availablity = true;
                break;
            }
        }
        for (const schedule of user.schedule.availability) {
            if (schedule.day.indexOf(moment().format('dddd')) !== -1) {
                availablity = true;
                break;
            }
        }
        return availablity;
    }
    checkTime(shiftEndTime) {
        return moment().isBefore(moment.unix(shiftEndTime));
    }
    formatTitleByRegion(region) {
        if (region === 'Ontario') {
            return 'Smart Serve';
        }
        else if (region === 'British_Columbia') {
            return 'Serving It Right';
        }
        else if (region === 'Quebec') {
            return 'Service in Action';
        }
        else if (region === 'Texas') {
            return 'TABC';
        }
        else if (region === 'New_York') {
            return 'ATAP';
        }
        else {
            return 'Smart Serve';
        }
    }
    getDateRange(jobItem) {
        let similarStartTimings = true;
        let similarEndTimings = true;
        const timeZone = jobItem.timeZone;
        let array;
        jobItem.jobs.map((job) => {
            job.formattedStartTime = moment.unix(job.shiftStartTime).tz(timeZone).format('hh:mm a');
            job.formattedEndTime = moment.unix(job.shiftEndTime).tz(timeZone).format('hh:mm a');
        });
        array = groupBy(jobItem.jobs, 'formattedStartTime');
        if (uniqBy(jobItem.jobs, 'formattedStartTime').length > 1) {
            similarStartTimings = false;
            array = groupBy(jobItem.jobs, 'formattedStartTime');
        }
        if (similarStartTimings == true && uniqBy(jobItem.jobs, 'formattedEndTime').length > 1) {
            similarEndTimings = false;
            array = groupBy(jobItem.jobs, 'formattedEndTime');
        }
        for (const i in array) {
            const endTimeArray = groupBy(array[i], 'formattedEndTime');
            for (const j in endTimeArray) {
                let jobShiftStartTime;
                if (similarStartTimings) {
                    jobShiftStartTime = array[i].map((job) => {
                        return job.shiftStartTime;
                    });
                }
                else {
                    jobShiftStartTime = endTimeArray[j].map((job) => {
                        return job.shiftStartTime;
                    });
                }
                const jobShiftEndTime = endTimeArray[j].map((job) => {
                    return job.shiftEndTime;
                });
                const obj = {
                    range: this.stringifyRecurringShifts(array[i][0].timeZone, jobShiftStartTime),
                    startTime: array[i][0].formattedStartTime,
                    endTime: endTimeArray[j][0].formattedEndTime,
                    shiftStartTime: jobShiftStartTime,
                    shiftEndTime: jobShiftEndTime
                };
                jobItem.formattedRange.push(obj);
            }
        }
        jobItem.formattedRange.forEach((i) => {
            i.range = i.range.replace(/,/g, ' | ');
        });
        return jobItem;
    }
    findCountryFromState(state) {
        const getCountry = {
            // USA
            Alabama: { country: 'United States' },
            Alaska: { country: 'United States' },
            Arizona: { country: 'United States' },
            Arkansas: { country: 'United States' },
            California: { country: 'United States' },
            Colorado: { country: 'United States' },
            Connecticut: { country: 'United States' },
            Delaware: { country: 'United States' },
            Florida: { country: 'United States' },
            Georgia: { country: 'United States' },
            Hawaii: { country: 'United States' },
            Idaho: { country: 'United States' },
            Illinois: { country: 'United States' },
            Indiana: { country: 'United States' },
            Iowa: { country: 'United States' },
            Kansas: { country: 'United States' },
            Kentucky: { country: 'United States' },
            Louisiana: { country: 'United States' },
            Maine: { country: 'United States' },
            Maryland: { country: 'United States' },
            Massachusetts: { country: 'United States' },
            Michigan: { country: 'United States' },
            Minnesota: { country: 'United States' },
            Mississippi: { country: 'United States' },
            Missouri: { country: 'United States' },
            Montana: { country: 'United States' },
            Nebraska: { country: 'United States' },
            Nevada: { country: 'United States' },
            New_Hampshire: { country: 'United States' },
            New_Jersey: { country: 'United States' },
            New_Mexico: { country: 'United States' },
            New_York: { country: 'United States' },
            North_Carolina: { country: 'United States' },
            North_Dakota: { country: 'United States' },
            Ohio: { country: 'United States' },
            Oklahoma: { country: 'United States' },
            Oregon: { country: 'United States' },
            Pennsylvania: { country: 'United States' },
            Rhode_Island: { country: 'United States' },
            South_Carolina: { country: 'United States' },
            South_Dakota: { country: 'United States' },
            Tennessee: { country: 'United States' },
            Texas: { country: 'United States' },
            Utah: { country: 'United States' },
            Vermont: { country: 'United States' },
            Virginia: { country: 'United States' },
            Washington: { country: 'United States' },
            West_Virginia: { country: 'United States' },
            Wisconsin: { country: 'United States' },
            Wyoming: { country: 'United States' },
            // CANADA
            Alberta: { country: 'Canada' },
            British_Columbia: { country: 'Canada' },
            Labrador: { country: 'Canada' },
            Manitoba: { country: 'Canada' },
            New_Brunswick: { country: 'Canada' },
            Newfoundland: { country: 'Canada' },
            Nova_Scotia: { country: 'Canada' },
            Nunavut: { country: 'Canada' },
            North_West_Terr: { country: 'Canada' },
            Ontario: { country: 'Canada' },
            Prince_Edward_Is: { country: 'Canada' },
            Quebec: { country: 'Canada' },
            Saskatchewen: { country: 'Canada' },
            Yukon: { country: 'Canada' }
        };
        return typeof (getCountry[state]) == 'undefined' ? 'Canada' : getCountry[state].country;
    }
    getTimeTracked(timeTracked) {
        return moment.duration(timeTracked).format('h:mm', { trim: false });
    }
    bigDeci(value, isFormat, decimalPlaces) {
        let val = new BigNumber(value.toString());
        let deciPlace = decimalPlaces ? decimalPlaces : 2;
        if (isFormat) {
            return val.toFormat(2);
        }
        val = val.toFixed(deciPlace, BigNumber.ROUND_DOWN);
        return new BigNumber(val);
    }
    contractorTaxes(state) {
        const tax = {
            'British_Columbia': 5,
            'Ontario': 13,
            'Quebec': 5,
            'New_York': 8.875,
            'Texas': 8.875,
            'Alberta': 5,
            'Saskatchewan': 11,
            'Manitoba': 5,
            'Nova_Scotia': 15
        };
        return state && typeof state !== 'undefined' ? tax[state] : 0;
    }
    removeExtraDecimalsPlaces(amount) {
        const paidAmount = Number(this.bigDeci(amount));
        return Number(paidAmount).toFixed(2);
    }
    isHCSkill(skill) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const healthcareSkills = yield this.skillsService.getListOfAllSkills({ industriesToInclude: ['Healthcare'], outputFormat: 'names' });
            return healthcareSkills.includes(skill);
        });
    }
    isHCNursingSkill(skill) {
        const HCNursingSkill = [
            'nurse practitioner',
            'registered nurse',
            'licensed practical nurse',
            'registered practical nurse',
        ];
        return HCNursingSkill.indexOf(skill) > -1;
    }
    isVSCRequired(skill) {
        const VSCSkills = [
            'nurse practitioner',
            'registered nurse',
            'licensed practical nurse',
            'registered practical nurse',
            'personal support worker',
            'nurse practitioner',
            'personal care aide',
            'patient care assistant',
            'health care aide',
            'pharmacy technician',
            'unregulated care provider'
        ];
        return VSCSkills.indexOf(skill) > -1;
    }
    doesContractorHasHCSkill(skill) {
        const HCNursingSkill = [
            'nurse practitioner',
            'registered nurse',
            'licensed practical nurse',
            'registered practical nurse',
        ];
        return HCNursingSkill.indexOf(skill) > -1;
    }
    doesSkillRequirePhysicalBadge(skill) {
        const physicalBadgeSkills = [
            'personal care aide',
            'personal support worker',
            'unregulated care provider',
            'registered nurse',
            'registered practical nurse',
        ];
        return physicalBadgeSkills.indexOf(skill) > -1;
    }
    not14daysPast(shiftStartTime, lastWorkedTime) {
        const diff = Math.ceil(moment.duration(moment.unix(shiftStartTime).diff(moment.unix(lastWorkedTime))).asDays());
        return diff < 15 ? true : false;
    }
    not12monthsPassed(lastSubmitted) {
        const timeNow = moment().unix();
        const diff = Math.ceil(moment.duration(moment.unix(timeNow).diff(moment.unix(lastSubmitted))).asMonths());
        return diff < 12 ? true : false;
    }
    calculateDistanceFromShift(srcAddress, destAddress) {
        var R = 6373;
        var dLon = this.deg2rad(destAddress.longitude) - this.deg2rad(srcAddress.longitude);
        var dLat = this.deg2rad(destAddress.latitude) - this.deg2rad(srcAddress.latitude);
        var dSqrt = Math.pow(Math.sin(dLat / 2), 2) + Math.cos(this.deg2rad(srcAddress.latitude)) * Math.cos(this.deg2rad(destAddress.latitude)) * Math.pow(Math.sin(dLon / 2), 2);
        ;
        var dTan = 2 * Math.atan2(Math.sqrt(dSqrt), Math.sqrt(1 - dSqrt));
        var distance = this.round(R * dTan);
        return distance.toFixed(2);
    }
    deg2rad(deg) {
        var rad = deg * Math.PI / 180;
        return rad;
    }
    round(x) {
        return Math.round(x * 1000) / 1000;
    }
    getIndustryBySkillName(skill) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allSkills = yield this.skillsService.getAllSkills();
            const industries = Object.keys(allSkills['industries']);
            for (const industry of industries) {
                const industrySkills = Object.keys([allSkills['industries'][industry]]);
                if (industrySkills.indexOf(skill) > -1) {
                    return industry;
                }
            }
        });
    }
    isStatPayAvailable(jobs) {
        return jobs.findIndex(obj => obj.isStatPay == true) > -1 ? true : false;
    }
    showStatPayShifts(jobItem, type, notification) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let statPayJobs = {
                jobs: []
            };
            if (jobItem.jobs) {
                statPayJobs.jobs = jobItem.jobs.filter((job) => {
                    job.type = type;
                    job.initialRate = job.initialRate ? job.initialRate : job.rate;
                    job.initialStafferRate = job.initialStafferRate ? job.initialStafferRate : (job.stafferRate ? job.stafferRate : job.rate);
                    return (job.isStatPay && job.isStatPay == true);
                });
            }
            const myModal = yield this.modalController.create({
                component: StatPayComponent,
                backdropDismiss: false,
                cssClass: 'forgotPassModal',
                componentProps: {
                    statPayJobs
                }
            });
            return yield myModal.present();
        });
    }
    checkNursingSkills(selectedSkills) {
        return selectedSkills.filter((skill) => { return skill.name == 'nurse practitioner' || skill.name == 'registered nurse' || skill.name == 'registered practical nurse' || skill.name == 'licensed practical nurse' || skill.name == 'x-ray technologist' || skill.name == 'ct technologist'; }).length > 0 ? true : false;
    }
    setAvailableShift(jobItem) {
        this.availableShift = jobItem;
    }
    getAvailableShift() {
        return this.availableShift;
    }
    updateCountryFlag(province) {
        const country = this.findCountryFromState(province);
        if (country === 'United States')
            return 'assets/images/usa-flag.png';
        return 'assets/images/ca-flag.png';
    }
    evaluateProfilePictureUrl(jobItem, invoice) {
        if (invoice && invoice.entity && invoice.entity.creator)
            return invoice.entity.creator.profileUrl;
        else if (jobItem && jobItem.entity && jobItem.entity.creator)
            return jobItem.entity.creator.profileUrl;
        else
            return 'assets/images/logo-sigil.png';
    }
    checkRequiredCertificates(skillsWithRequiredCertificates, userData) {
        let requiredCertificatesBySkill;
        let requiredCertificates;
        requiredCertificates = skillsWithRequiredCertificates.filter(o1 => userData.skills.some(o2 => o1.name === o2.name));
        requiredCertificatesBySkill = flatMap(requiredCertificates, 'requiredCertificates');
        requiredCertificates = flatMap(requiredCertificates, 'name');
        return { 'requiredCertificatesBySkill': requiredCertificatesBySkill, 'requiredCertificates': requiredCertificates };
    }
    isStringDefined(str) {
        if (!str || str === '' || str === undefined || str === null || typeof str === "undefined") {
            // not defined
            return false;
        }
        // defined
        return true;
    }
    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }
    capitalizeFirstCharacter(str) {
        return str.trim().charAt(0).toUpperCase() + str.slice(1);
    }
    /**
     * Get timezone by providing city
     * @param city
     * @returns
     */
    getTimezoneByCity(city) {
        let timeZone = 'America/' + (city === 'Mississauga' ? 'Toronto' : city);
        return timeZone === 'America/Houston' ? 'America/Chicago' : timeZone;
    }
    getSkillAbbreviation(skill) {
        if (/\W+/.test(skill)) {
            return skill.match(/\b\w/g).join('').toUpperCase();
        }
        else {
            return skill;
        }
    }
    formatRecurrentShiftDates(recurrentShiftTemplateId) {
        const startingFrom = moment.unix(recurrentShiftTemplateId.startDate).format('MMM DD');
        if (recurrentShiftTemplateId.intervalType !== 'Weekly') {
            return 'Every Mon-Fri from ' + startingFrom;
        }
        const dateRange = 'Every ' + this.convertDaysToShortFormat(recurrentShiftTemplateId.days) + ' from ' + startingFrom;
        return dateRange;
    }
    convertDaysToShortFormat(days) {
        if (days.length === 1) {
            return days[0];
        }
        const shortDays = days.map(day => moment(day, 'dddd').format('ddd'));
        if (shortDays.length === 2) {
            return shortDays.join(' & ');
        }
        else if (shortDays.length > 2) {
            const lastDay = shortDays.pop();
            return shortDays.join(', ') + ' & ' + lastDay;
        }
        return '';
    }
    fetchAddressesFromPatientList(job) {
        if (!job.homecareShift || !job.homecareShift.patients || !job.isMultiAddressShift) {
            return;
        }
        return _.flatMap(job.homecareShift.patients, 'address.street');
    }
    /**
    * Replace Encrypted Care Notes with Actual Care Notes for Patients
    * @param careNotes Array of care notes with patientId and encrypted careNote
    * @param patients Array of HomecarePatients to update with actual care notes
    */
    replaceEncryptedCareNotes(careNotes, patients) {
        if (patients && patients.length) {
            patients.forEach(patient => {
                const patientCareNote = careNotes.find(item => item.patientId === patient.id);
                if (patientCareNote) {
                    patient.careNotes = patientCareNote.careNote;
                }
            });
        }
    }
}
UtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilService_Factory() { return new UtilService(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.AppRate), i0.ɵɵinject(i3.Network), i0.ɵɵinject(i4.LocalStorageService), i0.ɵɵinject(i5.UserService), i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i6.SkillsService)); }, token: UtilService, providedIn: "root" });
