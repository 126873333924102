import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { filter, map } from 'lodash';
import { IClientJob } from 'src/modules/company-dashboard/interfaces/client-job.interface';
import { JobService } from 'src/modules/shared/services/job.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { AlertUpdateUnitNumberComponent } from '../alert-update-unit-number/alert-update-unit-number.component';


@Component({
    selector: 'app-edit-unit-number',
    templateUrl: './edit-unit-number.component.html',
    styleUrls: ['./edit-unit-number.component.scss'],
})
export class EditUnitNumberComponent implements OnInit {

    @Input() jobItem: IClientJob[] = [];
    @Input() unitNumbers: Array<String>
    @Input() shiftId: String;
    @Input() shiftUnitNumber: String | null;
    @Input() action: string;
    @Output() onUpdate: EventEmitter<{
        shiftId: String,
        shiftUnitNumber: String
    }> = new EventEmitter();
    @Output() onCancel: EventEmitter<void> = new EventEmitter();

    selectedUnitNumber;

    payloadUnit = {};
    constructor(private modalController: ModalController, private jobService: JobService, private loadingService: LoadingService, private popupService: PopupService) { }
    ngOnInit() {
        
        //Initialize job with 'selectedUnitNumber': unit number of each job, 
        //unitNumberList: each job will have their own dropdown list 
        //dirty: update jobs whose unit number are changed 
        this.jobItem = this.jobItem.map((job) => {
            job['selectedUnitNumber'] = job.unitNumber;
            job['unitNumberList'] = this.unitNumbers;
            return job;
        });

        this.jobItem.forEach((job) => {
            this.payloadUnit = {...this.payloadUnit, ...{[job._id] : {isChanged: false, unitNumber: job.unitNumber || ''}}};
        });
    }

    async handleSaveUnitNumber() {
        const loading = await this.loadingService.showLoading().toPromise();
        let payload = [];
        try {
            
            //make payload an array and push all unit numbers with shift ids in it!
            //check if the unit number is changed or not 
            payload = this.addUpdatedUnitNumbers();

            // Close the popup if unit number is not updated but save is clicked
            // Save api request call if unit numbers remain unchanged 
            if(!payload.length) {
                this.closeModal();
                return;
            } 

            
            await this.jobService.editShiftUnitNumber(payload, this.jobItem['_id']);
                
            this.popupService.showModal(
                {
                    heading: 'Success!',
                    message: 'Shift unit number has been updated successfully',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/thumbs-up.png'
                })
            
            this.modalController.dismiss({payload});
            
        } catch (e) {
            this.popupService.showModal(
                {
                    heading: 'Error',
                    message: 'Something went wrong!!, Please try again later',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/profile.png'
                })
        }
        finally {
            this.loadingService.hideLoading(loading);
        }

    }

    handleUnitNumberChange(jobItem: IClientJob) {
        //Indicate that the value of unit number is changed
        this.payloadUnit[jobItem._id] = {isChanged: true, unitNumber: jobItem['selectedUnitNumber']};
    }

    addUpdatedUnitNumbers() {
        let payload = [];
        
        //Convery Object of objects into array
        payload  = Object.keys(this.payloadUnit).map((keys) => {
            let element = this.payloadUnit[keys]; element.jobId = keys; return element;
        });

        //Filter payload array and fetch only those elements whose 'isChanged' property is true
        payload = filter(payload, (element) => {
            return element.isChanged === true;
        }); 
        
        return payload;
    }
    closeModal() {
        this.modalController.dismiss();
    }
}