<div>
  <div class="properties-and-badges" [ngClass]="{'directHire' : isDirectHire}">
    <div class="shift-properties ">

      <div class="signal-box light-orange-txt-icon" *ngIf="showTotalShifts">
        <div class="properties-figure bold-font">
          {{userData?.shifts_completed || 0}}
        </div>
        <div class="properties-text">
          Total shifts
        </div>
      </div>

      <div class="signal-box dark-blue-txt-icon">
        <div class="properties-figure bold-font">
          {{userData?.lateArrival || 0}}
        </div>
        <div class="properties-text">
          {{ 'HOME_TALENT.LATE_ARRIVALS' | translate }}
        </div>
      </div>

      <div class="signal-box dark-blue-txt-icon">
        <div class="properties-figure bold-font">
          {{userData?.shiftsCanceled || 0}}
        </div>

        <div class="properties-text">
          Cancelled
        </div>
      </div>

      <div class="signal-box dark-blue-txt-icon no-right-margin">
        <div class="properties-figure bold-font">
          {{userData?.noShows || 0}}

        </div>
        <div class="properties-text no-show-padding">
          {{'HOME_TALENT.NO_SHOWS' | translate}}</div>
      </div>
    </div>
  </div>