import * as tslib_1 from "tslib";
import { OnInit, QueryList } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { updateUserPartialStateSuccess } from 'src/modules/authentication/+store/actions/auth.actions';
import { LoadingService } from '../../services/loading.service';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user.service';
export class VerifyEmailPopupComponent {
    constructor(modalController, userService, popupService, store, loadingService) {
        this.modalController = modalController;
        this.userService = userService;
        this.popupService = popupService;
        this.store = store;
        this.loadingService = loadingService;
        this.otpCode = '';
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.sendCode();
        });
    }
    ngOnDestroy() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId);
        }
    }
    sendCode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.setTimer(60);
            if (this.isUserAuthenticated) {
                yield this.userService.sendCodeToVerifyEmail(this.email);
            }
            else {
                yield this.userService.sendCodeToVerifyEmailSignup(this.email);
            }
        });
    }
    setTimer(timerInSeconds) {
        this.timer = timerInSeconds;
        this.timerIntervalId = setInterval(() => {
            if (this.timer <= 0) {
                clearInterval(this.timerIntervalId);
            }
            this.timer = this.timer - 1;
        }, 1000);
    }
    clearTimer() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId);
        }
        this.timer = 0;
    }
    close() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.modalController.dismiss();
        });
    }
    otpController(event, i) {
        const inputArray = this.otpInputs.toArray();
        const inputValue = event.target.value.trim();
        if ((!inputValue && !['Backspace', 'Delete'].includes(event.key)) || (inputValue && isNaN(inputValue))) {
            inputArray[i].value = '';
            return;
        }
        const nextElement = inputArray[i + 1] || null;
        const previousElement = inputArray[i - 1] || null;
        this.otpCode = inputArray.map(input => input.value).join('');
        if (!inputValue && previousElement) {
            previousElement.setFocus();
        }
        else if (nextElement && inputValue) {
            nextElement.setFocus();
        }
    }
    submitCode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const otp = this.otpCode;
            try {
                if (this.doSignupWithOtp) {
                    this.doSignupWithOtp(otp);
                }
                else {
                    this.loader = yield this.loadingService.showLoading().toPromise();
                    const response = yield this.userService.setupETransferWithCode(otp);
                    if (response.message === 'verified') {
                        // UPDATE STORE
                        this.store.dispatch(updateUserPartialStateSuccess({
                            partialUserState: {
                                paymentMethod: 'e-transfer',
                                berkeleyFinancialAccount: {
                                    id: '',
                                    email: this.email
                                }
                            }
                        }));
                        // SHOW CONFIRMATION POPUP
                        this.popupService.showModal({
                            heading: 'Success',
                            message: 'Your email has been verified and e-Transfer setup successfully.',
                            btn: 'Dismiss',
                            imgURL: '',
                            navigateRoute: '',
                        });
                        this.close();
                    }
                }
            }
            catch (error) {
                this.popupService.showModal({
                    heading: 'Error',
                    message: error.error.message || 'Something went wrong, please try again.',
                    btn: 'Dismiss',
                    imgURL: '',
                    navigateRoute: '',
                });
            }
            finally {
                yield this.loadingService.hideLoading(this.loader);
            }
        });
    }
}
