import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BroadcastJobsUtilService } from 'src/modules/company-dashboard/services/broadcast-job-utils.service';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { RecurrentShiftService } from '../../services/recurrentShift.service';
export class ShiftBasicInfoComponent {
    constructor(talentJobFormatService, broadcastJobsUtilService, recurrentShiftService) {
        this.talentJobFormatService = talentJobFormatService;
        this.broadcastJobsUtilService = broadcastJobsUtilService;
        this.recurrentShiftService = recurrentShiftService;
        this.readOnly = false;
        this.patientId = '';
    }
    ngOnInit() {
        if (this.jobItem) {
            this.fetchPatientId();
        }
    }
    viewRecurringPropertiesOfShift(jobItem, heading, showUnitNumber) {
        //Format individual jobs into job.jobs Object  
        const job = {
            jobs: jobItem
        };
        this.talentJobFormatService.viewRecurringProperties(job, heading, showUnitNumber);
    }
    addUnitNumber(jobItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const updatedJob = yield this.broadcastJobsUtilService.editUnitNumber(jobItem, this.unitNumber, 'Add');
            this.jobItem = updatedJob;
        });
    }
    doesRecurringShiftHasUnitNumber(jobItem) {
        const unitNumber = this.recurrentShiftService.unitNumberByShift(jobItem);
        if (!unitNumber) {
            return false;
        }
        return true;
    }
    fetchPatientId() {
        const firstJob = this.jobItem[0];
        if (!firstJob || !firstJob.homecareShift || !firstJob.homecareShift.patients || firstJob.homecareShift.patients.length == 0) {
            return;
        }
        this.patientId = firstJob.homecareShift.patients.map(patient => patient.id).join(", ");
    }
}
