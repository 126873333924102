import * as tslib_1 from "tslib";
import { HttpPromiseService } from './http-promise.service';
import { RecurringShiftsTimingComponent } from '../components/recurring-shifts-timing/recurring-shifts-timing.component';
import { ModalController } from '@ionic/angular';
import { SkillsService } from './skills.service';
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-promise.service";
import * as i2 from "@ionic/angular";
import * as i3 from "./skills.service";
import * as i4 from "./local-storage.service";
export class JobService {
    constructor(httpPromiseService, modalController, skillsService, localStorageService) {
        this.httpPromiseService = httpPromiseService;
        this.modalController = modalController;
        this.skillsService = skillsService;
        this.localStorageService = localStorageService;
    }
    getDefaultRates(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/${userId}/defaultJobRates`);
    }
    isStafferAvailableToAcceptJob(jobId, body) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/${jobId}/isStafferAvailableToAcceptJob`, body);
    }
    updateJob(jobId, body) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/${jobId}`, body);
    }
    getAvailableShifts(staffer) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/availableShifts/${staffer.address.province}?stafferId=${staffer._id}&type=${staffer.type}`, { user: staffer });
    }
    getPlacesWorkedForStaffer(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/placesWorked/?staffer=${userId}`);
    }
    getAvailableShiftsV2(onlyRecurring, expertise) {
        return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/available-shifts?showRecurringOnly=${onlyRecurring || false}&showMyApplications=false&skills=${expertise || 'Hospitality'}&byJson=true`);
    }
    getClientCalendarShifts(entityId, jobStatusFlag, endTime, startTime, onlyRecurrentJobs) {
        const filled = jobStatusFlag.filled;
        const open = jobStatusFlag.open;
        const partial = jobStatusFlag.partial;
        const completed = jobStatusFlag.completed;
        return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/calendar-shifts?entityId=${entityId}&isFilled=${filled}&open=${open}&partial=${partial}&completed=${completed}&endTime=${endTime}&startTime=${startTime}&onlyRecurrentJobs=${onlyRecurrentJobs}`);
    }
    getPlacesWorkedWithAvailableShiftsV2(userId, onlyRecurring, expertise) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let [placesWorkedData, availableShiftsData] = yield Promise.all([
                this.getPlacesWorkedForStaffer(userId),
                this.getAvailableShiftsV2(onlyRecurring, expertise)
            ]);
            if (!availableShiftsData.results) {
                return;
            }
            availableShiftsData.results = availableShiftsData.results.map(availableShift => {
                let canApply = false;
                if (availableShift.isTrainingShift ||
                    !availableShift.trainingRequired ||
                    placesWorkedData.findIndex(placeWorked => placeWorked.entity == availableShift.entity._id) > -1)
                    canApply = true;
                return Object.assign({}, availableShift, { canApply: canApply });
            });
            const expertiseSkills = yield this.skillsService.getListOfAllSkills({
                industriesToInclude: [expertise],
                outputFormat: 'names'
            });
            availableShiftsData.results.forEach((availableShift, i) => {
                if (expertiseSkills.includes(availableShift.skill)) {
                    availableShiftsData.results.splice(i, 1);
                    availableShiftsData.results.unshift(availableShift);
                }
            });
            return availableShiftsData;
        });
    }
    isTrainingRequired(userId, jobItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let placesWorkedData = yield this.getPlacesWorkedForStaffer(userId);
            jobItem.canApply = false;
            if (jobItem.isTrainingShift || !jobItem.trainingRequired || placesWorkedData.findIndex(placeWorked => placeWorked.entity == jobItem.entity._id) > -1)
                jobItem.canApply = true;
            return jobItem.canApply;
        });
    }
    getAppliedShifts() {
        return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/available-shifts?showRecurringOnly=${false}&showMyApplications=${true}`);
    }
    unApplyFromBroadcast(id, staffer, unApply, isRecurring, dueToRCMPFee = false) {
        let path;
        let payload;
        if (isRecurring) {
            path = `/api/recurringShifts/${id}/unApplyFromBroadcast`;
            payload = {
                stafferId: staffer._id,
                stafferFirstName: staffer.firstName,
                stafferLastName: staffer.lastName,
                shiftUnapplied: unApply
            };
        }
        else {
            path = `/api/jobs/${id}/unApplyFromShift`;
            payload = {
                staffyId: staffer._id,
                staffyFirstName: staffer.firstName,
                staffyLastName: staffer.lastName,
                shiftUnapplied: unApply
            };
        }
        if (dueToRCMPFee) {
            path = `${path}?dueToRCMPFee=true`;
        }
        return this.httpPromiseService.httpPutRequest(path, Object.assign({}, payload));
    }
    rejectBroadcastShift(jodId, stafferId, stafferFirstName, stafferLastName) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/${jodId}/rejectShift`, { staffyId: stafferId, stafferFirstName, stafferLastName });
    }
    rejectRecurringShift(jodId, stafferId, stafferFirstName, stafferLastName) {
        return this.httpPromiseService.httpPutRequest(`/api/recurringShifts/${jodId}/rejectShift`, { stafferId, stafferFirstName, stafferLastName });
    }
    recurringShiftConfirm(groupShiftId, stafferId) {
        if (typeof groupShiftId == "object") {
            groupShiftId = groupShiftId._id;
        }
        return this.httpPromiseService.httpPutRequest(`/api/jobs/recurringShifts/confirmRecurringJob/${groupShiftId}/${stafferId}`, {});
    }
    nonRecurringShiftConfirm(jobId, stafferId) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/shifts/confirmJob/${jobId}/${stafferId}`, {});
    }
    acceptBroadcastRequestNonRecurring(jobId, stafferId, acceptedRcmpFee) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/${jobId}/acceptBroadcastRequest?acceptedRcmpFee=${acceptedRcmpFee}`, {
            stafferId
        });
    }
    acceptNonRecurringHireRequest(jobId, body) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/${jobId}/acceptHireRequest`, body);
    }
    rejectNonRecurringJob(job) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/${job._id}/reject`, job);
    }
    rejectStafferBroadcastRequest(body) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/rejectStaffer`, body);
    }
    hireStafferOnRecurringJob(groupJobId, body) {
        return this.httpPromiseService.httpPutRequest(`/api/recurringShifts/${groupJobId}/hireStaffer`, body);
    }
    fetchJobsForCompanyToRateStaffers() {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/fetchJobsForCompanyToRateStaffers?skip=0`);
    }
    rateStafferInJobs(body) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/rateStafferInJobs`, body);
    }
    getEmploymentHistory(userId, skip) {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/workHistory/${userId}?toSkipItems=${skip}&pageSize=10`);
    }
    fetchJobsForStafferToRateCompany() {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/fetchJobsForStafferToRateCompany`);
    }
    rateYourColleague(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/rateYourColleague/fetchJobsToRateYourColleague/${userId}`);
    }
    rateCompanyInJob(jobId, rating) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/${jobId}/rateCompanyInJob`, { rating });
    }
    rateStaffer(jobId, userId, body) {
        return this.httpPromiseService.httpPostRequest(`/api/jobs/rateYourColleague/rate/${jobId}/${userId}`, body);
    }
    fileDispute(jobId, body) {
        return this.httpPromiseService.httpPostRequest(`/api/jobs/${jobId}/fileDispute`, body);
    }
    confirmLoggedHoursOnAShift(stafferId, body) {
        return this.httpPromiseService.httpPatchRequest(`/api/tracker/confirmLoggedHours/${stafferId}`, body);
    }
    editManualLoggedHours(stafferId, body) {
        return this.httpPromiseService.httpPutRequest(`/api/manualHoursLogged/requestFromStaffer/update/${stafferId}`, body);
    }
    sendRequestToAdminToLoggedHours(stafferId, body) {
        return this.httpPromiseService.httpPostRequest(`/api/manualHoursLogged/requestFromStaffer/${stafferId}`, body);
    }
    fetchStaffersJobToShowWorkedPlaces() {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/fetchStaffersJobToShowWorkedPlaces`);
    }
    getOpenShiftsRequest(jobId = '', onlyRecurrentJobs) {
        return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/client-shifts?jobId=${jobId}&onlyRecurrentJobs=${onlyRecurrentJobs}&preset=getUnfilledJobs&withUnitWorked=true`);
    }
    upcomingStaffersGroupedByShiftDate() {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/upcomingStaffersGroupedByShiftDate`);
    }
    getFilledShiftsRequest(onlyRecurrentJobs) {
        return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/client-shifts?onlyRecurrentJobs=${onlyRecurrentJobs}&preset=getFilledJobs`);
    }
    fetchPastShiftsGroupedByShiftDate(startDate, endDate) {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/pastShiftsGroupedByShiftDate?startDate=${startDate}&endDate=${endDate}`);
    }
    deleteNonRecurrShift(shiftId) {
        return this.httpPromiseService.httpDeleteRequest(`/api/jobs/deleteShift/${shiftId}`);
    }
    deleteShiftWithIn48Hours(shiftId) {
        return this.httpPromiseService.httpPostRequest(`/api/jobs/deleteShiftWithIn48Hours/${shiftId}`, { deleteShift: true });
    }
    deleteRecurrShift(shiftId, toDelete, body) {
        return this.httpPromiseService.httpPostRequest(`/api/recurringShifts/admin/deleteShift/${shiftId}?toDelete=${toDelete}`, body || {});
    }
    rebroadcastShiftAndUpdateRate(jobId, updatedRate) {
        return this.httpPromiseService.httpPutRequestForCompany(`/api/jobs/rebroadcastShift/${jobId}`, {
            everyone: true,
            updatedRate
        });
    }
    rebroadcastRecurringShiftAndUpdateRate(jobId, updatedRate) {
        return this.httpPromiseService.httpPutRequestForCompany(`/api/recurringShifts/rebroadcastShift/${jobId}`, {
            everyone: true,
            updatedRate
        });
    }
    rejectStafferRecurringRequest(groupJobId, body) {
        return this.httpPromiseService.httpPutRequest(`/api/recurringShifts/${groupJobId}/rejectBroadcastRequest`, body);
    }
    deleteUpcomingRecurringShifts(jobId, forcefully) {
        const payload = {};
        if (forcefully) {
            payload.deleteShift = true;
        }
        return this.httpPromiseService.httpPostRequest(`/api/recurringShifts/admin/deleteUpcomingShifts/${jobId}`, payload);
    }
    retryPay(invoiceId) {
        return this.httpPromiseService.httpPostRequest(`/api/jobs/automateTransaction/${invoiceId}?actionTaken=employer`, {});
    }
    fetchPreviousHiredStaffys(skip) {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/previousStaffysEmp?skip=${skip}`);
    }
    fileMultipleDispute(id, data) {
        return this.httpPromiseService.httpPostRequest(`/api/jobs/${id}/fileMultipleDispute`, data);
    }
    broadcastRequest(data) {
        return this.httpPromiseService.httpPostRequestForCompany(`/api/jobs/broadcastRequest`, data, 'text');
    }
    createShiftRequest(data) {
        return this.httpPromiseService.httpPostRequestForCompany(`/api/jobs/createShiftRequest`, data, 'text');
    }
    recurringShiftsBroadcastRequest(data) {
        return this.httpPromiseService.httpPostRequestForCompany(`/api/recurringShifts/broadcastRequest`, data, 'text');
    }
    recurringShiftsDirectHireRequest(params) {
        return this.httpPromiseService.httpGetRequestForCompany(`/api/recurringShifts/shift/searchStaffers?${params}`);
    }
    createMutipleDaysShiftWithDifferentSkills(data) {
        return this.httpPromiseService.httpPostRequestForCompany(`/api/jobs/createMutipleDaysShiftWithDifferentSkills`, data, 'text');
    }
    createDirectHireShiftRequest(data) {
        return this.httpPromiseService.httpPostRequestForCompany(`/api/recurringShifts/shift/sendHireRequest`, data);
    }
    updateCOVIDAssessmentForStaffers(data) {
        return this.httpPromiseService.httpPutRequest(`/api/jobs/updateCOVIDAssessmentForStaffers`, { data });
    }
    searchStaffersForJob(entityId, queryParam) {
        return this.httpPromiseService.httpGetRequestForCompany(`/api/jobs/${entityId}/searchStaffersForJob?${queryParam}`);
    }
    editShift(jobId, body) {
        return this.httpPromiseService.httpPutRequestForCompany(`/api/jobs/editShift/${jobId}`, body);
    }
    editShiftRecurring(groupShiftId, body) {
        return this.httpPromiseService.httpPutRequestForCompany(`/api/recurringShifts/admin/editShift/${groupShiftId}`, body);
    }
    editShiftUnitNumber(body, jobId) {
        return this.httpPromiseService.httpPutRequestForCompany(`/api/jobs/editShiftUnitNumber/job`, body);
    }
    rebroadcastShift(jobId, options, jobType) {
        let url = '';
        if (jobType === 'nonRecurring') {
            url = '/api/jobs/rebroadcastShift/' + jobId;
        }
        else {
            url = '/api/recurringShifts/rebroadcastShift/' + jobId;
        }
        return this.httpPromiseService.httpPutRequestForCompany(url, options);
    }
    getOpenRequestCount() {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/openRequest/count?newAppUpdated=true`);
    }
    getFilledRequestCount() {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/closedRequest/count?newAppUpdated=true`);
    }
    findUpcomingShifts(userId, province, onlyForStore, withDeleted = false) {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/findUpcomingShiftsForLimitedDays/${userId}/${province}?storeShifts=${onlyForStore}&withDeleted=${withDeleted}`);
    }
    getPendingConfirmationCount() {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/pendingConfirmationCount/`);
    }
    findPreviousShifts(userId, province) {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/findPreviousShifts/${userId}/${province}`);
    }
    ensureLocation(userId, jobId, loc) {
        return this.httpPromiseService.httpPostRequest(`/api/jobs/ensureLocation/${userId}/${jobId}`, loc);
    }
    logDisclaimer(data) {
        return this.httpPromiseService.httpPostRequest(`/api/jobs/disclaimer`, data);
    }
    findShift(id) {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/fetchShiftDetails/${id}`);
    }
    fetchContractorHistoryWithClient(stafferId) {
        return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/stats/contractor-work-history?stafferId=${stafferId}`);
    }
    getShiftHistoryOfContractor(stafferId, showPastShifts) {
        return this.httpPromiseService.httpGetRequest(`/api/jobs/contractor-history/${stafferId}?showPastShifts=${showPastShifts}`);
    }
    viewShiftTimings(jobItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: RecurringShiftsTimingComponent,
                backdropDismiss: false,
                cssClass: 'wideModal',
                componentProps: {
                    jobItem
                }
            });
            return yield myModal.present();
        });
    }
    isApplied(userId, job) {
        for (let i = 0; i < job.isABroadcastRequest.interestedStaffers.length; i++) {
            if (job.isABroadcastRequest.interestedStaffers[i] === userId) {
                job.isApplied = true;
                break;
            }
        }
    }
    getTeams(entityId) {
        return this.httpPromiseService.httpGetRequest(`/api/teams/allTeams/${entityId}`);
    }
    updateTeam(teamId, data) {
        return this.httpPromiseService.httpPatchRequest(`/api/teams/updateTeam/${teamId}`, data);
    }
    hasStafferExistInTheTeam(team, stafferId) {
        let alreadyExist = false;
        for (const teamStaffer of team.staffers) {
            if (teamStaffer._id === stafferId) {
                alreadyExist = true;
                break;
            }
        }
        return alreadyExist;
    }
    addRecurrentShiftTemplate(payload) {
        return this.httpPromiseService.httpPostRequestForCompany(`/api/recurrentShiftTemplate/`, payload);
    }
    deleteRecurrentShiftTemplate(id) {
        return this.httpPromiseService.httpDeleteRequestForCompany(`/api/recurrentShiftTemplate/${id}`);
    }
    viewCareNotes(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.httpPromiseService.httpGetRequest(`/api/jobs/${id}/care-notes`);
        });
    }
    viewCareNotesV2(id, patientId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const queryParams = patientId ? `?patientId=${patientId}` : '';
            return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/${id}/care-notes${queryParams}`);
        });
    }
    createIndividualShiftsRequest(data) {
        return this.httpPromiseService.httpPostRequest(`/api/jobs/create-individual-shifts`, data, 'text');
    }
    getRecurringShiftsV2(queryParams) {
        return this.httpPromiseService.httpGetRequest(`/api/v2/jobs?${new URLSearchParams(queryParams).toString()}`);
    }
    editRecurrentShiftTempalte(id, payload) {
        return this.httpPromiseService.httpPutRequest(`/api/recurrentShiftTemplate/${id}`, payload);
    }
    editRecurrentSpecificShifts(groupJobId, payload) {
        return this.httpPromiseService.httpPutRequest(`/api/recurringShifts/bulkEdit/${groupJobId}`, payload);
    }
}
JobService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JobService_Factory() { return new JobService(i0.ɵɵinject(i1.HttpPromiseService), i0.ɵɵinject(i2.ModalController), i0.ɵɵinject(i3.SkillsService), i0.ɵɵinject(i4.LocalStorageService)); }, token: JobService, providedIn: "root" });
