/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video-player.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./video-player.component";
var styles_VideoPlayerComponent = [i0.styles];
var RenderType_VideoPlayerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoPlayerComponent, data: {} });
export { RenderType_VideoPlayerComponent as RenderType_VideoPlayerComponent };
export function View_VideoPlayerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "video-player-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "video", [["autoplay", ""], ["controls", ""], ["controlsList", "nodownload"], ["disablePictureInPicture", ""], ["height", "100%"], ["width", "100%"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_VideoPlayerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-video-player", [], null, null, null, View_VideoPlayerComponent_0, RenderType_VideoPlayerComponent)), i1.ɵdid(1, 114688, null, 0, i2.VideoPlayerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoPlayerComponentNgFactory = i1.ɵccf("app-video-player", i2.VideoPlayerComponent, View_VideoPlayerComponent_Host_0, { url: "url" }, {}, []);
export { VideoPlayerComponentNgFactory as VideoPlayerComponentNgFactory };
