import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { IClientJob } from 'src/modules/company-dashboard/interfaces/client-job.interface';
import { LocalStorageService } from '../../services/local-storage.service';
import { UtilService } from '../../services/util.services';

@Component({
  selector: 'app-edit-recurrent-shift-modal',
  templateUrl: './edit-recurrent-shift-modal.component.html',
  styleUrls: ['./edit-recurrent-shift-modal.component.scss'],
})
export class EditRecurrentShiftModalComponent implements OnInit {
  @ViewChild("editFullRecurrence", { static: false }) editFullRecurrence;
  @ViewChild("editSpecificShift", { static: false }) editSpecificShift;
  @Input() jobItem: IClientJob;
  @Input() userData: IUser;
  @Input() onSuccess : () => {}
  data: {
    leftText: string,
    rightText: string
  } = {
      leftText: 'Change full recurrence',
      rightText: 'Change specific shifts'
    };
  headerText: string = 'Edit recurrence';
  submitView: boolean = false;
  unChangeView : boolean = false; 
  toggleView: boolean = false;
  discardText: string = 'Go back';
  confirmText: string = 'Save';
  note: string;
  timeZone: string;
  constructor(
    private modalController: ModalController,
    private localStorageService: LocalStorageService,
    private utilService: UtilService
  ) { }

  async ngOnInit() {
    const province = this.userData.entityId.address.province || 'America/Toronto';
    this.note = `<span class = "mont-bold italic">Important note: </span><span class = "mont-regular italic">If you make any changes that affect shifts starting in the next 3 hours, cancellation fees may apply.</span>`
    this.timeZone = await this.localStorageService.getUserTimeZone() || this.utilService.getTimezone(province);
  }

  onRecurrentToggleChange(value: boolean) {
    this.toggleView = value;
  }

  async onSave() {
    this.unChangeView = false
    if(this.submitView && this.toggleView){
      await this.editSpecificShift.onSubmit(this.onSuccess)
      this.modalController.dismiss();
    }

    if (this.toggleView) {
      if((await this.editSpecificShift.trackShiftPropertyChanges()).length){
        this.submitView = true;
        this.discardText = "Discard",
        this.confirmText = "Yes, proceed"
        await this.editSpecificShift.trackShiftPropertyChanges(true)
      }else{
        this.unChangeView = true;
      }
     
    } else {
      this.modalController.dismiss({isFullRecurrence: this.editFullRecurrence.isFullRecurrence, payload: this.editFullRecurrence.recurrentForm,onSubmit:this.editFullRecurrence.onSubmit.bind(this.editFullRecurrence,this.onSuccess)});
    }

  }
  dismiss() {
    this.modalController.dismiss();
  }
}
