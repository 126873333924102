import * as tslib_1 from "tslib";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import * as authActions from '../actions/auth.actions';
import * as talentDashboardActions from '../../../talent-dashboard/+store/actions/talent-dashboard.actions';
import { exhaustMap, map, catchError, tap, switchMap, mergeMap, first } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { OnBoardingUtilService } from 'src/modules/shared/services/onboarding-util.service';
import { UserService } from 'src/modules/shared/services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { UserTypeEnum } from '../../enums/user-type.enum';
import { PushNotificationService } from 'src/modules/shared/services/push-notification.service';
import { AppInfoService } from 'src/modules/shared/services/app-info.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { AnalyticsService } from 'src/modules/shared/services/analytics.service';
import { LocalStorageKeys } from 'src/modules/shared/enums/local-storage-keys.enum';
import { getUserIdFromInfo } from '../auth-selector';
import { select, Store } from '@ngrx/store';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../../../shared/services/user.service";
import * as i3 from "../../../shared/services/local-storage.service";
import * as i4 from "@angular/router";
import * as i5 from "../../../shared/services/loading.service";
import * as i6 from "../../../shared/services/onboarding-util.service";
import * as i7 from "../../../shared/services/popup.service";
import * as i8 from "../../services/authentication.service";
import * as i9 from "../../../shared/services/push-notification.service";
import * as i10 from "../../../shared/services/app-info.service";
import * as i11 from "../../../shared/services/util.services";
import * as i12 from "../../../shared/services/analytics.service";
import * as i13 from "@ngrx/store";
export class AuthEffects {
    constructor(actions$, userService, localStorageService, router, loadingService, onBoardingUtilService, popupService, authenticationService, pushNotificationService, appInfoService, utilService, analyticsService, store) {
        this.actions$ = actions$;
        this.userService = userService;
        this.localStorageService = localStorageService;
        this.router = router;
        this.loadingService = loadingService;
        this.onBoardingUtilService = onBoardingUtilService;
        this.popupService = popupService;
        this.authenticationService = authenticationService;
        this.pushNotificationService = pushNotificationService;
        this.appInfoService = appInfoService;
        this.utilService = utilService;
        this.analyticsService = analyticsService;
        this.store = store;
        this.login$ = createEffect(() => this.actions$.pipe(ofType(authActions.loginAction), exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))), exhaustMap(({ action, loader }) => this.authenticationService.loginViaEmail(action.credentials).pipe(tap(() => tslib_1.__awaiter(this, void 0, void 0, function* () { return yield this.loadingService.hideLoading(loader); }), () => tslib_1.__awaiter(this, void 0, void 0, function* () { return yield this.loadingService.hideLoading(loader); })), map(data => authActions.loginSuccessAction(data)), catchError(error => of(authActions.loginFailureAction({ error })))))));
        this.loginSuccess$ = createEffect(() => this.actions$.pipe(ofType(authActions.loginSuccessAction), tap(action => this.localStorageService.setLoggedInUserToken(action.token)), map(_ => authActions.getUserInfoAfterLoginAction())));
        this.loginFailure$ = createEffect(() => this.actions$.pipe(ofType(authActions.loginFailureAction), tap(action => this.popupService.showModal({
            heading: 'Error',
            message: action.error.error.message ? action.error.error.message : 'Error connecting to Staffy, please try again in a few minutes.',
            btn: 'Ok',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
        }))), { dispatch: false });
        this.userInfo$ = createEffect(() => this.actions$.pipe(ofType(authActions.getUserInfoAction), exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))), switchMap(({ loader, action }) => this.userService.me().pipe(tap(() => tslib_1.__awaiter(this, void 0, void 0, function* () { return yield this.loadingService.hideLoading(loader); })), map((data) => authActions.getuserInfoSuccessAction({ user: data, redirectLink: action.redirectLink })), catchError(error => of(authActions.getUserInfoFailureAction({ error })))))));
        this.userInfoWoLoader$ = createEffect(() => this.actions$.pipe(ofType(authActions.getUserInfoActionWithoutLoader), switchMap(() => this.userService.me().pipe(map((data) => authActions.getuserInfoSuccessAction({ user: data })), catchError(error => of(authActions.getUserInfoFailureAction({ error })))))));
        this.userInfoSuccess$ = createEffect(() => this.actions$.pipe(ofType(authActions.getuserInfoSuccessAction), tap(action => {
            this.localStorageService.setLoggedInUserInfo(action.user);
            if (action.redirectLink) {
                this.router.navigate([action.redirectLink]);
            }
        })), { dispatch: false });
        this.userInfoAfterCreate$ = createEffect(() => this.actions$.pipe(ofType(authActions.getUserInfoAfterCreateUserAction), exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))), switchMap(({ loader, action }) => this.userService.me().pipe(tap(() => tslib_1.__awaiter(this, void 0, void 0, function* () { return yield this.loadingService.hideLoading(loader); })), map((data) => authActions.getUserInfoAfterLoginSuccessAction({ user: data })), catchError(error => of(authActions.getUserInfoFailureAction({ error })))))));
        this.addPatient$ = createEffect(() => this.actions$.pipe(ofType(authActions.addPatientAction), switchMap((action) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.localStorageService.getLoggedInUserInfo();
            if (user.entityId.homecareClient.isTrue) {
                user.entityId.homecareClient.patients.push(action.patient);
            }
            return authActions.getuserInfoSuccessAction({ user });
        }))));
        this.updatePatient$ = createEffect(() => this.actions$.pipe(ofType(authActions.updatePatientAction), switchMap((action) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.localStorageService.getLoggedInUserInfo();
            if (user.entityId.homecareClient.isTrue) {
                const index = user.entityId.homecareClient.patients.findIndex(p => p._id === action.patient._id);
                if (index !== -1) {
                    user.entityId.homecareClient.patients[index] = action.patient;
                }
            }
            return authActions.getuserInfoSuccessAction({ user });
        }))));
        this.removePatient$ = createEffect(() => this.actions$.pipe(ofType(authActions.removePatientAction), switchMap((action) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.localStorageService.getLoggedInUserInfo();
            if (user.entityId.homecareClient.isTrue) {
                user.entityId.homecareClient.patients = user.entityId.homecareClient.patients.filter(p => p._id !== action.patient._id);
            }
            return authActions.getuserInfoSuccessAction({ user });
        }))));
        this.updateUserPartialState$ = createEffect(() => this.actions$.pipe(ofType(authActions.updateUserPartialState), switchMap((action) => this.store.pipe(select(getUserIdFromInfo), first(), map(userId => ({ action, userId })))), switchMap(({ action, userId }) => this.userService.updateUser(userId, action.partialUserState).pipe(map(() => authActions.updateUserPartialStateSuccess({ partialUserState: action.partialUserState }))))));
        this.updateUserPersonalInfoState$ = createEffect(() => this.actions$.pipe(ofType(authActions.updateUserPersonalInfoState), switchMap((action) => this.store.pipe(select(getUserIdFromInfo), first(), map(userId => ({ action, userId })))), exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of(Object.assign({ loader }, action))))), switchMap(({ action, userId, loader }) => {
            const stateFix = Object.assign({}, action.partialPersonalInfoState);
            if (action.partialPersonalInfoState.address && typeof action.partialPersonalInfoState.address.street !== 'string') {
                stateFix.address = Object.assign({}, action.partialPersonalInfoState.address, { street: action.partialPersonalInfoState.address.street.formatted_address });
            }
            return this.userService.updateUser(userId, action.partialPersonalInfoState).pipe(map(() => authActions.updateUserPartialStateSuccess({ partialUserState: stateFix })), tap((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (action.successRoute) {
                    this.router.navigateByUrl(action.successRoute);
                }
                yield this.loadingService.hideLoading(loader);
            })));
        })));
        // DUPLICATE
        this.userInfoAfterCreateSuccess$ = createEffect(() => this.actions$.pipe(ofType(authActions.getUserInfoAfterCreateUserSuccessAction), tap(action => this.localStorageService.setLoggedInUserInfo(action.user)), tap(action => this.utilService.setTimeZone(action.user.address.province)), tap(action => this.pushNotificationService.initPushNotification(action.user)), tap(action => this.onBoardingUtilService.checkForVouchersAndCode(action.user)), tap(action => this.appInfoService.checkForAppInfo(action.user)), tap(action => {
            action.successRoute && this.router.navigate([action.successRoute]);
        })), { dispatch: false });
        this.getUserInfoAfterLoginAction$ = createEffect(() => this.actions$.pipe(ofType(authActions.getUserInfoAfterLoginAction), exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))), switchMap(({ loader }) => this.userService.me().pipe(tap(() => tslib_1.__awaiter(this, void 0, void 0, function* () { return yield this.loadingService.hideLoading(loader); })), map((data) => authActions.getUserInfoAfterLoginSuccessAction({ user: data })), catchError(error => of(authActions.getUserInfoFailureAction({ error })))))));
        this.getUserInfoAfterLoginSuccessAction$ = createEffect(() => this.actions$.pipe(ofType(authActions.getUserInfoAfterLoginSuccessAction), tap(action => this.localStorageService.setLoggedInUserInfo(action.user)), tap(action => this.appInfoService.checkForAppInfo(action.user)), tap(action => this.pushNotificationService.initPushNotification(action.user)), tap(action => this.utilService.setTimeZone(action.user.address.province)), tap(action => this.appInfoService.checkForAppInfo(action.user)), tap(action => {
            if (action.user.type === UserTypeEnum.EMPLOYER) {
                // Company is Logged In
                if (action.user.resetPasswordToken && action.user.resetPasswordToken.length > 0) {
                    // if resetPasswordToken is present, redirect to set password screen
                    this.router.navigate(['/shared/set-password/' + action.user.resetPasswordToken]);
                }
                else
                    this.router.navigate(['/company-dashboard/home']);
            }
            else {
                this.analyticsService.setUserProperty('user_type', 'Work');
                if (!action.user.isSignupProcessComplete && action.user.currentNavStep) {
                    this.onBoardingUtilService.resolveUrl(action.user);
                }
                else
                    this.router.navigate(['/talent-dashboard/home']);
            }
        })), { dispatch: false });
        this.redirectToLoginActions$ = createEffect(() => this.actions$.pipe(ofType(authActions.redirectToLoginAction), tap(_ => this.router.navigate(['/auth/login']))), { dispatch: false });
        this.redirectToDashboardAction$ = createEffect(() => this.actions$.pipe(ofType(authActions.redirectToDashboardAction), tap(action => {
            if (action.user.type === UserTypeEnum.EMPLOYER) {
                this.router.navigate(['/company-dashboard/home']);
            }
            else {
                if (!action.user.isSignupProcessComplete && action.user.currentNavStep != '/talent-dashboard/home')
                    this.onBoardingUtilService.resolveUrl(action.user);
                else
                    this.router.navigateByUrl('/talent-dashboard/home');
            }
        })), { dispatch: false });
        this.blockUserAction$ = createEffect(() => this.actions$.pipe(ofType(authActions.blockUserAction), exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))), switchMap(({ loader, action }) => this.userService.markBlocked().pipe(tap(() => tslib_1.__awaiter(this, void 0, void 0, function* () { return yield this.loadingService.hideLoading(loader); }), () => tslib_1.__awaiter(this, void 0, void 0, function* () { return yield this.loadingService.hideLoading(loader); })), map(() => authActions.logOutAction())))));
        this.logOutAction$ = createEffect(() => this.actions$.pipe(ofType(authActions.logOutAction), mergeMap(() => [
            talentDashboardActions.resetTalentDashboardStoreAction(),
            authActions.resetStoreAction(),
            authActions.removeDeviceTokenAction(),
            authActions.clearCacheAction()
        ])));
        this.setStoreAction$ = createEffect(() => this.actions$.pipe(ofType(authActions.setStoreAction), tap((action) => {
            if (action.token) {
                this.localStorageService.setLoggedInUserToken(action.token);
            }
            if (action.user) {
                this.localStorageService.setLoggedInUserInfo(action.user);
            }
        })), { dispatch: false });
        this.resetStoreAction$ = createEffect(() => this.actions$.pipe(ofType(authActions.resetStoreAction), exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))), tap(({ loader }) => this.localStorageService.logout()), tap(__ => this.router.navigate(['auth/login'], { replaceUrl: true })), tap(({ loader }) => this.loadingService.hideLoading(loader)), switchMap(() => EMPTY)), { dispatch: false });
        this.removeDeviceTokenAction$ = createEffect(() => this.actions$.pipe(ofType(authActions.removeDeviceTokenAction), tap(action => this.pushNotificationService.removePushNotificationToken())), { dispatch: false });
        this.clearCache$ = createEffect(() => this.actions$.pipe(ofType(authActions.clearCacheAction), tap(action => this.utilService.clearCashedJobs.next('true'))), { dispatch: false });
        this.completedIntroductoryStepsEffect$ = createEffect(() => this.actions$.pipe(ofType(authActions.completedIntroductoryStepsAction), tap(action => this.localStorageService.setSingleKey(LocalStorageKeys.HAS_COMPLETED_INTRODUCTORY_STEPS, true))), { dispatch: false });
    }
}
AuthEffects.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthEffects_Factory() { return new AuthEffects(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.LocalStorageService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.LoadingService), i0.ɵɵinject(i6.OnBoardingUtilService), i0.ɵɵinject(i7.PopupService), i0.ɵɵinject(i8.AuthenticationService), i0.ɵɵinject(i9.PushNotificationService), i0.ɵɵinject(i10.AppInfoService), i0.ɵɵinject(i11.UtilService), i0.ɵɵinject(i12.AnalyticsService), i0.ɵɵinject(i13.Store)); }, token: AuthEffects, providedIn: "root" });
