<div class = "staffer" *ngIf = "user.type == 'staffer'">
  <div *ngIf="!isCordova || isBrowser">
    <div *ngIf="isCamAllowed" class="wrapper" (window:resize)="onResize($event)">
      <webcam [width]="width" [height]="400" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
        [imageQuality]="1" (initError)="handleInitError($event)"></webcam>
    </div>

    <div class = "outer-circle">
      <ion-button *ngIf="isCamAllowed" color="basic" class="snap-btn" (click)="triggerSnapshot();">
      </ion-button>
    </div>
    
    <label class="custom-file-input">
      <i class="fas fa-image"></i>
      <input #imageInput id="chooseFile" type="file" accept="image/*" (change)="processFile(imageInput)">
    </label>
  </div>

  <div *ngIf="isCordova && !isBrowser" class="cordova-wrapper">
    <ion-button expand="full" color="basic" class="snap-btn" (click)="takePhoto('CAMERA')">
      Take Photo
    </ion-button>

    <ion-button expand="full" color="dark" class="snap-btn" (click)="takePhoto('GALLERY')">
      Choose Photo
    </ion-button>
  </div>
</div>

<div class = "employer"  *ngIf = "user.type == 'employer'">
  <div *ngIf="!isCordova || isBrowser">
    <div *ngIf="isCamAllowed" class="wrapper" (window:resize)="onResize($event)">
      <webcam [width]="width" [height]="400" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
        [imageQuality]="1" (initError)="handleInitError($event)"></webcam>
    </div>
    <ion-button *ngIf="isCamAllowed" expand="full" color="basic" class="snap-btn" (click)="triggerSnapshot();">
      {{ 'SHARED_IMAGE_COMPONENT.snapshot' | translate }}
    </ion-button>
  
    <label class="custom-file-upload">
      {{ 'SHARED_IMAGE_COMPONENT.chooseFromSystem' | translate }}
      <input #imageInput id="chooseFile" type="file" accept="image/*" (change)="processFile(imageInput)">
    </label>
  </div>
  
  <div *ngIf="isCordova && !isBrowser" class="cordova-wrapper">
    <ion-button expand="full" color="basic" class="snap-btn" (click)="takePhoto('CAMERA')">
      Take Photo
    </ion-button>
  
    <ion-button expand="full" color="dark" class="snap-btn" (click)="takePhoto('GALLERY')">
      Choose Photo
    </ion-button>
  </div>
</div>