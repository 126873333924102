/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-card-recurring.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../pipes/moment-format.pipe";
import * as i5 from "@angular/common";
import * as i6 from "./notification-card-recurring.component";
import * as i7 from "../../services/util.services";
import * as i8 from "../../services/notification.service";
import * as i9 from "../../services/popup.service";
import * as i10 from "@angular/router";
import * as i11 from "../../services/job.service";
import * as i12 from "../../../talent-dashboard/services/talent-job-checks.service";
import * as i13 from "../../../talent-dashboard/services/talent-job-format.service";
import * as i14 from "@ngrx/store";
import * as i15 from "../../../talent-dashboard/services/talent-dashboard-util.service";
import * as i16 from "../../services/subskill.service";
var styles_NotificationCardRecurringComponent = [i0.styles];
var RenderType_NotificationCardRecurringComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationCardRecurringComponent, data: {} });
export { RenderType_NotificationCardRecurringComponent as RenderType_NotificationCardRecurringComponent };
function View_NotificationCardRecurringComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "button-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.seeDetails() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["See details"]))], null, null); }
export function View_NotificationCardRecurringComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.MomentPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "text-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "general-book orange"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "general-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "general-regular"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCardRecurringComponent_1)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.notification.showPopup; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.notification.created, "MMM DD, YYYY")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.notification.notificationName; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.notification.body; _ck(_v, 9, 0, currVal_2); }); }
export function View_NotificationCardRecurringComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-notification-card-recurring", [], null, null, null, View_NotificationCardRecurringComponent_0, RenderType_NotificationCardRecurringComponent)), i1.ɵdid(1, 638976, null, 0, i6.NotificationCardRecurringComponent, [i7.UtilService, i8.NotificationService, i9.PopupService, i10.Router, i3.AlertController, i11.JobService, i12.TalentJobChecksService, i13.TalentJobFormatService, i14.Store, i15.TalentDashboardUtilService, i16.SubSkillService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationCardRecurringComponentNgFactory = i1.ɵccf("shared-notification-card-recurring", i6.NotificationCardRecurringComponent, View_NotificationCardRecurringComponent_Host_0, { hour24PayAvailableBadge: "hour24PayAvailableBadge", notification: "notification", settings: "settings", userData: "userData" }, { acceptRecurringBroadcastRequest: "acceptRecurringBroadcastRequest", rejectStafferRecurringBroadcastRequest: "rejectStafferRecurringBroadcastRequest", unApplyFromRecurringShift: "unApplyFromRecurringShift", confirmRecurringShift: "confirmRecurringShift", acceptRecurringHireRequest: "acceptRecurringHireRequest", rejectRecurringHireRequest: "rejectRecurringHireRequest", stafferStillWorkingOnRecurringJob: "stafferStillWorkingOnRecurringJob", stopTimerOnRecurringJob: "stopTimerOnRecurringJob", hireStafferForTheRecurringJob: "hireStafferForTheRecurringJob", submitRating: "submitRating", seeAvailableJobs: "seeAvailableJobs", updateUser: "updateUser", hideNotificationDrawer: "hideNotificationDrawer" }, []);
export { NotificationCardRecurringComponentNgFactory as NotificationCardRecurringComponentNgFactory };
