import * as tslib_1 from "tslib";
import { Calendar } from '@ionic-native/calendar/ngx';
import { Platform } from '@ionic/angular';
import * as moment from 'moment-timezone';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/calendar/ngx/index";
import * as i2 from "@ionic/angular";
import * as i3 from "./talent-job-format.service";
export class CalendarService {
    constructor(calendar, platform, talentJobFormatService) {
        this.calendar = calendar;
        this.platform = platform;
        this.talentJobFormatService = talentJobFormatService;
        this.removeDeletedShiftsFromCalendar = (shifts) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!shifts.length) {
                return;
            }
            for (const shift of shifts) {
                try {
                    yield this.calendar.deleteEvent(this.generateTitleForEvent(shift), shift.companyAddress.street, null, moment(shift.shiftStartTime, 'X').toDate(), moment(shift.shiftEndTime, 'X').toDate());
                }
                catch (err) {
                    console.log('Error while deleting shift : ', err);
                }
            }
        });
        this.generateTitleForEvent = (shift) => {
            return `Staffy Shift for ${shift.skill}`;
        };
        this.isDevice = () => {
            const isAndroid = this.platform.is('android');
            const isIos = this.platform.is('ios');
            const isIPad = this.platform.is('ipad');
            if ((isAndroid || isIos || isIPad) && !this.isBrowser) {
                return true;
            }
            return false;
        };
        this.checkCalendarPermission = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const hasReadWritePermission = yield this.calendar.hasReadWritePermission();
            if (!hasReadWritePermission) {
                yield this.calendar.requestReadWritePermission();
            }
        });
        this.getNotes = (shift) => {
            let data = ``;
            if (shift.shiftNotes && shift.shiftNotes.length) {
                data += `Shift Notes: ${shift.shiftNotes}\n`;
            }
            if (shift.unitNumber && shift.unitNumber.length) {
                data += `Unit Number: ${shift.unitNumber}\n`;
            }
            return data;
        };
        this.syncUpcomingShiftsInCalendar = (upcomingShifts) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (upcomingShifts.length === 0) {
                return;
            }
            try {
                console.log('Starting syncing event in calendar');
                // add shifts to calendar
                for (const shift of upcomingShifts) {
                    const event = {
                        title: this.generateTitleForEvent(shift),
                        location: shift.companyAddress.street,
                        notes: this.getNotes(shift),
                        startDate: moment(shift.shiftStartTime, 'X').toDate(),
                        endDate: moment(shift.shiftEndTime, 'X').toDate()
                    };
                    const calendarOptions = {
                        id: shift._id
                    };
                    console.log('Event : ', event);
                    // add the event to the calendar
                    yield this.calendar.createEventWithOptions(event.title, event.location, event.notes, event.startDate, event.endDate, calendarOptions);
                    console.log('Synced Event in Calendar : ', event);
                }
            }
            catch (err) {
                console.log('err while syncing event in calendar', err);
                console.log(err);
            }
        });
        this.isBrowser = this.talentJobFormatService.isBrowserCheck();
    }
    syncShifts(shiftsToDelete, upcomingShifts) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.isDevice()) {
                return;
            }
            try {
                yield this.checkCalendarPermission();
                yield this.removeDeletedShiftsFromCalendar(shiftsToDelete);
                yield this.removeDeletedShiftsFromCalendar(upcomingShifts);
                yield this.syncUpcomingShiftsInCalendar(upcomingShifts);
            }
            catch (err) {
                console.log('Syncing error', err);
            }
        });
    }
}
CalendarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalendarService_Factory() { return new CalendarService(i0.ɵɵinject(i1.Calendar), i0.ɵɵinject(i2.Platform), i0.ɵɵinject(i3.TalentJobFormatService)); }, token: CalendarService, providedIn: "root" });
