import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { Platform } from '@ionic/angular';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { JobService } from 'src/modules/shared/services/job.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';

@Injectable({ providedIn: 'root' })
export class TalentLocationTrackingService {
  constructor(
    private localStorageService: LocalStorageService,
    private platform: Platform,
    private popupService: PopupService,
    private jobService: JobService,
    private loadingService: LoadingService,
    private geolocation: Geolocation,
    private diagnostic: Diagnostic
  ) {}

  // DEPRECATED -- DO NOT CHANGE IT OR WORK ON IT
  async checkIfUserIn100meters(jobId, operation: 'start' | 'end', success, error) {
    const isBrowser = window.location.href.indexOf('app.staffy.com') > -1;
    const isCordova = this.platform.is('cordova');
    const user = await this.localStorageService.getLoggedInUserInfo();

    if (isCordova && !isBrowser && user) {
      const loading = await this.loadingService.showLoading().toPromise();

      this.diagnostic.isLocationEnabled().then(
        isEnabled => {
          if (!isEnabled) {
            this.loadingService.hideLoading(loading);
            this.showLocationPermissionDenialPopup();
            return;
          }
          // location is enabled
          // now try to get user's current position
          this.geolocation
            .getCurrentPosition({
              enableHighAccuracy: true
            })
            .then(
              // got the current position
              async resp => {
                try {
                  // ensuring that the user is within 150 meters of the location
                  await this.jobService.ensureLocation(user._id, jobId, {
                    lat: resp.coords.latitude,
                    lng: resp.coords.longitude,
                    operation
                  });
                  // user is within 150 meters
                  success();
                } catch (err) {
                  // user is not at the location, show error
                  console.log('err', err);
                  error();
                } finally {
                  this.loadingService.hideLoading(loading);
                }
              }
            )
            .catch(error => {
              // unable to retrive the location
              // maybe user denied the permission, so show the permission
              // message
              this.loadingService.hideLoading(loading);
              this.showLocationPermissionDenialPopup(`Please enable location access for staffy`);
              console.log('Error getting location', error);
            });
        },
        err => {
          // location is not enabled or failed to get the location enabled status
          this.loadingService.hideLoading(loading);
          this.showLocationPermissionDenialPopup();
        }
      );
    } else {
      success();
    }
  }

  // This is refactored version,
  async ensureUserIn100meters(jobId, operation: 'start' | 'end'): Promise<Geoposition> {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      const isBrowser = window.location.href.indexOf('app.staffy.com') > -1;
      const isCordova = this.platform.is('cordova');
      const user = await this.localStorageService.getLoggedInUserInfo();

      if (!isCordova && !user) {
        // because we can't determine the location
        throw new Error(`can't determine user's location`);
      }

      await this.ensureLocationIsEnabled();
      // location is enabled
      // now try to get user's current position
      const userLocation = await this.getUserLocation();
      // got the user location
      await this.ensureUserIsOnSite(user, jobId, userLocation, operation);
      // user is on site
      return userLocation;
    } catch (e) {
      console.log('error in checking user location', e);
      // rethrow the error
      throw e;
    } finally {
      // hide the loader
      this.loadingService.hideLoading(loading);
    }
  }

  showLocationPermissionDenialPopup(message?: string) {
    this.popupService.showModal(
      {
        heading: 'Location Access',
        message: message || `You must enable your location to proceed`,
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      },
      () => {
        this.diagnostic.switchToLocationSettings();
      }
    );
  }

  async ensureLocationIsEnabled() {
    try {
      const isEnabled = await this.diagnostic.isLocationEnabled();
      if (!isEnabled) {
        this.showLocationPermissionDenialPopup();
        throw new Error('Location is not enabled');
      }
    } catch (error) {
      console.log('Error getting location settings', error);
      this.showLocationPermissionDenialPopup();
      throw new Error('Error in getting location settings');
    }

    return;
  }

  async getUserLocation() {
    try {
      const userLocation = await this.geolocation.getCurrentPosition({
        enableHighAccuracy: true
      });
      return userLocation;
    } catch (e) {
      this.showLocationPermissionDenialPopup();
      throw new Error('Error in getting location');
    }
  }

  private async ensureUserIsOnSite(user, jobId, userLocation, operation) {
    // ensuring that the user is within 150 meters of the location
    try {
      await this.jobService.ensureLocation(user._id, jobId, {
        lat: userLocation.coords.latitude,
        lng: userLocation.coords.longitude,
        operation
      });
      // user is within 150 meters
    } catch (err) {
      this.showInAccurateLocationPopup();
      throw new Error('User is not within 150meters');
    }
  }

  private showInAccurateLocationPopup() {
    this.popupService.showModal({
      heading: 'Sorry',
      message: `You can only start this shift within 150 metres of the location.`,
      btn: 'Dismiss',
      navigateRoute: null,
      imgURL: 'assets/images/sorry1.png'
    });
  }
}
