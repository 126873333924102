import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
export class ConfigService {
    constructor() {
        this.defaultApiUrl = environment.API_BASE_URL; // Store the default URL
        this.defaultApiUrlCompanies = environment.API_BASE_URL_COMPANIES; // store the default url companies
        this.defaultStripe = environment.STRIPE_API_KEY; // Store the default STRIPE KEY
        this.apiUrlSubject = new BehaviorSubject(localStorage.getItem('API_BASE_URL') || this.defaultApiUrl);
        this.apiUrlCompaniesSubject = new BehaviorSubject(localStorage.getItem('API_BASE_URL_COMPANIES') || this.defaultApiUrlCompanies);
        this.stripeKeySubject = new BehaviorSubject(localStorage.getItem('STRIPE_API_KEY') || this.defaultStripe);
        this.apiUrl$ = this.apiUrlSubject.asObservable();
        this.apiUrlCompanies$ = this.apiUrlCompaniesSubject.asObservable();
        this.stripeKey$ = this.stripeKeySubject.asObservable();
    }
    setApiUrlWithStripeKey(url, compUrl, stripeKey) {
        this.apiUrlSubject.next(url);
        this.stripeKeySubject.next(stripeKey);
        environment.API_BASE_URL = url;
        environment.API_BASE_URL_COMPANIES = compUrl;
        environment.STRIPE_API_KEY = stripeKey;
        localStorage.setItem('API_BASE_URL', url);
        localStorage.setItem('API_BASE_URL_COMPANIES', compUrl);
        localStorage.setItem('STRIPE_API_KEY', stripeKey);
    }
    revertToDefault() {
        this.setApiUrlWithStripeKey(this.defaultApiUrl, this.defaultApiUrlCompanies, this.defaultStripe);
    }
    isDemoMode() {
        return this.getApiUrl() !== this.defaultApiUrl;
    }
    getApiUrl() {
        return this.apiUrlSubject.value;
    }
    getApiUrlCompanies() {
        return this.apiUrlCompaniesSubject.value;
    }
    getStripeKey() {
        return this.stripeKeySubject.value;
    }
}
ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(); }, token: ConfigService, providedIn: "root" });
