import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Platform } from '@ionic/angular';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { HttpPromiseService } from './http-promise.service';
import { UserTypeEnum } from 'src/modules/authentication/enums/user-type.enum';
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {
    OneSignal;

    constructor(
        private localStorageService: LocalStorageService,
        private platform: Platform,
        private httpPromiseService: HttpPromiseService,
        private router: Router,
        private userService: UserService,

    ) { }


    initPushNotification(currentUser: IUser) {
        if (!currentUser) { return; }
        if (this.isMobileDevice()) {
            this.initPushwoosh(currentUser);
        } else {
            if (!this.OneSignal && window.location.href.indexOf('localhost') < 1) { // if not initilized before and not localhost
                this.addScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js', (callback) => {
                    this.initOneSignal(currentUser);
                });
            }


        }

    }

    isMobileDevice() {
        return this.platform.is('cordova');
    }

    initPushwoosh(currentUser: IUser) {
        const pushNotification = (window as any).plugins.pushNotification;

        // set push notifications handler
        document.addEventListener('push-notification',
            (event) => {
                const message = (event as any).notification.message;
                const userData = (event as any).notification.userdata;

                // dump custom data to the console if it exists
                if (typeof (userData) !== 'undefined') {
                    const notificationId = userData.notificationId;
                    if (notificationId) {
                        this.navigateToSingleNotificationView(notificationId);
                    }
                }

            }
        );

        document.addEventListener('push-receive',
            (event) => {
                const message = (event as any).notification.message;
                const userData = (event as any).notification.userdata;
             }
        );

        pushNotification.onDeviceReady({
            appid: '72DD2-BE47E',
            projectid: '423542432527',
        });

        // register for push notifications
        const app = this;
        pushNotification.registerDevice(
            async (status) => {
                const pushToken = status.pushToken;
                console.log('PUSH TOKEN', pushToken);
                this.localStorageService.setSingleKey('deviceToken', pushToken);

                pushNotification.setMultiNotificationMode();

                pushNotification.setTags({
                    userInfo: [currentUser.type, currentUser.address.city]
                },
                    (successTag) => {
                        console.log(currentUser.type, currentUser.address.city);
                        console.log('setTags success');
                    },
                    (errorTag) => {
                        console.log('setTags failed');
                    });
                try {
                    await this.httpPromiseService.httpPostRequest(
                        `/api/users/token`, { token: pushToken }
                    );
                } catch (error) {
                    console.log('err updating token to server', error);
                }

            },
            (status) => {
                console.log('failed to register: ' + status);
            }
        );
    }

    async navigateToSingleNotificationView(notificationId: string) {
        const currentUser: IUser = await this.localStorageService.getLoggedInUserInfo();
        if (currentUser) {
            if (currentUser.type === UserTypeEnum.STAFFER) {
                this.router.navigateByUrl(`talent-dashboard/notifications?id=${notificationId}`);

            } else if (currentUser.type === UserTypeEnum.EMPLOYER) {
                this.router.navigateByUrl(`company-dashboard/notifications?id=${notificationId}`);
            }
        }



    }

    async removePushNotificationToken() {
        const token = await this.localStorageService.getSingleKey('deviceToken');

        if (!token) { return; }

        try {
            await this.httpPromiseService.httpPostRequest(
                `/api/users/rtoken`, { token }
            );
        } catch (error) {
            console.log('err updating token to server', error);
        }


    }
    ///////////////////////////////////////////////////////// desktop

    addScript(fileSrc, callback) {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = callback;
        script.src = fileSrc;
        head.appendChild(script);
    }

    initOneSignal(currentUser) {
        this.OneSignal = (window as any).OneSignal || [];

        console.log('Init OneSignal');
        this.OneSignal.push(() => {
            // If we're on an unsupported browser, do nothing
            if (!this.OneSignal.isPushNotificationsSupported()) {
                console.log('not push notification supported');
                return;
            }

            this.OneSignal.init({
                appId: 'ea51ea11-80eb-4037-9e96-da341a61844b', // v2 - ea51ea11-80eb-4037-9e96-da341a61844b
                // "f5adee87-116c-46c7-a343-046f40230c13",//"995cc7c7-e69a-41a0-9ed0-88f7390514c8", // PRODUCTION
                autoResubscribe: true,

                subdomainName: 'app', // "STAFFY",
                // /* The label for your site that you added in Site Setup mylabel.os.tc */
                welcomeNotification: {
                    disable: true,
                    title: 'STAFFY',
                    message: 'Thanks for subscribing to STAFFY',
                    // url: '/#/nav/notifications/' // https://www.staffy.ca/www/#/nav/
                    /* Leave commented for the notification to not open a window on Chrome and
                     Firefox (on Safari, it opens to your webpage) */
                },
                persistNotification: true, // Automatically dismiss the notification after ~20 seconds in non-mobile Chrome
                notifyButton: {
                    enable: true,
                    position: 'bottom-left',
                    size: 'medium'
                },
                allowLocalhostAsSecureOrigin: true,
            });
        });
        this.checkIfSubscribed(currentUser);
    }



    checkIfSubscribed(currentUser: IUser) {

        this.OneSignal.getSubscription((state) => {
            console.log('state', state);
            if (state && currentUser._id) {
                console.log('get user onesignal id');
                this.OneSignal.getUserId(async (id) => {
                    console.log('got user onesignal id');
                    try {
                        await this.userService.updateUser(currentUser._id, { desktopTokenId: id });
                    } catch (error) { console.log(error); }
                });
            }
        });
        // On change event for OneSignal for subscription
        this.OneSignal.on('subscriptionChange', async (isSubscribed) => {
            if (currentUser._id) {
                if (isSubscribed) {
                    console.log('subs');

                    this.OneSignal.getUserId(async (id) => {
                        try {
                            await this.userService.updateUser(currentUser._id, { desktopTokenId: id });
                        } catch (error) { console.log(error); }

                    });
                } else {
                    console.log('not subs');
                    try {
                        await this.userService.updateUser(currentUser._id, { desktopTokenId: '' });
                    } catch (error) { console.log(error); }

                }
            }
        });
    }

}
