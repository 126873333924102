/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-radio-buttons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./form-radio-buttons.component";
var styles_FormRadioButtonsComponent = [i0.styles];
var RenderType_FormRadioButtonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormRadioButtonsComponent, data: {} });
export { RenderType_FormRadioButtonsComponent as RenderType_FormRadioButtonsComponent };
function View_FormRadioButtonsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-item", [["class", "hide-border-bottom"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-radio", [], null, [[null, "ionBlur"], [null, "ionSelect"]], function (_v, en, $event) { var ad = true; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionSelect" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleIonSelect($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_IonRadio_0, i2.RenderType_IonRadio)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.RadioValueAccessor]), i1.ɵdid(4, 49152, null, 0, i3.IonRadio, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { value: [0, "value"] }, null), i1.ɵdid(5, 16384, null, 0, i3.RadioValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "ion-label", [["class", "small-font non-italic-label"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(7, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(8, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.label; _ck(_v, 8, 0, currVal_1); }); }
export function View_FormRadioButtonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "reusable-form-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-label", [["class", "label"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(2, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(3, 0, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "ion-radio-group", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.selectedOption = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ionChange" === en)) {
        var pd_3 = (_co.handleChange(_co.selectedOption) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_IonRadioGroup_0, i2.RenderType_IonRadioGroup)), i1.ɵdid(5, 16384, null, 0, i3.SelectValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectValueAccessor]), i1.ɵdid(7, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(9, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(10, 49152, null, 0, i3.IonRadioGroup, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FormRadioButtonsComponent_1)), i1.ɵdid(12, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.selectedOption; _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.options; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labelText; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_FormRadioButtonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-radio-buttons", [], null, null, null, View_FormRadioButtonsComponent_0, RenderType_FormRadioButtonsComponent)), i1.ɵdid(1, 114688, null, 0, i6.FormRadioButtonsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormRadioButtonsComponentNgFactory = i1.ɵccf("app-form-radio-buttons", i6.FormRadioButtonsComponent, View_FormRadioButtonsComponent_Host_0, { labelText: "labelText", options: "options" }, { onChange: "onChange" }, []);
export { FormRadioButtonsComponentNgFactory as FormRadioButtonsComponentNgFactory };
