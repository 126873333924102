<ion-list>

  <ion-item (click)="selected('ca')">
    <ion-thumbnail slot="start">
      <img src="assets/images/ca-flag.png">
    </ion-thumbnail>
    <ion-label>Canda +1</ion-label>
  </ion-item>
  <ion-item (click)="selected('usa')">
    <ion-thumbnail slot="start">
      <img src="assets/images/usa-flag.png">
    </ion-thumbnail>
    <ion-label>USA +1</ion-label>
  </ion-item>
</ion-list>