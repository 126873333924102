import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
export class LocalStorageService {
    constructor(storage) {
        this.storage = storage;
    }
    setLoggedInUserToken(accessToken) {
        this.storage.set('token', accessToken);
    }
    getLanguage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('language')) || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    setBio(bio) {
        this.storage.set('bio', bio);
    }
    getBio() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('bio')) || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    setLanguage(lng) {
        this.storage.set('language', lng);
    }
    getExpertise() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return this.storage.get('expertise') || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    setExpertise(expertise) {
        this.storage.set('expertise', expertise);
    }
    getApparel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('apparel')) || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    setApparel(apparel) {
        this.storage.set('apparel', apparel);
    }
    setLoggedInUserInfo(user) {
        this.storage.set('user', user);
    }
    removeLoggedInUserInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return (yield this.storage.get('user')) || null;
        });
    }
    storeUserSetting(setting) {
        this.storage.set('setting', setting);
    }
    setRemoveReferralBannerPopup(hideReferralBanner) {
        this.storage.set('hideReferralBanner', hideReferralBanner);
    }
    getRemoveReferralBannerPopup() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return !!(yield this.storage.get('hideReferralBanner'));
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    setShiftInProgress(shiftInProgress) {
        this.storage.set('shiftInProgress', shiftInProgress);
    }
    getShiftInProgress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return !!(yield this.storage.get('shiftInProgress'));
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    setLocalCount(localCount) {
        this.storage.set('localCount', localCount);
    }
    getLocalCount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return !!(yield this.storage.get('localCount'));
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    getSetiings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('setting')) || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    setUserTimeZoneAndCity(timezone) {
        this.storage.set('city', timezone);
        this.storage.set('timezone', timezone);
    }
    setTalentUpcomingShifts(upcomingShifts) {
        this.storage.set('upcomingShifts', upcomingShifts);
    }
    setCompletionStatusOfOnboarding(onboardingStep) {
        this.storage.set('onboardingStep', onboardingStep);
    }
    getCompletionStatusOfOnboarding() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('onboardingStep')) || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    setSignupCode(signupCode) {
        this.storage.set('signupCode', signupCode);
    }
    getSignupCode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('signupCode')) || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    getLoggedInUserToken() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('token')) || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    getLoggedInUserInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('user')) || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    getUserTimeZone() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('timezone')) || 'America/Toronto';
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    getTalentUpcomingShifts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('upcomingShifts')) || [];
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    getVoucherCode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('voucher')) || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    removeSingleKey(key) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.storage.remove(key);
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    getSingleKey(key) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get(key)) || null;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    setSingleKey(key, value) {
        this.storage.set(key, value);
    }
    setFAQs(faqs) {
        this.storage.set('faqs', faqs);
    }
    getFAQs() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return (yield this.storage.get('faqs')) || [];
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                console.log(this.storage);
                yield this.storage.clear();
                yield localStorage.setItem("filterOutClients", JSON.stringify([]));
            }
            catch (e) {
                console.log(e);
            }
        });
    }
}
