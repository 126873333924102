import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ITalentJob } from '../interfaces/talent-job.interface';
import { TalentDashboardUtilService } from './talent-dashboard-util.service';
import { UtilService } from '../../shared/services/util.services';
import { ImagesService } from '../../shared/services/images.service';
import { findIndex, forEach, orderBy, uniq } from 'lodash';
import { RecurringShiftsTimingComponent } from 'src/modules/shared/components/recurring-shifts-timing/recurring-shifts-timing.component';
import { ModalController } from '@ionic/angular';
import { IClientJob } from 'src/modules/company-dashboard/interfaces/client-job.interface';
import { IClientShift } from 'src/modules/company-dashboard/interfaces/clientShift.interface';
import * as _ from 'lodash';
import { CalendarFormattingService } from 'src/modules/shared/services/calendar-formatting.service';

@Injectable({
  providedIn: 'root'
})
export class TalentJobFormatService {

  constructor(
    private talentDashboardUtilService: TalentDashboardUtilService,
    private utilService: UtilService,
    private modalController: ModalController,
    private imagesService: ImagesService,
    private calendarFormattingService: CalendarFormattingService
  ) { }

  formatAvailableShifts(availableShifts: ITalentJob[], userData ): any[] {

    return availableShifts.map((jobItem, i) => {

      const formattedJobItem: any = {...jobItem};

      formattedJobItem.shiftStartTimeFormatted = moment
      .unix(jobItem.shiftStartTime)
      .tz(jobItem.timeZone)
      .format('MMM DD,YYYY');

      formattedJobItem.profilePicture = this.getProfilePic(formattedJobItem.entity.creator.profileUrl);

      formattedJobItem.potentialEarning = this.talentDashboardUtilService.getcalculatedPotentialEarning(formattedJobItem);

      formattedJobItem.proximity = this.utilService.calculateDistanceFromShift(jobItem.companyAddress, userData.address);

      formattedJobItem.startTime = this.talentDashboardUtilService
      .optimizeTime(jobItem.shiftStartTime);

      formattedJobItem.unitNumber = jobItem.unitNumber;
        
      if (formattedJobItem.recurringShift.isTrue == true) {  // recurring jobs
        formattedJobItem.potentialEarning = this.evaluatePotentialEarning(jobItem); 

        formattedJobItem.endTime = this.talentDashboardUtilService
                          .optimizeTime(jobItem.jobs[jobItem.jobs.length - 1].shiftEndTime);

        formattedJobItem.dateRange = this.getDateRange(jobItem);

        formattedJobItem.isStatPay = this.utilService.isStatPayAvailable(jobItem.jobs);

        formattedJobItem.formattedRate = jobItem.initialStafferRate ? jobItem.initialStafferRate : (jobItem.initialRate ? jobItem.initialRate : (jobItem.stafferRate ? jobItem.stafferRate : jobItem.rate));

        formattedJobItem.durationRequired = jobItem.jobs.length + ' shifts';

        if(jobItem.jobs.length == 1)
          formattedJobItem.durationRequired = formattedJobItem.durationRequired.replace('shifts', 'shift')
        
        formattedJobItem.jobPostedDuration = this.getJobPostedDuration(jobItem);

        formattedJobItem.similarTimings = this.checkSimilarTimings(jobItem);
        //Check if all recurring shifts have similar unit number
        formattedJobItem.similarUnitNumbers = this.similarUnitNumbers(jobItem);
        //If recurring shifts have similar unit number assign job.length unit number to variable 
        
        if(formattedJobItem.similarUnitNumbers) {
          formattedJobItem.unitNumber = jobItem.jobs[jobItem.jobs.length - 1].unitNumber;
        }

        if( jobItem.jobs[0].break)
          formattedJobItem.unpaidBreak =  jobItem.jobs[0].break.unpaid || 'No';
      } else {
        
        formattedJobItem.similarTimings = true;
        formattedJobItem.endTime = this.talentDashboardUtilService
                          .optimizeTime(jobItem.shiftEndTime);
        formattedJobItem.isStatPay = jobItem.isStatPay ? jobItem.isStatPay : undefined;
        formattedJobItem.formattedRate = jobItem.stafferRate ? jobItem.stafferRate : jobItem.rate;
        formattedJobItem.durationRequired = this.getJobDuration(moment.unix(formattedJobItem.shiftStartTime).tz('America/Toronto'), moment.unix(formattedJobItem.shiftEndTime).tz('America/Toronto'))
        formattedJobItem.jobPostedDuration = this.getJobPostedDuration(jobItem);
        
        if(jobItem.break)
          formattedJobItem.unpaidBreak = jobItem.break.unpaid || 'No';
      }
      return formattedJobItem;

    });

  }


  evaluatePotentialEarning(jobItem: ITalentJob) {
    let potentialEarning = 0;
    forEach(jobItem.jobs, (job: ITalentJob) => {
      
      //Adding missing properties required for calculating Potentail Earning of every recurring Job
      //as the jobs array is only projected with shift start time and shift end time
      job.break =  job.break || jobItem.break || {};
      job.recurringShift =  jobItem.recurringShift || null;
      job.initialStafferRate = jobItem.initialStafferRate;
      job.stafferRate = jobItem.stafferRate;
      job.rate = jobItem.rate;
      job.travelTime = jobItem.travelTime;
      job.serviceFeePercentageRate = jobItem.serviceFeePercentageRate

      potentialEarning += this.talentDashboardUtilService.getcalculatedPotentialEarning(job);
    })

    return potentialEarning.toFixed(2);
  }
  
  checkSimilarTimings(jobItem) {
    if(!jobItem || !jobItem.length) {
      return;
    }

    const firstShiftStartTimings = jobItem.jobs[0].shiftStartTime;
    const firstShiftEndTimings = jobItem.jobs[0].shiftEndTime;
    const timeZone = jobItem.jobs[0].timeZone;
    let similarTimings = true;

    const jobShiftTime = jobItem.jobs.map((job) => {
      if (moment.unix(firstShiftStartTimings)
        .tz(timeZone).format('hh:mm a')
        !== moment.unix(job.shiftStartTime).tz(timeZone).format('hh:mm a') && moment.unix(firstShiftEndTimings).format('hh:mm a')
        !== moment.unix(job.shiftEndTime).tz(timeZone).format('hh:mm a')) 
         similarTimings = false;
    });

    return similarTimings;
  }


  similarUnitNumbers(jobItem: ITalentJob): boolean {
	//assign first unit number of recurring shift for comparison
    const firstUnitNumber = jobItem.jobs[0].unitNumber;
    let similarUnit = true;

    jobItem.jobs.forEach((job) => {
	//if any uit number mismatch the first assigned unit number, mark similarUnit as false
	  if (firstUnitNumber !== job.unitNumber) 
         similarUnit = false;
    });

    return similarUnit;
    
  } 
  getJobPostedDuration(job) {

    const postedOn = moment(job.created).tz('America/Toronto');
    
    const currentDate = moment.tz('America/Toronto');
    const difference = postedOn.diff(currentDate);

    let result = moment.duration(difference).humanize();
    if(!result.includes('ago'))
      result += ' ago';

    return result;  
  }


  getJobDuration(start, end) {
    
    const validForUnit = moment.duration(end.diff(start))
    if(validForUnit.asDays() == 1) {
      return validForUnit.asDays().toFixed(0) + ' ' + 'day shift'
    }

    if(validForUnit.asDays() < 1) {
      return (Math.abs(validForUnit.asHours()).toFixed(1)) + ' ' + 'hour shift'; 
    }

    if(validForUnit.asDays() > 1) {
      return (Math.abs(validForUnit.asDays()).toFixed(1)) + ' ' + 'days shift'; 
    }
  }
  
  getProfilePic(profileUrl: { [key: string]: string }) {
    return this.imagesService.getProfilePic(profileUrl, 'w_300');
  }

  // TODO break this function in 2
  // one for checking similarTimings
  // one for getting formatted date range
  getDateRange(jobItem: any) {

    let formatted: string;
    const firstShiftStartTimings = jobItem.shiftStartTime;
    const firstShiftEndTimings = jobItem.shiftEndTime;
    const timeZone = jobItem.timeZone;
    jobItem.similarTimings = true;

    if(jobItem.recurringShift && jobItem.recurringShift.recurrentShiftTemplate && jobItem.recurringShift.recurrentShiftTemplate.length) {
      formatted = this.utilService.formatRecurrentShiftDates(jobItem.recurringShift.recurrentShiftTemplate[0]);
      return formatted;
    }


    const jobShiftTime = jobItem.jobs.map((job) => {

      if (moment.unix(firstShiftStartTimings)
        .tz(timeZone).format('hh:mm a')
        !== moment.unix(job.shiftStartTime).tz(timeZone).format('hh:mm a')
        && moment.unix(firstShiftEndTimings).format('hh:mm a')
        !== moment.unix(job.shiftEndTime).tz(timeZone).format('hh:mm a')) {
          // converting start time of all shifts in hh:mm a format
          // and comparing it with first Shift start time
          // if there is any mismatch, it  means the timings are not
          // similar accross all shifts
        jobItem.similarTimings = false;
      }
      return job.shiftStartTime;
    });

    if (jobShiftTime.length !== 0) {
      formatted = this.utilService.stringifyRecurringShiftsForClient(jobItem.jobs[0].timeZone, jobShiftTime);
    }
    return formatted;
  }

  setMissingProperties(jobItem: any, index, hour24PayAvailableBadge) {
    jobItem.isStaffyPayAvailable =  (!jobItem.entity.creator.customer.status 
      && jobItem.entity.creator.customer.hasCreditCard
      && hour24PayAvailableBadge) || jobItem.entity.creator.force24HourPayAvailableBadge || jobItem.is24HoursBadge;
    
    jobItem.index = index;

    return jobItem;
  }

  humanizeShiftTime(shiftStartTime: number, isJobStarted: boolean) {
    let time = '';
    
    const timeDifference = this.getTimeDifference(shiftStartTime);
    if(isJobStarted) {
      return 'In Progress';
    } else if(timeDifference < 0) {
      time += 'in ' + moment.duration(timeDifference).humanize();
      return time;
    } else {
      return 'Now';
    }
  }

  getTimeDifference(shiftStartTime: number) {
    const currentTime = +moment().format('X');
    const timeDifference = moment.unix(currentTime).diff(moment.unix(shiftStartTime));
    return timeDifference;
  }
  
  formatDuration(duration: string) {
    if(duration)
      return duration.substr(0, duration.indexOf(' needed'));
  }

  isJobRated(job: ITalentJob, userData) {
    const currentUserIndex = findIndex(job.staffersAndShifts, ['staffer', userData._id]);
    const currentUser = job.staffersAndShifts[currentUserIndex];
    return currentUser.comments.entity.rating ? currentUser.comments.entity.rating : undefined;
  }


  getTotalJobHoursNeeded(shiftStartTime: number, shiftEndTime: number) {
    const startTime = moment.unix(shiftStartTime);
    const endTime = moment.unix(shiftEndTime);
    const diff = moment.duration(endTime.diff(startTime));
    return diff;
  }

  calculateJobProgress(worked, total) {
    let percentage = (worked / total) * 100
    return percentage;
  }

  getJobDescription(invoice) {
    return invoice.jobs[0].staffers[0].staffer.firstName + ' ' 
    + invoice.jobs[0].staffers[0].staffer.lastName
    + ' as ' 
    +  invoice.jobs[0].skill 
    + ' @ '
    + invoice.entity.name + ' '  
    + this.formatDate(invoice.jobs[0].job.shiftStartTime, 'dddd MMMM Do', invoice.jobs[0].job.timeZone) 
    + ' (' 
    + this.formatDate(invoice.jobs[0].job.shiftStartTime, 'hh:mma', invoice.jobs[0].job.timeZone) 
    + ' - ' 
    + this.formatDate(invoice.jobs[0].job.shiftEndTime, 'hh:mma', invoice.jobs[0].job.timeZone) 
    + ') ' 
    + '$' 
    + invoice.jobs[0].rate  
    + '/hr'
  }

  formatDate(value, format, tz?) {
    if (tz)
          return moment.unix(+value).tz(tz).format(format);
      return moment.unix(+value).format(format);
  }

  formatTrackedHours(timeTracked: string) {
    const [hours,minutes] = timeTracked.split(':');
    return hours + 'H ' + minutes + 'M';
  }

  isBrowserCheck () {
    return window.location.href.indexOf('app.staffy.com') > -1 ||
      window.location.href.indexOf('staffy-6c0f4.firebaseapp.com') > -1;
  }

  sortByJobPostedDuration(job){

    const sortedArray = orderBy(job, (o: any) => {
      return moment(o.created).format('X')
    }, ['desc']);

    return sortedArray;
  }

async viewRecurringProperties(jobItem: (ITalentJob | IClientJob | Object), heading: string, showUnitNumber: boolean, unitNumber? : string[]) {
	//popup to view different shift timings and unit numbers
    
  const myModal = await this.modalController.create({
      component: RecurringShiftsTimingComponent,
      backdropDismiss: false,
      cssClass: 'wideModal',
      componentProps: {
        jobItem,
        heading,
        showUnitNumber,
        unitNumber
      }
    }); 
    return await myModal.present();
}



unitNumberByShift(jobItem: IClientJob) {
  let unitNumber = ''; 

  //If Shift is recurring fetch unit numbers from jobs array
  if(jobItem.from && jobItem.to) {
    //If any unit number exists in recurring job, return the unit number else return empty string
    const jobUnitNumber = jobItem.jobs.find(o => o.unitNumber !== undefined) || '';
  
    if(jobUnitNumber) {
      unitNumber = jobUnitNumber.unitNumber;
    }
  } else {
      unitNumber = jobItem.unitNumber || '';
  } 
  return unitNumber;
}

  formatShiftRate(jobItem: ITalentJob | IClientJob | IClientShift, isRecurring: boolean): Number {
    
    if(!isRecurring) {
      return jobItem.stafferRate ? jobItem.stafferRate : jobItem.rate;
    }

    return jobItem.initialStafferRate ? jobItem.initialStafferRate : (jobItem.initialRate ? jobItem.initialRate : (jobItem.stafferRate ? jobItem.stafferRate : jobItem.rate));
  }

  formatGroupedClientShifts(jobs: IClientShift[]) {
    let formattedShifts = [];
    jobs.forEach((jobItem) => {
        
      if (jobItem.recurringShift.isTrue && jobItem.jobs && jobItem.jobs.length) {
        jobItem.jobs.forEach(job => {
          let formattedJobItem: any = {...jobItem}; 
          
          formattedJobItem.shiftStartTimeFormatted = moment
          .unix(job.shiftStartTime)
          .tz(job.timeZone)
          .format('MMM DD,YYYY');

        
        formattedJobItem.shiftStartTime = job.shiftStartTime;
        formattedJobItem.shiftEndTime = job.shiftEndTime;

        formattedJobItem.startTime = this.talentDashboardUtilService
          .optimizeTime(job.shiftStartTime);

        formattedJobItem.shiftStartTimeFormatted = moment
        .unix(jobItem.shiftStartTime)
        .tz(jobItem.timeZone)
        .format('MMM DD,YYYY');

        formattedJobItem.endTime = this.talentDashboardUtilService.optimizeTime(job.shiftEndTime);
        formattedJobItem.dateRange = this.getDateRange(jobItem);
        formattedJobItem.formattedRate = this.formatShiftRate(jobItem, true);
        formattedJobItem.similarTimings = this.checkSimilarTimings(jobItem);
        const isPreviousShift = moment.unix(jobItem.shiftStartTime).tz(jobItem.timeZone).isBefore(moment().tz(jobItem.timeZone));
        formattedJobItem.jobStatus = this.calendarFormattingService.evaluateJobStatus(jobItem);
        formattedJobItem.className = this.calendarFormattingService.eventStyleByJobStatus(formattedJobItem.jobStatus, isPreviousShift)
        formattedShifts.push(formattedJobItem);
        }) 

      } else {
        let formattedJobItem: any = {...jobItem}; // Create a new object for each iteration

        formattedJobItem.shiftStartTimeFormatted = moment
          .unix(jobItem.shiftStartTime)
          .tz(jobItem.timeZone)
          .format('MMM DD,YYYY');

        
        formattedJobItem.startTime = this.talentDashboardUtilService
          .optimizeTime(jobItem.shiftStartTime);
  
        formattedJobItem.similarTimings = true;
        formattedJobItem.endTime = this.talentDashboardUtilService.optimizeTime(jobItem.shiftEndTime);
        formattedJobItem.formattedRate = this.formatShiftRate(jobItem, false);
        const isPreviousShift = moment.unix(jobItem.shiftStartTime).tz(jobItem.timeZone).isBefore(moment().tz(jobItem.timeZone));
        formattedJobItem.jobStatus = this.calendarFormattingService.evaluateJobStatus(jobItem);
        formattedJobItem.className = this.calendarFormattingService.eventStyleByJobStatus(formattedJobItem.jobStatus, isPreviousShift)
        formattedShifts.push(formattedJobItem);
      }
    })

    formattedShifts = _.sortBy(formattedShifts, 'shiftStartTime');   
    return formattedShifts;
  }

groupClientShiftsByDuration(jobItem: IClientShift[]) : {
  inMonth : Array<IClientShift>,
  inWeek : Array<IClientShift>,
  today :  Array<IClientShift>
} {

  let jobItems = [];
  //Check if shifts have same timings, if yes mark similarTimings as true and show time only, else show a popup for multiple timings
  jobItems = this.formatGroupedClientShifts(jobItem);

  //Group jobs by Date
  const timeZone = 'America/Toronto';
  const today = moment.tz(timeZone).startOf('day');
  const startOfNextWeek = moment.tz(timeZone).add(1, 'week').startOf('week');

  const groupedJobs = _.groupBy(jobItems, (job) => {
    const jobDate = moment.tz(job.shiftStartTime * 1000, timeZone).startOf('day');

    if (jobDate.isBefore(today)) {
      return 'old-data';
    } else if (jobDate.isSame(today, 'day')) {
      return 'today';
    } else if (jobDate.isAfter(today, 'day') && jobDate.isBefore(startOfNextWeek, 'day')) {
      return 'inWeek';
    } else {
      return 'inMonth';
    }
    });

    return {
      today : groupedJobs.today || [],
      inWeek : groupedJobs.inWeek || [],
      inMonth : groupedJobs.inMonth || []
    };
  }
}