import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/http-promise.service";
import * as i2 from "@ionic-native/in-app-browser/ngx/index";
export class HelpService {
    constructor(httpPromiseService, inAppBrowser) {
        this.httpPromiseService = httpPromiseService;
        this.inAppBrowser = inAppBrowser;
    }
    sendContactUsEmail(body) {
        return this.httpPromiseService.httpPostRequest(`/api/appInfo/sendContactUsEmail`, body);
    }
    sendTestPushNotification(userId) {
        return this.httpPromiseService.httpPostRequest(`/api/appInfo/testPushNotification/${userId}`, {});
    }
    openAppBrowserLink(url) {
        var target = "_blank";
        var options = "location=yes";
        let popup = this.inAppBrowser.create(url, target, options);
        popup.show();
    }
}
HelpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelpService_Factory() { return new HelpService(i0.ɵɵinject(i1.HttpPromiseService), i0.ɵɵinject(i2.InAppBrowser)); }, token: HelpService, providedIn: "root" });
