import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { ImagesService } from 'src/modules/shared/services/images.service';
import * as moment from 'moment-timezone';
import { TalentDashboardUtilService } from '../../services/talent-dashboard-util.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { TalentJobFormatService } from '../../services/talent-job-format.service';
import { findIndex } from 'lodash';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { TalentCovidAssessmentService } from '../../services/talent-covid-assessment.service';
import { ModalController } from '@ionic/angular';
import { ShiftDetailsModalComponent } from '../shift-details-modal/shift-details-modal.component';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { JobService } from 'src/modules/shared/services/job.service';
import { Location } from '@angular/common';
import { RecurrentShiftService } from 'src/modules/shared/services/recurrentShift.service';
export class UpcomingShiftCardComponent {
    constructor(imagesService, utilService, talentDashboardUtilService, talentJobFormatService, popupService, talentCovidAssessmentService, modalController, loadingService, jobService, location, recurrentShiftService) {
        this.imagesService = imagesService;
        this.utilService = utilService;
        this.talentDashboardUtilService = talentDashboardUtilService;
        this.talentJobFormatService = talentJobFormatService;
        this.popupService = popupService;
        this.talentCovidAssessmentService = talentCovidAssessmentService;
        this.modalController = modalController;
        this.loadingService = loadingService;
        this.jobService = jobService;
        this.location = location;
        this.recurrentShiftService = recurrentShiftService;
        this.shiftConfirmed = new EventEmitter();
        this.startTheShiftOffline = new EventEmitter();
        this.endTheTimer = new EventEmitter();
        this.shiftDeclined = new EventEmitter();
        this.shiftAddressList = [];
    }
    ngOnInit() {
    }
    ngOnChanges() {
        if (this.jobItem && this.userData) {
            this.profilePicture = this.getProfilePic(this.jobItem.entity.creator.profileUrl);
            if (this.jobItem.recurringShift.groupJobId && this.jobItem.recurringShift.groupJobId.recurrentShiftTemplateId && this.jobItem.recurringShift.groupJobId.recurrentShiftTemplateId._id) {
                this.shiftStartTimeFormatted = this.formatRecurrentShiftInformation(this.jobItem);
            }
            else {
                this.shiftStartTimeFormatted = moment.unix(this.jobItem.shiftStartTime).tz(this.jobItem.timeZone).format('dddd MMMM DD');
            }
            this.startTime = this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftStartTime, true);
            this.endTime = this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftEndTime, true);
            this.hasStafferStartedAJob = this.talentDashboardUtilService.hasStafferStartedAJob(this.userData._id, this.jobItem.staffersAndShifts);
            this.isStafferConfirmedShift = this.talentDashboardUtilService.isStafferConfirmedShift(this.jobItem, this.userData);
            this.humanizeShiftTime = this.talentJobFormatService.humanizeShiftTime(this.jobItem.shiftStartTime, this.hasStafferStartedAJob);
            this.duration = this.talentJobFormatService.getJobDuration(moment.unix(this.jobItem.shiftStartTime).tz(this.jobItem.timeZone), moment.unix(this.jobItem.shiftEndTime).tz(this.jobItem.timeZone));
            this.duration = this.talentJobFormatService.formatDuration(this.duration);
            this.potentialEarning = this.talentDashboardUtilService.getcalculatedPotentialEarning(this.jobItem);
            this.distanceFromShift = this.utilService.calculateDistanceFromShift(this.jobItem.companyAddress, this.userData.address);
            this.timeLeft = this.talentJobFormatService.getTimeDifference(this.jobItem.shiftStartTime) < 0 ? true : false;
            this.currentUserIndex = findIndex(this.jobItem.staffersAndShifts, ['staffer', this.userData._id]);
            this.source = this.initiator;
            if (this.currentUserIndex && this.jobItem.staffersAndShifts && this.currentUserIndex > -1) {
                this.isShiftStarted = this.jobItem.staffersAndShifts[this.currentUserIndex].isShiftStarted;
            }
            this.shiftAddressList = this.utilService.fetchAddressesFromPatientList(this.jobItem);
        }
    }
    viewNotes(type) {
        let heading = '';
        let message = '';
        if (type === 'shift') {
            heading = 'Shift notes';
            message = this.jobItem.shiftNotes;
        }
        if (type === 'site') {
            heading = 'Site notes';
            message = this.jobItem.siteNotes || this.jobItem.entity.siteNotes;
        }
        this.popupService.showModal({ heading: heading, message: message, btn: 'Dismiss', navigateRoute: '',
            imgURL: '' });
    }
    getProfilePic(profileUrl) {
        return this.imagesService.getProfilePic(profileUrl);
    }
    isShiftConfimed(shiftConfimed) {
        if (shiftConfimed)
            this.startShiftOffline(this.jobItem);
        else
            this.confirmShift(this.jobItem);
    }
    formatRecurrentShiftInformation(jobItem) {
        let shiftInterval = this.recurrentShiftService.formatShiftInterval(jobItem.recurringShift.groupJobId.recurrentShiftTemplateId);
        let shiftIntervalDays = this.recurrentShiftService.convertDaysToShortFormat(jobItem.recurringShift.groupJobId.recurrentShiftTemplateId.days);
        return `${shiftInterval} on ${shiftIntervalDays}`;
    }
    confirmShift(jobItem) {
        this.shiftConfirmed.emit({
            jobItem,
            userId: this.userData._id
        });
    }
    startShiftOffline(jobItem) {
        this.startTheShiftOffline.emit({
            jobItem,
            userId: this.userData._id
        });
    }
    timerEnded() {
        this.endTheTimer.emit();
    }
    showBulletins(bulletins, entityId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.popupService.viewShiftBulletins(bulletins, entityId);
        });
    }
    covidSigned() {
        const currentUserIndex = findIndex(this.jobItem.staffersAndShifts, ['staffer', this.userData._id]);
        const currentUser = this.jobItem.staffersAndShifts[currentUserIndex];
        const tempEnableCovidAssessment = moment.duration(moment(this.jobItem.shiftStartTime).diff(+moment().format('X')), 'seconds').asHours()
            <= 12;
        if (this.talentCovidAssessmentService.hasCovidPassed(currentUser) && this.enableCOVIDPopupInApp) {
            if (!tempEnableCovidAssessment) {
                this.popupService.showModal({
                    heading: 'COVID-19 ASSESSMENT',
                    message: `You may only submit the assessment within 12 hours of your shift start time.`,
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/sorry1.png'
                });
                return;
            }
            else {
                // go for the assessment
                this.talentCovidAssessmentService.checkForCovid(this.jobItem, currentUser, (covidRes, error) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (covidRes) {
                        this.jobItem.staffersAndShifts[currentUserIndex].covidAssessment = true;
                        this.jobItem.staffersAndShifts[currentUserIndex].covid = covidRes;
                        yield this.talentDashboardUtilService.saveShiftInLocalStorage(this.jobItem);
                    }
                }));
            }
        }
    }
    showDetailsModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: ShiftDetailsModalComponent,
                backdropDismiss: false,
                componentProps: {
                    jobItem: this.jobItem,
                    userData: this.userData,
                },
                cssClass: 'shift-details-modal-wrapper'
            });
            yield myModal.present();
        });
    }
    getAge(dateOfBirth) {
        if (dateOfBirth) {
            const year = moment().diff(dateOfBirth, 'years', false);
            return year;
        }
        return '';
    }
    declineShift(jobItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                const isRecurring = jobItem.recurringShift.isTrue;
                isRecurring ?
                    yield this.jobService.rejectRecurringShift(jobItem.recurringShift.groupJobId, this.userData._id, this.userData.firstName, this.userData.lastName) :
                    yield this.jobService.rejectBroadcastShift(jobItem._id, this.userData._id, this.userData.firstName, this.userData.lastName);
                this.shiftDeclined.emit({ jobItem });
            }
            catch (error) {
                console.log('error: ', error);
                this.popupService.showModal({
                    heading: 'Sorry',
                    message: error.data || typeof error.error === 'string' ? error.error : 'Something went wrong, please try again later',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/sorry1.png'
                });
            }
            finally {
                this.loadingService.hideLoading(loading);
            }
        });
    }
}
