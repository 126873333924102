import * as tslib_1 from "tslib";
import 'moment-duration-format';
import { SkillsService } from './skills.service';
export class SkillFilterationService {
    constructor(skillsService) {
        this.skillsService = skillsService;
    }
    filterSkills(skills, skillsToKeep) {
        return skills.filter(skill => skillsToKeep.indexOf(skill.name) > -1);
    }
    // //return an array against a skill to keep, passed from views 
    getSkillsArray(skills, skillToKeep) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allSkills = yield this.skillsService.getListOfAllSkills({ outputFormat: 'default', filters: [skillToKeep] });
            return this.filterSkills(skills, allSkills);
        });
    }
    doesUserHasHCSkills(skills) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allSkills = yield this.skillsService.getListOfAllSkills({ industriesToInclude: ['Healthcare'], outputFormat: 'names' });
            return this.filterSkills(skills, allSkills).length > 0;
        });
    }
    doesUserHasHospitalitySkills(skills) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allSkills = yield this.skillsService.getListOfAllSkills({ industriesToInclude: ['Hospitality'], outputFormat: 'names' });
            return this.filterSkills(skills, allSkills).length > 0;
        });
    }
    doesUserHasHCAttestationSkills(skills) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allSkills = yield this.skillsService.getListOfAllSkills({ filters: ['isAttestationRequired'], outputFormat: 'names' });
            return this.filterSkills(skills, allSkills).length > 0;
        });
    }
    doesUserHasBOHSkills(skills) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allSkills = yield this.skillsService.getListOfAllSkills({ outputFormat: 'names', filters: ['isBOH'] });
            return this.filterSkills(skills, allSkills).length > 0;
        });
    }
    doesUserHasFOHSkills(skills) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allSkills = yield this.skillsService.getListOfAllSkills({ outputFormat: 'names', filters: ['isFOH'], });
            ;
            return this.filterSkills(skills, allSkills).length > 0;
        });
    }
    doesUserHasGeneralSkills(skills) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allSkills = yield this.skillsService.getListOfAllSkills({ outputFormat: 'names', filters: ['isGeneral'] });
            ;
            return this.filterSkills(skills, allSkills).length > 0;
        });
    }
    doesUserSkillRequireApparel(skills) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allSkills = yield this.skillsService.getListOfAllSkills({ outputFormat: 'names', filters: ['isBOH', 'isFOH', 'isGeneral'], });
            return this.filterSkills(skills, allSkills).length > 0;
        });
    }
    fetchSkillsWithRequiredCertificates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const allSkills = yield this.skillsService.getListOfAllSkills({ industriesToInclude: ['Healthcare'], outputFormat: 'default' });
            return allSkills.filter((skill) => skill.requiredCertificates.length > 0);
        });
    }
}
