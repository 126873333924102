/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./searchable-select-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./searchable-select-modal.component";
var styles_SearchableSelectModalComponent = [i0.styles];
var RenderType_SearchableSelectModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchableSelectModalComponent, data: {} });
export { RenderType_SearchableSelectModalComponent as RenderType_SearchableSelectModalComponent };
function View_SearchableSelectModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-checkbox", [["class", "select-checkbox"], ["slot", "start"]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleIonChange($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.handleCheckboxSelect($event.detail.checked, _v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonCheckbox_0, i2.RenderType_IonCheckbox)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.BooleanValueAccessor]), i1.ɵdid(4, 49152, null, 0, i3.IonCheckbox, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { checked: [0, "checked"] }, null), i1.ɵdid(5, 16384, null, 0, i3.BooleanValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "ion-label", [["class", "option-text"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(7, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(8, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSelectedOption(_v.context.$implicit); _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 8, 0, currVal_1); }); }
export function View_SearchableSelectModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "alert-wrapper sc-ion-alert-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "close-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "ion-searchbar", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ionClear"], [null, "ionChange"], [null, "ngModelChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4)._handleInputEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionClear" === en)) {
        var pd_2 = (_co.handleClearSearch() !== false);
        ad = (pd_2 && ad);
    } if (("ionChange" === en)) {
        var pd_3 = (_co.handleSearchChange() !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchItem = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_IonSearchbar_0, i2.RenderType_IonSearchbar)), i1.ɵdid(4, 16384, null, 0, i3.TextValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TextValueAccessor]), i1.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(9, 49152, null, 0, i3.IonSearchbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "options-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "ion-list", [["lines", "none"]], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(12, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { lines: [0, "lines"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SearchableSelectModalComponent_1)), i1.ɵdid(14, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 10, "div", [["class", "alert-button-group sc-ion-alert-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["class", "alert-button ion-focusable ion-activatable sc-ion-alert-md"], ["tabindex", "0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [["class", "alert-button-inner sc-ion-alert-md"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "ion-ripple-effect", [["class", "sc-ion-alert-md md hydrated"], ["role", "presentation"]], null, null, null, i2.View_IonRippleEffect_0, i2.RenderType_IonRippleEffect)), i1.ɵdid(20, 49152, null, 0, i3.IonRippleEffect, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 4, "button", [["class", "alert-button ion-focusable ion-activatable sc-ion-alert-md"], ["tabindex", "0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect(_co.selectedOptions) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "span", [["class", "alert-button-inner sc-ion-alert-md"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select"])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "ion-ripple-effect", [["class", "sc-ion-alert-md md hydrated"], ["role", "presentation"]], null, null, null, i2.View_IonRippleEffect_0, i2.RenderType_IonRippleEffect)), i1.ɵdid(25, 49152, null, 0, i3.IonRippleEffect, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.searchItem; _ck(_v, 6, 0, currVal_7); var currVal_8 = "none"; _ck(_v, 12, 0, currVal_8); var currVal_9 = _co.options; _ck(_v, 14, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_SearchableSelectModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-searchable-select-modal", [], null, null, null, View_SearchableSelectModalComponent_0, RenderType_SearchableSelectModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.SearchableSelectModalComponent, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchableSelectModalComponentNgFactory = i1.ɵccf("app-searchable-select-modal", i6.SearchableSelectModalComponent, View_SearchableSelectModalComponent_Host_0, { options: "options", selectedOptions: "selectedOptions", onCancel: "onCancel", onSelect: "onSelect", multiple: "multiple" }, {}, []);
export { SearchableSelectModalComponentNgFactory as SearchableSelectModalComponentNgFactory };
