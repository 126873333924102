<div class = "date-container">
  <i class = "fa fa-times" (click) = "close()"></i>
  
  <div class = "label-and-date">
    <ion-label *ngIf = "displayDate"> {{ displayDate }}</ion-label>
    <dp-date-picker (onChange) = "dateChanged()" #datePicker  theme = "dp-material" [(ngModel)]="selectedDate" [config]="dateConfig"></dp-date-picker>  
  </div>

  <div class = "save-date">
    <span class = "current-date" (click) = "selectDate(true)" *ngIf = "referenceType === 'professional' && type === 'endDate'">I currently work here<i class = "fa fa-arrow-right"></i></span>
    <ion-button [disabled] = "!selectedDate" class = "select-date" [ngClass] = "{'current-date-btn' : referenceType === 'personal'}" color = "basic" (click) = "selectDate()">Select</ion-button>
  </div>    
</div>