<div class = "container">
  <div class="text-section">
    <p class  = "general-book orange">
      {{ notification.created | momentPipe:'MMM DD, YYYY'}}
    </p>
   
    <p class="name" class  = "general-bold">
      {{ notification.notificationName }}
    </p>
    <p class = "general-regular">
      {{ notification.body }}
    </p>
  </div>

  <div class = "button-section" *ngIf = "notification.showPopup">
    <ion-button (click) = "seeDetails()">See details</ion-button>
  </div>
</div>