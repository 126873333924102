<!-- container start -->
<div class = "edit-shift-modal">
<!-- header starts -->
  <div *ngIf = "!submitView" class = "header">
    <ion-label class = "heading">{{headerText}}</ion-label>
    
    <app-shared-toggle-bar 
    *ngIf = "!submitView" 
    class = "edit-shift" 
    [data] = "data" 
    (toggleBar) = "onRecurrentToggleChange($event)">
  </app-shared-toggle-bar>
  </div>
  <div *ngIf="submitView"  class = "header">
    <ion-label class = "heading confirmation-heading">Are you sure you want to save the changes?</ion-label>
  </div>
<!-- header ends -->


<!-- layout starts -->
<div class = "body">
  <ng-container *ngIf = "toggleView && jobItem">
  <app-edit-recurrent-specific-shifts [submitView]="submitView"  [jobItem] = "jobItem" [timeZone] = "timeZone" #editSpecificShift></app-edit-recurrent-specific-shifts>
</ng-container>
  <ng-container *ngIf = "!toggleView && jobItem">
    <app-edit-recurrent-full-recurrence [jobItem] = "jobItem" [timeZone] = "timeZone" #editFullRecurrence></app-edit-recurrent-full-recurrence>
  </ng-container>
</div>
<!-- layout ends -->


<!-- footer starts -->
<div class = "footer">
  <div [innerHTML] = "note"></div>
  <div class = "button-container">
    <ion-button class = "discard" (click) = "dismiss()">{{ discardText }}</ion-button>
    <ion-button class = "confirm" (click) = "onSave()">{{ confirmText }}</ion-button>
  </div>
  <div class="unchange-view" *ngIf = "unChangeView">No changes has been made yet</div>
</div>
<!-- footer ends -->


</div>
<!-- container ends -->