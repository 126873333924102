<div class="care-notes">
  <div class="ion-justify-content-center ion-text-center child">
    <div class="content-section">
      <h1 class="">
        Care Notes
      </h1>
    </div>
    <ul *ngIf="data?.length" class="listings">
      <li *ngFor="let patient of data">
        <p class="patient-title">{{getPatientTitle(patient)}}</p>
        <p><span class="heading">Care notes: </span>{{patient.careNotes || '-'}}</p>
      </li>
    </ul>
    <div *ngIf="!data.length" class="empty-container">
      <p>No care notes available</p>
    </div>
    <div class="buttons-section">
      <ion-button expand="full" color="dark" id="success-popup-btn" class=" ion-no-margin btn success-popup-ok"
        (click)='onClose()'>
        Dismiss
      </ion-button>
    </div>

  </div>
</div>