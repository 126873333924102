import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ModalController } from '@ionic/angular';
import { ViewCertificateModalComponent } from '../view-certificate-modal/view-certificate-modal.component';
import { JobService } from '../../services/job.service';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { ShiftHistoryDetailComponent } from '../shift-history-detail/shift-history-detail.component';
import { ImagesService } from '../../services/images.service';
import * as moment from 'moment';
import { BulletinViewerComponent } from '../bulletin-viewer/bulletin-viewer.component';
export class ShiftDetailContainerComponent {
    constructor(userService, modalController, jobService, talentJobFormatService, imageService) {
        this.userService = userService;
        this.modalController = modalController;
        this.jobService = jobService;
        this.talentJobFormatService = talentJobFormatService;
        this.imageService = imageService;
        this.viewingHired = false;
        this.emailCopied = false;
        this.showEmailPopOver = false;
        this.phoneNumberCopied = false;
        this.showPhoneNumberPopOver = false;
        this.isFilled = false;
        this.shouldShowPhoneNumber = false;
        this.hireContractor = new EventEmitter();
        this.showHistory = false;
        this.bulletins = [];
    }
    ngOnInit() {
        this.updateAcknowledgedBulletins();
        this.shouldShowPhoneNumber = this.canViewContractorPhoneNumber();
    }
    viewStafferCertificates(id, isCOVID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const approvedCertificates = yield this.userService.getApprovedCertificatesOfStaffer(id, isCOVID);
            const myModal = yield this.modalController.create({
                component: ViewCertificateModalComponent,
                cssClass: 'viewCertificateModal',
                componentProps: {
                    approvedCertificates,
                    isCOVID: isCOVID
                }
            });
            yield myModal.present();
        });
    }
    loadContractorHistory(stafferId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //Fetch contractor stats from server when clicked, toggle the component based on the status
            this.shiftHistoryStats = yield this.jobService.fetchContractorHistoryWithClient(stafferId);
            this.showHistory = !this.showHistory;
        });
    }
    getProfilePicture(profileUrl) {
        return this.imageService.getProfilePic(profileUrl);
    }
    viewShiftDetails(showPastShifts) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //Fetch complete history of contractor in a popup when see details is clicked, It will expand the stats data into detailed information
            //Which includes skill, rate, shift date, timings
            const shiftStatus = showPastShifts ? 'Completed by' : 'Upcoming';
            const contractorPastShifts = yield this.jobService.getShiftHistoryOfContractor(this.staffer.staffer._id, showPastShifts);
            yield this.getShiftHistory(contractorPastShifts, showPastShifts, this.staffer, shiftStatus);
        });
    }
    getShiftHistory(contractorPastShifts, history, staffer, shiftStatus) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //Popup modal when see details is clicked from histroy section
            const myModal = yield this.modalController.create({
                component: ShiftHistoryDetailComponent,
                backdropDismiss: false,
                cssClass: 'clientDetailModal',
                componentProps: {
                    userData: this.userData,
                    contractorPastShifts,
                    history,
                    staffer,
                    shiftStatus
                }
            });
            yield myModal.present();
            return yield myModal.onDidDismiss()
                .then((dataReturned) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (dataReturned.data) {
                    this.hireStaffer();
                }
                if (dataReturned.data === 'hireStaffer') {
                    this.hireStaffer();
                }
            }));
        });
    }
    hireStaffer() {
        this.hireContractor.emit();
    }
    viewUnitNumbers() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //Show unit number popup when clicked view details
            if (!this.shiftHistoryStats.unitsWorked || this.shiftHistoryStats.unitsWorked.length === 0) {
                return;
            }
            this.talentJobFormatService.viewRecurringProperties(null, 'Unit Numbers', true, this.shiftHistoryStats.unitsWorked);
        });
    }
    handlePhoneNumberCopyToClipBoard() {
        this.phoneNumberCopied = true;
    }
    handleEmailCopyToClipBoard() {
        this.emailCopied = true;
    }
    showPopover(popover) {
        this.emailCopied = false;
        this.phoneNumberCopied = false;
        if (this.hidePopoverTimeoutId) {
            clearTimeout(this.hidePopoverTimeoutId);
        }
        if (popover === 'email') {
            this.showEmailPopOver = true;
            this.showPhoneNumberPopOver = false;
        }
        else if (popover === 'phone') {
            this.showEmailPopOver = false;
            this.showPhoneNumberPopOver = true;
        }
    }
    hidePopover() {
        this.hidePopoverTimeoutId = setTimeout(() => {
            this.showEmailPopOver = false;
            this.showPhoneNumberPopOver = false;
            this.emailCopied = false;
            this.phoneNumberCopied = false;
        }, 2000);
    }
    /**
     * Fetch bulletins that require acknowledge and if staffer acknowledge then show date of acknowledge
     * @returns
     */
    updateAcknowledgedBulletins() {
        if (!this.staffer.staffer)
            return;
        // bulletins that user already acknowledged
        const userBulletinIds = this.staffer.staffer.bulletinsAcknowledgment.map(bulletin => bulletin.bulletin_id);
        for (const bulletin of this.userData.entityId.bulletins) {
            // if bulletin does not require acknowledgment, skip it
            if (!bulletin.requires_bulletin_acknowledgment)
                continue;
            // if user already acknowledged this bulletin, set its acknowledge date for UI
            if (userBulletinIds.includes(bulletin._id)) {
                const userBulletin = this.staffer.staffer.bulletinsAcknowledgment.find(obj => bulletin._id == obj.bulletin_id);
                bulletin.acknowledgment_date = moment(userBulletin.acknowledgment_date, "X").format("DD/MM/YY");
            }
            this.bulletins.push(bulletin);
        }
    }
    /**
     * Show bulletin popup
     * @param bulletin
     * @returns
     */
    showBulletin(bulletin) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: BulletinViewerComponent,
                backdropDismiss: false,
                cssClass: 'wideModal',
                componentProps: {
                    url: bulletin.type === 'video-url' ? bulletin.url : bulletin.secure_url,
                    title: bulletin.name,
                    isVideo: bulletin.type === 'video-url' ? true : false,
                    isAcknowledgmentRequired: false,
                    bulletinId: bulletin._id,
                    entityId: this.userData.entityId._id,
                }
            });
            return yield myModal.present();
        });
    }
    /**
     * Only show phone numbers of hired contractors and SE Health only
     * @returns
     */
    canViewContractorPhoneNumber() {
        let entityName = "";
        if (this.userData && this.userData.entityId && this.userData.entityId.name) {
            entityName = this.userData.entityId.name;
        }
        return entityName.toUpperCase().includes("SE HEALTH");
    }
}
