import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})

export class AddressModificationService {

constructor() { }
 
    // it removes the postal code from street address and append it at the end
    updateUserStreet(address) {

    const country = this.extractCountryFromStreetAddress(address.street);
    const postalCodeRegex = country === 'ca' ? new RegExp(/[A-Z][0-9][A-Z]( )?[0-9][A-Z][0-9]/i) : new RegExp(/[0-9]{5}(-{0,1}[0-9]{4})?/);

    const code = address.code ||  address.street.match(postalCodeRegex) || '';
    const updatedAddress = this.removePostalCodeFromStreetAddress(address.street);

    if(code != null)
        return this.formatString(updatedAddress) + ' ' + ', '+  code ;
    else 
        return this.formatString(updatedAddress)
    }
  
    extractCountryFromStreetAddress(address) {
        return address.includes(', Canada') ? 'ca' : 'us'
    }

    formatString(address: string) {
        if(address.lastIndexOf(',') == address.length -  2) {
            return address.substr(0, address.lastIndexOf(','))
        }        
        else 
            return address;
    }

    removePostalCodeFromStreetAddress(street: string) {
        let cnRegex = new RegExp(/[A-Z][0-9][A-Z]( )?[0-9][A-Z][0-9]/i);
        let usRegex = new RegExp(/[0-9]{5}(-{0,1}[0-9]{4})?/i);
        
        if (this.extractCountryFromStreetAddress(street) == 'ca') {
            street =  street.replace(cnRegex, '');
            return street;
        } else {
            street =  street.replace(usRegex, '');
            return street;
        }
    }

    extractAddressFieldsFromGeoAPIResult(formatted_address: string, address_components: google.maps.GeocoderAddressComponent[]) {
        let address: {
            country?: string;
            code?: string;
            street?: string;
            province?: string;
            } = {
            country: ''
          };
          
          address_components.forEach(addressComp => {
            
            if (addressComp.types.indexOf('postal_code') !== -1 || addressComp.types.indexOf('postal_code_prefix') !== -1) {
                address.code = addressComp.long_name;
              }
      
              if (addressComp.types.indexOf('country') !== -1 && addressComp.types.indexOf('political') !== -1) {
                address.country = addressComp.short_name.toLowerCase();
              }

              if (addressComp.types.indexOf('country') !== -1 && addressComp.types.indexOf('political') !== -1) {
                address.country = addressComp.short_name.toLowerCase();
              }

              if (addressComp.types.indexOf('administrative_area_level_1') !== -1) {
                    address.province = addressComp.long_name.split(' ').join('_');
                    address.province = _.deburr(address.province);
                }

          });

          address.street = this.removePostalCodeFromStreetAddress(formatted_address);
          return address;
    }

    isPostalCodeValid(postalCode, type: 'us' | 'ca') {
        if (!postalCode) {
          return null;
        }
    
        postalCode = postalCode.toString().trim();
    
        const usRegex = new RegExp('^\\d{5}(-{0,1}\\d{4})?$');
        const caRegex = new RegExp(/^[A-Z]\d[A-Z]( )?\d[A-Z]\d$/i);
    
        if (type === 'us') {
          if (usRegex.test(postalCode.toString())) {
            return true;
          }
        } else {
              if (caRegex.test(postalCode.toString())) {
                return true;
            }
        }
    
        return false;
    }
}
