import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { IStafferReference } from '../../interfaces/staffer-reference.interface';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { SkillsService } from 'src/modules/shared/services/skills.service';
import { OnBoardingStepService } from 'src/modules/shared/services/onboarding-step.service';
import { UserService } from 'src/modules/shared/services/user.service';
import { ModalController } from '@ionic/angular';
import { DateSelectorComponent } from 'src/modules/shared/components/date-selector/date-selector.component';
import { SkillFilterationService } from 'src/modules/shared/services/skill-filteration.service';
import { RegexService } from 'src/modules/shared/services/regex.service';


@Component({
  selector: 'app-add-reference-form',
  templateUrl: './add-reference-form.component.html',
  styleUrls: ['./add-reference-form.component.scss'],
})
export class AddReferenceFormComponent implements OnInit, OnChanges {
  @Input() user: IUser;
  @Input() skills: string[];
  @Input() subSkills: string[];
  @Output() formSubmitted = new EventEmitter();
  @Output() backEmitter = new EventEmitter();
  @Output() save = new EventEmitter();
  informationForm: FormGroup;
  isHCSkill: boolean= false;
  isDisabled: boolean = false;
  referenceCollection = [];
  relation: string[] = [
    'family',
    'friend'
  ];
  userRef;
  type: string[] = [
    'professional',
    'personal'
  ];

  onBoardingProcessSteps;
  completionStatus;
  totalReferences: number;
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private onBoardingStepService: OnBoardingStepService,
    private popupService: PopupService,
    private skillsService: SkillsService,
    private skillFilterationService: SkillFilterationService,
    private userService: UserService,
    private regexService: RegexService) {

    }
  
  async ngOnInit() {
  }

  async ngOnChanges(changes) {

    this.userRef = this.user;


      if(this.userRef) {
        this.totalReferences = this.userRef.references.length || 3;
        this.createForm();  

        if(this.userRef.profile_approved) 
          this.disableChanges();
      }    
     
  }
  async addressUpdates(cd: FormGroup, place: google.maps.places.PlaceResult, index: number) {
    //initialize all address inputs
    // Google Places API auto complete
   
    if(place){
      cd.controls.location.setValue(place.formatted_address);    
    }
    
    // const className = document.getElementsByClassName('googlePlaces');
    //autocomplete all google place inputs
    
    // this.referenceCollection.forEach(async (i, index) => {
      
    //   if(!className[index])
    //     return;

    //   const autocomplete = new google.maps.places.Autocomplete(className[index].children[0] as HTMLInputElement, { types: [] });
    //   google.maps.event.addListener(autocomplete, 'place_changed', () => {
    //     // retrieve the place object for your use
    //     const place = autocomplete.getPlace();
    //     cd.controls.location.setValue(place.formatted_address);
    //   });
    // })
  }

  showHelpPopup() {
    const popupDetails = this.onBoardingStepService.stepDetails('references');
    this.popupService.showModal(popupDetails);
  }

  async showDatePicker(cd, type: string, i: number) {
    
    //set initial date to display on date picker modal
    let initialDate = cd.value.startDate;
    
    if(type === 'endDate')
      initialDate = cd.value.endDate;
    
    // show date picker in modal and set initial value according to the type (start | end) clicked
    // pass reference type (personal | professional) to hide "currently working" for personal references 
    const myModal = await this.modalController.create({
      component: DateSelectorComponent,
      backdropDismiss: false,
      cssClass: 'references-global',
      componentProps: {
        startDate: initialDate,
        referenceType: cd.value.referenceType,
        type: type
      }
    });
    await myModal.present();
    myModal.onDidDismiss().then(async (dataReturned) => {
      const date = dataReturned.data;

      //set value of currently working to true if selected from date picker modal
      if(date && date.isCurrentlyWorking && type === 'endDate') {
        let control = this.informationForm.controls['referenceDetails']['controls'][i];
        control.get('isCurrentlyWorking').setValue(date.isCurrentlyWorking);
        control.get(type).setValue(null);
        control.get('endDate').clearValidators();
        control.get('endDate').updateValueAndValidity();
       
      }
      // if the returned data has a data type string and not object then an end date is selected
      // rather than selecting "currently working" 
      if(typeof date === 'string') {
        this.informationForm.controls['referenceDetails']['controls'][i].get(type).setValue(date);
        
        if(type === 'endDate')
          this.informationForm.controls['referenceDetails']['controls'][i].get('isCurrentlyWorking').setValue(false);
      }
    });
  }
  
  disableChanges() {
    //disabled changes in the reference form when viewed from profile 
    this.isDisabled = true;
    this.informationForm.disable();
  }


  createForm() {
    this.referenceCollection = [];
    for(let i = 0 ; i < this.totalReferences ; i++) {
      this.referenceCollection.push(this.BuildFormDynamic(i));
    }

    this.informationForm = this.formBuilder.group({
      referenceDetails: this.formBuilder.array(this.referenceCollection)
    })

  }

  BuildFormDynamic(index) {

    let initializeReference = {
      index: [index],
      referenceType: new FormControl(this.userRef.references[index] ? this.userRef.references[index].referenceType :  this.type[0], [Validators.required]),
      skill: new FormControl(this.userRef.references[index] ? this.userRef.references[index].skill : null),
      companyName: new FormControl(this.userRef.references[index] ? this.userRef.references[index].companyName : null, [Validators.pattern(this.regexService.companyRegex())]),
      contactPerson: new FormControl(this.userRef.references[index] ? this.userRef.references[index].contactPerson : '', [Validators.required, Validators.pattern(this.regexService.nameRegex())]),
      location: new FormControl(this.userRef.references[index] ? this.userRef.references[index].location : ''),
      unitNumber: new FormControl(this.userRef.references[index] ? this.userRef.references[index].unitNumber : ''),
      subSkill: new FormControl(this.userRef.references[index] ? this.userRef.references[index].subSkill : ''),
      companyEmail: new FormControl(this.userRef.references[index] ? this.userRef.references[index].companyEmail : '', [Validators.required, Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/)]),
      phoneNumber: new FormControl(this.userRef.references[index] ? this.userRef.references[index].phoneNumber : '', [Validators.required, Validators.maxLength(10) , Validators.pattern(/^([+]?[0-9]{1,2}[.-\s]?)?([0-9]{3}[.-]?){2}[0-9]{4}$/)]),
      relation: new FormControl(this.userRef.references[index] ? this.userRef.references[index].relation : ''),
      startDate: new FormControl(this.userRef.references[index] ? this.userRef.references[index].startDate : null, [Validators.required]),
      endDate: new FormControl(this.userRef.references[index] ? this.setEndDate(this.userRef.references[index]) : null),
      isCurrentlyWorking: new FormControl(this.userRef.references[index] ? this.userRef.references[index].isCurrentlyWorking : false),
      isHCSkill: new FormControl(this.userRef.references[index] ? this.skillsUpdated(this.userRef.references[index].skill, index) : false)
    };
    return this.formBuilder.group(initializeReference)
  }


  setEndDate(reference) {
    if(reference.isCurrentlyWorking)
      return reference.endDate = null;
    return reference.endDate;
  }
  async skillsUpdated(skill: string, index: number) {
    // show sub skill field if the selected job title belongs to Healthcare
    // encapsulate in an object to check the "name" of selected skill
    this.isHCSkill = await this.skillFilterationService.doesUserHasHCSkills([{name: skill}]);      
    this.informationForm.controls['referenceDetails']['controls'][index].get('isHCSkill').setValue(this.isHCSkill);
  }
  
  
  addReference(referenceData) {
    referenceData = this.filterNullValuesFromObject(referenceData);
    this.formSubmitted.emit(referenceData);
  }

  ngOnDestroy() {
    this.informationForm.reset();
  }


  filterNullValuesFromObject(referenceData) {
    referenceData.forEach((ref) => {
      for(let i in ref) {
          if(ref[i] === null || ref[i] === undefined)
            delete ref[i];
      }
    });


    return referenceData;
  }
  
  addNewReference() {
    
    
    const currentCount = this.informationForm.controls['referenceDetails']['controls'].length;
    this.referenceCollection.push(this.BuildFormDynamic(currentCount));

    this.informationForm = this.formBuilder.group({
      referenceDetails: this.formBuilder.array(this.referenceCollection)
    });
  }
  
  removeReference(index: number) {  

    //remove all instances of a reference, from array and form control
    // this.userRef.references = this.userRef.references.filter((i, refNum) => { return refNum !== index});
    
    this.referenceCollection.splice(index, 1);    
    this.informationForm.controls['referenceDetails']['controls'].splice(index, 1);
    
    if(this.informationForm.value['referenceDetails'][index])
      this.informationForm.value['referenceDetails'].splice(index, 1);
  }

  findDuplicateCompanyEmail(reference) {
    var valueArr = reference.map(function(item){ return item.companyEmail });
    const found =  valueArr.some(function(item, idx){ 
        return valueArr.indexOf(item) != idx 
    })

    if(found) {
      throw 'found';
    }  

    return;
  }

  updateValidators(referenceData: IStafferReference[]) {
    referenceData.forEach((element, index) => {
      let formControl = this.informationForm.controls['referenceDetails']['controls'][index];

      if(element.referenceType === 'professional') {
          formControl.get('skill').setValidators([Validators.required]);
          formControl.get('skill').updateValueAndValidity();

          formControl.get('companyName').setValidators([Validators.required, Validators.pattern(this.regexService.companyRegex())]);
          formControl.get('companyName').updateValueAndValidity();
        
          formControl.get('location').setValidators([Validators.required]);
          formControl.get('location').updateValueAndValidity();


          if(!element.isCurrentlyWorking) {
            formControl.get('endDate').setValidators([Validators.required]);
            formControl.get('endDate').updateValueAndValidity();
          }
        }


        if(element.referenceType === 'personal') {
          formControl.get('relation').setValidators([Validators.required]);
          formControl.get('relation').updateValueAndValidity();
        }

    });
    
  }


  trimInputValue(referenceData: IStafferReference[]) {
    referenceData.map((element) => {
      
      if(element.referenceType === "professional") {
          element.companyName = element.companyName.trim();
      }
      element.contactPerson = element.contactPerson.trim();
    
      return element;
    });
  }

  submitForm() {
    const errors  = {
      'found': {heading: 'Sorry!', message: 'Reference with this email already exists', btn: 'Dismiss'},
      'incomplete': {heading: 'INCOMPLETE INFO!', message: 'Kindly fill all the required fields for the reference to be accepted' , btn: 'GOT IT!'},
      'email' : {heading: 'Sorry!', message: 'You entered an invalid email address for Reference', btn: 'Dismiss'},
      'startBeforeEnd': {heading: 'Sorry!', message: 'Start date cannot be same or after end date', btn: 'Dismiss'},
      'inFuture': {heading: 'Sorry!', message: 'End date cannot be in future', btn: 'Dismiss'}
    } 

    let referenceData = this.informationForm.value.referenceDetails;
    
    
    try {

      this.updateValidators(referenceData);

      if(this.informationForm.valid) {
        this.findDuplicateCompanyEmail(referenceData);
        this.validTimeStamps(referenceData);
        this.trimInputValue(referenceData);
        this.addReference(referenceData);
      } else {
        this.showPopup(errors['incomplete']); 
      }
    } catch (error) {
      console.log('error: ', error);
       this.showPopup(errors[error]);
    }
  }
  
  showPopup(data) {
    this.popupService.showModal(
      {
        heading: data.heading,
        message: data.message,
        btn: data.btn,
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      })
  }

  validTimeStamps(referenceData) {
    
    let startBeforeEnd = false;
    let inFuture = false;


    referenceData.forEach((element) => {

      if(element.referenceType === 'personal')
        return;

      let startDate = moment(element.startDate, 'MMMM/DD/YYYY');
      let endDate = moment(element.endDate, 'MMMM/DD/YYYY');
      
      if(element.isCurrentlyWorking)
        return;

      const timeZone = this.evaluateTimeZone(this.userRef.address.city);
      if (moment(moment(startDate as moment.MomentInput).format('MM/DD/YYYY'),
        'MM/DD/YYYY').isSameOrAfter(moment(endDate as moment.MomentInput).format('MM/DD/YYYY'))) {
          startBeforeEnd = true;
      } else if (startDate.isSameOrAfter(endDate)) {
          startBeforeEnd = true;
  
      } else if (moment(endDate as moment.MomentInput).isAfter(moment.tz(timeZone))) {
          inFuture = true;
        }
    });

    if(startBeforeEnd) {
      throw 'startBeforeEnd'
    }
    
    if(inFuture) {
      throw 'inFuture'
    }
    return;
  }


  evaluateTimeZone(city: string) {
    
    let timeZone;

    //if contractor hasn't updated their address, checkk if contractor belongss to America 
    const validateTimeZone = moment.tz.guess();
      
    if(!validateTimeZone.includes('America')) {
      timeZone = 'America/Toronto';
    } else {
      timeZone = 'America/' + (city === 'Mississauga' ? 'Toronto' : city)
        timeZone = timeZone === 'America/Houston' ? 'America/Chicago' : timeZone;
    }

    return timeZone;
  }
  completeLater() {
    this.informationForm.reset();
    this.onBoardingStepService.updateCompletionStatus('reference', 'incomplete', 'certificates');
  }

  get startDate() { return this.informationForm.get('startDate'); }
  get endDate() { return this.informationForm.get('endDate'); }
  get skill() { return this.informationForm.get('skill'); }

  get formData(){
    return this.informationForm.get('referenceDetails') as FormArray;
  }
}
