<div [ngClass] = "{'outer center-content' : true, 'alayacare-shift': jobItem.alayacareShift?.isTrue }">
  <div class = "image-section" *ngIf="!jobItem.alayacareShift?.isTrue">
    <img class="profile-pic" src="{{profilePicture}}">
  </div>
  <!-- <div class="alayacare-title" *ngIf="jobItem.alayacareShift?.isTrue">
    Alayacare
    <img src="assets/images/alayacare_logo_white.png">
  </div> -->
  <div class = "wrapper center-content">
    <div class = "basicInfo center-content">
      <p class = "name" *ngIf="!jobItem.homecareShift?.isTrue">{{jobItem.entity.name}}</p>
      <p class = "skill">{{ ('SKILLS.' + jobItem.skill) | translate }}</p>
    </div>

    <div class = "notes" *ngIf = "(jobItem.shiftNotes || jobItem.entity?.siteNotes || jobItem.entity?.bulletins.length) ">
      <div class = "text flex-grow" *ngIf = "jobItem.shiftNotes" (click) = "viewNotes('shift')">
        <i class="fas fa-sticky-note"></i>Shift Notes
      </div>

      <div class = "text siteNotes flex-grow" *ngIf = "jobItem.siteNotes || jobItem.entity.siteNotes" (click) = "viewNotes('site')">
        <i class="fas fa-home"></i>Site Notes
      </div>

      <div class = "flex-grow" *ngIf = "jobItem?.entity.bulletins?.length">
        <div class = "text" *ngIf=" jobItem?.entity.bulletins?.length" (click) = "showBulletins(jobItem.entity.bulletins,jobItem.entity._id)">
          <i class="fas fa-table"></i>Shift Bulletins
      </div>
    </div>
  </div>

  <div class = "badge-container">
       
    <div class="client-badge recuurent-shift-badge" *ngIf="jobItem?.recurringShift?.groupJobId?.recurrentShiftTemplateId?._id">
     <span>Recurring Shift</span>
    </div>
   
  </div>

    <span class = "general-book sub-skill" *ngIf="jobItem.subSkills && jobItem.subSkills.length">
      <span class = "mont-bold-font display-content">Subskill Required: </span>{{ jobItem.subSkills.join(", ") }}
    </span>

    <div class = "detail-section center-content">
      <div class="notes alayacare-shift-details" *ngIf="jobItem?.homecareShift?.isTrue">
        <div class="text underline" (click)="showDetailsModal()">
          Check shift details
        </div>
      </div>
      
      <!-- <ng-template [ngIf]="jobItem?.alayacareShift?.isAcceptedByClient">
        <p class="patient-name">{{ 'SHIFT.PATIENT' | translate }}:
          <b>{{jobItem?.alayacareShift?.client?.demographics?.firstName}}
            {{jobItem?.alayacareShift?.client?.demographics?.lastName}}</b> </p>
        <div class="patient-details">
          <div class="item" *ngIf="jobItem?.alayacareShift?.client?.demographics?.phone">
            <i class="fas fa-phone icon"></i>
            {{jobItem?.alayacareShift?.client?.demographics?.phone}}
          </div>
          <div class="item" *ngIf="jobItem?.alayacareShift?.client?.demographics?.email">
            <i class="fa fa-envelope icon"></i>
            {{jobItem?.alayacareShift?.client?.demographics?.email}}
          </div>
          <div class="item" *ngIf="jobItem?.alayacareShift?.client?.demographics?.language">
            <i class="fa fa-language icon"></i>
            {{jobItem?.alayacareShift?.client?.demographics?.language}}
          </div>
      
          <div class="item" *ngIf="jobItem?.alayacareShift?.client?.demographics?.gender">
            <i class="fa fa-male icon"></i>
            {{jobItem?.alayacareShift?.client?.demographics?.gender}}
          </div>
      
          <div class="item" *ngIf="jobItem?.alayacareShift?.client?.demographics?.dateOfBirth">
            <i class="fa fa-birthday-cake icon"></i>
            {{getAge(jobItem?.alayacareShift?.client?.demographics?.dateOfBirth)}} Years old
          </div>
        </div>
      </ng-template> -->

      <p class = "general-book">Your shift starts:</p>
      <p class = "bold-orange">{{ humanizeShiftTime }} </p>
      <div class = "general-regular lh"><span>
        <i class = "fas fa-calendar"></i><span class = "general-book-bold">On </span>{{ shiftStartTimeFormatted }}
        </span>
      </div>

      <div class = "general-regular lh"><span>
        <i class = "fas fa-clock"></i><span class = "general-book-bold">From </span> {{ startTime}} - {{endTime}} 
        </span>
      </div>

      <div class = "general-regular lh" *ngIf = "jobItem.unitNumber"><span>
        <img class = "unitNumber" src = "assets/images/square-u.png"><span class = "general-book-bold">Unit Number:</span>&nbsp;{{ jobItem.unitNumber}} 
        </span>
      </div>
     
      <div class = "general-book-bold lh">
        <span><i class = "fas far fa-money-bill-alt	"></i>Earning ${{ potentialEarning }} @ ${{ jobItem.stafferRate ? jobItem.stafferRate : jobItem.rate}}/hr
          </span>
      </div>
        
        <div *ngIf = "jobItem.travelTime">
          <span class = "general-book" ><i class="fas fa-solid fa-bus"></i>Transit allowance: </span><span class = "general-book-bold">&nbsp;{{ jobItem.travelTime}} hr</span>
        </div>

        <div *ngIf = "jobItem?.break">
          <span class = "general-book"><i class="fas fa-money-check"></i></span>&nbsp;<span class = "general-book-bold">{{ jobItem?.break?.unpaid ? jobItem.break.unpaid: 'No'}} </span><span class = "general-book">unpaid break</span>
        </div>

        <div *ngIf = "jobItem.isTipIncluded">
          <span class = "general-book"><i class="fas fa-solid fa-folder-plus"></i>Includes Tip </span>
        </div>

        <div *ngIf = "jobItem.isStatPay && jobItem.isStatPay == true">
            <span class = "general-book"><i class="fas fa-calendar"></i>Stat Holiday Pay</span>
        </div>
        
      <div class = "address" *ngIf = "!jobItem?.isMultiAddressShift">
        <span class = "general-regular">
        <i class = "fas fa-map-marker"></i><span class = "general-book-bold">At </span>{{jobItem.companyAddress?.street}} - {{ distanceFromShift}} km away</span>
      </div>

      <app-shift-multi-address-list
      *ngIf = "jobItem?.isMultiAddressShift"
      [shiftAddressList] = "shiftAddressList">
      ></app-shift-multi-address-list>

      <div *ngIf = "jobItem.entity.isCovidAffected == true || jobItem.entity.isCovidAffected == false">
        <span class = "general-book" ><i class="fas fa-asterisk"></i>Covid affected: </span>
        <span *ngIf = "jobItem.entity.isCovidAffected == true" class = "general-book-bold">&nbsp;Positive</span>
        <span *ngIf = "jobItem.entity.isCovidAffected == false" class = "general-book-bold">&nbsp;Negative</span>
      </div>   
    </div>

    <div class = "btn-section" *ngIf="!hasStafferStartedAJob && source !== 'COVID'">
      <ion-button *ngIf="!jobItem.alayacareShift?.isTrue || jobItem.alayacareShift?.isAcceptedByClient" color = "basic" class = "login-btn" type="submit" (click) = "isShiftConfimed(isStafferConfirmedShift)">
        <div class = "login-txt" *ngIf="isStafferConfirmedShift">Start Shift</div>
        <div class = "login-txt" *ngIf="!isStafferConfirmedShift">Confirm Shift</div>
        <div class = "ellipse"><i class="fas fa-arrow-right"></i></div>
      </ion-button>
      
      <ion-button style="--padding-start: 0;" *ngIf="!isStafferConfirmedShift" color="dark" class="login-btn" type="button" (click)="declineShift(jobItem)">
        <div class="login-txt">Decline Shift</div>
      </ion-button>
      <!-- <ion-button disabled *ngIf="jobItem.alayacareShift?.isTrue && !jobItem.alayacareShift?.isAcceptedByClient">
        Awaiting Confirmation
      </ion-button> -->
    </div>

    <div class = "btn-section" *ngIf = "source == 'COVID' && jobItem.staffersAndShifts">
      <div *ngFor="let staffer of jobItem.staffersAndShifts">
        <ion-button *ngIf = "staffer.staffer == userData._id && !staffer.covidAssessment" color = "basic" class = "login-btn" type="submit" (click)="covidSigned()">
          <div class = "covid-txt">{{ 'COVID_ASSESSMENT.Start Assessment' | translate}}</div>
        </ion-button>
        
        <ion-button *ngIf = "staffer.staffer == userData._id && staffer.covidAssessment" color = "dark" class = "login-btn" type="submit" (click)="covidSigned()">
          <div class = "login-txt">Completed</div></ion-button>
       </div>
    </div>


    <div class = "btn-section" *ngIf="hasStafferStartedAJob && source != 'COVID'">
      <ion-button color = "basic" class = "login-btn" type="submit" (click) = "timerEnded()">
        <div class = "login-txt">Clock-out</div>
        <div class = "ellipse"><i class = "fas fa-arrow-right"></i></div>
      </ion-button>
    </div>
  </div>
</div>