import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientAppLayoutComponent } from './ClientAppLayout/client-app-layout.component';
import { ContractorAppLayoutComponent } from './ContractorAppLayout/contractor-app-layout.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { LayoutService } from './layout.service';
import { SharedModule } from 'src/modules/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NewClientLayoutComponent } from './ClientAppLayout/new-client-layout/new-client-layout.component';
import { OldClientLayoutComponent } from './ClientAppLayout/old-client-layout/old-client-layout.component';


@NgModule({
  declarations: [
    NewClientLayoutComponent,
    OldClientLayoutComponent,
    ClientAppLayoutComponent,
    ContractorAppLayoutComponent
  ],
  exports: [
    ClientAppLayoutComponent,
    ContractorAppLayoutComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    TranslateModule
  ],
  providers: [
    LayoutService
  ]
})
export class LayoutModule { }
