// This Service is used for HTTP calls.

/***************** Import angular modules *******************/
import { Injectable } from '@angular/core';

import {
  HttpErrorResponse,
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
/*************************** End ****************************/

/***************** Import Services *******************/
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';
/*************************** End ****************************/

/************** Import API Base URL **************/




/*************************** End ****************************/

@Injectable()
export class HttpPromiseService {
  constructor(
    private _http: HttpClient,
    public localStorageService: LocalStorageService,

  ) { }

  // convert response data return from promise (API body data) in to JSON
  private extractData(res: any) {
    return res || [];
  }

  // error return from promise
  responseError = (error: HttpErrorResponse) => {


    throw error;
  };

  // getTokenAppendHeader() -----> get token from local storage and append it in HTTP req headers.
  // private async getTokenAppendHeader() {
  //   let headers = new HttpHeaders();
  //   const authToken = await this.localStorageService.getLoggedInUserToken();
  //   headers = headers.set('Content-Type', 'application/json');
  //   headers = headers.set('Authorization', `Bearer ${authToken}`);
  //   return headers;
  // }

  private async getTokenAppendHeader() {
    let headers = new HttpHeaders();
    const authToken = await this.localStorageService.getLoggedInUserToken();
    // headers = headers.set('Content-Type', contentType);
    headers = headers.set('Authorization', `Bearer ${authToken}`);


    return headers;
  }

  private async makeHttpPostRequest(baseURL: string, url: string, data: any, responseType: any = 'json'): Promise<any> {
    const headers = await this.getTokenAppendHeader();
    const requestURL = `${baseURL}${url}`;
    return this._http
      .post(requestURL, data, { headers, responseType })
      .toPromise()
      .then(this.extractData, this.responseError);
  }
 
  // HTTP POST request without headers content-type
  async httpPostRequest(url: string, data: any, responseType: any = 'json'): Promise<any> {
    return this.makeHttpPostRequest(environment.API_BASE_URL, url, data, responseType);
  }

  // HTTP POST request without headers content-type
  async httpPostRequestForCompany(url: string, data: any, responseType: any = 'json'): Promise<any> {
    return this.makeHttpPostRequest(environment.API_BASE_URL_COMPANIES, url, data, responseType);
  }

  private async makeHttpGetRequest(baseURL: string, url: string, responseType: any = 'json'): Promise<any> {
    const headers = await this.getTokenAppendHeader();
    const requestURL = `${baseURL}${url}`;
    return this._http
      .get(requestURL, { headers, responseType })
      .toPromise()
      .then(res => res || [], this.responseError);
  }

  // HTTP GET request
  async httpGetRequest(url: string, responseType: any = 'json'): Promise<any> {
    return this.makeHttpGetRequest(environment.API_BASE_URL, url, responseType);
  }

  // HTTP GET request
  async httpGetRequestForCompany(url: string, responseType: any = 'json'): Promise<any> {
    return this.makeHttpGetRequest(environment.API_BASE_URL_COMPANIES, url, responseType);
  }

  private async makeHttpPutRequest(baseURL: string, url: string, data: any): Promise<any> {
    const headers = await this.getTokenAppendHeader();
    const requestURL = `${baseURL}${url}`;

    return this._http
      .put(requestURL, data, { headers })
      .toPromise()
      .then(this.extractData, this.responseError);
  }

  // HTTP PATCH request
  async httpPutRequest(url: string, data: any): Promise<any> {
    return this.makeHttpPutRequest(environment.API_BASE_URL, url, data);
  }

  // HTTP PATCH request
  async httpPutRequestForCompany(url: string, data: any): Promise<any> {
    return this.makeHttpPutRequest(environment.API_BASE_URL_COMPANIES, url, data);
  }

  // HTTP DELETE request
  async httpDeleteRequest(url: string): Promise<any> {
    const headers = await this.getTokenAppendHeader();
    const requestURL = `${environment.API_BASE_URL}${url}`;
    return this._http
      .delete(requestURL, { headers })
      .toPromise()
      .then(this.extractData, this.responseError);
  }

   // HTTP DELETE request
   async httpDeleteRequestForCompany(url: string): Promise<any> {
    const headers = await this.getTokenAppendHeader();
    const requestURL = `${environment.API_BASE_URL_COMPANIES}${url}`;
    return this._http
      .delete(requestURL, { headers })
      .toPromise()
      .then(this.extractData, this.responseError);
  }

  async httpPatchRequest(url: string, data: any): Promise<any> {
    const headers = await this.getTokenAppendHeader();
    const requestURL = `${environment.API_BASE_URL}${url}`;
    return this._http
      .patch(requestURL, data, { headers })
      .toPromise()
      .then(this.extractData, this.responseError);
  }



}
