import { AfterViewChecked, AfterViewInit, Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { CertificateValidationRulesService } from '../../services/certificate-validation-rules.service';
import { ICertificateSpecification } from 'src/modules/talent-dashboard/interfaces/certificate-specification.interface';

@Component({
  selector: 'app-certificate-date-picker',
  templateUrl: './certificate-date-picker.component.html',
  styleUrls: ['./certificate-date-picker.component.scss'],
})
export class CertificateDatePickerComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  @ViewChild('picker', { static: false }) picker: IonDatetime;
  @Input() heading: string;
  @Input() certificateType: string;
  @Input() timeZone: string;
  @Input() certificateDateType: "issueDate" | "expiryDate";
  @Input() certificateSpecification: ICertificateSpecification;
  @Input() maxDate: string;
  selectedDate: string;
  appendHeader: boolean = false;
  datePicker: any;
  pickerFormat: string = 'DD MMM YYYY';
  pickerOptions: any = {
    backdropDismiss: false,
    keyboardClose: false
  };
  pickerOpen: boolean = false;
  observer: MutationObserver;

  constructor(
    private modalController: ModalController,
    private certificateValidationRulesService: CertificateValidationRulesService
  ) { }

  ngOnInit() {
    //Set the maxiumum date in calendar according to certificate date type: issue date / expiry date
    this.appendHeader = false;
  }

  ngAfterViewInit() {
    // Open the picker after a short delay to ensure it is fully initialized
    setTimeout(() => {
      this.picker.open();
      //Set pickerOpen flag to true when picker is opened
      this.pickerOpen = true;
      // Setup MutationObserver after picker is opened
      this.setupObserver();
    }, 100);
  }

  ngAfterViewChecked() {
    // Check if the picker is open and header needs to be appended
    if (this.pickerOpen) {
      // Find the picker wrapper element
      this.datePicker = document.querySelector('.picker-wrapper');
      // Append heading if picker wrapper exists and header hasn't been appended yet
      if (this.datePicker && !this.appendHeader) {
        this.appendHeader = true;
        this.addHeading();
      }
    }
  }

  setupObserver() {
    //Mutation observer should observe any changes in child nodes and all sub child nodes
    const config = { childList: true, subtree: true };
    // Create a MutationObserver to detect changes in the DOM
    this.observer = new MutationObserver(() => {
      // Update datePicker reference on DOM change
      this.datePicker = document.querySelector('.picker-wrapper');
      if (this.datePicker && !this.appendHeader) {
        this.appendHeader = true;
        // Append heading if picker wrapper exists and header hasn't been appended yet
        this.addHeading();
      }
    });
    // Observe changes in the entire document body with specified configuration
    this.observer.observe(document.body, config);
  }

  addHeading() {
    if (this.datePicker) {
      // Remove any existing heading to prevent duplicates
      const existingHeading = this.datePicker.querySelector('h1');
      if (existingHeading) {
        existingHeading.remove();
      }

      // Create a new heading element and append it to the picker wrapper
      const heading = document.createElement('h1');
      heading.innerText = this.heading;
      this.datePicker.appendChild(heading);
    }
  }

  handleDateChange() {
    const formattedDate = moment.tz(this.selectedDate, this.timeZone);
    const isDateValid = this.certificateValidationRulesService.validateRequiredDate(
      formattedDate, this.timeZone, this.certificateDateType, this.certificateSpecification
    );

    if (isDateValid) {
      this.appendHeader = false;
      this.pickerOpen = false;
      this.modalController.dismiss({
        isDateValid: isDateValid,
        selectedDate: +moment.tz(formattedDate, this.timeZone).format('X')
      });
      return;
    }

    this.appendHeader = false;
    this.pickerOpen = false;
    this.modalController.dismiss({ isDateValid: isDateValid });
  }

  cancel() {
    this.appendHeader = false;
    this.pickerOpen = false;
    this.modalController.dismiss('no date found');
  }

  ngOnDestroy() {
    this.appendHeader = false;
    this.pickerOpen = false;
    //Disconnect the observer so it wont detect changes in dom
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
