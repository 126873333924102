/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-recurrent-shift-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./edit-recurrent-shift-footer.component";
var styles_EditRecurrentShiftFooterComponent = [i0.styles];
var RenderType_EditRecurrentShiftFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditRecurrentShiftFooterComponent, data: {} });
export { RenderType_EditRecurrentShiftFooterComponent as RenderType_EditRecurrentShiftFooterComponent };
export function View_EditRecurrentShiftFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" edit-recurrent-shift-footer works!\n"]))], null, null); }
export function View_EditRecurrentShiftFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-recurrent-shift-footer", [], null, null, null, View_EditRecurrentShiftFooterComponent_0, RenderType_EditRecurrentShiftFooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.EditRecurrentShiftFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditRecurrentShiftFooterComponentNgFactory = i1.ɵccf("app-edit-recurrent-shift-footer", i2.EditRecurrentShiftFooterComponent, View_EditRecurrentShiftFooterComponent_Host_0, {}, {}, []);
export { EditRecurrentShiftFooterComponentNgFactory as EditRecurrentShiftFooterComponentNgFactory };
