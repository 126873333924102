import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { filter } from 'lodash';
import { JobService } from 'src/modules/shared/services/job.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { PopupService } from 'src/modules/shared/services/popup.service';
export class EditUnitNumberComponent {
    constructor(modalController, jobService, loadingService, popupService) {
        this.modalController = modalController;
        this.jobService = jobService;
        this.loadingService = loadingService;
        this.popupService = popupService;
        this.jobItem = [];
        this.onUpdate = new EventEmitter();
        this.onCancel = new EventEmitter();
        this.payloadUnit = {};
    }
    ngOnInit() {
        //Initialize job with 'selectedUnitNumber': unit number of each job, 
        //unitNumberList: each job will have their own dropdown list 
        //dirty: update jobs whose unit number are changed 
        this.jobItem = this.jobItem.map((job) => {
            job['selectedUnitNumber'] = job.unitNumber;
            job['unitNumberList'] = this.unitNumbers;
            return job;
        });
        this.jobItem.forEach((job) => {
            this.payloadUnit = Object.assign({}, this.payloadUnit, { [job._id]: { isChanged: false, unitNumber: job.unitNumber || '' } });
        });
    }
    handleSaveUnitNumber() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            let payload = [];
            try {
                //make payload an array and push all unit numbers with shift ids in it!
                //check if the unit number is changed or not 
                payload = this.addUpdatedUnitNumbers();
                // Close the popup if unit number is not updated but save is clicked
                // Save api request call if unit numbers remain unchanged 
                if (!payload.length) {
                    this.closeModal();
                    return;
                }
                yield this.jobService.editShiftUnitNumber(payload, this.jobItem['_id']);
                this.popupService.showModal({
                    heading: 'Success!',
                    message: 'Shift unit number has been updated successfully',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/thumbs-up.png'
                });
                this.modalController.dismiss({ payload });
            }
            catch (e) {
                this.popupService.showModal({
                    heading: 'Error',
                    message: 'Something went wrong!!, Please try again later',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/profile.png'
                });
            }
            finally {
                this.loadingService.hideLoading(loading);
            }
        });
    }
    handleUnitNumberChange(jobItem) {
        //Indicate that the value of unit number is changed
        this.payloadUnit[jobItem._id] = { isChanged: true, unitNumber: jobItem['selectedUnitNumber'] };
    }
    addUpdatedUnitNumbers() {
        let payload = [];
        //Convery Object of objects into array
        payload = Object.keys(this.payloadUnit).map((keys) => {
            let element = this.payloadUnit[keys];
            element.jobId = keys;
            return element;
        });
        //Filter payload array and fetch only those elements whose 'isChanged' property is true
        payload = filter(payload, (element) => {
            return element.isChanged === true;
        });
        return payload;
    }
    closeModal() {
        this.modalController.dismiss();
    }
}
