import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { IDatePickerConfig } from 'ng2-date-picker';
import { getAvailableTransitAllowance, getAvailableUnpaidBreak } from 'src/modules/company-dashboard/constants/job-constants';
import { BroadcastJobsUtilService } from 'src/modules/company-dashboard/services/broadcast-job-utils.service';
import { RecurrentShiftService } from 'src/modules/talent-dashboard/services/recurrentShift.service';
import { IShiftTime } from '../../interfaces/shift-time.interface';
import { FormCheckboxComponent } from '../form-checkbox/form-checkbox.component';


@Component({
  selector: 'app-edit-recurring-shift-list-item',
  templateUrl: './edit-recurring-shift-list-item.component.html',
  styleUrls: ['./edit-recurring-shift-list-item.component.scss'],
})
export class EditRecurringShiftListItemComponent implements OnInit {

  @Input() config: Array<string>;
  @Input() control: any;
  @Input() data;
  @Input() confirmationView: Boolean = false
  availableTransitAllowance: Array<{label: string, value: string}>;
  availableUnpaidBreak: Array<{label: string, value: string}>;
  startTimeValues : Array<IShiftTime>;
  endTimeValues : Array<IShiftTime>;

  dateConfig: IDatePickerConfig = {
    allowMultiSelect: false,
    format: 'DD MMM,YY',
    closeOnSelect: true,
    showGoToCurrent: true,
    disableKeypress: true,
    unSelectOnClick: false  
  };

  constructor(private cdr: ChangeDetectorRef,private broadcastJobsUtilService:BroadcastJobsUtilService ,private recurrentShiftService: RecurrentShiftService) {}

  ngOnInit(){

    if(this.confirmationView){
      return 
    }
    this.availableTransitAllowance = getAvailableTransitAllowance()

    this.availableUnpaidBreak = getAvailableUnpaidBreak()

    this.getStartandEndTimeForDay(
      moment(moment().format('MM/DD/YYYY'), 'MM/DD/YYYY').format(),
      moment(moment().format('MM/DD/YYYY'), 'MM/DD/YYYY').format()
    );

    const startIndex = this.recurrentShiftService.fetchTimingIndex(this.startTimeValues, this.control , 'startTime')
    const endIndex = this.recurrentShiftService.fetchTimingIndex(this.endTimeValues, this.control, 'endTime')
    const unpaidBreakIndex = this.recurrentShiftService.fetchUnpaidBreakIndex(this.availableUnpaidBreak,this.control,'unpaidBreak');
    const travelAllowanceIndex = this.recurrentShiftService.fetchTravelAllowanceIndex(this.availableTransitAllowance,this.control,'transitAllowance');

    if(startIndex > -1){ 
      this.control.get('startTime').setValue(this.startTimeValues[startIndex]);
    }
   
    if(endIndex > -1) {
      this.control.get('endTime').setValue(this.endTimeValues[endIndex]);
    }

    if(unpaidBreakIndex > -1) {
      this.control.get('unpaidBreak').setValue(this.availableUnpaidBreak[unpaidBreakIndex]);
    }

    if(travelAllowanceIndex > -1) {
      this.control.get('transitAllowance').setValue(this.availableTransitAllowance[travelAllowanceIndex]);
    }
  }

  getStartandEndTimeForDay(startVal: string, endVal: string) {
    this.startTimeValues = this.broadcastJobsUtilService.generatedStepArray(startVal);
    this.endTimeValues = this.broadcastJobsUtilService.generatedStepArray(endVal);
  }

  getAvailableOptions(key:string){
    if(key == "Start Time"){
      return this.startTimeValues
    }else if(key == "End Time"){
      return this.endTimeValues
    }else if(key == "Transit Allowance"){
      return this.availableTransitAllowance
    }else if (key == "Unpaid Break"){
      return this.availableUnpaidBreak
    }
  }

  getFormControl(key:string){
    if(key == "Date"){
      return 'date'
    }else if(key == "Start Time"){
      return 'startTime'
    }else if(key == "End Time"){
      return 'endTime'
    }else if(key == "Transit Allowance"){
      return 'transitAllowance'
    }else if (key == "Unpaid Break"){
      return 'unpaidBreak'
    }
  }

  getFormControlValue(key: string){
    if(typeof this.control.get(this.getFormControl(key)).value == "object"){
      return this.control.get(this.getFormControl(key)).value.formatted || this.control.get(this.getFormControl(key)).value.label
    }
    return this.control.get(this.getFormControl(key)).value;
  }

  getLabelKey(key:string){
    if(key == "Start Time"|| key == "End Time"){
      return 'formatted'
    }else{  
      return 'label'
    }
  }
}

