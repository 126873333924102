import { Routes } from '@angular/router';
import { LoggedInGuard } from 'src/app/guards/logged-in.guard';
import { SplashScreenResolverService } from './services/splash-screen-resolver.service';
const ɵ0 = () => import("./pages/privacy-policy/privacy-policy.module.ngfactory").then(pp => pp.PrivacyPolicyPageModuleNgFactory), ɵ1 = () => import("./pages/privacy-policy/privacy-policy.module.ngfactory").then(pp => pp.PrivacyPolicyPageModuleNgFactory), ɵ2 = SplashScreenResolverService, ɵ3 = () => import("./../talent-dashboard/talent-dashboard.module.ngfactory").then(m => m.TalentDashboardModuleNgFactory), ɵ4 = () => import("./pages/set-password/set-password.module.ngfactory").then(m => m.SetPasswordPageModuleNgFactory);
const routes = [
    {
        path: 'privacy-policy',
        canActivateChild: [LoggedInGuard],
        loadChildren: ɵ0
    },
    {
        path: 'staffer-privacy-policy',
        canActivateChild: [],
        loadChildren: ɵ1
    },
    {
        path: 'talent-dashboard',
        resolve: { data: ɵ2 },
        canActivateChild: [LoggedInGuard],
        loadChildren: ɵ3
    },
    {
        path: 'set-password/:id',
        loadChildren: ɵ4
    }
];
export class SharedRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
