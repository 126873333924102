import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import { HttpService } from 'src/modules/shared/services/http.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/http-promise.service";
import * as i2 from "../../shared/services/http.service";
export class TalentDashboardService {
    constructor(httpPromiseService, http) {
        this.httpPromiseService = httpPromiseService;
        this.http = http;
    }
    acceptBroadcastRequestRecurring(groupJobId, stafferId, acceptedRcmpFee) {
        return this.httpPromiseService.httpPutRequest(`/api/recurringShifts/${groupJobId}/acceptBroadcastRequest?acceptedRcmpFee=${acceptedRcmpFee}`, { stafferId });
    }
    updateStartTime(startTime, trackerId) {
        return this.httpPromiseService.httpPutRequest(`/api/tracker/updateStartTime`, {
            startTime,
            trackerId
        });
    }
    getAllReferral(toSkipItems, pageSize) {
        return this.httpPromiseService.httpGetRequest(`/api/refferals/?toSkipItems=${toSkipItems}&pageSize=${pageSize}`);
    }
    startTimer(jobId, startTime, user, location) {
        let city = user.address.city;
        city = user.address.province === 'Texas' ? 'Chicago' : city;
        return this.httpPromiseService.httpPostRequest(`/api/tracker/start`, {
            jobId,
            userId: user._id,
            startTime,
            timeZone: 'America/' + city,
            location,
        });
    }
    updateBreaks(breaks, trackerId, state) {
        return this.httpPromiseService.httpPutRequest(`/api/tracker/updateBreaks`, {
            breaks,
            trackerId,
            state
        });
    }
    stopTimer(jobId, stopTime, userId, location, startTime, hasAddedFromStaffer, forceSkipGeoCheck) {
        return this.httpPromiseService.httpPostRequest(`/api/tracker/stop`, {
            jobId,
            userId,
            stopTime,
            startTime,
            hasAddedFromStaffer,
            location,
            forceSkipGeoCheck
        });
    }
    getSettings() {
        return this.httpPromiseService.httpGetRequest(`/api/settings `);
    }
    getSettingsWithMaxSupportedStafferContractVersion(maxSupportedStafferContractVersion) {
        return this.httpPromiseService.httpGetRequest(`/api/settings/maxSupportedStafferContractVersion/${maxSupportedStafferContractVersion}`);
    }
    getNotifications(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/notifications/myNotificationsUpdated/${userId} `);
    }
    getSingleNotifications(notificationId) {
        return this.httpPromiseService.httpGetRequest(`/api/notifications/${notificationId} `);
    }
    dismissOldNotifications(userId, userTimeNow) {
        return this.httpPromiseService.httpPostRequest(`/api/notifications/${userId}/dismissOldShiftNotifications`, { userTimeNow });
    }
    updateNotificationReadStatus(notificationId) {
        return this.httpPromiseService.httpPatchRequest(`/api/notifications/${notificationId} `, { read: true });
    }
    updateViewStatusForAll() {
        return this.httpPromiseService.httpPutRequest(`/api/notifications/updateViewStatusForAll`, {});
    }
    acceptRecurringHireRequest(body) {
        return this.httpPromiseService.httpPutRequest(`/api/recurringShifts/shift/acceptShiftRequest`, body);
    }
    rejectRecurringHireRequest(body) {
        return this.httpPromiseService.httpPutRequest(`/api/recurringShifts/shift/rejectShiftRequest`, body);
    }
    stillWorkingStafferNonRecurringJob(trackerId) {
        return this.httpPromiseService.httpPostRequest(`/api/tracker/tracker/updateTracker/stafferStillWorking`, { trackerId });
    }
    addAsTeam(userId, body) {
        return this.httpPromiseService.httpPutRequest(`/api/teams/addUserInATeam/${userId}`, body);
    }
    generateYearlyReport(userId, body) {
        return this.httpPromiseService.httpPatchRequest(`/api/invoicesNewModel/generateYearlyReport/${userId}`, body);
    }
    getInvoices(userId, filter, toSkipItems, pageSize) {
        return this.httpPromiseService.httpPatchRequest(`/api/invoicesNewModel?type=staffer&stafferId=${userId}&isGeneratedByQuery=false&invoiceId=undefined&filterByStatus=${filter}&toSkipItems=${toSkipItems}&pageSize=${pageSize}`, {});
    }
    getTransactionCardDetails(userId, sortBy) {
        return this.httpPromiseService.httpGetRequest(`/api/invoicesNewModel/transactionCard/${userId}?sortBy=${sortBy}`);
    }
    generateInvoicePdf(invoice) {
        return this.httpPromiseService.httpPostRequest(`/api/invoicesNewModel/invoice/sendInvoiceAsPDF`, { invoice });
    }
    payStaffy(stafferId, invoiceId) {
        return this.httpPromiseService.httpPostRequest(`/api/invoicesNewModel/contractorInvoice/staffyPayImmediate/payNow/${stafferId}/${invoiceId}`, {});
    }
    updateInvoice(invoice) {
        return this.httpPromiseService.httpPatchRequest(`/api/invoicesNewModel/updateInvoice/${invoice._id}`, { invoice });
    }
    fetchSingleInvoice(jobId, userId) {
        return this.httpPromiseService.httpGetRequest(`/api/invoicesNewModel/singleInvoiceDetail/${jobId}/${userId}`);
    }
    createStafferWaiver(data) {
        return this.httpPromiseService.httpPostRequest(`/api/stafferWaiver`, data);
    }
    submitWaiver(stafferId, data) {
        return this.httpPromiseService.httpPutRequest(`/api/stafferWaiver/submit/${stafferId}`, data);
    }
    getStafferWaiver(stafferId) {
        return this.httpPromiseService.httpGetRequest(`/api/stafferWaiver/${stafferId}`);
    }
    createStafferAttestation(data) {
        return this.httpPromiseService.httpPostRequest(`/api/vssAndImmunizationAttestation`, data);
    }
    getStafferAttestation(stafferId) {
        return this.httpPromiseService.httpGetRequest(`/api/vssAndImmunizationAttestation/${stafferId}`);
    }
    submitAttestation(userId, data, documentSignedTime, documentCompletedTime) {
        return this.httpPromiseService.httpPostRequest(`/api/vssAndImmunizationAttestation/${userId}`, { data, documentSignedTime, documentCompletedTime });
    }
    checkForUnverifiedHours(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/tracker/unconfirmed?contractorId=${userId}`);
    }
    getRequestOfSubSkill(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/pendingSubSkills/getAllRequestsByStafferId/${userId}`);
    }
    createRequestForSubSkill(userId, data) {
        return this.httpPromiseService.httpPutRequest(`/api/pendingSubSkills/createRequest/${userId}`, data);
    }
    getUserSubSkillRequest(userId) {
        return this.httpPromiseService.httpGetRequest(`/api/pendingSubSkills/getAllRequestsByStafferId/${userId}?pendingOnly=true`);
    }
    removeSubSkillRequest(userId) {
        return this.httpPromiseService.httpDeleteRequest(`/api/pendingSubSkills/deleteRequest/${userId}`);
    }
    completeTaskRequest(jobId, taskId, patientId) {
        return this.http.httpPutRequest(`/api/v2/jobs/${jobId}/tasks/complete`, { patientId, taskId });
    }
    saveCareNotesRequest(jobId, careNotes, patientId) {
        return this.http.httpPutRequest(`/api/v2/jobs/${jobId}/care-notes`, { careNotes, patientId });
    }
}
TalentDashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TalentDashboardService_Factory() { return new TalentDashboardService(i0.ɵɵinject(i1.HttpPromiseService), i0.ɵɵinject(i2.HttpService)); }, token: TalentDashboardService, providedIn: "root" });
