import { Component, OnInit, EventEmitter, Output, HostListener, Input } from '@angular/core';
import { WebcamInitError, WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { dataURLtoFile } from '../../utils/helpers/images.helper';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { IOnboardingCompanyState } from 'src/modules/onboarding-company/+store/onboarding-company.state';
import { chooseImageFromMobileAction } from 'src/modules/onboarding-company/+store/actions/onboarding-company.actions';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { stafferChooseImageFromMobileAction } from 'src/modules/onboarding-staffer/+store/actions/onboarding-staffer.actions';
import { UserTypeEnum } from 'src/modules/authentication/enums/user-type.enum';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'shared-choose-picture',
  templateUrl: './choose-picture.component.html',
  styleUrls: ['./choose-picture.component.scss'],
})
export class ChoosePictureComponent implements OnInit {
  @Output() formSubmitted = new EventEmitter();
  @Input() user: IUser;
  isCordova: boolean;
  isBrowser: boolean;
  selectedFile: ImageSnippet;
  width: number;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public isCamAllowed: boolean = true;
  public errors: WebcamInitError[] = [];
  private trigger: Subject<void> = new Subject<void>();

  constructor(
    private platform: Platform,
    private talentJobFormatService: TalentJobFormatService,
    private store: Store<IOnboardingCompanyState>
  ) {

  }

  ngOnInit() {
    this.isBrowser = this.talentJobFormatService.isBrowserCheck();
    this.isCordova = this.platform.is('cordova');
    this.onResize();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    const win = !!event ? (event.target as Window) : window;
    this.width = win.innerWidth;

  }
  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      const formData = new FormData();
      formData.append('images', this.selectedFile.file);

      this.formSubmitted.emit(formData);
    };

    reader.readAsDataURL(file);
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }
  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  handleImage(webcamImage: WebcamImage): void {
    const file = dataURLtoFile(webcamImage.imageAsDataUrl, 'image.png');

    const formData = new FormData();
    formData.append('images', file);
    this.formSubmitted.emit(formData);
  }

  takePhoto(sourceType: string) {
    this.platform.ready().then(async () => {
      if (this.user.type === UserTypeEnum.EMPLOYER) {
        // for company
        this.store.dispatch(chooseImageFromMobileAction({
          sourceType
        }));
      } else {
        // for staffer
        this.store.dispatch(stafferChooseImageFromMobileAction({
          sourceType
        }));
      }
    });
  }
}


