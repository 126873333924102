export enum OnboardingStepEnum {
    //// company /////
    CONFIRM_ADDRESS = 'confirmAddress',
    BUSINESS_CONTRACT = 'businessContract',
    PAYMENT_AT_SIGNUP = 'paymentAtSignup',
    DISPLAY_PICTURE_SIGNUP = 'displayPictureSignup',
    NAVIGATION_NON_AUTHENTICATE_HOME = 'navigation.non-AuthenticatedHome',
    ///// staffer ////
    STAFFER_HOME = 'home',
    CONTRACTOR_INFORMATION =  'contractorInformation',
    STAFFER_EXPERTISE = 'expertise',
    STAFFER_INSTRUCTION = 'instruction',
    STAFFER_GETREADY = 'get-ready',
    STAFFER_PERSONAL_INFORMATION = 'personal-information',
    STAFFER_CONTRACT = 'stafferContract',
    STAFFER_PROFESSIONAL_INFORMATION = 'professional-information',
    STAFFER_LICENSE = 'licenseAgreement',
    STAFFER_DISPLAY_PICTURE_SIGNUP = 'displayPictureSignup',
    STAFFER_RESUME_UPLOAD = 'resumeUpload',
    STAFFER_QUIZ = 'quiz',
    STAFFER_SKILLS = 'skills-and-experience',
    STAFFER_CERTIFICATES = 'certificates',
    STAFFER_VSS_ATTESTATION = 'vss-attestation',
    STAFFER_SET_SKILLS_RATE = 'setSkillAndRates',
    STAFFER_ADD_REFERENCE = 'addReference', 
    STAFFER_ADD_BACKGROUND_CHECK = 'addBackgroundCheck',
    STAFFER_ADD_BACKGROUND_CHECK_CPY = 'certnVerificationForm',
    STAFFER_PENDING_STEPS = 'pendinSteps',
    STAFFER_UPLOAD_EQUIPMENT = 'upload-equipment',
    STAFFER_PRIVACY_POLICY =  'privacy-policy',
    STAFFER_ONBOARDING_DASHBOARD =  'onboarding-dashboard',

}
