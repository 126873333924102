import { Injectable } from '@angular/core';
import { HttpPromiseService } from './http-promise.service';
import { LocalStorageService } from './local-storage.service';
import { Store } from '@ngrx/store';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { setNotificationCountAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { forEach, findIndex } from 'lodash';
import { UtilService } from './util.services';
import { INotification } from 'src/modules/talent-dashboard/interfaces/notification.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import * as moment from 'moment-timezone';
import { ModalController } from '@ionic/angular';
import { NotificationPopupComponent } from '../notification-popup/notification-popup.component';
import { ImagesService } from './images.service';
import { SuccessPopupComponent } from '../components/success-popup/success-popup.component';
import { ContractorShiftModifiedComponent } from '../components/contractor-shift-modified/contractor-shift-modified.component';
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private httpPromiseService: HttpPromiseService,
        private localStorageService: LocalStorageService,
        private utilService: UtilService,
        private authStore: Store<IAuthState>,
        private modalController: ModalController,
        private imagesService: ImagesService
    ) { }

    disputeToFileData: {
        jobId: string;
        skill: string;
        staffer: IUser;
    };

    showPopup(notification) {
        if(notification.key == 'signupawaiting' || notification.body.includes('approved'))
            return false;
        return true;
    }


    async getNotificationCount() {
        const user = await this.localStorageService.getLoggedInUserInfo();

        if (user) {
            try {
                const count = await this.httpPromiseService.httpGetRequest(
                    `/api/notifications/myNotificationCount/${user._id}`
                );
                if (typeof count === 'object') {
                    this.authStore.dispatch(setNotificationCountAction({ notificationCount: count.count }));
                }

            } catch (error) {
                console.log('err', error);
            }
        }


    }

    isStafferConfirmedShift(notification: INotification, userData: IUser) {
        let isConfirmed = false;

        if (notification.job && notification.user && notification.type === 'confirm shift') {

            if (notification.user.type === 'staffer') {
                forEach(notification.job.staffersAndShifts, (shift) => {
                    if (shift && shift.staffer === userData._id && shift.state === 'accepted' && shift.isConfirmedShift) {
                        isConfirmed = true;
                    }
                });
            } else if (notification.user.type === 'employer') {
                isConfirmed = true;
            }

        } else {
            isConfirmed = true;
        }
        return isConfirmed;
    }

    isQuotaFilled(notification: INotification) {
        if (notification.job) {
            return notification.job.isABroadcastRequest.vacancies ===
                this.utilService.isStaffersConfirmedShifts(notification.job.staffersAndShifts).length;
        }
    }

    isStafferHired(notification: INotification, userData: IUser) {
        let is = false;
        if(userData){
            if (notification.job && typeof notification.job.staffersAndShifts) {
                for (const shift of notification.job.staffersAndShifts) {
                    if (shift.staffer === userData._id) {
                        is = true;
                        break;
                    }
                }
            }
        }
       
        return is;
    }

    isJobRejected(notification: INotification, userData: IUser) {
        let response = false;
        if(userData){
            if (notification.job && typeof notification.job.isABroadcastRequest.broadcastedTo) {
                for (const shift of notification.job.isABroadcastRequest.broadcastedTo) {
                    if (shift.staffer === userData._id && shift.status === 'rejected') {
                        response = true;
                        break;
                    }
                }
            }
        }
        return response;
    }

    getJobState(notification: INotification) {
        let state = 'offer';
        if (notification.job && notification.user) {
            if (notification.job.isABroadcastRequest) {
                for (const entity of notification.job.isABroadcastRequest.broadcastedTo) {
                    if (entity.staffer === notification.user._id) {
                        state = entity.status;
                        break;
                    }
                }
            } else {
                for (const entity of notification.job.staffersAndShifts) {
                    if (entity.staffer === notification.user._id) {
                        state = entity.state;
                        break;
                    }
                }
            }
        }
        return state;
    }

    hasFiledDispute(notification: INotification) {
        let disputeFiled = false;
        if (notification.user && notification.job && notification.job.staffersAndShifts) {
            for (const shift of notification.job.staffersAndShifts) {
                if (shift.staffer === notification.user._id &&
                    shift.dispute) {
                    disputeFiled = true;
                    break;
                }
            }
        }
        return disputeFiled;
    }

    isJobActive(notification: INotification) {
        if (notification.user) {
            let is = false;
            if (notification.job) {
                for (const shift of notification.job.staffersAndShifts) {
                    if (shift.staffer === notification.user._id) {
                        is = shift.active;
                        break;
                    }
                }
            }
            return is;
        }
    }
    isJobAccpted(notification: INotification, userData: IUser) {
        let isAccepted = false;
        if (notification.job && notification.user) {
            if (notification.type === 'confirm shift') {
                forEach(notification.job.staffersAndShifts, (s) => {
                    if (s && s.staffer === userData._id && s.state === 'accepted' && !s.isConfirmedShift) {
                        isAccepted = true;
                    }
                });
            }
        }
        return isAccepted;
    }

    validateShiftEndTime(shiftEndTime: number) {
        return moment().isBefore(moment.unix(shiftEndTime))
    }

    findTrackerIsStillWorking(notification: INotification, userData: IUser) {
        const currentUserIndex = notification.job ? findIndex(notification.job.staffersAndShifts,
            ['staffer', userData._id]) : '';
        if (notification.job && typeof notification.job.staffersAndShifts[currentUserIndex] !== 'undefined' &&
            typeof notification.job.staffersAndShifts[currentUserIndex].tracker !== 'undefined' &&
            notification.job.staffersAndShifts[currentUserIndex].tracker &&
            notification.job.staffersAndShifts[currentUserIndex].tracker.state !== 'stopped' &&
            !notification.job.staffersAndShifts[currentUserIndex].tracker.stillWorking) {
            return true;
        } else {
            return false;
        }
    }

    isHoursUnConfimred(notification: INotification, userData: IUser) {
        if (notification.type === 'confirm hours') {
            let isHoursConfirmedForTheShift = false;
            if (notification.job && notification.job.staffersAndShifts) {
                forEach(notification.job.staffersAndShifts, (s) => {
                    if (s.staffer === userData._id && !s.tracker.hasLoggedHoursConfirm) {
                        isHoursConfirmedForTheShift = true;
                    }
                });
            }
            return isHoursConfirmedForTheShift;
        }
    }


    async showModal(notify, user, settings, cb?) {
        const myModal = await this.modalController.create({
          component: NotificationPopupComponent,
          backdropDismiss: true,
          cssClass: 'notificationsModal',
          componentProps: {
            notify,
            user,
            settings
          }
        });
        
        await myModal.present();  
        return await myModal.onDidDismiss().then(data => {
            return cb(data.data)       
        });
    }

    async showShiftCancellationModal(groupJobId : string) {
        let data = {
            heading: 'Cancelled!!',
            message: "Sorry this recurring shift has been cancelled",
            btn: "Close",
            navigateRoute: '',
            imgURL: 'assets/images/sorry1.png'
        }
        const myModal = await this.modalController.create({
          component: SuccessPopupComponent,
          backdropDismiss: true,
          cssClass: 'wideModal',
          componentProps: {
            data,
            groupJobId
          }
        });
        
        await myModal.present();  
    }

    async showShiftModifiedModal(groupJobId: string) {

        const myModal = await this.modalController.create({
          component: ContractorShiftModifiedComponent,
          backdropDismiss: true,
          cssClass: 'wideModal',
          componentProps: {
            groupJobId
          }
        });

        await myModal.present();  
    }

    async showShiftExtendedModal(groupJobId: string) {

        const myModal = await this.modalController.create({
          component: ContractorShiftModifiedComponent,
          backdropDismiss: true,
          cssClass: 'wideModal',
          componentProps: {
            groupJobId,
            shiftExtended : true
          }
        });
        
        await myModal.present();  
    }

    getName(notification, userData) {
        if (notification.type === 'admin') {
          return 'Team Staffy';
        } else {
          if (userData) {
            if (userData.type == 'staffer') {
              
                if (notification.job && notification.job.entity && notification.job.entity.creator) {                  
                    return notification.job.entity.name;
                } else {
                    return this.utilService.getUserName(notification.user);
                }
            } else {
              return this.utilService.getUserName(notification.user);
            }
          }
        }
      }

      getProfilePic(notification, userData): string {
        if (notification.type === 'admin') {
          return 'assets/images/staffy-gray.png';
        }
        if (userData.type === 'staffer') {
          if (notification.job && notification.job.entity && notification.job.entity.creator) {
            return this.imagesService.getProfilePic(notification.job.entity.creator.profileUrl);
          } else {
            return this.imagesService.getProfilePic(notification.user.profileUrl);
          }
        } else {
          return this.imagesService.getProfilePic(notification.user.profileUrl);
        }
      }


};