/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contractor-recurrent-shift.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../edit-recurring-shift-list/edit-recurring-shift-list.component.ngfactory";
import * as i3 from "../edit-recurring-shift-list/edit-recurring-shift-list.component";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "@angular/common";
import * as i7 from "./contractor-recurrent-shift.component";
import * as i8 from "../../services/job.service";
import * as i9 from "@angular/router";
import * as i10 from "../../services/loading.service";
var styles_ContractorRecurrentShiftComponent = [i0.styles];
var RenderType_ContractorRecurrentShiftComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContractorRecurrentShiftComponent, data: {} });
export { RenderType_ContractorRecurrentShiftComponent as RenderType_ContractorRecurrentShiftComponent };
function View_ContractorRecurrentShiftComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "para"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
function View_ContractorRecurrentShiftComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "notice-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "para"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 2, 0, currVal_0); }); }
function View_ContractorRecurrentShiftComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-recurring-shift-list", [], null, null, null, i2.View_EditRecurringShiftListComponent_0, i2.RenderType_EditRecurringShiftListComponent)), i1.ɵdid(1, 114688, null, 0, i3.EditRecurringShiftListComponent, [], { data: [0, "data"], config: [1, "config"], confirmationView: [2, "confirmationView"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shifts; var currVal_1 = _co.config; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ContractorRecurrentShiftComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "btn-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-button", [["class", "ion-no-margin btn"], ["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmShift() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Confirm "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "link"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Go back to shift details "]))], function (_ck, _v) { var currVal_0 = "basic"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContractorRecurrentShiftComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "btn-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-button", [["class", "ion-no-margin btn"], ["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToUpcomingShifts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" See upcoming shifts "]))], function (_ck, _v) { var currVal_0 = "basic"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ContractorRecurrentShiftComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "notice-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "notice-head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "notice-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorRecurrentShiftComponent_1)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorRecurrentShiftComponent_2)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorRecurrentShiftComponent_3)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorRecurrentShiftComponent_4)), i1.ɵdid(15, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractorRecurrentShiftComponent_5)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.confirmationView; _ck(_v, 9, 0, currVal_1); var currVal_2 = !_co.confirmationView; _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.confirmationView; _ck(_v, 13, 0, currVal_3); var currVal_4 = _co.confirmationView; _ck(_v, 15, 0, currVal_4); var currVal_5 = !_co.confirmationView; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 7, 0, currVal_0); }); }
export function View_ContractorRecurrentShiftComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contractor-recurrent-shift", [], null, null, null, View_ContractorRecurrentShiftComponent_0, RenderType_ContractorRecurrentShiftComponent)), i1.ɵdid(1, 114688, null, 0, i7.ContractorRecurrentShiftComponent, [i5.ModalController, i8.JobService, i9.Router, i10.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractorRecurrentShiftComponentNgFactory = i1.ɵccf("app-contractor-recurrent-shift", i7.ContractorRecurrentShiftComponent, View_ContractorRecurrentShiftComponent_Host_0, { groupJobId: "groupJobId", entityId: "entityId" }, {}, []);
export { ContractorRecurrentShiftComponentNgFactory as ContractorRecurrentShiftComponentNgFactory };
