import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {

  @Input() totalRecords: number;

  //Initialize pagination object
  pagination = {
    pageSize: 2,
    pageSizeOptions : [2, 4, 8, 16, 32],
    currentPage: 1
  }
  
  @Output() onPageChange = new EventEmitter<{
    startIndex: number,
    endIndex: number
  }>();

  constructor() { }

  ngOnInit() {

    //Update pagination when staffers count is > 0.
    //Staffer count is the length of total hired or total interested stafferss
    if(this.totalRecords > 0) {
      this.updatePagination();
    }
  }

  getTotalPages():number {

	//Calculate total number of pages according to staffer count
    if(!this.totalRecords || this.totalRecords === 0 ) {
      return 0;
    }

    let totalPages =  Math.ceil(this.totalRecords / this.pagination.pageSize);

    return totalPages;
  }


  getPageNumbers() {
	//Display page numbers in a sequence of 1,2...total pages
    const pages = [];
    const totalPages = this.getTotalPages();
  
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  
    return pages;
  }
  
  getPages(): number[] {
    if(!this.totalRecords || this.totalRecords === 0) {
      return [];
    }

	//Show only 3 page numbers at a times
	//On first page show 1,2,3 Next, on third pages show Previous 3,4,5 Next
    const totalPages = this.getTotalPages();
    if(totalPages <= 3) {
      return Array.from({length: totalPages}, (_, i) => i + 1)
    }

    const startPage = Math.max(1, this.pagination.currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 2);

    return Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);

  }

  goToPage(page: number) {
  	// Update pagination when a page number is clicked from the list
    this.pagination.currentPage = page;
    this.updatePagination();
  }

  updatePagination() {
  	//Emit the start and end index, which is used to splice the array
  	
    const startIndex = (this.pagination.currentPage - 1) * this.pagination.pageSize;
    const endIndex = Math.min(startIndex + this.pagination.pageSize, this.totalRecords);
   
    this.onPageChange.emit({
      startIndex: startIndex,
      endIndex: endIndex
    })
  }
  
  minCurrentPageItems() {
    return Math.min(this.pagination.currentPage * this.pagination.pageSize, this.totalRecords)
  }  

  goToPrevious() {
    if (this.pagination.currentPage > 1) {
      this.pagination.currentPage = this.pagination.currentPage - 1; // decrement current page
      this.updatePagination(); // update displayed data
    }
  }

  goToNext() {
    if (this.pagination.currentPage < this.getTotalPages()) {
      this.pagination.currentPage = this.pagination.currentPage + 1; // increment current page
      this.updatePagination(); // update displayed data
    }
  }

  changePageSize() {
    this.pagination.currentPage = 1; // reset current page to 1
    this.updatePagination(); // update displayed data
  }
}
