import * as tslib_1 from "tslib";
import { PopupService } from './popup.service';
import { UserService } from './user.service';
import * as i0 from "@angular/core";
import * as i1 from "./popup.service";
import * as i2 from "./user.service";
export class SubSkillService {
    constructor(popupService, userService) {
        this.popupService = popupService;
        this.userService = userService;
    }
    checkSubSkillError(error, userData) {
        if (!error || !error.error || error.error.indexOf('required subskil') == -1)
            return { isHandled: false };
        this.popupService.showModal({
            heading: 'Sorry',
            message: error.error,
            btn: 'I have the skill!',
            rejectBtnText: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/incomplete.png'
        }, (accept) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.userService.createSubSkillsRequest(userData._id, this.userService.extractSubSkillsFromErrorMessage(error.error));
            this.popupService.showModal({
                heading: 'Request Received.',
                message: 'We have recieved your request and will review your application.',
                btn: 'Ok',
                navigateRoute: null,
                imgURL: 'assets/images/thumbs-up.png'
            });
        }));
        return { isHandled: true };
    }
}
SubSkillService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubSkillService_Factory() { return new SubSkillService(i0.ɵɵinject(i1.PopupService), i0.ɵɵinject(i2.UserService)); }, token: SubSkillService, providedIn: "root" });
