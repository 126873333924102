/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bulletin-viewer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../video-player/video-player.component.ngfactory";
import * as i3 from "../video-player/video-player.component";
import * as i4 from "../pdf-viewer/pdf-viewer.component.ngfactory";
import * as i5 from "../pdf-viewer/pdf-viewer.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../services/popup.service";
import * as i8 from "@angular/common";
import * as i9 from "@angular/forms";
import * as i10 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i11 from "@ionic/angular";
import * as i12 from "../../pipes/embed-doc-file.pipe";
import * as i13 from "./bulletin-viewer.component";
import * as i14 from "../../services/user.service";
import * as i15 from "../../services/loading.service";
var styles_BulletinViewerComponent = [i0.styles];
var RenderType_BulletinViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BulletinViewerComponent, data: {} });
export { RenderType_BulletinViewerComponent as RenderType_BulletinViewerComponent };
function View_BulletinViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ack-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please read and acknowledge this document before applying to the shift. "]))], null, null); }
function View_BulletinViewerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ack-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please watch and acknowledge this video before applying to the shift. "]))], null, null); }
function View_BulletinViewerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-video-player", [["class", "video-container"]], null, null, null, i2.View_VideoPlayerComponent_0, i2.RenderType_VideoPlayerComponent)), i1.ɵdid(1, 114688, null, 0, i3.VideoPlayerComponent, [], { url: [0, "url"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BulletinViewerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-viewer", [], null, null, null, i4.View_PdfViewerComponent_0, i4.RenderType_PdfViewerComponent)), i1.ɵdid(1, 114688, null, 0, i5.PdfViewerComponent, [i6.DomSanitizer, i7.PopupService], { url: [0, "url"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BulletinViewerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "acknowledgment-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinViewerComponent_4)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinViewerComponent_5)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVideo; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isVideo; _ck(_v, 4, 0, currVal_1); }, null); }
function View_BulletinViewerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "acknowledgment-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "object", [["class", "doc-container"]], [[8, "data", 5]], null, null, null, null)), i1.ɵppd(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "embed", [], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(4, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.url)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.url)); _ck(_v, 3, 0, currVal_1); }); }
function View_BulletinViewerComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ack-disclaimer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" I acknowledge that I have read and understand this document "]))], null, null); }
function View_BulletinViewerComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ack-disclaimer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" I acknowledge that I have watched and understood this video. "]))], null, null); }
function View_BulletinViewerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "acknowledgment-txt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "bulletin-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "input", [["class", "custom-checkbox"], ["type", "checkbox"], ["value", "true"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.acknowedgment = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i9.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i9.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.CheckboxControlValueAccessor]), i1.ɵdid(5, 671744, null, 0, i9.NgModel, [[8, null], [8, null], [8, null], [6, i9.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i9.NgControl, null, [i9.NgModel]), i1.ɵdid(7, 16384, null, 0, i9.NgControlStatus, [[4, i9.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinViewerComponent_8)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinViewerComponent_9)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.acknowedgment; _ck(_v, 5, 0, currVal_7); var currVal_8 = !_co.isVideo; _ck(_v, 9, 0, currVal_8); var currVal_9 = _co.isVideo; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_BulletinViewerComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "buttons-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-button", [["class", " ion-no-margin btn success-popup-ok"], ["color", "basic"], ["id", "success-popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acknowledgeDocument() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_IonButton_0, i10.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i11.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], disabled: [1, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, [" Complete "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "basic"; var currVal_1 = !_co.acknowedgment; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_BulletinViewerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i12.EmbedDOCFilePipe, [i6.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "icon-cancel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinViewerComponent_1)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinViewerComponent_2)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinViewerComponent_3)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinViewerComponent_6)), i1.ɵdid(13, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinViewerComponent_7)), i1.ɵdid(15, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinViewerComponent_10)), i1.ɵdid(17, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isAcknowledgmentRequired && !_co.isVideo); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.isAcknowledgmentRequired && _co.isVideo); _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.isTemplate; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.isTemplate; _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.isAcknowledgmentRequired; _ck(_v, 15, 0, currVal_5); var currVal_6 = _co.isAcknowledgmentRequired; _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_BulletinViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bulletin-viewer", [], null, null, null, View_BulletinViewerComponent_0, RenderType_BulletinViewerComponent)), i1.ɵdid(1, 114688, null, 0, i13.BulletinViewerComponent, [i7.PopupService, i11.ModalController, i14.UserService, i15.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BulletinViewerComponentNgFactory = i1.ɵccf("app-bulletin-viewer", i13.BulletinViewerComponent, View_BulletinViewerComponent_Host_0, { bulletinId: "bulletinId", entityId: "entityId", url: "url", title: "title", isVideo: "isVideo", isTemplate: "isTemplate", isAcknowledgmentRequired: "isAcknowledgmentRequired", skill: "skill", onBulletinAcknowledgmentSuccess: "onBulletinAcknowledgmentSuccess" }, {}, []);
export { BulletinViewerComponentNgFactory as BulletinViewerComponentNgFactory };
