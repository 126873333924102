import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SplashScreenResolverService } from 'src/modules/shared/services/splash-screen-resolver.service';
import { LoggedInGuard } from './guards/logged-in.guard';
import { OnboardingGuard } from './guards/onboarding.guard';
import { ContractorAppLayoutComponent } from 'src/layouts/ContractorAppLayout/contractor-app-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    canActivateChild: [AuthGuard],
    resolve: { data: SplashScreenResolverService },
    loadChildren: () => import('../modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'onboarding-company',
    resolve: { data: SplashScreenResolverService },
    loadChildren: () => import('../modules/onboarding-company/onboarding-company.module').then(m => m.OnboardingCompanyModule)
  },
  {
    path: 'onboarding-staffer',
    resolve: { data: SplashScreenResolverService },
    loadChildren: () => import('../modules/onboarding-staffer/onboarding-staffer.module').then(m => m.OnboardingStafferModule)
  },
  {
    path: 'company-dashboard',
    resolve: { data: SplashScreenResolverService },

    loadChildren: () => import('../modules/company-dashboard/company-dashboard.module').then(m => m.CompanyDashboardModule)
  },
  {
    path: '',
    component: ContractorAppLayoutComponent,
    children: [
      {
        path: 'talent-dashboard',
        resolve: { data: SplashScreenResolverService },
        canActivateChild: [LoggedInGuard],
        loadChildren: () => import('../modules/talent-dashboard/talent-dashboard.module').then(m => m.TalentDashboardModule)
      },
    ]
  },
  {
    path: 'shared',
    resolve: { data: SplashScreenResolverService },
    loadChildren: () => import('../modules/shared/shared.module').then(s => s.SharedModule)
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading, anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
