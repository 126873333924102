// This Service is used for HTTP calls.
/***************** Import angular modules *******************/
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

/*************************** End ****************************/

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) { }

  // HTTP POST request without headers content-type
  httpPostRequest<T = object>(url: string, data: any) {
    return this.http
      .post<T>(url, data)
  }

  // HTTP GET request
  httpGetRequest<T = object>(url: string) {
    return this.http
      .get<T>(url)
  }

  // HTTP PATCH request
  httpPutRequest<T = object>(url: string, data: any) {
    return this.http
      .put<T>(url, data)
  }

  // HTTP DELETE request
  async httpDeleteRequest(url: string): Promise<any> {
    const headers = await this.getTokenAppendHeader();
    const requestURL = `${environment.API_BASE_URL}${url}`;
    return this.http
      .delete(requestURL, { headers })
      .toPromise()
      .then(res => res || [], this.responseError.bind(this));
  }

  // error return from promise
  responseError(error: HttpErrorResponse) {
    throw error;
  }

  private async getTokenAppendHeader() {
    let headers = new HttpHeaders();
    const authToken = await this.localStorageService.getLoggedInUserToken();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${authToken}`);
    return headers;
  }

}
