<div class = "issue-date">
  <ion-datetime 
  class = "issue-date-picker" 
  #picker 
  [max] = "maxDate" 
  [pickerOptions] = "pickerOptions"
  display-format = "DD MMM YYYY" 
  [pickerFormat]  = "pickerFormat" 
  [(ngModel)] = "selectedDate" 
  cancelText = "" 
  doneText="Done" 
  (ionChange) = "handleDateChange()">
  </ion-datetime>
</div> 