import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { IonInput } from '@ionic/angular';
export class PlacesAutocompleteComponent {
    constructor() {
        this.placeholder = 'Address';
        this.addressChanged = new EventEmitter();
    }
    ngOnInit() {
    }
    ngAfterViewInit() {
        this.addressUpdates();
    }
    addressUpdates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Google Places API auto complete
            const input = yield this.googlePlaceInput.getInputElement();
            const autocomplete = new google.maps.places.Autocomplete(input, { types: [] });
            google.maps.event.addListener(autocomplete, 'place_changed', () => {
                // retrieve the place object for your use
                const place = autocomplete.getPlace();
                //console.log('place ', place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng());
                this.formatedAddress = place.formatted_address;
                this.addressChanged.emit(place);
            });
        });
    }
}
