import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges
} from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { PopupService } from 'src/modules/shared/services/popup.service';
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-add-resume-form',
  templateUrl: './add-resume-form.component.html',
  styleUrls: ['./add-resume-form.component.scss'],
}


) export class AddResumeFormComponent implements OnInit {
  @Output() fileSubmitted = new EventEmitter();
  @Input() userData: IUser;

  blankResume: string = '';
  constructor(private popupService: PopupService) {

  }

  ngOnInit() {
    this.blankResume = 'Upload your CV. Please ensure that you upload documents in the appropriate format (e.g., PDF, Word)';

  }

  checkDocumentExtension(name) {
    return (name.includes('.doc') == true || name.includes('.docx') == true || name.includes('.pdf') == true) ? true : false  
  }
  
  showPopup(heading: string, message: string, imgURL: string, btn?: string, navigateRoute?: string) {
    this.popupService.showModal(
      {
        heading,
        message,
        btn: btn || 'Dismiss',
        navigateRoute,
        imgURL
      });
  }

  
  onFileChange(event, imageInput) {
    const reader = new FileReader();

    let file: File = imageInput.files[0];
    if(!file) {
      this.showPopup('Error', 'Please upload your resume', 'assets/images/sorry1.png');
    } else {

      console.log('file: ', file);
      if(this.checkDocumentExtension(file.name)) {
        if(event.target.files && event.target.files.length) {
          const newFile = new ImageSnippet(event.target.result, file);
          reader.readAsDataURL(file);
          reader.onload = () => {
            const formData = new FormData();
            formData.append('file', newFile.file);
            this.fileSubmitted.emit(formData);
          };
        } 
      }
      else {
        const files = event.target.files
        event.target.value = ''
        this.showPopup('Error', 'Invalid file format. Please upload the document in PDF or word format', 'assets/images/sorry1.png')  ;
        
      }  
    }
  }
}
