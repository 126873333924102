import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { mergeMap,  take } from 'rxjs/operators';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements CanActivateChild {

    constructor(
        private readonly store: Store<IAuthState>,
        private router: Router
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree>  {
        return this.store
        .pipe(select(getUserInfo))
        .pipe(take(1))
        .pipe(
          mergeMap( user =>{
            if(user){
              return of(true)
            }
            // User is not Logged In
            return of(this.router.parseUrl('/auth'))
          })
        )
    }


    
    
}