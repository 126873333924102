import { Component, Input, OnInit, forwardRef, Inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingService } from '../../services/loading.service';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user.service';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-bulletin-viewer',
  templateUrl: './bulletin-viewer.component.html',
  styleUrls: ['./bulletin-viewer.component.scss'],
})
export class BulletinViewerComponent implements OnInit {


  @Input() bulletinId: string;
  @Input() entityId: string;
  @Input() url: string;
  @Input() title: string;
  @Input() isVideo: Boolean = false
  @Input() isTemplate: Boolean = false;
  @Input() isAcknowledgmentRequired: Boolean = false
  @Input() skill = ""

  @Input() onBulletinAcknowledgmentSuccess : (bulletinId: string) => {}

  acknowedgment: Boolean = false

  constructor(@Inject(forwardRef(() => PopupService)) private popupService ,
    private modalController: ModalController, private userService: UserService
    ,private loadingService: LoadingService) { }

  ngOnInit() { 
   
  }

  closeModal() {
    this.modalController.dismiss()
  }

  async acknowledgeDocument() {

    const loading = await this.loadingService.showLoading().toPromise();

    try {

      await this.userService.acknowledgeBulletin(this.entityId, this.bulletinId, this.skill)
      this.popupService.showModal(
        {
          heading: 'Success!',
          message: 'Bulletin has been acknowledge successfully',
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/thumbs-up.png'
        })

      this.modalController.dismiss();

      if(this.onBulletinAcknowledgmentSuccess){
        this.onBulletinAcknowledgmentSuccess(this.bulletinId)
      }

    } catch (e) {
      this.popupService.showModal(
        {
          heading: 'Error',
          message: 'Something went wrong, Please try again later',
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/profile.png'
        })
    }
    finally {
      this.loadingService.hideLoading(loading);
    }
  }

}
