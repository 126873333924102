import * as tslib_1 from "tslib";
import { Platform } from '@ionic/angular';
import { Market } from '@ionic-native/market/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { UserService } from './user.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { HttpPromiseService } from './http-promise.service';
import { PopupService } from './popup.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic-native/device/ngx/index";
import * as i3 from "./user.service";
import * as i4 from "@ionic-native/app-version/ngx/index";
import * as i5 from "./http-promise.service";
import * as i6 from "./popup.service";
import * as i7 from "@ionic-native/market/ngx/index";
import * as i8 from "@ionic-native/in-app-browser/ngx/index";
export class AppInfoService {
    constructor(platform, device, userService, appVersion, httpPromiseService, popupService, market, theInAppBrowser) {
        this.platform = platform;
        this.device = device;
        this.userService = userService;
        this.appVersion = appVersion;
        this.httpPromiseService = httpPromiseService;
        this.popupService = popupService;
        this.market = market;
        this.theInAppBrowser = theInAppBrowser;
    }
    checkForAppInfo(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = {
                appVersion: '',
                appBuild: '',
                appName: 'Staffy',
                appPackage: 'test',
                isIOS: this.platform.is('ios'),
                isAndroid: this.platform.is('android'),
                isIPad: this.platform.is('ipad'),
                ionicDeployInfo: '',
                deviceName: '',
                deviceVersion: '',
                deviceManufacturer: ''
            };
            if (window.cordova && (data.isIOS || data.isAndroid || data.isIPad)) {
                data.deviceName = this.device.platform;
                data.deviceVersion = this.device.version;
                data.deviceManufacturer = this.device.manufacturer;
                data.appVersion = yield this.appVersion.getVersionNumber();
                data.appBuild = (yield this.appVersion.getVersionCode());
                data.appName = yield this.appVersion.getAppName();
                data.appPackage = yield this.appVersion.getPackageName();
                try {
                    yield this.userService.updateUserPromise(user._id, data);
                }
                catch (error) {
                    console.log(error);
                }
            }
        });
    }
    compareVersions(version1, version2) {
        const v1 = version1.split('.').map(Number);
        const v2 = version2.split('.').map(Number);
        for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
            const num1 = v1[i] || 0;
            const num2 = v2[i] || 0;
            if (num1 > num2)
                return 1;
            if (num1 < num2)
                return -1;
        }
        return 0;
    }
    ensureUserHasMinimumRequiredVersion() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // check if the device is a mobile
                if (!this.isMobile()) {
                    // if its not return
                    return;
                }
                // fetch the app version from api
                const { version } = yield this.getMiniumumRequiredVersion();
                // SET THE VARIABLES
                // ios or android
                const platform = this.device.platform.toLocaleLowerCase();
                // minimum required version for the platform user is currently on
                // in case user is on a platform that is not returned from backend
                // set the version to 0, so that user can continue using the app
                const platformMinRequiredVersion = version[platform] || '0';
                // current version of the installed app
                const appCurrentVersion = yield this.appVersion.getVersionNumber();
                // Compare the current version with the minimum required version
                if (this.compareVersions(appCurrentVersion, platformMinRequiredVersion) < 0) {
                    // Show a non-dismissable popup asking the user to update the app
                    this.showAppUpdatePopup();
                }
            }
            catch (exception) { }
        });
    }
    getMiniumumRequiredVersion() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Call the API and get the minium required version
            return yield this.httpPromiseService.httpGetRequest('/api/appInfo');
        });
    }
    showAppUpdatePopup() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // const appStoreUrl = this.platform.is('android') ? 'market://details?id=ca.staffy.app' : 'itms-apps://itunes.apple.com/app/id1133559351';
            this.popupService.showModal({
                heading: 'A new version of the app is available',
                message: 'Please click the button below to update your app.',
                btn: 'DOWNLOAD',
                navigateRoute: null,
                imgURL: 'assets/images/download-dpi.png'
            }, () => {
                //   callback for download button clic
                // open the respective app store
                if (this.platform.is('android')) {
                    this.market.open('ca.staffy.app');
                }
                else {
                    this.theInAppBrowser.create('itms-apps://itunes.apple.com/app/id1133559351', '_system');
                }
            });
        });
    }
    isMobile() {
        return window.cordova && (this.platform.is('ios') || this.platform.is('android') || this.platform.is('ipad'));
    }
}
AppInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppInfoService_Factory() { return new AppInfoService(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.Device), i0.ɵɵinject(i3.UserService), i0.ɵɵinject(i4.AppVersion), i0.ɵɵinject(i5.HttpPromiseService), i0.ɵɵinject(i6.PopupService), i0.ɵɵinject(i7.Market), i0.ɵɵinject(i8.InAppBrowser)); }, token: AppInfoService, providedIn: "root" });
