<div class = "contractor-card row">
  <div class = "image-column">
    <div class="overlayer-upper">
      <div class="img-wrapper">
        <img class="cmp-image" src="{{staffer.profileImage}}">
        <div *ngIf="staffer.staffer.isStafferVerified" class="staffy-verified orangebg">
          <i class="fa fa-check"></i>
        </div>
        
        <div *ngIf="staffer?.staffer?.badges?.indexOf('covid-19-vaccine') > -1" class="staffy-verified">
          <div class="staffy-verified-covid">
            <img src="assets/images/Covid-Badge.svg">
          </div>
        </div>


      </div>
      <div class="overlayer"
        [ngClass]="{'overlayer-border':staffer.staffer.isStafferVerified }">
      </div>
    </div>
  </div>

  <div class = "contractor-details">
      <div class = "row header">
        <!-- Contractor name and rating -->
        <div class = "">
          <div class = "mont-bold large-font text-capitalize">
            {{staffer.staffer.firstName}} {{staffer.staffer.lastName }}

            <span *ngIf="shouldShowPhoneNumber && staffer.staffer.phoneNumber" class="staffer-phone">
             {{staffer.staffer.phoneNumber}}
            </span>
            
            <span *ngIf="shouldShowPhoneNumber && staffer.staffer.email"  (mouseenter)="showPopover('email')" (mouseleave)="hidePopover()" class="staffer-email"><i   (click)="handleEmailCopyToClipBoard()" class="fas fa-envelope email-icon"></i> 
              <app-copy-popover [copiedToClipBoard]="emailCopied" [showPopover]="showEmailPopOver" [text]="staffer.staffer.email"></app-copy-popover></span>

          </div>
 
          <div class = "row mont-regular small-font align-center" *ngIf="staffer.staffer.numOfRatedUsers > 3 && staffer.staffer.aggregated_rating > 0">
            <star-rating class="ratings open-request-rating" value="{{staffer.staffer.aggregated_rating}}" totalstars="5"
              checkedcolor="#ff4d00" size="18px" readonly="true"></star-rating>
              <span class = "rating-txt">{{staffer.staffer.aggregated_rating}} ({{staffer.staffer.numOfRatedUsers}})</span>
          </div>

          <div>
            <shared-home-staffer-badges *ngIf="staffer.staffer.badges.length" 
            [horizontalOnly]="true" class = "client-view" [userData]="staffer.staffer">
          </shared-home-staffer-badges>
          </div>
        </div>

        <!-- Contractor signal indicators -->
        <div class = "">
          <shared-signals-indicators class = "client-view-badge" [userData]="staffer.staffer">
          </shared-signals-indicators>
        </div>
      </div>

      <!-- Contractor earning and shift details -->
      <div class = "details">
        
        <div class = "medium-font mont-bold vertical-spacing">Contractor Details</div>

        <div class = "row flex-wrap">
          <div class = "row" *ngIf = "staffer.hasContractorWorkedInUnit">
            <i class="fa fa-user-check"></i>
            <p>Already worked in unit</p>
          </div>

          <div class = "row" *ngIf="staffer.distanceFromShift && staffer.distanceFromShift">
            <i class="fa fa-map-marker"></i>
            <p>{{staffer.isProvinceNY ? staffer.distanceInMiles: staffer.distanceInKm }}
                {{staffer.isProvinceNY ? 'MI': 'KM' }} away</p>

          </div>

          <div class = "row">
            <i class="fa fa-user-check"></i>
            <p> {{staffer.staffer.numOfRecommendations || 0}} recommendations
            </p>
          </div>

          <div class = "row">
            <i class="fa fa-user-check"></i>
            <p>
                {{staffer.staffer.shifts_completed || 0}} shifts completed
            </p>
          </div>

          <div class = "row">
            <i class="fa fa-user-check"></i>
            <p *ngIf = "staffer.staffer.total_earned">
                ${{staffer.staffer.total_earned.toFixed(2)}} earned
            </p>

            <p *ngIf = "!staffer.staffer.total_earned">
              $0 earned
            </p>
        </div>

          <div class = "row" *ngIf = "userData?.companyType != 'Hospitality' && (staffer.status == 'interested' || staffer.status == 'hired')">
            <i class = "fa fa-warning"></i>
            <p (click) = "viewStafferCertificates(staffer.staffer._id, true)"><a class = "orange underline-only">
                View COVID-19 credential
              </a>
            </p>
          </div>

          <div class = "row">
            <i class = "fa fa-check text-success" *ngIf = "(staffer?.staffer?.rcmpVerification?.verified || staffer?.staffer?.byPassRCMPVerification)"></i>
            <i class = "fa fa-times text-danger" *ngIf = "(!staffer?.staffer?.rcmpVerification?.verified && staffer?.staffer?.rcmpVerification?.status != 'pending' && !staffer?.staffer?.byPassRCMPVerification)"></i>
            <i class = "fas fa-clock text-pending" *ngIf = "(staffer.staffer?.rcmpVerification?.status === 'pending' && !staffer?.staffer?.byPassRCMPVerification)"></i>
            <p><span>
                RCMP Verified
              </span>
            </p>
          </div>

          <div class = "row" *ngIf = "staffer.status == 'interested' || staffer.status == 'hired'">
            <i class = "fa fa-notes-medical"></i>
            <p (click)="viewStafferCertificates(staffer.staffer._id, false)">
                <a class = "orange underline-only">View Credentials</a>
            </p>
          </div>
        </div>

      </div>

      <!-- Bulletins seciton -->
      <div class="details">
      <div *ngIf="bulletins.length" class = "medium-font mont-bold vertical-spacing">Bulletins</div>

      <div class = "row flex-wrap" *ngIf="bulletins.length">
        <div *ngFor="let bulletin of bulletins"  class = "row bulletin-container">
          <div class="bulletin-item">
            <i (click)="showBulletin(bulletin)" class="bulletin-icon text-orange fas fa-file-alt"></i>
            <span (click)="showBulletin(bulletin)" class="bulletin-title">{{bulletin.name}}</span>
            <i *ngIf="bulletin.acknowledgment_date" (click)="showBulletin(bulletin)" class="fa fa-circle-check ack-tick"
              aria-hidden="true"></i>
          </div>
          <div class="bulletin-ack-date" *ngIf ="bulletin.acknowledgment_date">
            Acknowledge on {{bulletin.acknowledgment_date}}
          </div>
          <div class="bulletin-ack-date" *ngIf ="!bulletin.acknowledgment_date">
            Not acknowledge yet
          </div>
       
      </div>
    </div>


      <!-- Contractor history with the client -->
      <div class = "details" *ngIf = "!viewingHistory">
        <div class = "medium-font mont-bold vertical-spacing">History with {{ userData?.entityId?.name }}</div>

        <div class = "showHistory" *ngIf = "!showHistory" (click) = "loadContractorHistory(staffer.staffer._id)">Show history</div>

        <div class = "row flex-wrap" *ngIf = "showHistory">
          <div class = "row">
            <i class="fas fa-check-double"></i>
            <p *ngIf = "!shiftHistoryStats?.shifts_worked">0 Shifts</p>
            <p *ngIf = "shiftHistoryStats?.shifts_worked" (click) = "viewShiftDetails(true)">
              {{ shiftHistoryStats.shifts_worked || 0}} {{ shiftHistoryStats.shifts_worked < 2 ? "Shift" : "Shifts"}} <span class = "smaller-font orange underline">See Details</span></p>
          </div>

          <div class = "row">
            <i class="fas fa-calendar-check"></i>
            <p *ngIf = "!shiftHistoryStats?.trainingComplete">Training complete: N/A</p>
            <p *ngIf = "shiftHistoryStats?.trainingComplete">Training complete: <span class = "orange underline-only">{{ shiftHistoryStats.trainingComplete |  momentPipeUnix:'DD/MM/YYYY'}}</span></p>
          </div>

          <div class = "row">
            <i class="fa fa-building-o"></i>
            <p *ngIf = "!shiftHistoryStats?.unitsWorked || shiftHistoryStats?.unitsWorked?.length === 0">N/A Unit</p> 
            <p *ngIf = "shiftHistoryStats?.unitsWorked?.length >= 1">
              {{ shiftHistoryStats.unitsWorked.length }} {{ shiftHistoryStats.unitsWorked.length === 1 ? "Unit" : "Units"}} : <span class = "smaller-font orange underline" (click) = "viewUnitNumbers()">See Details</span></p>
          </div>

          <div class = "row" >
            <i class="fas fa-calendar-alt"></i>
            <p *ngIf = "!shiftHistoryStats?.futureShifts">0 Future Shifts</p>
            <p *ngIf = "shiftHistoryStats?.futureShifts" (click) = "viewShiftDetails(false)">
              {{ shiftHistoryStats.futureShifts || 0}} Future {{ shiftHistoryStats.futureShifts < 2 ? "Shift" : "Shifts"}}: <span class = "smaller-font orange underline">See Details</span></p>
          </div>
        </div>
  </div>

</div>

