<div class = "reusable-form-component">
  <dp-date-picker 
    placeholder = {{placeholder}} 
    [(ngModel)] = "shiftDate"
    #datePicker  
    theme = "dp-material" 
    [config]="dateConfig"></dp-date-picker> 
     <i *ngIf="showEditIcon" (click)="openDatePicker(datePicker)" class="fas fa-pencil-alt edit-icon"></i>
</div>


<app-form-errors class = "errors" [control]="control"></app-form-errors>
