/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modify-unit-number.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modify-unit-number.component";
import * as i3 from "../../company-dashboard/services/broadcast-job-utils.service";
import * as i4 from "../services/recurrentShift.service";
var styles_ModifyUnitNumberComponent = [i0.styles];
var RenderType_ModifyUnitNumberComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModifyUnitNumberComponent, data: {} });
export { RenderType_ModifyUnitNumberComponent as RenderType_ModifyUnitNumberComponent };
export function View_ModifyUnitNumberComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "edit-unit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editUnitNumberPopup(_co.jobItem) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " UNIT "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.action; _ck(_v, 2, 0, currVal_0); }); }
export function View_ModifyUnitNumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modify-unit-number", [], null, null, null, View_ModifyUnitNumberComponent_0, RenderType_ModifyUnitNumberComponent)), i1.ɵdid(1, 8503296, null, 0, i2.ModifyUnitNumberComponent, [i3.BroadcastJobsUtilService, i4.RecurrentShiftService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModifyUnitNumberComponentNgFactory = i1.ɵccf("app-modify-unit-number", i2.ModifyUnitNumberComponent, View_ModifyUnitNumberComponent_Host_0, { jobItem: "jobItem", unitNumber: "unitNumber", activeView: "activeView" }, {}, []);
export { ModifyUnitNumberComponentNgFactory as ModifyUnitNumberComponentNgFactory };
