import { Component, OnInit, Input } from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';

@Component({
  selector: 'shared-home-staffer-badges',
  templateUrl: './home-staffer-badges.component.html',
  styleUrls: ['./home-staffer-badges.component.scss'],
})
export class HomeStafferBadgesComponent implements OnInit {
  @Input() userData: IUser;
  @Input() horizontalOnly?= false;
  @Input() isDirectHire?= false;

  constructor() { }

  ngOnInit() {
  }


  getBadgeIcon(text: string) {

    if (text === 'Never Late') {
      return 'fa-clock';
    } else if (text === 'Red seal') {
      return 'fa-certificate badge-crimson';
    } else if (text === 'Student') {
      return 'fa-graduation-cap';
    } else if (text === '30 Hour Badge') {
      return 'fa-user-o';
    } else if (text === '100 Hour Badge') {
      return 'fa-user-plus';
    }
  }
}
