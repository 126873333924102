<a *ngIf="allowClose" class="close-btn" (click)="close()"><i class="fa fa-times"></i></a>
<div class="popup-wrapper">
  <h1 class="ion-text-center">Verify Email</h1>
  <div class="code-wrapper">
    <p>We have sent you an email at <a>{{ email }}</a>, please check your inbox and enter the verification code below. If you do not see it in your inbox, please check your spam folder.</p>

    <ion-label><strong>Enter Code</strong> </ion-label>
    <ion-grid>
      <ion-row text-center>
        <ion-col class = "otp-field">
          <ng-container *ngFor="let i of [0,1,2,3,4,5]">
            <ion-input type = "text" #otp required maxLength="1" (keyup)="otpController($event, i)">
            </ion-input>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-button color="basic" type="submit" expand="block" [disabled]="!otpCode || otpCode.length < 6" (click)="submitCode()">Submit</ion-button>
    <ion-button color="dark" type="submit" expand="block" [disabled]="timer > 0" (click)="sendCode()">
      <div *ngIf="timer > 0">Resend Code in {{ timer }}s</div>
      <div *ngIf="timer <= 0">Resend Code</div>
    </ion-button>
  </div>
</div>