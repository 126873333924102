import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-contract-update-notice',
  templateUrl: './contract-update-notice.component.html',
  styleUrls: ['./contract-update-notice.component.scss'],
})
export class ContractUpdateNoticeComponent implements OnInit {

  @Input() data!: any;

  showFullNotice = false;
  notices = {
    title: '',
    shortNotice: '',
    fullNotice: ''
  };
  
  constructor(
    private modalController: ModalController,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.notices.title = 'Important Updates to our platform';
    
    this.notices.shortNotice = this.data.noticeType === 'staffer' ? `
      We are streamlining our platform and updating our Staffy Terms and Conditions on ${this.contractCutoffDate} date. As part of the update you will also be asked to review and accept a Professional Conduct addendum.
    ` : `
      We are streamlining our platform and updating our Staffy Terms and Conditions on ${this.contractCutoffDate} date. As part of the update you will also be asked to review and accept a new Client addendum.
    `;

    this.notices.fullNotice = this.data.noticeType === 'staffer' ? `
      At Staffy, we are always looking for ways to improve our platform and further empower you to choose when and where you work. As part of that process, we are streamlining our platform and updating our Terms and Conditions. You will also be asked to review and accept a Professional Conduct addendum.<br><br>
      The Updated Staffy Terms and Conditions and your Professional Addendum will come into force on ${this.contractCutoffDate} date. From now until then, you will need to review and accept both the Updated Staffy Terms and Conditions and Professional Addendum in order to continue as an active contractor on the Staffy Platform.<br><br>
      We look forward to making the Staffy platform a more efficient and streamlined experience, and creating opportunities for professionals like you. Should you have any questions or feedback please contact us at <a href="mailto:info@staffy.ca">info@staffy.ca</a>.
    ` : `
      The new Staffy Terms and Conditions and your Client Addendum will come into force on ${this.contractCutoffDate} date. From now until then, you will need to review and accept the updated Staffy Terms and Conditions and Client Addendum in order to continue to post shifts on Staffy. Shifts that you have already scheduled are not impacted by this change.<br><br>
      Should you have any questions or feedback please contact us at <a href="mailto:info@staffy.ca">info@staffy.ca</a>.
    `;
  
    if (this.data.isShiftsBlocked) {
      this.notices.title = this.data.noticeType === 'staffer' ? 
        'Please accept the new Staffy Terms and Professional Addendum' : 
        'Please accept the new Staffy Terms and Client Addendum';
      
      this.notices.shortNotice = this.data.noticeType === 'staffer' ? 
        'In order to continue to apply for shifts on Staffy, you must accept the new Staffy Terms and Professional Addendum.' : 
        'In order to continue posting shifts on Staffy, you must accept the new Staffy Terms and Client Addendum.';
    }
  }

  readMore(): void {
    this.showFullNotice = true; 
  }

  closeModal(): void {
    this.modalController.dismiss();
  }

  acceptDoc(): void {
    this.closeModal();
    const contractLink = this.data.noticeType === 'staffer' ? 
      '/talent-dashboard/staffer-contract' : 
      '/company-dashboard/business-contract';
    this.router.navigateByUrl(contractLink);
  }

  get contractCutoffDate(): string {
    return this.data.cutOffDate || 'X';
  }

}
