import * as moment from 'moment';
import * as i0 from "@angular/core";
export class ShiftValidatorService {
    selectedTeamValidator() {
        return (control) => {
            //Return null, if no value exists
            if (!control.parent) {
                return null;
            }
            //Fetch value of broadcast to team from form
            const broadcastToTeam = control.parent.get('broadcastToTeam');
            //Fetch the value of team from parent
            const selectedTeam = control.parent.get('team');
            selectedTeam.setValidators(null);
            //If broadcast to team is checked, and no team is selected, throw error
            if (broadcastToTeam.value && selectedTeam.value == null) {
                return { message: 'Please select team' };
            }
            return null;
        };
    }
    requiredTeamValidator() {
        return (control) => {
            //Fetch value of broadcast to team from form
            const selectedTeam = control.value;
            //Return null, if no value exists
            if (!selectedTeam || !control.parent) {
                return null;
            }
            //Fetch the value of team from parent
            const broadcastToTeam = control.parent.get('broadcastToTeam');
            //If broadcast to team is checked, and no team is selected, throw error
            if (broadcastToTeam && (!selectedTeam || !selectedTeam.value || Object.keys(selectedTeam.value).length === 0)) {
                return { message: 'Please select team' };
            }
            return null;
        };
    }
    validateStartTime(tz, validationTime) {
        return (control) => {
            let startTime;
            let endTime;
            //If no start time/end time found, return null without proceeding further
            if (!control.value || !control.parent) {
                return null;
            }
            //Check if validation is for start time
            //If start time is changed, fetch the value directly from control
            //end time value will be fetched from parent control      
            if (validationTime === 'startTime') {
                const endTimeControl = control.parent.get('endTime');
                startTime = moment.unix(control.value.value);
                endTime = moment.unix(endTimeControl.value.value);
                const diff = moment.duration(endTime.diff(startTime)).asHours();
                // console.log(startTime.format('X'), moment().tz(tz).format('X'));
                if (startTime.format('X') <= moment().tz(tz).format('X')) {
                    return { message: 'Start time cannot be before the current time ' };
                }
                // //If start time is greater than the end time, throw error
                if (endTime.isBefore(startTime)) {
                    return { message: 'Start time cannot be after end time' };
                }
            }
            //Check if validation is for end time
            //If end time is changed, fetch the value directly from control
            //start time value will be fetched from parent control
            if (validationTime === 'endTime') {
                const startTimeControl = control.parent.get('startTime');
                startTime = moment.unix(startTimeControl.value.value);
                endTime = moment.unix(control.value.value);
                // //If end time is before start time, throw error
                if (endTime.isBefore(startTime)) {
                    return { message: 'End time cannot be before start time' };
                }
                // //If endTime is before current time, throw error
                if (endTime.isBefore(moment())) {
                    return { message: 'End time cannot be before the current time' };
                }
                startTimeControl.updateValueAndValidity();
            }
            return null;
        };
    }
    validateShiftDate(jobItem, tz) {
        return (control) => {
            //Return null, if no value exists
            if (!control || !control.value || !control.parent) {
                return null;
            }
            //Fetch the value of team from parent
            const shiftDate = control.value;
            //Return null if form is initialized and date is not changed
            if (control.parent.value.startDate == shiftDate || shiftDate === moment.unix(jobItem.recurrentShiftTemplateId.startDate).tz(tz).format('MM/DD/YYYY')) {
                return null;
            }
            let matchFound = false;
            jobItem.jobs.forEach((shift) => {
                if (moment.utc(shift.shiftDate).format('MM/DD/YYYY') === shiftDate) {
                    matchFound = true;
                }
            });
            if (!matchFound)
                return null;
            return { message: 'Not allowed to edit date as it already exists' };
        };
    }
    validateTime(validationTime) {
        return (control) => {
            let startTime;
            let endTime;
            //If no start time/end time found, return null without proceeding further
            if (!control.value || !control.parent) {
                return null;
            }
            //Check if validation is for start time
            //If start time is changed, fetch the value directly from control
            //end time value will be fetched from parent control      
            if (validationTime === 'startTime') {
                const endTimeControl = control.parent.get('endTime');
                startTime = moment.unix(control.value.value);
                endTime = moment.unix(endTimeControl.value.value);
                if (endTime.isBefore(startTime)) {
                    return { message: 'Start time cannot be after end time' };
                }
            }
            //Check if validation is for end time
            //If end time is changed, fetch the value directly from control
            //start time value will be fetched from parent control
            if (validationTime === 'endTime') {
                const startTimeControl = control.parent.get('startTime');
                startTime = moment.unix(startTimeControl.value.value);
                endTime = moment.unix(control.value.value);
                // //If end time is before start time, throw error
                if (endTime.isBefore(startTime)) {
                    return { message: 'End time cannot be before start time' };
                }
                startTimeControl.updateValueAndValidity();
            }
            return null;
        };
    }
}
ShiftValidatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShiftValidatorService_Factory() { return new ShiftValidatorService(); }, token: ShiftValidatorService, providedIn: "root" });
