<div class = "bulletin">
  <div class="ion-text-center child">
    <div class = "img-container">
    <img  src="assets/images/shift-bulletins.png">
    </div>
    
    <div class="heading">
      <h1>Shift Bulletins</h1>
    </div>
    <div class="bulletin-container">
      <ul class="listings">
        <li *ngFor="let bulletin of bulletins; let i = index" >
          <div (click)="openBulletin(bulletin)" class="bulletin-item-container">
            <p style="flex: 1;">
              {{bulletin.name}}
            </p>
            <i class = "fa fa-chevron-circle-right"></i>
            <ion-spinner *ngIf="downloadInprogressPdfId === bulletin._id"></ion-spinner>
          </div>
        </li>
      </ul>
    </div>
  
    <div class="buttons-section">
      <ion-button color="basic" id="success-popup-btn" class=" ion-no-margin btn success-popup-ok"
        (click)='dimissModal()'>
        Got it
      </ion-button>
    </div>
  </div>
  </div>