import { Injectable } from '@angular/core';
import { map, orderBy } from 'lodash';
import { Certificate } from 'src/modules/onboarding-staffer/interfaces/staffer-certificates.interface';
import { StatusEnum } from '../enums/status.enum';
import { SkillFilterationService } from './skill-filteration.service';
import { UtilService } from './util.services';
import * as _ from 'lodash';
import { HttpPromiseService } from './http-promise.service';

@Injectable({
    providedIn: 'root'
})

export class CertificateService {

    certificates = null;
    isLoadingCertificates = false;
    
    covidCertificates = [
        {name: 'COVID-19 Vaccination', isRequired: false},
      ];
    
      healthcareCertificates = [
        {name: 'CMRTO Registration', isRequired: false},
        {name: 'CMRITO Registration', isRequired: false},
        {name: 'CPR', isRequired: false},
        {name: 'Current Flu Vaccination', isRequired: false},
        {name: 'Government Issued Photo ID & Eligibility', isRequired: false},
        {name: 'Immunization Record', isRequired: false},
        {name: 'Nursing License/Certification', displayName: 'Nursing License / Certification', isRequired: false},
        {name: 'Medical Lab Certificate', isRequired: false},
        {name: 'N95', isRequired: false},
        {name: 'Nursing Specialization', isRequired: false},
        {name: 'Other', displayName: 'Additional Certificate', isRequired: false},
        {name: 'TB Testing', isRequired: false},
        {name: 'Vulnerable Sector Check', isRequired: false},
        {name: 'Professional Liability Protection', isRequired: false},
      ];
    
      dietaryaideCertificates = [
        {name: 'Food Service/Safe Certification', displayName: 'Food Service / Safe Certification', isRequired: false},
        // {name: 'Food Service Certification', isRequired: false}
      ];
    
      pswCertificates = [
        {name: 'CPI', isRequired: false},
        {name: 'PSW Certificate/Documentation', displayName: 'PSW Certificate / Documentation', isRequired: false},
        {name: 'OPSWA Membership', displayName: 'OPSWA', isRequired: false}
      ];
    
      otherCertificates = [
        {name: 'Food Handler Certified', isRequired: false},
        {name: 'Smart Serve Certified', isRequired: false}
      ]

    constructor(
        private skillFilterationService: SkillFilterationService,
        private utilService: UtilService,
        private httpPromiseService: HttpPromiseService
    ) {}

    mandatoryCertificatesList(): Array<String> {
        //List all the mandatory certificates, add more to this list if requried in future
        const certs = [
            'Vulnerable Sector Check',
            'Receipt of Vulnerable Sector Check'
        ];

        return certs;
    }

    async checkCertificateStatus(skills, certificates, byPassVSCFlow: boolean) : Promise<StatusEnum> {
        if(!skills)
            return;

        const skillsWithRequiredCertificates = await this.skillFilterationService.fetchSkillsWithRequiredCertificates();
        const reqCertObj = this.utilService.checkRequiredCertificates(skillsWithRequiredCertificates, {skills});
        
        // return enum depeding upon the status
        return this.evaluateCertificateCompletion(reqCertObj.requiredCertificatesBySkill, certificates, byPassVSCFlow); 
    }

    updateVSCRequiredCheck(requiredCertificates: string[], userCertificates: string[]): string[] {
        
        //Check if requried certificates needs a certificate/receipt
        //If not return, else check whcih document is uploaded and mark the other one as optional 
        const isVSC = requiredCertificates.includes('Receipt of Vulnerable Sector Check');
        
        if(!isVSC) {
            return requiredCertificates;
        }
        
        const isVSCPresent = userCertificates.includes("Vulnerable Sector Check");
        const isReceiptVSCPresent = userCertificates.includes("Receipt of Vulnerable Sector Check");
        
        if (isVSCPresent) {
            // Remove Receipt of Vulnerable Sector Check Requirement from required certificates if VSC is uploaded
            return this.markCertificateRequirementAsOptional(requiredCertificates, "Receipt of Vulnerable Sector Check")
        }

        if (isReceiptVSCPresent) {
            // Remove Vulnerable Sector Check Requirement from required certificates if Receipt is uploaded
            return this.markCertificateRequirementAsOptional(requiredCertificates, "Vulnerable Sector Check")
        }

        return requiredCertificates;
    }


    markCertificateRequirementAsOptional(requiredCertificates, certificateToRemove): string[] {
        //Remove the certificate from *required certificates* array, making it optional. So either of the certificate is enough to onboard
        requiredCertificates = requiredCertificates.filter((cert) => {return cert != certificateToRemove});
            return requiredCertificates;
    }

   
    getCertificateAnalytics(stafferCertificates,isHCW : Boolean):{
      totalCertificates : number,
      passedCertificates : number
  }{

      let passCertificates = 0;

      /**
       * Change total count dynamically when certificate list mapped dynamically
       */
      let totalCertificates = !isHCW ? 25 : 23 // as certificates list is hardcoded in UI right now so we are hardcoded this count also
      
  
      const keys = Object.keys(stafferCertificates);

      keys.forEach(key=>{
        if(this.getCertificateStatus(key,stafferCertificates) == StatusEnum.pass){
            passCertificates++;
        }
      })
  
      return {
          totalCertificates: totalCertificates,
          passedCertificates : passCertificates
      };
    } 

    getCertificateStatus(name: string, stafferCertificates): number { 
        if (!stafferCertificates[name] || stafferCertificates[name].length === 0) {
          return StatusEnum.missing
        } else if(
          stafferCertificates[name].every(
            c => c.markCertificateAsRejected.isDeleted
          )
        ) {
          return StatusEnum.notuploaded;
        } else if (stafferCertificates[name].rejectedFound !== 0) {
          return StatusEnum.fail;
        } 
        return StatusEnum.pass;
      }


      evaluateCertificateCompletion(requiredCertificates, userCertificates, byPassVSCFlow: boolean): StatusEnum {
        // No certificate uploaded yet (show arrow)
        const uploadedCertificates = Object.keys(userCertificates);

        //Evaluate required certificates based upon VSC Certificate or receipt. Mark other as optioanl if either of the two are uploaded 
        requiredCertificates = this.updateVSCRequiredCheck(requiredCertificates, uploadedCertificates)

        //Check if the mandatory certificates are uploaded and they are valid, if not then disable the submission 
        if(!byPassVSCFlow && this.checkMandatoryCertificates(requiredCertificates, userCertificates) === StatusEnum.block) {
            return StatusEnum.block;
        }

        if(uploadedCertificates.length === 0) {
            return StatusEnum.notuploaded;
        }

        // Some certificates not yet uploaded
        if (!requiredCertificates.every(cert => uploadedCertificates.includes(cert))) {
            return StatusEnum.missing;
        }

        // Default certificate state if all certificates are good to go
        let certificatesStatus = StatusEnum.pass;
        // Make an array from uploaded certificates object
        const userCertificateGroups = Object.entries(userCertificates);

        userCertificateGroups.every(([certKey, certGroup]) => {
            const certs = certGroup as Array<Certificate>;

            // Exclude deleted and experied certificates from array
            const excludeDeletedOrExpired = certs.filter(c => 
                c.markCertificateAsRejected.isDeleted === false && c.isExpired === false
            );

            // If after excluding those certificates remains an empty array it means 
            // that group is yet to be uploaded 
            if (!excludeDeletedOrExpired.length) {
                certificatesStatus = StatusEnum.missing;
                return false;
            }

            const isAnyApprovedExists = excludeDeletedOrExpired.filter(cert => 
                cert.markCertificateAsRejected.isRejected === false && cert.isApprove === true
            );

            if (!isAnyApprovedExists.length) {
                const isAnyPendingExists = excludeDeletedOrExpired.filter(cert => (!cert.markCertificateAsRejected.isRejected && !cert.isExpired) && !cert.isApprove );
    
                if (isAnyPendingExists.length) {
                    certificatesStatus = StatusEnum.pending;
                } else {
                    // If certificate is not approved neither in pending 
                    // it means certificate is rejected
                    certificatesStatus = StatusEnum.fail;
                    return false;
                }
            }

            return true;
        });
        
        return certificatesStatus;
    }

    getAllCertificatesByName(): string[] {
        let certificateList = [];
        const mergeCertificates = [...this.covidCertificates, ...this.healthcareCertificates, ...this.dietaryaideCertificates, ...this.pswCertificates, ...this.otherCertificates];
        
        // Return all certificates by name, should be moved to certificates.json like skills.json to keep one universal source of certificate names
        certificateList =  map(mergeCertificates, (certificate) => {
            return certificate.name;
        });  

        return orderBy(certificateList);
    }

    public async getAllCertificates(): Promise<Object> {
        if(!this.certificates && !this.isLoadingCertificates) {
            this.isLoadingCertificates = true;
            this.certificates = this.httpPromiseService.httpGetRequest('/api/certificates/certificates-json');
            await this.certificates;
            this.isLoadingCertificates = false;
        }

        return this.certificates;
    }
    
    checkMandatoryCertificates(requiredCertificates , userCertificates): StatusEnum {
        
        const mandatoryCertificates =  this.mandatoryCertificatesList();
        const isVSCRequired = _.intersection(requiredCertificates, mandatoryCertificates);
        
        //Check if VSC or Receipt of VSC is required. If any one found or both are found to be required then check if they are uploaded correctly 
        if(!isVSCRequired.length) {
            return StatusEnum.unblock;
        }

        //Check if the contractor has uploaded the mandatory certificates
        const isAnyMandatoryCertificateExists = mandatoryCertificates.some(item => userCertificates.hasOwnProperty(item));

        //If no mandatory certificate is uploaded, return a flag to disable submit button
        if(!isAnyMandatoryCertificateExists) {
            return StatusEnum.block;
        }

        //check if there is an uploaded VSC or receipt of VSC, then check whether they are valid or not 
        let matchingCertificates = _.flatMap(mandatoryCertificates, certType => userCertificates[certType.toString()] || []);
        //Combine both certificate groups using flatmap and check if any certificate exists that is neither expired, rejected or deleted 
        matchingCertificates =  _.filter((matchingCertificates), certs => {
            return !certs.isExpired && !certs.markCertificateAsRejected.isDeleted && !certs.markCertificateAsRejected.isRejected
        })     

        //Block state if no valid certificate/receiipt found
        if(!matchingCertificates.length) {
            return StatusEnum.block
        }

        return StatusEnum.unblock;
    }
    
    getRequestLetterModalMessageByCity(city: string): string {

        const message = {
            'Toronto': `<div class = "request-letter"><span>You can apply for a Vulnerable Sector Check by going to </span><a class = "request-link">https://www.tps.ca/services/police-record-checks/</a> <br/> and using the code <br/><span class = "code">202309TPSONFA562</span> to denote that you're a Staffy contractor</div>`,
            'Other': `<div class = "request-letter"><span>To apply for a Vulnerable Sector Check, look online for the entity responsible for managing this process in your area (usually municipal or provincial police) and follow their guidelines on the application process.<br/>You can download a request letter from Staffy here and provide it as part of your application.</div>`,
        }

        return message[city] || message['Other'];
    }

}
