import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, first, exhaustMap } from 'rxjs/operators';

import { shouldAppendHeadersTo } from '../utils/interceptors.utils';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { getLoggedInUserToken } from 'src/modules/authentication/+store/auth-selector';
import { logOutAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly storage: LocalStorageService,
    private readonly store: Store<IAuthState>,
    private configService: ConfigService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return shouldAppendHeadersTo(req)
      .pipe(exhaustMap(shouldAppend => (shouldAppend ? this.addAuthenticationToken(req) : of(req))))
      .pipe(switchMap(request => next.handle(request)))
      .pipe(catchError(err => this.handleExpiredToken(req, next)(err)));
  }

  private addAuthenticationToken(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    return from(this.store.pipe(select(getLoggedInUserToken), first())).pipe(
      map(token => req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) }))
    );
  }

  private handleExpiredToken(req: HttpRequest<any>, next: HttpHandler) {
    return (error: HttpErrorResponse) => {
      if (error) {
        if (error.status === 401) {
          // TODO: Implement Expired token handler
          // this.store.dispatch(signinRedirectAction());
          this.configService.revertToDefault();
          this.store.dispatch(logOutAction());
        }
        return throwError(error);
      }
    };
  }
}
