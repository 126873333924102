/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-task.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "@angular/forms";
import * as i7 from "./add-task.component";
import * as i8 from "../../../../shared/services/user.service";
import * as i9 from "@ngrx/store";
var styles_AddTaskComponent = [i0.styles];
var RenderType_AddTaskComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddTaskComponent, data: {} });
export { RenderType_AddTaskComponent as RenderType_AddTaskComponent };
function View_AddTaskComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "ion-text-center title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("TASKS.EDIT_TASK")); _ck(_v, 1, 0, currVal_0); }); }
function View_AddTaskComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "ion-text-center title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("TASKS.ADD_TASK")); _ck(_v, 1, 0, currVal_0); }); }
export function View_AddTaskComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "div", [["class", "add-task-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "heading-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddTaskComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddTaskComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 24, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 19, "ion-list", [], null, null, null, i4.View_IonList_0, i4.RenderType_IonList)), i1.ɵdid(10, 49152, null, 0, i5.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 8, "ion-item", [], null, null, null, i4.View_IonItem_0, i4.RenderType_IonItem)), i1.ɵdid(12, 49152, null, 0, i5.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 6, "ion-input", [["placeholder", "Task Title"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14)._handleInputEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.task.title = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_IonInput_0, i4.RenderType_IonInput)), i1.ɵdid(14, 16384, null, 0, i5.TextValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.TextValueAccessor]), i1.ɵdid(16, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(18, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), i1.ɵdid(19, 49152, null, 0, i5.IonInput, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { placeholder: [0, "placeholder"] }, null), (_l()(), i1.ɵeld(20, 0, null, 0, 8, "ion-item", [], null, null, null, i4.View_IonItem_0, i4.RenderType_IonItem)), i1.ɵdid(21, 49152, null, 0, i5.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(22, 0, null, 0, 6, "ion-textarea", [["placeholder", "Task Description (Required)"], ["rows", "5"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 23)._handleInputEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.task.description = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_IonTextarea_0, i4.RenderType_IonTextarea)), i1.ɵdid(23, 16384, null, 0, i5.TextValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.TextValueAccessor]), i1.ɵdid(25, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(27, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), i1.ɵdid(28, 49152, null, 0, i5.IonTextarea, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { placeholder: [0, "placeholder"], rows: [1, "rows"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 3, "div", [["class", "action-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 2, "button", [["class", "save-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addTask() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(31, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.task._id; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.task._id; _ck(_v, 5, 0, currVal_1); var currVal_9 = _co.task.title; _ck(_v, 16, 0, currVal_9); var currVal_10 = "Task Title"; _ck(_v, 19, 0, currVal_10); var currVal_18 = _co.task.description; _ck(_v, 25, 0, currVal_18); var currVal_19 = "Task Description (Required)"; var currVal_20 = "5"; _ck(_v, 28, 0, currVal_19, currVal_20); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 18).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 18).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 18).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 18).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 18).ngClassValid; var currVal_7 = i1.ɵnov(_v, 18).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 18).ngClassPending; _ck(_v, 13, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = i1.ɵnov(_v, 27).ngClassUntouched; var currVal_12 = i1.ɵnov(_v, 27).ngClassTouched; var currVal_13 = i1.ɵnov(_v, 27).ngClassPristine; var currVal_14 = i1.ɵnov(_v, 27).ngClassDirty; var currVal_15 = i1.ɵnov(_v, 27).ngClassValid; var currVal_16 = i1.ɵnov(_v, 27).ngClassInvalid; var currVal_17 = i1.ɵnov(_v, 27).ngClassPending; _ck(_v, 22, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_21 = i1.ɵunv(_v, 31, 0, i1.ɵnov(_v, 32).transform("TASKS.SAVE")); _ck(_v, 31, 0, currVal_21); }); }
export function View_AddTaskComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-add-task", [], null, null, null, View_AddTaskComponent_0, RenderType_AddTaskComponent)), i1.ɵdid(1, 114688, null, 0, i7.AddTaskComponent, [i8.UserService, i9.Store, i5.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddTaskComponentNgFactory = i1.ɵccf("app-add-task", i7.AddTaskComponent, View_AddTaskComponent_Host_0, { task: "task", entityId: "entityId" }, {}, []);
export { AddTaskComponentNgFactory as AddTaskComponentNgFactory };
