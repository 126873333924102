import * as tslib_1 from "tslib";
import { Network } from '@ionic-native/network/ngx';
import { TalentSyncService } from 'src/modules/talent-dashboard/services/talent-sync.service';
import { LocalStorageService } from './local-storage.service';
import { UserTypeEnum } from 'src/modules/authentication/enums/user-type.enum';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/network/ngx/index";
import * as i2 from "../../talent-dashboard/services/talent-sync.service";
import * as i3 from "./local-storage.service";
import * as i4 from "@ionic/angular";
export class NetworkService {
    constructor(network, talentSyncService, localStorageService, platform) {
        this.network = network;
        this.talentSyncService = talentSyncService;
        this.localStorageService = localStorageService;
        this.platform = platform;
        this.appNetworkStatus = new BehaviorSubject('online' || 'offline');
    }
    /////////////////////////////////////////////////////////
    watchForNetwork() {
        console.log('watch for network');
        if (this.platform.is('cordova')) {
            // watch network for a disconnection
            this.network.onDisconnect().subscribe(() => {
                console.log('network was disconnected :-(');
                this.appNetworkStatus.next('offline');
            });
            // // stop disconnect watch
            // disconnectSubscription.unsubscribe();
            // watch network for a connection
            this.network.onConnect().subscribe(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                console.log('network connected!');
                this.appNetworkStatus.next('online');
                const userData = yield this.localStorageService.getLoggedInUserInfo();
                // if user is loggedin and type is staffer and got wifi connection then sync it
                if (userData && userData._id && userData.type === UserTypeEnum.STAFFER) {
                    this.talentSyncService.sync(true);
                }
            }));
            // // stop connect watch
            // connectSubscription.unsubscribe();
        }
        else {
            fromEvent(window, 'offline').subscribe(() => {
                console.log('network disconnected:(');
                this.appNetworkStatus.next('offline');
            });
            fromEvent(window, 'online').subscribe(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                console.log('network connected!');
                this.appNetworkStatus.next('online');
                const userData = yield this.localStorageService.getLoggedInUserInfo();
                // if user is loggedin and type is staffer and got wifi connection then sync it
                if (userData && userData._id && userData.type === UserTypeEnum.STAFFER) {
                    this.talentSyncService.sync(true);
                }
            }));
        }
    }
}
NetworkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NetworkService_Factory() { return new NetworkService(i0.ɵɵinject(i1.Network), i0.ɵɵinject(i2.TalentSyncService), i0.ɵɵinject(i3.LocalStorageService), i0.ɵɵinject(i4.Platform)); }, token: NetworkService, providedIn: "root" });
