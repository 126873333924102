import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SearchableSelectModalComponent } from './searchable-select-modal/searchable-select-modal.component';
import { ControlValueAccessor, FormControl } from '@angular/forms';
export class SearchableSelectComponent {
    constructor(modalController) {
        this.modalController = modalController;
        this.selectedOptions = [];
        this.multiple = false;
        this.labelText = '';
        this.onSelect = new EventEmitter();
        this.placeHolderText = "Select Sub Skill";
        this.propagateChange = (_) => { };
        this.propagateTouched = (_) => { };
    }
    get subSkills() {
        return this._subSkills;
    }
    set subSkills(value) {
        this._subSkills = value;
        this.onSelect.emit(this._subSkills);
        this.propagateChange(this._subSkills);
    }
    ngOnInit() {
        this.originalSelectedOptions = this.multiple ? [...this.selectedOptions] : this.selectedOptions;
    }
    ngOnChanges() {
        if (!this.multiple) {
            this.originalSelectedOptions = this.selectedOptions;
            this.placeHolderText = this.originalSelectedOptions;
        }
        if (this.multiple && !this.selectedOptions.length || (this.control && this.control.pristine && !this.control.value.length)) {
            this.placeHolderText = "Select Sub Skill";
            this.originalSelectedOptions = [...this.selectedOptions];
        }
    }
    /**
     *
     * @param selectedOptions
     * @description this function handle when select button is called
     */
    handleSelect(selectedOptions) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (selectedOptions.length) {
                this.placeHolderText = selectedOptions.join(",");
            }
            else {
                this.placeHolderText = "Select Sub Skill";
            }
            this.originalSelectedOptions = [...selectedOptions];
            this.onSelect.emit(selectedOptions);
            this.subSkills = selectedOptions;
            yield this.alertController.dismiss();
        });
    }
    /**
     * this function handle the cancel button and dismiss the modal
     */
    handleCancel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.multiple) {
                this.selectedOptions = this.originalSelectedOptions;
                yield this.alertController.dismiss();
                return;
            }
            this.selectedOptions = [...this.originalSelectedOptions];
            this.subSkills = this.selectedOptions;
            yield this.alertController.dismiss();
        });
    }
    /**
     * @descripiton this function init the searchable select modal
     */
    handleSubSkillDropDownClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.alertController) {
                yield this.alertController.dismiss();
            }
            this.alertController = yield this.modalController.create({
                component: SearchableSelectModalComponent,
                backdropDismiss: false,
                cssClass: 'searchable-select-modal',
                componentProps: {
                    options: this.options,
                    selectedOptions: this.selectedOptions,
                    onCancel: this.handleCancel.bind(this),
                    multiple: this.multiple,
                    onSelect: this.handleSelect.bind(this)
                }
            });
            yield this.alertController.present();
        });
    }
    //Form Accessor Functions for custom components
    writeValue(value) {
        this._subSkills = value;
    }
    registerOnChange(fn) {
        this.propagateChange = fn;
    }
    registerOnTouched(fn) {
        this.propagateTouched = fn;
    }
}
