import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
    providedIn: 'root'
})
export class HelpService {
    constructor(
        private httpPromiseService: HttpPromiseService,
        private inAppBrowser: InAppBrowser,
    ) { }

    public sendContactUsEmail(body: FormData) {
        return this.httpPromiseService.httpPostRequest(
            `/api/appInfo/sendContactUsEmail`, body
        );
    }
    public sendTestPushNotification(userId: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/appInfo/testPushNotification/${userId}`, {}
        );
    }

    
    openAppBrowserLink(url) {
        var target = "_blank";
        var options = "location=yes";
        let popup = this.inAppBrowser.create(url, target, options);
        
        popup.show();
    }
}

