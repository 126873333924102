import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { JobService } from 'src/modules/shared/services/job.service';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { PreviousHireAddToTeamComponent } from 'src/modules/company-dashboard/components/previous-hire-add-to-team/previous-hire-add-to-team.component';
export class ShiftHistoryDetailComponent {
    constructor(popupService, modalController, jobService) {
        this.popupService = popupService;
        this.modalController = modalController;
        this.jobService = jobService;
        this.shiftStatus = 'completed';
        this.teams = [];
        this.unitNumber = [];
    }
    ngOnInit() {
        this.contractorPastShifts = this.contractorPastShifts.map(item => {
            return Object.assign({}, item);
        });
    }
    dismiss() {
        this.modalController.dismiss();
    }
    addToTeam(stafferId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                //Load team data when add a team is clicked
                this.teams = yield this.jobService.getTeams(this.userData.entityId._id);
                //No team found
                if (!this.teams || this.teams.length === 0) {
                    this.showPopup('Sorry!', 'No team found', 'assets/images/sorry1.png');
                    return;
                }
                //Show a list of all teams of the client in a popup
                const myModal = yield this.modalController.create({
                    component: PreviousHireAddToTeamComponent,
                    cssClass: 'forgotPassModal',
                    componentProps: {
                        teams: this.teams
                    }
                });
                yield myModal.present();
                myModal.onDidDismiss()
                    .then((dataReturned) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    const team = dataReturned.data;
                    if (!team) {
                        return;
                    }
                    //Check if contarctor is added in the team, if not update the team with contractor id 
                    if (!this.jobService.hasStafferExistInTheTeam(team, stafferId)) {
                        //Push staffer id into team array containing othher staffers id
                        const teamStaffers = team.staffers.map((staffer) => {
                            return staffer._id;
                        });
                        teamStaffers.push(stafferId);
                        try {
                            yield this.jobService.updateTeam(team._id, {
                                teamStaffers
                            });
                            this.showPopup('Success', 'Team updated Successfully', 'assets/images/thumbs-up.png');
                        }
                        catch (error) {
                            this.showPopup('Sorry!', 'Unable to add staffer right now', 'assets/images/sorry1.png');
                        }
                    }
                    else {
                        //Show error if contractor is already added in the selected team
                        this.showPopup('Sorry!', 'Contractor already exist in the team', 'assets/images/sorry1.png');
                    }
                }));
            }
            catch (error) {
                console.log('error while adding contractor ', this.teams, stafferId);
                this.showPopup('Sorry!', 'Unable to add staffer right now', 'assets/images/sorry1.png');
            }
        });
    }
    showPopup(heading, message, imgUrl) {
        this.popupService.showModal({
            heading: heading,
            message: message,
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: imgUrl
        });
    }
    hireAgain() {
        this.modalController.dismiss('hireStaffer');
    }
}
