import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getNotificationCount, getShiftInProgress, getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { AccountTypeEnum } from 'src/modules/shared/enums/company-account-type.enum';
import { AppInfoService } from 'src/modules/shared/services/app-info.service';
import { JobService } from 'src/modules/shared/services/job.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { NetworkService } from 'src/modules/shared/services/network.service';
import { NotificationService } from 'src/modules/shared/services/notification.service';
import { PushNotificationService } from 'src/modules/shared/services/push-notification.service';
import { SideMenuUtilService } from 'src/modules/shared/services/sideMenu-util.service';
import { SkillFilterationService } from 'src/modules/shared/services/skill-filteration.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { TalentSyncService } from 'src/modules/talent-dashboard/services/talent-sync.service';
import { LayoutService } from '../layout.service';
export class ContractorAppLayoutComponent {
    constructor(sideMenuUtilService, store, menuCtrl, navCtrl, networkService, talentSyncService, notificationService, pushNotificationService, jobService, localStorageService, appInfoService, loadingService, utilService, skillFilterationService, menuController, router, layoutService) {
        this.sideMenuUtilService = sideMenuUtilService;
        this.store = store;
        this.menuCtrl = menuCtrl;
        this.navCtrl = navCtrl;
        this.networkService = networkService;
        this.talentSyncService = talentSyncService;
        this.notificationService = notificationService;
        this.pushNotificationService = pushNotificationService;
        this.jobService = jobService;
        this.localStorageService = localStorageService;
        this.appInfoService = appInfoService;
        this.loadingService = loadingService;
        this.utilService = utilService;
        this.skillFilterationService = skillFilterationService;
        this.menuController = menuController;
        this.router = router;
        this.layoutService = layoutService;
        this.showBackBtn = false;
        this.notificationCount$ = this.store.pipe(select(getNotificationCount));
        this.shiftInProgress$ = this.store.pipe(select(getShiftInProgress));
        this.totalRequestCount = 0;
        this.openRequestCount = 0;
        this.filledRequestCount = 0;
        this.isOnline = true;
    }
    ngOnInit() {
        this.userSubscription$ = this.store
            .pipe(select(getUserInfo), filter(user => !!user))
            .subscribe(user => {
            this.user = user;
            this.setMenuItems(this.user.type);
            this.skills = this.user.skills.filter(skill => skill.has == true);
        });
        this.networkService.appNetworkStatus.subscribe(status => {
            this.isOnline = status === 'online';
        });
    }
    ngOnDestroy() {
        if (this.userSubscription$) {
            this.userSubscription$.unsubscribe();
        }
        if (this.networkSubscription$) {
            this.networkSubscription$.unsubscribe();
        }
    }
    closeMenu() {
        this.menuController.close();
    }
    navBack() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.showBackBtn = false;
            if (this.user.type) {
                yield this.setMenuItems(this.user.type);
            }
        });
    }
    setMenuItems(type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.appPages =
                type === AccountTypeEnum.EMPLOYER ? this.sideMenuUtilService.getMenuForCompany(false) : this.sideMenuUtilService.getMenuForStaffy();
            yield this.checkForValidation();
        });
    }
    checkForValidation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.appPages.forEach((page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                page.show = true;
                if (page.title === 'Wallet' && this.user.address) {
                    this.city = this.user.address.city === 'New_York' || this.user.address.province === 'Texas' ? 'US' : 'CA';
                }
                if (page.title == 'Requests') {
                    try {
                        this.openRequestCount = yield this.jobService.getOpenRequestCount();
                        if (typeof this.openRequestCount == 'object') {
                            this.openRequestCount = 0;
                        }
                        this.filledRequestCount = yield this.jobService.getFilledRequestCount();
                        if (typeof this.filledRequestCount == 'object') {
                            this.filledRequestCount = 0;
                        }
                    }
                    catch (error) {
                        console.log('error: ', error);
                    }
                    finally {
                        this.totalRequestCount =
                            (this.openRequestCount ? this.openRequestCount : 0) + (this.filledRequestCount ? this.filledRequestCount : 0);
                    }
                }
                if (page.title === 'Direct Hire') {
                    page.show = this.user.enableDirectHire;
                }
                if (page.title === 'Add Manual Time') {
                    page.show = !!this.user.enableManualHoursOnApp;
                }
                if (page.subMenu) {
                    page.subMenu.forEach((subPage, i) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (page.title == 'Settings' && subPage.title === 'Edit Picture') {
                            const isHCW = yield this.skillFilterationService.doesUserHasHCSkills(this.skills);
                            if (isHCW && this.user.profile_approved === true) {
                                subPage.show = false;
                            }
                            else {
                                subPage.show = true;
                            }
                        }
                        else if (page.title == 'Settings' &&
                            subPage.title === 'Set Sub Skills' &&
                            this.utilService.checkNursingSkills(this.skills) == false) {
                            subPage.show = false;
                        }
                        else {
                            subPage.show = true;
                        }
                        if (page.title === 'Direct Hire') {
                            page.show = this.user.enableDirectHire;
                        }
                        if (page.title === 'Add Manual Time') {
                            page.show = !!this.user.enableManualHoursOnApp;
                        }
                        if (page.subMenu == 'Open Requests') {
                            try {
                                this.openRequestCount = yield this.jobService.getOpenRequestCount();
                                if (typeof this.openRequestCount == 'object') {
                                    this.openRequestCount = 0;
                                }
                            }
                            catch (error) {
                                console.log('error: ', error);
                            }
                            finally {
                                this.totalRequestCount =
                                    (this.openRequestCount ? this.openRequestCount : 0) + (this.filledRequestCount ? this.filledRequestCount : 0);
                            }
                        }
                        if (page.subMenu == 'Filled Requests') {
                            try {
                                this.filledRequestCount = yield this.jobService.getFilledRequestCount();
                                if (typeof this.filledRequestCount == 'object') {
                                    this.filledRequestCount = 0;
                                }
                            }
                            catch (error) {
                                console.log('error: ', error);
                            }
                            finally {
                                this.totalRequestCount =
                                    (this.openRequestCount ? this.openRequestCount : 0) + (this.filledRequestCount ? this.filledRequestCount : 0);
                            }
                        }
                    }));
                }
            }));
        });
    }
    navigate(page) {
        this.layoutService.navigate(page, this.user, (page, setActiveMenu = false) => {
            this.showBackBtn = true;
            this.appPages = page.subMenu;
            if (setActiveMenu) {
                this.activeMenu = page;
            }
        });
    }
}
