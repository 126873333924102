import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITeam } from '../../interfaces/team.interface';

@Component({
  selector: 'app-previous-hire-add-to-team',
  templateUrl: './previous-hire-add-to-team.component.html',
  styleUrls: ['./previous-hire-add-to-team.component.scss'],
})
export class PreviousHireAddToTeamComponent implements OnInit {
  selectedOpt;
  @Input() teams: ITeam[];

  constructor(private modalController: ModalController) { }

  ngOnInit() {

  }

  cancel() {
    this.modalController.dismiss();
  }

  submit() {
    if (this.selectedOpt) {
      this.modalController.dismiss(this.selectedOpt);
    }

  }

}
