/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../pipes/sanitize-html.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i7 from "@ionic/angular";
import * as i8 from "./notes.component";
var styles_NotesComponent = [i0.styles];
var RenderType_NotesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotesComponent, data: {} });
export { RenderType_NotesComponent as RenderType_NotesComponent };
function View_NotesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p", [["class", "notes-verbiage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " : "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("NOTES.Shift_Notes")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _co.item.shiftNotes)); _ck(_v, 4, 0, currVal_1); }); }
function View_NotesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "notes-verbiage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " : "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("NOTES.Site_Notes")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.item.entity.siteNotes; _ck(_v, 4, 0, currVal_1); }); }
export function View_NotesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SanitizeHtmlPipe, [i4.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "root"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "scroll-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "flex-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notes"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "notes-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesComponent_1)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ion-button", [["class", " ion-no-margin btn success-popup-ok"], ["color", "dark"], ["expand", "full"], ["id", "success-popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_IonButton_0, i6.RenderType_IonButton)), i1.ɵdid(12, 49152, null, 0, i7.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, ["Dismiss "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.shiftNotes; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.item.entity.siteNotes; _ck(_v, 10, 0, currVal_1); var currVal_2 = "dark"; var currVal_3 = "full"; _ck(_v, 12, 0, currVal_2, currVal_3); }, null); }
export function View_NotesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notes", [], null, null, null, View_NotesComponent_0, RenderType_NotesComponent)), i1.ɵdid(1, 114688, null, 0, i8.NotesComponent, [i7.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotesComponentNgFactory = i1.ɵccf("app-notes", i8.NotesComponent, View_NotesComponent_Host_0, { item: "item" }, {}, []);
export { NotesComponentNgFactory as NotesComponentNgFactory };
