import { Component, OnInit, Input } from '@angular/core';
import { ITalentJob } from '../../../talent-dashboard/interfaces/talent-job.interface';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-stat-pay',
  templateUrl: './stat-pay.component.html',
  styleUrls: ['./stat-pay.component.scss'],
})
export class StatPayComponent implements OnInit {

  @Input() statPayJobs: ITalentJob;

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    if (this.statPayJobs && this.statPayJobs.jobs.length) {
      this.formatJobs();
    }
  }

  formatJobs() {
    this.statPayJobs.jobs.forEach(job => {
    job.momentedShiftStartTimeTz = moment.unix(job.shiftStartTime).tz(job.timeZone).format('MMM DD, YYYY')
    });
  }

  submit() {
    this.modalController.dismiss();
  }
  
}
