import { Injectable } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import {
    FileTransfer,
    FileUploadOptions,
    FileTransferObject
} from '@ionic-native/file-transfer/ngx';
import { environment } from 'src/environments/environment';
import { Subscription, from, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';


@Injectable({
    providedIn: 'root'
})
export class ImagesService {
    subscription: Subscription;
    token: string;
    constructor(
        private camera: Camera,
        private transfer: FileTransfer,
    ) { }

    choosePicture(srcType: string

        // 'CAMERA' | 'GALLERY'
    ) {
        const options: CameraOptions = {
            quality: 75,
            destinationType: this.camera.DestinationType.FILE_URI,
            sourceType: srcType === 'CAMERA' ? this.camera.PictureSourceType.CAMERA : this.camera.PictureSourceType.PHOTOLIBRARY,
            encodingType: this.camera.EncodingType.JPEG,
            targetWidth: 720,
            saveToPhotoAlbum: false,
            correctOrientation: true
        };

        return from(this.camera.getPicture(options)) as Observable<string>;
    }


    uploadPicture(imageData: string, token: string) {
        const fileTransfer: FileTransferObject = this.transfer.create();

        const uploadOptions: FileUploadOptions = {
            fileKey: 'images',
            fileName: 'avatar.jpeg',
            chunkedMode: true,
            mimeType: 'image/jpeg',
            headers: {
                Authorization: 'Bearer ' + token
            }
        };

        const server = environment.API_BASE_URL + '/api/users/avatar';
        const filePath = imageData;

        return from(fileTransfer.upload(
            filePath,
            server,
            uploadOptions
        ));


    }



    getProfilePic(profileUrl: { [key: string]: string }, dimensions?: string) {
        
        if (profileUrl && !profileUrl.error) {
            // resize it and return the url
            let imageUrl = profileUrl.url.slice(0, 46);
            const imageName = profileUrl.url.slice(45);
            const imageSize = dimensions || 'w_300,h_300';
            imageUrl = imageUrl + imageSize + imageName;
            return imageUrl;
        }

        return ('assets/images/staffy-gray.png');
    }
}
