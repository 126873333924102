/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pdf-viewer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng2-pdf-viewer/ng2-pdf-viewer.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "ng2-pdf-viewer";
import * as i5 from "./pdf-viewer.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../services/popup.service";
var styles_PdfViewerComponent = [i0.styles];
var RenderType_PdfViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PdfViewerComponent, data: {} });
export { RenderType_PdfViewerComponent as RenderType_PdfViewerComponent };
function View_PdfViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "pdf-viewer", [["class", "pdf-viewer"]], null, [[null, "text-layer-rendered"], [null, "error"], [null, "on-progress"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onPageResize() !== false);
        ad = (pd_0 && ad);
    } if (("text-layer-rendered" === en)) {
        var pd_1 = (_co.textLayerRendered() !== false);
        ad = (pd_1 && ad);
    } if (("error" === en)) {
        var pd_2 = (_co.onError() !== false);
        ad = (pd_2 && ad);
    } if (("on-progress" === en)) {
        var pd_3 = (_co.onProgress() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_PdfViewerComponent_0, i2.RenderType_PdfViewerComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "blurDocument": 0 }), i1.ɵdid(5, 9158656, null, 0, i4.PdfViewerComponent, [i1.ElementRef], { src: [0, "src"], originalSize: [1, "originalSize"], showAll: [2, "showAll"], stickToPage: [3, "stickToPage"], autoresize: [4, "autoresize"], showBorders: [5, "showBorders"] }, { textLayerRendered: "text-layer-rendered", onError: "error", onProgress: "on-progress" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pdf-viewer"; var currVal_1 = _ck(_v, 4, 0, _co.showLoading); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = ((_co.sanitizeUrl == null) ? null : _co.sanitizeUrl.changingThisBreaksApplicationSecurity); var currVal_3 = false; var currVal_4 = true; var currVal_5 = true; var currVal_6 = false; var currVal_7 = false; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_PdfViewerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfViewerComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.sanitizeUrl == null) ? null : _co.sanitizeUrl.changingThisBreaksApplicationSecurity); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PdfViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-viewer", [], null, null, null, View_PdfViewerComponent_0, RenderType_PdfViewerComponent)), i1.ɵdid(1, 114688, null, 0, i5.PdfViewerComponent, [i6.DomSanitizer, i7.PopupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PdfViewerComponentNgFactory = i1.ɵccf("app-pdf-viewer", i5.PdfViewerComponent, View_PdfViewerComponent_Host_0, { url: "url" }, {}, []);
export { PdfViewerComponentNgFactory as PdfViewerComponentNgFactory };
