import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IDatePickerConfig } from 'ng2-date-picker';
import { BroadcastJobsUtilService } from 'src/modules/company-dashboard/services/broadcast-job-utils.service';
import { RecurrentShiftService } from 'src/modules/talent-dashboard/services/recurrentShift.service';


@Component({
  selector: 'app-recurrent-shift-list-item',
  templateUrl: './recurrent-shift-list-item.component.html',
  styleUrls: ['./recurrent-shift-list-item.component.scss'],
})
export class RecurrentShiftListItemComponent implements OnInit {

  @Input() config: Array<string>;
  @Input() data;

  dateConfig: IDatePickerConfig = {
    allowMultiSelect: false,
    format: 'DD MMM',
    closeOnSelect: true,
    showGoToCurrent: true,
    disableKeypress: true,
    unSelectOnClick: false  
  };

  constructor(private cdr: ChangeDetectorRef,private broadcastJobsUtilService:BroadcastJobsUtilService ,private recurrentShiftService: RecurrentShiftService) {}

  ngOnInit(){



  }

}