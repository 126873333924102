/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-resume-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./add-resume-form.component";
import * as i4 from "../../../shared/services/popup.service";
var styles_AddResumeFormComponent = [i0.styles];
var RenderType_AddResumeFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddResumeFormComponent, data: {} });
export { RenderType_AddResumeFormComponent as RenderType_AddResumeFormComponent };
export function View_AddResumeFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "resume"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "label", [["class", "custom-file-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "i", [["class", "fa fa-upload"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "enableText": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 4, "p", [["class", "text"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "enableText": 0 }), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, [["imageInput", 1]], null, 0, "input", [["id", "chooseFile"], ["name", "resumeUrl"], ["type", "file"]], [[8, "placeholder", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event, i1.ɵnov(_v, 11)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fa fa-upload"; var currVal_1 = _ck(_v, 5, 0, ((_co.userData == null) ? null : ((_co.userData.resumeUrl == null) ? null : _co.userData.resumeUrl.name))); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = "text"; var currVal_3 = _ck(_v, 9, 0, ((_co.userData == null) ? null : ((_co.userData.resumeUrl == null) ? null : _co.userData.resumeUrl.name))); _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (((_co.userData == null) ? null : ((_co.userData.resumeUrl == null) ? null : _co.userData.resumeUrl.name)) || _co.blankResume); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.blankResume; _ck(_v, 11, 0, currVal_5); }); }
export function View_AddResumeFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-add-resume-form", [], null, null, null, View_AddResumeFormComponent_0, RenderType_AddResumeFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.AddResumeFormComponent, [i4.PopupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddResumeFormComponentNgFactory = i1.ɵccf("app-add-resume-form", i3.AddResumeFormComponent, View_AddResumeFormComponent_Host_0, { userData: "userData" }, { fileSubmitted: "fileSubmitted" }, []);
export { AddResumeFormComponentNgFactory as AddResumeFormComponentNgFactory };
