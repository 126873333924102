<div class="ion-justify-content-center ion-text-center child">
  <h1 class="">
    Select Team </h1>

  <div class="form-wrapper">
    <select [(ngModel)]="selectedOpt" class="list">
      <option [value]="undefined" selected disabled>
        Select Team*
      </option>
      <option *ngFor="let team of teams" [ngValue]="team">
        {{team.name}}
      </option>

    </select>
  </div>




</div>
<div class=" button-stack">

  <button (click)="submit()" class="btn blue">
    SUBMIT
  </button>




  <button (click)="cancel()" class="btn black">
    CANCEL
  </button>
</div>