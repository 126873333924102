<div class = "img-container" *ngIf = "contractorInfo?.length && jobStatus !== 'open'">
  <div *ngFor = "let attendant of contractorInfo | slice: 0:maxSliceCount; let i = index">
    <div class = "img-inner" *ngIf = "i < maxSliceCount">
      <button class = "image-circle" (mouseover) = "mouseOver(attendant.firstName)">
        <img  *ngIf = "attendant?.profileUrl && showImage" [src] = "attendant.profileUrl"/>
        <div class = "tooltip-name" [attr.id] = "attendant.firstName" #attendant.firstName>{{attendant.firstName}} {{attendant.lastName}}</div>
      </button>
    </div>
  </div>
  <div class = "dotted-circle darkFont" *ngIf = "jobStatus === 'open' || jobStatus === 'partial'"></div>
  <span *ngIf = "contractorInfo.length >=maxSliceCount" class = "remaining-count">+{{contractorInfo.length - 7}}</span>
</div>

<div class = "dotted-circle darkFont" *ngIf = "jobStatus === 'open'"></div>
  