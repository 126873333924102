/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-patient-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../places-autocomplete/places-autocomplete.component.ngfactory";
import * as i4 from "../places-autocomplete/places-autocomplete.component";
import * as i5 from "@angular/common";
import * as i6 from "./new-patient-form.component";
import * as i7 from "@ionic/angular";
import * as i8 from "../../services/user.service";
import * as i9 from "../../../company-dashboard/services/broadcast-job-utils.service";
import * as i10 from "@ngrx/store";
var styles_NewPatientFormComponent = [i0.styles];
var RenderType_NewPatientFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewPatientFormComponent, data: {} });
export { RenderType_NewPatientFormComponent as RenderType_NewPatientFormComponent };
function View_NewPatientFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errMsg; _ck(_v, 1, 0, currVal_0); }); }
function View_NewPatientFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNewPatient() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], null, null); }
function View_NewPatientFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updatePatient() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Update"]))], null, null); }
export function View_NewPatientFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "patient-form-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add New Patient"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Patient ID"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "input", [["class", "form-input"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (_co.handlePatientChange() !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.name = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(10, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(12, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Postal Code"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-places-autocomplete", [["class", "auto-input form-input"]], null, [[null, "addressChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addressChanged" === en)) {
        var pd_0 = (_co.getAddress($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_PlacesAutocompleteComponent_0, i3.RenderType_PlacesAutocompleteComponent)), i1.ɵdid(16, 4308992, null, 0, i4.PlacesAutocompleteComponent, [], { placeholder: [0, "placeholder"], class: [1, "class"], formatedAddress: [2, "formatedAddress"] }, { addressChanged: "addressChanged" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewPatientFormComponent_1)), i1.ɵdid(18, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewPatientFormComponent_2)), i1.ɵdid(21, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewPatientFormComponent_3)), i1.ɵdid(23, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.name; _ck(_v, 10, 0, currVal_7); var currVal_8 = ""; var currVal_9 = "auto-input form-input"; var currVal_10 = ((_co.patientAddress == null) ? null : _co.patientAddress.street); _ck(_v, 16, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.errMsg; _ck(_v, 18, 0, currVal_11); var currVal_12 = !_co.patientId; _ck(_v, 21, 0, currVal_12); var currVal_13 = _co.patientId; _ck(_v, 23, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 12).ngClassValid; var currVal_5 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_NewPatientFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-patient-form", [], null, null, null, View_NewPatientFormComponent_0, RenderType_NewPatientFormComponent)), i1.ɵdid(1, 114688, null, 0, i6.NewPatientFormComponent, [i7.ModalController, i8.UserService, i9.BroadcastJobsUtilService, i10.Store, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewPatientFormComponentNgFactory = i1.ɵccf("app-new-patient-form", i6.NewPatientFormComponent, View_NewPatientFormComponent_Host_0, { entityId: "entityId", addPatientAddress: "addPatientAddress", class: "class", patientId: "patientId", name: "name", patientAddress: "patientAddress" }, {}, []);
export { NewPatientFormComponentNgFactory as NewPatientFormComponentNgFactory };
