import * as tslib_1 from "tslib";
import { getStateAndCity, findCountryFromState } from 'src/modules/onboarding-company/utils/helpers/business-contract.helper';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import { StafferUtilService } from './contract-email.util.service';
import { forEach } from 'lodash';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { Router } from '@angular/router';
import { OnBoardingStafferService } from './onboarding-staffer.service';
import * as i0 from "@angular/core";
import * as i1 from "./contract-email.util.service";
import * as i2 from "./onboarding-staffer.service";
import * as i3 from "../../shared/services/local-storage.service";
import * as i4 from "@angular/router";
import * as i5 from "../../shared/services/skills.service";
import * as i6 from "../../shared/services/popup.service";
import * as i7 from "@ionic/angular";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { SkillsService } from './../../shared/services/skills.service';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { SuccessPopupComponent } from 'src/modules/shared/components/success-popup/success-popup.component';
import { ModalController } from '@ionic/angular';
export class OnboardingStafferUtilService {
    constructor(stafferUtilService, onBoardingStafferService, localStorageService, router, skillsService, popupService, modalController) {
        this.stafferUtilService = stafferUtilService;
        this.onBoardingStafferService = onBoardingStafferService;
        this.localStorageService = localStorageService;
        this.router = router;
        this.skillsService = skillsService;
        this.popupService = popupService;
        this.modalController = modalController;
    }
    createQuizErrorHandler(user, quiz) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const chancesLeft = 3 - quiz.length;
            const totalQuestions = quiz[quiz.length - 1].questions.length;
            const message = `Sorry, you only correctly answered ${quiz[quiz.length - 1].score} out of ${totalQuestions} questions. \n Please review your answers and try again, you still have ${chancesLeft} more chances left to pass the quiz. \n If you need help click on the "I need help with this" button on the top right."`;
            let buttonText = 'Try Again';
            let data = {
                heading: quiz[quiz.length - 1].percentage + '%',
                message: message,
                btn: buttonText,
                navigateRoute: '',
                imgURL: 'assets/images/sorry1.png'
            };
            if (chancesLeft == 0 || quiz[quiz.length - 1].percentage >= 75)
                return;
            if (quiz[quiz.length - 1].percentage < 75) {
                const myModal = yield this.modalController.create({
                    component: SuccessPopupComponent,
                    cssClass: 'wideModal',
                    backdropDismiss: false,
                    componentProps: {
                        data: data
                    }
                });
                yield myModal.present();
                return yield myModal.onDidDismiss().then(() => {
                    return;
                });
            }
        });
    }
    sendStafferEmail(user) {
        if (getStateAndCity(user.address.city).country === 'United States'
            || findCountryFromState(user.address.province) === 'United States') {
            return this.makeStafferContractPdfAndSendEmail(user);
        }
        else {
            return this.makeStafferContractPdfAndSendEmail(user);
        }
    }
    makeStafferContractPdfAndSendEmail(user) {
        const content = this.stafferUtilService.getStafferContent(user);
        const docDefinition = {
            content: content,
            styles: {
                header: {
                    fontSize: 18,
                    bold: true
                },
                subheader: {
                    fontSize: 15,
                    bold: true
                },
                bolder: {
                    fontSize: 13,
                    bold: true
                },
                boldest: {
                    fontSize: 16,
                    bold: true
                },
                quote: {
                    italics: true
                },
                small: {
                    fontSize: 8
                }
            }
        };
        const getPDFFile = pdfMake.createPdf(docDefinition);
        return new Promise((resolve, reject) => getPDFFile.getBase64((file) => {
            resolve(file);
        }));
    }
    verifyStafferWithExperience(skills) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const skillsWithExperience = yield this.skillsService.getListOfAllSkills({ outputFormat: 'experience' });
            // check staffer skill experience
            // if experience is enough to approve then approve the staffer otherwise reject
            forEach(skills, (skill) => {
                for (const key in skillsWithExperience) {
                    if (key === skill.name && skill.experience >= skillsWithExperience[key]) {
                        // isApproveUser = true;
                        skill.has = true;
                    }
                    // If any skill have less experience remove that skill from user object
                    else if (key === skill.name && skill.experience < skillsWithExperience[key]) {
                        skill.has = false;
                    }
                }
            });
            skills = skills.filter((s) => {
                return s.has;
            });
            return skills;
        });
    }
    getTimePeriodOptions() {
        const experience = [];
        for (let i = 1; i <= 23; i++) {
            const obj = {};
            if (i === 1) {
                obj['value'] = i + ' month';
                obj['name'] = i;
            }
            else {
                obj['value'] = i + ' months';
                obj['name'] = i;
            }
            experience.push(obj);
        }
        for (let i = 2; i <= 15; i++) {
            const obj = {};
            obj['value'] = i + ' years';
            obj['name'] = i * 12;
            experience.push(obj);
        }
        return experience;
    }
    fetchExperience(matchValue) {
        const experienceList = this.getTimePeriodOptions();
        const index = experienceList.findIndex((i) => { if (i.name == matchValue) {
            return i.value;
        } });
        console.log('index: ', index);
        if (index == -1)
            return;
        return experienceList[index].value;
    }
    getSuffix(i) {
        const j = i % 10, k = i % 100;
        if (j === 1 && k !== 11) {
            return i + 'st';
        }
        if (j === 2 && k !== 12) {
            return i + 'nd';
        }
        if (j === 3 && k !== 13) {
            return i + 'rd';
        }
        return i + 'th';
    }
    ensureSignupCodeAndNavigate() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const signupCode = yield this.localStorageService.getSignupCode();
            if (!signupCode) {
                this.router.navigateByUrl('onboarding-staffer/staffer-contract');
            }
            else {
                try {
                    yield this.onBoardingStafferService.verfiyCode(signupCode);
                    this.router.navigateByUrl('onboarding-staffer/license-agreement');
                }
                catch (e) {
                    this.router.navigateByUrl('onboarding-staffer/staffer-contract');
                }
            }
        });
    }
    assignWidgetSlideText() {
        const instructionObject = [
            {
                id: 0, imgUrl: 'assets/images/widget0.jpg', heading: 'You’re on a path to work when and where you want as an ', subheading: 'independent contractor.', text: 'You’ve just completed the first step on your journey to work when and where you want as an independent contractor. By completing the following steps, you are entering one of the most connected and skilled marketplace of workers, supporting businesses and organizations across North America. Staffy gives you the freedom to decide when, where and how you work. Complete your profile today to gain access to the wide range of flexible earning opportunities available through Staffy.', btnText: 'Next'
            },
            {
                id: 1, imgUrl: 'assets/images/widget1.jpg', heading: 'You’re an independent contractor using the', subheading: 'Staffy platform', text: 'You are an independent contractor, not an employee. As an independent contractor, you can accept and decline shifts in the app as you wish. This not only means there are no minimums on the number of shifts you need to accept, but allows contractors to be active on other marketplaces. Be sure to check the disclaimers and shift notes for any important information relating to the shift, facility, or role. Once you have been selected for a shift, you will receive a notification in the Staffy app letting you know you have been approved and prompting you to confirm your shift.', btnText: 'Next'
            },
            {
                id: 2, imgUrl: 'assets/images/widget2.jpg', heading: 'Be punctual and responsible', subheading: 'and get more shifts.', text: 'Any questions or concerns should be brought to the Staffy customer success team via text message (SMS). Communication with the team is crucial, especially if you are running late for your shift or experiencing any issues with the Staffy app. In the event that you need to cancel a shift due to an emergency, you must either provide 48 hours of notice, or written documentation of an extenuating circumstance within 72 hours. Be sure to let the Staffy team know as soon as you know you won’t be able to attend the shift and provide the necessary documentation. Since no-shows and last minute cancellations greatly affect the quality of service we are able to provide to our clients, any undocumented cancellations will be subject to a $150 cancellation fee.', btnText: 'Next'
            },
            {
                id: 3, imgUrl: 'assets/images/widget3.jpg', heading: 'Clock-in, clock-out, and ', subheading: 'get paid.', text: 'Track your hours for each shift by clocking in and out directly in the Staffy app. After completing your shift, Staffy issues payments after 5 calendar days of the shift\'s start date. Payments are sent by email and deposited via e-transfer to your bank account. Be sure to keep track of your invoices for tax purposes, as we do not provide T4s or T4As to our contractors.', btnText: 'Next'
            },
            {
                id: 4, imgUrl: 'assets/images/widget4.jpg', heading: 'Earn bonuses and ', subheading: 'other rewards', text: 'Refer your colleagues or organization to Staffy and receive referral bonuses for spreading the word! For each contractor you refer through the Staffy app, you will receive a $150 bonus - conditions apply. Refer a client to the Staffy platform and receive a $500 bonus. We are thrilled to provide recognition to workers who use our platform. That’s why every week, we select an exceptional worker to receive a $50 bonus and the designation of "Staffy of the Week".', btnText: 'Create my account'
            }
        ];
        return instructionObject;
    }
}
OnboardingStafferUtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnboardingStafferUtilService_Factory() { return new OnboardingStafferUtilService(i0.ɵɵinject(i1.StafferUtilService), i0.ɵɵinject(i2.OnBoardingStafferService), i0.ɵɵinject(i3.LocalStorageService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.SkillsService), i0.ɵɵinject(i6.PopupService), i0.ɵɵinject(i7.ModalController)); }, token: OnboardingStafferUtilService, providedIn: "root" });
