import { Injectable } from '@angular/core';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { IDeployConfig } from 'cordova-plugin-ionic/dist/ngx/IonicCordova';
import { ModalController } from '@ionic/angular';
import { AppUpdateProgressComponent } from '../componets/app-update-progress/app-update-progress.component';
import { environment } from 'src/environments/environment';
import { Observable, observable, Observer } from 'rxjs';
import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  protected config: IDeployConfig = {
    appId: 'da4462b7',
    channel: environment.APPFLOW_CHANNEL,
    updateMethod: 'none'
  };

  private progressModal: HTMLIonModalElement;
  private downloadProgressObservable: Observable<number>;
  // the observer that would subscribe to downloadProgressObservable
  // we can make it an array too, but use case doesn't require that
  private observer: Observer<number>;

  constructor(private deploy: Deploy, private modalController: ModalController, private httpPromiseService: HttpPromiseService) {}

  async check() {
    try {
      // initialize observable
      this.downloadProgressObservable = new Observable(observer => {
        observer.next(0);
        this.observer = observer;
      });

      // configure live deploy plugin
      await this.deploy.configure(this.config);
      // check if any update is available
      const update = await this.deploy.checkForUpdate();

   
      if (update.available) {
        // check importance
        const { isCritical } = await this.checkBuildImportance(update.build).catch(() => {
          return {
            isCritical: false
          };
        });
        // if criticial
        if (isCritical) {
          // show popup
          this.showProgressModal();
        }
        // download updates
        await this.downloadUpdates();
        // if its a critical update, reload the app
        if (isCritical) {
          await this.deploy.reloadApp();
        }
      }

      return;
    } catch (e) {
      console.log('error in downlodaing updates');
    } finally {
      this.hideProgressModal();
    }
  }

  private async downloadUpdates() {
    try {
      //   this.isUpdating = true;
      await this.deploy.downloadUpdate(progress => {
        if (this.observer) {
          this.observer.next(progress);
        }
      });
      await this.deploy.extractUpdate();
    } catch (err) {
      console.log(err);
    }
  }

  private async showProgressModal() {
    this.progressModal = await this.modalController.create({
      component: AppUpdateProgressComponent,
      cssClass: 'wideModal',
      backdropDismiss: false,
      componentProps: {
        progress: this.downloadProgressObservable
      }
    });

    this.progressModal.present();
  }

  private async hideProgressModal() {
    if (this.progressModal) {
      this.progressModal.dismiss();
    }
  }

  private async checkBuildImportance(buildId): Promise<{ buildId: string; isCritical: boolean }> {
    const buildImportance = await this.httpPromiseService.httpGetRequest(`/api/live-updates/${buildId}`);
    // if its defined return it
    if (buildImportance) {
      return buildImportance;
    }

    // if not found, return false
    return {
      isCritical: false,
      buildId
    };
  }
}
