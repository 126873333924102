/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bulletins.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./bulletins.component";
import * as i6 from "@ionic-native/file-opener/ngx/index";
import * as i7 from "@ionic-native/file-transfer/ngx/index";
import * as i8 from "@ionic-native/file/ngx/index";
import * as i9 from "../../talent-dashboard/services/talent-job-format.service";
var styles_BulletinsComponent = [i0.styles];
var RenderType_BulletinsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BulletinsComponent, data: {} });
export { RenderType_BulletinsComponent as RenderType_BulletinsComponent };
function View_BulletinsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-spinner", [], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(1, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null)], null, null); }
function View_BulletinsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "bulletin-item-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openBulletin(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["style", "flex: 1;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-chevron-circle-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinsComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.downloadInprogressPdfId === _v.context.$implicit._id); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
export function View_BulletinsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "bulletin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "ion-text-center child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/images/shift-bulletins.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Shift Bulletins"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "bulletin-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "ul", [["class", "listings"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulletinsComponent_1)), i1.ɵdid(10, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "buttons-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "ion-button", [["class", " ion-no-margin btn success-popup-ok"], ["color", "basic"], ["id", "success-popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dimissModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(13, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Got it "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bulletins; _ck(_v, 10, 0, currVal_0); var currVal_1 = "basic"; _ck(_v, 13, 0, currVal_1); }, null); }
export function View_BulletinsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bulletins", [], null, null, null, View_BulletinsComponent_0, RenderType_BulletinsComponent)), i1.ɵdid(1, 114688, null, 0, i5.BulletinsComponent, [i3.Platform, i6.FileOpener, i7.FileTransfer, i8.File, i9.TalentJobFormatService, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BulletinsComponentNgFactory = i1.ɵccf("app-bulletins", i5.BulletinsComponent, View_BulletinsComponent_Host_0, { bulletins: "bulletins", entityId: "entityId" }, {}, []);
export { BulletinsComponentNgFactory as BulletinsComponentNgFactory };
