/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./searchable-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./searchable-select.component";
var styles_SearchableSelectComponent = [i0.styles];
var RenderType_SearchableSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchableSelectComponent, data: {} });
export { RenderType_SearchableSelectComponent as RenderType_SearchableSelectComponent };
function View_SearchableSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-label", [["class", "label"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(1, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labelText; _ck(_v, 2, 0, currVal_0); }); }
export function View_SearchableSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "reusable-form-component"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchableSelectComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["class", "position select-font"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleSubSkillDropDownClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "placeholder": 0 }), (_l()(), i1.ɵted(7, null, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labelText; _ck(_v, 2, 0, currVal_0); var currVal_1 = "position select-font"; var currVal_2 = _ck(_v, 6, 0, (_co.placeHolderText == "Select Sub Skill")); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.placeHolderText; _ck(_v, 7, 0, currVal_3); }); }
export function View_SearchableSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-searchable-select", [], null, null, null, View_SearchableSelectComponent_0, RenderType_SearchableSelectComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.SearchableSelectComponent]), i1.ɵdid(2, 638976, null, 0, i6.SearchableSelectComponent, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SearchableSelectComponentNgFactory = i1.ɵccf("app-searchable-select", i6.SearchableSelectComponent, View_SearchableSelectComponent_Host_0, { options: "options", selectedOptions: "selectedOptions", multiple: "multiple", labelText: "labelText", control: "control" }, { onSelect: "onSelect" }, []);
export { SearchableSelectComponentNgFactory as SearchableSelectComponentNgFactory };
