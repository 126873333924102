import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ShiftValidatorService } from 'src/modules/company-dashboard/services/shift-validator.service';
import { BroadcastJobsUtilService } from 'src/modules/company-dashboard/services/broadcast-job-utils.service';
import * as _ from 'lodash';
import { RecurrentShiftService } from 'src/modules/talent-dashboard/services/recurrentShift.service';
import { JobService } from '../../services/job.service';
import { LoadingService } from '../../services/loading.service';
import { PopupService } from '../../services/popup.service';
export class EditRecurrentFullRecurrenceComponent {
    constructor(formBuilder, shiftValidatorService, broadcastJobsUtilService, recurrentShiftService, jobService, loadingService, popupService) {
        this.formBuilder = formBuilder;
        this.shiftValidatorService = shiftValidatorService;
        this.broadcastJobsUtilService = broadcastJobsUtilService;
        this.recurrentShiftService = recurrentShiftService;
        this.jobService = jobService;
        this.loadingService = loadingService;
        this.popupService = popupService;
        this.tz = 'America/Toronto';
        this.dateConfig = {
            allowMultiSelect: false,
            format: 'MM/DD/YYYY',
            closeOnSelect: true,
            showGoToCurrent: true,
            disableKeypress: true,
            unSelectOnClick: false
        };
        this.repeatIntervalList = [1, 2, 3, 4];
        this.frequencyList = ['Weekly', 'Daily'];
        this.availableEndOption = [{ label: 'Never', value: 'never' }];
        this.availableBroadcastType = [{ label: 'Keep hired contractor', value: 'hired' },
            { label: 'Broadcast again', value: 'broadcast' }];
        this.disableIntervals = false;
        this.isFullRecurrence = true;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.jobItem) {
                return;
            }
            this.setFormOptions();
            this.initializeRecurrentForm();
        });
    }
    setFormOptions() {
        this.availableDays = [
            { label: 'S', value: 'Sunday', checked: false },
            { label: 'M', value: 'Monday', checked: false },
            { label: 'T', value: 'Tuesday', checked: false },
            { label: 'W', value: 'Wednesday', checked: false },
            { label: 'T', value: 'Thursday', checked: false },
            { label: 'F', value: 'Friday', checked: false },
            { label: 'S', value: 'Saturday', checked: false }
        ];
        this.isFullRecurrence = true;
        this.getStartandEndTimeForDay(moment(moment.unix(this.jobItem.recurrentShiftTemplateId.startTime).tz(this.tz).format('MM/DD/YYYY'), 'MM/DD/YYYY').tz(this.tz).format(), moment(moment.unix(this.jobItem.recurrentShiftTemplateId.endTime).tz(this.tz).format('MM/DD/YYYY'), 'MM/DD/YYYY').tz(this.tz).format());
        this.latestStartDate = this.setLatestStartDate();
        this.dateConfig.min = this.latestStartDate;
        this.updateDaysList();
        [this.startTimeIndex, this.endTimeIndex] = this.recurrentShiftService.fetchInitalTimingIndex(this.jobItem.recurrentShiftTemplateId, this.startTimeValues, this.endTimeValues);
    }
    setLatestStartDate() {
        const recurrentStartDate = moment.unix(this.jobItem.recurrentShiftTemplateId.startDate).tz('America/Toronto');
        const currentDate = moment().tz('America/Toronto');
        //Compare recurrent template start date with current date, if the current date is after recurrent date. Update date min by current date
        if (!recurrentStartDate.isAfter(currentDate)) {
            return currentDate;
        }
        //If the start date is after current date, return the existing recurrent start date
        return recurrentStartDate;
    }
    initializeRecurrentForm() {
        this.recurrentForm = this.formBuilder.group({
            recurrentShiftTemplate: this.formBuilder.group({
                frequency: [this.jobItem.recurrentShiftTemplateId.intervalType, Validators.required],
                repeatInterval: [this.jobItem.recurrentShiftTemplateId.interval.toString(), [Validators.required]],
                startTime: [this.startTimeValues[this.startTimeIndex], [Validators.required, this.shiftValidatorService.validateStartTime(this.tz, 'startTime')]],
                endTime: [this.endTimeValues[this.endTimeIndex], [Validators.required, this.shiftValidatorService.validateStartTime(this.tz, 'endTime')]],
                days: [this.jobItem.recurrentShiftTemplateId.days, [Validators.required]],
                // startDate: [moment.unix(this.jobItem.recurrentShiftTemplateId.startDate).tz(this.tz).format('MM/DD/YYYY'), [Validators.required, this.shiftValidatorService.validateShiftDate(this.jobItem, this.tz)]],
                startDate: [this.latestStartDate.format('MM/DD/YYYY'), Validators.required],
                endDate: [''],
                broadcastType: [this.availableBroadcastType[0].value]
            }),
            timeZone: [this.tz]
        });
        if (this.jobItem.recurrentShiftTemplateId.intervalType == 'Daily') {
            this.disableInterval();
        }
    }
    disableInterval() {
        const recurrentShiftTemplate = this.recurrentForm.get('recurrentShiftTemplate');
        // Set all available days as checked
        this.disableIntervals = true;
        this.availableDays.forEach(item => item.checked = true);
        // Extract the values of checked days and assign them to the 'days' array
        const days = this.availableDays.map(item => item.value);
        //Restore the initial value of repeat interval when daily is selected
        recurrentShiftTemplate.get('repeatInterval').setValue('1');
        recurrentShiftTemplate.get('days').setValue(days);
    }
    updateDaysList() {
        this.availableDays = _.map(this.availableDays, (day) => (Object.assign({}, day, { checked: _.includes(this.jobItem.recurrentShiftTemplateId.days, day.value) })));
    }
    handleBroadcastTypeChange(value) {
        const recurrentShiftTemplate = this.recurrentForm.get('recurrentShiftTemplate');
        recurrentShiftTemplate.get('broadcastType').setValue(value);
    }
    handleFrequencyChange(value) {
        const recurrentShiftTemplate = this.recurrentForm.get('recurrentShiftTemplate');
        if (!value) {
            return;
        }
        if (value !== 'Daily') {
            this.disableIntervals = false;
            // Set all available days as unchecked
            this.availableDays.forEach(item => item.checked = false);
            // Reset the 'daysIncluded' value to an empty array
            recurrentShiftTemplate.get('days').setValue([]);
            return;
        }
        // Set all available days as checked
        this.disableIntervals = true;
        this.availableDays.forEach(item => item.checked = true);
        // Extract the values of checked days and assign them to the 'days' array
        const days = this.availableDays.map(item => item.value);
        //Restore the initial value of repeat interval when daily is selected
        recurrentShiftTemplate.get('repeatInterval').setValue('1');
        recurrentShiftTemplate.get('days').setValue(days);
    }
    getStartandEndTimeForDay(startVal, endVal) {
        this.startTimeValues = this.broadcastJobsUtilService.generatedStepArray(startVal);
        this.endTimeValues = this.broadcastJobsUtilService.generatedStepArray(endVal);
    }
    handleDateChanged(dateString) {
        const date = moment(dateString, 'MM/DD/YYYY').tz(this.tz).format();
        this.getStartandEndTimeForDay(date, date);
        const startIndex = this.recurrentShiftService.updateTimingDropdownIndexByDate(this.startTimeValues, this.recurrentForm, this.tz, dateString, 'startTime');
        const endIndex = this.recurrentShiftService.updateTimingDropdownIndexByDate(this.endTimeValues, this.recurrentForm, this.tz, dateString, 'endTime');
        if (startIndex > -1) {
            this.recurrentForm.get('recurrentShiftTemplate').get('startTime').setValue(this.startTimeValues[startIndex]);
        }
        if (endIndex > -1) {
            this.recurrentForm.get('recurrentShiftTemplate').get('endTime').setValue(this.endTimeValues[endIndex]);
        }
        this.handleTimeValidation();
    }
    formatTimeValues() {
        //Extract values from object and assign values to form control
        const recurrentFormControl = this.recurrentForm.get('recurrentShiftTemplate');
        const startTime = recurrentFormControl.get('startTime').value.value;
        const endTime = recurrentFormControl.get('endTime').value.value;
        recurrentFormControl.get('startTime').setValue(startTime);
        recurrentFormControl.get('endTime').setValue(endTime);
    }
    onSubmit(cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('recurrent form: ', this.recurrentForm);
            this.recurrentForm.markAllAsTouched();
            if (this.recurrentForm.valid) {
                const loader = yield this.loadingService.showLoading().toPromise();
                try {
                    this.formatTimeValues();
                    yield this.jobService.editRecurrentShiftTempalte(this.jobItem.recurrentShiftTemplateId._id, this.recurrentForm.value);
                    this.loadingService.hideLoading(loader);
                    this.popupService.showModal({
                        heading: 'Success',
                        message: 'Successfully edit the recurring template',
                        btn: 'Dismiss',
                        navigateRoute: '',
                        imgURL: 'assets/images/request-sent.png'
                    }, (data) => {
                    });
                    if (cb) {
                        yield cb();
                    }
                }
                catch (error) {
                    this.loadingService.hideLoading(loader);
                    this.popupService.showModal({
                        heading: 'Error',
                        message: error.error || 'Something went wrong, Please try again',
                        btn: 'Dismiss!',
                        navigateRoute: '',
                        imgURL: 'assets/images/sorry1.png'
                    });
                }
            }
            else {
                this.popupService.showModal({
                    heading: 'Validation Error',
                    message: 'Please fill all details',
                    btn: 'Dismiss!',
                    navigateRoute: '',
                    imgURL: 'assets/images/sorry1.png'
                });
            }
            return true;
        });
    }
    handleTimeValidation() {
        const validationUpdateKey = ['startTime', 'endTime'];
        validationUpdateKey.forEach((key) => {
            const newValidatorStart = this.shiftValidatorService.validateStartTime(this.tz, key);
            // Set the new validator for the specific control
            this.recurrentForm.get('recurrentShiftTemplate').get(key).setValidators([newValidatorStart]);
            this.recurrentForm.get('recurrentShiftTemplate').get(key).updateValueAndValidity();
        });
    }
}
