import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { addPatientAction, updatePatientAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { UserService } from 'src/modules/shared/services/user.service';
import { BroadcastJobsUtilService } from '../../../company-dashboard/services/broadcast-job-utils.service';
export class NewPatientFormComponent {
    constructor(modalController, userService, broadcastUtilService, authStore, cdr) {
        this.modalController = modalController;
        this.userService = userService;
        this.broadcastUtilService = broadcastUtilService;
        this.authStore = authStore;
        this.cdr = cdr;
        this.name = '';
        this.errMsg = '';
    }
    ngOnInit() { }
    closeModal(status = null) {
        this.modalController.dismiss(status);
    }
    addNewPatient() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.name || !this.patientAddress) {
                this.errMsg = 'Invalid: Patient ID or Postal Code';
                console.log('no name or address');
                return;
            }
            try {
                const newPatient = {
                    id: this.name,
                    address: this.patientAddress
                };
                const res = yield this.userService.addNewPatientIntoCompany(this.entityId, newPatient);
                if (res.msg === 'success') {
                    this.authStore.dispatch(addPatientAction({ patient: res.patient }));
                    this.addPatientAddress && this.addPatientAddress(res.patient);
                    this.closeModal('success');
                }
            }
            catch (error) {
                this.closeModal('fail');
                console.log(error);
            }
        });
    }
    updatePatient() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.name || !this.patientAddress) {
                this.errMsg = 'Invalid: Patient ID or Postal Code';
                console.log('no name or address');
                return;
            }
            try {
                const patient = {
                    _id: this.patientId,
                    id: this.name,
                    address: this.patientAddress
                };
                const res = yield this.userService.editCompanyPatient(this.entityId, patient);
                if (res === 'success') {
                    this.authStore.dispatch(updatePatientAction({ patient }));
                    this.closeModal('success');
                }
            }
            catch (error) {
                this.closeModal('fail');
                console.log(error);
            }
        });
    }
    getAddress(address) {
        this.errMsg = "";
        const formatedAddress = this.broadcastUtilService.getFormatedAddress(address);
        this.patientAddress = formatedAddress;
        this.cdr.detectChanges();
    }
    handlePatientChange() {
        this.errMsg = "";
    }
}
