<form [formGroup]="expiryDateForm" (submit) = "nextStep()">
  <div formArrayName="referenceDetails" *ngFor="let cd of expiryDateForm['controls'].referenceDetails['controls'] ;let i=index;">

    <div class = "form-row">
  <div class = "date-head" (click) = "toggleStartDate(cd)"><i class = "fa fa-calendar"></i>From</div>
  <div class = "drop-down-list" *ngIf = "cd.showStartDate || user.references.length > 0">
    <select *ngIf = "cd.showStartDate || user.references.length > 0" formControlName="startYear"
      (change)="getDaysInMonth(cd, cd.value.startYear, cd.value.startMonth, cd.value.reference, 'start'); setDate(cd, 'start'); onYearChange(cd, 'start')">
    
      <option value= null disabled>{{"ADD_REFERENCE.Select Year" | translate}}</option>
      <option value={{year}} *ngFor="let year of yearsList; let i = index">{{year}}</option>
    </select>

    <select *ngIf = "cd.showStartDate || user.references.length > 0" formControlName="startMonth"
      (change)="getDaysInMonth(cd, cd.value.startYear, cd.value.startMonth, cd.value.reference, 'start'); setDate(cd, 'start')">
      <option value= null disabled>{{"ADD_REFERENCE.Select Month" | translate}}</option>
      <option value="01">{{"ADD_REFERENCE.January" | translate}}</option>
      <option value="02">{{"ADD_REFERENCE.February" | translate}}</option>
      <option value="03">{{"ADD_REFERENCE.March" | translate}}</option>
      <option value="04">{{"ADD_REFERENCE.April" | translate}}</option>
      <option value="05">{{"ADD_REFERENCE.May" | translate}}</option>
      <option value="06">{{"ADD_REFERENCE.June" | translate}}</option>
      <option value="07">{{"ADD_REFERENCE.July" | translate}}</option>
      <option value="08">{{"ADD_REFERENCE.August" | translate}}</option>
      <option value="09">{{"ADD_REFERENCE.September" | translate}}</option>
      <option value="10">{{"ADD_REFERENCE.October" | translate}}</option>
      <option value="11">{{"ADD_REFERENCE.November" | translate}}</option>
      <option value="12">{{"ADD_REFERENCE.December" | translate}}</option>
    </select>


    <select *ngIf = "cd.showStartDate || user.references.length > 0" formControlName="startDay"
      (change)="setDate(cd, 'start')">
      <option value= null disabled>{{"ADD_REFERENCE.Select Day" | translate}}</option>
      <option value={{day}} *ngFor="let day of cd.value.reference?.startDaysList; let i = index">{{day}}</option>
    </select>
  </div>

  <div class = "date-head left" *ngIf = "!cd.showEndDate && !(user.references.length > 0)" ><i class = "fa fa-calendar"></i>To</div>
  <div class = "drop-down-list left" *ngIf = "cd.showEndDate || user.references.length > 0">
    <select *ngIf = "cd.showEndDate || user.references.length > 0" formControlName="endYear"
      (change)="getDaysInMonth(cd, cd.value.endYear, cd.value.endMonth, cd.value.reference, 'end'); setDate(cd, 'end'); onYearChange(cd, 'end')">
      <option value= null disabled>{{"ADD_REFERENCE.Select Year" | translate}}</option>
      <option value={{year}} *ngFor="let year of yearsList; let i = index">{{year}}</option>
    </select>


    <select *ngIf = "cd.showEndDate || user.references.length > 0" formControlName="endMonth"
      (change)="getDaysInMonth(cd, cd.value.endYear, cd.value.endMonth, cd.value, 'end'); setDate(cd, 'end')">
      <option value = null disabled>{{"ADD_REFERENCE.Select Month" | translate}}</option>
      <option value="01">{{"ADD_REFERENCE.January" | translate}}</option>
      <option value="02">{{"ADD_REFERENCE.February" | translate}}</option>
      <option value="03">{{"ADD_REFERENCE.March" | translate}}</option>
      <option value="04">{{"ADD_REFERENCE.April" | translate}}</option>
      <option value="05">{{"ADD_REFERENCE.May" | translate}}</option>
      <option value="06">{{"ADD_REFERENCE.June" | translate}}</option>
      <option value="07">{{"ADD_REFERENCE.July" | translate}}</option>
      <option value="08">{{"ADD_REFERENCE.August" | translate}}</option>
      <option value="09">{{"ADD_REFERENCE.September" | translate}}</option>
      <option value="10">{{"ADD_REFERENCE.October" | translate}}</option>
      <option value="11">{{"ADD_REFERENCE.November" | translate}}</option>
      <option value="12">{{"ADD_REFERENCE.December" | translate}}</option>
    </select>

    <select *ngIf = "cd.showEndDate || user.references.length > 0" formControlName="endDay"
      (change)="setDate(cd, 'end')">
      <option value= null disabled>{{"ADD_REFERENCE.Select Day" | translate}}</option>
      <option value={{day}} *ngFor="let day of cd.value.reference?.endDaysList; let i = index">{{day}}</option>
    </select>
  </div>
</div></div></form>