import { Injectable } from '@angular/core';
import { HttpService } from 'src/modules/shared/services/http.service';
import { IStafferInformation } from '../interfaces/staffer-information.interface';
import { IAddressConfirmation } from 'src/modules/shared/enums/company-address-confirmation.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import { IUpdateUser } from 'src/modules/authentication/interfaces/update-user.interface';
import { IStafferReference } from '../interfaces/staffer-reference.interface';
import { IStafferReferral } from '../interfaces/staffer-referral.interface';

@Injectable({ providedIn: 'root' })

export class OnBoardingStafferService {

  constructor(
    private http: HttpService,
    private httpPromiseService: HttpPromiseService,
  ) { }



  public createUser(data: IStafferInformation) {
    return this.http.httpPostRequest<{ token: string }>(
      '/api/users/createUser',
      data
    );
  }

  public addressConfirmation(userId: string, data: IAddressConfirmation) {
    return this.http.httpPutRequest<IUser>(
      `/api/users/${userId}`,
      data
    );
  }

  public sendContractToEmail(data: { user: IUser, file: any }) {
    return this.httpPromiseService.httpPostRequest(
      `/api/users/contractSend/all`,
      data
    );
  }

  public updateUser$(userId: string, data: IUpdateUser) {
    return this.http.httpPutRequest<IUser>(
      `/api/users/${userId}`,
      data
    );
  }


  updateNavigationStep(userId: string, currentNavStep: string) {
    return this.httpPromiseService.httpPutRequest(
      `/api/users/updateRoute/${userId}`,
      {currentNavStep: currentNavStep}
    );
  }

  public updateUser(userId: string, data: IUpdateUser) {
    return this.httpPromiseService.httpPutRequest(
      `/api/users/${userId}`,
      data
    );
  }

  public updateRoutes(userId: string, data: IUpdateUser) {
    return this.httpPromiseService.httpPutRequest(
      `/api/users/updateRoute/${userId}`,
      data
    );
  }


  public updateSkillsRate(userId: string, data: { skill: string, rate: number }) {
    return this.httpPromiseService.httpPutRequest(
      `/api/users/${userId}/updateSkillsRate`,
      data
    );
  }

  public deleteUser(userId: string) {
    return this.httpPromiseService.httpDeleteRequest(
      `/api/users/${userId}`
    );
  }

  public addCompanyLogo$(data: FormData) {
    return this.http.httpPostRequest<IUser>(
      `/api/users/avatar`,
      data
    );
  }
  
  public addProfilePicture(data: FormData) {
    return this.httpPromiseService.httpPostRequest(
      `/api/users/avatar`,
      data
    );
  }


  public addResume(data: FormData) {
    return this.httpPromiseService.httpPostRequest(
      `/api/users/resume`,
      data
    );
  }

  public getQuizQuestions(version: number) {
    return this.httpPromiseService.httpGetRequest(
      `/api/quizzes?version=${version}`
    );
  }
  public verfiyCode(code) {
    return this.httpPromiseService.httpGetRequest(
      `/api/users/verfiyCode/license/agreement/?code=${code}`
    );
  }



  public addReferences(userId: string, data: IStafferReference[]) {
    return this.httpPromiseService.httpPostRequest(
      `/api/appInfo/sendEmailToReferences/${userId}`, data
    );
  }

  public addBackgroundCheck(userId: string, data: { dob: string }) {
    return this.httpPromiseService.httpPostRequest(
      `/api/certn/${userId}`, data
    );
  }

  public addNewReferral(data: IStafferReferral) {
    return this.httpPromiseService.httpPostRequest(
      `/api/refferals`, data
    );
  }

  public resendInvite(inviteeId: string) {
    return this.httpPromiseService.httpPostRequest(
      `/api/refferals/resendInvite/${inviteeId}`, {}
    );
  }

  public getFriendReferrals() {
    return this.httpPromiseService.httpGetRequest(
      `/api/refferals`
    );
  }

  public getActiveReferrals() {
    return this.httpPromiseService.httpGetRequest(
      `/api/refferals/active`
    );
  }

  public addUnifromPicture(category: string, data: FormData) {
    return this.httpPromiseService.httpPostRequest(
      `/api/users/uploadUniformPhoto?category=${category}`, data
    );
  }

  public submitForApproval() {
    return this.http.httpPostRequest(
      '/api/users/submit-for-approval',
      {}
    );
  }

}
