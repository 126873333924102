import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './local-storage.service';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selectedLanguage = 'en';

  constructor(
    private localStorageService: LocalStorageService,
    private translate: TranslateService) {
    this.checkForLanguage();
  }

  async setInitialAppLanguage() {
    // const language = this.translate.getBrowserLang();

    let lng = await this.localStorageService.getLanguage();
    lng = lng ? lng : 'en';
    this.translate.setDefaultLang(lng);
    await this.localStorageService.setLanguage(lng);
  }

  setLanguage(lng) {
    this.localStorageService.setLanguage(lng);
    this.translate.use(lng);
    this.selectedLanguage = lng;
  }

  async checkForLanguage() {
    const lng = await this.localStorageService.getLanguage();
    if (lng) {
      this.translate.use(lng);
      this.selectedLanguage = lng;
    }
    return lng;
  }
}
