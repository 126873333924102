import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from 'src/app/guards/logged-in.guard';
import { SplashScreenResolverService } from './services/splash-screen-resolver.service';

const routes: Routes = [
  {
    path: 'privacy-policy',
    canActivateChild: [LoggedInGuard],
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(pp => pp.PrivacyPolicyPageModule)
  },

  {
    path: 'staffer-privacy-policy',
    canActivateChild: [],
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(pp => pp.PrivacyPolicyPageModule)
  },
  {
    path: 'talent-dashboard',
    resolve: { data: SplashScreenResolverService },
    canActivateChild: [LoggedInGuard],
    loadChildren: () => import('../talent-dashboard/talent-dashboard.module').then(m => m.TalentDashboardModule)
  },
  {
    path: 'set-password/:id',
    loadChildren: () => import('./pages/set-password/set-password.module').then(m => m.SetPasswordPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule {}
