<div class="main-container">
  <div *ngIf="!submitView"class="heading">Shifts List</div>
  <form *ngIf="editRecurrentSpecificShiftForm" [formGroup]="editRecurrentSpecificShiftForm">
    <app-edit-recurring-shift-list
    [confirmationView]="submitView"
    [formGroup]="editRecurrentSpecificShiftForm"
    [config]="config" [data]="data"
    ></app-edit-recurring-shift-list>
</form>
<app-pagination class="flex-end" *ngIf = "shifts && shifts.length" [totalRecords] = "shifts.length" (onPageChange) = "loadMoreData($event)"></app-pagination>
</div>