<div class="profile" *ngIf = "initView === 'profile'">
  <div class="profile-cont">
    <div class="profile-container">
      
      <form (ngSubmit)="saveChanges()" [formGroup]="personalInformationForm">
        <div class = "flex-column">
          <div class = "mailing-type">
              <div class = "border-bottom"> 
                <ion-input #googlePlaces (onchange)="addressUpdates()" class="profile-form-input"
                placeholder="{{ 'ONBOARDING_STAFFER_INFORMATION.address' | translate }}*"
                formControlName="formattedAddress">
              </ion-input>
            </div>
          </div>

          
          <div formGroupName="address" class = "mailing-type">
            <div class = "border-bottom">
              <div>
                <ion-input class="profile-form-input" type="text" placeholder="Postal Code*" formControlName="code" >
               </ion-input>
              </div>
            </div>
            <div *ngIf="code.value && address.errors && address.errors['code']" class="err-msg">
              Postal code is incorrect
            </div>
            <div *ngIf="code.touched && code.dirty && !code.value" class="err-msg">
              (Required)
            </div>
          </div>
        </div>



        <div class = "flex-column">
          <div class = "mailing-type border-bottom">
            <div formGroupName="address" class="profile-form-input">
              <ion-select placeholder="Address Type" ok-text="Select" [interfaceOptions]="{cssClass: 'references-select'}" required type="text" formControlName="type" name="selectedType">
                <ion-select-option disabled value=undefined >Address type</ion-select-option>
                <ion-select-option value="apartment">Apartment</ion-select-option>
                <ion-select-option value="condo">Condo</ion-select-option>
                <ion-select-option value="home">House</ion-select-option>
                <ion-select-option value="townhouse">Townhouse</ion-select-option>
              </ion-select>
            </div>
          </div>

          <div class = "mailing-type" *ngIf="formValue.address.type !== 'home'">
            <div formGroupName="address" class="profile-form-input border-bottom" *ngIf="formValue.address.type == 'apartment' || formValue.address.type == 'condo' || formValue.address.type == 'townhouse'">
              <ion-input
                class="profile-form-input"
                type="text"
                formControlName="suiteNumber"
                [placeholder]="formValue.address.type == 'apartment' ? 'Enter Apartment Number' : (formValue.address.type == 'condo' ? 'Enter Suite Number' : 'Enter Unit Number')"
              >
              </ion-input>

            </div>
            <div *ngIf="suiteNumber.invalid && suiteNumber.touched && suiteNumber.dirty && !suiteNumber.value && (type.value == 'apartment' || type.value == 'condo' || type.value == 'townhouse')" class="err-msg">(Required)
            </div>
          </div>
        </div>

        <ion-row class = "professional-section column margin-top-50">
          <ion-label class="general-heavy margin-bottom">Professional Information</ion-label>
          <app-add-resume-form class = "add-resume" [userData] = "userData" (fileSubmitted)="choosedFile($event)"></app-add-resume-form>
          <div class = "bio">
            <textarea formControlName="bio" type="text" name = "bio" [(ngModel)] = "formValue.bio" placeholder="*Provide us with a brief description of your professional background"></textarea>
          </div>
        </ion-row>
        <div class = "professional-section column margin-top-50">
          <div class = "align-center">
            <ion-button type="submit" class = "action-button save" [disabled]="!personalInformationForm.valid">Save</ion-button>
          </div>
        </div>
        </form>
      </div>
  </div>
  <!-- <button class="save" [disabled]="saving" (click)="save()">{{!saving ? 'Save' : 'Saving...' }}</button> -->
  
</div>

<div class="child" *ngIf = "initView !== 'profile'">
  <div class="cont">
    <div class="outer-container">
      <div class="title-with-times">
        <p class="mailing-address-title">Mailing Address</p>
        <p class="sub-text">Please verify your current mailing address</p>
        <div class="cancel-modal" (click)="dismiss({})"><i class="fa fa-times"></i></div>
      </div>
      <div>
        <label>Select Type <span>*</span></label>
        <div class="mt10">
          <ion-select
            style="padding-left: 8px; padding-bottom: 10px"
            name="selectedType"
            [(ngModel)]="mailingAddress.type"
            (change)="clearState()"
          >
            <ion-select-option disabled value="">Select One</ion-select-option>
            <ion-select-option value="apartment">Apartment</ion-select-option>
            <ion-select-option value="condo">Condo</ion-select-option>
            <ion-select-option value="home">House</ion-select-option>
            <ion-select-option value="townhouse">Townhouse</ion-select-option>
          </ion-select>
        </div>
      </div>

      <div class="mt20 type" *ngIf="mailingAddress.type == 'apartment' || mailingAddress.type == 'condo' || mailingAddress.type == 'townhouse'" style="margin-bottom: 10px">
        <label *ngIf = "mailingAddress.type == 'apartment'">Apartment Number<span>*</span></label>
        <label *ngIf = "mailingAddress.type == 'condo'">Suite Number<span>*</span></label>
        <label *ngIf = "mailingAddress.type == 'townhouse'">Unit Number<span></span></label>
        <ion-input
          class="mt10 form-input"
          type="text"
          [(ngModel)]="mailingAddress.suiteNumber"
          [placeholder] = "mailingAddress.type == 'apartment' ? 'Enter Apartment Number' : (mailingAddress.type == 'condo' ? 'Enter Suite Number' : 'Enter Unit Number')"
          style="margin-top: 0px; padding-top: 10px; padding-left: 8px"
        >
        </ion-input>
      </div>

      <div class="mt20 type">
        <label>Street Address<span>*</span></label>
        <app-places-autocomplete (addressChanged)="onAddressChanged($event, 'address')"></app-places-autocomplete>
      </div>

      <div class="mt20 type">
        <label>Confirm Street Address<span>*</span></label>
        <app-places-autocomplete (addressChanged)="onAddressChanged($event, 'confirmAddress')"></app-places-autocomplete>
      </div>

      <div class="mt20 type">
        <label>Code<span>*</span></label>
        <ion-input class="form-input" type="text" placeholder="Code" [(ngModel)]="mailingAddress.code"> </ion-input>
      </div>
    </div>
  </div>

  <button class="save" [disabled]="saving" (click)="save()">{{!saving ? 'Save' : 'Saving...' }}</button>
</div>
