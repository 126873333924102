/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./places-autocomplete.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/forms";
import * as i6 from "./places-autocomplete.component";
var styles_PlacesAutocompleteComponent = [i0.styles];
var RenderType_PlacesAutocompleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlacesAutocompleteComponent, data: {} });
export { RenderType_PlacesAutocompleteComponent as RenderType_PlacesAutocompleteComponent };
export function View_PlacesAutocompleteComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { googlePlaceInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "ion-input", [["class", "form-input"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleInputEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.formatedAddress = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonInput_0, i2.RenderType_IonInput)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "placeholder-form": 0, "placeholder-broadcast": 1 }), i1.ɵdid(5, 16384, null, 0, i4.TextValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.TextValueAccessor]), i1.ɵdid(7, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(9, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), i1.ɵdid(10, 49152, [[1, 4], ["googlePlaces", 4]], 0, i4.IonInput, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { placeholder: [0, "placeholder"], type: [1, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "form-input"; var currVal_8 = _ck(_v, 4, 0, _co.class.form, !_co.class.form); _ck(_v, 3, 0, currVal_7, currVal_8); var currVal_9 = _co.formatedAddress; _ck(_v, 7, 0, currVal_9); var currVal_10 = i1.ɵinlineInterpolate(1, "", _co.placeholder, ""); var currVal_11 = "text"; _ck(_v, 10, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_PlacesAutocompleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-places-autocomplete", [], null, null, null, View_PlacesAutocompleteComponent_0, RenderType_PlacesAutocompleteComponent)), i1.ɵdid(1, 4308992, null, 0, i6.PlacesAutocompleteComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlacesAutocompleteComponentNgFactory = i1.ɵccf("app-places-autocomplete", i6.PlacesAutocompleteComponent, View_PlacesAutocompleteComponent_Host_0, { placeholder: "placeholder", class: "class", formatedAddress: "formatedAddress" }, { addressChanged: "addressChanged" }, []);
export { PlacesAutocompleteComponentNgFactory as PlacesAutocompleteComponentNgFactory };
