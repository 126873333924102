<div class="ion-text-center child">
  <div class = "heading" [ngClass] = "{'certificate-heading' : certificate}">
    <h1 class = "title" [ngClass] = "{'certificate-title': certificate}">{{heading}}</h1>

    <p class="ion-text-center ov-auto" *ngIf = "message"> {{ message }} </p>
    
    <p class = "listHead" *ngIf = "listHead">{{listHead}}</p>
    
    <div class="listings" *ngIf="listPoints?.length">
      <ul>
        <li *ngFor="let item of listPoints">
          <div class = "dot"> </div>
          <div>
            {{item.head}}
            <span *ngIf = "item.subHead"><br/>{{item.subHead}}</span>
          </div>
        </li>
      </ul>
    </div>

    <div class = "flex-row" *ngIf = "certificate">
      
      <div class = "crop-image">
         <i class="fas fa-id-card"></i>
          <div class=  "circle">
            <i class = "fa fa-check"></i>
          </div>
      </div>

      <div class = "crop-image top-spacing">
        <div class = "hide-img">
          <i class="fas fa-id-card crop"></i>
          <div class=  "circle">
            <i class = "fa fa-times"></i>
          </div>
        </div>
      </div>

      <div class = "crop-image top-spacing">
        <div class = "hide-img">
          <i class="fas fa-id-card rotate"></i>
          <div class=  "circle">
            <i class = "fa fa-times"></i>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="buttons-section" [ngClass] = "{'certificate-btn' : certificate}">
    <ion-button color="basic" class=" ion-no-margin btn success-popup-ok" (click) = "submit()">
      {{btnText}}
    </ion-button>
  </div>
</div>