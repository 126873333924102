/**
 * @description : To append provided parameters in query
 * @param link 
 * @param parameter 
 * @returns newLink with query parameters
 */
export function appendQueryParameter(link: String, parameter:String){
    if (link.indexOf("?") === -1) {
        // Link does not have query parameters
        link += "?" + parameter;
      } else {
        // Link already has query parameters
        link += "&" + parameter;
      }
    return link;
}