
const firebaseConfig = {
  apiKey: "AIzaSyBY9lnJVgpU3jBebPQb-BZoQ4076pf0sJY",
  authDomain: "staffy-8f0ed.firebaseapp.com",
  databaseURL: "https://staffy-8f0ed.firebaseio.com",
  projectId: "staffy-8f0ed",
  storageBucket: "staffy-8f0ed.appspot.com",
  messagingSenderId: "423542432527",
  appId: "1:423542432527:web:1348c7b8f98a0232ff547a",
  measurementId: "G-ZKKBBP23S9"
};

export const environment = {
  production: true,
  name: 'Production',
  STRIPE_API_KEY: 'pk_live_y1DhF99kcoQ3RqV4OwNkNeR6',
  INTERCOM_KEY: "a7qp8zwx",
  API_BASE_URL: 'https://onboarding.staffy.com',
  API_BASE_URL_COMPANIES: 'https://v2.staffy.com',
  APPFLOW_CHANNEL: 'Production',
  SENTRY_RELEASE: 'testing',
  firebase: firebaseConfig
};

// comment again 2.8.1