import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { getUserInfoAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { LoadingService } from '../../services/loading.service';
import { UserService } from '../../services/user.service';
export class ServiceFeeUpdateNoticeComponent {
    constructor(modalController, router, userService, loadingService, popupService, authStore) {
        this.modalController = modalController;
        this.router = router;
        this.userService = userService;
        this.loadingService = loadingService;
        this.popupService = popupService;
        this.authStore = authStore;
        this.notices = {
            title: 'Important Update',
            shortNotice: `From August 01, 2024, a 3% fee will be deducted from your payout for completed shifts to help maintain and improve the app. There is no charge to download the app or view available shifts. Thank you for your understanding and continued support!`,
        };
    }
    ngOnInit() {
    }
    closeModal() {
        this.modalController.dismiss();
    }
    acceptDoc() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                const acknowledgeDate = moment().unix();
                yield this.userService.updateUserPromise(this.staffer._id, {
                    platformServiceFeeAcknowledgementDate: acknowledgeDate,
                    isPlatformServiceFeeAcknowledged: true
                });
                this.authStore.dispatch(getUserInfoAction());
                this.staffer.platformServiceFeeAcknowledgementDate = acknowledgeDate;
                this.staffer.isPlatformServiceFeeAcknowledged = true;
                this.popupService.showModal({
                    heading: 'Success!',
                    message: ' ',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/thumbs-up.png'
                });
                this.modalController.dismiss();
            }
            catch (e) {
                this.popupService.showModal({
                    heading: 'Error',
                    message: 'Something went wrong, Please try again later',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/profile.png'
                });
            }
            finally {
                this.loadingService.hideLoading(loading);
            }
        });
    }
}
