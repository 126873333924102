import { Injectable } from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { ITalentJob } from '../interfaces/talent-job.interface';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { ModalController } from '@ionic/angular';
import { CovidQuizModalComponent } from '../components/covid-quiz-modal/covid-quiz-modal.component';
import { JobService } from 'src/modules/shared/services/job.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';

@Injectable({ providedIn: 'root' })
export class TalentCovidAssessmentService {
  constructor(
    private popupService: PopupService,
    private modalController: ModalController,
    private jobService: JobService,
    private loadingService: LoadingService
  ) {}

  hasCovidPassed(staffObj: IUser) {
    if (staffObj.covidAssessment) {
      return staffObj.covid.p1a || staffObj.covid.p1b || staffObj.covid.p1c || staffObj.covid.p2a || staffObj.covid.p2b;
    } else {
      return true;
    }
  }

  async checkForCovid(job: ITalentJob, currentUser: IUser, cb?) {
    return new Promise(async (resolve, reject) => {
      const isFilledBefore = this.hasAlreadySubmittedQuiz(currentUser);
      if (isFilledBefore) {
        //   FORM is already filled
        cb ? cb(null, true) : resolve(false);
      } else {
        // create modal instance
        const myModal = await this.modalController.create({
          component: CovidQuizModalComponent,
          cssClass: 'uploadImgModal',
          componentProps: {}
        });
        // show it
        await myModal.present();

        const dataReturned = await myModal.onDidDismiss();

        if (dataReturned.data) {
          // show Loader
          const loading = await this.loadingService.showLoading().toPromise();

          try {
            //   submit covid quick
            await this.jobService.updateCOVIDAssessmentForStaffers({
              covid: dataReturned.data,
              covidAssessment: true,
              jobIds: [job._id],
              stafferId: (currentUser as any).staffer
            });

            cb ? cb(dataReturned.data, null) : resolve(dataReturned.data);
          } catch (error) {
            console.log('err', error);
            cb ? cb(null, true) : reject();
          } finally {
            this.loadingService.hideLoading(loading);
          }
        }
      }
    });
  }

  hasAlreadySubmittedQuiz(currentUser: IUser) {
    if (currentUser.covidAssessment) {
      this.popupService.showModal({
        heading: 'COVID-19 ASSESSMENT',
        message: `Sorry, You have failed to pass the assessment for COVID-19.
                     Please contact Staffy team for further instructions`,
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
      return true;
    } else {
      return false;
    }
  }
}
