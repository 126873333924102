import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
    name: 'embedDOCFile'
  })
  export class EmbedDOCFilePipe implements PipeTransform {
  
    constructor(private sanitizer: DomSanitizer) {}
  
    transform(uId: string): SafeResourceUrl {
      const url = `https://view.officeapps.live.com/op/embed.aspx?src=${uId}&embedded=true`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }