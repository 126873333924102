<div [ngClass]="{'horizontalOnly' : horizontalOnly, 'directHire' : isDirectHire, 'badge-container' : !isDirectHire}">
  <div class="card">
    <div>

      <div *ngIf="userData?.badges?.length" class="inner">
        <div *ngFor="let badge of  userData.badges" class="badges staffer-badge-desktop">
          
          <div *ngIf="badge.indexOf('covid-19-vaccine') != -1"   class=" "><img src="assets/images/Covid-Button-Icon.svg"
            class=" employee-award-img"></div>
          
            <div
            *ngIf="badge.indexOf('covid-19-vaccine') == -1 && badge.indexOf('Red seal') == -1 && badge.indexOf('Employee Of The Year') == -1 && badge.indexOf('100') == -1 && badge.indexOf('30') == -1 && badge.indexOf('Late night hero') == -1 && badge.indexOf('Distinguished service') == -1 && badge.indexOf('Punctual') == -1 && badge.indexOf('Last minute hero') == -1"
            class="  badge"><i class="fa {{getBadgeIcon(badge)}} fa-icon " aria-hidden="true"></i>
          </div>
          
          <div *ngIf="badge.indexOf('Red seal') != -1" class=" "><img src="assets/images/red-seal.png"
              class=" employee-award-img"></div>
          
              <div *ngIf="badge.indexOf('Employee Of The Year') != -1" class=" employee-award "><img
              src="assets/images/staffy-award.png" class="badge-img employee-award-img"></div>
          
              <div *ngIf="badge.indexOf('100') != -1" class=" employee-award "><img src="assets/images/hundredhours.svg"
              class="badge-img employee-award-img"></div>
          
              <div *ngIf="badge.indexOf('30') != -1" class=" employee-award "><img src="assets/images/thirtyhours.svg"
              class="badge-img employee-award-img"></div>
          
              <div *ngIf="badge.indexOf('Punctual') != -1" class=" employee-award "><img src="assets/images/punctual.svg"
              class="badge-img employee-award-img">
          </div>
          
          <div *ngIf="badge.indexOf('Last minute hero') != -1" class=" employee-award ">
            <img src="assets/images/lastMinuteHero.svg" class="badge-img employee-award-img"></div>
          
            <div *ngIf="badge.indexOf('Late night hero') != -1" class=" employee-award ">
            <img src="assets/images/lateNightHero.svg" class="badge-img employee-award-img"></div>
          
            <div *ngIf="badge.indexOf('Distinguished service') != -1" class=" employee-award "><img
              src="assets/images/distinguishedService.svg" class="badge-img employee-award-img">
          </div>
          <h4 class="badge-text">
            {{ ('BADGES.' + badge) | translate }}
          </h4>
        </div>
        <div style="width: 10px"></div>
      </div>
    </div>
  </div>
</div>