import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { ConfigService } from 'src/app/services/config.service';
import { logOutAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { getNotificationCount, getShiftInProgress } from 'src/modules/authentication/+store/auth-selector';
import { AccountTypeEnum } from 'src/modules/shared/enums/company-account-type.enum';
import { JobService } from 'src/modules/shared/services/job.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { NotificationService } from 'src/modules/shared/services/notification.service';
import { PushNotificationService } from 'src/modules/shared/services/push-notification.service';
import { SideMenuUtilService } from 'src/modules/shared/services/sideMenu-util.service';
import { SkillFilterationService } from 'src/modules/shared/services/skill-filteration.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { TalentSyncService } from 'src/modules/talent-dashboard/services/talent-sync.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/router";
import * as i3 from "../modules/shared/services/loading.service";
import * as i4 from "../modules/talent-dashboard/services/talent-sync.service";
import * as i5 from "../modules/shared/services/notification.service";
import * as i6 from "../modules/shared/services/push-notification.service";
import * as i7 from "../modules/shared/services/sideMenu-util.service";
import * as i8 from "@ngrx/store";
import * as i9 from "../modules/shared/services/job.service";
import * as i10 from "../modules/shared/services/util.services";
import * as i11 from "../modules/shared/services/skill-filteration.service";
import * as i12 from "../app/services/config.service";
export class LayoutService {
    constructor(menuCtrl, router, loadingService, talentSyncService, notificationService, pushNotificationService, sideMenuUtilService, store, jobService, utilService, skillFilterationService, configService) {
        this.menuCtrl = menuCtrl;
        this.router = router;
        this.loadingService = loadingService;
        this.talentSyncService = talentSyncService;
        this.notificationService = notificationService;
        this.pushNotificationService = pushNotificationService;
        this.sideMenuUtilService = sideMenuUtilService;
        this.store = store;
        this.jobService = jobService;
        this.utilService = utilService;
        this.skillFilterationService = skillFilterationService;
        this.configService = configService;
        this.showBackBtn = false;
        this.notificationCount$ = this.store.pipe(select(getNotificationCount));
        this.shiftInProgress$ = this.store.pipe(select(getShiftInProgress));
        this.totalRequestCount = 0;
        this.openRequestCount = 0;
        this.filledRequestCount = 0;
        this.isOnline = true;
    }
    navigate(page, user, goToSubMenu) {
        switch (page.path) {
            // staffy
            case 'talent-home':
                this.toggleMenuAndNavigate('talent-dashboard/home');
                break;
            case 'settings':
                goToSubMenu(page, true);
                break;
            case 'talent-active-shifts':
                this.toggleMenuAndNavigate('talent-dashboard/active-shifts');
                break;
            case 'covid-assessment':
                this.toggleMenuAndNavigate('talent-dashboard/covid-assessment');
                break;
            case 'talent-set-sub-skills':
                this.toggleMenuAndNavigate('talent-dashboard/sub-skills-health-care');
                break;
            case 'set-availability':
                this.toggleMenuAndNavigate('talent-dashboard/set-availability');
                break;
            case 'peer-ratings':
                this.toggleMenuAndNavigate('talent-dashboard/peer-ratings');
                break;
            case 'block-location':
                this.toggleMenuAndNavigate('talent-dashboard/block-location');
                break;
            case 'talent-add-referrals':
                this.toggleMenuAndNavigate('talent-dashboard/add-referrals');
                break;
            case 'shifts-confirmation':
                this.toggleMenuAndNavigate('talent-dashboard/shifts-confirmation');
                break;
            case 'shifts':
                goToSubMenu(page);
                break;
            case 'talent-help':
                goToSubMenu(page, true);
                break;
            case 'talent-billing':
                goToSubMenu(page, true);
                break;
            case 'talent-faq':
                this.toggleMenuAndNavigate('talent-dashboard/faqs');
                break;
            case 'talent-set-profile':
                this.toggleMenuAndNavigate(user.profile_approved ?
                    'talent-dashboard/profile' :
                    'onboarding-staffer/onboarding-dashboard');
                break;
            case 'talent-help-page':
                this.toggleMenuAndNavigate('talent-dashboard/help');
                break;
            case 'talent-set-lng':
                this.toggleMenuAndNavigate('talent-dashboard/switch-language');
                break;
            case 'talent-add-certificates':
                this.toggleMenuAndNavigate('talent-dashboard/certificates');
                break;
            case 'talent-add-as-team':
                this.toggleMenuAndNavigate('talent-dashboard/add-as-team');
                break;
            case 'talent-upload-equipment':
                this.toggleMenuAndNavigate('talent-dashboard/upload-equipment');
                break;
            case 'talent-profile-picture':
                this.toggleMenuAndNavigate('onboarding-staffer/add-display-picture');
                break;
            case 'talent-add-references':
                this.toggleMenuAndNavigate('onboarding-staffer/add-reference');
                break;
            case 'talent-upload-resume':
                this.toggleMenuAndNavigate('onboarding-staffer/add-resume');
                break;
            case 'talent-ignore-client':
                this.toggleMenuAndNavigate('talent-dashboard/ignore-clients');
                break;
            case 'talent-notification-preferences':
                this.toggleMenuAndNavigate('talent-dashboard/notification-preferences');
                break;
            case 'talent-set-skills-rate':
                this.toggleMenuAndNavigate('talent-dashboard/set-skills-rate');
                break;
            case 'talent-public-profile':
                this.toggleMenuAndNavigate('talent-dashboard/public-profile');
                break;
            case 'upcoming-shifts':
                this.toggleMenuAndNavigate('talent-dashboard/upcoming-shifts');
                break;
            case 'my-applications':
                this.toggleMenuAndNavigate('talent-dashboard/my-applications');
                break;
            case 'available-shifts':
                this.toggleMenuAndNavigate('talent-dashboard/available-shifts');
                break;
            case 'privacy-policy':
                this.toggleMenuAndNavigate('talent-dashboard/privacy-policy');
                break;
            case 'recurring-shifts':
                this.toggleMenuAndNavigate('talent-dashboard/recurring-shifts');
                break;
            case 'talent-staffy-pay':
                this.toggleMenuAndNavigate('talent-dashboard/staffy-pay');
                break;
            case 'talent-log-hours':
                this.toggleMenuAndNavigate('talent-dashboard/log-hours');
                break;
            case 'talent-unverified-hours':
                this.toggleMenuAndNavigate('talent-dashboard/unverified-hours');
                break;
            case 'talent-invoices':
                this.toggleMenuAndNavigate('/talent-dashboard/invoices', { queryParams: { showTab: 'invoices' } });
                break;
            case 'talent-wallet':
                this.toggleMenuAndNavigate('/talent-dashboard/invoices');
                break;
            case 'talent-contractor-tax':
                this.toggleMenuAndNavigate('talent-dashboard/contractor-tax');
                break;
            case 'talent-reports':
                this.toggleMenuAndNavigate('talent-dashboard/reports');
                break;
            case 'talent-sync':
                this.menuCtrl.toggle();
                this.getTalentSync();
                break;
            case 'setup-payment-method':
                this.toggleMenuAndNavigate('/talent-dashboard/payment-method-setup');
                break;
            case 'setup-payment-method':
                this.toggleMenuAndNavigate('talent-dashboard/payment-method-setup');
                break;
            case 'privacy-agreement':
                this.toggleMenuAndNavigate(['/shared/privacy-policy']);
                break;
            case 'logout':
                this.configService.revertToDefault();
                this.menuCtrl.toggle();
                this.store.dispatch(logOutAction());
                break;
            case 'company-tasks':
                this.toggleMenuAndNavigate(['/company-dashboard/tasks']);
                break;
            default:
                break;
        }
    }
    getTalentSync() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            yield this.talentSyncService.sync();
            yield this.notificationService.getNotificationCount();
            this.loadingService.hideLoading(loading);
        });
    }
    toggleMenuAndNavigate(redirection, params) {
        this.menuCtrl.close();
        this.router.navigate(typeof redirection === 'string' ? [redirection] : redirection, params);
    }
    setMenuItems(user, isMobileDevice = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const type = user.type.toString();
            const appPages = type === AccountTypeEnum.EMPLOYER ? this.sideMenuUtilService.getMenuForCompany(isMobileDevice) : this.sideMenuUtilService.getMenuForStaffy();
            yield this.checkForValidation(appPages, user);
            return appPages;
        });
    }
    checkForValidation(appPages, user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            appPages.forEach((page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                page.show = true;
                if (page.title === 'Wallet' && user.address) {
                    this.city = user.address.city === 'New_York' || user.address.province === 'Texas' ? 'US' : 'CA';
                }
                if (page.title == 'Requests') {
                    try {
                        page.openRequestCount = yield this.jobService.getOpenRequestCount();
                        if (typeof page.openRequestCount == 'object') {
                            page.openRequestCount = 0;
                        }
                        page.filledRequestCount = yield this.jobService.getFilledRequestCount();
                        if (typeof page.filledRequestCount == 'object') {
                            page.filledRequestCount = 0;
                        }
                    }
                    catch (error) {
                        console.log('error: ', error);
                    }
                    finally {
                        page.totalRequestCount =
                            (page.openRequestCount ? page.openRequestCount : 0) + (page.filledRequestCount ? page.filledRequestCount : 0);
                    }
                }
                if (page.title === 'Direct Hire') {
                    page.show = user.enableDirectHire;
                }
                if (page.title === 'Add Manual Time') {
                    page.show = !!user.enableManualHoursOnApp;
                }
                if (page.forHomeCareClientOnly) {
                    const isHomecareClient = user.entityId.homecareClient.isTrue || false;
                    page.show = isHomecareClient;
                }
                if (page.subMenu) {
                    page.subMenu.forEach((subPage, i) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (page.title == 'Settings' && subPage.title === 'Edit Picture') {
                            const isHCW = yield this.skillFilterationService.doesUserHasHCSkills(this.skills);
                            if (isHCW && user.profile_approved === true) {
                                subPage.show = false;
                            }
                            else {
                                subPage.show = true;
                            }
                        }
                        else if (page.title == 'Settings' &&
                            subPage.title === 'Set Sub Skills' &&
                            this.utilService.checkNursingSkills(this.skills) == false) {
                            subPage.show = false;
                        }
                        else {
                            subPage.show = true;
                        }
                        if (page.title === 'Direct Hire') {
                            page.show = user.enableDirectHire;
                        }
                        if (page.title === 'Add Manual Time') {
                            page.show = !!user.enableManualHoursOnApp;
                        }
                        if (subPage.title == 'Open Requests') {
                            try {
                                subPage.openRequestCount = yield this.jobService.getOpenRequestCount();
                                if (typeof subPage.openRequestCount == 'object') {
                                    subPage.openRequestCount = 0;
                                }
                            }
                            catch (error) {
                                console.log('error: ', error);
                            }
                            finally {
                                subPage.totalRequestCount =
                                    (subPage.openRequestCount ? subPage.openRequestCount : 0) + (subPage.filledRequestCount ? subPage.filledRequestCount : 0);
                            }
                        }
                        if (subPage.title == 'Filled Requests') {
                            try {
                                subPage.filledRequestCount = yield this.jobService.getFilledRequestCount();
                                if (typeof subPage.filledRequestCount == 'object') {
                                    subPage.filledRequestCount = 0;
                                }
                            }
                            catch (error) {
                                console.log('error: ', error);
                            }
                            finally {
                                subPage.totalRequestCount =
                                    (subPage.openRequestCount ? subPage.openRequestCount : 0) + (subPage.filledRequestCount ? subPage.filledRequestCount : 0);
                            }
                        }
                        if (subPage.forHomeCareClientOnly) {
                            const isHomecareClient = user.entityId.homecareClient.isTrue || false;
                            subPage.show = isHomecareClient;
                        }
                    }));
                }
            }));
            return appPages;
        });
    }
}
LayoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(i0.ɵɵinject(i1.MenuController), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LoadingService), i0.ɵɵinject(i4.TalentSyncService), i0.ɵɵinject(i5.NotificationService), i0.ɵɵinject(i6.PushNotificationService), i0.ɵɵinject(i7.SideMenuUtilService), i0.ɵɵinject(i8.Store), i0.ɵɵinject(i9.JobService), i0.ɵɵinject(i10.UtilService), i0.ɵɵinject(i11.SkillFilterationService), i0.ɵɵinject(i12.ConfigService)); }, token: LayoutService, providedIn: "root" });
