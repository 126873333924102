import { HttpService } from 'src/modules/shared/services/http.service';
import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/http.service";
import * as i2 from "../../shared/services/http-promise.service";
export class AuthenticationService {
    constructor(http, httpPromiseService) {
        this.http = http;
        this.httpPromiseService = httpPromiseService;
    }
    loginViaEmail(data) {
        return this.http.httpPostRequest('/auth/local', data);
    }
    forgotPassword(data) {
        return this.httpPromiseService.httpPostRequest('/api/appInfo/forgotPassword', data);
    }
    findUserByResetPasswordToken(token) {
        return this.httpPromiseService.httpGetRequest(`/api/appInfo/findUserByResetPasswordToken/${token}`);
    }
    resetPassword(body) {
        return this.httpPromiseService.httpPutRequest(`/api/appInfo/resetPassword`, body);
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.HttpPromiseService)); }, token: AuthenticationService, providedIn: "root" });
