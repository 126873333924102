import { Component, EventEmitter, Input, OnInit, Output, forwardRef, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-form-circular-checkbox',
  templateUrl: './form-circular-checkbox.component.html',
  styleUrls: ['./form-circular-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCircularCheckboxComponent),
      multi: true
    }
  ]
})
export class FormCircularCheckboxComponent implements OnInit, ControlValueAccessor {

  @Input() labelText: string;
  @Input() options : Array<any>;
  @Input() disableList: boolean;
  @Input() control;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  
  _value: string[];
  propagateChange = (_: any) => {};
  propagateTouched = (_: any) => {};

  constructor() { }

  ngOnInit() {
  }

  get value(): string[] {
    return this._value;
  }

  set value(value: string[]) {
      this._value = value;
      this.onChange.emit(this._value);
      this.propagateChange(this._value);
  }


  handleChange() {
    const checkedOptions = this.options.filter(item=>item.checked).map(item=>item.value);
    this.onChange.emit(checkedOptions);
    this.value = checkedOptions;
  }

  
  //Form Accessor Functions for custom components
  writeValue(value: any): void {
    this._value = value;
  }
  

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }


}
