<div class="ion-justify-content-center ion-text-center child">
  <h1 class="">
    Stat Holiday Pay
  </h1>

  <div style="height:50%; overflow-y: auto;">
    <h2><b>Holiday Pay Rate: </b><span *ngIf = "statPayJobs.jobs[0].type == 'staffer'">${{ statPayJobs.jobs[0].initialStafferRate * 1.5}}/hr</span>
      <span *ngIf = "statPayJobs.jobs[0].type == 'employer'">${{ statPayJobs.jobs[0].initialRate * 1.5}}/hr</span>
    </h2>
    <div *ngFor="let job of statPayJobs.jobs" class="inner">
      <b> {{ job.momentedShiftStartTimeTz }} </b> &nbsp;<img *ngIf = "job.isStatPay" class="staffer-icons" src="/assets/images/tick.png">
    </div>
  </div>

  <ion-button expand="full" color="dark" id="success-popup-btn" class=" ion-no-margin btn success-popup-ok"
    (click)='submit()'>
    Dismiss
  </ion-button>
</div>