/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./certificate-date-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "./certificate-date-picker.component";
import * as i6 from "../../services/certificate-validation-rules.service";
var styles_CertificateDatePickerComponent = [i0.styles];
var RenderType_CertificateDatePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CertificateDatePickerComponent, data: {} });
export { RenderType_CertificateDatePickerComponent as RenderType_CertificateDatePickerComponent };
export function View_CertificateDatePickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { picker: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "issue-date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "ion-datetime", [["cancelText", ""], ["class", "issue-date-picker"], ["display-format", "DD MMM YYYY"], ["doneText", "Done"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.selectedDate = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ionChange" === en)) {
        var pd_3 = (_co.handleDateChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_IonDatetime_0, i2.RenderType_IonDatetime)), i1.ɵdid(3, 16384, null, 0, i3.SelectValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectValueAccessor]), i1.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(8, 49152, [[1, 4], ["picker", 4]], 0, i3.IonDatetime, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { cancelText: [0, "cancelText"], doneText: [1, "doneText"], max: [2, "max"], pickerFormat: [3, "pickerFormat"], pickerOptions: [4, "pickerOptions"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.selectedDate; _ck(_v, 5, 0, currVal_7); var currVal_8 = ""; var currVal_9 = "Done"; var currVal_10 = _co.maxDate; var currVal_11 = _co.pickerFormat; var currVal_12 = _co.pickerOptions; _ck(_v, 8, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CertificateDatePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-certificate-date-picker", [], null, null, null, View_CertificateDatePickerComponent_0, RenderType_CertificateDatePickerComponent)), i1.ɵdid(1, 12828672, null, 0, i5.CertificateDatePickerComponent, [i3.ModalController, i6.CertificateValidationRulesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CertificateDatePickerComponentNgFactory = i1.ɵccf("app-certificate-date-picker", i5.CertificateDatePickerComponent, View_CertificateDatePickerComponent_Host_0, { heading: "heading", certificateType: "certificateType", timeZone: "timeZone", certificateDateType: "certificateDateType", certificateSpecification: "certificateSpecification", maxDate: "maxDate" }, {}, []);
export { CertificateDatePickerComponentNgFactory as CertificateDatePickerComponentNgFactory };
