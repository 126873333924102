import * as tslib_1 from "tslib";
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { TalentDashboardService } from 'src/modules/talent-dashboard/services/talent-dashboard.service';
import { LoadingService } from './loading.service';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { SuccessPopupComponent } from '../components/success-popup/success-popup.component';
import { UtilService } from './util.services';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "./loading.service";
import * as i3 from "@ionic-native/file-transfer/ngx/index";
import * as i4 from "@ionic-native/file-opener/ngx/index";
import * as i5 from "@ionic-native/file/ngx/index";
import * as i6 from "../../talent-dashboard/services/talent-dashboard.service";
import * as i7 from "./util.services";
import * as i8 from "../../talent-dashboard/services/talent-job-format.service";
export class InvoicesService {
    constructor(platform, loadingService, transfer, fileopen, file, modalController, talentDashboardService, utilService, talentJobFormatService) {
        this.platform = platform;
        this.loadingService = loadingService;
        this.transfer = transfer;
        this.fileopen = fileopen;
        this.file = file;
        this.modalController = modalController;
        this.talentDashboardService = talentDashboardService;
        this.utilService = utilService;
        this.talentJobFormatService = talentJobFormatService;
        this.bigDeci = this.utilService.bigDeci;
    }
    download(invoice, isBrowser) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                const response = yield this.talentDashboardService.generateInvoicePdf(invoice);
                const pdfData = response.attachment;
                const linkSource = `data:application/pdf;base64,${pdfData}`;
                const downloadLink = document.createElement('a');
                const fileName = `Invoice-${invoice.serialNo}.pdf`;
                if ((this.platform.is('android') || this.platform.is('ios') || this.platform.is('ipad')) && !isBrowser) {
                    const path = this.file.dataDirectory;
                    const fileTransfer = this.transfer.create();
                    fileTransfer.download(linkSource, path + fileName).then((entry) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        let data = {
                            heading: 'Success',
                            message: 'Download complete!',
                            btn: 'Open',
                            navigateRoute: null,
                            imgURL: 'assets/images/thumbs-up.png'
                        };
                        this.createPopup(data, true, entry);
                    })).catch((err) => {
                        console.log(err);
                        alert('Error saving file: ' + err.message);
                    });
                }
                else {
                    // If downloaded by Web Browser or ios
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
            }
            catch (error) {
                console.log('err', error);
                let data = {
                    heading: 'Error',
                    message: typeof error.error === 'string' ? error.error : 'Sorry, Unable to download, try again',
                    btn: 'Dismiss',
                    navigateRoute: null,
                    imgURL: 'assets/images/sorry1.png'
                };
                this.createPopup(data, false);
            }
            finally {
                yield this.loadingService.hideLoading(loading);
            }
        });
    }
    createPopup(data, isSuccess, entry) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: SuccessPopupComponent,
                backdropDismiss: false,
                cssClass: 'wideModal',
                componentProps: {
                    data
                }
            });
            yield myModal.present();
            return yield myModal.onDidDismiss().then(dismiss => {
                if (isSuccess)
                    this.fileopen.open(entry.toURL(), 'application/pdf');
                else
                    return;
            });
        });
    }
    formatNumberIntoHumanReadableForm(data) {
        // Format all variables into readable format
        const shiftAmount = this.bigDeci(data.shiftAmount, true) || 0.00;
        const contractorTax = this.bigDeci(data.contractorTax, true) || 0.00;
        const subTotal = this.bigDeci(data.subTotal, true) || 0.00;
        const total = this.bigDeci(data.total, true, 2) || 0.00;
        const rate = this.bigDeci(data.rate, true) || 0.00;
        const backgroundCheckAmount = this.bigDeci(data.backgroundCheckAmount, true) || 0.00;
        const penaltyAmount = this.bigDeci(data.invoice.penaltyForLastShift, true) || 0.00;
        return [
            shiftAmount,
            contractorTax,
            subTotal,
            total,
            rate,
            backgroundCheckAmount,
            penaltyAmount
        ];
    }
    calculateInvoiceBreakdown(invoice) {
        let trackedHours = invoice.timeTracked ? moment.duration(moment.duration(invoice.timeTracked).asHours(), 'hours').format("h:mm", { trim: false }) : '';
        let overtimeHours = invoice.overtime.time > 0 ? moment.duration(moment.duration(invoice.overtime.time).asHours(), 'hours').format("h:mm", { trim: false }) : '';
        const rate = invoice.jobs.length ? invoice.jobs[0].job.stafferRate : 0;
        let overtimeRate = this.calculateOvertimeRate(invoice);
        if (trackedHours)
            trackedHours = this.talentJobFormatService.formatTrackedHours(trackedHours);
        if (overtimeHours)
            overtimeHours = this.talentJobFormatService.formatTrackedHours(overtimeHours);
        // Feeses
        const backgroundCheckAmount = invoice.backgroundCheckAmount > 0 ? invoice.backgroundCheckAmount : 0;
        const manualHrsReviewFee = invoice.manualHrsReviewFee > 0 ? invoice.manualHrsReviewFee : 0;
        const contractorTax = invoice.contractorTax > 0 ? invoice.contractorTax : 0;
        // Calculation
        const subtractTax = (invoice.contractorTax > 0 && invoice.status === 'paid') ? Number(this.bigDeci(invoice.shiftAmount).minus(this.bigDeci(invoice.contractorTax))) : invoice.shiftAmount;
        const shiftAmount = invoice.staffyPayAdminFee > 0 && invoice.status === 'paid' ? Number(this.bigDeci(subtractTax).plus(this.bigDeci(invoice.staffyPayAdminFee)).plus(this.bigDeci(backgroundCheckAmount)).plus(this.bigDeci(manualHrsReviewFee)).plus(this.bigDeci(invoice.penaltyForLastShift))) : Number(this.bigDeci(subtractTax).plus(this.bigDeci(backgroundCheckAmount)).plus(this.bigDeci(manualHrsReviewFee)).plus(this.bigDeci(invoice.penaltyForLastShift)));
        // subtotal = ( contractorTax + Shift amount ) - adminFee
        const subTotal = Number(this.bigDeci(shiftAmount).minus(this.bigDeci(backgroundCheckAmount)).minus(this.bigDeci(manualHrsReviewFee)).minus(this.bigDeci(invoice.penaltyForLastShift)).minus(this.bigDeci(invoice.staffyPayAdminFee)));
        const total = Number(this.bigDeci(shiftAmount).plus(this.bigDeci(contractorTax)).minus(this.bigDeci(invoice.staffyPayAdminFee)).minus(this.bigDeci(backgroundCheckAmount)).minus(this.bigDeci(manualHrsReviewFee)).minus(this.bigDeci(invoice.penaltyForLastShift)));
        return [
            shiftAmount,
            subTotal,
            total,
            backgroundCheckAmount,
            manualHrsReviewFee,
            contractorTax,
            trackedHours,
            overtimeHours,
            rate,
            overtimeRate
        ];
    }
    calculateOvertimeRate(invoice) {
        if (!invoice.jobs.length) {
            return '';
        }
        const { overtimeSettings, stafferRate } = invoice.jobs[0].job;
        return (stafferRate * overtimeSettings.multiplier).toFixed(2);
    }
    getInvoiceLabel(eligibilityCriteria, scheduledDatePassed) {
        if (eligibilityCriteria && !scheduledDatePassed) {
            // If the invoice hasn't been paid yet and they're eligible for Staffypay, the button should say "StaffyPay".
            return "StaffyPay";
        }
        else if (!eligibilityCriteria && !scheduledDatePassed) {
            // If they're not eligible then it should say "Pending"
            return "Pending";
        }
        else if (scheduledDatePassed) {
            // If it's after the date it's to be paid and still hasn't been paid, it should say "Pay now"
            return "Pay now";
        }
        else {
            return "Pending";
        }
    }
    eligibilityCheck(invoice) {
        const eligibilityCriteria = invoice.jobs.length && invoice.jobs[0].staffers.length ?
            invoice.jobs[0].staffers[0].staffer.shifts_completed >= 5 && invoice.jobs[0].staffers[0].staffer.aggregated_rating >= 4.2 : false;
        const scheduledDatePassed = moment(invoice.staffyPayScheduledDate, 'MM/DD/YYYY').tz('America/Toronto').isBefore(moment().tz('America/Toronto'));
        return [eligibilityCriteria, scheduledDatePassed];
    }
    formatInvoice(invoice) {
        [invoice.eligibilityCriteria, invoice.scheduledDatePassed] = this.eligibilityCheck(invoice);
        invoice.applyInvoiceLabel = this.getInvoiceLabel(invoice.eligibilityCriteria, invoice.scheduledDatePassed);
        [
            invoice.shiftAmount,
            invoice.subTotal,
            invoice.total,
            invoice.backgroundCheckAmount,
            invoice.manualHrsReviewFee,
            invoice.contractorTax,
            invoice.trackedHours,
            invoice.overtimeHours,
            invoice.rate,
            invoice.overtimeRate
        ] = this.calculateInvoiceBreakdown(invoice);
        const invoiceFractions = {
            shiftAmount: invoice.shiftAmount,
            contractorTax: invoice.contractorTax,
            subTotal: invoice.subTotal,
            total: invoice.total,
            rate: invoice.rate,
            backgroundCheckAmount: invoice.backgroundCheckAmount,
            invoice: invoice
        };
        [invoice.shiftAmount,
            invoice.contractorTax,
            invoice.subTotal,
            invoice.total,
            invoice.rate,
            invoice.backgroundCheckAmount,
            invoice.penaltyAmount] = this.formatNumberIntoHumanReadableForm(invoiceFractions);
        return invoice;
    }
    formatPaymentDate(date, format) {
        let result = moment.unix(date).format(format);
        return result;
    }
}
InvoicesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvoicesService_Factory() { return new InvoicesService(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.LoadingService), i0.ɵɵinject(i3.FileTransfer), i0.ɵɵinject(i4.FileOpener), i0.ɵɵinject(i5.File), i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i6.TalentDashboardService), i0.ɵɵinject(i7.UtilService), i0.ɵɵinject(i8.TalentJobFormatService)); }, token: InvoicesService, providedIn: "root" });
