/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contract-update-notice.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./contract-update-notice.component";
import * as i6 from "@angular/router";
var styles_ContractUpdateNoticeComponent = [i0.styles];
var RenderType_ContractUpdateNoticeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContractUpdateNoticeComponent, data: {} });
export { RenderType_ContractUpdateNoticeComponent as RenderType_ContractUpdateNoticeComponent };
function View_ContractUpdateNoticeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null))], null, null); }
function View_ContractUpdateNoticeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "notice-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "para"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "btn-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ion-button", [["class", "ion-no-margin btn"], ["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.readMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(8, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" More Information "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "ion-button", [["class", "ion-no-margin btn"], ["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptDoc() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Review and Accept Document "]))], function (_ck, _v) { var currVal_2 = "basic"; _ck(_v, 8, 0, currVal_2); var currVal_3 = "basic"; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notices.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.notices.shortNotice; _ck(_v, 5, 0, currVal_1); }); }
function View_ContractUpdateNoticeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-button", [["class", "ion-no-margin btn close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, [" Close "]))], null, null); }
function View_ContractUpdateNoticeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "notice-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "para"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "btn-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ion-button", [["class", "ion-no-margin btn"], ["color", "basic"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptDoc() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(6, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Review and Accept Document "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractUpdateNoticeComponent_4)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "basic"; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.data.allowClose; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notices.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.notices.fullNotice; _ck(_v, 3, 0, currVal_1); }); }
export function View_ContractUpdateNoticeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "notice-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "notice-head"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractUpdateNoticeComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "alret"], ["src", "assets/images/alert.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContractUpdateNoticeComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["fullNotice", 2]], null, 0, null, View_ContractUpdateNoticeComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.allowClose; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.showFullNotice; var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_ContractUpdateNoticeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contract-update-notice", [], null, null, null, View_ContractUpdateNoticeComponent_0, RenderType_ContractUpdateNoticeComponent)), i1.ɵdid(1, 114688, null, 0, i5.ContractUpdateNoticeComponent, [i3.ModalController, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractUpdateNoticeComponentNgFactory = i1.ɵccf("app-contract-update-notice", i5.ContractUpdateNoticeComponent, View_ContractUpdateNoticeComponent_Host_0, { data: "data" }, {}, []);
export { ContractUpdateNoticeComponentNgFactory as ContractUpdateNoticeComponentNgFactory };
