/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./app.component";
import * as i7 from "../modules/shared/services/language.service";
import * as i8 from "@ngrx/store";
import * as i9 from "@ionic-native/status-bar/ngx/index";
import * as i10 from "../modules/shared/services/network.service";
import * as i11 from "../modules/shared/services/push-notification.service";
import * as i12 from "../modules/shared/services/local-storage.service";
import * as i13 from "../modules/shared/services/app-info.service";
import * as i14 from "../modules/shared/services/util.services";
import * as i15 from "./services/app-updates.service";
import * as i16 from "../modules/shared/services/skills.service";
import * as i17 from "./services/config.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "network-status"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["STAFFY is running in offline mode!"]))], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "network-status"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" STAFFY is running in Demo mode! "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "close-demo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideDemoBanner() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Hide"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "exit-demo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exitDemoMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Exit Demo"]))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-app", [], null, null, null, i2.View_IonApp_0, i2.RenderType_IonApp)), i1.ɵdid(1, 49152, null, 0, i3.IonApp, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, 0, 1, "ion-router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i3.IonRouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i3.Config, i3.NavController, i4.Location, i1.ElementRef, i5.Router, i1.NgZone, i5.ActivatedRoute, [3, i3.IonRouterOutlet]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isOnline; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.isDemo && _co.showDemoBanner); _ck(_v, 5, 0, currVal_1); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppComponent, [i3.Platform, i7.LanguageService, i8.Store, i9.StatusBar, i10.NetworkService, i11.PushNotificationService, i12.LocalStorageService, i13.AppInfoService, i14.UtilService, i15.AppUpdateService, i16.SkillsService, i17.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
