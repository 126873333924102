import { ErrorHandler } from '@angular/core';
import { environment } from 'src/environments/environment';

import * as Sentry from '@sentry/browser';

export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error) {
    super.handleError(error);
    try {
      if (environment.production) {
        Sentry.captureException(error.originalError || error);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
