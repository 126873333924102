import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HomecarePatient } from '../../interfaces/homecare-patient.interface';

@Component({
  selector: 'app-shift-carenotes',
  templateUrl: './shift-carenotes.component.html',
  styleUrls: ['./shift-carenotes.component.scss'],
})
export class ShiftCarenotesComponent implements OnInit {

  @Input() data : Array<HomecarePatient> = []

  constructor(
    private modalController: ModalController, 
  ) { }

  ngOnInit() {
  }

  onClose(){
    this.modalController.dismiss()
  }

  getPatientTitle(patient: HomecarePatient){
    let title = patient.id || "";

    if (patient.address && patient.address.street) {
        title = title.length ? `${title} - ${patient.address.street}` : patient.address.street;
    }

    return title;
  }

}
