import { Component, OnInit, Input, ViewChild, EventEmitter, Output, OnChanges } from '@angular/core';
import { IonRange, ModalController } from '@ionic/angular';
import { ISetAvailability } from 'src/modules/talent-dashboard/interfaces/set-availability.interface';

@Component({
  selector: 'app-choose-time-modal',
  templateUrl: './choose-time-modal.component.html',
  styleUrls: ['./choose-time-modal.component.scss'],
})
export class ChooseTimeModalComponent implements OnInit {
  heading: string;
  @Input() slider: {
      min: number,
      max: number,
      ticked: boolean,
      floor?: number,
      ceil?: number,
    }

  
  constructor(private modalController: ModalController) { }
  timeVal: {
    lower: number,
    upper: number
  };
  
  @ViewChild(IonRange, { static: false }) ionRanger: IonRange;
  @Output() setNewTime = new EventEmitter<ISetAvailability>();
  
  
  @Input() hr = 6;
  @Input() min = 30;
  @Input() a = 'AM';

  ngOnInit() {
    this.timeVal = {
      lower: (this.slider.floor ? this.slider.floor : this.slider.min),
      upper: (this.slider.ceil ? this.slider.ceil : this.slider.max)
    }
  }
  cancel() {
    this.modalController.dismiss();
  }
  submit() {
    const data = {startTime: this.timeVal.lower, endTime: this.timeVal.upper}
    this.modalController.dismiss(data);
  }
}
