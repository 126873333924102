import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { INotification } from 'src/modules/talent-dashboard/interfaces/notification.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { UtilService } from '../../services/util.services';
import { NotificationService } from '../../services/notification.service';
import { AlertController } from '@ionic/angular';
import { PopupService } from '../../services/popup.service';
import * as moment from 'moment-timezone';
import { findIndex } from 'lodash';
import { Router } from '@angular/router';
import { IUpdateUser } from 'src/modules/authentication/interfaces/update-user.interface';
import { ITalentJob } from 'src/modules/talent-dashboard/interfaces/talent-job.interface';
import { JobService } from '../../services/job.service';
import { TalentJobChecksService } from '../../../talent-dashboard/services/talent-job-checks.service';
import { ISetting } from 'src/modules/talent-dashboard/interfaces/settings.interface';
import { IEntity } from 'src/modules/talent-dashboard/interfaces/entity.interface';
import { setAvailableShiftAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { Store } from '@ngrx/store';
import { TalentDashboardUtilService } from 'src/modules/talent-dashboard/services/talent-dashboard-util.service';
import { SubSkillService } from '../../services/subskill.service';

@Component({
  selector: 'shared-notification-card-recurring',
  templateUrl: './notification-card-recurring.component.html',
  styleUrls: ['./notification-card-recurring.component.scss'],
})
export class NotificationCardRecurringComponent implements OnInit, OnChanges {
  @Input() hour24PayAvailableBadge: boolean;
  @Input() notification: INotification;
  @Input() settings: ISetting[];
  @Input() userData: IUser;
  @Output() acceptRecurringBroadcastRequest = new EventEmitter<{
    groupJobId: string,
    stafferId: string,
    rcmpFeeRequired: boolean,
    skill: string ,
    entity: IEntity,
    job : ITalentJob
  }>();
  @Output() rejectStafferRecurringBroadcastRequest = new EventEmitter<{
    groupJobId: string
    stafferId: string,
    notificationId: string
  }>();
  @Output() unApplyFromRecurringShift = new EventEmitter<{
    groupJobId: string,
    dueToRCMPFee: boolean
  }>();
  @Output() confirmRecurringShift = new EventEmitter<{
    groupJobId: string
  }>();
  @Output() acceptRecurringHireRequest = new EventEmitter<{
    groupJobId: string,
    shiftResponseTime: number
  }>();
  @Output() rejectRecurringHireRequest = new EventEmitter<{
    groupJobId: string,
  }>();
  @Output() stafferStillWorkingOnRecurringJob = new EventEmitter<{
    trackerId: string
  }>();
  @Output() stopTimerOnRecurringJob = new EventEmitter<{
    jobId: string,
    endTime: number,
    userId: string,
    skill: string
  }>();
  @Output() hireStafferForTheRecurringJob = new EventEmitter<{
    staffer: IUser,
    groupJobId: string
  }>();
  @Output() submitRating = new EventEmitter<{
    jobId: string,
    stafferId: string,
    rating: number,
    skill: string
  }>();
  @Output() seeAvailableJobs = new EventEmitter();
  @Output() updateUser = new EventEmitter<IUpdateUser>();

  @Output() hideNotificationDrawer = new EventEmitter();

  profileUrl: string;
  notificationName: string;
  jobState: string;
  recurringJobState: string;
  isRecurringIsAJobOffer: boolean;
  isRecurringIsStafferHired: boolean;
  isRecurringIsQuotaFilled: boolean;
  isRecurringRejected: boolean;
  isRecurringAlreadyApplied: boolean;
  quotaFilled: boolean;
  stafferConfirmedShift: boolean;
  stafferHired: boolean;
  filedDispute: boolean;
  jobActive: boolean;
  jobAccpted: boolean;
  jobRate: number;
  isMobile: boolean;
  trackerIsStillWorking: boolean;
  isIntrestedStaffer: boolean;
  hoursUnConfimred: boolean;
  count = 0;
  
  constructor(
    private utilService: UtilService,
    private notificationService: NotificationService,
    private popupService: PopupService,
    private router: Router,
    private alertController: AlertController,
    private jobService: JobService,
    private jobChecksService: TalentJobChecksService,
    private jobFormattingService: TalentJobFormatService,
    private readonly authStore: Store<IAuthState>,
    private talentDashboardUtilService: TalentDashboardUtilService,
    private subSkillService: SubSkillService

  ) {
    this.isMobile = this.utilService.isMobile();

  }

  ngOnInit() { }
  ngOnChanges() {
    if (this.userData) {
      this.profileUrl = this.notificationService.getProfilePic(this.notification, this.userData);
      this.notification.showPopup = this.notificationService.showPopup(this.notification);
      this.notificationName = this.notificationService.getName(this.notification, this.userData);
      this.notification.isRecurringIsAJobOffer = this.recurringIsAJobOffer();
      this.notification.isRecurringIsStafferHired = this.recurringIsStafferHired();
      this.notification.isRecurringIsQuotaFilled = this.recurringIsQuotaFilled();
      this.notification.isRecurringRejected = this.recurringRejected();
      this.notification.isRecurringAlreadyApplied = this.recurringAlreadyApplied();
      this.notification.stafferConfirmedShift = this.notificationService.isStafferConfirmedShift(this.notification, this.userData);
      this.notification.quotaFilled = this.notificationService.isQuotaFilled(this.notification);
      this.notification.stafferHired = this.notificationService.isStafferHired(this.notification, this.notification.user);
      this.notification.jobState = this.notificationService.getJobState(this.notification);
      this.notification.filedDispute = this.notificationService.hasFiledDispute(this.notification);
      this.notification.recurringJobState = this.getRecurringJobState();
      this.notification.jobActive = this.notificationService.isJobActive(this.notification);
      this.notification.jobAccpted = this.notificationService.isJobAccpted(this.notification, this.userData);
      this.notification.trackerIsStillWorking = this.notificationService.findTrackerIsStillWorking(this.notification, this.userData);
      this.notification.isIntrestedStaffer = this.recurringCheckStatus();
      this.notification.hoursUnConfimred = this.notificationService.isHoursUnConfimred(this.notification, this.userData);
      if(this.userData.type == 'staffer' && this.notification.job != null) {
        this.notification.jobRate = this.notification.job.initialStafferRate ? this.notification.job.initialStafferRate : (this.notification.job.initialRate ? this.notification.job.initialRate : (this.notification.job.stafferRate ? this.notification.job.stafferRate : this.notification.job.rate));
      } else {
        if(this.notification.job != null){
          this.notification.jobRate = this.notification.job && this.notification.job.initialRate ? this.notification.job.initialRate : this.notification.job.rate
        }
      }
    }
  }

  async seeDetails() {
     
    if (this.notification.key == "recurrent_shift_modified") {
      await this.notificationService.showShiftModifiedModal(this.notification.groupJobId._id)
    }else if (this.notification.key == "recurrent_shift_extended") {
      await this.notificationService.showShiftExtendedModal(this.notification.groupJobId._id)
    } else if (this.notification.key == "recurrent_shift_cancelled") {
      await this.notificationService.showShiftCancellationModal(this.notification.groupJobId._id)
    }
    else {
      await this.notificationService.showModal(this.notification, this.userData, this.settings,
        (data) => {
          if (data) {
            this[data]();
          }
        })
   }
  }


  async showStatPayShifts(jobItem: ITalentJob) {
    this.utilService.showStatPayShifts(jobItem, this.userData.type, true);
  }

  confirmShift() {
    this.confirmRecurringShift.emit({ groupJobId: this.notification.job.recurringShift.groupJobId });
  }

  recurringIsAJobOffer() {
    let is = false;
    const jobs = this.notification.groupJobId.jobs;
    if (this.notification.type === 'recurring broadcast offer') {
      for (const job of jobs) {
        const staffers = job.isABroadcastRequest.interestedStaffers;
        is = true;
        for (const staffer of staffers) {
          if (this.notification.user._id === staffer) {
            is = false;
            break;
          }
        }
        if (is === false) {
          break;
        }
      }
    }

    if (this.notification.type === 'recurring shift offer') {
      for (const job of jobs) {
        const staffers = job.staffersAndShifts;
        for (const staffer of staffers) {
          if (this.notification.user._id === staffer.staffer
            && staffer.state === 'offer') {
            is = true;
            break;
          }
        }
        if (is === true) {
          break;
        }
      }
    }


    return is;
  }

  recurringIsStafferHired() {
    let is = false;
    const jobs = this.notification.groupJobId.jobs;
    for (const job of jobs) {
      for (const shift of job.staffersAndShifts) {
        if (this.userData &&
          this.userData._id === shift.staffer) {
          is = true;
          break;
        }
      }
      if (is) {
        break;
      }
    }
    return is;


  }

  recurringIsQuotaFilled() {
    let isFilled = false;
    const jobs = this.notification.groupJobId.jobs;
    for (const job of jobs) {
      if (job.isABroadcastRequest.vacancies ===
        this.utilService.isStaffersConfirmedShifts(job.staffersAndShifts).length) {
        isFilled = true;
        break;
      }
    }

    return isFilled;
  }

  recurringRejected() {
    let rejected = false;
    const jobs = this.notification.groupJobId.jobs;
    for (const job of jobs) {
      const list = job.isABroadcastRequest.broadcastedTo;
      for (const item of list) {
        if (item.staffer === this.userData._id && item.status === 'rejected') {
          rejected = true;
          break;
        }
      }
      if (rejected) {
        return rejected;
      }
    }
    return rejected;
  }

  recurringAlreadyApplied() {
    const jobs = this.notification.groupJobId.jobs;
    for (const job of jobs) {
      const list = job.isABroadcastRequest.interestedStaffers;
      if (list.indexOf(this.userData._id) > -1) {
        return true;
      }
    }
    return false;
  }

  getRecurringJobState() {
    let state = 'offer';
    if (this.notification.type === 'recurring broadcast offer') {
      const jobs = this.notification.groupJobId.jobs;
      for (const job of jobs) {
        const list = job.isABroadcastRequest.broadcastedTo;
        for (const shift of list) {
          if (shift.staffer === this.notification.user._id) {
            state = shift.status;
            break;
          }
        }
      }
    }
    return state;

  }


  recurringCheckStatus() {
    let isInterested = true;
    for (const job of this.notification.groupJobId.jobs) {
      const list = job.isABroadcastRequest.broadcastedTo;
      for (const item of list) {
        if (this.notification.user && this.notification.user._id == item.staffer && item.status !== 'interested') {
          isInterested = false;
          break;
        }
      }
    }
    return isInterested;
  }

  //////////// Buttons events ////////////
  
  async recurringRespondToBroadcastRequest() {
    let jobItem = this.notification.job;

    /**
     * As we have to redirect to shift detail page so we have to make sure to format data according to that view
     * 
     * Problem : noification listing payload is different from available shift payload
     * 
     * Solution :  format payload according to that view
     * 
     * In this function we format the data , redirect to shift detail page and close the notification drawer
     * 
     * 
     */
    jobItem = { ...jobItem, jobs: this.notification.groupJobId.jobs , recurringShift : {
      ...this.notification.job.recurringShift,
      groupJobId : this.notification.groupJobId._id,
      groupShift: this.notification.groupJobId
    }}

    let results =  this.jobFormattingService.formatAvailableShifts([jobItem], this.userData);


    let hasRequiredSubSkills = this.talentDashboardUtilService.hasRequiredSubSkills(this.userData, results[0])

    if(!hasRequiredSubSkills){
      this.subSkillService.checkSubSkillError({
        error: `You do not have the required subskills (${this.talentDashboardUtilService.getMissingSubSkills(this.userData,results[0]).join(", ")}) to work this shift.`,
      },this.userData)
      return;
    }

    if(this.hideNotificationDrawer){
      this.hideNotificationDrawer.emit()
    }

    this.router.navigate(['/talent-dashboard/shift-details']);
    this.authStore.dispatch(setAvailableShiftAction({ availableShift: results[0] }));
  }
  
  validateAndAccept(rcmpFeeRequired) {
    if (this.notificationService.validateShiftEndTime(this.notification.groupJobId.endTime)) {
      this.acceptRecurringBroadcastRequest.emit({
        groupJobId: this.notification.groupJobId._id,
        stafferId: this.userData._id,
        rcmpFeeRequired: rcmpFeeRequired,
        skill: this.notification.groupJobId.jobs[this.notification.groupJobId.jobs.length - 1].skill,
        entity: this.notification.job.entity,
        job :this.notification.job
      });
    } else {
      this.popupService.showModal({
        heading: 'Sorry',
        message: 'Sorry, this shift is no longer available',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/profile.png'

      });
    }
  }

  recurringRejectBroadcastRequest() {
    this.rejectStafferRecurringBroadcastRequest.emit({
      groupJobId: this.notification.groupJobId._id,
      stafferId: this.notification.user._id,
      notificationId: this.notification._id
    });
  }

  recurringUnApplyFromShift(dueToRCMPFee = false) {
    this.unApplyFromRecurringShift.emit({
      groupJobId: this.notification.groupJobId._id,
      dueToRCMPFee
    });
  }

  recurringAcceptJob() {
    if (this.notificationService.validateShiftEndTime(this.notification.groupJobId.endTime)) {
      this.acceptRecurringHireRequest.emit({
        groupJobId: this.notification.groupJobId._id,
        shiftResponseTime: moment().diff(moment(this.notification.created))
      });

    } else {
      this.popupService.showModal({
        heading: 'Sorry',
        message: 'Sorry, this shift is no longer available',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'

      });
    }
  }

  recurringRejectJob() {
    this.rejectRecurringHireRequest.emit({
      groupJobId: this.notification.groupJobId._id,
    });
  }

  stillWorkingStaffer() {
    const currentUserIndex = findIndex(this.notification.job.staffersAndShifts, ['staffer', this.userData._id]);

    if (this.notification.job.staffersAndShifts[currentUserIndex].tracker.state !== 'stopped') {

      const trackerId = this.notification.job.staffersAndShifts[currentUserIndex].tracker._id;
      this.stafferStillWorkingOnRecurringJob.emit({
        trackerId
      });

    } else {
      this.popupService.showModal({
        heading: 'End Shift Request',
        message: 'Shift already ended.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'

      });
    }
  }

  stopTrackerNow() {
    const currentUserIndex = findIndex(this.notification.job.staffersAndShifts, ['staffer', this.userData._id]);

    if (this.notification.job.staffersAndShifts[currentUserIndex].tracker.state !== 'stopped') {

      this.stopTimerOnRecurringJob.emit({
        jobId: this.notification.job._id,
        endTime: +moment().tz(this.notification.job.timeZone).format('X'),
        userId: this.userData._id,
        skill: this.notification.job.skill
      })

    } else {
      this.popupService.showModal({
        heading: 'End Shift Request',
        message: 'Shift already ended.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'

      });
    }
  }

  recurringHireStaffer() {
    if (this.notificationService.validateShiftEndTime(this.notification.groupJobId.endTime)) {
      this.hireStafferForTheRecurringJob.emit({
        staffer: this.notification.user,
        groupJobId: this.notification.groupJobId._id
      });

    } else {
      this.popupService.showModal({
        heading: 'Sorry',
        message: 'Sorry, this shift is no longer available',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/profile.png'

      });
    }
  }

  rateStaffer() {
    this.popupService.showStafferRatingPopup({
      name: this.notificationName,
      message: 'Please review and rate the staffer',
    }, (cb: {
      data: { rating: number }
    }) => {
        if (cb.data.rating) {
          this.submitRating.emit({
            jobId: this.notification.job._id,
            stafferId: this.notification.user._id,
            rating: cb.data.rating,
            skill: this.notification.job.skill
          });
        }
      });
  }

  fileDispute() {

    this.notificationService.disputeToFileData = {
      jobId: this.notification.job._id,
      skill: this.notification.job.skill,
      staffer: this.notification.user
    };
    this.router.navigateByUrl(`/company-dashboard/file-dispute`);
  }

  showLocationOnMap(lat: number, lng: number, name: string) {
    this.utilService.launchAddressOnMap(lat, lng, name);
  }

  seeAvailableShifts() {
    this.seeAvailableJobs.emit();
  }
}
