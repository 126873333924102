import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { IDatePickerConfig, DatePickerComponent, ECalendarValue } from 'ng2-date-picker';
import * as moment from 'moment-timezone';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {
  displayDate: string = moment().format('ddd, MMM DD');

  dateConfig: IDatePickerConfig = {
    allowMultiSelect: false,
    format: 'MMM, YYYY',
    closeOnSelect: false,
    showGoToCurrent: true,
    max: moment(),
    disableKeypress: true,
  };

  selectedDate;
  @ViewChild('datePicker', {static: false}) datePicker: DatePickerComponent;  
  @Input() startDate: string;
  @Input() referenceType: string;
  @Input() type: string;
  start;

  constructor(
    private cd: ChangeDetectorRef,
    private modalController: ModalController
  ) {
    this.dateConfig = {
      allowMultiSelect: false,
      format: 'MMM, YYYY',
      closeOnSelect: false,
      showGoToCurrent: true,
      max: moment(),
      disableKeypress: true
    };

    console.log(this.dateConfig)
 }

  ngOnInit() {
    this.start = this.startDate;
    
    if(this.start) {
      this.selectedDate = moment(this.start, 'MMMM Do YYYY');
    }
  } 


  ngAfterViewInit() {
        
    if(this.datePicker)
    this.datePicker.api.open();

    this.cd.detectChanges();

  } 

  dateChanged() {
    if(this.selectedDate) {
      this.displayDate = moment(this.selectedDate).format('ddd, MMM DD')
    }
  }

  selectDate(isCurrentlyWorking?: boolean) {

    if(isCurrentlyWorking)
      this.modalController.dismiss({
        isCurrentlyWorking
      }
    );

    this.modalController.dismiss(
      moment(this.selectedDate).format('MMMM Do YYYY')
    );
  }

  close() {
    this.modalController.dismiss();
  }
}
