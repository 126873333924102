import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-radio-buttons',
  templateUrl: './form-radio-buttons.component.html',
  styleUrls: ['./form-radio-buttons.component.scss'],
})
export class FormRadioButtonsComponent implements OnInit {

  @Input() labelText: string;
  @Input() options: any[];
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  selectedOption: any;
  
  constructor() { }

  ngOnInit() {
    //Select defauult option
    this.selectedOption = this.options[0].value;
  }

  handleChange(event: any){
    this.onChange.emit(event);
  }


}

