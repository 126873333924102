import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Router } from '@angular/router';


@Component({
  selector: 'app-social-signup',
  templateUrl: './social-signup.component.html',
  styleUrls: ['./social-signup.component.scss'],
})
export class SocialSignupComponent implements OnInit {
  @Input() data: {
    message: string;
    btn: string;
    navigateRoute: string;
    imgURL?: string;
  };
  
  constructor(
    private modalController: ModalController,
    private inAppBrowser: InAppBrowser,
    private router: Router
  ) { }
  ngOnInit() {}

  dismissPopup() {
    this.modalController.dismiss();
  }


  socialSiteNaviate(socialSite: string) {
    const site = {
      facebook: 'https://www.facebook.com/groups/communitybystaffyapp/',
      instagram: 'https://www.instagram.com/staffyapp/?hl=en',
    }
    this.inAppBrowser.create(site[socialSite], "_system");
  }

  navigateToHome() {
    this.modalController.dismiss();
    this.router.navigateByUrl('talent-dashboard/home');
  }
}

