import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { BulletinViewerComponent } from '../components/bulletin-viewer/bulletin-viewer.component';
export class BulletinsComponent {
    constructor(platform, fileOpener, transfer, file, talentJobFormatService, modalController) {
        this.platform = platform;
        this.fileOpener = fileOpener;
        this.transfer = transfer;
        this.file = file;
        this.talentJobFormatService = talentJobFormatService;
        this.modalController = modalController;
    }
    ngOnInit() { }
    openBulletin(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: BulletinViewerComponent,
                backdropDismiss: false,
                cssClass: 'wideModal',
                componentProps: {
                    url: data.type === 'video-url' ? data.url : data.secure_url,
                    title: data.name,
                    isVideo: data.type === 'video-url' ? true : false,
                    isAcknowledgmentRequired: false,
                    bulletinId: data._id,
                    entityId: this.entityId
                }
            });
            return yield myModal.present();
        });
    }
    openPdf(data) {
        if (!this.downloadInprogressPdfId) {
            console.log("opening pdf");
            const fileName = `ShiftBulletin-${data._id}.pdf`;
            const isBrowser = this.talentJobFormatService.isBrowserCheck();
            if ((this.platform.is('android') || this.platform.is('ios') || this.platform.is('ipad')) && !isBrowser) {
                this.downloadInprogressPdfId = data._id;
                const path = this.file.dataDirectory;
                const fileTransfer = this.transfer.create();
                fileTransfer.download(data.secure_url, path + fileName).then((entry) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.fileOpener.open(path + fileName, 'application/pdf');
                    this.downloadInprogressPdfId = null;
                })).catch((err) => {
                    console.log(err);
                    alert('Error saving file: ' + err.message);
                    this.downloadInprogressPdfId = null;
                });
            }
            else {
                window.open(data.secure_url, '_system');
            }
        }
    }
    dimissModal() {
        this.modalController.dismiss();
    }
}
