import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-shared-toggle-bar',
  templateUrl: './shared-toggle-bar.component.html',
  styleUrls: ['./shared-toggle-bar.component.scss'],
})
export class SharedToggleBarComponent implements OnInit, OnChanges {

    @Input() data;
    @Input() userType;
    @Output() toggleBar = new EventEmitter();
    @Input() paramValue;
    @Input() requestViewChange;
    @Input() recurrentView;
    @Input() availabilityView;
    @Input() isWeeklyEnabled;
    @Input() allView;
    leftText : string;
    allText: 'all';
    rightText : string;
    value: (boolean | string ) = 'all';
    constructor() { }
  
    ngOnInit() {

      if(this.userType !== 'client') {
        this.value = false;
      }
      
    }

  
    ngOnChanges() {
      this.allText = this.data.allText ? this.data.allText : 'all';
      this.leftText = this.data.leftText;
      this.rightText = this.data.rightText;

      if(this.requestViewChange){
        this.value = false;
      }

      if(this.recurrentView){
        this.value = true
      }

      if(this.allView){
        this.value = 'all'
      }

      //Initialize toggle button to active according to contractor's schedule
      if(this.availabilityView) {
        this.value = this.isWeeklyEnabled;
      }
    }

    
  
    onToggleChange(selectedValue) {
      if(this.userType !== 'client') {
        this.value = !this.value;
      } else {
        this.value = selectedValue;
      }
      
      this.toggleBar.emit(this.value)
    }
  }
