import { Injectable } from '@angular/core';
import { Calendar } from '@ionic-native/calendar/ngx';
import { Platform } from '@ionic/angular';
import { ITalentJob } from '../interfaces/talent-job.interface';
import * as moment from 'moment-timezone';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';

@Injectable({ providedIn: 'root' })
export class CalendarService {
  isBrowser: boolean;
  constructor(private calendar: Calendar, private platform: Platform, private talentJobFormatService: TalentJobFormatService) {
    this.isBrowser = this.talentJobFormatService.isBrowserCheck();
  }

  removeDeletedShiftsFromCalendar = async (shifts: ITalentJob[]) => {
    if (!shifts.length) {
      return;
    }

    for (const shift of shifts) {
      try {
        await this.calendar.deleteEvent(
          this.generateTitleForEvent(shift),
          shift.companyAddress.street,
          null,
          moment(shift.shiftStartTime, 'X').toDate(),
          moment(shift.shiftEndTime, 'X').toDate(),
        );
      } catch (err) {
        console.log('Error while deleting shift : ', err);
      }
    }
  };

  generateTitleForEvent = (shift: ITalentJob) => {
    return `Staffy Shift for ${shift.skill}`;
  };

  isDevice = () => {
    const isAndroid = this.platform.is('android');
    const isIos = this.platform.is('ios');
    const isIPad = this.platform.is('ipad');
    if ((isAndroid || isIos || isIPad) && !this.isBrowser) {
      return true;
    }
    return false;
  };

  checkCalendarPermission = async () => {
    const hasReadWritePermission = await this.calendar.hasReadWritePermission();
    if (!hasReadWritePermission) {
      await this.calendar.requestReadWritePermission();
    }
  };

  getNotes = (shift: ITalentJob) => {
    let data = ``;
    if (shift.shiftNotes && shift.shiftNotes.length) {
      data += `Shift Notes: ${shift.shiftNotes}\n`;
    }
    if (shift.unitNumber && shift.unitNumber.length) {
      data += `Unit Number: ${shift.unitNumber}\n`;
    }
    return data;
  };

  syncUpcomingShiftsInCalendar = async (upcomingShifts: ITalentJob[]) => {
    if (upcomingShifts.length === 0) {
      return;
    }
    try {
      console.log('Starting syncing event in calendar');

      // add shifts to calendar
      for (const shift of upcomingShifts) {
        const event = {
          title: this.generateTitleForEvent(shift),
          location: shift.companyAddress.street,
          notes: this.getNotes(shift),
          startDate: moment(shift.shiftStartTime, 'X').toDate(),
          endDate: moment(shift.shiftEndTime, 'X').toDate()
        };

        const calendarOptions = {
          id: shift._id
        };

        console.log('Event : ', event);

        // add the event to the calendar
        await this.calendar.createEventWithOptions(
          event.title,
          event.location,
          event.notes,
          event.startDate,
          event.endDate,
          calendarOptions
        );
        console.log('Synced Event in Calendar : ', event);
      }
    } catch (err) {
      console.log('err while syncing event in calendar', err);
      console.log(err);
    }
  };

  public async syncShifts(shiftsToDelete: ITalentJob[], upcomingShifts: ITalentJob[]) {
    if (!this.isDevice()) {
      return;
    }
    try {
      await this.checkCalendarPermission();
      await this.removeDeletedShiftsFromCalendar(shiftsToDelete);
      await this.removeDeletedShiftsFromCalendar(upcomingShifts);

      await this.syncUpcomingShiftsInCalendar(upcomingShifts);
    } catch (err) {
      console.log('Syncing error', err);
    }
  }
}
