/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./social-signup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/line-break.pipe";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "./social-signup.component";
import * as i7 from "@ionic-native/in-app-browser/ngx/index";
import * as i8 from "@angular/router";
var styles_SocialSignupComponent = [i0.styles];
var RenderType_SocialSignupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SocialSignupComponent, data: {} });
export { RenderType_SocialSignupComponent as RenderType_SocialSignupComponent };
function View_SocialSignupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.data.imgURL, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SocialSignupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "ov-auto para"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.data.message)); _ck(_v, 0, 0, currVal_0); }); }
export function View_SocialSignupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ReplaceLineBreaks, []), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "staffer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 21, "div", [["class", "ion-text-center child detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialSignupComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialSignupComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "buttons-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "ss-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fab fa-facebook-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "ion-button", [["class", " ion-no-margin btn success-popup-ok"], ["id", "success-popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.socialSiteNaviate("facebook") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(14, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Join"])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "ss-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fab fa-instagram"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "ion-button", [["class", " ion-no-margin btn success-popup-ok"], ["id", "success-popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.socialSiteNaviate("instagram") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(19, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Follow"])), (_l()(), i1.ɵeld(21, 0, null, null, 3, "div", [["class", "navigate-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "span", [["class", "available-shifts"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Check out available shifts"])), (_l()(), i1.ɵeld(24, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.imgURL; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.data.message; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_SocialSignupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-social-signup", [], null, null, null, View_SocialSignupComponent_0, RenderType_SocialSignupComponent)), i1.ɵdid(1, 114688, null, 0, i6.SocialSignupComponent, [i5.ModalController, i7.InAppBrowser, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SocialSignupComponentNgFactory = i1.ɵccf("app-social-signup", i6.SocialSignupComponent, View_SocialSignupComponent_Host_0, { data: "data" }, {}, []);
export { SocialSignupComponentNgFactory as SocialSignupComponentNgFactory };
