import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { JobService } from '../../services/job.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-contractor-shift-modified',
  templateUrl: './contractor-shift-modified.component.html',
  styleUrls: ['./contractor-shift-modified.component.scss'],
})
export class ContractorShiftModifiedComponent implements OnInit {

  @Input() groupJobId: string;
  @Input() shiftExtended : Boolean = false;

  shifts = []

  config = ["Date", "Start Time", "End Time", "Unpaid Break", "Transit Allowance"]

  description = "Your client has made the following changes to your shifts:";
  title= "This shift has been modified"


  constructor(private modalController: ModalController, private jobService: JobService, private loadingService: LoadingService) { }

  ngOnInit() {
    this.fetchRecurringShifts()
    if(this.shiftExtended){
      this.title = "This shift has been extended"
      this.description = "Your client has extended this shift on these dates:"
    }
  }

  closeModal(): void {
    this.modalController.dismiss();
  }

  async fetchRecurringShifts() {
    const loading = await this.loadingService.showLoading().toPromise();

    try {

      const { data:jobs } = await this.jobService.getRecurringShiftsV2({
        groupJobId: this.groupJobId,
        shiftStartTimeFrom: moment().format("X"),
        pageSize: 93,
        format:'simple'
      });

      if (jobs && jobs.length) {
        this.shifts = jobs.map(item => {
          return {
            Date: moment(item.shiftDate).tz(item.timeZone).format('DD MMM, YYYY'),
            'Start Time': moment(item.shiftStartTime, 'X').tz(item.timeZone).format('hh:mm A'),
            'End Time': moment(item.shiftEndTime, 'X').tz(item.timeZone).format('hh:mm A'),
            'Unpaid Break': item.break ? item.break.unpaid : "No break",
            'Transit Allowance': item.travelTime.toString(),
          }
        })
      }

    } catch (error) {
      console.log(error);
    } finally {
      await this.loadingService.hideLoading(loading);
    }

  }

}
