import { HttpPromiseService } from './http-promise.service';
import { Injectable } from '@angular/core';
import { IViewCert } from '../interfaces/certificate.interface';
import { IDeclaration } from 'src/modules/authentication/interfaces/declaration.interface';
@Injectable({ providedIn: 'root' })

export class AttestationAndDeclarationService {

    constructor(
        private httpPromiseService: HttpPromiseService
    ) {

    }
    public updateDocumentValidityDate(certificateId: string, requestBody: {stafferId: string, type?: string, issueDate?: number, expiryDate?: number}) {
        return this.httpPromiseService.httpPutRequest(
            `/api/certificates/updateDocumentValidityDate/${certificateId}`,
            requestBody
        );
    }

    public doesContractorHasVSCWithinXYears(userId: string, certificateType: string, period: string, duration: string): Promise<IViewCert[]> {
        return this.httpPromiseService.httpGetRequest(
            `/api/certificates/checkCertificateByExpiryDateRange/${userId}?type=${certificateType.toString()}&period=${period}&duration=${duration}`
        );
    }

    public updateDeclarationSignedDate(stafferId: string, data: {type: string, certificateType?: string, issueDate?: number, certificateId?: string}): Promise<IDeclaration[]> {

        return this.httpPromiseService.httpPostRequest(
            `/api/attestationsAndDeclarations/${stafferId}`, data
        );
    }

    public generateRequestLetter(stafferId: string, timeZone: string, skills: string[], type: string, city: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/attestationsAndDeclarations/vsc-request/${stafferId}`, {
                timeZone,
                skills,
                type,
                city
            }
        );
    }

    public fetchContractorVSCProperties(stafferId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/attestationsAndDeclarations/${stafferId}`
        );
    }
}

