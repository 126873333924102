<div class="alert-wrapper sc-ion-alert-md">
   <a class="close-btn"><i class="fa fa-times" (click)="onCancel()"></i></a>
   <ion-searchbar (ionClear)="handleClearSearch()" (ionChange)="handleSearchChange()" [(ngModel)]="searchItem"></ion-searchbar>
   <div class="options-list"> 
 
 
 <ion-list lines="none" >
   <ion-item *ngFor="let option of options">
       <ion-checkbox [checked]="isSelectedOption(option)" (ionChange)="handleCheckboxSelect($event.detail.checked,option)" class="select-checkbox" slot="start"></ion-checkbox>
       <ion-label class="option-text">{{option}}</ion-label>
   </ion-item>
 </ion-list>
 </div>
 
 
   <div class="alert-button-group sc-ion-alert-md">
       <button (click)="onCancel()" type="button" class="alert-button ion-focusable ion-activatable sc-ion-alert-md" tabindex="0">
          <span class="alert-button-inner sc-ion-alert-md">Cancel</span>
          <ion-ripple-effect class="sc-ion-alert-md md hydrated" role="presentation"></ion-ripple-effect>
       </button>
       <button (click)="onSelect(selectedOptions)" type="button" class="alert-button ion-focusable ion-activatable sc-ion-alert-md" tabindex="0">
          <span class="alert-button-inner sc-ion-alert-md">Select</span>
          <ion-ripple-effect class="sc-ion-alert-md md hydrated" role="presentation"></ion-ripple-effect>
       </button>
    </div>
 </div>
