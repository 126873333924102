/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-circular-checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "../form-errors/form-errors.component.ngfactory";
import * as i7 from "../form-errors/form-errors.component";
import * as i8 from "./form-circular-checkbox.component";
var styles_FormCircularCheckboxComponent = [i0.styles];
var RenderType_FormCircularCheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormCircularCheckboxComponent, data: {} });
export { RenderType_FormCircularCheckboxComponent as RenderType_FormCircularCheckboxComponent };
function View_FormCircularCheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "custom-checkbox"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "disable-buttons": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 5, "input", [["type", "checkbox"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_v.context.$implicit.checked = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.handleChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i3.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CheckboxControlValueAccessor]), i1.ɵdid(8, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(10, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "label", [], [[8, "htmlFor", 0], [1, "data-value", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "custom-checkbox"; var currVal_1 = _ck(_v, 4, 0, _co.disableList); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_10 = _co.disableList; var currVal_11 = _v.context.$implicit.checked; _ck(_v, 8, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.value, ""); var currVal_3 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 10).ngClassValid; var currVal_8 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_12 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.value, ""); var currVal_13 = _v.context.$implicit.label; _ck(_v, 11, 0, currVal_12, currVal_13); }); }
export function View_FormCircularCheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "reusable-form-component checkbox-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-label", [["class", "label"]], null, null, null, i4.View_IonLabel_0, i4.RenderType_IonLabel)), i1.ɵdid(2, 49152, null, 0, i5.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(3, 0, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "flex-row padding-top-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormCircularCheckboxComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-form-errors", [], null, null, null, i6.View_FormErrorsComponent_0, i6.RenderType_FormErrorsComponent)), i1.ɵdid(8, 114688, null, 0, i7.FormErrorsComponent, [], { control: [0, "control"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.options; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.control; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labelText; _ck(_v, 3, 0, currVal_0); }); }
export function View_FormCircularCheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-form-circular-checkbox", [], null, null, null, View_FormCircularCheckboxComponent_0, RenderType_FormCircularCheckboxComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.FormCircularCheckboxComponent]), i1.ɵdid(2, 114688, null, 0, i8.FormCircularCheckboxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FormCircularCheckboxComponentNgFactory = i1.ɵccf("app-form-circular-checkbox", i8.FormCircularCheckboxComponent, View_FormCircularCheckboxComponent_Host_0, { labelText: "labelText", options: "options", disableList: "disableList", control: "control" }, { onChange: "onChange" }, []);
export { FormCircularCheckboxComponentNgFactory as FormCircularCheckboxComponentNgFactory };
