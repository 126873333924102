import { ITalentJob } from '../interfaces/talent-job.interface';

export interface ITalentDashboardState {
    upcomingShifts: ITalentJob[];
    futureUpcomingShiftsCount: number;
    availableShift: any
}

export const talentDashboardstate: ITalentDashboardState = {
    upcomingShifts: [],
    futureUpcomingShiftsCount: 0,
    availableShift: null
};
