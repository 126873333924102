import { Injectable } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { IClientJob } from "src/modules/company-dashboard/interfaces/client-job.interface";
import { IShiftTime } from "src/modules/shared/interfaces/shift-time.interface";

@Injectable({ providedIn: 'root' })
export class RecurrentShiftService {

constructor() {}

unitNumberByShift(jobItem) {
    let unitNumber = ''; 

    const isRecurring = jobItem[0].recurringShift.isTrue;

    //If Shift is recurring fetch unit numbers from jobs array
    if(isRecurring) {
      //If any unit number exists in recurring job, return the unit number else return empty string
      const jobUnitNumber = jobItem.findIndex(o => o.unitNumber !== undefined);
    
      if(jobUnitNumber > -1) {
        unitNumber = jobItem[jobUnitNumber].unitNumber;
      }
    } else {
        unitNumber = jobItem[0].unitNumber || '';
    } 
    return unitNumber;
  }

  fetchInitalTimingIndex(recurrentTemplate, startTimeValues, endTimeValues) {

    const startTimeIndex = _.findIndex(startTimeValues, (startTime: {value: string, formatted: string}) => {
      return startTime.value === recurrentTemplate.startTime});
    
    
    const endTimeIndex = _.findIndex(startTimeValues, (endTime: {value: string, formatted: string}) => {
        return endTime.value === recurrentTemplate.endTime});

    return [startTimeIndex, endTimeIndex];
  }


  updateTimingDropdownIndexByDate(timeValues: IShiftTime[], recurrentForm, tz: string, dateString: string, key: string): number {
    const index = timeValues.findIndex((i) => {
      return i.formatted === recurrentForm.get('recurrentShiftTemplate').get(key).value.formatted
      && moment.unix(i.value).tz(tz).format('MM/DD/YYYY') === moment(dateString, 'MM/DD/YYYY').tz(tz).format('MM/DD/YYYY');
    });

    return index;
  }

  fetchTimingIndex(timeValues: IShiftTime[], recurrentForm,key: string): number {
    const index = timeValues.findIndex((i) => {
      return i.formatted === recurrentForm.get(key).value.formatted
    });

    return index;
  }

  fetchUnpaidBreakIndex(unpaidBreakOptions: Array<{
    value: string,
    label: string
  }>, recurrentForm,key: string): number {
    const index = unpaidBreakOptions.findIndex((i) => {
      return i.value === recurrentForm.get(key).value.value
    });

    return index;
  }

    fetchTravelAllowanceIndex(travelAllowanceOptions: Array<{
    value: string,
    label: string
  }>, recurrentForm,key: string): number {
    const index = travelAllowanceOptions.findIndex((i) => {
      return i.value === recurrentForm.get(key).value.value
    });

    return index;
  }
}
