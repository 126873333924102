import * as i0 from "@angular/core";
export class NotificationBadgesService {
    constructor() { }
    showNotificationTypeBadge(notification) {
        if (notification.stafferConfirmedShift
            && notification.type != 'job completed'
            && notification.body != 'Still Working ?'
            && notification.type !== 'confirm hours'
            && notification.type != 'broadcast request'
            && !(notification.body.indexOf('responded by') > -1 && (notification.type == 'broadcast request' && notification.user && notification.job && notification.quotaFilled && notification.user.type != 'staffer') == false)
            && notification.type != 'confirm shift'
            && notification.type != 'admin')
            return true;
        else
            return false;
    }
    showJobCompletedBadge(notification) {
        if (notification.stafferHired
            && notification.jobState == 'complete')
            return true;
        else
            return false;
    }
    showFileDisputeBadge(notification) {
        if (notification.type == 'job completed'
            && notification.user
            && notification.job
            && notification.user.type != 'staffer'
            && notification.filedDispute)
            return true;
        else
            return false;
    }
    showShiftFilledBadge(notification) {
        if (notification.creator && notification.user && notification.job) {
            if ((notification.type == 'broadcast request' && !notification.stafferHired && notification.quotaFilled) ||
                (notification.type == 'recurring broadcast offer' && !notification.isRecurringIsStafferHired && notification.isRecurringIsQuotaFilled))
                return true;
        }
        return false;
    }
    showHiredBadge(notification) {
        if (notification.creator
            && notification.user
            && notification.job) {
            if ((notification.type == 'broadcast request' && notification.stafferHired && notification.jobState != 'complete')
                || (notification.type == 'recurring broadcast offer' && notification.isRecurringIsStafferHired && notification.recurringJobState != 'complete'))
                return true;
        }
        return false;
    }
    showRejectedBadge(notification) {
        if (notification.creator && notification.user && notification.job) {
            if ((notification.type == 'broadcast request' && notification.jobState == 'rejected') || (notification.type == 'recurring broadcast offer' && notification.recurringJobState == 'rejected'))
                return true;
        }
        return false;
    }
    showAwaitingResponseBadge(notification) {
        if (notification.creator && notification.user && notification.job) {
            if ((notification.type == 'broadcast request' && notification.isAlreadyApplied && !notification.isRejected && !notification.stafferHired) ||
                (notification.type == 'recurring broadcast offer' && notification.isRecurringAlreadyApplied && !notification.isRecurringRejected && !notification.isRecurringIsStafferHired && !notification.isRecurringIsQuotaFilled))
                return true;
        }
        return false;
    }
    showStafferHiredBadge(notification) {
        if (notification.user && notification.job && notification.user.type != 'staffer') {
            if ((notification.type == 'broadcast request' && notification.quotaFilled && notification.stafferHired) ||
                (notification.type == 'recurring broadcast offer' && notification.isRecurringIsQuotaFilled && notification.isRecurringIsStafferHired))
                return true;
        }
        return false;
    }
    showStafferIgnoredBadge(notification) {
        if (notification.user && notification.job && notification.user.type != 'staffer')
            if ((notification.type == 'broadcast request' && notification.quotaFilled && !notification.stafferHired) ||
                (notification.type == 'recurring broadcast offer' && !notification.isRecurringIsStafferHired))
                return true;
        return false;
    }
    showRatingBadge(notification) {
        if (notification.body.indexOf('is responded by') > -1
            && (notification.type == 'broadcast request' && notification.user && notification.job && notification.quotaFilled && notification.user.type != 'staffer') == false)
            return true;
        else
            return false;
    }
    showIsABroadcastRequestBadge(notification) {
        if (notification.job && notification.job.isABroadcastRequest.isTrue)
            return true;
        else
            return false;
    }
    showApplyButton(notification) {
        if (notification.job
            && notification.job.isABroadcastRequest.isTrue
            && !(!notification.stafferHired && notification.quotaFilled))
            return true;
        else
            return false;
    }
    showVerifiedHoursBadge(notification) {
        if (notification.type == 'confirm hours' && notification.hoursUnConfimred)
            return true;
        else
            return false;
    }
    showTrackerWorkingBadge(notification) {
        if (notification.trackerIsStillWorking && notification.body == 'Still Working ?')
            return true;
        else
            return false;
    }
    showAlreadyAppliedBadge(notification) {
        if (notification.isAlreadyApplied && !notification.isRejected && !notification.stafferHired)
            return true;
        else
            return false;
    }
    showAlreadyHiredBadge(notification) {
        if (notification.body.indexOf('is responded by') > -1 && (notification.type == 'broadcast request' && notification.user && notification.job && notification.quotaFilled && notification.user.type != 'staffer') == false)
            return true;
        else
            return false;
    }
    showViewCertificatesBadge(notification) {
        if (notification.key == 'shift_hire_accepted' && notification.type == 'job Accepted')
            return true;
        else
            return false;
    }
    showAvailableShiftsBadge(notification) {
        if (notification.type == 'broadcast request'
            && notification.creator
            && notification.user
            && notification.job
            && !notification.stafferHired
            && notification.quotaFilled)
            return true;
        else
            return false;
    }
    showlogFileDisputeBadge(notification) {
        if (notification.type == 'job completed'
            && notification.user
            && notification.job
            && notification.user.type != 'staffer'
            && !notification.filedDispute
            && notification.isDisputeButtonCondition)
            return true;
        else
            return false;
    }
    showLogRatingBadge(notification) {
        if (notification.type == 'job completed'
            && notification.user
            && notification.job
            && notification.user.type != 'staffer'
            && notification.type == 'job completed'
            && notification.jobActive)
            return true;
        else
            return false;
    }
    recurringJobOffer(notification) {
        if ((notification.type == 'recurring broadcast offer' || notification.type == 'recurring shift offer') && notification.isRecurringIsAJobOffer)
            return true;
        return false;
    }
    showApplyRecurringButton(notification) {
        if (notification.type == 'recurring broadcast offer' && !(!notification.isRecurringIsStafferHired && notification.isRecurringIsQuotaFilled))
            return true;
        return false;
    }
    showAlreadyAppliedOnRecurring(notification) {
        if ((notification.type == 'recurring broadcast offer' || notification.type == 'recurring shift offer') && !notification.isRecurringIsStafferHired && !notification.isRecurringRejected
            && notification.isRecurringAlreadyApplied && !notification.isRecurringIsQuotaFilled)
            return true;
        return false;
    }
    recurringJobCheck(notification, type) {
        if (notification.body.indexOf('is responded by') > -1 && (notification.type == 'broadcast request' && notification.user && notification.job && notification.isRecurringIsQuotaFilled && type != 'staffer') == false)
            return true;
        return false;
    }
    canViewCertificates(notification) {
        if (notification.key == 'shift_hire_accepted' && notification.type == 'job Accepted')
            return true;
        return false;
    }
    isRecurringBroadcast(notification) {
        if (notification.type == 'recurring broadcast offer' && notification.creator && notification.user && notification.job)
            return true;
        return false;
    }
    seeAvailableShifts(notification) {
        if (!notification.isRecurringIsStafferHired && notification.isRecurringIsQuotaFilled)
            return true;
        return false;
    }
    jobCompletedForEmployer(notification, type) {
        if (notification.type == 'job completed' && notification.user && notification.job && type != 'staffer')
            return true;
        return false;
    }
    canRateShift(notification) {
        if (notification.type == 'job completed' && notification.jobActive)
            return true;
        return false;
    }
}
NotificationBadgesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationBadgesService_Factory() { return new NotificationBadgesService(); }, token: NotificationBadgesService, providedIn: "root" });
