<div class="container">
  <h1 class="header">{{title}}</h1>
  <div class="icon-cancel"><i (click)="closeModal()" class="fa fa-times"></i></div>

  <div  class="ack-title" *ngIf = "isAcknowledgmentRequired&&!isVideo">
    Please read and acknowledge this document before applying to the shift.
  </div>
  <div class="ack-title" *ngIf = "isAcknowledgmentRequired&&isVideo">
    Please watch and acknowledge this video before applying to the shift.
  </div>

  <div *ngIf="!isTemplate" class="acknowledgment-content">
  <app-video-player class="video-container" *ngIf = "isVideo" [url]="url" ></app-video-player>
  <app-pdf-viewer *ngIf = "!isVideo" [url]="url"></app-pdf-viewer>
  </div>

  <div *ngIf="isTemplate" class="acknowledgment-content">
      <object class="doc-container" [data]="url | embedDOCFile">
          <embed [src]="url | embedDOCFile" />
      </object>
    </div>

  <div  *ngIf="isAcknowledgmentRequired" class="acknowledgment-txt">
    <div class="bulletin-checkbox">
      <input type="checkbox" [(ngModel)]="acknowedgment" class="custom-checkbox" value=true>
    </div>
    <div class="ack-disclaimer" *ngIf = "!isVideo">
      I acknowledge that I have read and understand this document
    </div>
    <div class="ack-disclaimer" *ngIf = "isVideo">
      I acknowledge that I have watched and understood this video.
    </div>
  </div>
  <div *ngIf="isAcknowledgmentRequired" class="buttons-section">
    <ion-button [disabled]="!acknowedgment" color="basic" id="success-popup-btn" class=" ion-no-margin btn success-popup-ok"
      (click)='acknowledgeDocument()'>
      Complete
    </ion-button>
  </div>
</div>
