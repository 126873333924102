import { createReducer, Action, on } from '@ngrx/store';
import * as talentDashboardActions from '../actions/talent-dashboard.actions';

import { ITalentDashboardState, talentDashboardstate } from '../talent-dashboard.state';
const talentDashboardReducers = createReducer(
    talentDashboardstate,
    on(talentDashboardActions.setTalentDashboardStoreAction, (state, { upcomingShifts }) => ({
        ...state,
        upcomingShifts
    })),

    on(talentDashboardActions.setFutureShiftsCountStoreAction, (state, { futureUpcomingShiftsCount }) => ({
        ...state,
        futureUpcomingShiftsCount
    })),

    on(talentDashboardActions.resetTalentDashboardStoreAction, (state, ) => ({
        ...state,
        jobs: null,
        futureUpcomingShiftsCount: null
    })),
    on(talentDashboardActions.completeTaskSuccessAction, (state, { jobId, taskId }) => ({
        ...state,
        upcomingShifts : state.upcomingShifts.map(item=> item._id === jobId ? ({
            ...item,
            homecareShift: {
                ...item.homecareShift,
                tasks: item.homecareShift.tasks.map(task=> task.id === taskId ? { ...task, isCompleted: true } : task )
            }
        }): item )
    })),
    on(talentDashboardActions.saveCareNotesSuccessAction, (state, { jobId, careNotes }) => ({
        ...state,
        upcomingShifts : state.upcomingShifts.map(item=> item._id === jobId ? ({
            ...item,
            homecareShift: {
                ...item.homecareShift,
                careNotes
            }
        }): item )
    })),
    on(talentDashboardActions.rejectNonRecurringSuccessAction, (state, { jobId, stafferId }) => ({
        ...state,
        upcomingShifts : state.upcomingShifts.map(item=> item._id === jobId ? ({
            ...item,
            staffersAndShifts : item.staffersAndShifts.map( staffers => staffers.staffer === stafferId ? {
                ...staffers,
                state: 'rejected'
            }: staffers)
        }): item )
    })),

    on(talentDashboardActions.rejectRecurringSuccessAction, (state, { groupJobId, stafferId }) => ({
        ...state,
        upcomingShifts : state.upcomingShifts.map(item=> item.recurringShift.groupJobId === groupJobId ? ({
            ...item,
            staffersAndShifts : item.staffersAndShifts.map( staffers => staffers.staffer === stafferId ? {
                ...staffers,
                state: 'rejected'
            }: staffers)
        }): item )
    }),
))

export function talentDashboardReducer(state: ITalentDashboardState | undefined, action: Action) {
    return talentDashboardReducers(state, action);
}