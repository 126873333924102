/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recurrent-shift-list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@ionic/angular";
import * as i5 from "./recurrent-shift-list-item.component";
import * as i6 from "../../../company-dashboard/services/broadcast-job-utils.service";
import * as i7 from "../../../talent-dashboard/services/recurrentShift.service";
var styles_RecurrentShiftListItemComponent = [i0.styles];
var RenderType_RecurrentShiftListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecurrentShiftListItemComponent, data: {} });
export { RenderType_RecurrentShiftListItemComponent as RenderType_RecurrentShiftListItemComponent };
function View_RecurrentShiftListItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-col", [["class", "recurrent-data"], ["size", "2"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "date-item": 0 }), i1.ɵdid(4, 49152, null, 0, i4.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵted(5, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = "recurrent-data"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit == "Date")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "2"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.data[_v.context.$implicit]; _ck(_v, 5, 0, currVal_3); }); }
function View_RecurrentShiftListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecurrentShiftListItemComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RecurrentShiftListItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecurrentShiftListItemComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RecurrentShiftListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recurrent-shift-list-item", [], null, null, null, View_RecurrentShiftListItemComponent_0, RenderType_RecurrentShiftListItemComponent)), i1.ɵdid(1, 114688, null, 0, i5.RecurrentShiftListItemComponent, [i1.ChangeDetectorRef, i6.BroadcastJobsUtilService, i7.RecurrentShiftService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecurrentShiftListItemComponentNgFactory = i1.ɵccf("app-recurrent-shift-list-item", i5.RecurrentShiftListItemComponent, View_RecurrentShiftListItemComponent_Host_0, { config: "config", data: "data" }, {}, []);
export { RecurrentShiftListItemComponentNgFactory as RecurrentShiftListItemComponentNgFactory };
