// import { AngularFireAnalytics } from '@angular/fire/analytics';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/firebase-analytics/ngx/index";
import * as i2 from "@ionic/angular";
export class AnalyticsService {
    constructor(firebaseAnalytics, 
    // private angularFireAnalytics: AngularFireAnalytics,
    platform) {
        this.firebaseAnalytics = firebaseAnalytics;
        this.platform = platform;
        this.params = {
            category: '',
            action: '',
            label: '',
            user_industry: ''
        };
    }
    setUserId(id) {
        this.firebaseAnalytics.setUserId(id);
    }
    setUserProperty(name, value) {
        this.firebaseAnalytics.setUserProperty(name, value);
    }
    trackEvent(eventName, category, action, label, userIndustry) {
        this.params.category = category;
        this.params.action = action;
        this.params.label = label;
        this.params.user_industry = userIndustry;
        // assign ionic native firebase analaytics for android and ios
        // when running on the web browser use angular fire
        // const fireBaseAnalyticsService = this.pluginSupportedByDevice() ? this.firebaseAnalytics : this.angularFireAnalytics;
        if (this.pluginSupportedByDevice()) {
            this.firebaseAnalytics
                .logEvent(eventName, this.params)
                .then((res) => {
                console.log(res);
            })
                .catch((error) => {
                console.log('not supported');
            });
        }
    }
    pluginSupportedByDevice() {
        const isIOS = this.platform.is('ios');
        const isAndroid = this.platform.is('android');
        const isIPad = this.platform.is('ipad');
        return isIOS || isAndroid || isIPad;
    }
}
AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.ɵɵinject(i1.FirebaseAnalytics), i0.ɵɵinject(i2.Platform)); }, token: AnalyticsService, providedIn: "root" });
