/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-update-progress.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./app-update-progress.component";
var styles_AppUpdateProgressComponent = [i0.styles];
var RenderType_AppUpdateProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppUpdateProgressComponent, data: {} });
export { RenderType_AppUpdateProgressComponent as RenderType_AppUpdateProgressComponent };
export function View_AppUpdateProgressComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "ion-justify-content-center ion-text-center child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/images/download-dpi.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Downloading updates"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "ion-text-center ov-auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["A new version of the app is available. Please wait while the updates are being downloaded."])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [["class", "ion-text-center ov-auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["Download progress ", "%"])), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 1, "ion-progress-bar", [["color", "danger"]], null, null, null, i3.View_IonProgressBar_0, i3.RenderType_IonProgressBar)), i1.ɵdid(11, 49152, null, 0, i4.IonProgressBar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], value: [1, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "danger"; var currVal_2 = _co.progressValue; _ck(_v, 11, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), (_co.progressValue * 100), "1.0-0")); _ck(_v, 8, 0, currVal_0); }); }
export function View_AppUpdateProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-app-update-progress", [], null, null, null, View_AppUpdateProgressComponent_0, RenderType_AppUpdateProgressComponent)), i1.ɵdid(1, 114688, null, 0, i5.AppUpdateProgressComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppUpdateProgressComponentNgFactory = i1.ɵccf("app-app-update-progress", i5.AppUpdateProgressComponent, View_AppUpdateProgressComponent_Host_0, { progress: "progress" }, {}, []);
export { AppUpdateProgressComponentNgFactory as AppUpdateProgressComponentNgFactory };
