// This Service is used for HTTP calls.
import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
/*************************** End ****************************/
/***************** Import Services *******************/
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';
/*************************** End ****************************/
/************** Import API Base URL **************/
/*************************** End ****************************/
export class HttpPromiseService {
    constructor(_http, localStorageService) {
        this._http = _http;
        this.localStorageService = localStorageService;
        // error return from promise
        this.responseError = (error) => {
            throw error;
        };
    }
    // convert response data return from promise (API body data) in to JSON
    extractData(res) {
        return res || [];
    }
    // getTokenAppendHeader() -----> get token from local storage and append it in HTTP req headers.
    // private async getTokenAppendHeader() {
    //   let headers = new HttpHeaders();
    //   const authToken = await this.localStorageService.getLoggedInUserToken();
    //   headers = headers.set('Content-Type', 'application/json');
    //   headers = headers.set('Authorization', `Bearer ${authToken}`);
    //   return headers;
    // }
    getTokenAppendHeader() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let headers = new HttpHeaders();
            const authToken = yield this.localStorageService.getLoggedInUserToken();
            // headers = headers.set('Content-Type', contentType);
            headers = headers.set('Authorization', `Bearer ${authToken}`);
            return headers;
        });
    }
    makeHttpPostRequest(baseURL, url, data, responseType = 'json') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getTokenAppendHeader();
            const requestURL = `${baseURL}${url}`;
            return this._http
                .post(requestURL, data, { headers, responseType })
                .toPromise()
                .then(this.extractData, this.responseError);
        });
    }
    // HTTP POST request without headers content-type
    httpPostRequest(url, data, responseType = 'json') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.makeHttpPostRequest(environment.API_BASE_URL, url, data, responseType);
        });
    }
    // HTTP POST request without headers content-type
    httpPostRequestForCompany(url, data, responseType = 'json') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.makeHttpPostRequest(environment.API_BASE_URL_COMPANIES, url, data, responseType);
        });
    }
    makeHttpGetRequest(baseURL, url, responseType = 'json') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getTokenAppendHeader();
            const requestURL = `${baseURL}${url}`;
            return this._http
                .get(requestURL, { headers, responseType })
                .toPromise()
                .then(res => res || [], this.responseError);
        });
    }
    // HTTP GET request
    httpGetRequest(url, responseType = 'json') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.makeHttpGetRequest(environment.API_BASE_URL, url, responseType);
        });
    }
    // HTTP GET request
    httpGetRequestForCompany(url, responseType = 'json') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.makeHttpGetRequest(environment.API_BASE_URL_COMPANIES, url, responseType);
        });
    }
    makeHttpPutRequest(baseURL, url, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getTokenAppendHeader();
            const requestURL = `${baseURL}${url}`;
            return this._http
                .put(requestURL, data, { headers })
                .toPromise()
                .then(this.extractData, this.responseError);
        });
    }
    // HTTP PATCH request
    httpPutRequest(url, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.makeHttpPutRequest(environment.API_BASE_URL, url, data);
        });
    }
    // HTTP PATCH request
    httpPutRequestForCompany(url, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.makeHttpPutRequest(environment.API_BASE_URL_COMPANIES, url, data);
        });
    }
    // HTTP DELETE request
    httpDeleteRequest(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getTokenAppendHeader();
            const requestURL = `${environment.API_BASE_URL}${url}`;
            return this._http
                .delete(requestURL, { headers })
                .toPromise()
                .then(this.extractData, this.responseError);
        });
    }
    // HTTP DELETE request
    httpDeleteRequestForCompany(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getTokenAppendHeader();
            const requestURL = `${environment.API_BASE_URL_COMPANIES}${url}`;
            return this._http
                .delete(requestURL, { headers })
                .toPromise()
                .then(this.extractData, this.responseError);
        });
    }
    httpPatchRequest(url, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getTokenAppendHeader();
            const requestURL = `${environment.API_BASE_URL}${url}`;
            return this._http
                .patch(requestURL, data, { headers })
                .toPromise()
                .then(this.extractData, this.responseError);
        });
    }
}
