import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { getUserInfoAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { LoadingService } from '../../services/loading.service';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-service-fee-update-notice',
  templateUrl: './service-fee-update-notice.component.html',
  styleUrls: ['./service-fee-update-notice.component.scss'],
})
export class ServiceFeeUpdateNoticeComponent implements OnInit {
  @Input() staffer: IUser

  notices = {
    title: 'Important Update',
    shortNotice: `From August 01, 2024, a 3% fee will be deducted from your payout for completed shifts to help maintain and improve the app. There is no charge to download the app or view available shifts. Thank you for your understanding and continued support!`,
  };

  constructor(
    private modalController: ModalController,
    private router: Router,
    private userService: UserService,
    private loadingService: LoadingService,
    @Inject(forwardRef(() => PopupService)) private popupService,
    private authStore: Store<IAuthState>,
  ) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.modalController.dismiss();
  }

  async acceptDoc() {
    const loading = await this.loadingService.showLoading().toPromise();

    try {

      const acknowledgeDate =  moment().unix()
      await this.userService.updateUserPromise(this.staffer._id, {
        platformServiceFeeAcknowledgementDate :  acknowledgeDate,
        isPlatformServiceFeeAcknowledged: true
      })
      this.authStore.dispatch(getUserInfoAction());
      this.staffer.platformServiceFeeAcknowledgementDate = acknowledgeDate
      this.staffer.isPlatformServiceFeeAcknowledged = true

      this.popupService.showModal(
        {
          heading: 'Success!',
          message: ' ',
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/thumbs-up.png'
        })

      this.modalController.dismiss();


    } catch (e) {
      this.popupService.showModal(
        {
          heading: 'Error',
          message: 'Something went wrong, Please try again later',
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/profile.png'
        })
    }
    finally {
      this.loadingService.hideLoading(loading);
    }
  }



}
