import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { updateUserPartialStateSuccess } from 'src/modules/authentication/+store/actions/auth.actions';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { LoadingService } from '../../services/loading.service';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-verify-email-popup',
  templateUrl: './verify-email-popup.component.html',
  styleUrls: ['./verify-email-popup.component.scss'],
})
export class VerifyEmailPopupComponent implements OnInit {

  @Input() email: string;
  @Input() allowClose: boolean;
  @Input() isUserAuthenticated?: boolean;
  @Input() doSignupWithOtp?: Function;

  @ViewChildren('otp') otpInputs: QueryList<IonInput>;

  timer;
  timerIntervalId;
  loader;
  otpCode = '';

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private popupService: PopupService,
    private store: Store<IAuthState>,
    private loadingService: LoadingService
  ) { }

  async ngOnInit() {
    await this.sendCode();
  }

  ngOnDestroy(): void {
    if (this.timerIntervalId) {
      clearInterval(this.timerIntervalId);
    }
  }


  async sendCode(): Promise<void> {
    this.setTimer(60);
    if (this.isUserAuthenticated) {
      await this.userService.sendCodeToVerifyEmail(this.email);
    }else{
      await this.userService.sendCodeToVerifyEmailSignup(this.email);
    }
  }

  setTimer(timerInSeconds: number): void {
    this.timer = timerInSeconds;
    this.timerIntervalId = setInterval(() => {
      if (this.timer <= 0) {
        clearInterval(this.timerIntervalId);
      }
      this.timer = this.timer - 1;
    }, 1000);
  }

  clearTimer(): void {
    if (this.timerIntervalId) {
      clearInterval(this.timerIntervalId);
    }
    this.timer = 0;
  }

  async close(): Promise<void> {
    await this.modalController.dismiss();
  }

  otpController(event, i) {
    const inputArray = this.otpInputs.toArray();
    const inputValue = event.target.value.trim();
    if ((!inputValue && !['Backspace', 'Delete'].includes(event.key)) || (inputValue && isNaN(inputValue))) {
      inputArray[i].value = ''
      return;
    }
    const nextElement = inputArray[i+1] || null;
    const previousElement = inputArray[i-1] || null;
    this.otpCode = inputArray.map(input => input.value).join('');
    if (!inputValue && previousElement) {
      previousElement.setFocus();
    } else if (nextElement && inputValue) {
      nextElement.setFocus();
    }
  }

  async submitCode(): Promise<void> {
    const otp = this.otpCode;
    try {
      if(this.doSignupWithOtp) {
        this.doSignupWithOtp(otp);
      } else {
        this.loader = await this.loadingService.showLoading().toPromise();
        const response = await this.userService.setupETransferWithCode(otp);

        if (response.message === 'verified') {

          // UPDATE STORE
          this.store.dispatch(updateUserPartialStateSuccess({
            partialUserState: {
              paymentMethod: 'e-transfer',
              berkeleyFinancialAccount: {
                id: '',
                email: this.email
              } 
            }
          }));

          // SHOW CONFIRMATION POPUP
          this.popupService.showModal({
            heading: 'Success',
            message: 'Your email has been verified and e-Transfer setup successfully.',
            btn: 'Dismiss',
            imgURL: '',
            navigateRoute: '',
          });

          this.close();

        }

      }
    } catch(error) {

      this.popupService.showModal({
        heading: 'Error',
        message: error.error.message || 'Something went wrong, please try again.',
        btn: 'Dismiss',
        imgURL: '',
        navigateRoute: '',
      });

    } finally {

      await this.loadingService.hideLoading(this.loader);

    }

  }

}
