<div class="shift-details-modal">
  <div class="close-action-container">
    <i class="fa fa-times close-icon" (click)="closeModal()"></i>
  </div>
  <div class="details-container">

    <div class="shift-info">
      <div class="image-section">
        <img class="profile-pic" src="{{profilePicture}}">
      </div>

      <p class="skill">{{ ('SKILLS.' + jobItem.skill) | translate }}</p>

      <div class="shift-figures">
        <span class="rate">${{ formattedJobItem?.formattedRate.toFixed(2) }} Hourly</span>
        <span class="timing">${{ formattedJobItem?.potentialEarning.toFixed(2) }} - {{ formattedJobItem?.durationRequired
          }}</span>
      </div>

      <span class = "general-book sub-skill" *ngIf="jobItem.subSkills && jobItem.subSkills.length">
        <span class = "mont-bold-font display-content">Subskill Required: </span>{{ jobItem.subSkills.join(", ") }}
      </span>

      <div class="timing">
        <div class="shift-date">
          <i class="fa fa-calendar"></i>
          <span *ngIf="jobItem.recurringShift.isTrue == true">
            {{formattedJobItem.dateRange || formattedJobItem.shiftStartTimeFormatted}}
          </span>
          <span *ngIf="jobItem.recurringShift.isTrue == false">
            {{formattedJobItem.shiftStartTimeFormatted}}
          </span>
        </div>
        <div class="shift-time">
          <i class="fa fa-clock"></i>
          <span *ngIf="formattedJobItem?.similarTimings">
            {{formattedJobItem.startTime}} - {{formattedJobItem.endTime}}
          </span>
        </div>
      </div>

      <!-- <ng-template [ngIf]="jobItem?.homecareShift?.alayacareShift?.isAcceptedByClient">
        <p class="patient-name">{{ 'SHIFT.PATIENT' | translate }}:
          <b>{{jobItem?.homecareShift?.alayacareShift?.client?.demographics?.firstName}}
            {{jobItem?.homecareShift?.alayacareShift?.client?.demographics?.lastName}}</b>
        </p>
        <div class="patient-details">
          <div class="item" *ngIf="jobItem?.homecareShift?.alayacareShift?.client?.demographics?.phone">
            <i class="fa fa-phone icon"></i>
            {{jobItem?.homecareShift?.alayacareShift?.client?.demographics?.phone}}
          </div>
          <div class="item" *ngIf="jobItem?.homecareShift?.alayacareShift?.client?.demographics?.email">
            <i class="fa fa-envelope icon"></i>
            {{jobItem?.homecareShift?.alayacareShift?.client?.demographics?.email}}
          </div>
          <div class="item text-capitalize" *ngIf="jobItem?.homecareShift?.alayacareShift?.client?.demographics?.language">
            <i class="fa fa-language icon"></i>
            {{jobItem?.homecareShift?.alayacareShift?.client?.demographics?.language}}
          </div>

          <div class="item text-capitalize" *ngIf="jobItem?.alayacareShift?.client?.demographics?.gender">
            <i class="fa fa-male icon"></i>
            {{jobItem?.homecareShift?.alayacareShift?.client?.demographics?.gender}}
          </div>

          <div class="item" *ngIf="jobItem?.alayacareShift?.client?.demographics?.dateOfBirth">
            <i class="fa fa-birthday-cake icon"></i>
            {{getAge(jobItem?.homecareShift?.alayacareShift?.client?.demographics?.dateOfBirth)}} Years old
          </div>
        </div>
      </ng-template> -->
    </div>

    <div class="shift-details">
      <div class="item">
        <div class="header" (click)="toggleDropdown('shiftDescription')">
          <i class="fas fa-file-alt icon"></i>
          <span>
            shift description
          </span>
          <i class="fa fa-chevron-down dropdown-icon" *ngIf="!showDropdownDetails.shiftDescription"></i>
          <i class="fa fa-chevron-up dropdown-icon" *ngIf="showDropdownDetails.shiftDescription"></i>
        </div>
        <div [innerHTML]="jobItem.shiftNotes | sanitizeHtml" class="description" *ngIf="showDropdownDetails.shiftDescription">
        </div>
      </div>

      <!-- <div class="item" *ngIf="jobItem?.alayacareShift?.client?.contacts?.length">
        <div class="header" (click)="toggleDropdown('contacts')">
          <i class="fa fa-address-card icon"></i>
          <span>
            contacts
          </span>
          <i class="fa fa-chevron-down dropdown-icon" *ngIf="!showDropdownDetails.contacts"></i>
          <i class="fa fa-chevron-up dropdown-icon" *ngIf="showDropdownDetails.contacts"></i>
        </div>
        <div class="description contact" *ngIf="showDropdownDetails.contacts">
          <div *ngFor="let contact of jobItem.alayacareShift.client.contacts">
            <p class="title">{{contact.firstName}} {{contact.lastName	}}</p>
            <div class="details">
              <div class="details-item">
                <i class="fa fa-phone icon"></i>
                {{contact.phone}}
              </div>

              <div class="details-item text-capitalize">
                <i class="fas fa-map-marker-alt icon"></i>
                {{contact.address?.address}}
              </div>

              <div class="details-item">
                <i class="fa fa-envelope icon"></i>
                {{contact.email}}
              </div>

              <div class="details-item text-capitalize">
                <i class="fa fa-language icon"></i>
                {{contact.language}}
              </div>

              <div class="details-item text-capitalize">
                <i class="fas fa-handshake icon"></i>
                {{contact.relationship}}
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="item" *ngIf="jobItem?.alayacareShift?.client?.allergies?.length">
        <div class="header" (click)="toggleDropdown('allergies')">
          <i class="fas fa-hand-paper icon"></i>
          <span>
            allergies
          </span>
          <i class="fa fa-chevron-down dropdown-icon" *ngIf="!showDropdownDetails.allergies"></i>
          <i class="fa fa-chevron-up dropdown-icon" *ngIf="showDropdownDetails.allergies"></i>
        </div>
        <div class="description" *ngIf="showDropdownDetails.allergies">
          <div *ngFor="let allergy of jobItem.alayacareShift.client.allergies">
            <p class="title text-capitalize">{{allergy.name}}</p>
            <div class="details">
              <div class="details-item text-capitalize">
                <i class="fas fa-search icon"></i>
                {{allergy.category}}
              </div>

              <div class="details-item text-capitalize">
                <i class="fas fa-temperature-low icon"></i>
                {{allergy.severity}}
              </div>

              <div class="details-item text-capitalize">
                <i class="fas fa-calendar icon"></i>
                since: {{allergy.startDate}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="item" *ngIf="jobItem?.alayacareShift?.client?.risks?.length">
        <div class="header" (click)="toggleDropdown('risks')">
          <i class="fa fa-asterisk icon"></i>
          <span>
            risks
          </span>
          <i class="fa fa-chevron-down dropdown-icon" *ngIf="!showDropdownDetails.risks"></i>
          <i class="fa fa-chevron-up dropdown-icon" *ngIf="showDropdownDetails.risks"></i>
        </div>
        <div class="description" *ngIf="showDropdownDetails.risks">
          <div *ngFor="let risk of jobItem.alayacareShift.client.risks">
            <p class="title text-capitalize">{{risk.name}}</p>
            <div class="details">
              <div class="details-item text-capitalize">
                <i class="fas fa-search icon"></i>
                {{risk.category}}
              </div>

              <div class="details-item text-capitalize">
                <i class="fas fa-temperature-low icon"></i>
                {{risk.severity}}
              </div>

              <div class="details-item text-capitalize">
                <i class="fas fa-calendar icon"></i>
                since: {{risk.startDate}}
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="item" *ngIf="jobItem?.alayacareShift?.client?.diagnoses?.length">
        <div class="header" (click)="toggleDropdown('diagnoses')">
          <i class="fa fa-stethoscope icon"></i>
          <span>
            diagnosis
          </span>
          <i class="fa fa-chevron-down dropdown-icon" *ngIf="!showDropdownDetails.diagnoses"></i>
          <i class="fa fa-chevron-up dropdown-icon" *ngIf="showDropdownDetails.diagnoses"></i>
        </div>
        <div class="description" *ngIf="showDropdownDetails.diagnoses">
          <div *ngFor="let diagnose of jobItem.alayacareShift.client.diagnoses">
            <p class="title text-capitalize">{{diagnose.name}}</p>
            <div class="details">
              <div class="details-item text-capitalize" *ngIf="diagnose.description?.length">
                <i class="fas fa-search icon"></i>
                {{diagnose.description}}
              </div>

              <div class="details-item text-capitalize">
                <i class="fas fa-calendar icon"></i>
                since: {{diagnose.startDate}}
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="item" *ngIf="jobItem?.alayacareShift?.client?.surgeries?.length">
        <div class="header" (click)="toggleDropdown('surgeries')">
          <i class="fas fa-comment-medical icon"></i>
          <span>
            surgeries
          </span>
          <i class="fa fa-chevron-down dropdown-icon" *ngIf="!showDropdownDetails.surgeries"></i>
          <i class="fa fa-chevron-up dropdown-icon" *ngIf="showDropdownDetails.surgeries"></i>
        </div>
        <div class="description" *ngIf="showDropdownDetails.surgeries">
          <div *ngFor="let surgery of jobItem.alayacareShift.client.surgeries">
            <p class="title text-capitalize">{{surgery.name}}</p>
            <div class="details">
              <div class="details-item text-capitalize" *ngIf="surgery.description?.length">
                <i class="fas fa-search icon"></i>
                {{surgery.description}}
              </div>

              <div class="details-item text-capitalize">
                <i class="fas fa-calendar icon"></i>
                since: {{surgery.startDate}}
              </div>

            </div>
          </div>
        </div>
      </div> -->

      <div class="item">
        <div class="header" (click)="toggleDropdown('tasks')">
          <i class="fa fa-tasks icon"></i>
          <span>
            tasks
          </span>
          <i class="fa fa-chevron-down dropdown-icon" *ngIf="!showDropdownDetails.tasks"></i>
          <i class="fa fa-chevron-up dropdown-icon" *ngIf="showDropdownDetails.tasks"></i>
        </div>
        <div class="description" *ngIf="showDropdownDetails.tasks">
          <div class="tasks-container">
            <div *ngFor="let patient of jobItem.homecareShift?.patients">
              <span *ngIf="patient.id"  class="task-title">Patient: {{patient.id}}</span><br />
            <div class="task" *ngFor="let task of patient?.tasks">
              
              <span class="sub-head"><span class="required" *ngIf="task.isRequired">*</span>{{task.name}}</span><br />
              <span class="description">{{task.description}}</span>
            </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>