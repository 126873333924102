/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stat-pay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./stat-pay.component";
var styles_StatPayComponent = [i0.styles];
var RenderType_StatPayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatPayComponent, data: {} });
export { RenderType_StatPayComponent as RenderType_StatPayComponent };
function View_StatPayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["$", "/hr"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.statPayJobs.jobs[0].initialStafferRate * 1.5); _ck(_v, 1, 0, currVal_0); }); }
function View_StatPayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["$", "/hr"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.statPayJobs.jobs[0].initialRate * 1.5); _ck(_v, 1, 0, currVal_0); }); }
function View_StatPayComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "staffer-icons"], ["src", "/assets/images/tick.png"]], null, null, null, null, null))], null, null); }
function View_StatPayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵted(-1, null, [" \u00A0"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatPayComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.isStatPay; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.momentedShiftStartTimeTz; _ck(_v, 2, 0, currVal_0); }); }
export function View_StatPayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "ion-justify-content-center ion-text-center child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Stat Holiday Pay "])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["style", "height:50%; overflow-y: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Holiday Pay Rate: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatPayComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatPayComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatPayComponent_3)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "ion-button", [["class", " ion-no-margin btn success-popup-ok"], ["color", "dark"], ["expand", "full"], ["id", "success-popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(14, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, [" Dismiss "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.statPayJobs.jobs[0].type == "staffer"); _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.statPayJobs.jobs[0].type == "employer"); _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.statPayJobs.jobs; _ck(_v, 12, 0, currVal_2); var currVal_3 = "dark"; var currVal_4 = "full"; _ck(_v, 14, 0, currVal_3, currVal_4); }, null); }
export function View_StatPayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stat-pay", [], null, null, null, View_StatPayComponent_0, RenderType_StatPayComponent)), i1.ɵdid(1, 114688, null, 0, i5.StatPayComponent, [i4.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatPayComponentNgFactory = i1.ɵccf("app-stat-pay", i5.StatPayComponent, View_StatPayComponent_Host_0, { statPayJobs: "statPayJobs" }, {}, []);
export { StatPayComponentNgFactory as StatPayComponentNgFactory };
