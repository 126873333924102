import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnChanges, OnInit, QueryList } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IonDatetime, IonSlides } from '@ionic/angular';
import { forEach, sortBy } from 'lodash';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user.service';
import { HelpService } from '../../pages/help/services/help.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
export class ViewCertificatesComponent {
    constructor(popupService, sanitizer, userService, helpService, router, loadingService) {
        this.popupService = popupService;
        this.sanitizer = sanitizer;
        this.userService = userService;
        this.helpService = helpService;
        this.router = router;
        this.loadingService = loadingService;
        this.addCertificate = new EventEmitter();
        this.editCertificateDate = new EventEmitter();
        this.slideConfig = {
            slidesPerView: 1,
            spaceBetween: 10
        };
        this.currentIndex = 0;
        this.images = [];
        this.currentSlide = 0;
        this.viewer = 'google';
        this.doc = '';
        this.isCertificateDOC = false;
        this.isCertificatePDF = false;
        this.isCertificateImage = false;
        this.sanitizeURL = '';
        this.showLoading = true;
        this.customPickerButtons = {};
        this.showDatePicker = false;
    }
    ngOnInit() {
        if (!this.stafferCertificates || !this.stafferCertificates.length) {
            return;
        }
    }
    slideTouchEnd() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let isEnd = yield (this.slider.isEnd());
            let sliderLength = (yield this.slider.length()) - 1;
            if (isEnd && this.currentSlide < sliderLength) {
                this.currentSlide = this.currentSlide + 1;
            }
            this.slider.update();
        });
    }
    enableDeleteButton(item) {
        //if certificate is approved or already deleted hide the delete button
        if (item.isApprove || item.markCertificateAsRejected.isDeleted)
            return false;
        return true;
    }
    slideChanged() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentSlide = yield this.slider.getActiveIndex();
        });
    }
    ngOnChanges() {
        this.stafferCertificates = this.certificates;
        this.stafferCertificates = sortBy(this.stafferCertificates, o => (!o.markCertificateAsRejected.isRejected));
        this.formatExtension();
    }
    //Emit action when add/replaced certificate is clicked
    addCertificates(isReplaced, certificateId) {
        this.addCertificate.emit({ isReplaced: isReplaced, certificateId });
    }
    updateSliders(index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (index == 0)
                yield this.slider.slideNext();
            else
                yield this.slider.slidePrev();
        });
    }
    formatExtension() {
        forEach(this.stafferCertificates, (cert) => {
            if (cert.url.includes('http:')) {
                cert.url = cert.url.replace('http:', 'https:');
            }
            let extension = cert.url.substring(cert.url.lastIndexOf('.'));
            if (extension == '.pdf') {
                cert.isCertificatePDF = true;
                cert.sanitizeURL = this.sanitizer.bypassSecurityTrustResourceUrl(cert.url);
            }
            else if (extension === '.docx' || extension === '.doc' || extension === '.txt') {
                cert.isCertificateDOC = true;
                cert.sanitizeURL = this.sanitizer.bypassSecurityTrustResourceUrl(cert.url);
            }
            else if (extension === '.heic') {
                cert.url = cert.url.replace('.heic', '.jpg');
                cert.isCertificateImage = true;
            }
            else {
                cert.isCertificateImage = true;
            }
        });
    }
    textLayerRendered(e) {
        this.showLoading = false;
    }
    showDeleteCertificatePopup(item) {
        //show confirmation popup when contractor wants to remove certificate, uploaded accidently
        this.popupService.showModal({
            heading: 'STOP',
            message: 'Are you sure you want to delete this credential',
            btn: 'Yes',
            rejectBtnText: 'No',
            navigateRoute: null,
            imgURL: 'assets/images/notice.png'
        }, (data) => {
            this.removeCertificate(item._id);
        });
    }
    removeCertificate(certificateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingService.showLoading().toPromise();
            try {
                //Remove certificate from backend by requesting API
                this.userService.removeCertificate(this.userData._id, certificateId, false);
                //Remove certificate from frontend by slicing the id from Array
                const index = this.stafferCertificates.findIndex(cert => cert._id == certificateId);
                this.stafferCertificates.splice(index, 1);
                //if contractor deleted all the certificates then naviagte to back screen
                if (this.stafferCertificates.length == 0)
                    this.navigateBackForEmptyCertificates();
            }
            catch (error) {
                this.onDeletionError();
            }
            finally {
                this.loadingService.hideLoading(loading);
            }
        });
    }
    navigateBackForEmptyCertificates() {
        if (!this.userData.profile_approved)
            this.router.navigateByUrl('onboarding-staffer/certificates');
        else
            this.router.navigateByUrl('talent-dashboard/profile');
    }
    onError(error) {
        console.log('error: ', error);
        this.showLoading = false;
        this.popupService.showModal({
            heading: 'Error',
            message: 'Failed to load document. Please click on link to view credential file.',
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
        });
    }
    onDeletionError() {
        this.showLoading = false;
        this.popupService.showModal({
            heading: 'Error',
            message: 'Failed to delete document. Please try again later.',
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
        });
    }
    showReasonOfRejection(item) {
        this.popupService.showModal({
            heading: 'This document has not been approved',
            message: item.markCertificateAsRejected.reason ? 'Reason for rejection: \n\n' + item.markCertificateAsRejected.reason : '-',
            btn: 'Reupload',
            rejectBtnText: 'Ok',
            navigateRoute: null,
            imgURL: 'assets/images/notice.png'
        });
    }
    showPicker() {
        this.showDatePicker = !this.showDatePicker;
    }
    loadResults(item) {
        if (!item.expiryDate) {
            console.log('Expiry date missing');
            return;
        }
    }
    onProgress(certificateProgress) {
        this.showLoading = true;
        // const loadingTask = pdf.getDocument({ data: certificateProgress });
    }
    openLink(url) {
        this.helpService.openAppBrowserLink(url);
    }
    navigateToUpload() {
        if (!this.userData.isSignupProcessComplete)
            this.router.navigateByUrl('onboarding-staffer/certificates');
        else
            this.router.navigateByUrl('talent-dashboard/home');
    }
    //Emit action when edit X date is clicked. Only 2 type of dates can be edited, issue date or expiry date
    editDateForCertificate(certificate, selectedDateType) {
        this.editCertificateDate.emit({ certificate, selectedDateType });
    }
}
