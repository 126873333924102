import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import * as moment from 'moment-timezone';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { OnBoardingStepService } from 'src/modules/shared/services/onboarding-step.service';
import { PopupService } from 'src/modules/shared/services/popup.service';
export class ExpiryDateComponent {
    constructor(popupService, localStorageService, formBuilder, onBoardingStepService) {
        this.popupService = popupService;
        this.localStorageService = localStorageService;
        this.formBuilder = formBuilder;
        this.onBoardingStepService = onBoardingStepService;
        this.refrenceData = [];
        this.formSubmitted = new EventEmitter();
        this.backEmitter = new EventEmitter();
        this.save = new EventEmitter();
        this.referenceList = [
            { index: 0, showStartDate: false, showEndDate: false },
            { index: 1, showStartDate: false, showEndDate: false },
            { index: 2, showStartDate: false, showEndDate: false }
        ];
        this.yearsList = [];
        this.currentYear = moment().year();
        this.showReferenceForm = true;
        this.index = 3;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let i = 0; i <= 50; i++) {
                this.yearsList.push(this.currentYear - i);
            }
        });
    }
    toggleStartDate(reference) {
        reference.showStartDate = true;
    }
    createForm() {
        let array = [];
        for (let i = 0; i < this.referenceList.length; i++) {
            array.push(this.BuildFormDynamic(this.referenceList[i]));
        }
        this.expiryDateForm = this.formBuilder.group({
            referenceDetails: this.formBuilder.array(array)
        });
    }
    BuildFormDynamic(referenceData) {
        let initializeReference = {
            index: [referenceData.index],
            startYear: new FormControl(this.user.references[referenceData.index] ? this.resolveDate(this.user.references[referenceData.index].startDate).year : null, [Validators.required]),
            startMonth: new FormControl(this.user.references[referenceData.index] ? this.resolveDate(this.user.references[referenceData.index].startDate).month : null, [Validators.required]),
            startDay: new FormControl(this.user.references[referenceData.index] ? this.resolveDate(this.user.references[referenceData.index].startDate).day : null, [Validators.required]),
            startDate: new FormControl(this.user.references[referenceData.index] ? this.user.references[referenceData.index].startDate : null, [Validators.required]),
        };
        initializeReference['reference'] = {
            startDay: [],
            endDay: [],
            startDaysList: this.user.references[referenceData.index] ? this.getDaysInMonth(initializeReference, initializeReference['startYear'].value, initializeReference['startMonth'].value, this.user.references[referenceData.index], 'start') : [],
            endDaysList: this.user.references[referenceData.index] ? this.getDaysInMonth(initializeReference, initializeReference['endYear'].value, initializeReference['endMonth'].value, this.user.references[referenceData.index], 'end') : [],
            startYear: '',
            startMonth: '',
        };
        return this.formBuilder.group(initializeReference);
    }
    resolveDate(date) {
        const split_date = date.split(' ');
        let resolvedDate = {
            month: moment().month(split_date[0]).format('MM'),
            day: moment(split_date[1], 'DD').format('D'),
            year: split_date[2]
        };
        return resolvedDate;
    }
    getDaysInMonth(cd, year, month, reference, type) {
        // get days in the selected month of the selected year
        const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
        // push days numbers in dayslist array
        const daysList = [];
        for (let day = 1; day <= daysInMonth; day++) {
            daysList.push(day.toString());
        }
        // if function is called with reference passed then update the reference dayslist array depending on the type passed
        if (reference && type === 'start') {
            // if selected start day of reference is not in the dayslist of selected month and year then assign 01 date to it
            if (cd.controls && !daysList.includes(reference.startDay)) {
                cd.controls.startDay.setValue('1');
            }
            reference.startDaysList = daysList;
            return reference.startDaysList;
        }
        else if (reference && type === 'end') {
            // if selected end day of reference is not in the dayslist of selected month and year then assign 01 date to it
            if (cd.controls && !daysList.includes(reference.endDay)) {
                cd.controls.endDay.setValue('1');
            }
            reference.endDaysList = daysList;
            return reference.endDaysList;
        }
        else {
            return daysList;
        }
    }
    setDate(cd, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let reference = cd.value;
            let timeZone = yield this.localStorageService.getUserTimeZone();
            if (!timeZone) {
                timeZone = 'America/Toronto';
            }
            if (type === 'start') {
                let { startYear, startMonth, startDay } = reference;
                if (startYear && startMonth && startDay) {
                    startMonth = startMonth - 1;
                    cd.controls.startDate.setValue(moment({ year: startYear, month: startMonth, day: startDay }).tz(timeZone).format('MMMM Do YYYY'));
                }
            }
            if (type === 'end') {
                let { endYear, endMonth, endDay } = reference;
                if (endYear && endMonth && endDay) {
                    endMonth = endMonth - 1;
                    cd.controls.endDate.setValue(moment({ year: endYear, month: endMonth, day: endDay }).tz(timeZone).format('MMMM Do YYYY'));
                }
            }
        });
    }
    ;
    toMomentInTimezone(date, timezone) {
        const result = moment.tz(timezone);
        result.year(date.getFullYear());
        result.month(date.getMonth());
        result.date(date.getDate());
        return result;
    }
    ;
    onYearChange(cd, val) {
        if (val === 'start') {
            cd.controls.startMonth.setValue('');
            cd.controls.startDay.setValue('');
        }
        else {
            cd.controls.endMonth.setValue('');
            cd.controls.endDay.setValue('');
        }
    }
    addReference(referenceData) {
        this.formSubmitted.emit(referenceData);
        this.expiryDateForm.reset();
    }
    nextStep() {
    }
    validTimeStamps(referenceData) {
        let startBeforeEnd = false;
        let inFuture = false;
        referenceData.forEach((element) => {
            let startDate = moment(element.startDate, 'MMMM/DD/YYYY');
            let endDate = moment(element.endDate, 'MMMM/DD/YYYY');
            let timeZone = 'America/' + (this.user.address.city === 'Mississauga' ? 'Toronto' : this.user.address.city);
            timeZone = timeZone === 'America/Houston' ? 'America/Chicago' : timeZone;
            if (moment(moment(startDate).format('MM/DD/YYYY'), 'MM/DD/YYYY').isSameOrAfter(moment(endDate).format('MM/DD/YYYY'))) {
                startBeforeEnd = true;
            }
            else if (startDate.isSameOrAfter(endDate)) {
                startBeforeEnd = true;
            }
            else if (moment(endDate).isAfter(moment.tz(timeZone))) {
                inFuture = true;
            }
        });
        if (startBeforeEnd) {
            throw 'startBeforeEnd';
        }
        if (inFuture) {
            throw 'inFuture';
        }
        return;
    }
    get startYear() { return this.expiryDateForm.get('startYear'); }
    get startMonth() { return this.expiryDateForm.get('startMonth'); }
    get startDay() { return this.expiryDateForm.get('startDay'); }
    get startDate() { return this.expiryDateForm.get('startDate'); }
    get endYear() { return this.expiryDateForm.get('endYear'); }
    get endMonth() { return this.expiryDateForm.get('endMonth'); }
    get endDay() { return this.expiryDateForm.get('endDay'); }
    get endDate() { return this.expiryDateForm.get('endDate'); }
    get formData() {
        return this.expiryDateForm.get('referenceDetails');
    }
}
