import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';

interface RecurringShift {
  Date: string;
  'Start Time': string;
  'End Time': string;
  'Unpaid Break' : string;
  'Transit Allowance' : string;
}

@Component({
  selector: 'app-recurrent-shift-list',
  templateUrl: './recurrent-shift-list.component.html',
  styleUrls: ['./recurrent-shift-list.component.scss'],
})
export class RecurrentShiftListComponent implements OnInit{
  @Input() data: RecurringShift[] = [];
  @Input() config: string[] = [];

  ngOnInit(){
  }
 
}