import { Component, EventEmitter, Input, OnInit, Output, forwardRef, OnChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SearchableSelectModalComponent } from './searchable-select-modal/searchable-select-modal.component';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';


@Component({
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchableSelectComponent),
      multi: true
    }
  ]
})
export class SearchableSelectComponent implements OnInit, ControlValueAccessor {

  @Input() options: Array<string>;
  @Input() selectedOptions: Array<string> = [];

  @Input() multiple : Boolean = false;
  @Input() labelText: string = '';
  @Output() onSelect : EventEmitter<Array<String>> = new EventEmitter();
  @Input() control: FormControl;
  alertController: HTMLIonModalElement;

  placeHolderText: string = "Select Sub Skill";
  
  originalSelectedOptions;
  _subSkills: string[];
  propagateChange = (_: any) => {};
  propagateTouched = (_: any) => {};

  constructor( private modalController: ModalController){
  }

  get subSkills(): any {
    return this._subSkills;
  }

  set subSkills(value:  any) {
    this._subSkills = value;
    this.onSelect.emit(this._subSkills);
    this.propagateChange(this._subSkills);
  }

  ngOnInit() {
    this.originalSelectedOptions = this.multiple ? [...this.selectedOptions] : this.selectedOptions;
  }

  ngOnChanges() {

    

    if(!this.multiple) {
      this.originalSelectedOptions = this.selectedOptions;
      this.placeHolderText = this.originalSelectedOptions;
    }


    if(this.multiple && !this.selectedOptions.length || (this.control && this.control.pristine && !this.control.value.length)) {
      this.placeHolderText= "Select Sub Skill"
      this.originalSelectedOptions = [...this.selectedOptions];
    }

  }
  /**
   * 
   * @param selectedOptions 
   * @description this function handle when select button is called
   */

  async handleSelect(selectedOptions: Array<string>){
      if(selectedOptions.length) {
        this.placeHolderText = selectedOptions.join(",");
      }
      else {
        this.placeHolderText= "Select Sub Skill"
      }
      this.originalSelectedOptions = [ ...selectedOptions ];
      
      this.onSelect.emit(selectedOptions);
      this.subSkills = selectedOptions;
      await this.alertController.dismiss()
  }


  /**
   * this function handle the cancel button and dismiss the modal
   */

  async handleCancel(){

    if(!this.multiple) {
      this.selectedOptions = this.originalSelectedOptions;
      await this.alertController.dismiss();
      return;
    }
    this.selectedOptions = [...this.originalSelectedOptions];
    this.subSkills = this.selectedOptions;
    await this.alertController.dismiss()
  }


  /**
   * @descripiton this function init the searchable select modal
   */

  async handleSubSkillDropDownClick(){
    
    if(this.alertController){
      await this.alertController.dismiss()
    }

    this.alertController = await this.modalController.create({
      component: SearchableSelectModalComponent,
      backdropDismiss: false,
      cssClass: 'searchable-select-modal',
      componentProps:{
        options: this.options,
        selectedOptions: this.selectedOptions,
        onCancel: this.handleCancel.bind(this),
        multiple: this.multiple,
        onSelect: this.handleSelect.bind(this)
      }
  });

    await this.alertController.present();
  }


  //Form Accessor Functions for custom components
  writeValue(value: any): void {
    this._subSkills = value;
  }
  

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }
 


}
