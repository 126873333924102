
import { Injectable } from '@angular/core';
import { PopupService } from './popup.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class SubSkillService {
  constructor(
      private popupService: PopupService,
      private userService: UserService
  ) {}

  checkSubSkillError(error, userData){
    if(!error || !error.error || error.error.indexOf('required subskil') == -1)
        return {isHandled: false};
    
    this.popupService.showModal(
      {
        heading: 'Sorry',
        message: error.error,
        btn: 'I have the skill!',
        rejectBtnText: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/incomplete.png'
      },
      async accept => {
        this.userService.createSubSkillsRequest(userData._id, this.userService.extractSubSkillsFromErrorMessage(error.error));
        this.popupService.showModal({
          heading: 'Request Received.',
          message: 'We have recieved your request and will review your application.',
          btn: 'Ok',
          navigateRoute: null,
          imgURL: 'assets/images/thumbs-up.png'
        });
      }
    );

     return { isHandled: true };
  }

}
