import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ITalentJob } from '../../interfaces/talent-job.interface';
import { ImagesService } from 'src/modules/shared/services/images.service';
import * as moment from 'moment-timezone';
import { TalentDashboardUtilService } from '../../services/talent-dashboard-util.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { TalentJobFormatService } from '../../services/talent-job-format.service';
import { findIndex } from 'lodash';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { TalentCovidAssessmentService } from '../../services/talent-covid-assessment.service';
import { ModalController } from '@ionic/angular';
import { ShiftDetailsModalComponent } from '../shift-details-modal/shift-details-modal.component';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { JobService } from 'src/modules/shared/services/job.service';
import { Location } from '@angular/common';
import { RecurrentShiftService } from 'src/modules/shared/services/recurrentShift.service';
import { IBulletin } from 'src/modules/shared/interfaces/bulletin.interface';

@Component({
  selector: 'app-upcoming-shift-card',
  templateUrl: './upcoming-shift-card.component.html',
  styleUrls: ['./upcoming-shift-card.component.scss'],
})
export class UpcomingShiftCardComponent implements OnInit {
  showShiftNote: boolean;
  showSiteNote: boolean;
  hasStafferStartedAJob: boolean;
  isStafferConfirmedShift: boolean;
  profilePicture: string;
  humanizeShiftTime: string;
  shiftStartTimeFormatted: string;
  startTime: string;
  endTime: string;
  duration: string;
  potentialEarning: number;
  distanceFromShift: string;
  timeLeft: boolean;
  currentUserIndex: number;
  isShiftStarted: boolean;
  timeTracked: string;
  trackerAlreadyConfirmed: boolean;
  alreadyAppliedForManualHours: boolean;
  downloadInprogressPdfId: string;


  @Input() jobItem: ITalentJob;
  @Input() activeTab: string;
  @Input() userData: IUser;
  @Input() enableCOVIDPopupInApp : boolean;
  @Input() initiator: string;
  @Output() shiftConfirmed = new EventEmitter<{
    jobItem: ITalentJob,
    userId: string,
  }>();
  @Output() startTheShiftOffline = new EventEmitter<{
    jobItem: ITalentJob,
    userId: string,
  }>();
  @Output() endTheTimer = new EventEmitter<{}>();
  @Output() shiftDeclined = new EventEmitter<{jobItem: ITalentJob}>();

  source: string;
  shiftAddressList: string[] = [];
  constructor(
    private imagesService: ImagesService,
    private utilService: UtilService,
    private talentDashboardUtilService: TalentDashboardUtilService,
    private talentJobFormatService: TalentJobFormatService,
    private popupService: PopupService,
    private talentCovidAssessmentService: TalentCovidAssessmentService,
    private modalController: ModalController,
    private loadingService: LoadingService,
    private jobService: JobService,
    private location: Location,
    private recurrentShiftService :  RecurrentShiftService
  ) {

  }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.jobItem && this.userData) {
      this.profilePicture = this.getProfilePic(this.jobItem.entity.creator.profileUrl);
      if(this.jobItem.recurringShift.groupJobId && this.jobItem.recurringShift.groupJobId.recurrentShiftTemplateId && this.jobItem.recurringShift.groupJobId.recurrentShiftTemplateId._id){
        this.shiftStartTimeFormatted=  this.formatRecurrentShiftInformation(this.jobItem)
      }else{
      this.shiftStartTimeFormatted = moment.unix(this.jobItem.shiftStartTime).tz(this.jobItem.timeZone).format('dddd MMMM DD');
      }
      this.startTime = this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftStartTime, true);
      this.endTime = this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftEndTime, true);
      this.hasStafferStartedAJob = this.talentDashboardUtilService.hasStafferStartedAJob(this.userData._id, this.jobItem.staffersAndShifts);
      this.isStafferConfirmedShift = this.talentDashboardUtilService.isStafferConfirmedShift(this.jobItem, this.userData);
      this.humanizeShiftTime = this.talentJobFormatService.humanizeShiftTime(this.jobItem.shiftStartTime, this.hasStafferStartedAJob);
      this.duration = this.talentJobFormatService.getJobDuration(moment.unix(this.jobItem.shiftStartTime).tz(this.jobItem.timeZone), moment.unix(this.jobItem.shiftEndTime).tz(this.jobItem.timeZone));
      this.duration = this.talentJobFormatService.formatDuration(this.duration);
      this.potentialEarning = this.talentDashboardUtilService.getcalculatedPotentialEarning(this.jobItem);
      this.distanceFromShift = this.utilService.calculateDistanceFromShift(this.jobItem.companyAddress, this.userData.address);
      this.timeLeft = this.talentJobFormatService.getTimeDifference(this.jobItem.shiftStartTime) < 0 ? true : false;
      this.currentUserIndex = findIndex(this.jobItem.staffersAndShifts, ['staffer', this.userData._id]);
      this.source = this.initiator;
      if(this.currentUserIndex && this.jobItem.staffersAndShifts && this.currentUserIndex > -1) {
        this.isShiftStarted = this.jobItem.staffersAndShifts[this.currentUserIndex].isShiftStarted;
      }
      this.shiftAddressList = this.utilService.fetchAddressesFromPatientList(this.jobItem);    
    }
  }

  viewNotes(type: 'shift' | 'site') {
    
    let heading = '';
    let message = '';

    if (type === 'shift') {
     heading = 'Shift notes';
     message = this.jobItem.shiftNotes;
    }
    if (type === 'site') {
     heading = 'Site notes';
     message = this.jobItem.siteNotes || this.jobItem.entity.siteNotes;
    }

    this.popupService.showModal({heading: heading, message: message, btn: 'Dismiss', navigateRoute: '',
    imgURL: ''});
  }

  getProfilePic(profileUrl: { [key: string]: string }) {
    return this.imagesService.getProfilePic(profileUrl);
  }

  isShiftConfimed(shiftConfimed: boolean) {
    if(shiftConfimed)
      this.startShiftOffline(this.jobItem);

    else this.confirmShift(this.jobItem)

  }

  formatRecurrentShiftInformation(jobItem){

    let shiftInterval = this.recurrentShiftService.formatShiftInterval(jobItem.recurringShift.groupJobId.recurrentShiftTemplateId);
    let shiftIntervalDays = this.recurrentShiftService.convertDaysToShortFormat(jobItem.recurringShift.groupJobId.recurrentShiftTemplateId.days);

    return `${shiftInterval} on ${shiftIntervalDays}`
  }
  
  confirmShift(jobItem: ITalentJob) {
    this.shiftConfirmed.emit({
      jobItem,
      userId: this.userData._id
    });
  }

  startShiftOffline(jobItem: ITalentJob) {
    this.startTheShiftOffline.emit({
      jobItem,
      userId: this.userData._id
    });
  }

  timerEnded() {
    this.endTheTimer.emit();
  }

  async showBulletins(bulletins: IBulletin, entityId : string) {
    await this.popupService.viewShiftBulletins(bulletins, entityId);
  }

  covidSigned() {
    const currentUserIndex = findIndex(this.jobItem.staffersAndShifts, ['staffer', this.userData._id]);

    const currentUser = this.jobItem.staffersAndShifts[currentUserIndex];

    const tempEnableCovidAssessment = moment.duration(moment(this.jobItem.shiftStartTime).diff(+moment().format('X')), 'seconds').asHours()
      <= 12;

    if (this.talentCovidAssessmentService.hasCovidPassed(currentUser) && this.enableCOVIDPopupInApp) {

      if (!tempEnableCovidAssessment) {
        this.popupService.showModal(
          {
            heading: 'COVID-19 ASSESSMENT',
            message: `You may only submit the assessment within 12 hours of your shift start time.`,
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
          }
        );
        return;
      } else {
        // go for the assessment
        this.talentCovidAssessmentService.checkForCovid(
          this.jobItem, currentUser,
          async (covidRes, error) => {
            if (covidRes) {
              this.jobItem.staffersAndShifts[currentUserIndex].covidAssessment = true;
              this.jobItem.staffersAndShifts[currentUserIndex].covid = covidRes;
              await this.talentDashboardUtilService.saveShiftInLocalStorage(this.jobItem);
            }
          });
      }
    }
  }

  async showDetailsModal(){
    const myModal = await this.modalController.create({
      component: ShiftDetailsModalComponent,
      backdropDismiss: false,
      componentProps: {
        jobItem: this.jobItem,
        userData: this.userData,
      },
      cssClass: 'shift-details-modal-wrapper'
    });
    await myModal.present();  
  }

  getAge(dateOfBirth) {
    if (dateOfBirth) {
      const year = moment().diff(dateOfBirth, 'years', false);
      return year;
    }
    return '';
  }

  async declineShift(jobItem) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      const isRecurring = jobItem.recurringShift.isTrue;
      isRecurring ? 
        await this.jobService.rejectRecurringShift(jobItem.recurringShift.groupJobId, this.userData._id, this.userData.firstName, this.userData.lastName):
        await this.jobService.rejectBroadcastShift(jobItem._id, this.userData._id, this.userData.firstName, this.userData.lastName);
      
      this.shiftDeclined.emit({ jobItem });
    } catch (error) {
      console.log('error: ', error);
      this.popupService.showModal({
        heading: 'Sorry',
        message: error.data || typeof error.error === 'string' ? error.error : 'Something went wrong, please try again later',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
    } finally {
      this.loadingService.hideLoading(loading);
    }
  }

}
