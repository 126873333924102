import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {

  @Input() item; 		
  constructor(
  	private modalController: ModalController
  ) { }

  ngOnInit() {}

  submit() {
    this.modalController.dismiss();
  }
}
