import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenResolverService implements Resolve<boolean>{
  resolve(): boolean {
    this.hideSplashScreen();
    return true;
  }

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
  ) {

  }

  hideSplashScreen() {
    this.platform.ready().then(() => {

      this.splashScreen.hide();


    });
  }
}
