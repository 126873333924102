import { Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-select-experience',
  templateUrl: './select-experience.component.html',
  styleUrls: ['./select-experience.component.scss'],
})
export class SelectExperienceComponent implements OnInit {

  @Input() data;
  @Input() activated;
  list;
  heading;
  activate;
  selectedExperience;
  

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.list = this.data.list;
    this.activate = this.data.activated;
    this.heading = this.data.heading;
   }



  cancel() {
    this.modalController.dismiss();
  }

  experienceSelected(selectedExperience) {
    this.selectedExperience = selectedExperience;
    this.activate = selectedExperience.name;
  }

  saveExperience() {
    this.modalController.dismiss(this.selectedExperience);
  }
}
