import { Injectable } from '@angular/core';
import 'moment-duration-format';
import { ISkill } from 'src/modules/shared/interfaces/skills.interface';
import { SkillsService } from './skills.service';
import { SkillInterface } from './../interfaces/skill.interface';

@Injectable()
export class SkillFilterationService {
    constructor(
        private skillsService: SkillsService
    ) {}

    filterSkills(skills: ISkill[], skillsToKeep: string[]){
        return skills.filter(skill => skillsToKeep.indexOf(skill.name) > -1)
    }

    // //return an array against a skill to keep, passed from views 
    async getSkillsArray(skills: ISkill[], skillToKeep: string): Promise<ISkill[]> {
        const allSkills = await this.skillsService.getListOfAllSkills({outputFormat: 'default', filters: [skillToKeep]}) as string[];
        return this.filterSkills(skills, allSkills);
    }

    async doesUserHasHCSkills(skills: ISkill[]): Promise<boolean> {
        const allSkills = await this.skillsService.getListOfAllSkills({industriesToInclude: ['Healthcare'], outputFormat: 'names'}) as string[];
        return this.filterSkills(skills, allSkills).length > 0
    }

    async doesUserHasHospitalitySkills(skills: ISkill[]): Promise<boolean> {
        const allSkills = await this.skillsService.getListOfAllSkills({industriesToInclude: ['Hospitality'], outputFormat: 'names'}) as string[];
        return this.filterSkills(skills, allSkills).length > 0
    }

    async doesUserHasHCAttestationSkills(skills: ISkill[]): Promise<boolean> {
        const allSkills = await this.skillsService.getListOfAllSkills({filters: ['isAttestationRequired'], outputFormat: 'names'}) as string[];
        return this.filterSkills(skills, allSkills).length > 0;
    }

    async doesUserHasBOHSkills(skills: ISkill[]): Promise<boolean> {
        const allSkills = await this.skillsService.getListOfAllSkills({outputFormat: 'names', filters: ['isBOH']}) as string[];
        return this.filterSkills(skills, allSkills).length > 0;
    }

    async doesUserHasFOHSkills(skills: ISkill[]): Promise<boolean> {
        const allSkills = await this.skillsService.getListOfAllSkills({outputFormat: 'names', filters: ['isFOH'],}) as string[];;
        return this.filterSkills(skills, allSkills).length > 0;
    }

    async doesUserHasGeneralSkills(skills: ISkill[]): Promise<boolean> {
        const allSkills = await this.skillsService.getListOfAllSkills({outputFormat: 'names', filters: ['isGeneral']}) as string[];;
        return this.filterSkills(skills, allSkills).length > 0;
    }

    async doesUserSkillRequireApparel(skills?: ISkill[]): Promise<boolean> {
        const allSkills = await this.skillsService.getListOfAllSkills({outputFormat: 'names', filters: ['isBOH', 'isFOH', 'isGeneral'],  }) as string[];
        return this.filterSkills(skills, allSkills).length > 0;
    }

    async fetchSkillsWithRequiredCertificates(): Promise<SkillInterface[]> {
        const allSkills = await this.skillsService.getListOfAllSkills({industriesToInclude: ['Healthcare'], outputFormat: 'default'}) as SkillInterface[];
        return allSkills.filter((skill: SkillInterface) => skill.requiredCertificates.length > 0);
    }
}