import * as tslib_1 from "tslib";
import { AlertController, ModalController } from '@ionic/angular';
import { SuccessPopupComponent } from '../components/success-popup/success-popup.component';
import { StafferRatingPopupComponent } from '../components/staffer-rating-popup/staffer-rating-popup.component';
import { DetailPopupComponent } from '../components/detail-popup/detail-popup.component';
import { Router } from '@angular/router';
import { FullDetailComponent } from '../components/full-detail/full-detail.component';
import { InvoicesService } from './invoices.service';
import { SelectExperienceComponent } from 'src/modules/onboarding-staffer/components/select-experience/select-experience.component';
import { BulletinsComponent } from '../bulletins/bulletins.component';
import { ServiceFeeUpdateNoticeComponent } from '../components/service-fee-update-notice/service-fee-update-notice.component';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/router";
import * as i3 from "./invoices.service";
export class PopupService {
    constructor(alertController, modalController, router, invoicesService) {
        this.alertController = alertController;
        this.modalController = modalController;
        this.router = router;
        this.invoicesService = invoicesService;
    }
    showPopup(header, message, btnText = 'OK') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header,
                message,
                cssClass: 'showPopup',
                buttons: [btnText]
            });
            yield alert.present();
        });
    }
    showModal(
    // msgType: ModalTypeEnum,
    data, cb, cb2) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: data.component ? data.component : SuccessPopupComponent,
                backdropDismiss: false,
                cssClass: data.specificClass ? data.specificClass : data.class ? 'certificatesModal' : 'wideModal',
                componentProps: {
                    // msgType,
                    data
                }
            });
            yield myModal.present();
            return yield myModal.onDidDismiss().then(dismiss => {
                if (dismiss.data === 'accept') {
                    cb && cb();
                }
                else if (dismiss.data === 'reject') {
                    cb2 && cb2();
                }
                else
                    cb && cb();
            });
        });
    }
    showCustomComponentModal(data, cb, cb2) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: data.component,
                backdropDismiss: false,
                cssClass: 'wideModal',
                componentProps: {
                    data: data.customComponentParams,
                }
            });
            yield myModal.present();
            return yield myModal.onDidDismiss().then(dismiss => {
                if (dismiss.data === 'accept') {
                    cb && cb();
                }
                else if (dismiss.data === 'reject') {
                    cb2 && cb2();
                }
                else
                    cb && cb();
            });
        });
    }
    showExperienceModal(data, cb, cb2) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: SelectExperienceComponent,
                backdropDismiss: false,
                cssClass: 'wideModal',
                componentProps: {
                    data
                }
            });
            yield myModal.present();
            return yield myModal.onDidDismiss().then(dismiss => {
                if (dismiss.data === 'accept') {
                    cb && cb();
                }
                else if (dismiss.data === 'reject') {
                    cb2 && cb2();
                }
                else
                    cb && cb(dismiss);
            });
        });
    }
    showDetails(data, cssClass) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: DetailPopupComponent,
                backdropDismiss: false,
                cssClass: cssClass ? cssClass : 'wideModal',
                componentProps: {
                    data
                }
            });
            yield myModal.present();
            return yield myModal.onDidDismiss().then(dismiss => {
            });
        });
    }
    showStafferRatingPopup(data, cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: StafferRatingPopupComponent,
                backdropDismiss: false,
                cssClass: 'wideModal',
                componentProps: {
                    // msgType,
                    data
                }
            });
            yield myModal.present();
            return yield myModal.onDidDismiss().then(data => {
                if (cb) {
                    cb(data);
                }
            });
        });
    }
    recurringWarning() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.showModal({
                    heading: 'Notice',
                    message: 'By pressing the Confirm button below, you are accepting all the posted shifts and dates. \n Failure to complete all your shifts may result in deactivation from the Staffy platform.',
                    btn: 'Confirm',
                    rejectBtnText: 'Reject',
                    navigateRoute: null,
                    imgURL: 'assets/images/notice.png'
                }, () => {
                    // 'confirm';
                    resolve(true);
                }, () => {
                    //'cancel';
                    resolve(false);
                });
            });
        });
    }
    fullDetailModal(data, markAsPaid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: FullDetailComponent,
                backdropDismiss: true,
                cssClass: 'shiftDetailModal',
                componentProps: {
                    data
                }
            });
            yield myModal.present();
            return yield myModal.onDidDismiss().then(dismiss => {
                if (dismiss.data && dismiss.data.download === true) {
                    this.invoicesService.download(data.currentInvoice, true);
                }
                else if (dismiss.data && dismiss.data.markedAsPaid === true) {
                    markAsPaid();
                }
            });
        });
    }
    showScreeningPopup(link, entityName) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.showModal({
                heading: "Shift requested",
                message: `You have successfully requested this shift. We will send you a notification if you are approved. ${entityName} requires a one-on-one call before hiring you for this your shift`,
                btn: "Book Call",
                rejectBtnText: "Not Now",
                imgURL: "assets/images/calender2.png",
                navigateRoute: null,
                externalNavigationUrl: link,
            });
        });
    }
    viewShiftBulletins(bulletins, entityId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: BulletinsComponent,
                backdropDismiss: false,
                cssClass: 'wideModal',
                componentProps: {
                    bulletins,
                    entityId
                }
            });
            return yield myModal.present();
        });
    }
    showPlatformServiceFeeUpdateNotice(staffer, cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const myModal = yield this.modalController.create({
                component: ServiceFeeUpdateNoticeComponent,
                backdropDismiss: true,
                cssClass: 'wideModal',
                componentProps: {
                    staffer
                }
            });
            yield myModal.present();
            return yield myModal.onDidDismiss().then(data => {
                if (cb) {
                    cb(data);
                }
            });
        });
    }
}
PopupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopupService_Factory() { return new PopupService(i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.InvoicesService)); }, token: PopupService, providedIn: "root" });
