import { createAction, props } from '@ngrx/store';
import { ICompanyInformation } from '../../interfaces/company-information.interface';
import { IAddressConfirmation } from '../../../shared/enums/company-address-confirmation.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { IUserSignupPendingState } from '../../interfaces/company-signup-pending-state.interface.';
import { IBusinessContractEmail } from '../../interfaces/business-contract-email.interface';
import { IUpdateUser } from 'src/modules/authentication/interfaces/update-user.interface';

//////////////////////////////////////////////
export const createUserAction = createAction(
    '[OnBoarding Company] Create User Action',
    props<{ credentials: ICompanyInformation }>()
);
export const createUserSuccessAction = createAction(
    '[OnBoarding Company / API] Create User Success Action',
    props<{ token: string }>()
);
export const createUserFailureAction = createAction(
    '[OnBoarding Company / API] Create User Failure Action',
    props<{ error: any }>()
);


export const addressConfimationAction = createAction(
    '[OnBoarding Company] Address Confimation Action',
    props<{ credentials: IAddressConfirmation }>()
);
export const addressConfimationSuccessAction = createAction(
    '[OnBoarding Company / API] Address Confimation Success Action'
    , props<{ user: IUser, emailwhiteListStatus : Boolean }>()
);
export const addressConfimationFailureAction = createAction(
    '[OnBoarding Company / API] Address Confimation Failure Action',
    props<{ error: any }>()
);

export const redirectToAddressConfirmation = createAction(
    '[OnBoarding Company]  Redirect To Address Confirmation Action'
);

export const redirectToBusinessContract = createAction(
    '[OnBoarding Company]  Redirect To Business Contract Action'
);

export const redirectToDashboard = createAction(
    '[OnBoarding Company]  Redirect To Dashboard Action'
);
//////////////////////////////////////////////

export const signupPendingStateAction = createAction(
    '[OnBoarding Company] Signup Pending State Action',
    props<{ credentials: IUserSignupPendingState }>()
);

export const makePDFAndSendInEmail = createAction(
    '[OnBoarding Company] Make PDF And Send In Email Action',
    props<{ companyName: any, clientNewContract: number, clientAcceptedContract: boolean, contract_signed: string}>()
);

export const sendContractToEmail = createAction(
    '[OnBoarding Company] Send Contract To Email Action',
    props<{ credentials: IBusinessContractEmail }>()
);

export const redirectToPaymentAtSignup = createAction(
    '[OnBoarding Company]  Redirect To Payment At Signup Action'
);
export const redirectToAddCompanyLogo = createAction(
    '[OnBoarding Company]  Redirect To Add Company Logo Action'
);

export const sendContractToEmailSuccessAction = createAction(
    '[OnBoarding Company / API] Send Contract To Email Success Action',
    props<{contractVersion: number, contract_signed?: string}>()
);
export const sendContractToEmailFailureAction = createAction(
    '[OnBoarding Company / API] Send Contract To Email Failure Action',
    props<{ error: any }>()
);

export const updateUser = createAction(
    '[OnBoarding Company] Update User Action',
    props<{ credentials: IUpdateUser, redirectLink?: string }>()
);


export const addLogoAction = createAction(
    '[OnBoarding Company] Signup Add Logo Action',
    props<{ credentials: FormData }>()
);

export const addLogoSuccessAction = createAction(
    '[OnBoarding Company / API] Signup Add Logo Success Action',
    props<{ user: IUser }>()
);
export const addLogoFailureAction = createAction(
    '[OnBoarding Company / API] Signup Add Logo Failure Action',
    props<{ error: any }>()
);

export const chooseImageFromMobileAction = createAction(
    '[OnBoarding Company] Choose Image From Mobile Action',
    props<{ sourceType: string }>()
);

export const uploadMobilePictureAction = createAction(
    '[OnBoarding Company] Upload Mobile Picture Action',
    props<{ filePath: string, token: string }>()
);

export const checkEmailWhiteListStatusAction = createAction(
    '[OnBoarding Company] Check Email Whitelist Status Action',
    props<{ user: IUser }>()
);

export const checkEmailWhiteListStatusFailureAction = createAction(
    '[OnBoarding Company / API] Check Email Whitelist Status Action',
    props<{ error: any }>()
);
