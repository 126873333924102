import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
    name: 'momentPipeUnix'
})
export class MomentPipeUnix implements PipeTransform {
    transform(value: number | string, ...args: any[]): any {
        const [format, tz] = args;
        if (tz) {
            return moment.unix(+value).tz(tz).format(format);
        }
        else {
            return moment.unix(+value).format(format);

        }
    }
}