/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../pipes/moment-format.pipe";
import * as i6 from "./notification-card.component";
import * as i7 from "../../services/popup.service";
import * as i8 from "../../services/util.services";
import * as i9 from "@angular/router";
import * as i10 from "../../services/notification.service";
import * as i11 from "../../services/user.service";
import * as i12 from "../../../talent-dashboard/services/talent-job-checks.service";
import * as i13 from "../../../talent-dashboard/services/talent-job-format.service";
import * as i14 from "@ngrx/store";
import * as i15 from "../../../talent-dashboard/services/talent-dashboard-util.service";
import * as i16 from "../../services/subskill.service";
var styles_NotificationCardComponent = [i0.styles];
var RenderType_NotificationCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationCardComponent, data: {} });
export { RenderType_NotificationCardComponent as RenderType_NotificationCardComponent };
function View_NotificationCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "button-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.seeDetails() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["See details"]))], null, null); }
function View_NotificationCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "staffer": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "text-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "general-book orange"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "general-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "general-regular"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCardComponent_2)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (((_co.userData == null) ? null : _co.userData.type) == "staffer")); _ck(_v, 2, 0, currVal_0); var currVal_4 = _co.notification.showPopup; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.notification.created, "MMM DD, YYYY")); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.notification.notificationName; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.notification.body; _ck(_v, 12, 0, currVal_3); }); }
export function View_NotificationCardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.MomentPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCardComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.userData == null) ? null : _co.userData.type) == "staffer"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NotificationCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-notification-card", [], null, null, null, View_NotificationCardComponent_0, RenderType_NotificationCardComponent)), i1.ɵdid(1, 573440, null, 0, i6.NotificationCardComponent, [i7.PopupService, i8.UtilService, i9.Router, i10.NotificationService, i3.AlertController, i11.UserService, i12.TalentJobChecksService, i13.TalentJobFormatService, i14.Store, i15.TalentDashboardUtilService, i16.SubSkillService], null, null)], null, null); }
var NotificationCardComponentNgFactory = i1.ɵccf("shared-notification-card", i6.NotificationCardComponent, View_NotificationCardComponent_Host_0, { hour24PayAvailableBadge: "hour24PayAvailableBadge", notification: "notification", userData: "userData", settings: "settings" }, { acceptNonRecurringBroadcastRequest: "acceptNonRecurringBroadcastRequest", rejectStafferBroadcastRequest: "rejectStafferBroadcastRequest", unApplyFromNonRecurringShift: "unApplyFromNonRecurringShift", seeAvailableJobs: "seeAvailableJobs", confirmNonRecurringShift: "confirmNonRecurringShift", acceptNonRecurringHireRequest: "acceptNonRecurringHireRequest", rejectNonRecurringJob: "rejectNonRecurringJob", stafferStillWorkingOnNonRecurringJob: "stafferStillWorkingOnNonRecurringJob", stopTimerOnNonRecurringJob: "stopTimerOnNonRecurringJob", hireStafferForTheNonRecurringJob: "hireStafferForTheNonRecurringJob", submitRating: "submitRating", updateUser: "updateUser", hideNotificationDrawer: "hideNotificationDrawer" }, []);
export { NotificationCardComponentNgFactory as NotificationCardComponentNgFactory };
