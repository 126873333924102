import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Platform, ModalController } from '@ionic/angular';
import { dataURLtoFile } from 'src/modules/shared/utils/helpers/images.helper';
import { ImagesService } from 'src/modules/shared/services/images.service';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
class ImageSnippet {
    constructor(src, file) {
        this.src = src;
        this.file = file;
    }
}
export class ChoosePictureModalComponent {
    constructor(platform, imagesService, modalController, talentJobFormatService) {
        this.platform = platform;
        this.imagesService = imagesService;
        this.modalController = modalController;
        this.talentJobFormatService = talentJobFormatService;
        this.selectImage = false;
        this.errors = [];
        this.trigger = new Subject();
    }
    ngOnInit() {
        this.isBrowser = this.talentJobFormatService.isBrowserCheck();
        this.isCordova = this.platform.is('cordova');
        this.onResize();
        if (this.isCordova && !this.isBrowser && this.type == 'staffer') {
            this.takePhoto('CAMERA');
        }
    }
    onResize(event) {
        const win = !!event ? event.target : window;
        this.width = win.innerWidth;
    }
    ///////////////////////////////////////////////////// for browser
    processFile(imageInput) {
        const file = imageInput.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);
            const formData = new FormData();
            formData.append('images', this.selectedFile.file);
            this.modalController.dismiss({ formData });
        };
        reader.readAsDataURL(file);
    }
    triggerSnapshot() {
        this.trigger.next();
    }
    handleInitError(error) {
        this.errors.push(error);
    }
    get triggerObservable() {
        return this.trigger.asObservable();
    }
    handleImage(webcamImage) {
        const file = dataURLtoFile(webcamImage.imageAsDataUrl, 'image.png');
        var fd = new FormData();
        fd.append('images', file);
        // Create a test FormData object
        this.modalController.dismiss({ fd });
    }
    /////////////////////////////////////////////////// for mobile
    takePhoto(sourceType) {
        this.platform.ready().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const filePath = yield this.imagesService.choosePicture(sourceType).toPromise();
            this.modalController.dismiss({ filePath, sourceType });
        }));
    }
}
