/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-recurring-shift-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../edit-recurring-shift-list-item/edit-recurring-shift-list-item.component.ngfactory";
import * as i5 from "@angular/forms";
import * as i6 from "../edit-recurring-shift-list-item/edit-recurring-shift-list-item.component";
import * as i7 from "../../../company-dashboard/services/broadcast-job-utils.service";
import * as i8 from "../../../talent-dashboard/services/recurrentShift.service";
import * as i9 from "@angular/common";
import * as i10 from "./edit-recurring-shift-list.component";
var styles_EditRecurringShiftListComponent = [i0.styles];
var RenderType_EditRecurringShiftListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditRecurringShiftListComponent, data: {} });
export { RenderType_EditRecurringShiftListComponent as RenderType_EditRecurringShiftListComponent };
function View_EditRecurringShiftListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-col", [["class", "header"], ["size", "2"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(1, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = "2"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
function View_EditRecurringShiftListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-edit-recurring-shift-list-item", [["class", "row-container"], ["formArrayName", "recurringShifts"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i4.View_EditRecurringShiftListItemComponent_0, i4.RenderType_EditRecurringShiftListItemComponent)), i1.ɵdid(1, 212992, null, 0, i5.FormArrayName, [[3, i5.ControlContainer], [8, null], [8, null]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormArrayName]), i1.ɵdid(3, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), i1.ɵdid(4, 114688, null, 0, i6.EditRecurringShiftListItemComponent, [i1.ChangeDetectorRef, i7.BroadcastJobsUtilService, i8.RecurrentShiftService], { config: [0, "config"], control: [1, "control"], confirmationView: [2, "confirmationView"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "recurringShifts"; _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.config; var currVal_9 = _co.formGroup.get("recurringShifts").controls[_v.context.$implicit.index]; var currVal_10 = _co.confirmationView; _ck(_v, 4, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 3).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 3).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 3).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 3).ngClassValid; var currVal_5 = i1.ɵnov(_v, 3).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 3).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_EditRecurringShiftListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-row", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i1.ɵdid(3, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), i1.ɵdid(4, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_EditRecurringShiftListComponent_3)), i1.ɵdid(6, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formGroup; _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.data; _ck(_v, 6, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 3).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 3).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 3).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 3).ngClassValid; var currVal_5 = i1.ɵnov(_v, 3).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 3).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_EditRecurringShiftListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-recurring-shift-list-item", [["class", "row-container"]], null, null, null, i4.View_EditRecurringShiftListItemComponent_0, i4.RenderType_EditRecurringShiftListItemComponent)), i1.ɵdid(1, 114688, null, 0, i6.EditRecurringShiftListItemComponent, [i1.ChangeDetectorRef, i7.BroadcastJobsUtilService, i8.RecurrentShiftService], { config: [0, "config"], data: [1, "data"], confirmationView: [2, "confirmationView"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.confirmationView; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_EditRecurringShiftListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_EditRecurringShiftListComponent_5)), i1.ɵdid(3, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_EditRecurringShiftListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-row", [["class", "main-container"]], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "header-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurringShiftListComponent_1)), i1.ɵdid(4, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurringShiftListComponent_2)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditRecurringShiftListComponent_4)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.formGroup; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.formGroup; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_EditRecurringShiftListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-recurring-shift-list", [], null, null, null, View_EditRecurringShiftListComponent_0, RenderType_EditRecurringShiftListComponent)), i1.ɵdid(1, 114688, null, 0, i10.EditRecurringShiftListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditRecurringShiftListComponentNgFactory = i1.ɵccf("app-edit-recurring-shift-list", i10.EditRecurringShiftListComponent, View_EditRecurringShiftListComponent_Host_0, { data: "data", config: "config", formGroup: "formGroup", confirmationView: "confirmationView" }, {}, []);
export { EditRecurringShiftListComponentNgFactory as EditRecurringShiftListComponentNgFactory };
