import { createAction, props } from '@ngrx/store';
import { IUserQuiz } from 'src/modules/onboarding-staffer/interfaces/quiz';
import { ILogin } from '../../interfaces/login.interface';
import { IUpdateUser } from '../../interfaces/update-user.interface';
import { IUser } from '../../interfaces/user.interface';
//////////////////////////////////////////////
export const loginAction = createAction('[Auth] Login Action', props<{ credentials: ILogin }>());
export const loginSuccessAction = createAction('[Auth / API] Login Success Action', props<{ token: string }>());
export const loginFailureAction = createAction('[Auth / API] Login Failure Action', props<{ error: any }>());
//////////////////////////////////////////////
export const getUserInfoAfterCreateUserAction = createAction(
  '[Auth] User Info After Create User Action',
  props<{ successRoute: string }>()
);
export const getUserInfoAfterCreateUserSuccessAction = createAction(
  '[Auth / API]  User Info  After Create User Success Action',
  props<{ user: IUser; successRoute: string }>()
);
export const getUserInfoAfterCreateUserFailureAction = createAction(
  '[Auth / API]  User Info After Create User Failure Action',
  props<{ error: any }>()
);

export const setAvailableShiftAction = createAction(
  '[Auth]  Set setAvailableShiftAction Action',
  props<{ availableShift: any }>()
);

//////////////////////////////////////////////
export const getUserInfoAfterLoginAction = createAction('[Auth] User Info After Login Action');
export const getUserInfoAfterLoginSuccessAction = createAction(
  '[Auth / API]  User Info  After Login Success Action',
  props<{ user: IUser }>()
);
export const getUserInfoAfterLoginailureAction = createAction(
  '[Auth / API]  User Info After Login Failure Action',
  props<{ error: any }>()
);

//////////////////////////////////////////////
export const getUserInfoAction = createAction('[Auth] User Info Action', (payload: {redirectLink?: string} = {}) => payload);
export const getuserInfoSuccessAction = createAction('[Auth / API]  User Info Success Action', props<{ user: IUser, redirectLink?: string }>());
export const getUserInfoFailureAction = createAction('[Auth / API]  User Info Failure Action', props<{ error: any }>());

export const getUserInfoActionWithoutLoader = createAction('[Auth] User Info Action without loader');
///////////////////////////////////////////////


/////////////////// Patient actions /////////////////////

export const addPatientAction = createAction('[Auth] Add Patient', props<{ patient: any }>());

export const updatePatientAction = createAction('[Auth] Update Patient', props<{ patient: any }>());

export const removePatientAction = createAction('[Auth] Remove Patient', props<{ patient: any }>());

/////////////////// Patient actions /////////////////////
export const setStoreAction = createAction('[Auth]  Set Store Action', props<{ token: string; user: IUser }>());
export const setNotificationCountAction = createAction(
  '[Auth]  Set setNotificationCountAction Action',
  props<{ notificationCount: number }>()
);

export const setShiftInProgressAction = createAction(
  '[Auth]  Set setShiftInProgressAction Action',
  props<{ shiftInProgress: boolean }>()
);

export const resetStoreAction = createAction('[Auth]  Reset Store Action ');

export const removeDeviceTokenAction = createAction('[Auth]  Remove Device Token Action ');

export const clearCacheAction = createAction('[Auth]  Clear Cache Action ');
export const redirectToLoginAction = createAction('[Auth]  Redirect To Login Action');

export const redirectToDashboardAction = createAction('[Auth]  Redirect To Dashboard Action', props<{ user: IUser }>());

export const updateTalentContract = createAction('[Auth]  Update Talent Contract Action', props<{ user: IUser }>());

export const updateRoute = createAction('[Auth]  UpdateRoute Action', props<{ user: IUser }>());

export const updateUserPartialState = createAction(
  '[Auth] Update User + State Action',
  props<{ partialUserState: IUpdateUser }>()
);

export const updateUserPersonalInfoState = createAction(
  '[Auth] Update User Personal Info Action',
  props<{ partialPersonalInfoState: IUpdateUser, successRoute?: string }>()
);

export const updateUserPartialStateSuccess = createAction(
  '[Auth] Update User State Success Action',
  props<{ partialUserState: IUpdateUser }>()
);

export const setTokenToStoreAction = createAction('[Auth]  Set Token To Store Action', props<{ token: string }>());
export const setUserToStoreAction = createAction('[Auth]  Set User To Store Action', props<{ user: IUser }>());

export const blockUserAction = createAction('[Auth] Block User Action');

export const logOutAction = createAction('[Auth] logOut Action');

export const getSettings = createAction('[Auth / API] Get Settings');

export const settingsFetched = createAction('[Auth / API] Settings Fetched', props<{ settings: {} }>());

export const userQuizSubmissionSuccessAction = createAction('[Auth] Quiz Submission success', props<{ quiz: IUserQuiz }>());

export const userQuizSubmissionFailureAction =   createAction(
  '[OnBoarding Staffer / API] Signup Quiz Failure Action',
  props<{ error: any }>()
);

export const completedIntroductoryStepsAction =   createAction(
  '[Auth] User has passed through the onboarding intro steps'
)
