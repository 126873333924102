/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./choose-time-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/moment-format-unix.pipe";
import * as i3 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/forms";
import * as i6 from "./choose-time-modal.component";
var styles_ChooseTimeModalComponent = [i0.styles];
var RenderType_ChooseTimeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChooseTimeModalComponent, data: {} });
export { RenderType_ChooseTimeModalComponent as RenderType_ChooseTimeModalComponent };
export function View_ChooseTimeModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.MomentPipeUnix, []), i1.ɵqud(671088640, 1, { ionRanger: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "card-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "flex-grow"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit Logged Hours"])), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "range-slider flex-grow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "timing-section"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " - ", " "])), i1.ɵppd(8, 2), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "ranger-sec"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "ion-range", [["class", "ranger"], ["dual-knobs", ""], ["id", "dual-range"], ["step", "1800"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.timeVal = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_IonRange_0, i3.RenderType_IonRange)), i1.ɵdid(12, 16384, null, 0, i4.SelectValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.SelectValueAccessor]), i1.ɵdid(14, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(16, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), i1.ɵdid(17, 49152, [[1, 4]], 0, i4.IonRange, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { max: [0, "max"], min: [1, "min"], step: [2, "step"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "button-stack flex-grow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "btn submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Submit "]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.timeVal; _ck(_v, 14, 0, currVal_9); var currVal_10 = _co.slider.max; var currVal_11 = _co.slider.min; var currVal_12 = "1800"; _ck(_v, 17, 0, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), ((_co.timeVal == null) ? null : _co.timeVal.lower), "h:mm a")); var currVal_1 = i1.ɵunv(_v, 7, 1, _ck(_v, 9, 0, i1.ɵnov(_v, 0), ((_co.timeVal == null) ? null : _co.timeVal.upper), "h:mm a")); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 16).ngClassValid; var currVal_7 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ChooseTimeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-choose-time-modal", [], null, null, null, View_ChooseTimeModalComponent_0, RenderType_ChooseTimeModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.ChooseTimeModalComponent, [i4.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChooseTimeModalComponentNgFactory = i1.ɵccf("app-choose-time-modal", i6.ChooseTimeModalComponent, View_ChooseTimeModalComponent_Host_0, { slider: "slider", hr: "hr", min: "min", a: "a" }, { setNewTime: "setNewTime" }, []);
export { ChooseTimeModalComponentNgFactory as ChooseTimeModalComponentNgFactory };
