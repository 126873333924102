/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-toggle-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./shared-toggle-bar.component";
var styles_SharedToggleBarComponent = [i0.styles];
var RenderType_SharedToggleBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedToggleBarComponent, data: {} });
export { RenderType_SharedToggleBarComponent as RenderType_SharedToggleBarComponent };
function View_SharedToggleBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "schedule"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleChange("all") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "toggleActive": 0 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "schedule"; var currVal_1 = _ck(_v, 3, 0, (_co.value === "all")); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.allText; _ck(_v, 4, 0, currVal_2); }); }
export function View_SharedToggleBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "toggle-schedule"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "ion-button", [], null, null, null, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SharedToggleBarComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 4, "div", [["class", "schedule"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleChange(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "toggleActive": 0 }), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, 0, 4, "div", [["class", "schedule"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleChange(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(13, { "toggleActive": 0 }), (_l()(), i1.ɵted(14, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.allText && (_co.userType === "client")); _ck(_v, 4, 0, currVal_0); var currVal_1 = "schedule"; var currVal_2 = _ck(_v, 8, 0, (_co.value === false)); _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_4 = "schedule"; var currVal_5 = _ck(_v, 13, 0, (_co.value === true)); _ck(_v, 12, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.leftText; _ck(_v, 9, 0, currVal_3); var currVal_6 = _co.rightText; _ck(_v, 14, 0, currVal_6); }); }
export function View_SharedToggleBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shared-toggle-bar", [], null, null, null, View_SharedToggleBarComponent_0, RenderType_SharedToggleBarComponent)), i1.ɵdid(1, 638976, null, 0, i5.SharedToggleBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedToggleBarComponentNgFactory = i1.ɵccf("app-shared-toggle-bar", i5.SharedToggleBarComponent, View_SharedToggleBarComponent_Host_0, { data: "data", userType: "userType", paramValue: "paramValue", requestViewChange: "requestViewChange", recurrentView: "recurrentView", availabilityView: "availabilityView", isWeeklyEnabled: "isWeeklyEnabled", allView: "allView" }, { toggleBar: "toggleBar" }, []);
export { SharedToggleBarComponentNgFactory as SharedToggleBarComponentNgFactory };
