import { IUser } from '../interfaces/user.interface';

// AUTH STATE IS MORE LIKE APP STATE WHERE YOU STORE USER INFO AND THE INFO YOU WILL NEED AT THE START OF THE APP

export interface IAuthState {
  user: IUser;
  token: string;
  notificationCount: number;
  isLoading: boolean;
  signupCode: string;
  settings: any;
  availableShift: any;
  shiftInProgress: false;
  hasUserCompletedIntroductorySteps: boolean

}

export const authState: IAuthState = {
  user: null,
  token: null,
  isLoading: false,
  signupCode: null,
  notificationCount: 0,
  settings: null,
  availableShift: null,
  shiftInProgress: false,
  hasUserCompletedIntroductorySteps: false
};
