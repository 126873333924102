import { createAction, props } from '@ngrx/store';
import { IStafferInformation } from '../../interfaces/staffer-information.interface';
import { IAddressConfirmation } from 'src/modules/shared/enums/company-address-confirmation.interface';
import { IUpdateUser } from 'src/modules/authentication/interfaces/update-user.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { IUserQuiz } from '../../interfaces/quiz';


//////////////////////////////////////////////
export const createUserAction = createAction(
    '[OnBoarding Staffer] Create Staffer User Action',
    props<{ credentials: IStafferInformation }>()
);
export const createUserSuccessAction = createAction(
    '[OnBoarding Staffer / API] Create Staffer User Success Action',
    props<{ token: string }>()
);
export const createUserFailureAction = createAction(
    '[OnBoarding Staffer / API] Create Staffer User Failure Action',
    props<{ error: any }>()
);

//////////////////////////////////////////////////

export const addressConfimationAction = createAction(
    '[OnBoarding Staffer] Address Confimation Action',
    props<{ credentials: IAddressConfirmation }>()
);
export const addressConfimationSuccessAction = createAction(
    '[OnBoarding Staffer / API] Address Confimation Success Action'
);
export const addressConfimationFailureAction = createAction(
    '[OnBoarding Staffer / API] Address Confimation Failure Action',
    props<{ error: any }>()
);

export const redirectToAddressConfirmation = createAction(
    '[OnBoarding Staffer]  Redirect To Address Confirmation Action'
);

export const navigateTo = createAction(
    '[OnBoarding Staffer] Navigate to',
    props<{ route: string }>()
);
//////////////////////////////////////////////

export const stafferContractNotEligibleAction = createAction(
    '[OnBoarding Staffer]  Staffer Contract Not Eligible Action'
);


export const makePDFAndSendStafferEmail = createAction(
    '[OnBoarding Staffer ] Make PDF And Send In Email Action',
    props<{ userName: string }>()
);


export const updateUser = createAction(
    '[OnBoarding Staffer] Update User Action',
    props<{ credentials: IUpdateUser }>()
);

export const updateUserRoute = createAction(
    '[OnBoarding Staffer] Update User Route Action',
    props<{ currentNavStep: string }>()
);

export const addProfileImageAction = createAction(
    '[OnBoarding Staffer] Signup Add Logo Action',
    props<{ credentials: FormData }>()
);


export const addProfileImageSuccessAction = createAction(
    '[OnBoarding Staffer / API] Signup Add Logo Success Action',
    props<{ user: IUser }>()
);
export const addProfileImageFailureAction = createAction(
    '[OnBoarding Staffer / API] Signup Add Logo Failure Action',
    props<{ error: any }>()
);

export const stafferChooseImageFromMobileAction = createAction(
    '[OnBoarding Staffer] Choose Image From Mobile Action',
    props<{ sourceType: string }>()
);

export const uploadMobilePictureAction = createAction(
    '[OnBoarding Staffer] Upload Mobile Picture Action',
    props<{ filePath: string, token: string }>()
);

export const updateOnGoingQuizAction = createAction(
    '[OnBoarding Staffer]  Set setQuizData Action',
    props<{ update: Partial<IUserQuiz> }>()
  );

  export const updateQuizAnswers = createAction(
    '[OnBoarding Staffer]  Quiz question answered',
    props<{ questionIndex: number, selectedAnswer: { option: string, isCorrect: boolean } }>()
  );

  export const quizCompletedAction = createAction(
    '[OnBoarding Staffer]  Quiz completed',
  );

  export const resetOnGoingQuizAction = createAction(
    '[OnBoarding Staffer]  Reset OnGoing Quiz',
  );

export const submitForApprovalAction = createAction(
    '[OnBoarding Staffer]  Submit for Approval',
);
export const submitForApprovalSuccessAction = createAction(
    '[OnBoarding Staffer]  Submit for Approval Success',
);
export const submitForApprovalFailureAction = createAction(
    '[OnBoarding Staffer]  Submit for Approval Failed',
    props<{ error: any }>()
);

export const fetchCertificates = createAction(
    '[OnBoarding Staffer]  Fetch Certificates for Store',
    props<{stafferId: string}>()
);

export const fetchCertificatesSuccess = createAction(
    '[OnBoarding Staffer]  Save Certificates in Store',
    props<{certificates: any}>()
);


