import * as tslib_1 from "tslib";
import { PopupService } from 'src/modules/shared/services/popup.service';
import { ModalController } from '@ionic/angular';
import { CovidQuizModalComponent } from '../components/covid-quiz-modal/covid-quiz-modal.component';
import { JobService } from 'src/modules/shared/services/job.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/popup.service";
import * as i2 from "@ionic/angular";
import * as i3 from "../../shared/services/job.service";
import * as i4 from "../../shared/services/loading.service";
export class TalentCovidAssessmentService {
    constructor(popupService, modalController, jobService, loadingService) {
        this.popupService = popupService;
        this.modalController = modalController;
        this.jobService = jobService;
        this.loadingService = loadingService;
    }
    hasCovidPassed(staffObj) {
        if (staffObj.covidAssessment) {
            return staffObj.covid.p1a || staffObj.covid.p1b || staffObj.covid.p1c || staffObj.covid.p2a || staffObj.covid.p2b;
        }
        else {
            return true;
        }
    }
    checkForCovid(job, currentUser, cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const isFilledBefore = this.hasAlreadySubmittedQuiz(currentUser);
                if (isFilledBefore) {
                    //   FORM is already filled
                    cb ? cb(null, true) : resolve(false);
                }
                else {
                    // create modal instance
                    const myModal = yield this.modalController.create({
                        component: CovidQuizModalComponent,
                        cssClass: 'uploadImgModal',
                        componentProps: {}
                    });
                    // show it
                    yield myModal.present();
                    const dataReturned = yield myModal.onDidDismiss();
                    if (dataReturned.data) {
                        // show Loader
                        const loading = yield this.loadingService.showLoading().toPromise();
                        try {
                            //   submit covid quick
                            yield this.jobService.updateCOVIDAssessmentForStaffers({
                                covid: dataReturned.data,
                                covidAssessment: true,
                                jobIds: [job._id],
                                stafferId: currentUser.staffer
                            });
                            cb ? cb(dataReturned.data, null) : resolve(dataReturned.data);
                        }
                        catch (error) {
                            console.log('err', error);
                            cb ? cb(null, true) : reject();
                        }
                        finally {
                            this.loadingService.hideLoading(loading);
                        }
                    }
                }
            }));
        });
    }
    hasAlreadySubmittedQuiz(currentUser) {
        if (currentUser.covidAssessment) {
            this.popupService.showModal({
                heading: 'COVID-19 ASSESSMENT',
                message: `Sorry, You have failed to pass the assessment for COVID-19.
                     Please contact Staffy team for further instructions`,
                btn: 'Dismiss',
                navigateRoute: null,
                imgURL: 'assets/images/sorry1.png'
            });
            return true;
        }
        else {
            return false;
        }
    }
}
TalentCovidAssessmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TalentCovidAssessmentService_Factory() { return new TalentCovidAssessmentService(i0.ɵɵinject(i1.PopupService), i0.ɵɵinject(i2.ModalController), i0.ɵɵinject(i3.JobService), i0.ɵɵinject(i4.LoadingService)); }, token: TalentCovidAssessmentService, providedIn: "root" });
