/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-carenotes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./shift-carenotes.component";
var styles_ShiftCarenotesComponent = [i0.styles];
var RenderType_ShiftCarenotesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftCarenotesComponent, data: {} });
export { RenderType_ShiftCarenotesComponent as RenderType_ShiftCarenotesComponent };
function View_ShiftCarenotesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "patient-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Care notes: "])), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPatientTitle(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.careNotes || "-"); _ck(_v, 6, 0, currVal_1); }); }
function View_ShiftCarenotesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "listings"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftCarenotesComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ShiftCarenotesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "empty-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No care notes available"]))], null, null); }
export function View_ShiftCarenotesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "care-notes"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "ion-justify-content-center ion-text-center child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "content-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Care Notes "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftCarenotesComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftCarenotesComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "buttons-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "ion-button", [["class", " ion-no-margin btn success-popup-ok"], ["color", "dark"], ["expand", "full"], ["id", "success-popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, [" Dismiss "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.length); _ck(_v, 6, 0, currVal_0); var currVal_1 = !_co.data.length; _ck(_v, 8, 0, currVal_1); var currVal_2 = "dark"; var currVal_3 = "full"; _ck(_v, 11, 0, currVal_2, currVal_3); }, null); }
export function View_ShiftCarenotesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shift-carenotes", [], null, null, null, View_ShiftCarenotesComponent_0, RenderType_ShiftCarenotesComponent)), i1.ɵdid(1, 114688, null, 0, i5.ShiftCarenotesComponent, [i4.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShiftCarenotesComponentNgFactory = i1.ɵccf("app-shift-carenotes", i5.ShiftCarenotesComponent, View_ShiftCarenotesComponent_Host_0, { data: "data" }, {}, []);
export { ShiftCarenotesComponentNgFactory as ShiftCarenotesComponentNgFactory };
