import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { Market } from '@ionic-native/market/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { UserService } from './user.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { HttpPromiseService } from './http-promise.service';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {
  constructor(
    private platform: Platform,
    private device: Device,
    private userService: UserService,
    private appVersion: AppVersion,
    private httpPromiseService: HttpPromiseService,
    private popupService: PopupService,
    private market: Market,
    private theInAppBrowser: InAppBrowser
  ) {}

  async checkForAppInfo(user: IUser) {
    const data = {
      appVersion: '',
      appBuild: '',
      appName: 'Staffy',
      appPackage: 'test',
      isIOS: this.platform.is('ios'),
      isAndroid: this.platform.is('android'),
      isIPad: this.platform.is('ipad'),
      ionicDeployInfo: '',
      deviceName: '',
      deviceVersion: '',
      deviceManufacturer: ''
    };

    if ((window as any).cordova && (data.isIOS || data.isAndroid || data.isIPad)) {
      data.deviceName = this.device.platform;
      data.deviceVersion = this.device.version;
      data.deviceManufacturer = this.device.manufacturer;
      data.appVersion = await this.appVersion.getVersionNumber();
      data.appBuild = (await this.appVersion.getVersionCode()) as string;
      data.appName = await this.appVersion.getAppName();
      data.appPackage = await this.appVersion.getPackageName();
      try {
        await this.userService.updateUserPromise(user._id, data);
      } catch (error) {
        console.log(error);
      }
    }
  }

  compareVersions(version1, version2) {
    const v1 = version1.split('.').map(Number);
    const v2 = version2.split('.').map(Number);
    
    for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
      const num1 = v1[i] || 0;
      const num2 = v2[i] || 0;
    
      if (num1 > num2) return 1;
      if (num1 < num2) return -1;
    }
    
    return 0;
}

  async ensureUserHasMinimumRequiredVersion() {
    try {
      // check if the device is a mobile
      if (!this.isMobile()) {
        // if its not return
        return;
      }
      // fetch the app version from api
      const { version } = await this.getMiniumumRequiredVersion();
      // SET THE VARIABLES
      // ios or android
      const platform = this.device.platform.toLocaleLowerCase();
      // minimum required version for the platform user is currently on
      // in case user is on a platform that is not returned from backend
      // set the version to 0, so that user can continue using the app
      const platformMinRequiredVersion = version[platform] || '0';
      // current version of the installed app
      const appCurrentVersion = await this.appVersion.getVersionNumber();

      // Compare the current version with the minimum required version
      if (this.compareVersions(appCurrentVersion, platformMinRequiredVersion) < 0) {
        // Show a non-dismissable popup asking the user to update the app
        this.showAppUpdatePopup();
      }
    } catch (exception) {}
  }

  async getMiniumumRequiredVersion() {
    // Call the API and get the minium required version
    return await this.httpPromiseService.httpGetRequest('/api/appInfo');
  }

  private async showAppUpdatePopup() {
    // const appStoreUrl = this.platform.is('android') ? 'market://details?id=ca.staffy.app' : 'itms-apps://itunes.apple.com/app/id1133559351';

    this.popupService.showModal(
      {
        heading: 'A new version of the app is available',
        message: 'Please click the button below to update your app.',
        btn: 'DOWNLOAD',
        navigateRoute: null,
        imgURL: 'assets/images/download-dpi.png'
      },
      () => {
        //   callback for download button clic
        // open the respective app store
        if (this.platform.is('android')) {
          this.market.open('ca.staffy.app');
        } else {
          this.theInAppBrowser.create('itms-apps://itunes.apple.com/app/id1133559351', '_system');
        }
      }
    );
  }

  private isMobile() {
    return (window as any).cordova && (this.platform.is('ios') || this.platform.is('android') || this.platform.is('ipad'));
  }
}
