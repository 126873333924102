import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ShiftValidatorService } from 'src/modules/company-dashboard/services/shift-validator.service';
import { JobService } from '../../services/job.service';
import { LoadingService } from '../../services/loading.service';
import { PopupService } from '../../services/popup.service';
export class EditRecurrentSpecificShiftsComponent {
    constructor(formBuilder, loadingService, jobService, popupService, shiftValidatorService, changeDetectorRef) {
        this.formBuilder = formBuilder;
        this.loadingService = loadingService;
        this.jobService = jobService;
        this.popupService = popupService;
        this.shiftValidatorService = shiftValidatorService;
        this.changeDetectorRef = changeDetectorRef;
        this.confirmationView = false;
        this.data = [];
        this.config = ["Date", "Start Time", "End Time", "Unpaid Break", "Transit Allowance"];
        this.shifts = [];
    }
    ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
    }
    ngOnInit() {
        this.createForm();
        this.shifts = this.jobItem.map((item, index) => ({
            Date: moment(item.shiftDate).tz(item.timeZone).format('DD MMM,YY'),
            'Start Time': moment(item.shiftStartTime, 'X').tz(item.timeZone).format('hh:mm A'),
            'End Time': moment(item.shiftEndTime, 'X').tz(item.timeZone).format('hh:mm A'),
            'Unpaid Break': item.break ? item.break.unpaid : "No break",
            'Transit Allowance': item.travelTime.toString(),
            _id: item._id,
            index
        }));
        if (this.shifts.length > 2) {
            this.data = this.shifts.slice(0, 2);
        }
        else {
            this.data = this.shifts;
        }
    }
    loadMoreData({ startIndex, endIndex }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loader = yield this.loadingService.showLoading().toPromise();
            setTimeout(() => {
                this.data = [...this.shifts.slice(startIndex, endIndex)];
                this.loadingService.hideLoading(loader);
            }, 2000);
        });
    }
    createForm() {
        this.recurringShiftsCollection = [];
        for (let i = 0; i < this.jobItem.length; i++) {
            this.recurringShiftsCollection.push(this.formBuilder.group({
                date: [moment(this.jobItem[i].shiftDate).tz(this.timeZone).format('DD MMM,YY'), [Validators.required]],
                _id: [this.jobItem[i]._id],
                startTime: [{ value: this.jobItem[i].shiftStartTime, formatted: moment(this.jobItem[i].shiftStartTime, 'X').tz(this.timeZone).format('hh:mm A') }, [Validators.required, this.shiftValidatorService.validateTime('startTime')]],
                endTime: [{ value: this.jobItem[i].shiftEndTime, formatted: moment(this.jobItem[i].shiftEndTime, 'X').tz(this.timeZone).format('hh:mm A') }, [Validators.required, this.shiftValidatorService.validateTime('endTime')]],
                unpaidBreak: [{ label: this.jobItem[i].break ? this.jobItem[i].break.unpaid : "No Break", value: this.jobItem[i].break ? this.jobItem[i].break.unpaid : "No break" }],
                transitAllowance: [{ label: this.jobItem[i].travelTime ? `${this.jobItem[i].travelTime.toString()} Hour` : "None", value: this.jobItem[i].travelTime ? this.jobItem[i].travelTime.toString() : "0", }],
            }));
        }
        this.editRecurrentSpecificShiftForm = this.formBuilder.group({
            recurringShifts: this.formBuilder.array(this.recurringShiftsCollection)
        });
    }
    trackShiftPropertyChanges(updateView = false) {
        let updatedData = [];
        let data = [];
        for (const item of this.shifts) {
            const shift = this.editRecurrentSpecificShiftForm.value.recurringShifts.find(obj => obj._id == item._id);
            if (!shift) {
                continue;
            }
            if (item["Date"] !== shift.date
                || item["Start Time"] !== shift.startTime.formatted
                || item["End Time"] !== shift.endTime.formatted
                || item["Unpaid Break"] !== shift.unpaidBreak.value
                || item["Transit Allowance"] !== shift.transitAllowance.value) {
                updatedData.push({
                    shiftDate: shift.date,
                    _id: shift._id,
                    shiftStartTime: shift.startTime.value,
                    shiftEndTime: shift.endTime.value,
                    unpaidBreak: shift.unpaidBreak.value,
                    transitAllowance: shift.transitAllowance.value
                });
                data.push(item);
            }
        }
        if (updateView) {
            this.data = data;
            this.shifts = this.data;
        }
        return updatedData;
    }
    onSubmit(cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.editRecurrentSpecificShiftForm.markAllAsTouched();
            if (this.editRecurrentSpecificShiftForm.valid) {
                const loader = yield this.loadingService.showLoading().toPromise();
                try {
                    yield this.jobService.editRecurrentSpecificShifts(this.jobItem[0].recurringShift.groupJobId, { jobs: this.trackShiftPropertyChanges() });
                    this.loadingService.hideLoading(loader);
                    this.popupService.showModal({
                        heading: 'Success',
                        message: 'Successfully edit the recurring shifts',
                        btn: 'Dismiss',
                        navigateRoute: '',
                        imgURL: 'assets/images/request-sent.png'
                    }, (data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (cb) {
                            yield cb();
                        }
                    }));
                }
                catch (error) {
                    this.loadingService.hideLoading(loader);
                    this.popupService.showModal({
                        heading: 'Error',
                        message: error.error || 'Something went wrong, Please try again',
                        btn: 'Dismiss!',
                        navigateRoute: '',
                        imgURL: 'assets/images/sorry1.png'
                    });
                }
            }
            return true;
        });
    }
}
