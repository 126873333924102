import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Subscribable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, pluck } from 'rxjs/operators';
import { logOutAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { getNotificationCount, getShiftInProgress, getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { AccountTypeEnum } from 'src/modules/shared/enums/company-account-type.enum';
import { AppInfoService } from 'src/modules/shared/services/app-info.service';
import { JobService } from 'src/modules/shared/services/job.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { NetworkService } from 'src/modules/shared/services/network.service';
import { NotificationService } from 'src/modules/shared/services/notification.service';
import { PushNotificationService } from 'src/modules/shared/services/push-notification.service';
import { SideMenuUtilService } from 'src/modules/shared/services/sideMenu-util.service';
import { SkillFilterationService } from 'src/modules/shared/services/skill-filteration.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { TalentSyncService } from 'src/modules/talent-dashboard/services/talent-sync.service';
import { LayoutService } from '../layout.service';
@Component({
  selector: 'app-contractor-app-layout',
  templateUrl: './contractor-app-layout.component.html',
  styleUrls: ['./contractor-app-layout.component.scss']
})
export class ContractorAppLayoutComponent implements OnInit {
  userSubscription$?: Subscription;
  networkSubscription$?: Subscription;
  user?: IUser;
  appPages: any;
  skills?: any[];
  showBackBtn = false;
  notificationCount$ = this.store.pipe(select(getNotificationCount));
  shiftInProgress$ = this.store.pipe(select(getShiftInProgress));
  city: string;
  userWallet: number;
  totalRequestCount = 0;
  openRequestCount = 0;
  filledRequestCount = 0;
  isOnline = true;
  isHCW;
  notificationCount;
  activeMenu;
  shiftInProgress;

  constructor(
    private sideMenuUtilService: SideMenuUtilService,
    private store: Store<IAuthState>,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private networkService: NetworkService,
    private talentSyncService: TalentSyncService,
    private notificationService: NotificationService,
    private pushNotificationService: PushNotificationService,
    private jobService: JobService,
    private localStorageService: LocalStorageService,
    private appInfoService: AppInfoService,
    private loadingService: LoadingService,
    private utilService: UtilService,
    private skillFilterationService: SkillFilterationService,
    private menuController: MenuController,
    private readonly router: Router,
    private layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.userSubscription$ = this.store
      .pipe(
        select(getUserInfo),
        filter(user => !!user)
      )
      .subscribe(user => {
        this.user = user;
        this.setMenuItems(this.user.type);
        this.skills = this.user.skills.filter(skill => skill.has == true);
      });

    this.networkService.appNetworkStatus.subscribe(status => {
      this.isOnline = status === 'online';
    });
  }

  ngOnDestroy(): void {
    if(this.userSubscription$) {
      this.userSubscription$.unsubscribe();
    }

    if(this.networkSubscription$) {
      this.networkSubscription$.unsubscribe();
    }
  }

  closeMenu() {
    this.menuController.close();
  }

  async navBack() {
    this.showBackBtn = false;
    if (this.user.type) {
      await this.setMenuItems(this.user.type);
    }
  }

  async setMenuItems(type: string) {
    this.appPages =
      type === AccountTypeEnum.EMPLOYER ? this.sideMenuUtilService.getMenuForCompany(false) : this.sideMenuUtilService.getMenuForStaffy();
    await this.checkForValidation();
  }

  async checkForValidation() {
    this.appPages.forEach(async page => {
      page.show = true;

      if (page.title === 'Wallet' && this.user.address) {
        this.city = this.user.address.city === 'New_York' || this.user.address.province === 'Texas' ? 'US' : 'CA';
      }

      if (page.title == 'Requests') {
        try {
          this.openRequestCount = await this.jobService.getOpenRequestCount();

          if (typeof this.openRequestCount == 'object') {
            this.openRequestCount = 0;
          }
          this.filledRequestCount = await this.jobService.getFilledRequestCount();
          if (typeof this.filledRequestCount == 'object') {
            this.filledRequestCount = 0;
          }
        } catch (error) {
          console.log('error: ', error);
        } finally {
          this.totalRequestCount =
            (this.openRequestCount ? this.openRequestCount : 0) + (this.filledRequestCount ? this.filledRequestCount : 0);
        }
      }

      if (page.title === 'Direct Hire') {
        page.show = this.user.enableDirectHire;
      }

      if (page.title === 'Add Manual Time') {
        page.show = !!this.user.enableManualHoursOnApp;
      }

      if (page.subMenu) {
        page.subMenu.forEach(async (subPage, i) => {
          if (page.title == 'Settings' && subPage.title === 'Edit Picture') {
            const isHCW = await this.skillFilterationService.doesUserHasHCSkills(this.skills);
            if (isHCW && this.user.profile_approved === true) {
              subPage.show = false;
            } else {
              subPage.show = true;
            }
          } else if (
            page.title == 'Settings' &&
            subPage.title === 'Set Sub Skills' &&
            this.utilService.checkNursingSkills(this.skills) == false
          ) {
            subPage.show = false;
          } else {
            subPage.show = true;
          }

          if (page.title === 'Direct Hire') {
            page.show = this.user.enableDirectHire;
          }

          if (page.title === 'Add Manual Time') {
            page.show = !!this.user.enableManualHoursOnApp;
          }

          if (page.subMenu == 'Open Requests') {
            try {
              this.openRequestCount = await this.jobService.getOpenRequestCount();
              if (typeof this.openRequestCount == 'object') {
                this.openRequestCount = 0;
              }
            } catch (error) {
              console.log('error: ', error);
            } finally {
              this.totalRequestCount =
                (this.openRequestCount ? this.openRequestCount : 0) + (this.filledRequestCount ? this.filledRequestCount : 0);
            }
          }

          if (page.subMenu == 'Filled Requests') {
            try {
              this.filledRequestCount = await this.jobService.getFilledRequestCount();
              if (typeof this.filledRequestCount == 'object') {
                this.filledRequestCount = 0;
              }
            } catch (error) {
              console.log('error: ', error);
            } finally {
              this.totalRequestCount =
                (this.openRequestCount ? this.openRequestCount : 0) + (this.filledRequestCount ? this.filledRequestCount : 0);
            }
          }
        });
      }
    });
  }

  navigate(page): void {
    this.layoutService.navigate(
      page,
      this.user,
      (page: any, setActiveMenu = false) => {
        this.showBackBtn = true;
        this.appPages = page.subMenu;
        if (setActiveMenu) {
          this.activeMenu = page;
        }
      }
    );
  }

}
