import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./local-storage.service";
/*************************** End ****************************/
export class HttpService {
    constructor(http, localStorageService) {
        this.http = http;
        this.localStorageService = localStorageService;
    }
    // HTTP POST request without headers content-type
    httpPostRequest(url, data) {
        return this.http
            .post(url, data);
    }
    // HTTP GET request
    httpGetRequest(url) {
        return this.http
            .get(url);
    }
    // HTTP PATCH request
    httpPutRequest(url, data) {
        return this.http
            .put(url, data);
    }
    // HTTP DELETE request
    httpDeleteRequest(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getTokenAppendHeader();
            const requestURL = `${environment.API_BASE_URL}${url}`;
            return this.http
                .delete(requestURL, { headers })
                .toPromise()
                .then(res => res || [], this.responseError.bind(this));
        });
    }
    // error return from promise
    responseError(error) {
        throw error;
    }
    getTokenAppendHeader() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let headers = new HttpHeaders();
            const authToken = yield this.localStorageService.getLoggedInUserToken();
            headers = headers.set('Content-Type', 'application/json');
            headers = headers.set('Authorization', `Bearer ${authToken}`);
            return headers;
        });
    }
}
HttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService)); }, token: HttpService, providedIn: "root" });
